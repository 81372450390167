import axios from "axios";

const API_URL = `${process.env.REACT_APP_BACKEND_ROUTE}/contact/`;

const getContactData = async () => {
  const response = await axios.get(API_URL + "contact-data");
  if (response.data) {
    return response.data;
  }
};

const addContactContent = async (userData) => {
  const response = await axios.post(API_URL + "add-contacts", userData);
  if (response.data) {
    return response.data;
  }
};

const contactUs = async (data) => {
  const response = await axios.post(API_URL + "contact-us", data);
  if (response.data) {
    return response.data;
  }
};
const productService = {
  getContactData,
  addContactContent,
  contactUs,
  // editPlansData,
  // deletePlansData,
};

export default productService;
