import React, { useState, useEffect, useLayoutEffect } from "react";
import Footer from "../Footer/Footer";
import FeatureBlogs from "../Home/_part/FeatureBlogs";
import Tours from "../Home/_part/Tours";
import Header from "../Header/Header";
import VirtualMap from "./_part/VirtualMap";
import { getProfile, reset } from "../../../features/MyProfile/profileSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  clearList,
  getTour,
  reset as reset1,
} from "../../../features/360Tours/tourSlice";
import { useNavigate } from "react-router";
import { addAreaOIfInterest } from "../../../features/auth/authSlice";

function VirtualTours({ Profile, setProfile }) {
  const user = JSON.parse(localStorage.getItem("user"));
  const {
    profile_data,
    isLoading,
    isError: isErrorAuth,
    isSuccess: isSuccessAuth,
    message: messageAuth,
  } = useSelector((state) => state.profileData);
  const { tour_List, isError, isSuccess, message, isList, imageLocation } =
    useSelector((state) => state.tours);
  const [tourData, setTourData] = useState([]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useLayoutEffect(() => {
    dispatch(reset());
    dispatch(clearList());
    dispatch(reset1());
    dispatch(getTour("All"));
    if (user?.data.user._id) {
      dispatch(getProfile(user?.data.user._id));
      // dispatch(updateLastLogin(ID));
    }
  }, []);
  const GoToTour = (data) => {
    console.log("go to tour", data);
    navigate("/virtual-tours", {
      state: { Data: data, tourData: tourData?.slice(0, 4) },
    });

    if (user) {
      const interest = {
        tourId: data._id,
        userId: user?.data?.user?._id,
      };
      dispatch(addAreaOIfInterest(interest));
    }
  };

  useEffect(() => {
    if (tour_List) {
      const newData = tour_List?.filter((val) => val?.status == "PUBLISHED");
      setTourData(newData);
    }
  }, [isError, isSuccess, message]);

  return (
    <div>
      <Header
        user={user}
        userProfile={profile_data}
        Profile={Profile}
        setProfile={setProfile}
      />
      <div className="mapBox">
        <VirtualMap GoToTour={GoToTour} />
      </div>
      <div className="virtualTours pb-4 pt-3">
        <div className="container-fluid px-50px">
          <h1>Featured Virtual Tours</h1>
          <div className="col-12 pt-4 pb-5">
            <Tours tourData={tourData} GoToTour={GoToTour} />
          </div>
        </div>
      </div>
      <FeatureBlogs />
      <Footer />
    </div>
  );
}

export default VirtualTours;
