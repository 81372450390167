import axios from "axios";
import { toast } from "react-toastify";

const API_URL = `${process.env.REACT_APP_BACKEND_ROUTE}/article/`;

// Get list of article user
const articleDraftList = async (ID) => {
  const response = await axios.get(API_URL + `draft-list/${ID}`);
  if (response.data) {
    return response.data;
  }
};

const getArticlePending = async (ID) => {
  const response = await axios.get(API_URL + `pending-list/${ID}`);
  if (response.data) {
    return response.data;
  }
};

const getArticlePendingAll = async () => {
  const response = await axios.get(API_URL + "pending-list-all");
  if (response.data) {
    return response.data;
  }
};

const addArticleData = async (userData) => {
  // let url = "http://3.108.146.184:8082/article/"
  const response = await axios.post(API_URL + "add-article", userData);
  if (response.data) {
    return response.data;
  }
};

const updateCount = async (countData) => {
  const response = await axios.post(API_URL + "add-count", countData);
  if (response.data) {
    return response.data;
  }
};

const publishArticle = async (ID) => {
  const response = await axios.put(API_URL + `publish-articles/${ID}`);
  if (response.data) {
    return response.data;
  }
};

const editArticles = async (userData) => {
  const response = await axios.put(API_URL + "edit-article", userData);
  if (response.data) {
    return response.data;
  }
};

const ApproveArticles = async (artData) => {
  const response = await axios.put(API_URL + "approve-article", artData);
  if (response.data) {
    return response.data;
  }
};

const RejectArticles = async (artData) => {
  const response = await axios.put(API_URL + "reject-article", artData);
  if (response.data) {
    return response.data;
  }
};

const getArticleApproved = async (ID) => {
  const response = await axios.get(API_URL + `get-approve-articles/${ID}`);
  if (response.data) {
    return response.data;
  }
};

const getArticlePublished = async (ID) => {
  const response = await axios.get(API_URL + `get-publish-articles/${ID}`);
  if (response.data) {
    return response.data;
  }
};

const getArticlePublishedAll = async () => {
  const response = await axios.get(API_URL + "get-publish-articles-all");
  if (response.data) {
    return response.data;
  }
};

const getArticleDeleted = async () => {
  const response = await axios.get(API_URL + "get-articles-deleted");
  if (response.data) {
    return response.data;
  }
};

const getArticleDeletedCont = async (ID) => {
  const response = await axios.get(API_URL + `get-articles-deleted-cont/${ID}`);
  if (response.data) {
    return response.data;
  }
};

const getArticleRejected = async (ID) => {
  const response = await axios.get(API_URL + `get-articles-rejected/${ID}`);
  if (response.data) {
    return response.data;
  }
};

const deletedArticle = async (delt) => {
  const response = await axios.put(API_URL + "delete-article", delt);
  if (response.data) {
    return response.data;
  }
};

const deletedArticleCont = async (delt) => {
  const response = await axios.put(API_URL + "delete-article-cont", delt);
  if (response.data) {
    return response.data;
  }
};

const getArticleDetail = async (id) => {
  const response = await axios.get(API_URL + `article-details/${id}`);
  if (response.data) {
    return response.data;
  }
};

const createReview = async (Data) => {
  const response = await axios.post(API_URL + "article-reviews", Data);
  if (response.data) {
    return response.data;
  }
};
const createReply = async (Data) => {
  const response = await axios.post(API_URL + "review-reply", Data);
  if (response.data) {
    return response.data;
  }
};

// // Add Image Data
// const saveImageC = async (bodyFormData) => {
//   const response = await axios.post(API_URL + 'add-uploads/s3', bodyFormData, {
//     headers: {
//       'Content-Type': 'multipart/form-data',
//     }
//   });
//   if (response.data) {
//       return response.data
//   }
// }

// const updatePassC = async (passData) => {
//   const response = await axios.post(API_URL + 'change-password', passData);
//     return response.data;
// };

const deletedRowsArticles = async (passData) => {
  const response = await axios.put(API_URL + "deleted-rows", passData);
  return response.data;
};
const deleteReview = async (id) => {
  const response = await axios.post(API_URL + "delete-review", id);
  return response.data;
};

const articleService = {
  articleDraftList,
  addArticleData,
  publishArticle,
  getArticlePending,
  getArticlePendingAll,
  editArticles,
  ApproveArticles,
  RejectArticles,
  getArticleApproved,
  getArticlePublished,
  getArticlePublishedAll,
  getArticleDeleted,
  deletedArticle,
  getArticleDetail,
  getArticleDeletedCont,
  getArticleRejected,
  deletedArticleCont,
  deletedRowsArticles,
  createReview,
  updateCount,
  createReply,
  deleteReview,

  //   editContributorsData,
  //   deleteContributorsData,
  //   saveImageC,
  //   updatePassC,
  //   deletedRowsC,
};

export default articleService;
