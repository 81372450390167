import React, { useState, useEffect, useLayoutEffect } from "react";
import { Form, InputGroup, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { paid_column } from "../../../../utils/constant/column_header_data";
import {
  getQuotation,
  reset,
} from "../../../../features/Quotations/quotationSlice";
import { useDispatch, useSelector } from "react-redux";
import DataTable from "react-data-table-component";
import { toast } from "react-toastify";
import { formatDate } from "../../../../utils/DateHelper";

function Paid() {
  const user = JSON.parse(localStorage.getItem("user"));
  const role = user?.data?.user?.role;
  const ID = user?.data?.user?._id;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { quotation_List, isError, isSuccess, message, isList } = useSelector(
    (state) => state.quotations
  );
  const { frontData } = useSelector((state) => state.front_Data);

  const [Search, setSearch] = useState("");

  const [CList, setCList] = useState([]);

  useLayoutEffect(() => {
    dispatch(reset());
    dispatch(getQuotation());
  }, []);

  useEffect(() => {
    if (quotation_List) {
      const [questID] = quotation_List.map((val) => val.plan[0].questionaire);
      //   dispatch(getQuestionsScore(questID));
      let data = [...quotation_List]
        .reverse()
        .filter((val) => val.isAccepted == true && val.status === "Accept");
      if (role === "staff") {
        setCList(data);
      }
      if (role === "contributor" || role === "user") {
        data = data.filter((val) => val?.owner?._id === ID);
        setCList(data);
      }
      if (role === "admin") {
        setCList(data);
      }
    }
  }, [dispatch, navigate, isSuccess, isError, message, isList, ID]);

  const onView = (value) => {
    (user?.data?.user?.role === "staff" &&
      user?.data?.user?.permissions[0]?.ArticlesOrders?.some(
        (val) => val === "view"
      )) ||
    user?.data?.user?.role === "contributor" ||
    user?.data?.user?.role === "admin"
      ? navigate(`/orders/quotation/order-detail/${value._id}`, {
          state: { Details: value, edit: false },
        })
      : toast.error("Permission denied!");
  };

  const onPay = (value) => {
    user?.data?.user?.role === "contributor" ||
    user?.data?.user?.role === "user" ||
    (user?.data?.user?.role === "staff" &&
      user?.data?.user?.permissions[0]?.ArticlesOrders?.some(
        (val) => val === "view"
      )) ||
    user?.data?.user?.role === "admin"
      ? navigate("/billing/bill-form", {
          state: { Details: value, edit: false },
        })
      : toast.error("Permission denied!");
  };
  console.log(CList, 999);
  var rows = [];
  if (CList) {
    for (let [i, value] of CList?.entries()) {
      rows[i] = {
        id: value._id,
        user: value?.owner?.user_name,
        amount: value?.plan[0]?.pricing_amount,
        order_date: formatDate(value.createdAt),
        pricing_type: value?.plan[0]?.pricing_type,
        product_plan: value.product_plan,
        paid_at: formatDate(value.paidDate),
        action: (
          <div className="d-flex gap-2">
            <div className="d-flex gap-3">
              <img
                src="/imgs/u_eye.svg"
                width={20}
                alt=""
                onClick={() => onView(value)}
              />
            </div>
            {!value?.isPaid && (
              <div className="d-flex gap-3">
                <img
                  src="/imgs/amount.svg"
                  width={20}
                  alt=""
                  onClick={() => onPay(value)}
                />
              </div>
            )}
          </div>
        ),
      };
    }
  }

  return (
    <div className="staffMainDiv">
      <div className="row mx-0 mb-4">
        <div className="d-flex justify-content-between">
          <div className="d-flex gap-3">
            <img
              src="/imgs/revoke.svg"
              width={20}
              alt=""
              onClick={() => setSearch("")}
            />
            <Form.Group className="mb-0">
              <InputGroup className="mb-0">
                <InputGroup.Text id="basic-addon1">
                  <img src="/imgs/search.svg" alt="" />
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  placeholder="Search"
                  value={Search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </InputGroup>
            </Form.Group>
          </div>
        </div>
      </div>
      <DataTable
        columns={paid_column}
        data={
          rows
            ? rows.filter(
                (item) =>
                  item?.product_plan
                    ?.toLowerCase()
                    ?.includes(Search?.toLowerCase()) ||
                  item?.user?.toLowerCase()?.includes(Search?.toLowerCase())
              )
            : []
        }
        pagination
      />
    </div>
  );
}

export default Paid;
