import React from "react";
import { Link } from "react-router-dom";

function PageManagement() {
  return (
    <div className="pageManagement">
      <div className="row">
        <div className="col-md-4">
          <Link to="/settings/vetting/bad-words">
            <div className="block">
              <h1>Bad Words</h1>
            </div>
          </Link>
        </div>
        <div className="col-md-4">
          <Link to="/settings/vetting/post-requirements">
            <div className="block">
              <h1>Post Requirements</h1>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default PageManagement;
