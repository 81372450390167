import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import Modal from "react-bootstrap/Modal";
import moment from "moment";
import { useSnackbar } from "notistack";
import {
  getProfile,
  reset,
  eprofileUpdatePass,
  reset as resetProfile,
  resetImage,
  saveImage,
  updateProfile,
} from "../../../features/MyProfile/profileSlice";
import {
  updatePass,
  reset as resetAuth,
} from "../../../features/auth/authSlice";
import { useLayoutEffect } from "react";
import { clearList } from "../../../features/Articles/articleSlice";

import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/semantic-ui.css";
import { getBadword } from "../../../features/badWord/BadWordSlice";

function Profile() {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [imageUpdated, setImageUpdated] = useState(false);
  const [show, setShow] = useState(false);
  const [showPass, setShowPass] = useState("password");
  const [showPass2, setShowPass2] = useState("password");
  const [showPass3, setShowPass3] = useState("password");
  const [tempProfile, setTempProfile] = useState("");

  const [oldPass, setOldPass] = useState("");
  const [newPass, setNewPass] = useState("");
  const [CPass, setCPass] = useState("");

  const handleClose = () => {
    setShow(false);
    setOldPass("");
    setNewPass("");
    setCPass("");
    setShowPass("password");
  };
  const handleShow = () => setShow(true);

  const {
    user,
    message: userMessage,
    isError: userError,
    isSuccess: userSuccess,
    isLoading: userLoading,
    changeSuccess,
    changeError,
  } = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  // const navigate = useNavigate();

  const SavePass = () => {
    const passData = {
      oldPass,
      newPass,
      CPass,
      id: user.data.user._id,
    };
    if (newPass === CPass) {
      const reg = /(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/;
      if (reg.test(CPass)) {
        eprofileUpdatePass(passData)
          .then((res) => {
            if (res.success === false) {
              console.log("res=>>>>>>", res);
              toast.error(res?.message);
            } else {
              toast.success(res.data);
              setShowPass("password");
              handleClose();
            }
          })
          .catch((err) => {
            toast.error("Error when updating password");
          });
      } else {
        toast.error(
          "Password must have at least 1 capital letter, 1 number and be a minimum of 8 characters"
        );
      }
      // dispatch(resetAuth());
    } else {
      // enqueueSnackbar('Password did not match!', {variant: 'error'});
      toast.error("Password did not match!");
    }
  };

  const user2 = JSON.parse(localStorage.getItem("user"));

  const {
    profile_data,
    isLoading,
    isError,
    isSuccess,
    message,
    imageLocation,
  } = useSelector((state) => state.profileData);

  const [Edit, setEdit] = useState(true);

  const [editFormData, setEditFormData] = useState({
    first_name: "",
    last_name: "",
    user_name: "",
    dob: "",
    gender: "",
    profile: "",
    status: "",
    password: "",
    email: "",
    contact_no: "",
    country: "",
    state: "",
    city: "",
    postal_code: "",
    address: "",
  });

  const handleEditFormChange = (event) => {
    event.preventDefault();
    const fieldName = event.target.getAttribute("name");
    const fieldValue = event.target.value;

    const newFormData = { ...editFormData };
    newFormData[fieldName] = fieldValue;

    setEditFormData(newFormData);
  };
  const handlePhoneNumberChange = (event) => {
    const newFormData = { ...editFormData };
    newFormData["contact_no"] = event;

    setEditFormData(newFormData);
  };
  const UpdateForm = async (event) => {
    event.preventDefault();

    const userData = {
      _id: profile_data._id,
      first_name: editFormData.first_name,
      last_name: editFormData.last_name,
      user_name: editFormData.user_name,
      dob: editFormData.dob,
      profile: imageLocation ? imageLocation : editFormData.profile,
      status: editFormData.status,
      // password: editFormData.password,
      email: editFormData.email,
      contact_no: editFormData.contact_no,
      country: editFormData.country ? editFormData?.country : "",
      state: editFormData.state ? editFormData.state : "",
      city: editFormData.city ? editFormData.city : "",
      postal_code: editFormData.postal_code ? editFormData.postal_code : "",
      address: editFormData.address ? editFormData.address : "",
      gender: editFormData.gender ? editFormData.gender : "",
      // role: editFormData.role,
      // permissions: data ? data : '',
    };
    const data = await dispatch(updateProfile(userData));
    setEditFormData({ ...editFormData, profile: data?.payload?.data?.profile });
    if (data.error) {
      toast.error(data.payload);
    } else {
      toast.success("Profile Updated Succesfully");
    }
    if (isSuccess == true) {
      setEdit(true);
    }
  };

  var path = window.location.pathname;

  useLayoutEffect(() => {
    let ID = user2?.data?.user?._id;
    dispatch(getBadword());
    if (path === "/profile") {
      dispatch(clearList());
      dispatch(reset());
      dispatch(getProfile(ID));
    }
  }, []);

  useEffect(() => {
    const formData = {
      first_name: profile_data?.first_name,
      last_name: profile_data?.last_name,
      user_name: profile_data?.user_name,
      dob: profile_data?.dob,
      // national_id: profile_data?.national_id,
      gender: profile_data?.gender,
      profile: imageLocation ? imageLocation : profile_data?.profile,
      status: profile_data?.status,
      password: profile_data?.password,
      email: profile_data?.email,
      contact_no: profile_data?.contact_no,
      country: profile_data?.country,
      state: profile_data?.state,
      city: profile_data?.city,
      postal_code: profile_data?.postal_code,
      address: profile_data?.address,
      role: profile_data?.role,
      // permissions: data,
    };

    setEditFormData(formData);
  }, [profile_data]);

  useEffect(() => {
    if (userMessage?.message !== "" && userMessage?.status === "Success") {
      toast.success("password updated successfully!");
      setTimeout(() => {
        dispatch(resetAuth());
      }, 1000);
    }
  }, [userMessage?.message, userMessage?.status]);

  const handleEditClick = () => {
    setEdit(false);

    const formData = {
      first_name: profile_data?.first_name,
      last_name: profile_data?.last_name,
      user_name: profile_data?.user_name,
      dob: profile_data?.dob,
      gender: profile_data?.gender,
      profile: profile_data?.profile,
      status: profile_data?.status,
      password: profile_data?.password,
      email: profile_data?.email,
      contact_no: profile_data?.contact_no,
      country: profile_data?.country,
      state: profile_data?.state,
      city: profile_data?.city,
      postal_code: profile_data?.postal_code,
      address: profile_data?.address,
      role: profile_data?.role,
      // permissions: data,
    };
    setEditFormData(formData);
  };

  const handleEditClick2 = () => {
    toast.error("Permission denied! Please verify email first to proceed.");
  };

  const uploadFileHandler = async (e) => {
    const file = e.target.files[0];
    setTempProfile(editFormData?.profile);
    const bodyFormData = new FormData();
    bodyFormData.append("image", file);

    const { payload } = await dispatch(saveImage(bodyFormData));
    setImageUpdated(true);
    setEditFormData({ ...editFormData, profile: payload.data });
  };

  var dtToday = new Date();

  var month = dtToday.getMonth() + 1;
  var day = dtToday.getDate() - 1;
  var year = dtToday.getFullYear();

  if (month < 10) month = "0" + month.toString();
  if (day < 10) day = "0" + day.toString();

  var maxDate = year + "-" + month + "-" + day;

  const showPassword = () => {
    if (showPass === "password") {
      setShowPass("text");
    }
    if (showPass === "text") {
      setShowPass("password");
    }
  };
  const showPassword2 = () => {
    if (showPass2 === "password") {
      setShowPass2("text");
    }
    if (showPass2 === "text") {
      setShowPass2("password");
    }
  };
  const showPassword3 = () => {
    if (showPass3 === "password") {
      setShowPass3("text");
    }
    if (showPass3 === "text") {
      setShowPass3("password");
    }
  };
  return (
    <div>
      <ToastContainer />
      {profile_data && (
        <>
          <div className="cardSection">
            <div className="d-flex justify-content-between">
              <h1>General Information</h1>
              <div>
                {Edit == false ? (
                  <div className="d-flex justify-content-center gap-4 mb-3">
                    <Button
                      className="cancelBtn"
                      type="button"
                      onClick={() => {
                        setEdit(true);
                        setEditFormData({
                          ...editFormData,
                          profile: tempProfile,
                        });
                      }}
                    >
                      Cancel
                    </Button>
                    <Button className="loginBtn" onClick={UpdateForm}>
                      Update
                    </Button>
                  </div>
                ) : user2.data?.user?.isConfirmed == true ? (
                  <img src="/imgs/edit.svg" alt="" onClick={handleEditClick} />
                ) : (
                  <img src="/imgs/edit.svg" alt="" onClick={handleEditClick2} />
                )}
              </div>
            </div>
            <div className="text-center">
              {!Edit && (
                <img
                  src="/imgs/delImg.svg"
                  className="delImg"
                  alt="profile"
                  style={{ position: "absolute", right: "499px" }}
                  onClick={() => {
                    // setTempProfile("")
                    dispatch(resetImage());
                    setEditFormData({
                      ...editFormData,
                      profile:
                        "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_640.png",
                    });
                  }}
                />
              )}
              <img src={editFormData.profile} className="profileImg" alt="" />
              <br />
              {Edit == false && (
                <input type="file" onChange={uploadFileHandler}></input>
              )}
            </div>
            <div className="row mx-0">
              <div className="col-10 mx-auto">
                <Form className="mt-5">
                  <div className="row mx-0">
                    <div className="col-md-5 px-0">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>First Name</Form.Label>
                        <InputGroup className="mb-3">
                          <InputGroup.Text id="basic-addon1">
                            <img src="/imgs/u_user.svg" alt="" />
                          </InputGroup.Text>

                          <Form.Control
                            type="text"
                            name="first_name"
                            disabled={Edit}
                            value={editFormData.first_name}
                            onChange={handleEditFormChange}
                          />
                        </InputGroup>
                      </Form.Group>
                    </div>
                    <div className="col-md-2"></div>
                    <div className="col-md-5 px-0">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Last Name</Form.Label>
                        <InputGroup className="mb-3">
                          <InputGroup.Text id="basic-addon1">
                            <img src="/imgs/u_user.svg" alt="" />
                          </InputGroup.Text>
                          <Form.Control
                            type="text"
                            disabled={Edit}
                            name="last_name"
                            value={editFormData.last_name}
                            onChange={handleEditFormChange}
                          />
                        </InputGroup>
                      </Form.Group>
                    </div>
                    <div className="col-md-5 px-0">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>User Name</Form.Label>
                        <InputGroup className="mb-3">
                          <InputGroup.Text id="basic-addon1">
                            <img src="/imgs/u_user.svg" alt="" />
                          </InputGroup.Text>
                          <Form.Control
                            type="text"
                            name="user_name"
                            disabled={Edit}
                            value={editFormData.user_name}
                            onChange={handleEditFormChange}
                          />
                        </InputGroup>
                      </Form.Group>
                    </div>
                    <div className="col-md-2"></div>
                    <div className="col-md-5 px-0">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Date of Birth</Form.Label>
                        <InputGroup className="mb-3">
                          <InputGroup.Text id="basic-addon1">
                            <img src="/imgs/dob.svg" alt="" />
                          </InputGroup.Text>
                          <Form.Control
                            type="date"
                            name="dob"
                            max={maxDate}
                            disabled={Edit}
                            value={moment(editFormData.dob).format(
                              "YYYY-MM-DD"
                            )}
                            onChange={handleEditFormChange}
                          />
                        </InputGroup>
                      </Form.Group>
                    </div>
                    <div className="col-md-5 px-0">
                      {/* <Form.Group className="mb-3" controlId="formBasicEmail"> */}
                      {/* <Form.Label>National Id</Form.Label>
                        <InputGroup className="mb-3">
                          <InputGroup.Text id="basic-addon1">
                            <img src="/imgs/national.svg" alt="" />
                          </InputGroup.Text>
                          <Form.Control
                            type="text"
                            name="national_id"
                            disabled={Edit}
                            value={editFormData.national_id}
                            onChange={handleEditFormChange}
                          />
                        </InputGroup> */}
                      {/* </Form.Group> */}
                      <Form.Group className="mb-3">
                        <Form.Label>Gender</Form.Label>
                        <div>
                          <Form.Check
                            inline
                            id="male"
                            type="radio"
                            label="Male"
                            name="gender"
                            value="man"
                            checked={editFormData.gender == "man"}
                            onChange={handleEditFormChange}
                          />
                          <Form.Check
                            inline
                            id="female"
                            type="radio"
                            label="Female"
                            name="gender"
                            value="woman"
                            checked={editFormData.gender == "woman"}
                            onChange={handleEditFormChange}
                          />
                        </div>
                      </Form.Group>
                    </div>
                    <div className="col-md-2"></div>
                    <div className="col-md-5 px-0">
                      <Form.Group className="mb-0" controlId="formBasicEmail">
                        <Form.Label>Password</Form.Label>
                        <InputGroup className="mb-2">
                          <InputGroup.Text id="basic-addon1">
                            <img src="/imgs/blackLock.svg" alt="" />
                          </InputGroup.Text>
                          <Form.Control
                            type={showPass}
                            disabled={true}
                            name="password"
                            value={"password"}
                            onChange={handleEditFormChange}
                          />
                          {/* {showPass === "text" ? (
                            <InputGroup.Text id="basic-addon1">
                              <VisibilityOffIcon
                                fontSize="small"
                                style={{
                                  cursor: "pointer",
                                  color: "lightgray",
                                }}
                                alt=""
                                onClick={showPassword}
                              />
                            </InputGroup.Text>
                          ) : (
                            <InputGroup.Text id="basic-addon1">
                              <VisibilityIcon
                                fontSize="small"
                                style={{
                                  cursor: "pointer",
                                  color: "lightgray",
                                }}
                                alt=""
                                onClick={showPassword}
                              />
                            </InputGroup.Text>
                          )} */}
                        </InputGroup>
                      </Form.Group>
                      {Edit == false && (
                        <p className="forgotPassword" onClick={handleShow}>
                          Change Password
                        </p>
                      )}
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
          <div className="cardSection mt-4 mb-2">
            <div className="d-flex justify-content-between">
              <h1>Contact & Address Information</h1>
            </div>
            <div className="row mx-0">
              <div className="col-10 mx-auto">
                <Form className="mt-5">
                  <div className="row mx-0">
                    <div className="col-md-5 px-0">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Email</Form.Label>
                        <InputGroup className="mb-3">
                          <InputGroup.Text id="basic-addon1">
                            <img src="/imgs/email.svg" alt="" />
                          </InputGroup.Text>
                          <Form.Control
                            type="email"
                            disabled={Edit}
                            name="email"
                            value={editFormData.email}
                            onChange={handleEditFormChange}
                          />
                        </InputGroup>
                      </Form.Group>
                    </div>
                    <div className="col-md-2"></div>
                    <div className="col-md-5 px-0">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Contact No</Form.Label>
                        <InputGroup className="mb-3">
                          <PhoneInput
                            specialLabel="Contact No"
                            country={"sg"}
                            value={editFormData.contact_no}
                            onChange={handlePhoneNumberChange}
                            disabled={Edit}
                            name="contact_no"
                            containerStyle={{ width: "100%", height: "100%" }}
                          />
                        </InputGroup>
                      </Form.Group>
                    </div>
                    <div className="col-12 px-0">
                      <h4 className="identifier py-3">Address</h4>
                    </div>
                    <div className="col-md-5 px-0">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Country</Form.Label>
                        <InputGroup className="mb-3">
                          <InputGroup.Text id="basic-addon1">
                            <img src="/imgs/country.svg" alt="" />
                          </InputGroup.Text>
                          <Form.Control
                            type="text"
                            disabled={Edit}
                            name="country"
                            placeholder="e.g: Singapore"
                            style={{ fontStyle: "italic" }}
                            value={editFormData.country}
                            onChange={handleEditFormChange}
                          />
                        </InputGroup>
                      </Form.Group>
                    </div>
                    <div className="col-md-2"></div>
                    <div className="col-md-5 px-0">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>State</Form.Label>
                        <InputGroup className="mb-3">
                          <InputGroup.Text id="basic-addon1">
                            <img src="/imgs/address.svg" alt="" />
                          </InputGroup.Text>
                          <Form.Control
                            type="text"
                            disabled={Edit}
                            name="state"
                            placeholder="e.g: Singapore"
                            style={{ fontStyle: "italic" }}
                            value={editFormData.state}
                            onChange={handleEditFormChange}
                          />
                        </InputGroup>
                      </Form.Group>
                    </div>
                    <div className="col-md-5 px-0">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>City</Form.Label>
                        <InputGroup className="mb-3">
                          <InputGroup.Text id="basic-addon1">
                            <img src="/imgs/address.svg" alt="" />
                          </InputGroup.Text>
                          <Form.Control
                            type="text"
                            disabled={Edit}
                            name="city"
                            placeholder="e.g: Singapore"
                            style={{ fontStyle: "italic" }}
                            value={editFormData.city}
                            onChange={handleEditFormChange}
                          />
                        </InputGroup>
                      </Form.Group>
                    </div>
                    <div className="col-md-2"></div>
                    <div className="col-md-5 px-0">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Postal Code</Form.Label>
                        <InputGroup className="mb-3">
                          <InputGroup.Text id="basic-addon1">
                            <img src="/imgs/postal_code.svg" alt="" />
                          </InputGroup.Text>
                          <Form.Control
                            type="text"
                            disabled={Edit}
                            name="postal_code"
                            value={editFormData.postal_code}
                            onChange={handleEditFormChange}
                          />
                        </InputGroup>
                      </Form.Group>
                    </div>
                    <div className="col-md-12 px-0">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Address</Form.Label>
                        <InputGroup className="mb-3">
                          <InputGroup.Text id="basic-addon1">
                            <img src="/imgs/address.svg" alt="" />
                          </InputGroup.Text>
                          <Form.Control
                            type="text"
                            disabled={Edit}
                            name="address"
                            value={editFormData.address}
                            placeholder="e.g: Singapore"
                            style={{ fontStyle: "italic" }}
                            onChange={handleEditFormChange}
                          />
                        </InputGroup>
                      </Form.Group>
                      {/* <p className="forgotPassword">Add New Address</p> */}
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
          <Modal show={show} onHide={handleClose} centered>
            <div className="d-flex justify-content-end pe-2 pt-2">
              <img
                src="/imgs/revoke.svg"
                className="closeIcon"
                onClick={handleClose}
                alt=""
              />
            </div>
            <Modal.Body>
              <div className="row">
                <div className="col-md-8 mx-auto">
                  <div className="row gx-5">
                    <div className="col-md-12">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Old Password</Form.Label>
                        <InputGroup className="mb-3">
                          <InputGroup.Text id="basic-addon1">
                            <img src="/imgs/password.svg" alt="" />
                          </InputGroup.Text>
                          <Form.Control
                            type={showPass}
                            required={true}
                            value={oldPass}
                            onChange={(e) => setOldPass(e.target.value)}
                          />
                          {showPass === "text" ? (
                            <InputGroup.Text id="basic-addon1">
                              <VisibilityOffIcon
                                fontSize="small"
                                style={{
                                  cursor: "pointer",
                                  color: "lightgray",
                                }}
                                alt=""
                                onClick={showPassword}
                              />
                            </InputGroup.Text>
                          ) : (
                            <InputGroup.Text id="basic-addon1">
                              <VisibilityIcon
                                fontSize="small"
                                style={{
                                  cursor: "pointer",
                                  color: "lightgray",
                                }}
                                alt=""
                                onClick={showPassword}
                              />
                            </InputGroup.Text>
                          )}
                        </InputGroup>
                      </Form.Group>
                    </div>
                    <div className="col-md-12">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>New Password</Form.Label>
                        <InputGroup className="mb-3">
                          <InputGroup.Text id="basic-addon1">
                            <img src="/imgs/password.svg" alt="" />
                          </InputGroup.Text>
                          <Form.Control
                            type={showPass2}
                            required={true}
                            value={newPass}
                            onChange={(e) => setNewPass(e.target.value)}
                          />
                          {showPass2 === "text" ? (
                            <InputGroup.Text id="basic-addon1">
                              <VisibilityOffIcon
                                fontSize="small"
                                style={{
                                  cursor: "pointer",
                                  color: "lightgray",
                                }}
                                alt=""
                                onClick={showPassword2}
                              />
                            </InputGroup.Text>
                          ) : (
                            <InputGroup.Text id="basic-addon1">
                              <VisibilityIcon
                                fontSize="small"
                                style={{
                                  cursor: "pointer",
                                  color: "lightgray",
                                }}
                                alt=""
                                onClick={showPassword2}
                              />
                            </InputGroup.Text>
                          )}
                        </InputGroup>
                      </Form.Group>
                    </div>
                    <div className="col-md-12">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Confirm Password</Form.Label>
                        <InputGroup className="mb-3">
                          <InputGroup.Text id="basic-addon1">
                            <img src="/imgs/password.svg" alt="" />
                          </InputGroup.Text>
                          <Form.Control
                            type={showPass3}
                            required={true}
                            value={CPass}
                            onChange={(e) => setCPass(e.target.value)}
                          />

                          {showPass3 === "text" ? (
                            <InputGroup.Text id="basic-addon1">
                              <VisibilityOffIcon
                                fontSize="small"
                                style={{
                                  cursor: "pointer",
                                  color: "lightgray",
                                }}
                                alt=""
                                onClick={showPassword3}
                              />
                            </InputGroup.Text>
                          ) : (
                            <InputGroup.Text id="basic-addon1">
                              <VisibilityIcon
                                fontSize="small"
                                style={{
                                  cursor: "pointer",
                                  color: "lightgray",
                                }}
                                alt=""
                                onClick={showPassword3}
                              />
                            </InputGroup.Text>
                          )}
                        </InputGroup>
                      </Form.Group>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-sm-flex justify-content-center text-center gap-4">
                <Button
                  className="loginBtn me-sm-0 me-3 mb-sm-0 mb-2 mt-4"
                  onClick={SavePass}
                  disabled={oldPass === "" || newPass === "" ? true : false}
                >
                  Submit
                </Button>
              </div>
            </Modal.Body>
          </Modal>
        </>
      )}
    </div>
  );
}

export default Profile;
