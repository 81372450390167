import React, { useState, useEffect, useLayoutEffect } from "react";
// import Table from "../../../Table/Table";
import { Form, InputGroup } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import DataTable from "react-data-table-component";
import { listing_column } from "../../../utils/constant/column_header_data";
import {
  getArticleDeletedCont,
  deletedArticle,
  reset,
} from "../../../features/Articles/articleSlice";
import { Stack, Rating } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import { formatDate } from "../../../utils/DateHelper";

function DeletedArticle() {
  const user = JSON.parse(localStorage.getItem("user"));

  const ID = user?.data?.user?._id;
  const { article_List, isError, isSuccess, message, isList, imageLocation } =
    useSelector((state) => state.article);
  const { frontData } = useSelector((state) => state.front_Data);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [Search, setSearch] = useState("");

  const [CList, setCList] = useState([]);
  const [DelID, setDelID] = useState("");
  const [DelReason, setDelReason] = useState("");

  useLayoutEffect(() => {
    dispatch(reset());
    dispatch(getArticleDeletedCont(ID));
  }, []);

  useEffect(() => {
    if (article_List) {
      setCList(article_List);
    }
  }, [
    dispatch,
    navigate,
    isSuccess,
    isError,
    message,
    isList,
    imageLocation,
    ID,
    CList,
  ]);
  const handleDelete = async (id) => {
    if (window.confirm("are you sure, you want to delete?")) {
      let result = await dispatch(deletedArticle({ id }));
      console.log(result);
      if (result?.payload?.status === "Success") {
        toast.success(result?.payload?.message);
        setTimeout(() => {
          dispatch(getArticleDeletedCont(ID));
        }, 1000);
      }
    }
  };

  var rows = [];
  if (CList) {
    for (let [i, value] of CList?.entries()) {
      rows[i] = {
        id: value._id,
        title: value?.title,
        author: value?.owner?.first_name + " " + value?.owner?.last_name,
        email: value?.owner?.email,
        submited_at: value?.createdAt
          ? formatDate(value.createdAt, frontData.timezone)
          : "",
        publish: value?.updatedAt
          ? formatDate(value.updatedAt, frontData.timezone)
          : "",
        publish_type: value?.publish_type,
        views_count: value?.count.length > 0 ? value?.count.length : 0,
        share_count: value?.share ? value?.share : 0,
        reviews: (
          <div className="d-flex gap-1 align-items-center">
            {/* <img src="/imgs/star.svg" alt="" /> */}
            <Stack>
              <Rating value={value?.rating} precision={0.5} readOnly />
            </Stack>
            <span>{value?.review ? value?.review : 0}</span>
          </div>
        ),
        action: (
          <div>
            <div className="d-flex gap-3">
              <Link to={`/article/article-detail/${value?._id}`}>
                <img src="/imgs/u_eye.svg" width={20} alt="" />
              </Link>
              <Link to={`/article/article-detail/${value._id}`}>
                <img src="/imgs/t_edit.svg" width={20} alt="" />
              </Link>

              <img
                onClick={() => handleDelete(value?._id)}
                src="/imgs/delete.svg"
                width={20}
                alt=""
              />
            </div>
          </div>
        ),
      };
    }
  }
  console.log(123, rows, CList);

  return (
    <div className="staffMainDiv">
      <ToastContainer />
      <div className="row mx-0 mb-4">
        <div className="d-flex justify-content-between">
          <div className="d-flex gap-3">
            {/* <img src="/imgs/delete.svg" width={20} alt="" onClick={deleteRow}/> */}
            <img
              src="/imgs/revoke.svg"
              width={20}
              alt=""
              onClick={() => setSearch("")}
            />
            <Form.Group className="mb-0">
              <InputGroup className="mb-0">
                <InputGroup.Text id="basic-addon1">
                  <img src="/imgs/search.svg" alt="" />
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  placeholder="Search"
                  value={Search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </InputGroup>
            </Form.Group>
          </div>
          {/* <Link to="/article/article-form">
            <Button className="loginBtn" type="submit">
              Add New
            </Button>
          </Link> */}
        </div>
      </div>
      <DataTable
        columns={listing_column}
        data={
          rows
            ? rows.filter((item) =>
                item.title.toLowerCase().includes(Search.toLowerCase())
              )
            : []
        }
        pagination
      />
    </div>
  );
}

export default DeletedArticle;
