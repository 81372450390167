import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import articleService from "./articleService";
// Get user from localStorage
import axios from "axios";

const API_URL = `${process.env.REACT_APP_BACKEND_ROUTE}/quotation/`;

const initialState = {
  article_List: [],
  article_Detail: {},
  isError: false,
  isSuccess: false,
  review: false,
  isLoading: false,
  credit: false,
  message: "",
  imageLocation: "",
  isList: false,
  articleAdded: false,
  articleUpdated: false,
  articleApproved: false,
};

// get articles list
export const getArticle = createAsyncThunk(
  "article/getArticle",
  async (ID, thunkAPI) => {
    try {
      return await articleService.articleDraftList(ID);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getArticlePending = createAsyncThunk(
  "article/getArticlePending",
  async (ID, thunkAPI) => {
    try {
      return await articleService.getArticlePending(ID);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getArticlePendingAll = createAsyncThunk(
  "article/getArticlePendingAll",
  async (thunkAPI) => {
    try {
      return await articleService.getArticlePendingAll();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const setStatusAsDraft = async (currentUserData) => {
  const data = currentUserData;
  data.status = "Draft";
  const response = await axios.put(API_URL + "edit-quotation", data);
  if (response.data) {
    return response.data;
  }
};
export const addArticle = createAsyncThunk(
  "article/addArticle",
  async (userData, thunkAPI) => {
    try {
      const result = await articleService.addArticleData(userData);
      return result;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const createReview = createAsyncThunk(
  "article/createReview",
  async (Data, thunkAPI) => {
    try {
      return await articleService.createReview(Data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const createReply = createAsyncThunk(
  "article/createReply",
  async (Data, thunkAPI) => {
    try {
      return await articleService.createReply(Data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const publishArticle = createAsyncThunk(
  "article/publishArticle",
  async (ID, thunkAPI) => {
    try {
      return await articleService.publishArticle(ID);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const editArticles = createAsyncThunk(
  "article/editArticles",
  async (userData, thunkAPI) => {
    try {
      const result = await articleService.editArticles(userData);
      return result;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const ApproveArticles = createAsyncThunk(
  "article/ApproveArticles",
  async (artData, thunkAPI) => {
    try {
      return await articleService.ApproveArticles(artData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const RejectArticles = createAsyncThunk(
  "article/RejectArticles",
  async (artData, thunkAPI) => {
    try {
      return await articleService.RejectArticles(artData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getArticleApproved = createAsyncThunk(
  "article/getArticleApproved",
  async (ID, thunkAPI) => {
    try {
      return await articleService.getArticleApproved(ID);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getArticlePublished = createAsyncThunk(
  "article/getArticlePublished",
  async (ID, thunkAPI) => {
    try {
      return await articleService.getArticlePublished(ID);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getArticlePublishedAll = createAsyncThunk(
  "article/getArticlePublishedAll",
  async (thunkAPI) => {
    try {
      return await articleService.getArticlePublishedAll();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updateCount = createAsyncThunk(
  "article/updateCount",
  async (countData, thunkAPI) => {
    try {
      return await articleService.updateCount(countData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getArticleDeleted = createAsyncThunk(
  "article/getArticleDeleted",
  async (thunkAPI) => {
    try {
      return await articleService.getArticleDeleted();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getArticleDeletedCont = createAsyncThunk(
  "article/getArticleDeletedCont",
  async (ID, thunkAPI) => {
    try {
      return await articleService.getArticleDeletedCont(ID);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getArticleRejected = createAsyncThunk(
  "article/getArticleRejected",
  async (ID, thunkAPI) => {
    try {
      return await articleService.getArticleRejected(ID);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getArticleDetail = createAsyncThunk(
  "article/getArticleDetail",
  async (id, thunkAPI) => {
    console.log(9999, id);
    try {
      return await articleService.getArticleDetail(id);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const deletedArticle = createAsyncThunk(
  "article/deletedArticle",
  async (delt, thunkAPI) => {
    try {
      return await articleService.deletedArticle(delt);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const deletedArticleCont = createAsyncThunk(
  "article/deletedArticleCont",
  async (delt, thunkAPI) => {
    try {
      return await articleService.deletedArticleCont(delt);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const deletedRowsArticles = createAsyncThunk(
  "article/deletedRowsArticles",
  async (passData, thunkAPI) => {
    try {
      return await articleService.deletedRowsArticles(passData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const deleteReview = createAsyncThunk(
  "article/deletereview",
  async (passData, thunkAPI) => {
    try {
      return await articleService.deleteReview(passData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const articleSlice = createSlice({
  name: "article",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = "";
      state.imageLocation = "";
      state.isList = false;
      state.articleAdded = false;
      state.articleUpdated = false;
      state.review = false;
    },
    clearList: (state) => {
      state.article_List = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getArticle.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getArticle.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isList = true;
        state.credit = false;
        state.message = action.payload.data.message;
        state.article_List = action.payload.data;
      })
      .addCase(getArticle.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isList = false;
        state.credit = false;
        state.message = action.payload;
        state.article_List = [];
      })

      .addCase(getArticleDetail.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getArticleDetail.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isList = true;
        state.credit = false;
        state.message = action.payload.data.message;
        state.article_Detail = action.payload.data;
      })
      .addCase(getArticleDetail.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isList = false;
        state.credit = false;
        state.message = action.payload;
        state.article_Detail = [];
      })

      .addCase(getArticleApproved.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getArticleApproved.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isList = true;
        state.credit = false;
        state.message = action.payload.data.message;
        state.article_List = action.payload.data;
      })
      .addCase(getArticleApproved.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isList = false;
        state.credit = false;
        state.message = action.payload;
        state.article_List = [];
      })

      .addCase(getArticleDeleted.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getArticleDeleted.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isList = true;
        state.credit = false;
        state.message = action.payload.data.message;
        state.article_List = action.payload.data;
      })
      .addCase(getArticleDeleted.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isList = false;
        state.credit = false;
        state.message = action.payload;
        state.article_List = [];
      })

      .addCase(getArticleDeletedCont.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getArticleDeletedCont.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isList = true;
        state.credit = false;
        state.message = action.payload.data.message;
        state.article_List = action.payload.data;
      })
      .addCase(getArticleDeletedCont.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isList = false;
        state.credit = false;
        state.message = action.payload;
        state.article_List = [];
      })

      .addCase(getArticleRejected.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getArticleRejected.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isList = true;
        state.credit = false;
        state.message = action.payload.data.message;
        state.article_List = action.payload.data;
      })
      .addCase(getArticleRejected.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isList = false;
        state.credit = false;
        state.message = action.payload;
        state.article_List = [];
      })

      .addCase(getArticlePublished.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getArticlePublished.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isList = true;
        state.credit = false;
        state.message = action.payload.data.message;
        state.article_List = action.payload.data;
      })
      .addCase(getArticlePublished.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isList = false;
        state.credit = false;
        state.message = action.payload;
        state.article_List = [];
      })

      .addCase(getArticlePublishedAll.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getArticlePublishedAll.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isList = true;
        state.credit = false;
        state.message = action.payload.data.message;
        state.article_List = action.payload.data;
      })
      .addCase(getArticlePublishedAll.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isList = false;
        state.credit = false;
        state.message = action.payload;
        state.article_List = [];
      })

      .addCase(getArticlePending.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getArticlePending.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isList = true;
        state.credit = false;
        state.message = action.payload.data.message;
        state.article_List = action.payload.data;
      })
      .addCase(getArticlePending.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isList = false;
        state.message = action.payload;
        state.credit = false;
        state.article_List = [];
      })

      .addCase(getArticlePendingAll.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getArticlePendingAll.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isList = true;
        state.credit = false;
        state.message = action.payload.data.message;
        state.article_List = action.payload.data;
      })
      .addCase(getArticlePendingAll.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isList = false;
        state.message = action.payload;
        state.credit = false;
        state.article_List = [];
      })

      .addCase(addArticle.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addArticle.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = action.payload;
        state.credit = true;
        state.articleAdded = true;
        // state.article_List = [...state.article_List, action.payload.data]
      })
      .addCase(addArticle.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.credit = false;
        state.articleAdded = false;
        state.message = action.payload;
        state.article_List = [];
      })

      .addCase(updateCount.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateCount.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = "Count Added!";
        state.credit = false;
        // state.article_List = [...state.article_List, action.payload.data]
      })
      .addCase(updateCount.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.credit = false;
        state.message = action.payload;
        // state.article_List = []
      })

      .addCase(createReview.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createReview.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = action.payload;
        state.credit = false;
        state.review = true;
        const newData = [...state.article_List];
        const Index = newData.findIndex(
          (val) => val._id === action.payload.data._id
        );
        newData[Index] = action.payload.data;
        state.article_List = newData;
        state.article_Detail = action.payload.data;
      })
      .addCase(createReview.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.credit = false;
        state.message = action.payload;
        state.review = false;
        state.article_List = [...state.article_List];
      })
      .addCase(createReply.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createReply.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = action.payload;
        state.credit = false;
        state.review = true;
        const newData = [...state.article_List];
        const Index = newData.findIndex(
          (val) => val._id === action.payload.data._id
        );
        newData[Index] = action.payload.data;
        state.article_List = newData;
        state.article_Detail = action.payload.data;
      })
      .addCase(createReply.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.credit = false;
        state.message = action.payload;
        state.review = false;
        state.article_List = [...state.article_List];
      })

      .addCase(ApproveArticles.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(ApproveArticles.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = "Approved!";
        state.credit = false;
        // state.article_List = [...state.article_List, action.payload.data]
      })
      .addCase(ApproveArticles.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.article_List = [];
        state.credit = false;
      })

      .addCase(RejectArticles.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(RejectArticles.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = "Rejected!";
        state.credit = false;
        // state.article_List = [...state.article_List, action.payload.data]
      })
      .addCase(RejectArticles.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.credit = false;
        state.article_List = [];
      })

      .addCase(publishArticle.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(publishArticle.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = "Published!";
        state.credit = false;
        // state.article_List = [...state.article_List, action.payload.data]
      })
      .addCase(publishArticle.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.credit = false;
        // state.article_List =
      })

      .addCase(editArticles.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(editArticles.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = "Updated!";
        state.credit = false;
        state.articleUpdated = true;
        // state.article_List = action.payload.data
      })
      .addCase(editArticles.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.credit = false;
        state.article_List = [];
      })

      .addCase(deletedArticle.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deletedArticle.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = "Article Deleted!";
        state.credit = false;
        // state.article_List = [...state.article_List]
      })
      .addCase(deletedArticle.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.credit = false;
        state.article_List = [];
      })

      .addCase(deletedArticleCont.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deletedArticleCont.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.credit = false;
        state.message = "Article Deleted!";
        // state.article_List = [...state.article_List]
      })
      .addCase(deletedArticleCont.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.credit = false;
        state.article_List = [];
      })

      //   .addCase(saveImageC.pending, (state) => {
      //     state.isLoading = true
      //   })
      //   .addCase(saveImageC.fulfilled, (state, action) => {
      //     state.isLoading = false
      //     // state.isSuccess = true
      //     // state.message = action.payload
      //     state.imageLocation = action.payload.data
      //     // state.profile_data = action.payload.data
      //   })
      //   .addCase(saveImageC.rejected, (state, action) => {
      //     state.isLoading = false
      //     state.isError = true
      //     state.message = action.payload
      //     state.contributors_List = null
      //   })

      //   .addCase(updatePassC.pending, (state) => {
      //     state.isLoading = true
      //   })
      //   .addCase(updatePassC.fulfilled, (state, action) => {
      //     state.isLoading = false
      //     state.isSuccess = true
      //     state.isError = false
      //     // state.user = action.payload
      //     state.message = action.payload.data
      //   })
      //   .addCase(updatePassC.rejected, (state, action) => {
      //     state.isLoading = false
      //     state.isError = true
      //     state.isSuccess = false
      //     state.message = action.payload
      //     // state.user = null
      //   })

      .addCase(deletedRowsArticles.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deletedRowsArticles.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.credit = false;
        // state.user = action.payload
        state.message = action.payload.data;
      })
      .addCase(deletedRowsArticles.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.credit = false;
        state.message = action.payload;
        // state.user = null
      })
      .addCase(deleteReview.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteReview.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.message = action.payload.message;
        // state.nearestTours = action.payload.data;
      })
      .addCase(deleteReview.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.credit = false;
        state.message = action.payload.message;
        // state.user = null
      });
  },
});

export const { reset, clearList } = articleSlice.actions;
export default articleSlice.reducer;
