import React, { useLayoutEffect, useState } from "react";
import Table from "../../Table/Table";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import { Link, useNavigate } from "react-router-dom";
import { notification_column } from "../../../utils/constant/column_header_data";
import { notification_data } from "../../../utils/constant/column_row_data";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  deletedRowsNotify,
  deleteNotify,
  getNotify,
  readRowsNotify,
  reset,
  updateNotify,
} from "../../../features/Notifications/notifySlice";
import { useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import { Modal } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { formatDate } from "../../../utils/DateHelper";
import moment from "moment";

function Notifications() {
  const { notification, isLoading, isError, isSuccess, message } = useSelector(
    (state) => state.notifyData
  );
  const user = JSON.parse(localStorage.getItem("user"));

  const [Data, setData] = useState([]);
  const [DelID, setDelID] = useState("");
  const [toggledClearRows, setToggleClearRows] = useState(false);
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [selectedRows, setSelectedRows] = useState(false);

  const handleClose = () => {
    setShow(false);
    setShow2(false);
    setDelID("");
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();
  useLayoutEffect(() => {
    dispatch(reset());
    dispatch(getNotify(user?.data?.user?._id));
  }, [dispatch]);

  useEffect(() => {
    if (notification) {
      const data = [...notification].reverse();
      setData(data);
    }

    if (isSuccess == true && message.message !== "") {
      toast.success(message.message);
      dispatch(reset());
    }
    if (isError == true && message !== "") {
      toast.error(message);
      dispatch(reset());
    }
  }, [isError, isSuccess, message]);

  const Readfun = (Id) => {
    dispatch(updateNotify(Id));
  };
  const deleteFun = (Id) => {
    setDelID(Id);
    setShow(true);
  };

  const DelFun = () => {
    if (DelID !== "") {
      dispatch(deleteNotify(DelID));
      setDelID("");
      setShow(false);
      setShow2(false);
    }
  };

  const viewChange = (route) => {
    // window.location.replace(route)
    navigate(route);
  };
  var rows = [];
  console.log(123, Data);
  if (Data) {
    for (let [i, value] of Data?.entries()) {
      rows[i] = {
        id: value._id,
        notif_detail: (
          <div>
            <div className="d-flex gap-2 align-items-center">
              {/* <img src="/imgs/order_dp.png" width={30} alt="" /> */}
              <span>{value?.Details}</span>
            </div>
          </div>
        ),
        time: moment(value?.created_at).format("LT"),
        date: value?.created_at?.substring(0, 10),
        action: (
          <div className="d-flex gap-3">
            <img
              src="/imgs/carbon_view.svg"
              width={20}
              alt=""
              onClick={() => viewChange(value?.route)}
            />
            {value?.isRead == false ? (
              <>
                <img
                  src="/imgs/unread.png"
                  width={20}
                  alt=""
                  onClick={() => Readfun(value._id)}
                />
              </>
            ) : (
              <>
                <img src="/imgs/mail.svg" width={20} alt="" />
              </>
            )}
            <img
              src="/imgs/del.svg"
              width={20}
              alt=""
              onClick={() => deleteFun(value?._id)}
            />
          </div>
        ),
      };
    }
  }

  const delRows = () => {
    setShow2(true);
  };

  const deleteRow = () => {
    let yFilter = selectedRows.map((itemY) => {
      return itemY.id;
    });
    let filteredX = notification.filter(
      (itemX) => !yFilter.includes(itemX._id)
    );
    const data = {
      Ids: yFilter,
    };
    dispatch(deletedRowsNotify(data));
    setDelID("");
    setSelectedRows([]);
    setShow(false);
    setShow2(false);
  };

  const readRows = () => {
    let yFilter = selectedRows.map((itemY) => {
      return itemY.id;
    });
    let filteredX = notification.filter(
      (itemX) => !yFilter.includes(itemX._id)
    );
    const data = {
      Ids: yFilter,
    };
    dispatch(readRowsNotify(data));
    setDelID("");
    setSelectedRows([]);
  };

  const handleChange = ({ selectedRows }) => {
    setSelectedRows(selectedRows);
  };
  return (
    <div className="staffMainDiv">
      <ToastContainer />
      <div className="row mx-0 mb-4">
        <div className="d-flex justify-content-between">
          <div className="d-flex gap-3">
            <img
              src="/imgs/mail.svg"
              width={23}
              alt=""
              style={{ cursor: "pointer" }}
              onClick={() => readRows()}
            />
            <img
              src="/imgs/del.svg"
              width={20}
              alt=""
              style={{ cursor: "pointer" }}
              onClick={() => delRows()}
            />
          </div>
        </div>
      </div>
      <DataTable columns={notification_column} data={rows} pagination />
      <Modal
        show={show == true ? show : show2}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Notification deleted?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this notification?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={() => (show == true ? DelFun() : deleteRow())}
          >
            Sure
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Notifications;
