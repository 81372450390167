import React, { useState } from "react";
import Data from "../../../../utils/data/reviewrsData.json";
import Accordion from "react-bootstrap/Accordion";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { useEffect } from "react";
import DOMPurify from "dompurify";
import _ from "lodash";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import {
  createReview,
  reset,
  deleteReview,
} from "../../../../features/Articles/articleSlice";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
// import Rating from "./Rating";
import Rating from "@mui/material/Rating";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import StarIcon from "@mui/icons-material/Star";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
import { toast, ToastContainer } from "react-toastify";
import ShareButtons from "../../../share";
import axios from "axios";
import { v4 as uuid } from "uuid";
import {
  getArticleDetail,
  createReply,
} from "../../../../features/Articles/articleSlice";
import Header from "../../Header/Header";
import { useParams } from "react-router-dom";

const BlogDetails = ({ Profile, setProfile }) => {
  const { article_Detail } = useSelector((state) => state.article);
  const user = JSON.parse(localStorage.getItem("user"));
  const ROLE = user?.data?.user?.role;
  const {
    profile_data,
    isLoading,
    isError: isErrorAuth,
    isSuccess: isSuccessAuth,
    message: messageAuth,
  } = useSelector((state) => state.profileData);

  const [showModel, setShowModel] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  let { id } = useParams();

  const [rating, setRating] = useState(0);
  const [name, setName] = useState("");
  const [comment, setComment] = useState("");
  const [coverImages, setCoverImages] = useState([]);
  const [Image, setImage] = useState("");
  const [show, setShow] = useState(false);
  const [fetch, setFetch] = useState(false);
  const [reply, setReply] = useState([{}]);

  const dispatch = useDispatch();
  const currentUrl = window.location.href;

  const deleteImg = (ID) => {
    const newData = [...coverImages];

    const data = newData.filter((s) => s.id !== ID);
    setCoverImages(data);
  };

  const uploadFileHandler = async (e) => {
    const file = e.target.files[0];
    const bodyFormData = new FormData();
    bodyFormData.append("image", file);

    const ImgRes = await axios.put(
      `${process.env.REACT_APP_BACKEND_ROUTE}/article/uploads/s3`,
      bodyFormData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    const ID = uuid().slice(0, 8);
    const newData = {
      id: ID,
      img: ImgRes.data.data,
      cover: false,
    };
    const data = [...coverImages, newData];
    setCoverImages(data);
  };

  const CloseModal = () => {
    setShowModel(false);
  };
  useEffect(() => {
    dispatch(getArticleDetail(id));
  }, [fetch]);

  const createMarkup = (html) => {
    return { __html: html };
  };

  const submitHandler1 = async () => {
    if (comment && rating) {
      let result = await dispatch(
        createReview({
          ID: article_Detail?._id,
          rating,
          comment,
          userId: user?.data?.user?._id,
          reviewImages: coverImages,
        })
      );

      if (result?.payload?.status === "Success") {
        toast.success(result?.payload?.message);
      }
      // await dispatch(getArticlePublishedAll());
      setRating(0);
      setName("");
      setComment("");
      setShowModel(false);
      setCoverImages([]);
      setFetch(!fetch);
    }
  };

  const handleCloses = () => {
    setShow(false);
  };
  const handleShow = (img) => {
    setImage(img);
    setShow(true);
  };

  const deleteRev = async ({ _id }) => {
    if (window.confirm("are you sure, you want to delete?")) {
      let result = await dispatch(deleteReview({ reviewId: _id, all: false }));
      if (result?.payload?.status === "Success") {
        toast.success(result?.payload?.message);
      }
      setFetch(!fetch);
      // handleClose();
    }
  };
  const handleReplyChange = (e, index, id) => {
    const { value } = e.target;
    setReply((prevReply) => {
      const updatedReply = [...prevReply];
      updatedReply[index] = { id, value };
      return updatedReply;
    });
  };
  const addReply = async (articleId, reviewId) => {
    const data = {
      userId: user?.data?.user?._id,
      name: user?.data?.user?.role,
      comment: reply?.filter((item) => item.id == reviewId)[0]?.value,
      articleId: articleId,
      reviewId: reviewId,
    };
    let result = await dispatch(createReply(data));
    if (result?.payload?.status === "Success") {
      toast.success(result?.payload?.message);
      setFetch(!fetch);
    }
    dispatch(reset());
    // await dispatch(getArticlePublishedAll());
    // handleClose();
    // setReply([{}]);
    // setFetch(!fetch);
  };
  const handleDeleteReply = async (reviewId, replyId) => {
    if (window.confirm("are you sure, you want to delete?")) {
      let result = await dispatch(
        deleteReview({ reviewId, replyId, all: false })
      );
      if (result?.payload?.status === "Success") {
        toast.success(result?.payload?.message);
        setFetch(!fetch);
      }
    }
  };
  console.log(reply);
  return (
    <div>
      <ToastContainer />
      <Header
        user={user}
        userProfile={profile_data}
        setProfile={setProfile}
        Profile={Profile}
      />
      {Object.keys(article_Detail).length > 0 && (
        <>
          <div className="pb-5">
            <div className="reviewMain w-100">
              <div className="detailAboutBlog">
                <div className="detailBlogImg">
                  {Object.keys(article_Detail).length > 0 &&
                  article_Detail?.cover_images?.some((val) => val?.cover) ? (
                    article_Detail?.cover_images?.map(
                      (val) =>
                        val?.cover == true && (
                          <img src={val?.img} className="w-100 h-100" alt="" />
                        )
                    )
                  ) : (
                    <img
                      src={article_Detail?.cover_images[0]?.img}
                      className="w-100 h-100"
                      alt=""
                    />
                  )}
                </div>
                <div className="container mt-5">
                  <div className="d-flex justify-content-between align-items-center gap-3 my-3">
                    <div>
                      <h2 style={{ marginBottom: 0 }}>
                        {article_Detail?.title}
                      </h2>
                      <h6 style={{ opacity: 0.7 }}>
                        author:{" "}
                        {article_Detail?.owner?.first_name +
                          " " +
                          article_Detail?.owner?.last_name}
                      </h6>
                    </div>
                    <div className="d-flex align-items-center gap-4 my-4 mx-1">
                      <div className="d-flex gap-2">
                        <img src="/imgs/calender.svg" alt="" />
                        <p className="p-0 m-0">
                          {article_Detail?.createdAt.substring(0, 10)}
                        </p>
                      </div>
                      <span className="vr"></span>
                      <div className="d-flex gap-2">
                        <img src="/imgs/comment.svg" alt="" />
                        <p className="p-0 m-0">
                          {article_Detail?.reviews?.length} COMMENTS
                        </p>
                      </div>
                      <span className="vr"></span>
                      <div className="d-flex gap-2">
                        <ShareButtons url={currentUrl} title={currentUrl} />
                      </div>
                    </div>
                  </div>
                  <section>
                    <div className="description">
                      <img
                        src={article_Detail?.owner?.profile}
                        alt=""
                        className="me-4 mb-3 profileImgInner rounded-circle"
                        width={60}
                        height={60}
                      />
                      <p
                        style={{ textAlign: "justify" }}
                        className="dangerous"
                        dangerouslySetInnerHTML={createMarkup(
                          article_Detail?.description
                        )}
                      ></p>
                    </div>
                  </section>
                </div>
              </div>
            </div>
            <div className="cardSection articleDetail my-5 container">
              <Accordion defaultActiveKey="0" style={{ overflowY: "auto" }}>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>Reviews</Accordion.Header>
                  <Accordion.Body>
                    <div className="ratingReviews">
                      <div className="d-flex justify-content-between align-items-center gap-4 p-3">
                        <div className="d-sm-flex gap-4">
                          <Form.Group className="" controlId="formBasicEmail">
                            <InputGroup
                              className=""
                              style={{ background: "#F1F1F1" }}
                            >
                              <InputGroup.Text id="basic-addon1">
                                <Stack spacing={1}>
                                  <Rating
                                    name="half-rating"
                                    value={article_Detail?.rating}
                                    precision={0.5}
                                    readOnly
                                  />
                                </Stack>
                              </InputGroup.Text>
                              <Form.Control
                                aria-label="Default select example"
                                disabled={true}
                                value={
                                  article_Detail?.rating
                                    ? `${parseInt(
                                        article_Detail?.rating
                                      )} Stars`
                                    : "0 Star"
                                }
                              />
                            </InputGroup>
                          </Form.Group>
                          <p className="d-flex gap-2 mt-sm-0 mt-3">
                            <span>
                              {" "}
                              {article_Detail?.rating
                                ? Math.round(article_Detail?.rating * 10) / 10
                                : 0}
                            </span>
                            <div>
                              Total Reviews <br />{" "}
                              {article_Detail?.review
                                ? article_Detail?.review
                                : 0}
                            </div>
                          </p>
                        </div>
                        <Button
                          className="loginBtn"
                          type="submit"
                          onClick={() => setShowModel(true)}
                        >
                          Add New
                        </Button>
                      </div>
                    </div>
                    {article_Detail?.reviews?.map((item, index) => {
                      return (
                        <>
                          <div className="d-flex gap-3 reviewrsList align-items-center d-flex justify-content-between">
                            <div className="align-items-center d-flex justify-content-between gap-3">
                              <img
                                src={item?.userId?.profile}
                                alt="User Profile Image"
                                style={{ borderRadius: "50%" }}
                              />
                              <div>
                                <h3 className="name">
                                  {item?.userId?.first_name}{" "}
                                  {item?.userId?.last_name}
                                </h3>
                                <p className="description">{item.comment}</p>
                              </div>
                            </div>

                            <div className="data">
                              <div className="d-flex gap-5">
                                <div className="rating">
                                  <Stack spacing={1}>
                                    <Rating
                                      name="half-rating"
                                      value={item?.rating}
                                      precision={0.5}
                                      readOnly
                                    />
                                  </Stack>
                                  {(user?.data?.user?._id ===
                                    item?.userId?._id ||
                                    user?.data?.user?.role == "admin" ||
                                    user?.data?.user?.role == "staff") && (
                                    <img
                                      src="/imgs/delete.svg"
                                      onClick={() => deleteRev(item)}
                                      style={{ width: "44px", height: "22px" }}
                                    />
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="d-flex reviewrsList align-items-center flex-wrap gap-1">
                            {item?.reviewImages?.map((val) => (
                              <>
                                <div id="imagePreview1">
                                  <img
                                    id="saveImageFile"
                                    onClick={() => handleShow(val.img)}
                                    src={val.img}
                                    alt={val.img}
                                  />
                                </div>
                              </>
                            ))}
                          </div>
                          <div className="m-4 me-0">
                            {item?.replies.map((val, i) => (
                              <>
                                <div className="d-flex align-items-center gap-2">
                                  <div>
                                    <img
                                      src={val?.userId?.profile}
                                      style={{
                                        width: "30px",
                                        height: "30px",
                                        borderRadius: "50%",
                                      }}
                                      alt=""
                                    />{" "}
                                  </div>
                                  <div>
                                    <h5> {val?.name}</h5>
                                    <div className="d-flex align-items-center gap-1">
                                      <h6>
                                        {val?.createdAt?.substring(0, 10)}
                                      </h6>
                                    </div>
                                  </div>
                                  <div className="align-self-end ms-auto">
                                    <img
                                      src="/imgs/delete.svg"
                                      width={20}
                                      style={{
                                        height: "24px",
                                      }}
                                      alt=""
                                      onClick={() =>
                                        handleDeleteReply(item._id, val?._id)
                                      }
                                    />
                                  </div>
                                </div>
                                <p>{val?.comment}</p>
                                {/* <p>reply by: {item?.name}</p>
                              <p>{val?._id}</p> */}
                              </>
                            ))}
                          </div>
                          {(ROLE == "admin" || ROLE == "staff") && (
                            <div className="replyDiv">
                              <div className="d-flex justify-content-between align-items-center">
                                <div className="d-flex align-items-center w-100">
                                  {/* <img src="/imgs/profile.png" alt="" /> */}
                                  <Form.Control
                                    className="border-0 w-100"
                                    type="text"
                                    name="first_name"
                                    placeholder="Reply"
                                    value={reply[index]?.value || ""}
                                    onChange={(e) =>
                                      handleReplyChange(e, index, item._id)
                                    }
                                  />
                                </div>
                                <Button
                                  className="loginBtn"
                                  onClick={() =>
                                    addReply(article_Detail._id, item._id)
                                  }
                                >
                                  Reply
                                </Button>
                              </div>
                            </div>
                          )}
                        </>
                      );
                    })}
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
              <div className="seperator"></div>
            </div>
          </div>
        </>
      )}

      <Modal
        show={showModel}
        onHide={CloseModal}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Rating & Reviews</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div>
              <Box>
                <Rating
                  name="simple-controlled"
                  value={rating}
                  size="large"
                  onChange={(event, newValue) => {
                    setRating(newValue);
                  }}
                  sx={{
                    "& .MuiRating-iconFilled": {
                      color: "orange",
                    },
                  }}
                  icon={
                    <StarIcon
                      style={{ width: "30px", height: "30px" }}
                    ></StarIcon>
                  }
                  emptyIcon={
                    <StarOutlineIcon
                      style={{ width: "30px", height: "30px" }}
                    ></StarOutlineIcon>
                  }
                />
              </Box>
            </div>
            <br />
            <div>
              <input
                type={"text"}
                className="form-control"
                placeholder="Add Name"
                value={user?.data?.user?.user_name}
              />
            </div>
            <br />
            <div>
              <textarea
                className="form-control"
                rows="4"
                value={comment}
                placeholder="Write Comments here..."
                onChange={(e) => setComment(e.target.value)}
              ></textarea>
            </div>
          </div>
          <h6 className="mt-3 mb-2">Share Your Photo Review</h6>
          <div className="image-placeholder flex-wrap">
            {coverImages !== [] &&
              coverImages?.map((val) => (
                <>
                  <div className="avatar-preview">
                    <img
                      src="/imgs/delImg.svg"
                      className="delImg"
                      alt=""
                      onClick={() => deleteImg(val.id)}
                    />

                    <div id="imagePreview">
                      <img id="saveImageFile" src={val.img} alt={val.img} />
                    </div>
                  </div>
                </>
              ))}
            {
              <div className="avatar-edit">
                <img src="/imgs/addImg.png" alt="" />
                <input
                  type="file"
                  // onChange={fileHandler}
                  id="imageUpload"
                  onChange={uploadFileHandler}
                />
                <label htmlFor="imageUpload" name=""></label>
              </div>
            }
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={CloseModal}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={submitHandler1}
            disabled={(rating && comment) === "" ? true : false}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={show}
        onHide={handleCloses}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Preview Image</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="imagePreviewModal">
            <img id="saveImageFile" src={Image} alt={Image} />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloses}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default BlogDetails;
