import React from "react";
import { useEffect } from "react";
import { useLayoutEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { getProfile, reset } from "../../../features/MyProfile/profileSlice";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import DOMPurify from "dompurify";

function AboutUs({ setProfile, Profile }) {
  const user = JSON.parse(localStorage.getItem("user"));
  const {
    profile_data,
    isLoading,
    isError: isErrorAuth,
    isSuccess: isSuccessAuth,
    message: messageAuth,
  } = useSelector((state) => state.profileData);
  const { state } = useLocation();
  const dispatch = useDispatch();
  useLayoutEffect(() => {
    dispatch(reset());
    if (user?.data.user._id) {
      dispatch(getProfile(user?.data.user._id));
      // dispatch(updateLastLogin(ID));
    }
  }, []);

  useEffect(() => {
    if (user?.data.user._id) {
      dispatch(getProfile(user?.data.user._id));
      // dispatch(updateLastLogin(ID));
    }
  }, [isErrorAuth, isSuccessAuth, messageAuth]);

  const sanitizedData = (data) => ({
    __html: DOMPurify.sanitize(data),
  });
  return (
    <div className="aboutUsMain">
      <Header
        user={user}
        userProfile={profile_data}
        setProfile={setProfile}
        Profile={Profile}
      />
      <div className="banner">
        <div className="container-fluid px-50px">
          <div className="row mx-0 h-500px">
            <div className="col-md-7 mx-auto text-center my-auto">
              <h1>About Us</h1>
            </div>
          </div>
        </div>
      </div>
      <div className="ourMission">
        <div className="container-fluid py-5 px-50px">
          <div className="row gx-5">
            <div className="col-md-12">
              {/* <h1>Our Mission</h1> */}
              <p>
                <div dangerouslySetInnerHTML={sanitizedData(state?.About)} />
              </p>
            </div>
            {/* <div className="col-md-6">
              <img src="/imgs/aboutus.png" className="w-100" alt="" />
            </div> */}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default AboutUs;
