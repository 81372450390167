import React, { useEffect, useLayoutEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import {
  addStaff,
  editStaff,
  getStaff,
  reset,
  resetImage,
  saveImageS,
  updatePassS,
} from "../../../../features/staffs/staffSlice";
import StaffEditForm from "./StaffEditForm";
import StaffForm from "./StaffForm";
import validator from "validator";
import PermissionTbl from "./_part/PermissionTbl";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { useSnackbar } from "notistack";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";

function AddStaff() {
  const userID = useParams();
  console.log(userID);
  const [Details, setDetails] = useState({});
  const [oldPass, setOldPass] = useState("");
  const [newPass, setNewPass] = useState("");
  const [CPass, setCPass] = useState("");
  const [show, setShow] = useState(false);
  const [showPass, setShowPass] = useState("password");
  const [showPass2, setShowPass2] = useState("password");
  const [showPass3, setShowPass3] = useState("password");
  const [Edit, setEdit] = useState(false);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const handleClose = () => {
    setShow(false);
    setOldPass("");
    setNewPass("");
    setCPass("");
    setShowPass("password");
  };
  const handleShow = () => setShow(true);

  const {
    Staff_List,
    isError,
    message,
    isSuccess,
    isLoading,
    imageLocation,
    isList,
  } = useSelector((state) => state.staff);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [addFormData, setAddFormData] = useState({
    first_name: "",
    last_name: "",
    user_name: "",
    dob: "",
    // national_id: "",
    gender: "male",
    profile: "",
    status: "",
    password: "",
    email: "",
    contact_no: "",
    country: "",
    state: "",
    city: "",
    postal_code: "",
    address: "",
    permissions: [],
  });

  const [editFormData, setEditFormData] = useState({
    first_name: "",
    last_name: "",
    user_name: "",
    dob: "",
    gender: "",
    profile: "",
    status: "",
    // password: '',
    email: "",
    contact_no: "",
    country: "",
    state: "",
    city: "",
    postal_code: "",
    address: "",
    permissions: [],
  });

  useLayoutEffect(() => {
    dispatch(reset());
    dispatch(getStaff());
  }, []);
  useEffect(() => {
    if (userID.id) {
      if (isList == true) {
        const newData = [...Staff_List];
        var data = newData?.filter((user) => user._id == userID.id);
        setDetails(data[0]);
      }
    }
  }, [Details, userID, Staff_List]);

  useEffect(() => {
    if (
      (addFormData.first_name &&
        addFormData.last_name &&
        addFormData.user_name &&
        addFormData.email) !== ""
    ) {
      setEdit(true);
    } else {
      setEdit(false);
    }

    if (isSuccess == true && message.message !== "") {
      toast.success(message.message ? message.message : message);
      setTimeout(() => {
        navigate(-1);
        dispatch(reset());
        dispatch(resetImage());
      }, 1500);
    }
    if (isError == true) {
      toast.error(message);
      dispatch(reset());
    }
  }, [
    dispatch,
    userID,
    isError,
    message,
    isSuccess,
    imageLocation,
    isList,
    addFormData,
    Edit,
  ]);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
  } = useForm({});

  const selectAll = watch("selectAll");

  const SavePass = () => {
    const passData = {
      oldPass,
      newPass,
      CPass,
      id: userID.id,
    };
    if (newPass === CPass) {
      const reg = /(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/;
      if (reg.test(CPass)) {
        dispatch(updatePassS(passData));
        setShowPass("password");
      } else {
        toast.error(
          "Password must have at least 1 capital letter, 1 number and be a minimum of 8 characters"
        );
      }
    } else {
      toast.error("Password and confirm password did not match!");
      // toast.error("Password did not match!");
    }
  };

  const handleAddFormChange = (event) => {
    if (event.type === "change") {
      event.preventDefault();
      const fieldName = event.target.getAttribute("name");
      const fieldValue = event.target.value;
      const newFormData = { ...addFormData };
      newFormData[fieldName] = fieldValue;
      setAddFormData(newFormData);
    } else {
      const newFormData = { ...addFormData };
      newFormData["contact_no"] = event;
      setAddFormData(newFormData);
    }
  };

  // const onSubmit = data => alert(JSON.stringify(data));

  const defaultImage =
    "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_640.png";

  const AddForm = (data) => {
    // event.preventDefault();

    const userData = {
      first_name: addFormData.first_name,
      last_name: addFormData.last_name,
      user_name: addFormData.user_name,
      dob: addFormData.dob,
      // national_id: addFormData.national_id,
      gender: addFormData.gender,
      profile: imageLocation !== "" ? imageLocation : defaultImage,
      status: addFormData.status,
      password: addFormData.password,
      email: addFormData.email,
      contact_no: addFormData.contact_no,
      country: addFormData.country,
      state: addFormData.state,
      city: addFormData.city,
      postal_code: addFormData.postal_code,
      address: addFormData.address,
      role: "staff",
      permissions: data,
    };
    const reg = /(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/;
    if (reg.test(userData.password)) {
      dispatch(addStaff(userData));
    } else {
      toast.error(
        "Password must have at least 1 capital letter, 1 number and be a minimum of 8 characters"
      );
    }

    // navigate(-1)
  };

  const handleEditFormChange = (event) => {
    if (event.type === "change") {
      event.preventDefault();
      const fieldName = event.target.getAttribute("name");

      const fieldValue = event.target.value;
      const newFormData = { ...editFormData };
      newFormData[fieldName] = fieldValue;
      setEditFormData(newFormData);
    } else {
      const newFormData = { ...editFormData };
      newFormData["contact_no"] = event;
      setEditFormData(newFormData);
    }
    // event.preventDefault();

    // const fieldName = event.target.getAttribute("name");
    // const fieldValue = event.target.value;

    // const newFormData = { ...editFormData };
    // newFormData[fieldName] = fieldValue;

    // setEditFormData(newFormData);
  };

  const UpdateForm = (data) => {
    // event.preventDefault();
    const userData = {
      id: userID.id,
      first_name: editFormData.first_name,
      last_name: editFormData.last_name,
      user_name: editFormData.user_name,
      dob: editFormData.dob,
      // national_id: editFormData.national_id,
      gender: editFormData.gender,
      profile: imageLocation ? imageLocation : editFormData.profile,
      status: editFormData.status,
      // password: editFormData.password,
      email: editFormData.email,
      contact_no: editFormData.contact_no,
      country: editFormData.country,
      state: editFormData.state,
      city: editFormData.city,
      postal_code: editFormData.postal_code,
      address: editFormData.address,
      role: "staff",
      permissions: data,
    };
    dispatch(editStaff(userData));
    // navigate(-1);
  };

  const uploadFileHandler = (e) => {
    const file = e.target.files[0];
    const bodyFormData = new FormData();
    bodyFormData.append("image", file);

    dispatch(saveImageS(bodyFormData));
  };

  const showPassword = () => {
    if (showPass === "password") {
      setShowPass("text");
    }
    if (showPass === "text") {
      setShowPass("password");
    }
  };
  const showPassword2 = () => {
    if (showPass2 === "password") {
      setShowPass2("text");
    }
    if (showPass2 === "text") {
      setShowPass2("password");
    }
  };
  const showPassword3 = () => {
    if (showPass3 === "password") {
      setShowPass3("text");
    }
    if (showPass3 === "text") {
      setShowPass3("password");
    }
  };
  console.log(Details);

  return (
    <div>
      <ToastContainer />
      <button
        className="loginBtn"
        style={{ marginBottom: "10px" }}
        onClick={() => navigate(-1)}
      >
        Go back
      </button>
      {Object.keys(userID).length === 0 && userID.constructor === Object ? (
        <StaffForm
          AddForm={AddForm}
          addFormData={addFormData}
          handleAddFormChange={handleAddFormChange}
          handleSubmit={handleSubmit}
          register={register}
          watch={watch}
          uploadFileHandler={uploadFileHandler}
          imageLocation={imageLocation}
          showPassword={showPassword}
          showPass={showPass}
          Edit={Edit}
          selectAll={selectAll}
        />
      ) : (
        <StaffEditForm
          UpdateForm={UpdateForm}
          handleEditFormChange={handleEditFormChange}
          Details={Details}
          editFormData={editFormData}
          setEditFormData={setEditFormData}
          handleSubmit={handleSubmit}
          register={register}
          watch={watch}
          uploadFileHandler={uploadFileHandler}
          imageLocation={imageLocation}
          handleShow={handleShow}
          showPassword={showPassword}
          showPass={showPass}
          setValue={setValue}
          Edit={Edit}
        />
      )}

      <Modal show={show} onHide={handleClose} centered>
        <div className="d-flex justify-content-end pe-2 pt-2">
          <img
            src="/imgs/revoke.svg"
            className="closeIcon"
            onClick={handleClose}
            alt=""
          />
        </div>
        <Modal.Body>
          <div className="row">
            <div className="col-md-8 mx-auto">
              <div className="row gx-5">
                <div className="col-md-12">
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Old Password</Form.Label>
                    <InputGroup className="mb-3">
                      <InputGroup.Text id="basic-addon1">
                        <img src="/imgs/password.svg" alt="" />
                      </InputGroup.Text>
                      <Form.Control
                        type={showPass}
                        required={true}
                        value={oldPass}
                        onChange={(e) => setOldPass(e.target.value)}
                      />
                      {showPass === "text" ? (
                        <InputGroup.Text id="basic-addon1">
                          <VisibilityOffIcon
                            fontSize="small"
                            style={{ cursor: "pointer", color: "lightgray" }}
                            alt=""
                            onClick={showPassword}
                          />
                        </InputGroup.Text>
                      ) : (
                        <InputGroup.Text id="basic-addon1">
                          <VisibilityIcon
                            fontSize="small"
                            style={{ cursor: "pointer", color: "lightgray" }}
                            alt=""
                            onClick={showPassword}
                          />
                        </InputGroup.Text>
                      )}
                    </InputGroup>
                  </Form.Group>
                </div>
                <div className="col-md-12">
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>New Password</Form.Label>
                    <InputGroup className="mb-3">
                      <InputGroup.Text id="basic-addon1">
                        <img src="/imgs/password.svg" alt="" />
                      </InputGroup.Text>
                      <Form.Control
                        type={showPass2}
                        required={true}
                        value={newPass}
                        onChange={(e) => setNewPass(e.target.value)}
                      />
                      {showPass2 === "text" ? (
                        <InputGroup.Text id="basic-addon1">
                          <VisibilityOffIcon
                            fontSize="small"
                            style={{ cursor: "pointer", color: "lightgray" }}
                            alt=""
                            onClick={showPassword2}
                          />
                        </InputGroup.Text>
                      ) : (
                        <InputGroup.Text id="basic-addon1">
                          <VisibilityIcon
                            fontSize="small"
                            style={{ cursor: "pointer", color: "lightgray" }}
                            alt=""
                            onClick={showPassword2}
                          />
                        </InputGroup.Text>
                      )}
                    </InputGroup>
                  </Form.Group>
                </div>
                <div className="col-md-12">
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Confirm Password</Form.Label>
                    <InputGroup className="mb-3">
                      <InputGroup.Text id="basic-addon1">
                        <img src="/imgs/password.svg" alt="" />
                      </InputGroup.Text>
                      <Form.Control
                        type={showPass3}
                        required={true}
                        value={CPass}
                        onChange={(e) => setCPass(e.target.value)}
                      />

                      {showPass3 === "text" ? (
                        <InputGroup.Text id="basic-addon1">
                          <VisibilityOffIcon
                            fontSize="small"
                            style={{ cursor: "pointer", color: "lightgray" }}
                            alt=""
                            onClick={showPassword3}
                          />
                        </InputGroup.Text>
                      ) : (
                        <InputGroup.Text id="basic-addon1">
                          <VisibilityIcon
                            fontSize="small"
                            style={{ cursor: "pointer", color: "lightgray" }}
                            alt=""
                            onClick={showPassword3}
                          />
                        </InputGroup.Text>
                      )}
                    </InputGroup>
                  </Form.Group>
                </div>
              </div>
            </div>
          </div>
          <div className="d-sm-flex justify-content-center text-center gap-4">
            <Button
              className="loginBtn me-sm-0 me-3 mb-sm-0 mb-2 mt-4"
              onClick={SavePass}
              disabled={oldPass === "" || newPass === "" ? true : false}
            >
              Submit
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default AddStaff;
