import React, { useState, useEffect, useLayoutEffect } from "react";
import { Form, InputGroup } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { delete_article_column } from "../../../../utils/constant/column_header_data";
import { useSelector, useDispatch } from "react-redux";
import {
  getArticleDeleted,
  reset,
  deletedArticle,
} from "../../../../features/Articles/articleSlice";
import DataTable from "react-data-table-component";
import { Rating, Stack } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import { formatDate } from "../../../../utils/DateHelper";

function Deleted() {
  const user = JSON.parse(localStorage.getItem("user"));
  const ID = user?.data?.user?._id;
  const { article_List, isError, isSuccess, message, isList, imageLocation } =
    useSelector((state) => state.article);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [Search, setSearch] = useState("");
  const [selectedRows, setSelectedRows] = useState(false);
  const [CList, setCList] = useState([]);

  useLayoutEffect(() => {
    // dispatch(clearList())
    dispatch(reset());
    dispatch(getArticleDeleted());
  }, []);

  useEffect(() => {
    // dispatch(getArticlePending(ID))

    if (article_List) {
      setCList(article_List);
    }
  }, [
    dispatch,
    navigate,
    isSuccess,
    isError,
    message,
    isList,
    imageLocation,
    ID,
    CList,
  ]);
  const handleDelete = async (id) => {
    if (window.confirm("are you sure, you want to delete?")) {
      let result = await dispatch(deletedArticle({ id }));
      if (result?.payload?.status === "Success") {
        toast.success(result?.payload?.message);
        setTimeout(() => {
          dispatch(getArticleDeleted());
        }, 1000);
      }
    }
  };
  var rows = [];
  if (CList) {
    for (let [i, value] of CList?.entries()) {
      rows[i] = {
        id: value._id,
        title: value.title,
        email: value?.owner?.email,
        author: value?.owner?.first_name + " " + value?.owner?.last_name,
        submited_at: formatDate(value?.createdAt?.substring(0, 10)),
        publish: formatDate(value?.updatedAt?.substring(0, 10)),
        publish_type: value.publish_type,
        views_count: value.count ? value.count.length : 0,
        share_count: value.share ? value.share : 0,
        reviews: (
          <div className="d-flex gap-1 align-items-center">
            {/* <img src="/imgs/star.svg" alt="" /> */}
            <Stack>
              <Rating value={value?.rating} precision={0.5} readOnly />
            </Stack>
            <span>{value?.review ? value.review : 0}</span>
          </div>
        ),
        action: (
          <div>
            <div className="d-flex gap-3">
              {((user?.data?.user?.role === "staff" &&
                user?.data?.user?.permissions[0]?.ArticlesReviews?.includes(
                  "view"
                )) ||
                user?.data?.user?.role === "admin") && (
                <Link to={`/article/article-detail/${value._id}`}>
                  <img src="/imgs/u_eye.svg" width={20} alt="" />
                </Link>
              )}

              <img
                src="/imgs/delete.svg"
                width={20}
                alt=""
                onClick={() => handleDelete(value?._id)}
              />
            </div>
          </div>
        ),
      };
    }
    rows = rows.sort((a, b) => {
      if (b.publish > a.publish) return 1;
      if (b.publish < a.publish) return -1;
    });
  }

  const handleChange = ({ selectedRows }) => {
    setSelectedRows(selectedRows);
  };

  return (
    <div className="staffMainDiv">
      <ToastContainer />
      <div className="row pb-3 px-3">
        <div className="d-flex justify-content-between align-items-center">
          <h1 className="innerTitle">Deleted</h1>
        </div>
      </div>
      <div
        className="row"
        style={{ marginLeft: "-20px", marginRight: "-20px" }}
      >
        <hr />
      </div>
      <div className="row mx-0 mb-4">
        <div className="d-flex justify-content-between">
          <div className="d-flex gap-3">
            <img
              src="/imgs/revoke.svg"
              width={20}
              alt=""
              onClick={() => setSearch("")}
            />
            <Form.Group className="mb-0">
              <InputGroup className="mb-0">
                <InputGroup.Text id="basic-addon1">
                  <img src="/imgs/search.svg" alt="" />
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  placeholder="Search"
                  value={Search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </InputGroup>
            </Form.Group>
          </div>
        </div>
      </div>
      <DataTable
        columns={delete_article_column}
        data={
          rows
            ? rows.filter(
                (item) =>
                  item?.title?.toLowerCase().includes(Search.toLowerCase()) ||
                  item?.user_name
                    ?.toLowerCase()
                    .includes(Search.toLowerCase()) ||
                  item?.email?.toLowerCase().includes(Search.toLowerCase()) ||
                  item?.author?.toLowerCase().includes(Search.toLowerCase())
              )
            : []
        }
        pagination
      />
    </div>
  );
}

export default Deleted;
