import React, { useEffect, useLayoutEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import {
  addPlans,
  editPlans,
  getPlans,
  reset,
} from "../../../../features/ProductPlans/productSlice";
import PlanEditForm from "./PlanEditForm";
import PlanForm from "./PlanForm";
import validator from "validator";
import { useSnackbar } from "notistack";

function AddProductPlan() {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const userID = useParams();
  const [Details, setDetails] = useState({});
  const [Edit, setEdit] = useState(false);
  const [ShowPrice, setShowPrice] = useState(false);
  const [QtyH, setQty] = useState(false);

  const {
    product_plans,
    isError,
    message,
    isSuccess,
    isLoading,
    isAdded,
    isUpdated,
  } = useSelector((state) => state.productPlan);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [addFormData, setAddFormData] = useState({
    name: "",
    description: "",
    product_type: "",
    product_credit: "",
    content_generator: "",
    pricing_type: "",
    pricing_amount: "",
    display: "",
    status: "",
    questionaire: "",
  });

  const [editFormData, setEditFormData] = useState({
    name: "",
    description: "",
    product_type: "",
    product_credit: "",
    content_generator: "",
    pricing_type: "",
    pricing_amount: "",
    display: "",
    status: "",
    questionaire: "",
  });

  useLayoutEffect(() => {
    dispatch(reset());
    dispatch(getPlans());
  }, []);

  useEffect(() => {
    if (userID.id) {
      dispatch(getPlans());
      if (isSuccess == true && isUpdated == false) {
        const newData = product_plans ? [...product_plans] : [];
        var data = newData?.filter((user) => user._id == userID.id);
        setDetails(data[0]);
      }
    }
    if (
      (isAdded == true || isUpdated == true) &&
      isSuccess == true &&
      message !== ""
    ) {
      toast.success(message);
      dispatch(reset());
      setTimeout(() => {
        navigate(-1);
      }, 1500);
    }
    if (isError == true && message !== "") {
      toast.error(message);
      dispatch(reset());
    }
    if ((addFormData.name && addFormData.description) !== "") {
      setEdit(true);
    } else {
      setEdit(false);
    }
  }, [dispatch, userID, addFormData, message, isError, isSuccess, isAdded]);

  const handleAddFormChange = (event) => {
    event.preventDefault();
    const fieldName = event.target.getAttribute("name");
    const fieldValue = event.target.value;
    if (fieldName === "pricing_type") {
      if (fieldValue === "Quoted") {
        setShowPrice(true);
      } else {
        setShowPrice(false);
      }
    }
    if (fieldName === "content_generator") {
      if (fieldValue === "Commissioned") {
        setQty(true);
      } else {
        setQty(false);
      }
    }
    const newFormData = { ...addFormData };
    newFormData[fieldName] = fieldValue;

    setAddFormData(newFormData);
  };

  const AddForm = (event) => {
    event.preventDefault();
    if (
      validator.isEmail(addFormData.name) ||
      validator.isDecimal(addFormData.name)
    ) {
      toast.error("Please, enter valid name!");
    } else if (
      (addFormData.questionaire == null || !addFormData.questionaire) &&
      addFormData.pricing_type !== "Quoted"
    ) {
      toast.error("Please, select questionnaire!");
    } else {
      if (
        validator.isAlphanumeric(addFormData.name, "en-US", { ignore: " " })
      ) {
        const userData = {
          name: addFormData.name,
          description: addFormData.description,
          product_type: addFormData.product_type,
          product_credit: addFormData.product_credit,
          content_generator: addFormData.content_generator,
          pricing_type: addFormData.pricing_type,
          pricing_amount:
            addFormData.pricing_type === "Quoted"
              ? 0
              : addFormData.pricing_amount,
          display: addFormData.display,
          status: addFormData.status,
          questionaire: addFormData.questionaire
            ? addFormData.questionaire
            : null,
        };
        dispatch(addPlans(userData));
        if (isAdded == true) {
          toast.success(message);
        }
      } else {
        toast.error("Please, enter valid Name!");
      }
    }
  };

  const handleEditFormChange = (event) => {
    event.preventDefault();
    const fieldName = event.target.getAttribute("name");
    const fieldValue = event.target.value;
    if (fieldName === "pricing_type") {
      if (fieldValue === "Quoted") {
        setShowPrice(true);
      } else {
        setShowPrice(false);
      }
    }
    if (fieldName === "content_generator") {
      if (fieldValue === "Commissioned") {
        setQty(true);
      } else {
        setQty(false);
      }
    }
    const newFormData = { ...editFormData };
    newFormData[fieldName] = fieldValue;

    setEditFormData(newFormData);
  };

  const UpdateForm = (event) => {
    event.preventDefault();
    if (
      validator.isEmail(editFormData.name) ||
      validator.isDecimal(editFormData.name)
    ) {
      enqueueSnackbar("Please enter valid name", { variant: "error" });
    } else {
      if (
        validator.isAlphanumeric(editFormData.name, "en-US", { ignore: " " })
      ) {
        const userData = {
          id: userID.id,
          name: editFormData.name,
          description: editFormData.description,
          product_type: editFormData.product_type,
          product_credit: editFormData.product_credit,
          content_generator: editFormData.content_generator,
          pricing_type: editFormData.pricing_type,
          pricing_amount:
            editFormData.pricing_type === "Quoted"
              ? 0
              : editFormData.pricing_amount,
          display: editFormData.display,
          status: editFormData.status,
          questionaire: editFormData.questionaire
            ? editFormData.questionaire
            : null,
        };
        dispatch(editPlans(userData));
      } else {
        toast.error("Please, enter valid Name!");
      }
    }
  };

  return (
    <div>
      <ToastContainer />
      {Object.keys(userID).length === 0 && userID.constructor === Object ? (
        <PlanForm
          AddForm={AddForm}
          handleAddFormChange={handleAddFormChange}
          Edit={Edit}
          ShowPrice={ShowPrice}
          QtyH={QtyH}
        />
      ) : (
        <PlanEditForm
          UpdateForm={UpdateForm}
          handleEditFormChange={handleEditFormChange}
          Details={Details}
          editFormData={editFormData}
          setEditFormData={setEditFormData}
          ShowPrice={ShowPrice}
          QtyH={QtyH}
        />
      )}
    </div>
  );
}

export default AddProductPlan;
