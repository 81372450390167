import { useState, useEffect } from "react";
import momentTZ from "moment-timezone";
import { Form, InputGroup, Button } from "react-bootstrap";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import { changeTimezone } from "../../../../features/Frontend/frontendSlice";

const OtherSettings = () => {
  const defaultTimeZone = momentTZ.tz.guess();
  const timeZonesList = momentTZ.tz.names();
  const API_URL = `${process.env.REACT_APP_BACKEND_ROUTE}/sorting/`;
  const {
    frontData: { timezone },
  } = useSelector((state) => state.front_Data);
  const [state, setState] = useState("");
  const [quotedMinScore, setQuotedMinScore] = useState(0);
  const [valuedMinScore, setValuedMinScore] = useState(0);

  const dispatch = useDispatch();

  useEffect(() => {
    axios
      .get(API_URL)
      .then((res) => {
        setQuotedMinScore(res?.data?.data?.quotedMinScore);
        setValuedMinScore(res?.data?.data?.valuedMinScore);
        setState(res?.data?.data?.timezone);
      })
      .catch((err) => console.log(err));
  }, []);
  const handleChange = (e) => {
    const data = {
      timezone: e.target.value,
    };
    setState(e.target.value);
    axios
      .put(API_URL, data)
      .then((res) => {
        toast.success("Timezone saved successfully!");
      })
      .catch(() => {
        toast.error("Timezone not saved!");
      });
    dispatch(changeTimezone(e.target.value));
  };

  const submitScore = () => {
    const data = {
      quotedMinScore,
      valuedMinScore,
    };
    axios
      .put(API_URL, { productScore: data })
      .then((res) => {
        console.log(res);
        //   navigate(-1);
        toast.success("Min Score saved successfully!");
      })
      .catch(() => {
        toast.error("score not saved!");
      });
  };
  return (
    <>
      <div className="row">
        <ToastContainer />
        <div className="col-md-6">
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Timezone</Form.Label>
            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon1">
                <img src="/imgs/status-info.svg" alt="" />
              </InputGroup.Text>
              <Form.Select
                aria-label="Default select example"
                name="timezone"
                onChange={handleChange}
                value={state}
              >
                <option value="">Select</option>
                {timeZonesList?.map((val, i) => (
                  <option value={val} key={i}>
                    {val}
                  </option>
                ))}
              </Form.Select>
            </InputGroup>
          </Form.Group>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Quoted Min Score</Form.Label>
            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon1">
                <img src="/imgs/status-info.svg" alt="" />
              </InputGroup.Text>
              <Form.Control
                type="number"
                placeholder="Enter Min Score"
                name="minScore"
                value={quotedMinScore}
                onChange={(e) => setQuotedMinScore(e.target.value)}
              />
            </InputGroup>
          </Form.Group>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Valued Min Score</Form.Label>
            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon1">
                <img src="/imgs/status-info.svg" alt="" />
              </InputGroup.Text>
              <Form.Control
                type="number"
                placeholder="Enter Min Score"
                name="minScore"
                value={valuedMinScore}
                onChange={(e) => setValuedMinScore(e.target.value)}
              />
            </InputGroup>
          </Form.Group>
        </div>
      </div>
      <Button className="loginBtn" onClick={submitScore}>
        Save
      </Button>
    </>
  );
};

export default OtherSettings;
