import React, { useEffect, useState } from "react";
import { Form, InputGroup, Button, Modal } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { otherPages_column } from "../../../../../utils/constant/column_header_data";
import { useDispatch, useSelector } from "react-redux";
import DataTable from "react-data-table-component";
import { toast } from "react-toastify";
import {
  deletedRowsO,
  deleteOtherPages,
  getOtherPages,
  reset,
} from "../../../../../features/OtherPages/otherPagesSlice";

function OtherPages() {
  const { O_Pages, isError, message, isSuccess, isLoading } = useSelector(
    (state) => state.otherPages
  );

  const [Search, setSearch] = useState("");
  const [selectedRows, setSelectedRows] = useState(false);
  const [CList, setCList] = useState([]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [delID, setDelID] = useState("");
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);

  const handleClose = () => {
    setShow(false);
    setDelID("");
  };

  const handleClose2 = () => {
    setShow2(false);
    setDelID("");
  };

  useEffect(() => {
    dispatch(getOtherPages());

    if (O_Pages) {
      const data = [...O_Pages].reverse();
      setCList(data);
    }

    if (isError) {
      toast.error(message);
    }
    if (isSuccess) {
      toast.success(message);
    }
  }, [dispatch, isError, message, isSuccess, selectedRows]);

  const deleteRow = () => {
    let yFilter = selectedRows.map((itemY) => {
      return itemY.id;
    });
    let filteredX = O_Pages.filter((itemX) => !yFilter.includes(itemX._id));
    dispatch(deletedRowsO(yFilter));
    dispatch(reset());
    setShow2(false);
  };

  const onView = (ID) => {
    navigate(`/settings/page-management/other-pages/otherPages-form/${ID}`, {
      state: { editPage: false },
    });
  };

  const onEdit = (ID) => {
    navigate(`/settings/page-management/other-pages/otherPages-form/${ID}`, {
      state: { editPage: true },
    });
  };

  const onDelete = (ID) => {
    setDelID(ID);
    setShow(true);
  };

  const onDelete2 = () => {
    dispatch(deleteOtherPages(delID));
    setDelID("");
    setShow(false);
  };

  var rows = [];
  if (CList) {
    for (let [i, value] of CList?.entries()) {
      rows[i] = {
        id: value._id,
        name: value.name,
        url: value.url,
        status:
          value.status === "Published" ? (
            <p className="status active">{value.status}</p>
          ) : value.status === "Draft" ? (
            <p className="status draft">{value.status}</p>
          ) : (
            <p className="status revoked">{value.status}</p>
          ),
        // navigation: value.navigation,
        // content: value.content,
        tags:
          value.tags.length > 0
            ? value.tags?.map((val) => val.text + ", ")
            : "_",

        action: (
          <div>
            <div className="d-flex gap-3">
              <img
                src="/imgs/u_eye.svg"
                width={20}
                alt=""
                onClick={() => onView(value._id)}
              />
              <img
                src="/imgs/t_edit.svg"
                width={20}
                alt=""
                onClick={() => onEdit(value._id)}
              />
              {/* <img src="/imgs/revoke.svg" width={20} alt="" /> */}
              <img
                src="/imgs/delete.svg"
                width={20}
                alt=""
                onClick={() => onDelete(value._id)}
              />
            </div>
          </div>
        ),
      };
    }
  }

  return (
    <div className="staffMainDiv">
      <div className="row mx-0 mb-4">
        <div className="d-flex justify-content-between">
          <div className="d-flex gap-3">
            <img
              src="/imgs/revoke.svg"
              width={20}
              alt=""
              onClick={() => setSearch("")}
            />
            <Form.Group className="mb-0">
              <InputGroup className="mb-0">
                <InputGroup.Text id="basic-addon1">
                  <img src="/imgs/search.svg" alt="" />
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  placeholder="Search"
                  value={Search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </InputGroup>
            </Form.Group>
          </div>
          <Link to="/settings/page-management/other-pages/otherPages-form">
            <Button className="loginBtn" type="submit">
              Add New
            </Button>
          </Link>
        </div>
      </div>
      <DataTable
        columns={otherPages_column}
        data={
          rows
            ? rows.filter((item) =>
                item.name.toLowerCase().includes(Search.toLowerCase())
              )
            : []
        }
        pagination
      />

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Deleted</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this Item?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              onDelete2();
            }}
          >
            Sure
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={show2}
        onHide={handleClose2}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Deleted</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose2}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              deleteRow();
            }}
          >
            Sure
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default OtherPages;
