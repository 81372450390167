import React, { useEffect, useState } from "react";
import { useAccordionButton } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  getProfile,
  updateLastLogin,
  logoutProfile,
} from "../../../features/MyProfile/profileSlice";
import { getUsers } from "../../../features/users/userSlice";
import { logout } from "./../../../features/auth/authSlice";
import LogoutIcon from "@mui/icons-material/Logout";
import ArticleIcon from "../../../assets/articlesvg.svg";
import TourIcon from "../../../assets/360svg.svg";
import TourReviews from "../../../assets/tourReviewsSvg.svg";
import ArticleReviewsSvg from "../../../assets/articleReviewsSvg.svg";
import GallerytIcon from "../../../assets/gallerySvg.svg";
import QuestionIcon from "../../../assets/questionSvg.svg";

function Sidebar({ sidebar, setProfile }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [activePage, setActivePage] = useState();
  const [SubactivePage, setSubActivePage] = useState();
  const [Toggle, setToggle] = useState({
    management: false,
    article: false,
    settings: false,
    order: false,
    billing: false,
    commonbilling: false,
    profile: false,
    tours: false,
    questionare: false,
    distArticle: false,
  });

  var path = window.location.pathname;
  // const user = JSON.parse(localStorage.getItem("user"));
  const { user, message, isError, isSuccess, isLoading } = useSelector(
    (state) => state.auth
  );
  const {
    profile_data,
    message: profileMessage,
    isError: profileError,
    isSuccess: profileSuccess,
    isLoading: profileLoading,
  } = useSelector((state) => state.profileData);

  const user2 = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    let ID = user2?.data?.user?._id;
    if (ID) {
      dispatch(getProfile(ID));
      // dispatch(updateLastLogin(ID));
    }
    if (path === "/dashboard") {
      setActivePage("/dashboard");
    }

    if (path === "/profile") {
      setActivePage("/profile");
    }

    if (
      user2?.data?.user?.role === "user" &&
      (path === "/login" || path === "/signup")
    ) {
      localStorage.setItem("profileData", true);
      setProfile(localStorage.getItem("profileData"));
      // setProfile(true);
      navigate("/profile");
    }

    if (
      (user2?.data?.user?.role === "contributor" ||
        user2?.data?.user?.role === "user") &&
      path === "/login"
    ) {
      // setProfile(false);
      navigate("/profile");
    }
  }, [dispatch, path, isError, isSuccess, message, navigate]);

  const logoutData = () => {
    if (user2?.data.user.role === "admin") {
      dispatch(updateLastLogin(user2?.data.user._id));
      dispatch(logout());
      dispatch(logoutProfile());
      window.location = `${process.env.REACT_APP_FRONTEND_ROUTE}/dashboard-login`;
      // navigate("/adminLogin");
    } else {
      dispatch(updateLastLogin(user2?.data.user._id));
      dispatch(logout());
      dispatch(logoutProfile());
      navigate("/", { redirect: true });
      window.location.reload();
    }
    localStorage.setItem("profileData", false);
    setProfile(false);
  };
  const GoHome = () => {
    setProfile(false);
    localStorage.setItem("myProfile", "false");
  };

  // console.log(document.querySelector(".sideitem"));
  return (
    <div className={`sideBar ${sidebar ? "openSidebar" : "closeSidebar"}`}>
      <div className="d-flex gap-3 align-items-center mb-5">
        <img src={`${profile_data?.profile}`} className="dpImg" alt="" />
        <div>
          <h1>{profile_data?.user_name}</h1>
          <h5>
            {profile_data?.role === "contributor" ? "user" : profile_data?.role}
          </h5>
        </div>
      </div>
      <div className="sideMenuheight">
        <ul>
          {user?.data?.user?.role === "admin" && (
            <Link
              to="/dashboard"
              onClick={() => {
                setActivePage("/dashboard");
              }}
            >
              <li className="sideitem">
                <span
                  className={activePage === "/dashboard" ? "active" : ""}
                  onClick={() => {
                    setSubActivePage("/dashboard");
                  }}
                >
                  <div>
                    <i className="fa-solid fa-gauge"></i>
                    Dashboard
                  </div>
                </span>
              </li>
            </Link>
          )}
          <Link
            to="/profile"
            onClick={() => {
              setActivePage("/profile");
            }}
          >
            <li className="sideitem">
              <span
                className={activePage === "/profile" ? "active" : ""}
                onClick={() => {
                  setToggle({
                    management: false,
                    article: false,
                    settings: false,
                    order: false,
                    billing: false,
                    profile: !Toggle.profile,
                  });
                  setSubActivePage("/profile");
                }}
              >
                <div>
                  <i className="fa-solid fa-money-bills"></i>
                  My Profile
                  <i className="fa-solid fa-angle-down ms-2"></i>
                </div>
                <i className="fa-solid fa-angle-down ms-2"></i>
              </span>
              {Toggle.profile == true && (
                <ul>
                  <Link
                    to="/profile"
                    onClick={() => {
                      setSubActivePage("/profile");
                    }}
                  >
                    <li
                      className={SubactivePage === "/profile" ? "active" : ""}
                    >
                      Profile
                    </li>
                  </Link>
                  {/* <Link
                    to="/profile/change-password"
                    onClick={() => {
                      setSubActivePage("/profile/change-password");
                    }}
                  >
                    <li
                      className={
                        SubactivePage === "/profile/change-password"
                          ? "active"
                          : ""
                      }
                    >
                      Change Password
                    </li>
                  </Link> */}
                </ul>
              )}
            </li>
          </Link>

          {(user?.data.user.role === "admin" ||
            (user?.data?.user?.role === "staff" &&
              user?.data?.user?.isConfirmed == true)) && (
            <Link
              to={
                user?.data.user.role === "admin"
                  ? "/user-management/staff"
                  : "/user-management/users"
              }
              onClick={() => {
                if (user?.data.user.role === "admin") {
                  setActivePage("/user-management/staff");
                }
                // if (user?.data.user.role === "staff") {
                //   setActivePage("/user-management/contributor");
                // }
              }}
            >
              <li className="sideitem userManagement">
                <span
                  className={
                    activePage === "/user-management/staff"
                      ? "active"
                      : activePage === "/user-management/users"
                      ? "active"
                      : ""
                  }
                  onClick={() => {
                    setToggle({
                      management: !Toggle.management,
                      article: false,
                      settings: false,
                    });
                    if (user?.data.user.role === "admin") {
                      setSubActivePage("/user-management/staff");
                    }
                    if (user?.data.user.role === "staff") {
                      setSubActivePage("/user-management/users");
                    }
                  }}
                >
                  <div>
                    <i className="fa-solid fa-user"></i>
                    User Management
                    <i className="fa-solid fa-angle-down ms-2"></i>
                  </div>
                  <i className="fa-solid fa-angle-down ms-2"></i>
                </span>
                {Toggle.management == true && (
                  <ul>
                    {user?.data?.user?.role === "admin" && (
                      <Link
                        to="/user-management/staff"
                        onClick={() => {
                          setSubActivePage("/user-management/staff");
                        }}
                      >
                        <li
                          className={
                            SubactivePage === "/user-management/staff"
                              ? "active"
                              : ""
                          }
                        >
                          Staff
                        </li>
                      </Link>
                    )}

                    {/* <Link
                      to="/user-management/contributor"
                      onClick={() => {
                        setSubActivePage("/user-management/contributor");
                      }}
                    >
                      <li
                        className={
                          SubactivePage === "/user-management/contributor"
                            ? "active"
                            : ""
                        }
                      >
                        Contributors
                      </li>
                    </Link> */}
                    <Link
                      to="/user-management/users"
                      onClick={() => {
                        setSubActivePage("/user-management/users");
                      }}
                    >
                      <li
                        className={
                          SubactivePage === "/user-management/users"
                            ? "active"
                            : ""
                        }
                      >
                        Users
                      </li>
                    </Link>
                  </ul>
                )}
              </li>
            </Link>
          )}

          {user?.data.user.role !== "user" && (
            <>
              {(user?.data?.user?.role === "staff" ||
                user?.data.user.role == "admin") &&
              user?.data?.user?.isConfirmed == true ? (
                <>
                  <Link
                    to="/article/listing"
                    onClick={() => {
                      setActivePage("/article/listing");
                    }}
                  >
                    <li className="sideitem">
                      <span
                        className={
                          activePage === "/article/listing" ? "active" : ""
                        }
                        onClick={() => {
                          setToggle({
                            management: false,
                            article: !Toggle.article,
                            settings: false,
                          });
                          setSubActivePage("/article/listing");
                        }}
                      >
                        <div>
                          <i className="">
                            <img src={ArticleIcon} width={20} height={20} />
                          </i>
                          Article
                          <i className="fa-solid fa-angle-down ms-2"></i>
                        </div>
                        {/* <i className="fa-solid fa-angle-down ms-2"></i> */}
                      </span>
                      {Toggle.article == true && (
                        <ul>
                          <Link
                            to="/article/listing"
                            onClick={() => {
                              setSubActivePage("/article/listing");
                            }}
                          >
                            <li
                              className={
                                SubactivePage === "/article/listing"
                                  ? "active"
                                  : ""
                              }
                            >
                              Listing
                            </li>
                          </Link>

                          <Link
                            to="/article/pending-approval"
                            onClick={() => {
                              setSubActivePage("/article/pending-approval");
                            }}
                          >
                            <li
                              className={
                                SubactivePage === "/article/pending-approval"
                                  ? "active"
                                  : ""
                              }
                            >
                              Pending Approvals
                            </li>
                          </Link>
                          <Link
                            to="/article/deleted"
                            onClick={() => {
                              setSubActivePage("/article/delete");
                            }}
                          >
                            <li
                              className={
                                SubactivePage === "/article/delete"
                                  ? "active"
                                  : ""
                              }
                            >
                              Deleted
                            </li>
                          </Link>
                        </ul>
                      )}
                    </li>
                  </Link>
                </>
              ) : (
                (user?.data?.user?.role === "contributor" ||
                  user?.data?.user?.role === "user") &&
                user?.data?.user?.isConfirmed == true && (
                  <>
                    <Link
                      to="/article/draft"
                      onClick={() => {
                        setActivePage("/article/draft");
                      }}
                    >
                      <li className="sideitem">
                        <span
                          className={
                            activePage === "/article/draft" ? "active" : ""
                          }
                          onClick={() => {
                            setToggle({
                              management: false,
                              article: !Toggle.article,
                              settings: false,
                            });
                            setSubActivePage("/article/draft");
                          }}
                        >
                          <div>
                            <i className="">
                              <img width={20} height={20} src={ArticleIcon} />
                            </i>
                            Article
                            <i className="fa-solid fa-angle-down ms-2"></i>
                          </div>
                          {/* <i className="fa-solid fa-angle-down ms-2"></i> */}
                          {/* <span> */}
                          {(SubactivePage === "/article/draft" ||
                            SubactivePage === "/published" ||
                            SubactivePage === "/article/approved" ||
                            SubactivePage === "/pending-approval" ||
                            SubactivePage === "/article/rejected" ||
                            SubactivePage === "/article/delete") && (
                            <span className="credit-score-back">
                              <p className="credit-score">
                                {profile_data?.blog_credit} Credit
                              </p>
                            </span>
                          )}
                          {/* </span> */}
                        </span>
                        {Toggle.article == true && (
                          <ul>
                            <Link
                              to="/article/draft"
                              onClick={() => {
                                setSubActivePage("/article/draft");
                              }}
                            >
                              <li
                                className={
                                  SubactivePage === "/article/draft"
                                    ? "active"
                                    : ""
                                }
                              >
                                Draft
                              </li>
                            </Link>
                            <Link
                              to="/published"
                              onClick={() => {
                                setSubActivePage("/published");
                              }}
                            >
                              <li
                                className={
                                  SubactivePage === "/published" ? "active" : ""
                                }
                              >
                                Published
                              </li>
                            </Link>
                            <Link
                              to="/article/approved"
                              onClick={() => {
                                setSubActivePage("/article/approved");
                              }}
                            >
                              <li
                                className={
                                  SubactivePage === "/article/approved"
                                    ? "active"
                                    : ""
                                }
                              >
                                Approved
                              </li>
                            </Link>

                            <Link
                              to="/pending-approval"
                              onClick={() => {
                                setSubActivePage("/pending-approval");
                              }}
                            >
                              <li
                                className={
                                  SubactivePage === "/pending-approval"
                                    ? "active"
                                    : ""
                                }
                              >
                                Pending Approval
                              </li>
                            </Link>

                            <Link
                              to="/article/rejected"
                              onClick={() => {
                                setSubActivePage("/article/rejected");
                              }}
                            >
                              <li
                                className={
                                  SubactivePage === "/article/rejected"
                                    ? "active"
                                    : ""
                                }
                              >
                                Rejected Articles
                              </li>
                            </Link>
                            <Link
                              to="/article/delete"
                              onClick={() => {
                                setSubActivePage("/article/delete");
                              }}
                            >
                              <li
                                className={
                                  SubactivePage === "/article/delete"
                                    ? "active"
                                    : ""
                                }
                              >
                                Deleted Articles
                              </li>
                            </Link>
                          </ul>
                        )}
                      </li>
                    </Link>
                  </>
                )
              )}

              <Link
                to="/article-reviews"
                onClick={() => {
                  setActivePage("/article-reviews");
                }}
              >
                <li className="sideitem">
                  <span
                    className={
                      activePage === "/article-reviews" ? "active" : ""
                    }
                    onClick={() => {
                      setSubActivePage("/article-reviews");
                    }}
                  >
                    <div>
                      <i className="">
                        <img height={20} width={20} src={ArticleReviewsSvg} />
                      </i>
                      Article Reviews
                    </div>
                  </span>
                </li>
              </Link>
              {user?.data?.user?.isConfirmed == true && (
                <Link
                  to="/360-tours"
                  onClick={() => {
                    setActivePage("/360-tours");
                  }}
                >
                  <li className="sideitem">
                    <span
                      className={activePage === "/360-tours" ? "active" : ""}
                      onClick={() => {
                        setToggle({
                          management: false,
                          article: false,
                          settings: false,
                          order: false,
                          billing: false,
                          tours: !Toggle.tours,
                        });
                        setSubActivePage("/360-tours");
                      }}
                    >
                      <div>
                        <i className="">
                          <img src={TourIcon} width={20} height={20} />
                        </i>
                        Tours
                        <i className="fa-solid fa-angle-down ms-2"></i>
                      </div>
                      {/* <i className="fa-solid fa-angle-down"></i> */}
                      {(SubactivePage === "/360-tours" ||
                        SubactivePage === "/360-tours/tour-list" ||
                        SubactivePage === "/360-tours/tours-form") &&
                        user?.data?.user?.role !== "staff" &&
                        user?.data?.user?.role !== "admin" && (
                          <span className="credit-score-back">
                            <p className="credit-score">
                              {profile_data?.tour_credit} Credit
                            </p>
                          </span>
                        )}
                    </span>

                    {Toggle.tours == true && (
                      <ul>
                        <Link
                          to="/360-tours"
                          onClick={() => {
                            setSubActivePage("/360-tours");
                          }}
                        >
                          <li
                            className={
                              SubactivePage === "/360-tours" ? "active" : ""
                            }
                          >
                            360 Tour
                          </li>
                        </Link>
                      </ul>
                    )}
                  </li>
                </Link>
              )}
              <Link
                to="/tour-reviews"
                onClick={() => {
                  setActivePage("/tour-reviews");
                }}
              >
                <li className="sideitem">
                  <span
                    className={activePage === "/tour-reviews" ? "active" : ""}
                    onClick={() => {
                      setSubActivePage("/tour-reviews");
                    }}
                  >
                    <div>
                      <i className="">
                        <img width={20} height={20} src={TourReviews} />
                      </i>
                      Tour Reviews
                    </div>
                  </span>
                </li>
              </Link>
            </>
          )}
          {/* {(user?.data.user.role === "admin" ||
            user?.data.user.role === "staff" ||
            user?.data.user.role === "user") && ( */}
          <Link
            to={
              // user?.data.user.role === "admin"
              //   ?
              "Commission-Job-List/Unpublished"
              // : "Commission-Job-List/Published"
            }
            onClick={() => {
              // if (user?.data.user.role === "admin") {
              setActivePage("Commission-Job-List/Unpublished");
              // }
            }}
          >
            <li className="sideitem userManagement">
              <span
                className={
                  activePage === "Commission-Job-List/Unpublished"
                    ? "active"
                    : activePage === "/user-management/users"
                    ? "active"
                    : ""
                }
                onClick={() => {
                  setToggle({
                    management: !Toggle.management,
                    article: false,
                    settings: false,
                  });
                  // if (user?.data.user.role === "admin") {
                  setSubActivePage("Commission-Job-List/Unpublished");
                  // }
                }}
              >
                <div>
                  <i className="fa-solid fa-newspaper"></i>
                  Commissioned Job List
                  <i className="fa-solid fa-angle-down ms-2"></i>
                </div>
                <i className="fa-solid fa-angle-down ms-2"></i>
              </span>
              {Toggle.management == true && (
                <ul>
                  <Link
                    to="Commission-Job-List/Unpublished"
                    onClick={() => {
                      setSubActivePage("Commission-Job-List/Unpublished");
                    }}
                  >
                    <li
                      className={
                        SubactivePage === "Commission-Job-List/Unpublished"
                          ? "active"
                          : ""
                      }
                    >
                      Unpublished
                    </li>
                  </Link>

                  <Link
                    to="Commission-Job-List/Published"
                    onClick={() => {
                      setSubActivePage("Commission-Job-List/Published");
                    }}
                  >
                    <li
                      className={
                        SubactivePage === "Commission-Job-List/Published"
                          ? "active"
                          : ""
                      }
                    >
                      Published
                    </li>
                  </Link>
                </ul>
              )}
            </li>
          </Link>
          {/* )} */}
          {(user?.data?.user?.role === "admin" ||
            user?.data?.user?.role === "staff") && (
            <>
              <Link
                to="/gallery"
                onClick={() => {
                  setActivePage("/gallery");
                }}
              >
                <li className="sideitem">
                  <span
                    className={activePage === "/gallery" ? "active" : ""}
                    onClick={() => {
                      setSubActivePage("/gallery");
                    }}
                  >
                    <div>
                      <i className="">
                        <img src={GallerytIcon} width={20} height={20} />
                      </i>
                      Photo Gallery
                    </div>
                  </span>
                </li>
              </Link>
              {user?.data?.user?.role === "admin" && (
                <Link
                  to="/settings/product-plan"
                  onClick={() => {
                    setActivePage("/settings/product-plan");
                  }}
                >
                  <li className="sideitem">
                    <span
                      className={
                        activePage === "/settings/product-plan" ? "active" : ""
                      }
                      onClick={() => {
                        setToggle({
                          management: false,
                          article: false,
                          settings: !Toggle.settings,
                        });
                        setSubActivePage("/settings/product-plan");
                      }}
                    >
                      <div>
                        <i className="fa-solid fa-gear"></i>
                        Settings
                        <i className="fa-solid fa-angle-down ms-2"></i>
                      </div>
                      <i className="fa-solid fa-angle-down ms-2"></i>
                    </span>
                    {Toggle.settings == true && (
                      <ul>
                        <Link
                          to="/settings/product-plan"
                          onClick={() => {
                            setSubActivePage("/settings/product-plan");
                          }}
                        >
                          <li
                            className={
                              SubactivePage === "/settings/product-plan"
                                ? "active"
                                : ""
                            }
                          >
                            Product Plan
                          </li>
                        </Link>
                        <Link
                          to="/settings/payment-gateway/paymentGateway-form"
                          onClick={() => {
                            setSubActivePage(
                              "/settings/payment-gateway/paymentGateway-form"
                            );
                          }}
                        >
                          <li
                            className={
                              SubactivePage ===
                              "/settings/payment-gateway/paymentGateway-form"
                                ? "active"
                                : ""
                            }
                          >
                            Payment Gateway
                          </li>
                        </Link>
                        <Link
                          to="/settings/navigations"
                          onClick={() => {
                            setSubActivePage("/settings/navigations");
                          }}
                        >
                          <li
                            className={
                              SubactivePage === "/settings/navigations"
                                ? "active"
                                : ""
                            }
                          >
                            Navigations
                          </li>
                        </Link>
                        {/* <Link
                          to="/settings/upgrade-plan"
                          onClick={() => {
                            setSubActivePage("/settings/upgrade-plan");
                          }}
                        >
                          <li
                            className={
                              SubactivePage === "/settings/upgrade-plan"
                                ? "active"
                                : ""
                            }
                          >
                            Upgrade Plan
                          </li>
                        </Link> */}
                        <Link
                          to="/settings/page-management"
                          onClick={() => {
                            setSubActivePage("/settings/page-management");
                          }}
                        >
                          <li
                            className={
                              SubactivePage === "/settings/page-management"
                                ? "active"
                                : ""
                            }
                          >
                            Page Management
                          </li>
                        </Link>
                        <Link
                          to="/settings/vetting"
                          onClick={() => {
                            setSubActivePage("/settings/vetting");
                          }}
                        >
                          <li
                            className={
                              SubactivePage === "/settings/vetting"
                                ? "active"
                                : ""
                            }
                          >
                            Vetting
                          </li>
                        </Link>
                        {user?.data?.user?.role === "admin" && (
                          <>
                            <Link
                              to="/settings/credits"
                              onClick={() => {
                                setSubActivePage("/settings/credits");
                              }}
                            >
                              <li
                                className={
                                  SubactivePage === "/settings/credits"
                                    ? "active"
                                    : ""
                                }
                              >
                                Credit System
                              </li>
                            </Link>
                            <Link
                              to="/settings/sorting"
                              onClick={() => {
                                setSubActivePage("/settings/sorting");
                              }}
                            >
                              <li
                                className={
                                  SubactivePage === "/settings/sorting"
                                    ? "active"
                                    : ""
                                }
                              >
                                Sorting Highlight
                              </li>
                            </Link>
                            <Link
                              to="/settings/testimonial"
                              onClick={() => {
                                setSubActivePage("/settings/testimonial");
                              }}
                            >
                              <li
                                className={
                                  SubactivePage === "/settings/testimonial"
                                    ? "active"
                                    : ""
                                }
                              >
                                Testimonial
                              </li>
                            </Link>
                            <Link
                              to="/settings/footer"
                              onClick={() => {
                                setSubActivePage("/settings/footer");
                              }}
                            >
                              <li
                                className={
                                  SubactivePage === "/settings/footer"
                                    ? "active"
                                    : ""
                                }
                              >
                                Footer
                              </li>
                            </Link>
                            <Link
                              to="/settings/other"
                              onClick={() => {
                                setSubActivePage("/settings/other");
                              }}
                            >
                              <li
                                className={
                                  SubactivePage === "/settings/other"
                                    ? "active"
                                    : ""
                                }
                              >
                                Other
                              </li>
                            </Link>
                            <Link
                              to="/settings/billing-reminder"
                              onClick={() => {
                                setSubActivePage("/settings/billing-reminder");
                              }}
                            >
                              <li
                                className={
                                  SubactivePage === "/settings/billing-reminder"
                                    ? "active"
                                    : ""
                                }
                              >
                                Billing reminder
                              </li>
                            </Link>
                          </>
                        )}
                      </ul>
                    )}
                  </li>
                </Link>
              )}
            </>
          )}

          {(user?.data?.user?.role === "staff" ||
            user?.data?.user?.role === "contributor" ||
            user?.data?.user?.role === "admin") &&
            user?.data?.user?.isConfirmed == true && (
              <>
                <Link
                  to={"/orders/quotation"}
                  onClick={() => {
                    setActivePage("/orders/quotation");
                  }}
                >
                  <li className="sideitem">
                    <span
                      className={
                        activePage === "/orders/quotation" ? "active" : ""
                      }
                      onClick={() => {
                        setToggle({
                          management: false,
                          article: false,
                          settings: false,
                          order: !Toggle.order,
                        });
                        setSubActivePage("/orders/quotation");
                      }}
                    >
                      <div>
                        <i className="fa-solid fa-cart-shopping"></i>
                        Orders
                        <i className="fa-solid fa-angle-down ms-2"></i>
                      </div>
                      <i className="fa-solid fa-angle-down ms-2"></i>
                    </span>
                    {Toggle.order == true && (
                      <ul>
                        <Link
                          to="/orders/quotation"
                          onClick={() => {
                            setSubActivePage("/orders/quotation");
                          }}
                        >
                          <li
                            className={
                              SubactivePage === "/orders/quotation"
                                ? "active"
                                : ""
                            }
                          >
                            Quotation
                          </li>
                        </Link>

                        <Link
                          to="/orders/outstanding"
                          onClick={() => {
                            setSubActivePage("/orders/outstanding");
                          }}
                        >
                          <li
                            className={
                              SubactivePage === "/orders/outstanding"
                                ? "active"
                                : ""
                            }
                          >
                            Declined
                          </li>
                        </Link>
                        <Link
                          to="/orders/paid"
                          onClick={() => {
                            setSubActivePage("/orders/paid");
                          }}
                        >
                          <li
                            className={
                              SubactivePage === "/orders/paid" ? "active" : ""
                            }
                          >
                            Accepted
                          </li>
                        </Link>
                        <Link
                          to="/orders/rejected"
                          onClick={() => {
                            setSubActivePage("/orders/rejected");
                          }}
                        >
                          <li
                            className={
                              SubactivePage === "/orders/rejected"
                                ? "active"
                                : ""
                            }
                          >
                            Rejected
                          </li>
                        </Link>
                      </ul>
                    )}
                  </li>
                </Link>
                {user?.data?.user?.role === "staff" ||
                  (user?.data?.user?.role === "admin" && (
                    <Link
                      to="/billing/pending"
                      onClick={() => {
                        setActivePage("/billing/pending");
                      }}
                    >
                      <li className="sideitem">
                        <span
                          className={
                            activePage === "/billing/pending" ? "active" : ""
                          }
                          onClick={() => {
                            setToggle({
                              management: false,
                              article: false,
                              settings: false,
                              order: false,
                              billing: !Toggle.billing,
                            });
                            setSubActivePage("/billing/pending");
                          }}
                        >
                          <div>
                            <i className="fa-solid fa-money-bills"></i>
                            Billing
                            <i className="fa-solid fa-angle-down ms-2"></i>
                          </div>
                          <i className="fa-solid fa-angle-down ms-2"></i>
                        </span>
                        {Toggle.billing == true && (
                          <ul>
                            <Link
                              to="/billing/pending"
                              onClick={() => {
                                setSubActivePage("/billing/pending");
                              }}
                            >
                              <li
                                className={
                                  SubactivePage === "/billing/pending"
                                    ? "active"
                                    : ""
                                }
                              >
                                Pending
                              </li>
                            </Link>
                            <Link
                              to="/billing/paid"
                              onClick={() => {
                                setSubActivePage("/billing/paid");
                              }}
                            >
                              <li
                                className={
                                  SubactivePage === "/billing/paid"
                                    ? "active"
                                    : ""
                                }
                              >
                                Paid
                              </li>
                            </Link>
                          </ul>
                        )}
                      </li>
                    </Link>
                  ))}

                {/* <Link
                to="/questionare-detail"
                onClick={() => {
                  setActivePage("/questionare-detail");
                }}
              >
                <li className="sideitem">
                  <span
                    className={
                      activePage === "/questionare-detail" ? "active" : ""
                    }
                    onClick={() => {
                      setSubActivePage("/questionare-detail");
                    }}
                  >
                    <div>
                      <i className="fa-brands fa-rocketchat"></i>
                      Questionare Detail
                    </div>
                  </span>
                </li>
              </Link> */}
              </>
            )}

          {(user?.data?.user?.role === "contributor" ||
            user?.data?.user.role === "user") && (
            <>
              {user?.data?.user?.role === "contributor" &&
                user?.data?.user?.isConfirmed == true && (
                  <Link
                    to="/order/details"
                    onClick={() => {
                      setActivePage("/order/details");
                    }}
                  >
                    <li className="sideitem">
                      <span
                        className={
                          activePage === "/order/details" ? "active" : ""
                        }
                        onClick={() => {
                          setSubActivePage("/order/details");
                        }}
                      >
                        <div>
                          <i className="fa-solid fa-cart-plus"></i>
                          Order Product Plan
                        </div>
                      </span>
                    </li>
                  </Link>
                )}

              <Link
                to="/billing/pending-bill"
                onClick={() => {
                  setActivePage("/billing/pending-bill");
                }}
              >
                <li className="sideitem">
                  <span
                    className={
                      activePage === "/billing/pending-bill" ? "active" : ""
                    }
                    onClick={() => {
                      setToggle({
                        management: false,
                        article: false,
                        settings: false,
                        order: false,
                        billing: false,
                        commonbilling: !Toggle.commonbilling,
                      });
                      setSubActivePage("/billing/pending-bill");
                    }}
                  >
                    <div>
                      <i className="fa-solid fa-money-bills"></i>
                      Billing
                      <i className="fa-solid fa-angle-down ms-2"></i>
                    </div>
                    <i className="fa-solid fa-angle-down ms-2"></i>
                  </span>
                  {Toggle.commonbilling == true && (
                    <ul>
                      <Link
                        to="/billing/pending-bill"
                        onClick={() => {
                          setSubActivePage("/billing/pending-bill");
                        }}
                      >
                        <li
                          className={
                            SubactivePage === "/billing/pending-bill"
                              ? "active"
                              : ""
                          }
                        >
                          Pending
                        </li>
                      </Link>
                      <Link
                        to="/billing/bill-history"
                        onClick={() => {
                          setSubActivePage("/billing/bill-history");
                        }}
                      >
                        <li
                          className={
                            SubactivePage === "/billing/bill-history"
                              ? "active"
                              : ""
                          }
                        >
                          Billing History
                        </li>
                      </Link>
                    </ul>
                  )}
                </li>
              </Link>
            </>
          )}

          {user?.data?.user?.role === "admin" && (
            <>
              <Link
                to="/questionnaire"
                onClick={() => {
                  setActivePage("/questionnaire");
                }}
              >
                <li className="sideitem">
                  <span
                    className={activePage === "/questionnaire" ? "active" : ""}
                    onClick={() => {
                      setToggle({
                        management: false,
                        article: false,
                        settings: false,
                        order: false,
                        billing: false,
                        commonbilling: false,
                        questionare: !Toggle.questionare,
                      });
                      setSubActivePage("/questionnaire");
                    }}
                  >
                    <div>
                      <i className="">
                        <img src={QuestionIcon} width={20} height={20} />
                      </i>
                      Questionnaire
                      <i className="fa-solid fa-angle-down ms-2"></i>
                    </div>
                    <i className="fa-solid fa-angle-down ms-2"></i>
                  </span>
                  {Toggle.questionare == true && (
                    <ul>
                      <Link
                        to="/questionnaire"
                        onClick={() => {
                          setSubActivePage("/questionnaire");
                        }}
                      >
                        <li
                          className={
                            SubactivePage === "/questionnaire" ? "active" : ""
                          }
                        >
                          Questionnaire
                        </li>
                      </Link>
                      {/* <Link
                        to="/add-questionnaire"
                        onClick={() => {
                          setSubActivePage("/add-questionnaire");
                        }}
                      >
                        <li
                          className={
                            SubactivePage === "/add-questionnaire"
                              ? "active"
                              : ""
                          }
                        >
                          Detail Form
                        </li>
                      </Link> */}
                    </ul>
                  )}
                </li>
              </Link>
            </>
          )}
        </ul>
      </div>

      <p className="signOut" onClick={logoutData} style={{ color: "#cc2939" }}>
        {/* <img src="/imgs/icon-out.svg" alt="" /> */}
        <LogoutIcon />
        &nbsp; Sign out
      </p>
    </div>
  );
}

export default Sidebar;
