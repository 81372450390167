import React, { useState } from "react";
import { useEffect } from "react";
import Chart from "react-apexcharts";
import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getAllUsers } from "../../../features/Dashboard/dashboardSlice";

function Dashboard() {
  const Dashboard = useSelector((state) => state.Dashboard);
  const { all_users, isSuccess, isError, message, isLoading } = Dashboard;

  const dispatch = useDispatch();

  const [ChartData, setChartData] = useState({
    series: [
      {
        name: "Followers",
        data: [31, 40, 28, 51, 42, 109, 100],
      },
      {
        name: "Following",
        data: [11, 32, 45, 32, 34, 52, 41],
      },
      {
        name: "Key Followers",
        data: [8, 16, 23, 32, 31, 28, 41],
      },
    ],
    options: {
      chart: {
        height: 350,
        type: "area",
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },
      // xaxis: {
      //   type: "date",
      //   categories: [
      //     "Jul 12",
      //     "Jul 13",
      //     "Jul 14",
      //     "Jul 15",
      //     "Jul 16",
      //     "Jul 17",
      //   ],
      // },
    },
  });

  useEffect(() => {
    dispatch(getAllUsers());
  }, [dispatch]);

  return (
    <div className="dashboardMain">
      <div className="row">
        <div className="col-md-4 mb-3">
          <div className="dashCard">
            <div className="d-flex gap-4">
              <i className="fa-solid fa-users"></i>
              <div>
                <h2>Total Users</h2>
                <p>{all_users?.usersLength ? all_users?.usersLength : 0}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4 mb-3">
          <div className="dashCard">
            <div className="d-flex gap-4">
              <i className="fa-regular fa-newspaper"></i>
              <div>
                <h2>Total Articles</h2>
                <p>{all_users?.articleLength ? all_users?.articleLength : 0}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4 mb-3">
          <div className="dashCard">
            <div className="d-flex gap-4">
              <i className="fa-solid fa-plane"></i>
              <div>
                <h2>Virtual Tours</h2>
                <p>{all_users?.tourLength ? all_users?.tourLength : 0}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12 mt-3 mb-3">
          <div className="dashCard">
            <div className="d-flex justify-content-between align-items-center mb-4">
              <h1>Most Viewed Article</h1>
              {/* <Button className="loginBtn w-100px">View Detail</Button> */}
            </div>
            <Chart
              options={ChartData.options}
              series={ChartData.series}
              type="area"
              height={350}
              className="w-100"
            />
          </div>
        </div>
        <div className="col-md-12 mt-3 mb-3">
          <div className="dashCard">
            <div className="d-flex justify-content-between align-items-center mb-4">
              <h1>Most visited virtual tours</h1>
              {/* <Button className="loginBtn w-100px">View Detail</Button> */}
            </div>
            <Chart
              options={ChartData.options}
              series={ChartData.series}
              type="area"
              height={350}
              className="w-100"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
