import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  frogetPass,
  register,
  reset,
} from "./../../../features/auth/authSlice";
import { toast } from "react-toastify";
import { useSnackbar } from "notistack";
import validator from "validator";

function CustomerForgetPassword() {
  const [email, setEmail] = useState("");
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const { user, isLoading, isSuccess, isError, message } = useSelector(
    (state) => state.auth
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (isSuccess || message) {
      enqueueSnackbar(message.data, { variant: "success" });
    }
    if (isError) {
      enqueueSnackbar(message.data, { variant: "error" });
    }
  }, [dispatch, isSuccess, isError, message]);

  const SubmitHandler = (event) => {
    event.preventDefault();
    if (validator.isEmail(email)) {
      dispatch(frogetPass({ email: email }));
      if (isSuccess || message) {
        toast(message.data);
      }
      if (isError) {
        toast(message.data);
      }
    } else {
      enqueueSnackbar("Please add valid email.", { variant: "error" });
    }
  };

  return (
    <div className="signupMain">
      <div className="row mx-0 loginMain">
        <div className="col-12 my-auto">
          <div className="text-center py-5">
            <img
              src="/imgs/white-logo.svg"
              className="logo"
              width={270}
              alt=""
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/")}
            />
          </div>
          <div className="row mx-0">
            <div className="col-md-8 mx-auto">
              <div className="SignupBox mb-5">
                <h3>Forget Password</h3>
                <div className="row">
                  <div className="col-md-10 mx-auto">
                    <Form className="mt-5" onSubmit={SubmitHandler}>
                      <div className="row gx-5">
                        <div className="col-md-12">
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>Email</Form.Label>
                            <InputGroup className="mb-3">
                              <InputGroup.Text id="basic-addon1">
                                <img
                                  src="/imgs/email.svg"
                                  alt=""
                                  onClick={() => navigate("/")}
                                />
                              </InputGroup.Text>
                              <Form.Control
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                              />
                            </InputGroup>
                          </Form.Group>
                        </div>
                      </div>

                      <div className="d-flex justify-content-end gap-4 mt-0 mb-3">
                        <Button
                          className="loginBtn"
                          type="submit"
                          disabled={email === "" ? true : false}
                        >
                          Submit
                        </Button>
                      </div>
                      <p className="forgotpassword">
                        Don’t have an account? <Link to="/signup">Sign up</Link>
                      </p>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CustomerForgetPassword;
