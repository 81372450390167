import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import userService from "./userService";

// Get user from localStorage
// const contrib = JSON.parse(localStorage.getItem('Users'))

const initialState = {
  Users_List: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
  isList: false,
  allStaff: [],
};

// get contributer list
export const getUsers = createAsyncThunk("user/getUsers", async (thunkAPI) => {
  try {
    return await userService.usersList();
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const addUsers = createAsyncThunk(
  "user/addUsers",
  async (userData, thunkAPI) => {
    try {
      return await userService.addUsersData(userData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const editUsers = createAsyncThunk(
  "user/editUsers",
  async (userData, thunkAPI) => {
    try {
      return await userService.editUsersData(userData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const deleteUsers = createAsyncThunk(
  "user/deleteUsers",
  async (ID, thunkAPI) => {
    try {
      return await userService.deleteUsersData(ID);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const saveImageU = createAsyncThunk(
  "user/saveImageU",
  async (bodyFormData, thunkAPI) => {
    try {
      return await userService.saveImageU(bodyFormData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// update password post
export const updatePassU = createAsyncThunk(
  "user/updatePass",
  async (passData, thunkAPI) => {
    try {
      return await userService.updatePassU(passData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// deleted rows
export const deletedRowsU = createAsyncThunk(
  "user/deletedRowsS",
  async (passData, thunkAPI) => {
    try {
      return await userService.deletedRowsU(passData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getUsersByRole = createAsyncThunk(
  "user/getUsersByRole",
  async (role, thunkAPI) => {
    try {
      return await userService.roleBaseUser(role);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = "";
      state.isList = false;
    },
    resetImage: (state) => {
      state.imageLocation = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUsers.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getUsers.fulfilled, (state, action) => {
        state.isLoading = false;
        // state.isSuccess = true
        // state.message = action.payload.data.message
        state.isList = true;
        state.Users_List = action.payload.data;
      })
      .addCase(getUsers.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.Users_List = null;
      })

      .addCase(addUsers.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addUsers.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = action.payload;
        // state.Users_List = [...state.Users_List, action.payload.data]
      })
      .addCase(addUsers.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.Users_List = null;
      })

      .addCase(editUsers.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(editUsers.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = action.payload;
        // state.Users_List = action.payload.data
      })
      .addCase(editUsers.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.Users_List = null;
      })

      .addCase(deleteUsers.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteUsers.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = action.payload;
        // state.Users_List = action.payload.data
      })
      .addCase(deleteUsers.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.Users_List = null;
      })

      .addCase(saveImageU.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(saveImageU.fulfilled, (state, action) => {
        state.isLoading = false;
        // state.isSuccess = true
        // state.message = action.payload
        state.imageLocation = action.payload.data;
        // state.profile_data = action.payload.data
      })
      .addCase(saveImageU.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.Users_List = null;
      })

      .addCase(updatePassU.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updatePassU.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        // state.user = action.payload
        state.message = action.payload.data;
      })
      .addCase(updatePassU.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
        // state.user = null
      })

      .addCase(deletedRowsU.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deletedRowsU.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        // state.user = action.payload
        state.message = action.payload.data;
      })
      .addCase(deletedRowsU.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
        // state.user = null
      })
      .addCase(getUsersByRole.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getUsersByRole.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.allStaff = action.payload.data;
        state.message = action.payload.message;
      })
      .addCase(getUsersByRole.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
        // state.user = null
      });
  },
});

export const { reset, resetImage } = userSlice.actions;
export default userSlice.reducer;
