import React from "react";
import ReactGA from "react-ga";
import { Button } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import { logout } from "../../../features/auth/authSlice";
import { useDispatch } from "react-redux";
import {
  updateLastLogin,
  logoutProfile,
} from "../../../features/MyProfile/profileSlice";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLayoutEffect } from "react";
import { useState } from "react";
import { getFrontend, reset } from "../../../features/Frontend/frontendSlice";
import DropdownButton from "react-bootstrap/DropdownButton";

ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID);

function Header({ user, setProfile, Profile, userProfile }) {
  const location = useLocation();
  const user2 = JSON.parse(localStorage.getItem("user"));
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [nav, setNav] = useState([]);
  const [OtherPages, setOtherPages] = useState([]);
  const [about, setAbout] = useState();
  const [contact, setContact] = useState();

  const { frontData, isError, isSuccess, message } = useSelector(
    (state) => state.front_Data
  );

  useEffect(() => {
    ReactGA.pageview(location.pathname);
  }, [location]);

  useLayoutEffect(() => {
    dispatch(reset());
    dispatch(getFrontend());
  }, []);

  useEffect(() => {
    if (frontData) {
      setNav(frontData?.navigationData);
      setOtherPages(frontData?.otherPages);
      setAbout(frontData?.aboutsData);
      setContact(frontData?.contactsData);
    }
  }, [isError, isSuccess, message]);

  const ChangeSwitch = () => {
    localStorage.setItem("profileData", true);
    setProfile(localStorage.getItem("profileData"));
    // setProfile(true);
    navigate("/profile");
  };

  const logout2 = () => {
    dispatch(updateLastLogin(user?.data.user._id));
    dispatch(logout());
    setTimeout(() => {
      dispatch(logoutProfile());
      window.location.reload();
    }, 1000);
    setProfile(false);
  };

  return (
    <>
      <div className="effect"></div>
      <Navbar expand="lg" className="header withoutLogin">
        <Container fluid className="px-50px">
          <div className="d-flex justify-content-between">
            <Navbar.Brand onClick={() => navigate("/")}>
              <img
                src="/imgs/white-logo.svg"
                className="logo"
                style={{ cursor: "pointer" }}
                alt=""
              />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav">
              <i className="fa-solid fa-bars"></i>
            </Navbar.Toggle>
          </div>

          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto me-4">
              {nav?.map(
                (val, i) =>
                  val.showScreen && (
                    <Nav.Link
                      key={i}
                      onClick={() => {
                        if (val.name === "Contact Us") {
                          navigate(val.url, { state: { Contact: contact } });
                        } else if (val.name === "About Us") {
                          navigate(val.url, { state: { About: about } });
                        } else {
                          navigate(val.url);
                        }
                      }}
                    >
                      {val.name}
                    </Nav.Link>
                  )
              )}
              {OtherPages?.length > 0 && (
                <DropdownButton
                  id="dropdown-basic-button"
                  className="btn btn-default"
                  title="Other Pages"
                >
                  {OtherPages?.map((val, i) => (
                    <Dropdown.Item
                      href="#"
                      key={i}
                      onClick={() => navigate(`/Other-Pages` + val?.url)}
                    >
                      {val?.name}
                    </Dropdown.Item>
                  ))}
                </DropdownButton>
              )}

              <div className="d-flex gap-3 mt-md-0 mt-3 w-md-100 justify-content-end ms-3">
                {!user2 ? (
                  <>
                    <Link to="/signup">
                      <Button className="registerBtn" type="button">
                        Register
                      </Button>
                    </Link>

                    {/* <Link to="/auth/signin">
                    <Button className="registerBtn" type="button">
                        private login
                      </Button>
                    </Link> */}

                    <Link to="/login">
                      <Button className="loginBtn" type="submit">
                        Login
                      </Button>
                    </Link>
                  </>
                ) : (
                  <>
                    <Dropdown>
                      <Dropdown.Toggle variant="success" id="dropdown-basic">
                        <div className="d-flex gap-3">
                          <img
                            src={userProfile?.profile}
                            className="profileImg"
                            width={40}
                            height={40}
                            alt=""
                          />
                          <img src="/imgs/arrow-down.svg" width={10} alt="" />
                        </div>
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <img
                          src={userProfile?.profile}
                          className="profileImgInner"
                          width={60}
                          height={60}
                          alt=""
                        />
                        <p className="name">{userProfile?.user_name}</p>
                        <div className="contributor">
                          <p>
                            {userProfile?.role === "contributor"
                              ? "user"
                              : userProfile?.role}
                          </p>
                        </div>
                        <Dropdown.Item
                          className="active"
                          href="#"
                          onClick={ChangeSwitch}
                        >
                          <img
                            src="/imgs/dashboard_icon.svg"
                            alt="dashboard_icon"
                          />{" "}
                          <span>Dashboard</span>
                        </Dropdown.Item>
                        <Dropdown.Item href="#" onClick={logout2}>
                          <img src="/imgs/sign_out.svg" alt="sign_out" />{" "}
                          Signout
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                    {/* <button className="btn btn-danger" onClick={LogoutData}>Logout</button> */}
                  </>
                )}
              </div>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default Header;
