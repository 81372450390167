import React, { useState, useEffect, useLayoutEffect } from "react";
import { Button, Modal, InputGroup, Form } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { listing_column } from "../../../../utils/constant/column_header_data";
import { useSelector, useDispatch } from "react-redux";
import DataTable from "react-data-table-component";
import {
  deletedArticle,
  deletedRowsArticles,
  getArticlePublishedAll,
  reset,
} from "../../../../features/Articles/articleSlice";
import { reset as reset1 } from "../../../../features/chatgpt/ChatgptSlice";
import { Rating, Stack } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import { getBadword } from "../../../../features/badWord/BadWordSlice";
import { formatDate } from "../../../../utils/DateHelper";

function Listing() {
  const user = JSON.parse(localStorage.getItem("user"));
  const { article_List, isError, isSuccess, message } = useSelector(
    (state) => state.article
  );
  const { frontData } = useSelector((state) => state.front_Data);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [show, setShow] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [DeltReason, setDeltReason] = useState("");
  const [D_ID, setD_ID] = useState();
  const [show2, setShow2] = useState(false);

  const handleClose = () => {
    setShow(false);
    setD_ID("");
    setDeltReason("");
  };

  const handleClose2 = () => {
    setShow2(false);
    setD_ID("");
    setDeltReason("");
  };

  const [Search, setSearch] = useState("");
  const [selectedRows, setSelectedRows] = useState(false);
  const [CList, setCList] = useState([]);

  useLayoutEffect(() => {
    dispatch(reset());
    dispatch(getArticlePublishedAll());
    setRefresh(false);
    dispatch(getBadword());
  }, [dispatch, refresh]);

  useEffect(() => {
    if (isSuccess && message) {
      toast.success(message);
    }
    if (article_List) {
      const data = [...article_List].reverse();
      setCList(data);
    }

    setTimeout(() => {
      dispatch(getArticlePublishedAll());
    }, 1000);
  }, [isSuccess, message, isError, message, selectedRows]);

  const deleteRow = () => {
    let yFilter = selectedRows.map((itemY) => {
      return itemY.id;
    });
    let filteredX = article_List.filter(
      (itemX) => !yFilter.includes(itemX._id)
    );
    const data = {
      Ids: yFilter,
      DelReason: DeltReason,
    };
    dispatch(deletedRowsArticles(data));
    setTimeout(() => dispatch(reset()), 1000);
    setD_ID("");
    setSelectedRows([]);
    setShow2(false);
    setDeltReason("");
  };

  const DeleteArticle = (ID) => {
    setD_ID(ID);
    setShow(true);
  };

  const DeltArticle = () => {
    const delt = {
      id: D_ID,
      reason: DeltReason,
    };

    dispatch(deletedArticle(delt));

    setRefresh(true);
    setD_ID("");
    setDeltReason("");
    setShow(false);
    setRefresh(false);
  };

  const onEdit = (Id) => {
    dispatch(reset1());
    navigate(`/article/article-form/${Id}`, {
      state: { type: "EditArt", user: "EditStaff" },
    });
  };

  var rows = [];
  if (CList) {
    for (let [i, value] of CList?.entries()) {
      rows[i] = {
        id: value._id,
        title: value.title,
        email: value?.owner?.email,
        author: value?.owner?.first_name + " " + value?.owner?.last_name,
        submit: formatDate(value?.createdAt),
        publish: formatDate(value?.createdAt),
        publish_type: value.publish_type,
        views_count: value.count?.length > 0 ? value.count : 0,
        // share_count: value.share ? value.share : 0,
        reviews: (
          <div className="d-flex gap-1 align-items-center">
            {/* <img src="/imgs/star.svg" alt="" /> */}
            <Stack>
              <Rating value={value?.rating} precision={0.5} readOnly />
            </Stack>
            <span>{value?.review ? value.review : 0}</span>
          </div>
        ),
        action: (
          <div>
            <div className="d-flex gap-3">
              {((user?.data?.user?.role === "staff" &&
                user?.data?.user?.permissions[0]?.ArticlesReviews?.includes(
                  "view"
                )) ||
                user?.data?.user?.role === "admin") && (
                <Link to={`/article/article-detail/${value._id}`}>
                  <img src="/imgs/u_eye.svg" width={20} alt="" />
                </Link>
              )}
              {((user?.data?.user?.role === "staff" &&
                user?.data?.user?.permissions[0]?.ArticlesReviews?.includes(
                  "view"
                )) ||
                user?.data?.user?.role === "admin") && (
                <img
                  src="/imgs/t_edit.svg"
                  width={20}
                  alt=""
                  onClick={() => onEdit(value._id)}
                />
              )}
              {((user?.data?.user?.role === "staff" &&
                user?.data?.user?.permissions[0]?.ArticlesReviews?.includes(
                  "view"
                )) ||
                user?.data?.user?.role === "admin") && (
                <img
                  src="/imgs/delete.svg"
                  width={20}
                  alt=""
                  onClick={() => DeleteArticle(value._id)}
                />
              )}

              {/* <Link to={`/article/article-detail/${value._id}`}>
                <img src="/imgs/t_edit.svg" width={20} alt="" />
              </Link> */}
            </div>
          </div>
        ),
      };
    }
  }

  const AddNew = () => {
    dispatch(reset());
    navigate("/article/article-form", {
      state: { type: "Draft", view: false, user: "Addstaff" },
    });
  };

  return (
    <div className="staffMainDiv">
      <ToastContainer />
      <div className="row pb-3 px-3">
        <div className="d-flex justify-content-between align-items-center">
          <h1 className="innerTitle">Listing</h1>
        </div>
      </div>
      <div
        className="row"
        style={{ marginLeft: "-20px", marginRight: "-20px" }}
      >
        <hr />
      </div>
      <div className="row mx-0 mb-4">
        <div className="d-flex justify-content-between">
          <div className="d-flex gap-3">
            <img
              src="/imgs/revoke.svg"
              width={20}
              alt=""
              onClick={() => setSearch("")}
            />
            <Form.Group className="mb-0">
              <InputGroup className="mb-0">
                <InputGroup.Text id="basic-addon1">
                  <img src="/imgs/search.svg" alt="" />
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  placeholder="Search"
                  value={Search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </InputGroup>
            </Form.Group>
          </div>
          {((user?.data?.user?.role === "staff" &&
            user?.data?.user?.permissions[0]?.ArticlesReviews?.includes(
              "create"
            )) ||
            user?.data?.user?.role === "admin") && (
            <Button className="loginBtn" onClick={AddNew}>
              Add New
            </Button>
          )}
        </div>
      </div>
      <DataTable
        columns={listing_column}
        data={
          rows
            ? rows.filter(
                (item) =>
                  item?.title?.toLowerCase().includes(Search.toLowerCase()) ||
                  item?.user_name
                    ?.toLowerCase()
                    .includes(Search.toLowerCase()) ||
                  item?.email?.toLowerCase().includes(Search.toLowerCase()) ||
                  item?.author?.toLowerCase().includes(Search.toLowerCase())
              )
            : []
        }
        pagination
      />

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add deleted reason</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <textarea
            className="form-control"
            placeholder="Please add reason for deleting this article..."
            rows={4}
            value={DeltReason}
            onChange={(e) => setDeltReason(e.target.value)}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={DeltArticle}
            disabled={DeltReason !== "" ? false : true}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={show2}
        onHide={handleClose2}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Are you sure you want to delete this articles?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <textarea
            rows={4}
            className="form-control"
            placeholder="Add Reason for deleting..."
            value={DeltReason}
            onChange={(e) => setDeltReason(e.target.value)}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose2}>
            Close
          </Button>
          <Button
            variant="primary"
            disabled={DeltReason !== "" ? false : true}
            onClick={deleteRow}
          >
            Sure
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Listing;
