import React, { useEffect, useLayoutEffect, useState } from "react";
import { convertFromHTML, EditorState } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Button, Form, InputGroup } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import { convertToHTML } from "draft-convert";
import ContentState from "draft-js/lib/ContentState";
import { WithContext as ReactTags } from "react-tag-input";
import { useDispatch } from "react-redux";
import { reset } from "../../../../../features/OtherPages/otherPagesSlice";
import ReactQuill from "../../../../Editor/Editor";

function OtherEditForm({
  tags,
  setTags,
  handleDelete,
  handleAddition,
  handleDrag,
  handleTagClick,
  delimiters,
  handleEditFormChange,
  UpdateForm,
  Details,
  setEditFormData,
  editFormData,
  navData,
  URL_DATA,
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useLayoutEffect(() => {
    dispatch(reset());
  }, [dispatch]);
  const { state } = useLocation();
  const [Edit, setEdit] = useState(true);
  const [value, setValue] = useState("");

  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  const [convertedContent, setConvertedContent] = useState(null);

  const handleEditorChange = (state) => {
    setEditorState(state);
    convertContentToHTML();
  };
  const convertContentToHTML = () => {
    let currentContentAsHTML = convertToHTML(editorState?.getCurrentContent());
    setConvertedContent(currentContentAsHTML);
  };

  console.log(editFormData);

  useEffect(() => {
    setTags(Details?.tags);
    const formData = {
      name: Details.name,
      url: Details.url,
      status: Details.status,
      navigation: Details.navigation,
      // content: Details.content,
      tags: Details.tags,
      description: Details.description,
    };
    setValue(Details.content);
    setEditFormData(formData);

    if (Object.keys(Details).length > 0) {
      if (Details.content != null) {
        setEditorState(
          EditorState.createWithContent(
            ContentState.createFromBlockArray(convertFromHTML(Details?.content))
          )
        );
      }
    }

    setEdit(state.editPage);
  }, [Details]);

  return (
    <div>
      <div className="cardSection">
        <div className="d-flex justify-content-between">
          <h1>New Page</h1>
          {Edit ? (
            <div className="d-flex justify-content-center gap-4 mb-3">
              <Button
                className="cancelBtn"
                type="button"
                onClick={() => setEdit(false)}
              >
                Cancel
              </Button>
              <Button className="loginBtn" onClick={() => UpdateForm(value)}>
                Update
              </Button>
            </div>
          ) : (
            <img
              src="/imgs/edit.svg"
              className="editIcon"
              alt=""
              onClick={() => setEdit(true)}
            />
          )}
        </div>
        <div className="row mx-0 mt-4">
          <div className="col-10 mx-auto">
            <div className="row gx-5">
              <div className="col-md-6">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Name</Form.Label>
                  <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1">
                      <img src="/imgs/name.svg" alt="" />
                    </InputGroup.Text>
                    <Form.Control
                      type="text"
                      value={editFormData.name}
                      disabled={!Edit}
                      name="name"
                      onChange={handleEditFormChange}
                    />
                  </InputGroup>
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>URL {editFormData.url}</Form.Label>
                  <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1">
                      <img src="/imgs/api.svg" alt="" />
                    </InputGroup.Text>
                    <Form.Control
                      type="text"
                      value={editFormData.url}
                      disabled={true}
                      name="url"
                      // onChange={handleEditFormChange}
                    />
                  </InputGroup>
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Status</Form.Label>
                  <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1">
                      <img src="/imgs/status-info.svg" alt="" />
                    </InputGroup.Text>
                    <Form.Select
                      aria-label="Default select example"
                      value={editFormData.status}
                      disabled={!Edit}
                      name="status"
                      onChange={handleEditFormChange}
                    >
                      <option value="">Select Status</option>
                      <option value="Published">Published</option>
                      <option value="Draft">Draft</option>
                      <option value="Hide">Hide</option>
                    </Form.Select>
                  </InputGroup>
                </Form.Group>
              </div>
              {/* <div className="col-md-6">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Navigation</Form.Label>
                  <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1">
                      <img src="/imgs/navigation.svg" alt="" />
                    </InputGroup.Text>
                    <Form.Select
                      aria-label="Default select example"
                      value={editFormData.navigation}
                      disabled={!Edit}
                      name="navigation"
                      onChange={handleEditFormChange}
                    >
                      <option value="">Select Navigation</option>
                      {navData?.map((val, i) => (
                        <option value={val.url} key={i}>
                          {val.name} {val.url}
                        </option>
                      ))}
                    </Form.Select>
                  </InputGroup>
                </Form.Group>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <div className="cardSection mt-4">
        <div className="d-flex justify-content-between">
          <h1>Content</h1>
        </div>
        <div className="row mx-0 mt-4">
          <div className="my-3 ugradePlanEditor">
            {/* <Editor
              // disabled={!Edit}
              readOnly={!Edit}
              value={editorState}
              editorState={editorState}
              onEditorStateChange={handleEditorChange}
              // plugins={plugins}
              spellCheck
            /> */}
            <ReactQuill value={value} setValue={setValue} />
          </div>
        </div>
      </div>
      <div className="cardSection mt-4">
        <div className="d-flex justify-content-between"></div>
        <div className="row mx-0 mt-4">
          <div className="col-10 mx-auto">
            <div className="row gx-5">
              <div className="col-md-12">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Tags</Form.Label>
                  <ReactTags
                    tags={tags}
                    autofocus={false}
                    delimiters={delimiters}
                    handleDelete={handleDelete}
                    handleAddition={handleAddition}
                    handleDrag={handleDrag}
                    handleTagClick={handleTagClick}
                    inputFieldPosition="bottom"
                    autocomplete
                    name="tags"
                    placeholder="Enter tag here"
                  />
                  {/* <Form.Control
                      type="text"
                      placeholder="Tags"
                      value={editFormData.tags}
                      disabled={!Edit}
                      name="tags"
                      onChange={handleEditFormChange}
                    /> */}
                </Form.Group>
              </div>
              <div className="col-md-12">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={5}
                    value={editFormData.description}
                    disabled={!Edit}
                    name="description"
                    onChange={handleEditFormChange}
                  />
                </Form.Group>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OtherEditForm;
