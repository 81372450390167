import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import upgradeService from './upgradeService'

const initialState = {
  upgrade_plan: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  isAdded: false,
  isUpdated: false,
  message: '',
}


export const getUpgrade = createAsyncThunk(
  'upgrade/getUpgrade',
  async (thunkAPI) => {
    try {
      return await upgradeService.upgradeList()
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const addUpgrade = createAsyncThunk(
  'upgrade/addUpgrade',
  async (userData,thunkAPI) => {
    try {
      return await upgradeService.addUpgrade(userData)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)


export const editUpgrade = createAsyncThunk(
  'upgrade/editUpgrade',
  async (userData,thunkAPI) => {
    try {
      return await upgradeService.editUpgrade(userData)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const deleteUpgrade = createAsyncThunk(
  'upgrade/deleteUpgrade',
  async (ID,thunkAPI) => {
    try {
      return await upgradeService.deleteUpgrade(ID)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

// deleted rows
export const deletedRowsUp = createAsyncThunk('upgrade/deletedRowsUp', async (passData, thunkAPI) => {
  try {
    return await upgradeService.deletedRowsUp(passData)
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

export const upgradeSlice = createSlice({
  name: 'upgrade',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false
      state.isSuccess = false
      state.isError = false
      state.isAdded = false
      state.isUpdated = false
      state.message = ''
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUpgrade.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getUpgrade.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.message = action.payload.data.message
        state.upgrade_plan = action.payload.data 
      })
      .addCase(getUpgrade.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
        state.upgrade_plan = []
      })

      .addCase(addUpgrade.pending, (state) => {
        state.isLoading = true
      })
      .addCase(addUpgrade.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.isAdded = true
        state.message = action.payload
        // state.upgrade_plan = [...state.upgrade_plan, action.payload.data]
      })
      .addCase(addUpgrade.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
        state.upgrade_plan = []
      })

      .addCase(editUpgrade.pending, (state) => {
        state.isLoading = true
      })
      .addCase(editUpgrade.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.message = action.payload
        state.isUpdated = true
        // state.upgrade_plan = action.payload.data 
      })
      .addCase(editUpgrade.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
        state.upgrade_plan = null
      })

      .addCase(deleteUpgrade.pending, (state) => {
        state.isLoading = true
      })
      .addCase(deleteUpgrade.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.message = 'Deleted!'
        // state.upgrade_plan = action.payload.data 
      })
      .addCase(deleteUpgrade.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
        state.upgrade_plan = null
      })

      .addCase(deletedRowsUp.pending, (state) => {
        state.isLoading = true
      })
      .addCase(deletedRowsUp.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.isError = false
        // state.user = action.payload
        state.message = action.payload.data
      })
      .addCase(deletedRowsUp.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.isSuccess = false
        state.message = action.payload
        // state.user = null
      })
  },
})

export const { reset } = upgradeSlice.actions
export default upgradeSlice.reducer