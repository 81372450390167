import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import postRequirementService from "./PostRequirementService";

const initialState = {
  postRequirements: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

export const getPostRequirements = createAsyncThunk(
  "postRequirement/getPostRequirement",
  async (thunkAPI) => {
    try {
      return await postRequirementService.getData();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const addPostRequirement = createAsyncThunk(
  "postRequirement/addPostRequirement",
  async (content, thunkAPI) => {
    try {
      console.log(content);
      return await postRequirementService.addData(content);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updatePostRequirement = createAsyncThunk(
  "postRequirement/updatePostRequirement",
  async (content, thunkAPI) => {
    try {
      return await postRequirementService.updateData(content);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const deletePostRequirement = createAsyncThunk(
  "postRequirement/deletePostRequirement",
  async (id, thunkAPI) => {
    console.log(id);
    try {
      return await postRequirementService.deleteData(id);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const postRequirementSlice = createSlice({
  name: "postRequirements",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = "";
      state.postRequirements = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addPostRequirement.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addPostRequirement.fulfilled, (state, action) => {
        console.log(action.payload);
        state.isLoading = false;
        state.isSuccess = true;
        state.message = "Post requirement Added!";
        // state.product_plans = [...state.product_plans, action.payload.data]
      })
      .addCase(addPostRequirement.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.postRequirements = null;
      })

      .addCase(updatePostRequirement.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updatePostRequirement.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = "Post Requirement Updated!";
        // state.product_plans = [...state.product_plans, action.payload.data]
      })
      .addCase(updatePostRequirement.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.postRequirements = null;
      })

      .addCase(getPostRequirements.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getPostRequirements.fulfilled, (state, action) => {
        console.log(action.payload);
        state.isLoading = false;
        state.isSuccess = true;
        state.message = action.payload?.message;
        state.postRequirements = action.payload.data;
      })
      .addCase(getPostRequirements.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.postRequirements = null;
      })
      .addCase(deletePostRequirement.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deletePostRequirement.fulfilled, (state, action) => {
        console.log(action.payload);
        state.isLoading = false;
        state.isSuccess = true;
        state.message = action.payload?.message;
      })
      .addCase(deletePostRequirement.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.postRequirements = null;
      });
  },
});

export const { reset } = postRequirementSlice.actions;
export default postRequirementSlice.reducer;
