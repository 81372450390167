import { Input } from "@mui/material";
import React, { useLayoutEffect, useState } from "react";
import { useEffect } from "react";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Table from "react-bootstrap/Table";

// import { useForm } from "react-hook-form";

function PermissionTbl({
  UpdateForm,
  register,
  handleSubmit,
  Edit,
  setCustomerArray,
  // setContributorArray,
  setArticlesReviews,
  setArticlesOrders,
  setVirtualReviews,
  setBilling,
  customerArray,
  contributorArray,
  ArticlesReviews,
  VirtualReviews,
  ArticlesOrders,
  Billing,
  New,
  watch,
  selectAll,
  setValue,
}) {
  const AllArray = ["All", "view", "create", "edit", "delete"];
  useEffect(() => {
    setValue("Customers", customerArray);
    // setValue('Contributors', contributorArray);
    setValue("ArticlesReviews", ArticlesReviews);
    setValue("VirtualReviews", VirtualReviews);
    setValue("ArticlesOrders", ArticlesOrders);
    setValue("Billing", Billing);
  }, [
    customerArray,
    // contributorArray,
    ArticlesOrders,
    ArticlesReviews,
    VirtualReviews,
    Billing,
    watch,
  ]);

  const removeCheck = (val, name) => {
    if (name === "Customers") {
      if (val === "All") {
        if (customerArray?.length == 0) {
          setCustomerArray(AllArray);
        } else if (customerArray?.length < 5) {
          setCustomerArray(AllArray);
        } else {
          setCustomerArray([]);
        }
      } else {
        var data = [...customerArray];
        if (data.some((val2) => val2 === val)) {
          const index = customerArray.indexOf(val);
          if (index > -1) {
            data.splice(index, 1);
            data = data.filter((v) => v !== "All");
          }
          setCustomerArray(data);
        } else {
          if (customerArray?.length == 3) {
            setCustomerArray(AllArray);
          } else {
            setCustomerArray([...data, val]);
          }
        }
      }
    }

    // if (name === "Contributors") {
    //   if (val === "All") {
    //     if (contributorArray?.length == 0) {
    //       setContributorArray(AllArray);
    //     } else if (contributorArray?.length < 5) {
    //       setContributorArray(AllArray);
    //     } else {
    //       setContributorArray([]);
    //     }
    //   } else {
    //     var data = [...contributorArray];
    //     if (data.some((val2) => val2 === val)) {
    //       const index = contributorArray.indexOf(val);
    //       if (index > -1) {
    //         data.splice(index, 1);
    //         data = data.filter((v) => v !== "All");
    //       }
    //       setContributorArray(data);
    //     } else {
    //       if (contributorArray?.length == 3) {
    //         setContributorArray(AllArray);
    //       } else {
    //         setContributorArray([...data, val]);
    //       }
    //     }
    //   }
    // }

    if (name === "ArticlesReviews") {
      if (val === "All") {
        if (ArticlesReviews?.length == 0) {
          setArticlesReviews(AllArray);
        } else if (ArticlesReviews?.length < 5) {
          setArticlesReviews(AllArray);
        } else {
          setArticlesReviews([]);
        }
      } else {
        var data = [...ArticlesReviews];
        if (data.some((val2) => val2 === val)) {
          const index = ArticlesReviews.indexOf(val);
          if (index > -1) {
            data.splice(index, 1);
            data = data.filter((v) => v !== "All");
          }
          setArticlesReviews(data);
        } else {
          if (ArticlesReviews?.length == 3) {
            setArticlesReviews(AllArray);
          } else {
            setArticlesReviews([...data, val]);
          }
        }
      }
    }

    if (name === "VirtualReviews") {
      if (val === "All") {
        if (VirtualReviews?.length == 0) {
          setVirtualReviews(AllArray);
        } else if (VirtualReviews?.length < 5) {
          setVirtualReviews(AllArray);
        } else {
          setVirtualReviews([]);
        }
      } else {
        var data = [...VirtualReviews];
        if (data.some((val2) => val2 === val)) {
          const index = VirtualReviews.indexOf(val);
          if (index > -1) {
            data.splice(index, 1);
            data = data.filter((v) => v !== "All");
          }
          setVirtualReviews(data);
        } else {
          if (VirtualReviews?.length == 3) {
            setVirtualReviews(AllArray);
          } else {
            setVirtualReviews([...data, val]);
          }
        }
      }
    }

    if (name === "ArticlesOrders") {
      if (val === "All") {
        if (ArticlesOrders?.length == 0) {
          setArticlesOrders(AllArray);
        } else if (ArticlesOrders?.length < 5) {
          setArticlesOrders(AllArray);
        } else {
          setArticlesOrders([]);
        }
      } else {
        var data = [...ArticlesOrders];
        if (data.some((val2) => val2 === val)) {
          const index = ArticlesOrders.indexOf(val);
          if (index > -1) {
            data.splice(index, 1);
            data = data.filter((v) => v !== "All");
          }
          setArticlesOrders(data);
        } else {
          if (ArticlesOrders?.length == 3) {
            setArticlesOrders(AllArray);
          } else {
            setArticlesOrders([...data, val]);
          }
        }
      }
    }

    if (name === "Billing") {
      if (val === "All") {
        if (Billing?.length == 0) {
          setBilling(AllArray);
        } else if (Billing?.length < 5) {
          setBilling(AllArray);
        } else {
          setBilling([]);
        }
      } else {
        var data = [...Billing];
        if (data.some((val2) => val2 === val)) {
          const index = Billing.indexOf(val);
          if (index > -1) {
            data.splice(index, 1);
            data = data.filter((v) => v !== "All");
          }
          setBilling(data);
        } else {
          if (Billing?.length == 3) {
            setBilling(AllArray);
          } else {
            setBilling([...data, val]);
          }
        }
      }
    }
  };

  const showData = (data) => {
    console.log("show Data edit: ", data);
  };

  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  return (
    <div className="row mx-0 mt-4">
      {/* <button onClick = {handleSubmit(showData)}>Show Data</button> */}
      <form onSubmit={handleSubmit(UpdateForm)}>
        <div className="col-10 mx-auto">
          <Table responsive bordered>
            <thead>
              <tr>
                <th>
                  <div className="d-flex justify-content-between">
                    Permissions
                  </div>
                </th>
                <th>
                  <div className="d-flex justify-content-between">
                    View Data
                  </div>
                </th>
                <th>
                  <div className="d-flex justify-content-between">
                    Create Data
                  </div>
                </th>
                <th>
                  <div className="d-flex justify-content-between">
                    Edit Data
                  </div>
                </th>
                <th>
                  <div className="d-flex justify-content-between">
                    Delete Data
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div className="d-flex justify-content-between">
                    Users
                    <div className="tblCheckBox">
                      <Form.Group controlId="formBasicCheckbox">
                        <Form.Check
                          type="checkbox"
                          disabled={Edit}
                          value="All"
                          checked={
                            JSON.stringify(customerArray) ==
                            JSON.stringify(AllArray)
                          }
                          onClick={() => removeCheck("All", "Customers")}
                          {...register("Customers")}
                        />
                      </Form.Group>
                    </div>
                  </div>
                </td>
                <td
                  className="tblCheckBox"
                  onChange={(e) => removeCheck(e.target.value, "Customers")}
                >
                  <Form.Group controlId="formBasicCheckbox">
                    <Form.Check
                      type="checkbox"
                      disabled={Edit}
                      value="view"
                      checked={customerArray?.some((val) => val === "view")}
                      {...register("Customers")}
                    />
                  </Form.Group>
                </td>
                <td
                  className="tblCheckBox"
                  onChange={(e) => removeCheck(e.target.value, "Customers")}
                >
                  <Form.Group controlId="formBasicCheckbox">
                    <Form.Check
                      type="checkbox"
                      disabled={Edit}
                      value="create"
                      checked={customerArray?.some((val) => val === "create")}
                      {...register("Customers")}
                    />
                  </Form.Group>
                </td>
                <td
                  className="tblCheckBox"
                  onChange={(e) => removeCheck(e.target.value, "Customers")}
                >
                  <Form.Group controlId="formBasicCheckbox">
                    <Form.Check
                      type="checkbox"
                      disabled={Edit}
                      value="edit"
                      checked={customerArray?.some((val) => val === "edit")}
                      {...register("Customers")}
                    />
                  </Form.Group>
                </td>
                <td
                  className="tblCheckBox"
                  onChange={(e) => removeCheck(e.target.value, "Customers")}
                >
                  <Form.Group controlId="formBasicCheckbox">
                    <Form.Check
                      type="checkbox"
                      disabled={Edit}
                      value="delete"
                      checked={customerArray?.some((val) => val === "delete")}
                      {...register("Customers")}
                    />
                  </Form.Group>
                </td>
              </tr>
              {/* <tr>
                <td>
                  <div className="d-flex justify-content-between">
                    Contributors
                    <div className="tblCheckBox">
                      <Form.Group controlId="formBasicCheckbox">
                        <Form.Check
                          type="checkbox"
                          disabled={Edit}
                          value="All"
                          checked={
                            JSON.stringify(contributorArray) ==
                            JSON.stringify(AllArray)
                          }
                          onClick={() => removeCheck("All", "Contributors")}
                          {...register("Contributors", {
                            required: {
                              value: false,
                            },
                          })}
                        />
                      </Form.Group>
                    </div>
                  </div>
                </td>
                <td
                  className="tblCheckBox"
                  onChange={(e) => removeCheck(e.target.value, "Contributors")}
                >
                  <Form.Group controlId="formBasicCheckbox">
                    <Form.Check
                      type="checkbox"
                      disabled={Edit}
                      value="view"
                      checked={contributorArray?.some((val) => val === "view")}
                      {...register("Contributors", {
                        required: {
                          value: false,
                        },
                      })}
                    />
                  </Form.Group>
                </td>
                <td
                  className="tblCheckBox"
                  onChange={(e) => removeCheck(e.target.value, "Contributors")}
                >
                  <Form.Group controlId="formBasicCheckbox">
                    <Form.Check
                      type="checkbox"
                      disabled={Edit}
                      value="create"
                      checked={contributorArray?.some(
                        (val) => val === "create"
                      )}
                      {...register("Contributors", {
                        required: {
                          value: false,
                        },
                      })}
                    />
                  </Form.Group>
                </td>
                <td
                  className="tblCheckBox"
                  onChange={(e) => removeCheck(e.target.value, "Contributors")}
                >
                  <Form.Group controlId="formBasicCheckbox">
                    <Form.Check
                      type="checkbox"
                      disabled={Edit}
                      value="edit"
                      checked={contributorArray?.some((val) => val === "edit")}
                      {...register("Contributors", {
                        required: {
                          value: false,
                        },
                      })}
                    />
                  </Form.Group>
                </td>
                <td
                  className="tblCheckBox"
                  onChange={(e) => removeCheck(e.target.value, "Contributors")}
                >
                  <Form.Group controlId="formBasicCheckbox">
                    <Form.Check
                      type="checkbox"
                      disabled={Edit}
                      value="delete"
                      checked={contributorArray?.some(
                        (val) => val === "delete"
                      )}
                      {...register("Contributors", {
                        required: {
                          value: false,
                        },
                      })}
                    />
                  </Form.Group>
                </td>
              </tr> */}
              <tr>
                <td>
                  <div className="d-flex justify-content-between">
                    Articles & Reviews
                    <div className="tblCheckBox">
                      <Form.Group controlId="formBasicCheckbox">
                        <Form.Check
                          type="checkbox"
                          disabled={Edit}
                          value="All"
                          checked={
                            JSON.stringify(ArticlesReviews) ==
                            JSON.stringify(AllArray)
                          }
                          onClick={() => removeCheck("All", "ArticlesReviews")}
                          {...register("ArticlesReviews", {
                            required: {
                              value: false,
                            },
                          })}
                        />
                      </Form.Group>
                    </div>
                  </div>
                </td>
                <td
                  className="tblCheckBox"
                  onChange={(e) =>
                    removeCheck(e.target.value, "ArticlesReviews")
                  }
                >
                  <Form.Group controlId="formBasicCheckbox">
                    <Form.Check
                      type="checkbox"
                      disabled={Edit}
                      value="view"
                      checked={ArticlesReviews?.some((val) => val === "view")}
                      {...register("ArticlesReviews", {
                        required: {
                          value: false,
                        },
                      })}
                    />
                  </Form.Group>
                </td>
                <td
                  className="tblCheckBox"
                  onChange={(e) =>
                    removeCheck(e.target.value, "ArticlesReviews")
                  }
                >
                  <Form.Group controlId="formBasicCheckbox">
                    <Form.Check
                      type="checkbox"
                      disabled={Edit}
                      value="create"
                      checked={ArticlesReviews?.some((val) => val === "create")}
                      {...register("ArticlesReviews", {
                        required: {
                          value: false,
                        },
                      })}
                    />
                  </Form.Group>
                </td>
                <td
                  className="tblCheckBox"
                  onChange={(e) =>
                    removeCheck(e.target.value, "ArticlesReviews")
                  }
                >
                  <Form.Group controlId="formBasicCheckbox">
                    <Form.Check
                      type="checkbox"
                      disabled={Edit}
                      value="edit"
                      checked={ArticlesReviews?.some((val) => val === "edit")}
                      {...register("ArticlesReviews", {
                        required: {
                          value: false,
                        },
                      })}
                    />
                  </Form.Group>
                </td>
                <td
                  className="tblCheckBox"
                  onChange={(e) =>
                    removeCheck(e.target.value, "ArticlesReviews")
                  }
                >
                  <Form.Group controlId="formBasicCheckbox">
                    <Form.Check
                      type="checkbox"
                      disabled={Edit}
                      value="delete"
                      checked={ArticlesReviews?.some((val) => val === "delete")}
                      {...register("ArticlesReviews", {
                        required: {
                          value: false,
                        },
                      })}
                    />
                  </Form.Group>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="d-flex justify-content-between">
                    Virtual Maps & Reviews
                    <div className="tblCheckBox">
                      <Form.Group controlId="formBasicCheckbox">
                        <Form.Check
                          type="checkbox"
                          disabled={Edit}
                          value="All"
                          checked={
                            JSON.stringify(VirtualReviews) ==
                            JSON.stringify(AllArray)
                          }
                          onClick={() => removeCheck("All", "VirtualReviews")}
                          {...register("VirtualReviews", {
                            required: {
                              value: false,
                            },
                          })}
                        />
                      </Form.Group>
                    </div>
                  </div>
                </td>
                <td
                  className="tblCheckBox"
                  onChange={(e) =>
                    removeCheck(e.target.value, "VirtualReviews")
                  }
                >
                  <Form.Group controlId="formBasicCheckbox">
                    <Form.Check
                      type="checkbox"
                      disabled={Edit}
                      value="view"
                      checked={VirtualReviews?.some((val) => val === "view")}
                      {...register("VirtualReviews", {
                        required: {
                          value: false,
                        },
                      })}
                    />
                  </Form.Group>
                </td>
                <td
                  className="tblCheckBox"
                  onChange={(e) =>
                    removeCheck(e.target.value, "VirtualReviews")
                  }
                >
                  <Form.Group controlId="formBasicCheckbox">
                    <Form.Check
                      type="checkbox"
                      disabled={Edit}
                      value="create"
                      checked={VirtualReviews?.some((val) => val === "create")}
                      {...register("VirtualReviews", {
                        required: {
                          value: false,
                        },
                      })}
                    />
                  </Form.Group>
                </td>
                <td
                  className="tblCheckBox"
                  onChange={(e) =>
                    removeCheck(e.target.value, "VirtualReviews")
                  }
                >
                  <Form.Group controlId="formBasicCheckbox">
                    <Form.Check
                      type="checkbox"
                      disabled={Edit}
                      value="edit"
                      checked={VirtualReviews?.some((val) => val === "edit")}
                      {...register("VirtualReviews", {
                        required: {
                          value: false,
                        },
                      })}
                    />
                  </Form.Group>
                </td>
                <td
                  className="tblCheckBox"
                  onChange={(e) =>
                    removeCheck(e.target.value, "VirtualReviews")
                  }
                >
                  <Form.Group controlId="formBasicCheckbox">
                    <Form.Check
                      type="checkbox"
                      disabled={Edit}
                      value="delete"
                      checked={VirtualReviews?.some((val) => val === "delete")}
                      {...register("VirtualReviews", {
                        required: {
                          value: false,
                        },
                      })}
                    />
                  </Form.Group>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="d-flex justify-content-between">
                    Articles Orders
                    <div className="tblCheckBox">
                      <Form.Group controlId="formBasicCheckbox">
                        <Form.Check
                          type="checkbox"
                          disabled={Edit}
                          value="All"
                          checked={
                            JSON.stringify(ArticlesOrders) ==
                            JSON.stringify(AllArray)
                          }
                          onClick={() => removeCheck("All", "ArticlesOrders")}
                          {...register("ArticlesOrders", {
                            required: {
                              value: false,
                            },
                          })}
                        />
                      </Form.Group>
                    </div>
                  </div>
                </td>
                <td
                  className="tblCheckBox"
                  onChange={(e) =>
                    removeCheck(e.target.value, "ArticlesOrders")
                  }
                >
                  <Form.Group controlId="formBasicCheckbox">
                    <Form.Check
                      type="checkbox"
                      disabled={Edit}
                      value="view"
                      checked={ArticlesOrders?.some((val) => val === "view")}
                      {...register("ArticlesOrders", {
                        required: {
                          value: false,
                        },
                      })}
                    />
                  </Form.Group>
                </td>
                <td
                  className="tblCheckBox"
                  onChange={(e) =>
                    removeCheck(e.target.value, "ArticlesOrders")
                  }
                >
                  <Form.Group controlId="formBasicCheckbox">
                    <Form.Check
                      type="checkbox"
                      disabled={Edit}
                      value="create"
                      checked={ArticlesOrders?.some((val) => val === "create")}
                      {...register("ArticlesOrders", {
                        required: {
                          value: false,
                        },
                      })}
                    />
                  </Form.Group>
                </td>
                <td
                  className="tblCheckBox"
                  onChange={(e) =>
                    removeCheck(e.target.value, "ArticlesOrders")
                  }
                >
                  <Form.Group controlId="formBasicCheckbox">
                    <Form.Check
                      type="checkbox"
                      disabled={Edit}
                      value="edit"
                      checked={ArticlesOrders?.some((val) => val === "edit")}
                      {...register("ArticlesOrders", {
                        required: {
                          value: false,
                        },
                      })}
                    />
                  </Form.Group>
                </td>
                <td
                  className="tblCheckBox"
                  onChange={(e) =>
                    removeCheck(e.target.value, "ArticlesOrders")
                  }
                >
                  <Form.Group controlId="formBasicCheckbox">
                    <Form.Check
                      type="checkbox"
                      disabled={Edit}
                      value="delete"
                      checked={ArticlesOrders?.some((val) => val === "delete")}
                      {...register("ArticlesOrders", {
                        required: {
                          value: false,
                        },
                      })}
                    />
                  </Form.Group>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="d-flex justify-content-between">
                    Billing
                    <div className="tblCheckBox">
                      <Form.Group controlId="formBasicCheckbox">
                        <Form.Check
                          type="checkbox"
                          disabled={Edit}
                          value="All"
                          checked={
                            JSON.stringify(Billing) == JSON.stringify(AllArray)
                          }
                          onClick={() => removeCheck("All", "Billing")}
                          {...register("Billing", {
                            required: {
                              value: false,
                            },
                          })}
                        />
                      </Form.Group>
                    </div>
                  </div>
                </td>
                <td
                  className="tblCheckBox"
                  onChange={(e) => removeCheck(e.target.value, "Billing")}
                >
                  <Form.Group controlId="formBasicCheckbox">
                    <Form.Check
                      type="checkbox"
                      disabled={Edit}
                      value="view"
                      checked={Billing?.some((val) => val === "view")}
                      {...register("Billing", {
                        required: {
                          value: false,
                        },
                      })}
                    />
                  </Form.Group>
                </td>
                <td
                  className="tblCheckBox"
                  onChange={(e) => removeCheck(e.target.value, "Billing")}
                >
                  <Form.Group controlId="formBasicCheckbox">
                    <Form.Check
                      type="checkbox"
                      disabled={Edit}
                      value="create"
                      checked={Billing?.some((val) => val === "create")}
                      {...register("Billing", {
                        required: {
                          value: false,
                        },
                      })}
                    />
                  </Form.Group>
                </td>
                <td
                  className="tblCheckBox"
                  onChange={(e) => removeCheck(e.target.value, "Billing")}
                >
                  <Form.Group controlId="formBasicCheckbox">
                    <Form.Check
                      type="checkbox"
                      disabled={Edit}
                      value="edit"
                      checked={Billing?.some((val) => val === "edit")}
                      {...register("Billing", {
                        required: {
                          value: false,
                        },
                      })}
                    />
                  </Form.Group>
                </td>
                <td
                  className="tblCheckBox"
                  onChange={(e) => removeCheck(e.target.value, "Billing")}
                >
                  <Form.Group controlId="formBasicCheckbox">
                    <Form.Check
                      type="checkbox"
                      disabled={Edit}
                      value="delete"
                      checked={Billing?.some((val) => val === "delete")}
                      {...register("Billing", {
                        required: {
                          value: false,
                        },
                      })}
                    />
                  </Form.Group>
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      </form>
    </div>
  );
}

export default PermissionTbl;
