import React from "react";
import Toursdata from "../../../../utils/data/toursData.json";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  clearList,
  getTour,
  reset,
} from "../../../../features/360Tours/tourSlice";
import { useLayoutEffect } from "react";
import { useEffect } from "react";
import { addAreaOIfInterest } from "../../../../features/auth/authSlice";

function Tours({ tourData, GoToTour }) {
  const user = JSON.parse(localStorage.getItem("user"));
  // const { tour_List, isError, isSuccess, message, isList, imageLocation } =
  //   useSelector((state) => state.tours);
  // const [tourData, setTourData] = React.useState([]);
  const { interestedTours, tour_List } = useSelector((state) => state.tours);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // useLayoutEffect(() => {
  //   dispatch(clearList());
  //   dispatch(reset());
  //   dispatch(getTour("All"));
  // }, []);

  // const GoToTour = (data) => {
  //   // navigate(`/property-viewing/${ID}`);
  //   // console.log({ tourData: data });
  //   // return;
  //   navigate("/virtual-tours", {
  //     state: { Data: data, tourData: tourData?.slice(0, 4) },
  //   });

  //   if (user) {
  //     const interest = {
  //       tourId: data._id,
  //       userId: user?.data?.user?._id,
  //     };
  //     dispatch(addAreaOIfInterest(interest));
  //   }
  // };

  // useEffect(() => {
  //   // if (tour_List || interestedTours) {
  //   //   const newData =
  //   //     interestedTours.length > 0
  //   //       ? interestedTours?.filter((val) => val?.status == "PUBLISHED")
  //   //       : tour_List?.filter((val) => val?.status == "PUBLISHED");
  //   // }
  //   let newData = [];
  //   if (user?.data?.token) {
  //     newData = interestedTours?.filter((val) => val?.status == "PUBLISHED");
  //   } else {
  //     newData = tour_List?.filter((val) => val?.status == "PUBLISHED");
  //   }
  //   setTourData(newData);
  // }, [interestedTours, tour_List]);

  return (
    <>
      <div className="row">
        {tourData
          ?.sort((a, b) => {
            return new Date(b.createdAt) - new Date(a.createdAt);
          })
          ?.slice(0, 4)
          ?.map((data, index) => {
            return (
              <div
                className="col-lg-3 col-md-6 mb-md-0 mb-3"
                key={index}
                onClick={() => GoToTour(data)}
              >
                <div className="tourMain">
                  {data?.cover_images?.map((val, i) => {
                    if (val.cover == true) {
                      return <img key={i} src={val.img} alt={val.img} />;
                    }
                  })}
                  <button className="loginBtn" onClick={() => GoToTour(data)}>
                    {data?.tags[0]?.text}
                  </button>
                  <div className="bottomText">
                    <h3>{data.Name}</h3>
                    <h6>{data.location.country}</h6>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
      <div className="text-center mt-5">
        <button
          className="viewAllBlog"
          onClick={() => navigate("/featured-tours")}
        >
          See All Tour
        </button>
      </div>
    </>
  );
}

export default Tours;
