import axios from "axios";
import React, { useEffect, useState } from "react";
import { Form, Button } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";

function BillingReminder() {
  const [days, setDays] = useState(0);
  const API_URL = `${process.env.REACT_APP_BACKEND_ROUTE}/payment/`;
  const getData = async () => {
    try {
      const data = await axios.get(`${API_URL}billing-reminder`);
      setDays(data?.data?.data?.days);
    } catch (error) {
      console.log(error);
      toast.error(error.message);
    }
  };
  const user = JSON.parse(localStorage.getItem("user"));

  const onSubmit = async (e) => {
    e.preventDefault();

    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          token: user?.data?.token,
        },
      };
      const data = await axios.put(
        `${API_URL}billing-reminder`,
        { days: days },
        config
      );
      setDays(data?.data?.data?.days);
      toast.success(data?.data?.message);
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong!");
    }
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <div>
      <ToastContainer />
      <div className="cardSection">
        <div className="d-flex justify-content-center align-items-center flex-column">
          <h1>Days to remind user overdue billing</h1>
          <form onSubmit={onSubmit} className="mt-4">
            <div className="gap-4 d-flex">
              <Form.Group
                className="gap-4 d-flex justify-content-end align-items-center"
                controlId="formBasicEmail"
              >
                <Form.Label>Days</Form.Label>
              </Form.Group>
              <Form.Group className="mb-2" controlId="formBasicEmail">
                {/* <InputGroup.Text id="basic-addon1"></InputGroup.Text> */}
                <Form.Control
                  type="number"
                  value={days}
                  onChange={(e) => setDays(e.target.value)}
                />
              </Form.Group>
            </div>
            <div className="d-flex justify-content-center gap-4 mt-3">
              <Button className="cancelBtn" type="button">
                Cancel
              </Button>
              <Button className="loginBtn" type="submit" onSubmit={onSubmit}>
                Save
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default BillingReminder;
