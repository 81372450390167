import axios from 'axios'

const API_URL = `${process.env.REACT_APP_BACKEND_ROUTE}/frontend/`


const getFrontend = async () => {
  const response = await axios.get(API_URL + 'front-data');
  if (response.data) {
      return response.data
  }
}

const getOtherPagesFront = async (data) => {
  const response = await axios.get(API_URL + 'front-route-data', {params: {route: data}});
  if (response.data) {
      return response.data
  }
}

const frontendService = {
    getFrontend,
    getOtherPagesFront,
  }
  
  export default frontendService
