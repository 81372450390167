import React, { useState, useEffect, useLayoutEffect } from "react";
import { Modal, Form, InputGroup, Button } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { quotation_column } from "../../../../utils/constant/column_header_data";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import {
  getQuotation,
  getQuotationDetail,
  reset,
} from "../../../../features/Quotations/quotationSlice";
import { getQuestionsScore } from "../../../../features/Questions/questionSlice";
import { toast, ToastContainer } from "react-toastify";
import { formatDate } from "../../../../utils/DateHelper";

function Quotation() {
  const user = JSON.parse(localStorage.getItem("user"));
  const role = user?.data?.user?.role;
  const ID = user?.data?.user?._id;
  const { quotation_List, isError, isSuccess, message, isList } = useSelector(
    (state) => state.quotations
  );
  const { frontData } = useSelector((state) => state.front_Data);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [Search, setSearch] = useState("");
  const [Sum, setSum] = useState(0);

  const [CList, setCList] = useState([]);
  const [DelID, setDelID] = useState("");

  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
    setDelID("");
  };

  useLayoutEffect(() => {
    dispatch(reset());
    dispatch(getQuotation());
  }, []);

  useEffect(() => {
    if (quotation_List) {
      const [questID] = quotation_List.map((val) => val.plan[0].questionaire);
      dispatch(getQuestionsScore(questID));
      let data = [...quotation_List]
        .reverse()
        .filter((val) => val.status == "Pending" || val.status == "Sent");
      if (role === "staff") {
        setCList(data);
      }
      if (role === "contributor" || role === "user") {
        data = data.filter((val) => val?.owner?._id === ID);
        setCList(data);
      }
      if (role === "admin") {
        setCList(data);
      }
    }
  }, [dispatch, navigate, isSuccess, isError, message, isList, ID]);

  const onView = (value) => {
    (user?.data?.user?.role === "staff" &&
      user?.data?.user?.permissions[0]?.ArticlesOrders?.some(
        (val) => val === "view"
      )) ||
    user?.data?.user?.role === "contributor" ||
    user?.data?.user?.role === "admin"
      ? navigate("/orders/quotation/order-detail", {
          state: { Details: value, edit: false, sum: Sum },
        })
      : toast.error("Permission denied!");
  };
  const onEdit = (value) => {
    (user?.data?.user?.role === "staff" &&
      user?.data?.user?.permissions[0]?.ArticlesOrders?.some(
        (val) => val === "edit"
      )) ||
    user?.data?.user?.role === "contributor" ||
    user?.data?.user?.role === "admin"
      ? navigate(`/orders/quotation/order-detail/${value._id}`, {
          state: { Details: value, edit: true, sum: Sum },
        })
      : toast.error("Permission denied!");

  };

  var rows = [];
  if (CList) {
    for (let [i, value] of CList?.entries()) {
      rows[i] = {
        id: value?._id,
        score: value?.totalscore != 0 ? value?.totalscore : 0,
        order_date: formatDate(value?.createdAt),
        user: value?.owner?.first_name + " " + value?.owner?.last_name,
        product_plan: value?.product_plan,
        status:
          value?.status === "Accept" ? (
            <p className="status active">{value?.status}</p>
          ) : value?.status === "Pending" ? (
            <p className="status draft">{value?.status}</p>
          ) : (
            <p className="status revoked">{value?.status}</p>
          ),
        action: (
          <div>
            <div className="d-flex gap-3">
              {/* <img
                src="/imgs/u_eye.svg"
                width={20}
                alt=""
                onClick={() => onView(value)}
              /> */}
              {value?.status == "Pending" &&
              (role === "user" || role === "contributor") ? (
                ""
              ) : (
                <img
                  src="/imgs/redirect_gray.svg"
                  width={20}
                  alt=""
                  onClick={() => onEdit(value)}
                />
              )}
            </div>
          </div>
        ),
      };
    }
  }

  return (
    <div className="staffMainDiv">
      <div className="row mx-0 mb-4">
        <ToastContainer />
        <div className="d-flex justify-content-between">
          <div className="d-flex gap-3">
            <img
              src="/imgs/revoke.svg"
              width={20}
              alt=""
              onClick={() => setSearch("")}
            />
            <Form.Group className="mb-0">
              <InputGroup className="mb-0">
                <InputGroup.Text id="basic-addon1">
                  <img src="/imgs/search.svg" alt="" />
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  placeholder="Search"
                  value={Search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </InputGroup>
            </Form.Group>
          </div>
        </div>
      </div>
      <DataTable
        columns={quotation_column}
        data={
          rows
            ? rows.filter(
                (item) =>
                  item?.product_plan
                    ?.toLowerCase()
                    .includes(Search.toLowerCase()) ||
                  item?.user?.toLowerCase().includes(Search.toLowerCase())
              )
            : []
        }
        pagination
      />
    </div>
  );
}

export default Quotation;
