import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import creditsService from "./CreditService";

const initialState = {
  creditList: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

export const getCredits = createAsyncThunk(
  "credits/getCredits",
  async (thunkAPI) => {
    try {
      return await creditsService.getData();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const addCredits = createAsyncThunk(
  "credits/addCredits",
  async (content, thunkAPI) => {
    try {
      console.log(content);
      return await creditsService.addData(content);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updateCredits = createAsyncThunk(
  "credits/updateCredits",
  async (content, thunkAPI) => {
    try {
      return await creditsService.updateData(content);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const deleteCredits = createAsyncThunk(
  "credits/deleteCredits",
  async (id, thunkAPI) => {
    console.log(id);
    try {
      return await creditsService.deleteData(id);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const creditsSlice = createSlice({
  name: "credits",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = "";
      state.creditList = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addCredits.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addCredits.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = "credit Added!";
        // state.product_plans = [...state.product_plans, action.payload.data]
      })
      .addCase(addCredits.rejected, (state, action) => {
        console.log(action.payload);
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.creditList = null;
      })

      .addCase(updateCredits.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateCredits.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = "credits Updated!";
        // state.product_plans = [...state.product_plans, action.payload.data]
      })
      .addCase(updateCredits.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(getCredits.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getCredits.fulfilled, (state, action) => {
        console.log(action.payload);
        state.isLoading = false;
        state.isSuccess = true;
        state.message = action.payload?.message;
        state.creditList = action.payload.data;
      })
      .addCase(getCredits.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(deleteCredits.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteCredits.fulfilled, (state, action) => {
        console.log(action.payload);
        state.isLoading = false;
        state.isSuccess = true;
        state.message = action.payload?.message;
      })
      .addCase(deleteCredits.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.creditList = null;
      });
  },
});

export const { reset } = creditsSlice.actions;
export default creditsSlice.reducer;
