import React, { useEffect, useState, useLayoutEffect } from "react";
import { Form, InputGroup, Button, Modal } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { productPlan_column } from "../../../../utils/constant/column_header_data";
import { useDispatch, useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import {
  deletedRowsP,
  deletePlans,
  getPlans,
  reset,
} from "../../../../features/ProductPlans/productSlice";
import DataTable from "react-data-table-component";

function ProductPlan() {
  const { product_plans, isError, message, isSuccess, isLoading } = useSelector(
    (state) => state.productPlan
  );

  const [Search, setSearch] = useState("");
  const [CList, setCList] = useState([]);

  const [delID, setDelID] = useState("");
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [revoke, setRevoke] = useState(false);

  const handleClose = () => {
    setShow(false);
    setDelID("");
    setRevoke(false);
  };

  const handleClose2 = () => {
    setShow2(false);
    setDelID("");
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();
  useLayoutEffect(() => {
    dispatch(reset());
    dispatch(getPlans());
  }, []);
  useEffect(() => {
    // dispatch(getPlans());

    if (product_plans) {
      const data = [...product_plans].reverse();
      setCList(data);
    }
    if (isError == true && message !== "") {
      toast.error(message);
      dispatch(reset());
    }
    if (isSuccess == true && message?.message !== "") {
      toast.success(message?.message);
      dispatch(reset());
    }
  }, [dispatch, isError, message, isSuccess]);

  const onView = (ID) => {
    navigate(`/settings/product-plan/form/${ID}`, { state: true });
  };

  const onEdit = (ID) => {
    navigate(`/settings/product-plan/form/${ID}`);
  };

  const onDelete = (ID, isRevoked) => {
    setDelID(ID);
    if (isRevoked) {
      return setShow(true);
    }
    setRevoke(true);
  };

  const onDelete2 = () => {
    dispatch(deletePlans(delID));
    setDelID("");
    setShow(false);
    setRevoke(false);
  };

  var rows = [];
  if (CList) {
    for (let [i, value] of CList?.entries()) {
      rows[i] = {
        id: value._id,
        name: value.name,
        description: value.description,
        product_type: value.product_type,
        product_credit: value.product_credit,
        content_generator: value?.content_generator,
        pricing_type: value?.pricing_type,
        pricing_amount:
          Number(value.pricing_amount) == 0 ? "Quoted" : value.pricing_amount,
        display: value.display,
        status:
          value.status === "Active" ? (
            <p className="status active">{value.status}</p>
          ) : (
            <p className="status revoked">{value.status}</p>
          ),
        questionaire: value.questionaire,
        action: (
          <div>
            <div className="d-flex gap-3">
              <img
                src="/imgs/u_eye.svg"
                width={20}
                alt=""
                onClick={() => onView(value._id)}
              />
              <img
                src="/imgs/t_edit.svg"
                width={20}
                alt=""
                onClick={() => onEdit(value._id)}
              />
              {/* <img src="/imgs/revoke.svg" width={20} alt="" /> */}
              {value.isRevoked && value.status === "Revoked" ? (
                <img
                  src="/imgs/delete.svg"
                  width={20}
                  alt=""
                  onClick={() => onDelete(value._id, value?.isRevoked)}
                />
              ) : (
                <img
                  src="/imgs/revoke.svg"
                  width={20}
                  alt=""
                  onClick={() => onDelete(value._id, value?.isRevoked)}
                />
              )}
            </div>
          </div>
        ),
      };
    }
  }

  return (
    <div className="staffMainDiv">
      <ToastContainer />
      <div className="row mx-0 mb-4">
        <div className="d-flex justify-content-between">
          <div className="d-flex gap-3">
            <img
              src="/imgs/revoke.svg"
              width={20}
              alt=""
              onClick={() => setSearch("")}
            />
            <Form.Group className="mb-0">
              <InputGroup className="mb-0">
                <InputGroup.Text id="basic-addon1">
                  <img src="/imgs/search.svg" alt="" />
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  placeholder="Search"
                  value={Search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </InputGroup>
            </Form.Group>
          </div>
          <Link to="/settings/product-plan/form">
            <Button className="loginBtn" type="submit">
              Add New
            </Button>
          </Link>
        </div>
      </div>
      <DataTable
        columns={productPlan_column}
        data={
          rows
            ? rows.filter(
                (item) =>
                  item?.name?.toLowerCase().includes(Search.toLowerCase()) ||
                  item?.pricing_amount
                    ?.toLowerCase()
                    .includes(Search.toLowerCase())
              )
            : []
        }
        pagination
      />

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Deleted</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this Item?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              onDelete2();
            }}
          >
            Sure
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={revoke}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Revoke</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to revoke this Item?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              onDelete2();
            }}
          >
            Sure
          </Button>
        </Modal.Footer>
      </Modal>

      {/* <Modal
        show={show2}
        onHide={handleClose2}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Deleted</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose2}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              deleteRow();
            }}
          >
            Sure
          </Button>
        </Modal.Footer>
      </Modal> */}
    </div>
  );
}

export default ProductPlan;
