import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import sortingService from "./sortingService";

const initialState = {
  sortingList: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

export const getSorting = createAsyncThunk(
  "sorting/getSorting",
  async (thunkAPI) => {
    try {
      return await sortingService.getData();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// export const addCredits = createAsyncThunk(
//   "credits/addCredits",
//   async (content, thunkAPI) => {
//     try {
//       console.log(content);
//       return await creditsService.addData(content);
//     } catch (error) {
//       const message =
//         (error.response &&
//           error.response.data &&
//           error.response.data.message) ||
//         error.message ||
//         error.toString();
//       return thunkAPI.rejectWithValue(message);
//     }
//   }
// );

export const updateSorting = createAsyncThunk(
  "sorting/updateSorting",
  async (content, thunkAPI) => {
    try {
      return await sortingService.updateData(content);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
// export const deleteCredits = createAsyncThunk(
//   "credits/deleteCredits",
//   async (id, thunkAPI) => {
//     console.log(id);
//     try {
//       return await creditsService.deleteData(id);
//     } catch (error) {
//       const message =
//         (error.response &&
//           error.response.data &&
//           error.response.data.message) ||
//         error.message ||
//         error.toString();
//       return thunkAPI.rejectWithValue(message);
//     }
//   }
// );

export const sortingSlice = createSlice({
  name: "sorting",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = "";
      state.sortingList = [];
    },
  },
  extraReducers: (builder) => {
    builder
      //   .addCase(addCredits.pending, (state) => {
      //     state.isLoading = true;
      //   })
      //   .addCase(addCredits.fulfilled, (state, action) => {
      //     state.isLoading = false;
      //     state.isSuccess = true;
      //     state.message = "credit Added!";
      //     // state.product_plans = [...state.product_plans, action.payload.data]
      //   })
      //   .addCase(addCredits.rejected, (state, action) => {
      //     console.log(action.payload);
      //     state.isLoading = false;
      //     state.isError = true;
      //     state.message = action.payload;
      //     state.creditList = null;
      //   })

      .addCase(updateSorting.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateSorting.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = "sorting Updated!";
        // state.product_plans = [...state.product_plans, action.payload.data]
      })
      .addCase(updateSorting.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(getSorting.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getSorting.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = action.payload?.message;
        state.sortingList = action.payload.data;
      })
      .addCase(getSorting.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
    //   .addCase(deleteCredits.pending, (state) => {
    //     state.isLoading = true;
    //   })
    //   .addCase(deleteCredits.fulfilled, (state, action) => {
    //     console.log(action.payload);
    //     state.isLoading = false;
    //     state.isSuccess = true;
    //     state.message = action.payload?.message;
    //   })
    //   .addCase(deleteCredits.rejected, (state, action) => {
    //     state.isLoading = false;
    //     state.isError = true;
    //     state.message = action.payload;
    //     state.creditList = null;
    //   });
  },
});

export const { reset } = sortingSlice.actions;
export default sortingSlice.reducer;
