import React, { useEffect, useLayoutEffect, useState } from "react";
import { Form, InputGroup, Button, Modal } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { questionare_column } from "../../../../utils/constant/column_header_data";
import { useSelector, useDispatch } from "react-redux";
import {
  deletedRowsQuest,
  deleteQuestion,
  getQuestions,
  reset,
} from "../../../../features/Questions/questionSlice";
import DataTable from "react-data-table-component";
import { toast, ToastContainer } from "react-toastify";

function Questionare() {
  const { questions_List, isSuccess, isError, message, isLoading, isList } =
    useSelector((state) => state.questions);

  const user = JSON.parse(localStorage.getItem("user"));
  const userID = user?.data?.user?._id;
  const role = user?.data?.user?.role;

  const [Search, setSearch] = useState("");
  const [customQestions, setCustomQuestions] = useState([]);
  const [templateQuestions, setTemplateQuestion] = useState([]);

  const [delID, setDelID] = useState("");
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);

  const handleClose = () => {
    setShow(false);
    setDelID("");
  };

  const handleClose2 = () => {
    setShow2(false);
    setDelID("");
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useLayoutEffect(() => {
    dispatch(reset());
    if (role === "admin") {
      dispatch(getQuestions({ id: "all" }));
    } else {
      dispatch(getQuestions({ id: userID }));
    }
  }, []);

  useEffect(() => {
    if (questions_List) {
      const data = [...questions_List]
        .reverse()
        .filter((item) => item.type === "template");
      const data1 = [...questions_List]
        .reverse()
        .filter((item) => item.type === "custom");
      setTemplateQuestion(data);
      setCustomQuestions(data1);
    }
  }, [dispatch, isError, message, isSuccess, questions_List]);

  useEffect(() => {
    const get = async () => {
      if (role === "admin") {
        dispatch(getQuestions({ id: "all" }));
      } else {
        dispatch(getQuestions({ id: userID }));
      }
    };
    get();
  }, [delID]);

  const ViewQuestions = (ID) => {
    navigate(`/questionnaire-details/${ID}`);
  };

  const EditQuestions = (ID) => {
    navigate(`/add-questionnaire/${ID}`, {
      state: { edit: true, view: false },
    });
  };

  const onDelete = (ID, isDelete) => {
    setDelID(ID);
    if (isDelete) {
      return setShow2(true);
    }
    setShow(true);
  };

  const onDelete2 = async () => {
    let result = await dispatch(deleteQuestion(delID));
    if (result.payload?.status == "Success") {
      toast.success(result.payload?.message);
      setDelID("");
      setShow2(false);
      setShow(false);
    }
    // console.log(result);
    // setTimeout(() => {
    // }, 500);
  };

  var rows = [];
  if (customQestions) {
    for (let [i, value] of customQestions?.entries()) {
      rows[i] = {
        id: value?._id,
        name: value?.name,
        questions: value?.questions?.length,
        status:
          value.status == "Active" ? (
            <p className="status active">{value.status}</p>
          ) : (
            <p className="status d">{value.status}</p>
          ),
        action: (
          <div className="d-flex gap-3">
            <img
              src="/imgs/u_eye.svg"
              width={20}
              alt=""
              onClick={() => ViewQuestions(value._id)}
            />

            <img
              src="/imgs/t_edit.svg"
              width={20}
              alt=""
              onClick={() => EditQuestions(value._id)}
            />
            {value.isDelete && value?.status === "In-Active" ? (
              <img
                src="/imgs/delete.svg"
                width={20}
                alt=""
                onClick={() => onDelete(value._id, value?.isDelete)}
              />
            ) : (
              <img
                src="/imgs/revoke.svg"
                width={20}
                alt=""
                onClick={() => onDelete(value._id, value?.isDelete)}
              />
            )}
            {/* <img src="/imgs/delete.svg" width={20} alt="" onClick={() => onDelete(value._id)} /> */}
          </div>
        ),
      };
    }
  }
  console.log("customQestions", customQestions);
  var rows1 = [];
  if (templateQuestions) {
    for (let [i, value] of templateQuestions?.entries()) {
      rows1[i] = {
        id: value?._id,
        name: value?.name,
        questions: value?.questions?.length,
        status:
          value.status == "Active" ? (
            <p className="status active">{value.status}</p>
          ) : (
            <p className="status d">{value.status}</p>
          ),
        action: (
          <div className="d-flex gap-3">
            <img
              src="/imgs/u_eye.svg"
              width={20}
              alt=""
              onClick={() => ViewQuestions(value._id)}
            />

            <img
              src="/imgs/t_edit.svg"
              width={20}
              alt=""
              onClick={() => EditQuestions(value._id)}
            />
          </div>
        ),
      };
    }
  }

  return (
    <div className="staffMainDiv">
      <ToastContainer />
      <div className="row mx-0 mb-4">
        <div className="d-flex justify-content-between">
          <div className="d-flex gap-3">
            <img
              src="/imgs/revoke.svg"
              width={20}
              alt=""
              onClick={() => setSearch("")}
            />
            <Form.Group className="mb-0">
              <InputGroup className="mb-0">
                <InputGroup.Text id="basic-addon1">
                  <img src="/imgs/search.svg" alt="" />
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  placeholder="Search"
                  value={Search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </InputGroup>
            </Form.Group>
          </div>
          <Link to="/add-questionnaire">
            <Button className="loginBtn" type="submit">
              Add New
            </Button>
          </Link>
        </div>
      </div>
      <DataTable
        columns={questionare_column}
        data={
          rows
            ? rows.filter((item) =>
                item.name.toLowerCase().includes(Search.toLowerCase())
              )
            : []
        }
        pagination
      />
      <div className="row">
        <h4>Template Questions</h4>
        <DataTable
          columns={questionare_column}
          data={
            rows1
              ? rows1.filter((item) =>
                  item.name.toLowerCase().includes(Search.toLowerCase())
                )
              : []
          }
          pagination
        />
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Revoked</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to revoke this Item?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              onDelete2();
            }}
          >
            Sure
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={show2}
        onHide={handleClose2}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Deleted</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose2}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              onDelete2();
            }}
          >
            Sure
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Questionare;
