import React, { useEffect, useRef } from 'react';
import MapBoxGeocoder from '@mapbox/mapbox-gl-geocoder';
import { useControl } from 'react-map-gl';
import { useValue } from '../../../../context/ContextProvider';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';

const Geocoder = ({
  setLatLng,
  Address,
  setAddress,
  index,
  handleAddressChange,
  setaddress,
  addressRef,
}) => {
  const { mapdispatch } = useValue();
  const ctrl = new MapBoxGeocoder({
    accessToken: process.env.REACT_APP_MAP_TOKEN,
    marker: false,
    collapsed: true,
  });
  useControl(() => ctrl);
  ctrl.on('result', (e) => {
    const coords = e.result.geometry.coordinates;
    setLatLng({ lng: coords[0], lat: coords[1] });

    let obj = {};
    const list = [...addressRef?.current];
    obj = list[index];
    obj = {
      ...obj,
      address: e?.result?.place_name,
      lat: coords[1],
      longi: coords[0],
    };
    list[index] = obj;
    setAddress(list);
    setaddress(obj?.address);
    addressRef.current = list;
    // let event = {};
    // event.target = {
    //   name: "address",
    //   value: {
    //     address: e?.result?.place_name,
    //     lat: coords[1],
    //     longi: coords[0],
    //   },
    // };
    // handleAddressChange(index, event);
    // setAddress(e?.result?.place_name);
    let a = 0,
      b = 0,
      c = 0,
      d = 0,
      country = '';
    for (let i = 0; i < e.result.context.length; i++) {
      const str = e.result.context[i].id;
      const beforeDot = str.substring(0, str.indexOf('.'));
      if (beforeDot === 'region') {
        a++;
        // setState(e?.result?.context[i]?.text);
      } else if (beforeDot === 'place' || beforeDot === 'district') {
        b++;
        country = e?.result?.context[i]?.text;
        // setCity(e?.result?.context[i]?.text);
      } else if (beforeDot === 'country') {
        c++;
        // setCountry(e?.result?.context[i]?.text);
      } else if (beforeDot === 'postcode') {
        d++;
        // setPostal_Code(e?.result?.context[i]?.text);
      } else {
      }
    }
    // if (c === 0 && b >= 1) setCountry(country);

    if (d === 0) {
      //   setPostal_Code("");
    }

    // setResult({
    //   x: e?.result.geometry.coordinates[0],
    //   y: e?.result.geometry.coordinates[1],
    // });
    mapdispatch({
      type: 'UPDATE_LOCATION',
      payload: { lng: coords[0], lat: coords[1] },
    });
  });

  return <div>omosadkofkdso</div>;
};

export default React.memo(Geocoder);
