import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  Row,
  Container,
  Form,
  InputGroup,
} from "react-bootstrap";
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import axios from "axios";
import { getQuotationDetail } from "../../../features/Quotations/quotationSlice";
import { useSelector, useDispatch } from "react-redux";

function AddBill() {
  const { state } = useLocation();
  const { id } = useParams();
  const navigate = useNavigate();
  const billingData = useSelector(
    (state) => state?.quotations?.quotationDetail
  );
  const dispatch = useDispatch();

  const user = JSON.parse(localStorage.getItem("user"));

  var datee = new Date();
  const day = datee.getDay();
  const month = datee.getMonth() + 1;
  const year = datee.getFullYear();
  // console.log(day, month, year);
  datee = month + "/" + day + "/" + year;
  // console.log(datee);

  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [Spinner, setSpinner] = useState(false);
  const [Open, setOpen] = useState(false);
  const [Status, setStatus] = useState("");
  const [PayType, setPayType] = useState("");
  const [PayMessage, setPayMessage] = useState("");
  const [PayMessage2, setPayMessage2] = useState("");
  const [CardEmp, setCardEmp] = useState(false);

  const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        color: "#32325d",
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#aab7c4",
        },
        borderBottom: "1px solid red",
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
  };
  useEffect(() => {
    // if (state?.Details?.payment_gateway !== "") {
    //   setPayType(state?.Details?.payment_gateway);
    // }
    if (id != undefined) {
      dispatch(getQuotationDetail(id));
    }
    if (billingData?.payment_gateway !== "") {
      setPayType(billingData?.payment_gateway);
    }
  }, [id]);

  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setShow(false);
    setSpinner(true);

    const card = elements.getElement(CardElement);
    setCardEmp(false);
    card.on("change", function (event) {
      if (event.complete) {
        // enable payment button
        setCardEmp(false);
      } else if (event.error) {
        // show validation to customer
        setCardEmp(event.empty);
        setPayMessage2("Please, enter card details first to proceed payment!");
        return;
      }
    });
    const { token } = await stripe.createToken(card);

    const sendData = {
      card_token: token?.id,
      payment_type: PayType,
      userId: user?.data?.user?._id,
      plan: billingData?.plan[0],
      amount: billingData?.plan[0]?.pricing_amount,
      quotationId: billingData?._id,
    };

    if (token?.error || !token) {
      // Show error to your customer.
      setStatus("Fail");
      setPayMessage("Invalid Card!");
      setShow(false);
      setSpinner(false);
      setOpen(true);
    } else {
      const { data } = await axios.post(
        `${process.env.REACT_APP_BACKEND_ROUTE}/payment/create-charges`,
        sendData
      );
      if (data?.status === "Success") {
        setStatus("Success");
        setPayMessage(data?.data);
        setShow(false);
        setSpinner(false);
        setOpen(true);
      }
    }
  };

  const handleSubmit2 = async () => {
    const sendData = {
      payment_type: PayType,
      userId: user?.data?.user?._id,
      plan: billingData?.plan[0],
      amount: billingData?.plan[0]?.pricing_amount,
      quotationId: billingData?._id,
    };
    const { data } = await axios.post(
      `${process.env.REACT_APP_BACKEND_ROUTE}/payment/create-charges`,
      sendData
    );
    if (data?.status === "Success") {
      window.location.href = `${data?.data}`;
    }
  };

  const getFullDate = (paidDateStr) => {
    const paidDate = new Date(paidDateStr);
    const fullYear = paidDate.getFullYear();
    const month = paidDate.getMonth() + 1; // Months are 0-based, so add 1
    const day = paidDate.getDate();
    console.log(fullYear);
    const fullDateStr = `${fullYear}-${month.toString().padStart(2, "0")}-${day
      .toString()
      .padStart(2, "0")}`;
    return fullDateStr;
  };

  return (
    <div>
      <button
        className="loginBtn"
        style={{ marginBottom: "10px" }}
        onClick={() => navigate(-1)}
      >
        Go back
      </button>
      <div className="cardSection">
        <div className="d-flex justify-content-between">
          {/* <div className="d-flex justify-content-center gap-4 mb-3">
              <Button className="cancelBtn" type="button">
                Cancel
              </Button>
              <Button className="loginBtn" type="submit">
                Update
              </Button>
            </div> */}
        </div>
        <div className="row mx-0 mt-4">
          <div className="col-10 mx-auto">
            <div className="row gx-5">
              <div className="col-md-6">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Billing Date</Form.Label>
                  <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1">
                      <img src="/imgs/dob.svg" alt="" />
                    </InputGroup.Text>
                    <Form.Control
                      disabled={true}
                      value={
                        billingData?.billingDate
                          ? billingData?.billingDate?.substring(0, 10)
                          : datee
                      }
                    />
                  </InputGroup>
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Paid At</Form.Label>
                  <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1">
                      <img src="/imgs/dob.svg" alt="" />
                    </InputGroup.Text>
                    <Form.Control
                      disabled={true}
                      value={
                        billingData?.isPaid
                          ? getFullDate(billingData?.paidDate)
                          : ""
                      }
                    />
                  </InputGroup>
                </Form.Group>
              </div>
              <div
                className="col-md-6"
                style={{ backgroundColor: "#fff0f0b5" }}
              >
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Via</Form.Label>
                  <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1">
                      <img src="/imgs/gateway.svg" alt="" />
                    </InputGroup.Text>
                    <Form.Select
                      aria-label="Default select example"
                      value={PayType}
                      onChange={(e) => setPayType(e.target.value)}
                    >
                      <option value="">Select Payment Method</option>
                      <option value="card">CARD</option>
                      <option value="paynow">PAYNOW</option>
                    </Form.Select>
                  </InputGroup>
                </Form.Group>
              </div>
              {/* <div className="col-md-6">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Billing No</Form.Label>
                  <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1">
                      <img src="/imgs/billing.svg" alt="" />
                    </InputGroup.Text>
                    <Form.Control type="text" value="N/A" />
                  </InputGroup>
                </Form.Group>
              </div> */}
              {/* <div className="col-md-6">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Invoice No</Form.Label>
                  <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1">
                      <img src="/imgs/pricing.svg" alt="" />
                    </InputGroup.Text>
                    <Form.Control type="text" value="N/A" />
                  </InputGroup>
                </Form.Group>
              </div> */}
              <div className="col-md-6">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>User</Form.Label>
                  <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1">
                      <img src="/imgs/u_user.svg" alt="" />
                    </InputGroup.Text>
                    <Form.Control
                      aria-label="Default select example"
                      value={billingData?.owner?.user_name}
                    />
                  </InputGroup>
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Amount</Form.Label>
                  <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1">
                      <img src="/imgs/amount.svg" alt="" />
                    </InputGroup.Text>
                    <Form.Control
                      type="text"
                      value={billingData?.plan?.[0]?.pricing_amount}
                    />
                  </InputGroup>
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Product Plan</Form.Label>
                  <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1">
                      <img src="/imgs/product-type.svg" alt="" />
                    </InputGroup.Text>
                    <Form.Control
                      aria-label="Default select example"
                      value={billingData?.product_plan}
                    />
                  </InputGroup>
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Pricing Type</Form.Label>
                  <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1">
                      <img src="/imgs/billing.svg" alt="" />
                    </InputGroup.Text>
                    <Form.Control
                      aria-label="Default select example"
                      value={billingData?.plan?.[0]?.pricing_type}
                    />
                  </InputGroup>
                </Form.Group>
              </div>
              {(user?.data?.user?.role === "contributor" ||
                user?.data?.user?.role === "user") && (
                // ||
                //   (user?.data?.user?.role === "user"
                <div className="col-md-6">
                  {!billingData?.isPaid && (
                    <Button
                      className="loginBtn w-100 productDetailBtn"
                      onClick={() =>
                        PayType === "card"
                          ? setShow(true)
                          : PayType === "paynow" && setShow2(true)
                      }
                      disabled={
                        PayType === "card" || PayType === "paynow"
                          ? false
                          : true
                      }
                    >
                      Pay
                    </Button>
                  )}
                </div>
                // )
              )}
            </div>
          </div>
        </div>
      </div>

      <Modal show={show} onHide={() => setShow(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Online Payment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <CardElement options={CARD_ELEMENT_OPTIONS} />
            <div className="text-center">
              {Spinner == true ? (
                <div className="loader-container">
                  <div className="loader">
                    <img src="/imgs/mouse-wala.gif" />
                  </div>
                </div>
              ) : (
                <button
                  disabled={!stripe}
                  className="btn btn-success"
                  type="Submit"
                >
                  Pay ${billingData?.plan?.[0]?.pricing_amount}
                </button>
              )}
            </div>
          </form>
        </Modal.Body>
      </Modal>

      <Modal show={show2} onHide={() => setShow2(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to pay from paynow?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow2(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSubmit2}>
            Sure
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={Open} onHide={() => setOpen(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Payment Status</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Container>
              <Row>
                <div>
                  <div className="text-center">
                    {Status === "Fail" ? (
                      <>
                        <img
                          src={"/imgs/cross.png"}
                          style={{ width: "100px" }}
                          alt="Tick Image"
                        />
                        <h3
                          className="mt-5 text-center"
                          style={{ color: "red" }}
                        >
                          {CardEmp == true ? PayMessage2 : PayMessage}
                          {/* {CardEmp} */}
                        </h3>
                      </>
                    ) : (
                      <>
                        <img
                          src={"/imgs/tick.png"}
                          style={{ width: "100px" }}
                          alt="Tick Image"
                        />
                        <h3
                          className="mt-5 text-center"
                          style={{ color: "green" }}
                        >
                          {PayMessage}
                        </h3>
                      </>
                    )}
                  </div>

                  <div className="text-center">
                    <button
                      style={{ width: "5em" }}
                      className="btn btn-primary mt-3"
                      onClick={() => {
                        setOpen(false);
                        setPayMessage2("");
                      }}
                    >
                      OK
                    </button>
                  </div>
                </div>
              </Row>
            </Container>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default AddBill;
