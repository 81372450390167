import React, { useState } from "react";
import { Rating as Rating2 } from "react-simple-star-rating";
import { Button, Form, Modal } from "react-bootstrap";
import { v4 as uuid } from "uuid";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import {
  createReview,
  deleteReview,
  replyReview,
} from "./../../../../features/360Tours/tourSlice";
import Rating from "@mui/material/Rating";
import Stack from "@mui/material/Stack";
import "./reviews.css";
import axios from "axios";
import { formatDate } from "../../../../utils/DateHelper";

function Reviews({ Details, fetch, setFetch }) {
  const user = JSON.parse(localStorage.getItem("user"));
  const ROLE = user?.data?.user?.role;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [rating, setRating] = useState(0); // initial rating value
  const [readmore, setReadmore] = useState(true);
  const [Comments, setComments] = useState("");
  const [Image, setImage] = useState("");
  const [coverImages, setCoverImages] = useState([]);
  const [visible, setVisible] = useState(false);
  const [reply, setReply] = useState([{}]);

  const uploadFileHandler = async (e) => {
    const file = e.target.files[0];
    const bodyFormData = new FormData();
    bodyFormData.append("image", file);

    const ImgRes = await axios.put(
      `${process.env.REACT_APP_BACKEND_ROUTE}/tour/uploads/s3`,
      bodyFormData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    const ID = uuid().slice(0, 8);
    const newData = {
      id: ID,
      img: ImgRes.data.data,
      cover: false,
    };
    const data = [...coverImages, newData];
    setCoverImages(data);
  };

  const deleteImg = (ID) => {
    const newData = [...coverImages];

    const data = newData.filter((s) => s.id !== ID);
    setCoverImages(data);
  };

  // Catch Rating value
  const handleRating = (rate) => {
    setRating(rate);
    // other logic
  };

  const AddRating = () => {
    if (user) {
      if (Comments && rating) {
        dispatch(
          createReview({
            ID: Details?._id,
            rating,
            Comments,
            userId: user?.data?.user?._id,
            reviewImages: coverImages,
          })
        );
        setFetch(!fetch);
        setRating(0);
        setComments("");
        setCoverImages([]);
      }
    } else {
      toast.error("Please first login to add reviews");
    }
  };
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
  };
  const handleShow = (img) => {
    setImage(img);
    setShow(true);
  };

  const deleteRev = async ({ _id }) => {
    if (window.confirm("are you sure, you want to delete?")) {
      let result = await dispatch(deleteReview({ reviewId: _id, all: false }));
      if (result?.payload?.status === "Success") {
        toast.success(result?.payload?.message);
      }
      setFetch(!fetch);
      handleClose();
    }
  };
  const handleDeleteReply = async (reviewId, replyId) => {
    if (window.confirm("are you sure, you want to delete?")) {
      let result = await dispatch(
        deleteReview({ reviewId, replyId, all: false })
      );
      if (result?.payload?.status === "Success") {
        toast.success(result?.payload?.message);
        setFetch(!fetch);
      }
    }
  };
  const handleReplyChange = (e, index, id) => {
    const { value } = e.target;
    setReply((prevReply) => {
      const updatedReply = [...prevReply];
      updatedReply[index] = { id, value };
      return updatedReply;
    });
  };
  const addReply = async (articleId, reviewId) => {
    const data = {
      userId: user?.data?.user?._id,
      name: user?.data?.user?.role,
      comment: reply?.filter((item) => item.id == reviewId)[0]?.value,
      articleId: articleId,
      reviewId: reviewId,
    };
    let result = await dispatch(replyReview(data));
    if (result?.payload?.status === "Success") {
      toast.success(result?.payload?.message);
      setFetch(!fetch);
    }
  };
  console.log(Details);
  return (
    <div className="ratingMain">
      <ToastContainer />
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h1>Tell us what you think?</h1>
          </div>
          <div className="col-sm-10 mx-auto text-end">
            <Rating2
              className="mt-3"
              onClick={handleRating}
              initialValue="4"
              ratingValue={rating} /* Available Props */
            />
            <Form>
              <Form.Group
                className="mb-3 mt-3"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Control
                  as="textarea"
                  rows={5}
                  placeholder="Please add review here..."
                  value={Comments}
                  onChange={(e) => setComments(e.target.value)}
                />
              </Form.Group>
              <div className="coverImg mb-3 mt-4 App">
                <h5>Share Your Photo Review</h5>
                <div className="image-placeholder px-3 my-4">
                  {coverImages !== [] &&
                    coverImages?.map((val) => (
                      <>
                        <div className="avatar-preview">
                          <img
                            src="/imgs/delImg.svg"
                            className="delImg"
                            alt=""
                            onClick={() => deleteImg(val.id)}
                          />

                          <div id="imagePreview">
                            <img
                              id="saveImageFile"
                              src={val.img}
                              alt={val.img}
                            />
                          </div>
                        </div>
                      </>
                    ))}
                  {
                    <div className="avatar-edit">
                      <img src="/imgs/addImg.png" alt="" />
                      <input
                        type="file"
                        // onChange={fileHandler}
                        id="imageUpload"
                        onChange={uploadFileHandler}
                      />
                      <label htmlFor="imageUpload" name=""></label>
                    </div>
                  }
                </div>
              </div>
              <div className="text-end">
                <Button
                  className="loginBtn"
                  disabled={user ? false : true}
                  onClick={AddRating}
                >
                  Submit
                </Button>
              </div>
            </Form>
          </div>
        </div>
        <div className="row">
          <div className="col-12 mt-5">
            <h2>Public Reviews</h2>
            <div className={`textToShow ${readmore ? "opened" : ""}`}>
              {Details?.reviews?.map((data, index) => {
                return (
                  <>
                    <div className="list" key={index}>
                      <div className="d-md-flex gap-4 justify-content-between">
                        <div className="d-flex gap-3">
                          <img
                            src={data?.userId?.profile}
                            alt=" user profile image"
                            style={{ borderRadius: "50%" }}
                          />
                          <div>
                            <h4>
                              {data?.userId?.first_name}{" "}
                              {data?.userId?.last_name}
                            </h4>
                            <p>{data.description}</p>
                          </div>
                        </div>
                        <div className="reviewrRating">
                          <div className="rating">
                            <Stack spacing={1}>
                              <Rating
                                name="half-rating"
                                value={data?.rating}
                                precision={0.5}
                                readOnly
                              />
                            </Stack>
                            {(user?.data?.user?._id === data?.userId?._id ||
                              user?.data?.user?.role == "admin" ||
                              user?.data?.user?.role == "staff") && (
                              // <button onClick={() => deleteRev(data)}>
                              //   delete
                              // </button>
                              <img
                                src="/imgs/delete.svg"
                                onClick={() => deleteRev(data)}
                                style={{ width: "44px", height: "22px" }}
                              />
                            )}
                          </div>
                          <h6>
                            {formatDate(data?.createdAt?.substring(0, 10))}
                          </h6>
                        </div>
                      </div>
                      <div className="d-flex flex-wrap">
                        {data?.reviewImages?.map((val) => (
                          <>
                            <div className="image-list mt-3">
                              <div id="imagePreview1">
                                <img
                                  id="saveImageFile"
                                  onClick={() => handleShow(val.img)}
                                  src={val.img}
                                  alt={val.img}
                                />
                              </div>
                            </div>
                          </>
                        ))}
                      </div>
                    </div>
                    <div
                      className="m-4 me-0"
                      style={{ marginLeft: "40px !important" }}
                    >
                      {data?.replies.map((val, i) => (
                        <>
                          <div className="d-flex align-items-center gap-2">
                            {/* <div> */}
                            <img
                              src={val?.userId?.profile}
                              style={{ width: "30px", height: "30px" }}
                              alt=""
                            />{" "}
                            {/* </div> */}
                            <div>
                              <h5> {val?.name}</h5>
                              <div className="d-flex align-items-center gap-1">
                                <h6>
                                  {formatDate(val?.createdAt?.substring(0, 10))}
                                </h6>
                              </div>
                            </div>
                            <div className="align-self-end ms-auto">
                              <img
                                src="/imgs/delete.svg"
                                width={20}
                                style={{
                                  height: "24px",
                                }}
                                alt=""
                                onClick={() =>
                                  handleDeleteReply(data._id, val?._id)
                                }
                              />
                            </div>
                          </div>
                          <p>{val?.comment}</p>
                        </>
                      ))}
                    </div>
                    {(ROLE == "admin" || ROLE == "staff") && (
                      <div className="replyDiv">
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="d-flex align-items-center w-100">
                            {/* <img src="/imgs/profile.png" alt="" /> */}
                            <Form.Control
                              className="border-0 w-100"
                              type="text"
                              name="first_name"
                              placeholder="Reply"
                              value={reply[index]?.value || ""}
                              onChange={(e) =>
                                handleReplyChange(e, index, data?._id)
                              }
                            />
                          </div>
                          <Button
                            className="loginBtn"
                            onClick={() => addReply(Details?._id, data?._id)}
                          >
                            Reply
                          </Button>
                        </div>
                      </div>
                    )}
                  </>
                );
              })}
            </div>

            <div className={`readMoreBtn loadMore ${readmore ? "opened" : ""}`}>
              <button onClick={() => setReadmore(!readmore)}>Load More</button>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Preview Image</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="imagePreviewModal">
            <img id="saveImageFile" src={Image} alt={Image} />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Reviews;
