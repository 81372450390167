import React, { useRef } from "react";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { useState } from "react";
import { useLayoutEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import { getProfile } from "../../../features/MyProfile/profileSlice";
import TourList from "./_part/ToursList";
import { getTour, reset } from "../../../features/360Tours/tourSlice";

const blackTags = [
  "Singapore",
  "Waterfall",
  "World Tour",
  "Best Resturants in Signapore",
];

function FeaturedTours({ setProfile, Profile }) {
  const user = JSON.parse(localStorage.getItem("user"));
  const { state } = useLocation();
  const { tour_List, isError, isSuccess, message } = useSelector(
    (state) => state.tours
  );
  const {
    profile_data,
    isLoading,
    isError: isErrorAuth,
    isSuccess: isSuccessAuth,
    message: messageAuth,
  } = useSelector((state) => state.profileData);

  const ref = useRef(null);

  const [Tours, setTours] = useState([]);
  const [Search, setSearch] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useLayoutEffect(() => {
    dispatch(reset());
    dispatch(getTour("All"));
  }, []);

  useEffect(() => {
    if (tour_List) {
      const newData = [...tour_List];
      let data = newData
        .filter((val) => val?.status == "PUBLISHED")
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      setTours(data);
    }
    if (user?.data.user._id) {
      dispatch(getProfile(user?.data.user._id));
      // dispatch(updateLastLogin(ID));
    }
    if (isError == true && message) {
      toast.error(message, { variant: "error" });
      // dispatch(reset())
    }
  }, [isSuccess, isError, message]);

  const SearchFun = () => {
    const newData = [...tour_List];
    let searchTerm = Search.toLowerCase();
    let filtered = newData.filter((obj) =>
      obj.Name.toLowerCase().includes(searchTerm)
    );
    setTours(filtered);
  };

  return (
    <div className="blogMain">
      <ToastContainer />
      <Header
        user={user}
        userProfile={profile_data}
        setProfile={setProfile}
        Profile={Profile}
      />
      <div className="blogBanner">
        <img src="/imgs/footerLogo.png" className="logo" alt="" />
        <div className="container-fluid px-50px">
          <div className="row mx-0">
            <div className="col-md-7 veticallyCentered mx-auto text-center">
              <h1>Virtual Tours</h1>
              <Form.Group className="mb-3 mt-5" controlId="formBasicEmail">
                <InputGroup className="mb-3">
                  <Form.Control
                    type="text"
                    placeholder="Type To Search"
                    value={Search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                  <InputGroup.Text id="basic-addon1">
                    <Button className="loginBtn" onClick={SearchFun}>
                      Search
                    </Button>
                  </InputGroup.Text>
                </InputGroup>
              </Form.Group>
              {/* <div className="blackTags pt-3">
                {blackTags.map((data, index) => {
                  return <p key={index}>{data}</p>;
                })}
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <div className="featuredArticles">
        <div className="container-fluid px-50px" ref={ref}>
          {/* below is featured tours section */}
          <TourList Tours={Tours} />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default FeaturedTours;
