import React, { useEffect, useLayoutEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { reset, verifyEmail } from '../../../features/auth/authSlice';

function VerifyEmail() {

    const path = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const token = path.pathname.split("/")[2];

    const Auth = useSelector(
        (state) => state.auth
      );
      const { user, isLoading, isError, isSuccess, message, isVerified} = Auth

      useLayoutEffect(()=>{
        if(token){
          dispatch(verifyEmail(token))
        }
      },[])
    useEffect(()=>{
      
        if(isSuccess == true && isVerified == true){
            localStorage.removeItem('user');
            localStorage.removeItem("reg_user");
            localStorage.setItem("profileData", false);
            dispatch(reset())
            navigate('/login')
        }
        
    },[isError, isSuccess, message, isVerified])

  return (
    <div className="App pt-5">
    {
        (isSuccess == true && isVerified == true ) ? (
            <h3 style={{width:"fit-content", textAlign:'center'}}>Your account has been activated. Go to <NavLink to={'/'}>Login</NavLink> page!</h3>
        ):(
            <h3 style={{width:"fit-content", textAlign:'center'}}>Verifying...</h3>
        )
    }
    
    </div>
  )
}

export default VerifyEmail