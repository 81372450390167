import React, { Fragment, useEffect, useState, useRef } from "react";
import { Button, Form, InputGroup, Accordion } from "react-bootstrap";
import { useDispatch } from "react-redux";
import {
  addContactContent,
  getContactData,
  reset,
} from "../../../../features/ContactPage/contactSlice";
import { useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import { useLayoutEffect } from "react";
import validator from "validator";
import SearchControl from "../../../SharedSearch/SearchControl";
import L from "leaflet";
import { OpenStreetMapProvider } from "react-leaflet-geosearch";
import "leaflet/dist/leaflet.css";
import { useNavigate } from "react-router-dom";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import VirtualMap from "../../../Customer/VirtualTours/_part/VirtualMap";
import Geocoder from "./Geocoder";
import ReactMapGL, {
  GeolocateControl,
  Marker,
  NavigationControl,
} from "react-map-gl";
import { useValue } from "../../../../context/ContextProvider";
import { Box } from "@mui/material";
import "mapbox-gl/dist/mapbox-gl.css";

function ContactUs() {
  var key = process.env.REACT_APP_LEAFLET_MAP;

  const prov = OpenStreetMapProvider();
  const {
    state: {
      location: { lng, lat },
    },
    mapdispatch,
  } = useValue();
  // const [lat, setLat] = React.useState(1.3521);
  // const [longi, setlongi] = React.useState(103.8198);
  const [Result, setResult] = useState();
  const [Edit, setEdit] = useState({ isEdit: true, section: 0 });

  const contactD = useSelector((state) => state.contactData);
  const { contact, isError, isSuccess, message, isLoading } = contactD;

  const [PageContent, setPageContent] = useState({
    description: "",
    display: "",
  });
  const [Address, setAddress] = useState([]);
  const [address, setaddress] = useState("");
  const [Contact, setContact] = useState([]);
  const [Email, setEmail] = useState([]);
  const [latLng, setLatLng] = useState(["103.8198", "1.3521"]);
  const [latLng1, setLatLng1] = useState(["103.8198", "1.3521"]);

  const [country, setCountry] = useState("");
  const [state2, setState] = useState("");
  const [city, setCity] = useState("");
  const [postal_code, setPostal_Code] = useState("");

  const [DefaultEmail, setDefaultEmail] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const mapRef = useRef();
  const addressRef = useRef(Address);

  useLayoutEffect(() => {
    dispatch(reset());
    dispatch(getContactData());
  }, []);

  useEffect(() => {
    if (contact?.length > 0) {
      const pageContent = { ...[contact[0].PageContent[0]] };
      const address = contact[0].Address;
      const contactt = contact[0].Contact;
      const emaill = contact[0].Email;
      const default_Email = { ...[contact[0].defaullt_email] };
      setDefaultEmail(default_Email[0]);
      setPageContent({
        description: pageContent[0].description,
        display: pageContent[0].display,
      });

      setAddress(address);
      addressRef.current = address;
      setContact(contactt);
      setEmail(emaill);
    }
  }, [isSuccess, isError, message]);

  useEffect(() => {
    // diplay messages
    if (isSuccess == true && message?.message !== "") {
      toast.success(message?.message);
      dispatch(reset());
    }
    if (isError == true && message !== "") {
      toast.error(message);
      dispatch(reset());
    }

    // dispatch(getContactData());
  }, [isSuccess, isError, message, lat]);

  const handleContentChange = (event, section) => {
    event.preventDefault();
    if (section == 1) {
      const fieldName = event.target.getAttribute("name");
      const fieldValue = event.target.value;

      const newFormData = { ...PageContent };
      newFormData[fieldName] = fieldValue;

      setPageContent(newFormData);
    }
  };

  const handleAddressChange = (index, evnt) => {
    let obj = {};
    const { name, value } = evnt.target;
    console.log(index, evnt);

    console.log(value, index, name);
    const list = [...Address];
    obj = list[index];
    console.log(obj);
    if (name === "main") {
      if (evnt.target.checked == true) {
        obj = { ...obj, [name]: true };
        list[index] = obj;
      } else {
        obj = { ...obj, [name]: false };
        list[index] = obj;
      }
    } else if (name === "display") {
      if (evnt.target.checked == true) {
        obj = { ...obj, [name]: true };
        list[index] = obj;
      } else {
        obj = { ...obj, [name]: false };
        list[index] = obj;
      }
    } else if (name == "name") {
      obj = { ...obj, name: value };
      list[index] = obj;
    } else {
      obj = { ...obj, [name]: value };
      list[index] = obj;
    }

    setAddress(list);
  };

  const handleChangeAddress = (index) => {
    let obj = {};
    const list = [...Address];
    obj = list[index];
    console.log(obj);
    console.log(address);
    obj = { ...obj, address: address };
    list[index] = obj;
    console.log(list);
    setAddress(list);
  };

  const handleAddressChange2 = (index, result, name) => {
    let obj = {};
    const list = [...Address];
    obj = list[index];
    obj = {
      ...obj,
      [name]: result?.label,
      ["lat"]: result?.y,
      ["longi"]: result?.x,
    };
    list[index] = obj;
    setAddress(list);
  };

  const handleContactChange = (index, evnt, property) => {
    // let obj = {};
    // const { name, value } = evnt.target;
    // console.log(index, value, name, 777);

    // const list = [...Contact];
    // obj = list[index];
    // obj = { ...obj, [name]: value };
    // list[index] = obj;
    // setContact(list);
    const newData = [...Contact];

    const updatedObject = { ...newData[index] };

    updatedObject[property] = evnt;
    newData[index] = updatedObject;

    setContact(newData);
  };

  const handleEmailChange = (index, evnt) => {
    let obj = {};
    let { name, value } = evnt.target;
    let list = [...Email];
    obj = list[index];
    obj = { ...obj, [name]: value };
    list[index] = obj;
    setEmail(list);
  };

  const handleAddAddress = () => {
    setAddress([
      ...Address,
      {
        name: "",
        address: "",
        main: "",
        display: "",
        // lat,
        lat: 1.3521,
        // lng,
        lngi: 103.8198,
      },
    ]);
  };

  const handleAddContact = () => {
    setContact([
      ...Contact,
      {
        name: "",
        display: "",
        contact: "",
      },
    ]);
  };

  const handleAddEmail = () => {
    setEmail([
      ...Email,
      {
        name: "",
        display: "",
        email: "",
      },
    ]);
  };

  const handleAddContent = async (event, section) => {
    event.preventDefault();
    if (section == 1) {
      const userData = {
        description: PageContent.description,
        display: PageContent.display,
      };
      dispatch(addContactContent({ userData, section: 1 }));
    }
    if (section == 2) {
      for (let i = 0; i < Address?.length; i++) {
        console.log(Address[i], 7777);

        if (
          validator.isEmail(Address[i].name) ||
          validator.isDecimal(Address[i].name) ||
          validator.isEmpty(Address[i].name)
        ) {
          return toast.error("Please, enter a valid name");
        }
      }

      let filterdData = Address?.filter(
        (val, index) => (val.name && val.address) !== ""
      );
      dispatch(addContactContent({ userData: filterdData, section: 2 }));
    }

    if (section == 3) {
      for (let i = 0; i < Contact?.length; i++) {
        if (
          validator.isEmail(Contact[i].name) ||
          validator.isAlpha(Contact[i].name, "en-US", { ignore: " " }) == false
        ) {
          toast.error("Please, enter a valid name");
          return;
        }
        if (Contact[i].contact === "" || Contact[i].contact == undefined) {
          toast.error("Phone number is required!");
          return;
        }
        if (Contact[i].contact?.length < 7) {
          toast.error("Please, enter a valid contact number");
          return;
        }
      }
      dispatch(addContactContent({ userData: Contact, section: 3 }));
    }

    if (section == 4) {
      for (let i = 0; i < Email?.length; i++) {
        if (
          validator.isEmail(Email[i].name) ||
          validator.isDecimal(Email[i].name) ||
          validator.isAlpha(Email[i].name, "en-US", { ignore: " " }) == false
        ) {
          toast.error("Please, enter a valid name");
          return;
        }
        if (validator.isEmail(Email[i].email) == false) {
          toast.error("Please, enter a valid email");
          return;
        }
      }
      const data = await dispatch(
        addContactContent({ userData: Email, section: 4 })
      );
    }

    if (section == 5) {
      if (validator.isEmail(DefaultEmail)) {
        const userData = {
          DefaultEmail: DefaultEmail,
        };
        dispatch(addContactContent({ userData, section: 5 }));
      } else {
        toast.error("Please, enter a valid email");
      }
    }
    // setEdit(true);
  };
  const handleMarkerChange = async (lat, lng, index) => {
    const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${lng},${lat}.json?access_token=${process.env.REACT_APP_MAP_TOKEN}`;
    try {
      const response = await fetch(url);
      const data = await response.json();
      console.log(data);
      const result = data.features[0];
      const address = result.place_name;
      const country = result.context.find((c) =>
        c.id.includes("country")
      )?.text;
      const state = result.context.find((c) => c.id.includes("region"))?.text;
      const city = result.context.find((c) => c.id.includes("place"))?.text;
      const postCode = data.features.find((c) =>
        c.id.includes("postcode")
      )?.text;
      mapdispatch({
        type: "UPDATE_LOCATION",
        payload: { lng, lat },
      });
      console.log(address);
      let obj = {};
      const list = [...Address];
      obj = list[index];
      obj = { ...obj, address, lat, longi: lng };
      list[index] = obj;
      setAddress(list);
      setaddress(address);
      // setCountry(country);
      // setState(state);
      // setCity(city);
      // setPostal_Code(postCode);
      setLatLng([lng, lat]);
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const handleGeolocate = async (event, index) => {
    debugger;
    const { longitude, latitude } = event.coords;
    const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${longitude},${latitude}.json?access_token=${process.env.REACT_APP_MAP_TOKEN}`;
    try {
      const response = await fetch(url);
      const data = await response.json();

      const result = data.features[0];
      const address = result.place_name;
      console.log("result:", result);
      const country = result.context.find((c) => c.id.includes("country")).text;
      console.log("country", country);
      const state = result.context.find((c) => c.id.includes("region")).text;
      const city = result.context.find((c) => c.id.includes("place")).text;
      mapdispatch({
        type: "UPDATE_LOCATION",
        payload: { lng: longitude, lat: latitude },
      });
      console.log("address", address);

      let obj = {};
      const list = [...Address];
      console.log(list);
      obj = list[index];
      obj = {
        ...obj,
        address: address,
        lat: latitude,
        longi: longitude,
      };
      list[index] = obj;
      setAddress(list);
      setaddress(address);
      setCountry(country);
      setState(state);
      setCity(city);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div>
      <ToastContainer />
      <div className="cardSection">
        <div className="d-flex justify-content-between">
          <h1>Page Content</h1>
          {Edit.isEdit == false && Edit.section == 1 ? (
            <div className="d-flex justify-content-center gap-4 mb-3">
              <Button
                className="cancelBtn"
                type="button"
                onClick={() => setEdit({ isEdit: true, section: 0 })}
              >
                Cancel
              </Button>
              <Button
                className="loginBtn"
                onClick={(event) => handleAddContent(event, 1)}
              >
                Save
              </Button>
            </div>
          ) : (
            <div className="d-flex justify-content-center gap-4 mb-3">
              <img
                src="/imgs/edit.svg"
                style={{ cursor: "pointer" }}
                onClick={() => setEdit({ isEdit: false, section: 1 })}
                alt="Edit"
              />
            </div>
          )}
        </div>
        <div className="row mx-0 mt-4">
          <div className="col-10 mx-auto">
            <div className="row gx-5">
              <div className="col-md-12">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    disabled={Edit.isEdit == true && Edit.section == 0}
                    name="description"
                    value={PageContent.description}
                    onChange={(event) => handleContentChange(event, 1)}
                  />
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Display</Form.Label>
                  <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1">
                      <img src="/imgs/password.svg" alt="" />
                    </InputGroup.Text>
                    <Form.Select
                      aria-label="Default select example"
                      disabled={Edit.isEdit == true && Edit.section == 0}
                      name="display"
                      value={PageContent.display}
                      onChange={(event) => handleContentChange(event, 1)}
                    >
                      <option value="">Select Display</option>
                      <option value="Visible">Visible</option>
                      <option value="Revoked">Revoked</option>
                    </Form.Select>
                  </InputGroup>
                </Form.Group>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="cardSection mt-3">
        <div className="d-flex justify-content-between">
          <h1>Address</h1>
          {Edit.isEdit == false && Edit.section == 2 ? (
            <div className="d-flex justify-content-center gap-4 mb-3">
              <Button
                className="cancelBtn"
                type="button"
                onClick={() => setEdit({ isEdit: true, section: 0 })}
              >
                Cancel
              </Button>
              <Button
                className="loginBtn"
                type="submit"
                onClick={(event) => handleAddContent(event, 2)}
              >
                Save
              </Button>
            </div>
          ) : (
            <div className="d-flex justify-content-center gap-4 mb-3">
              <img
                src="/imgs/edit.svg"
                style={{ cursor: "pointer" }}
                onClick={() => setEdit({ isEdit: false, section: 2 })}
                alt="Edit"
              />
            </div>
          )}
        </div>
        <Accordion>
          <Accordion.Item eventKey="0">
            <Accordion.Body>
              <div className="row mx-0 mt-4">
                <div className="col-10 mx-auto">
                  {Address?.map((data, index) => {
                    const { name, address, main, display, lat, longi } = data;
                    return (
                      <div className="row gx-5" key={index}>
                        <div className="col-md-6">
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>Name</Form.Label>
                            <InputGroup className="mb-3">
                              <InputGroup.Text id="basic-addon1">
                                <img src="/imgs/u_user.svg" alt="" />
                              </InputGroup.Text>
                              <Form.Control
                                type="text"
                                disabled={
                                  Edit.isEdit == true && Edit.section == 0
                                }
                                name="name"
                                value={name}
                                onChange={(event) =>
                                  handleAddressChange(index, event)
                                }
                              />
                            </InputGroup>
                          </Form.Group>
                        </div>
                        <div className="col-md-12">
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>Address</Form.Label>
                            <Form.Control
                              as="textarea"
                              rows={3}
                              disabled={
                                Edit.isEdit == true && Edit.section == 0
                              }
                              name="address"
                              value={address}
                              onChange={(event) =>
                                handleAddressChange(index, event)
                              }
                            />
                          </Form.Group>
                        </div>
                        {Edit.isEdit == false && Edit.section == 2 && (
                          <>
                            <div className="col-12 adressCheckbox">
                              <Form.Group
                                className="mb-3"
                                controlId="formBasicCheckbox"
                              >
                                <Form.Check
                                  type="checkbox"
                                  label="Main Address"
                                  name="main"
                                  value={"main"}
                                  checked={main == true ? true : false}
                                  onChange={(event) =>
                                    handleAddressChange(index, event)
                                  }
                                />
                              </Form.Group>
                              <Form.Group
                                className="mb-3"
                                controlId="formBasicCheckbox"
                              >
                                <Form.Check
                                  type="checkbox"
                                  label="Display Address"
                                  name="display"
                                  value={"display"}
                                  checked={display == true ? true : false}
                                  onChange={(event) =>
                                    handleAddressChange(index, event)
                                  }
                                  // onChange={(event) => handleContentChange(event, 2)}
                                />
                              </Form.Group>
                            </div>
                            <div className="col-md-12 px-0">
                              <Form.Group
                                className="mb-3"
                                controlId="formBasicEmail"
                              >
                                <Form.Label>Map Location</Form.Label>
                              </Form.Group>
                              <Box
                                sx={{
                                  height: 400,
                                  position: "relative",
                                }}
                              >
                                <ReactMapGL
                                  ref={mapRef}
                                  mapboxAccessToken="pk.eyJ1IjoiYmx1ZXNpbG9zZyIsImEiOiJjbGZyeDZ1MGswMG1mM2RxcmJyajRzbXRyIn0.SwkzsvT0dgMXSafnYQoZSQ"
                                  initialViewState={{
                                    zoom: 11,
                                    longitude: `${
                                      longi && Address[index] ? longi : 103.8198
                                    }`,
                                    latitude: `${lat ? lat : 1.3521}`,
                                    // longitude: `${103.8198}`,
                                    // latitude: `${1.3521}`,
                                  }}
                                  mapStyle="mapbox://styles/mapbox/streets-v11"
                                >
                                  <Marker
                                    latitude={
                                      Address[index]?.lat
                                        ? Address[index]?.lat
                                        : 1.3521
                                    }
                                    longitude={
                                      Address[index]?.longi
                                        ? Address[index]?.longi
                                        : 103.8198
                                    }
                                    // latitude={1.3521}
                                    // longitude={103.8198}
                                    draggable
                                    onDragEnd={(e) => {
                                      handleMarkerChange(
                                        e.lngLat.lat,
                                        e.lngLat.lng,
                                        index
                                      );
                                      // handleChangeAddress(index);
                                      mapdispatch({
                                        type: "UPDATE_LOCATION",
                                        payload: {
                                          lng: e.lngLat.lng,
                                          lat: e.lngLat.lat,
                                        },
                                      });
                                    }}
                                  />
                                  <NavigationControl position="bottom-right" />
                                  <GeolocateControl
                                    position="top-left"
                                    // trackUserLocation
                                    onGeolocate={(e) =>
                                      handleGeolocate(e, index)
                                    }
                                  />
                                  <Geocoder
                                    index={index}
                                    handleAddressChange={handleAddressChange}
                                    // setResult={setResult}
                                    setAddress={setAddress}
                                    Address={addressRef.current}
                                    addressRef={addressRef}
                                    setaddress={setaddress}
                                    // setCountry={setCountry}
                                    // setState={setState}
                                    // setCity={setCity}
                                    // setPostal_Code={setPostal_Code}
                                    setLatLng={setLatLng}
                                  />
                                </ReactMapGL>
                              </Box>
                            </div>
                          </>
                        )}
                      </div>
                    );
                  })}
                </div>
                <span
                  className="btn btn-default text-center"
                  style={{ color: "#3356FF" }}
                  onClick={
                    Edit.isEdit == false &&
                    Edit.section == 2 &&
                    handleAddAddress
                  }
                >
                  Add Address
                </span>
              </div>
            </Accordion.Body>
            <Accordion.Header>
              {" "}
              <ArrowDropDownIcon color="#3356FF" />
            </Accordion.Header>
          </Accordion.Item>
        </Accordion>
      </div>
      <div className="cardSection mt-3">
        <div className="d-flex justify-content-between">
          <h1>Contact</h1>
          {Edit.isEdit == false && Edit.section == 3 ? (
            <div className="d-flex justify-content-center gap-4 mb-3">
              <Button
                className="cancelBtn"
                type="button"
                onClick={() => setEdit({ isEdit: true, section: 0 })}
              >
                Cancel
              </Button>
              <Button
                className="loginBtn"
                type="submit"
                onClick={(event) => handleAddContent(event, 3)}
              >
                Save
              </Button>
            </div>
          ) : (
            <div className="d-flex justify-content-center gap-4 mb-3">
              <img
                src="/imgs/edit.svg"
                style={{ cursor: "pointer" }}
                onClick={() => setEdit({ isEdit: false, section: 3 })}
                alt="Edit"
              />
            </div>
          )}
        </div>
        <Accordion>
          <Accordion.Item eventKey="0">
            <Accordion.Body>
              <div className="row mx-0 mt-4">
                <div className="col-10 mx-auto">
                  {Contact?.map((data, index) => {
                    const { name, display, contact } = data;
                    return (
                      <>
                        <div className="row gx-5" key={index}>
                          <div className="col-md-6">
                            <Form.Group
                              className="mb-3"
                              controlId="formBasicEmail"
                            >
                              <Form.Label>Name</Form.Label>
                              <InputGroup className="mb-3">
                                <InputGroup.Text id="basic-addon1">
                                  <img src="/imgs/u_user.svg" alt="" />
                                </InputGroup.Text>
                                <Form.Control
                                  type="text"
                                  disabled={
                                    Edit.isEdit == true && Edit.section == 0
                                  }
                                  name="name"
                                  value={name}
                                  onChange={(event) =>
                                    handleContactChange(
                                      index,
                                      event.target.value,
                                      "name"
                                    )
                                  }
                                />
                              </InputGroup>
                            </Form.Group>
                          </div>
                          <div className="col-md-6">
                            <Form.Group
                              className="mb-3"
                              controlId="formBasicEmail"
                            >
                              <Form.Label>Display</Form.Label>
                              <InputGroup className="mb-3">
                                <InputGroup.Text id="basic-addon1">
                                  <img src="/imgs/password.svg" alt="" />
                                </InputGroup.Text>
                                <Form.Select
                                  aria-label="Default select example"
                                  disabled={
                                    Edit.isEdit == true && Edit.section == 0
                                  }
                                  name="display"
                                  value={display}
                                  onChange={(event) =>
                                    handleContactChange(
                                      index,
                                      event.target.value,
                                      "display"
                                    )
                                  }
                                >
                                  <option value="">Select Display</option>
                                  <option value="Visible">Visible</option>
                                  <option value="Revoked">Revoked</option>
                                </Form.Select>
                              </InputGroup>
                            </Form.Group>
                          </div>
                          <div className="col-md-6">
                            <Form.Group
                              className="mb-3"
                              controlId="formBasicEmail"
                            >
                              <Form.Label>Contact</Form.Label>
                              <InputGroup className="mb-3">
                                <InputGroup.Text id="basic-addon1">
                                  <img src="/imgs/phone.svg" alt="" />
                                </InputGroup.Text>
                                <Form.Control
                                  onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                      event.preventDefault();
                                    }
                                  }}
                                  type="text"
                                  min="0"
                                  disabled={
                                    Edit.isEdit == true && Edit.section == 0
                                  }
                                  name="contact"
                                  value={contact}
                                  onChange={(event) =>
                                    handleContactChange(
                                      index,
                                      event.target.value,
                                      "contact"
                                    )
                                  }
                                />
                              </InputGroup>
                            </Form.Group>
                          </div>
                        </div>
                        <br />
                      </>
                    );
                  })}
                </div>
                <span
                  className="btn btn-default text-center"
                  style={{ color: "#3356FF" }}
                  onClick={
                    Edit.isEdit == false &&
                    Edit.section == 3 &&
                    handleAddContact
                  }
                >
                  Add Contact
                </span>
              </div>
            </Accordion.Body>
            <Accordion.Header>
              <ArrowDropDownIcon color="#3356FF" />{" "}
            </Accordion.Header>
          </Accordion.Item>
        </Accordion>
      </div>
      <div className="cardSection mt-3">
        <div className="d-flex justify-content-between">
          <h1>Email</h1>
          {Edit.isEdit == false && Edit.section == 4 ? (
            <div className="d-flex justify-content-center gap-4 mb-3">
              <Button
                className="cancelBtn"
                type="button"
                onClick={() => setEdit({ isEdit: true, section: 0 })}
              >
                Cancel
              </Button>
              <Button
                className="loginBtn"
                type="submit"
                onClick={(event) => handleAddContent(event, 4)}
              >
                Save
              </Button>
            </div>
          ) : (
            <div className="d-flex justify-content-center gap-4 mb-3">
              <img
                src="/imgs/edit.svg"
                style={{ cursor: "pointer" }}
                onClick={() => setEdit({ isEdit: false, section: 4 })}
                alt="Edit"
              />
            </div>
          )}
        </div>
        <Accordion>
          <Accordion.Item eventKey="0">
            <Accordion.Body>
              <div className="row mx-0 mt-4">
                <div className="col-10 mx-auto">
                  {Email?.map((data, index) => {
                    const { name, display, email } = data;
                    return (
                      <>
                        <div className="row gx-5" key={index}>
                          <div className="col-md-6">
                            <Form.Group
                              className="mb-3"
                              controlId="formBasicEmail"
                            >
                              <Form.Label>Name</Form.Label>
                              <InputGroup className="mb-3">
                                <InputGroup.Text id="basic-addon1">
                                  <img src="/imgs/u_user.svg" alt="" />
                                </InputGroup.Text>
                                <Form.Control
                                  type="text"
                                  disabled={
                                    Edit.isEdit == true && Edit.section == 0
                                  }
                                  name="name"
                                  value={name}
                                  onChange={(event) =>
                                    handleEmailChange(index, event)
                                  }
                                />
                              </InputGroup>
                            </Form.Group>
                          </div>
                          <div className="col-md-6">
                            <Form.Group
                              className="mb-3"
                              controlId="formBasicEmail"
                            >
                              <Form.Label>Display</Form.Label>
                              <InputGroup className="mb-3">
                                <InputGroup.Text id="basic-addon1">
                                  <img src="/imgs/password.svg" alt="" />
                                </InputGroup.Text>
                                <Form.Select
                                  aria-label="Default select example"
                                  disabled={
                                    Edit.isEdit == true && Edit.section == 0
                                  }
                                  name="display"
                                  value={display}
                                  onChange={(event) =>
                                    handleEmailChange(index, event)
                                  }
                                >
                                  <option value="">Select Display</option>
                                  <option value="Visible">Visible</option>
                                  <option value="Revoked">Revoked</option>
                                </Form.Select>
                              </InputGroup>
                            </Form.Group>
                          </div>
                          <div className="col-md-6">
                            <Form.Group
                              className="mb-3"
                              controlId="formBasicEmail"
                            >
                              <Form.Label>Email</Form.Label>
                              <InputGroup className="mb-3">
                                <InputGroup.Text id="basic-addon1">
                                  <img src="/imgs/email.svg" alt="" />
                                </InputGroup.Text>
                                <Form.Control
                                  type="email"
                                  disabled={Edit.isEdit}
                                  name="email"
                                  value={email}
                                  onChange={(event) =>
                                    handleEmailChange(index, event)
                                  }
                                />
                              </InputGroup>
                            </Form.Group>
                          </div>
                        </div>
                        <br />
                      </>
                    );
                  })}
                </div>
                <span
                  className="btn btn-default text-center"
                  style={{ color: "#3356FF" }}
                  onClick={
                    Edit.isEdit == false && Edit.section == 4 && handleAddEmail
                  }
                >
                  Add Email
                </span>
              </div>
            </Accordion.Body>
            <Accordion.Header>
              <ArrowDropDownIcon color="#3356FF" />
            </Accordion.Header>
          </Accordion.Item>
        </Accordion>
      </div>
      <div className="cardSection mt-3">
        <div className="d-flex justify-content-between">
          <h1>Default Email Query Contact</h1>

          {Edit.isEdit == false && Edit.section == 5 ? (
            <div className="d-flex justify-content-center gap-4 mb-3">
              <Button
                className="cancelBtn"
                type="button"
                onClick={() => setEdit({ isEdit: true, section: 0 })}
              >
                Cancel
              </Button>
              <Button
                className="loginBtn"
                onClick={(event) => handleAddContent(event, 5)}
              >
                Save
              </Button>
            </div>
          ) : (
            <div className="d-flex justify-content-center gap-4 mb-3">
              <img
                src="/imgs/edit.svg"
                style={{ cursor: "pointer" }}
                onClick={() => setEdit({ isEdit: false, section: 5 })}
                alt="Edit"
              />
            </div>
          )}
        </div>
        <div className="row mx-0 mt-4">
          <div className="col-10 mx-auto">
            <div className="row gx-5">
              <div className="col-md-6">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Email Input</Form.Label>
                  <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1">
                      <img src="/imgs/u_user.svg" alt="" />
                    </InputGroup.Text>
                    <Form.Control
                      type="email"
                      disabled={Edit.isEdit == true && Edit.section == 0}
                      name="DefaultEmail"
                      value={DefaultEmail}
                      onChange={(e) => setDefaultEmail(e.target.value)}
                    />
                  </InputGroup>
                </Form.Group>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactUs;
