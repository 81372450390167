import "./App.css";

import "bootstrap/dist/css/bootstrap.min.css";
import "../src/assets/css/style.css";

import MainLayout from "./components/Layouts/MainLayout/MainLayout";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import axios from "axios";
import { useState, useEffect } from "react";

function App() {
  const [keys, setKeys] = useState("");

  useEffect(() => {
    const ApiFun = async () => {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BACKEND_ROUTE}/auth/stripe-keys`
      );
      setKeys(data?.data?.publish_key);
    };
    ApiFun();
  }, []);

  const stripePromise = loadStripe(keys);
  return (
    <Elements stripe={stripePromise}>
      <MainLayout />
    </Elements>
  );
}

export default App;
