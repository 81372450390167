import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Modal, Form, InputGroup } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import {
  getSorting,
  updateSorting,
  reset,
} from "../../../../features/sortingHIghlight/sortingSlice";

const Highlight = () => {
  const dispatch = useDispatch();
  const [score, setScore] = useState(0);
  const [days, setDays] = useState(0);
  const [Edit, setEdit] = useState(false);
  const [show, setShow] = useState(false);
  const [editData, setEditData] = useState("");
  let { sortingList, isSuccess, message, isLoading, isError } = useSelector(
    (state) => state.sorting
  );
  const EditData = (type) => {
    setEdit(true);
    setShow(true);
    // setId(val._id);
    setEditData(type);
  };
  const handleClose = () => {
    // setName("");
    // setCredit("");
    setShow(false);
    setEdit(false);
  };

  useEffect(() => {
    if (sortingList) {
      setScore(sortingList.score);
      setDays(sortingList.days);
    }
  }, [sortingList]);

  useEffect(() => {
    dispatch(getSorting());
  }, []);
  const UpdateSorting = async () => {
    let result = await dispatch(updateSorting({ score, days }));
    if (result?.payload?.status == "Success") {
      toast.success(result?.payload?.message);
      setShow(false);
      setEdit(false);
    }
  };
  return (
    <div>
      <ToastContainer />
      <div className="d-flex flex-column gap-4 my-2">
        <div className="d-flex flex-column gap-3 w-50 me-auto">
          <label className="mb-1">
            Highlight
            <span
              className="py-2 px-5 mx-3 w-100px"
              style={{
                backgroundColor: "blue",
                borderRadius: "10px",
                color: "white",
                textAlign: "center",
              }}
            >
              BLUE
            </span>
            If "Score" More Then: (Points)
          </label>
          <span
            className="border border-dark py-3 px-3 rounded"
            onClick={() => EditData("score")}
            style={{
              cursor: "pointer",
              fontFamily: '"Inter", sans-serif',
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "12px",
              lineHeight: "15px",
              color: "#747474",
            }}
          >
            {score || 0} POINTS
          </span>
        </div>
        <div className="d-flex flex-column gap-3 w-50 me-auto">
          <label>
            Highlight
            <span
              className="py-2 px-5 mx-3 w-100p"
              style={{
                backgroundColor: "red",
                borderRadius: "10px",
                color: "white",
                textAlign: "center",
              }}
            >
              RED
            </span>
            If "Days to due date" Less Then: (Days)
          </label>
          <span
            className="border border-dark py-3 px-3 rounded"
            onClick={() => EditData("days")}
            style={{
              cursor: "pointer",
              fontFamily: '"Inter", sans-serif',
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "12px",
              lineHeight: "15px",
              color: "#747474",
            }}
          >
            {days || 0} DAYS
          </span>
        </div>
        {/* </thead> */}
        {/* <tbody> */}
        {/* {credits?.map((item, index) => (
        <tr key={item._id}>
          <td>{item.name}</td>
          <td onClick={() => EditData(item)} style={{ cursor: "pointer" }}>
            {item.credits}
          </td>
        </tr>
      ))} */}
        {/* </tbody> */}
      </div>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Body>
          <div className="d-flex justify-content-between mb-3"></div>
          <div className="row gx-5">
            <div className="col-md-12">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>{editData}</Form.Label>
                <InputGroup className="mb-3">
                  <InputGroup.Text id="basic-addon1">
                    <img src="/imgs/u_user.svg" alt="" />
                  </InputGroup.Text>
                  <Form.Control
                    type="text"
                    value={editData === "score" ? score : days}
                    onChange={
                      editData === "score"
                        ? (e) => setScore(e.target.value)
                        : (e) => setDays(e.target.value)
                    }
                  />
                </InputGroup>
              </Form.Group>
            </div>
          </div>

          <div className="d-sm-flex justify-content-center text-center gap-4">
            {Edit == true ? (
              <>
                <Button
                  className="cancelBtn me-sm-0 me-3 mb-sm-0 mb-2"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
                <Button
                  className="loginBtn me-sm-0 me-3"
                  onClick={UpdateSorting}
                >
                  Update
                </Button>
              </>
            ) : (
              <>
                <Button
                  className="cancelBtn me-sm-0 me-3 mb-sm-0 mb-2"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
                <Button
                  className="loginBtn me-sm-0 me-3"
                  //   disabled={(name && credits) === "" ? true : false}
                >
                  Save
                </Button>
              </>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Highlight;
