import React, { useState, useEffect, useLayoutEffect } from "react";
import { Form, InputGroup, Button, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { publishedContArticle_column } from "../../../utils/constant/column_header_data";
import { useSelector, useDispatch } from "react-redux";
import {
  deletedArticle,
  deletedRowsArticles,
  getArticlePublished,
  reset,
} from "../../../features/Articles/articleSlice";
import DataTable from "react-data-table-component";
import { Rating, Stack } from "@mui/material";
import { formatDate } from "../../../utils/DateHelper";

function PublishedContributorArticle() {
  const user = JSON.parse(localStorage.getItem("user"));

  const ID = user?.data?.user?._id;
  const { article_List, isError, isSuccess, message, isList, imageLocation } =
    useSelector((state) => state.article);
  const { frontData } = useSelector((state) => state.front_Data);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [Search, setSearch] = useState("");
  const [selectedRows, setSelectedRows] = useState(false);
  const [CList, setCList] = useState([]);
  const [DelID, setDelID] = useState("");
  const [DelReason, setDelReason] = useState("");

  const [toggledClearRows, setToggleClearRows] = useState(false);

  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);

  const handleClose = () => {
    setShow(false);
    setDelID("");
    setDelReason("");
  };

  const handleClose2 = () => {
    setShow2(false);
    setDelID("");
    setDelReason("");
  };

  useLayoutEffect(() => {
    dispatch(reset());
    dispatch(getArticlePublished(ID));
  }, []);

  useEffect(() => {
    dispatch(getArticlePublished(ID));
    if (article_List) {
      console.log("articles: ", article_List);
      setCList(article_List);
    }
  }, [
    dispatch,
    navigate,
    isSuccess,
    isError,
    message,
    selectedRows,
    isList,
    imageLocation,
    ID,
    CList,
  ]);

  const deleteRow = () => {
    let yFilter = selectedRows.map((itemY) => {
      return itemY.id;
    });
    let filteredX = article_List.filter(
      (itemX) => !yFilter.includes(itemX._id)
    );
    const data = {
      Ids: yFilter,
      DelReason: DelReason,
    };
    dispatch(deletedRowsArticles(data));
    dispatch(reset());
    setDelID("");
    setSelectedRows([]);
    setShow2(false);
    setDelReason("");
  };

  const onView = (Id) => {
    navigate(`/article/article-form/${Id}`, {
      state: { type: "Publish", view: true },
    });
  };
  const onEdit = (Id) => {
    navigate(`/article/article-form/${Id}`, {
      state: { type: "Publish", view: false, user: "user" },
    });
  };

  const onDelete = (Id) => {
    setDelID(Id);
    setShow(true);
  };

  const DelFun = () => {
    const Dele = {
      id: DelID,
      reason: DelReason,
    };
    dispatch(deletedArticle(Dele));
    setDelID("");
    setDelReason("");
    setShow(false);
  };

  var rows = [];
  if (CList) {
    for (let [i, value] of CList?.entries()) {
      rows[i] = {
        id: value?._id,
        title: value?.title,
        created_at: value?.createdAt
          ? formatDate(value?.createdAt, frontData.timezone)
          : "",
        submited_at: value?.createdAt
          ? formatDate(value?.createdAt, frontData.timezone)
          : "",
        publish_type: value?.publish_type,
        publish_at: value?.updatedAt
          ? formatDate(value?.updatedAt, frontData.timezone)
          : "",
        views_count: value.count ? value.count : 0,
        share_count: value.share ? value.share : 0,
        reviews: (
          <div className="d-flex gap-1 align-items-center">
            {/* <img src="/imgs/star.svg" alt="" /> */}
            <Stack>
              <Rating value={value?.rating} precision={0.5} readOnly />
            </Stack>
            <span>{value?.review ? value.review : 0}</span>
          </div>
        ),
        action: (
          <div>
            <div className="d-flex gap-3">
              <img
                src="/imgs/u_eye.svg"
                width={20}
                alt=""
                onClick={() => onView(value._id)}
              />

              <img
                src="/imgs/t_edit.svg"
                width={20}
                alt=""
                onClick={() => onEdit(value._id)}
              />

              <img
                src="/imgs/delete.svg"
                width={20}
                alt=""
                onClick={() => onDelete(value._id)}
              />
            </div>
          </div>
        ),
      };
    }
  }

  return (
    <div className="staffMainDiv">
      <div className="row mx-0 mb-4">
        <div className="d-flex justify-content-between">
          <div className="d-flex gap-3">
            <img
              src="/imgs/revoke.svg"
              width={20}
              alt=""
              onClick={() => setSearch("")}
            />
            <Form.Group className="mb-0">
              <InputGroup className="mb-0">
                <InputGroup.Text id="basic-addon1">
                  <img src="/imgs/search.svg" alt="" />
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  placeholder="Search"
                  value={Search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </InputGroup>
            </Form.Group>
          </div>
          {/* <Link to="/article/article-form">
            <Button className="loginBtn" type="submit">
              Add New
            </Button>
          </Link> */}
        </div>
      </div>
      <DataTable
        columns={publishedContArticle_column}
        data={
          rows
            ? rows.filter((item) =>
                item.title.toLowerCase().includes(Search.toLowerCase())
              )
            : []
        }
        pagination
      />

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Are you sure you want to delete this article?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <textarea
            rows={4}
            className="form-control"
            placeholder="Add Reason for deleting..."
            value={DelReason}
            onChange={(e) => setDelReason(e.target.value)}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="primary"
            disabled={DelReason !== "" ? false : true}
            onClick={DelFun}
          >
            Sure
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={show2}
        onHide={handleClose2}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Are you sure you want to delete this articles?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <textarea
            rows={4}
            className="form-control"
            placeholder="Add Reason for deleting..."
            value={DelReason}
            onChange={(e) => setDelReason(e.target.value)}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose2}>
            Close
          </Button>
          <Button
            variant="primary"
            disabled={DelReason !== "" ? false : true}
            onClick={deleteRow}
          >
            Sure
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default PublishedContributorArticle;
