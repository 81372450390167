import React, { useEffect, useState } from "react";
import { useLayoutEffect } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getQuestions,
  reset,
} from "../../../../features/Questions/questionSlice";

function PlanEditForm({
  handleEditFormChange,
  UpdateForm,
  Details,
  setEditFormData,
  editFormData,
  ShowPrice,
  QtyH,
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [Edit, setEdit] = useState(true);
  const { state } = useLocation();

  const { questions_List, isSuccess, isError, message, isLoading, isList } =
    useSelector((state) => state.questions);

  const user = JSON.parse(localStorage.getItem("user"));

  const [questionsData, setQuestionsData] = useState([]);

  useLayoutEffect(() => {
    dispatch(reset());
    if (user?.data?.user?.role !== "admin") {
      dispatch(getQuestions({ id: user?.data?.user?._id }));
    }
    if (user?.data?.user?.role === "admin") {
      dispatch(getQuestions({ id: "all", type: "custom" }));
    }
    // dispatch(getQuestions({ id: "all", type: "custom" }));
  }, []);

  console.log(Details);

  useEffect(() => {
    if (isList == true) {
      const dataQ = questions_List?.filter((val) => val.status !== "In-Active");
      setQuestionsData(dataQ);
    }
    const formData = {
      name: Details?.name,
      description: Details?.description,
      product_type: Details?.product_type,
      product_credit: Details?.product_credit,
      content_generator: Details?.content_generator,
      pricing_type: Details?.pricing_type,
      pricing_amount: Details?.pricing_amount,
      display: Details?.display,
      status: Details?.status,
      questionaire: Details?.questionaire?._id,
    };
    setEditFormData(formData);
  }, [Details, isList]);

  return (
    <div>
      <button
        className="loginBtn"
        style={{ marginBottom: "10px" }}
        onClick={() => navigate(-1)}
      >
        Go back
      </button>
      <div className="cardSection">
        <div className="d-flex justify-content-between">
          <h1>Product Plan Form</h1>
          {Edit == false ? (
            <div className="d-flex justify-content-center gap-4 mb-3">
              <Button
                className="cancelBtn"
                type="button"
                onClick={() => setEdit(true)}
              >
                Cancel
              </Button>
              <Button className="loginBtn" onClick={UpdateForm}>
                Save
              </Button>
            </div>
          ) : (
            !state && (
              <img
                src="/imgs/edit.svg"
                className="editIcon"
                alt=""
                onClick={() => setEdit(false)}
              />
            )
          )}
        </div>
        <div className="row mx-0 mt-4">
          <div className="col-10 mx-auto">
            <div className="row gx-5">
              <div className="col-md-12">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Name</Form.Label>
                  <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1">
                      <img src="/imgs/name.svg" alt="" />
                    </InputGroup.Text>
                    <Form.Control
                      type="text"
                      value={editFormData.name}
                      disabled={Edit}
                      name="name"
                      onChange={handleEditFormChange}
                    />
                  </InputGroup>
                </Form.Group>
              </div>
              <div className="col-md-12">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    value={editFormData.description}
                    disabled={Edit}
                    name="description"
                    onChange={handleEditFormChange}
                  />
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Product Type</Form.Label>
                  <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1">
                      <img src="/imgs/product-type.svg" alt="" />
                    </InputGroup.Text>
                    <Form.Select
                      aria-label="Default select example"
                      style={{ background: "#fff" }}
                      disabled={Edit}
                      value={editFormData.product_type}
                      name="product_type"
                      onChange={handleEditFormChange}
                    >
                      <option value="">Select Product Type</option>
                      <option value="Blog">Blog</option>
                      <option value="Video">Video</option>
                      <option value="360 tour">360 tour</option>
                    </Form.Select>
                  </InputGroup>
                </Form.Group>
              </div>
              {QtyH == false &&
                editFormData?.content_generator !== "Commissioned" && (
                  <div className="col-md-6">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Product Credit Qty</Form.Label>
                      <InputGroup className="mb-3">
                        <InputGroup.Text id="basic-addon1">
                          <img src="/imgs/pricing.svg" alt="" />
                        </InputGroup.Text>
                        <Form.Control
                          type="number"
                          name="product_credit"
                          disabled={Edit}
                          style={{ background: "#fff" }}
                          value={editFormData.product_credit}
                          onChange={handleEditFormChange}
                        />
                      </InputGroup>
                    </Form.Group>
                  </div>
                )}

              <div className="col-md-6">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Content Generator</Form.Label>
                  <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1">
                      <img src="/imgs/name.svg" alt="" />
                    </InputGroup.Text>
                    <Form.Select
                      aria-label="Default select example"
                      disabled={Edit}
                      style={{ background: "#fff" }}
                      value={editFormData.content_generator}
                      name="content_generator"
                      onChange={handleEditFormChange}
                    >
                      <option value="">Select Content Generated</option>
                      <option value="Self-Generated">Self-Generated</option>
                      <option value="Commissioned">Commissioned</option>
                    </Form.Select>
                  </InputGroup>
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Pricing Type</Form.Label>
                  <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1">
                      <img src="/imgs/credit.svg" alt="" />
                    </InputGroup.Text>
                    <Form.Select
                      aria-label="Default select example"
                      disabled={Edit}
                      style={{ background: "#fff" }}
                      value={editFormData?.pricing_type}
                      name="pricing_type"
                      onChange={handleEditFormChange}
                    >
                      <option value="">Select Pricing Type</option>
                      <option value="Quoted">Quoted</option>
                      <option value="Valued">Valued</option>
                    </Form.Select>
                  </InputGroup>
                </Form.Group>
              </div>
              {ShowPrice == false && editFormData.pricing_type == "Valued" && (
                <div className="col-md-6">
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Pricing Amount</Form.Label>
                    <InputGroup className="mb-3">
                      <InputGroup.Text id="basic-addon1">
                        <img src="/imgs/amount.svg" alt="" />
                      </InputGroup.Text>
                      <Form.Control
                        type="number"
                        value={editFormData.pricing_amount}
                        disabled={
                          Edit == true ? true : ShowPrice == true ? true : false
                        }
                        name="pricing_amount"
                        onChange={handleEditFormChange}
                      />
                    </InputGroup>
                  </Form.Group>
                </div>
              )}

              <div className="col-md-6">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Display</Form.Label>
                  <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1">
                      <img src="/imgs/password.svg" alt="" />
                    </InputGroup.Text>
                    <Form.Select
                      aria-label="Default select example"
                      value={editFormData.display}
                      style={{ background: "#fff" }}
                      disabled={Edit}
                      name="display"
                      onChange={handleEditFormChange}
                    >
                      <option value="">Select Display</option>
                      <option value="Visible">Visible</option>
                      <option value="Hide">Hide</option>
                    </Form.Select>
                  </InputGroup>
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Status</Form.Label>
                  <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1">
                      <img src="/imgs/status-info.svg" alt="" />
                    </InputGroup.Text>
                    <Form.Select
                      aria-label="Default select example"
                      value={editFormData.status}
                      style={{ background: "#fff" }}
                      disabled={Edit}
                      name="status"
                      onChange={handleEditFormChange}
                    >
                      <option value="">Select Status</option>
                      <option value="Active">Active</option>
                      <option value="Revoked">Revoked</option>
                    </Form.Select>
                  </InputGroup>
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Questionaire</Form.Label>
                  <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1">
                      <img src="/imgs/status-info.svg" alt="" />
                    </InputGroup.Text>
                    <Form.Select
                      aria-label="Default select example"
                      value={editFormData.questionaire}
                      style={{ background: "#fff" }}
                      disabled={Edit}
                      name="questionaire"
                      onChange={handleEditFormChange}
                    >
                      <option value="">Select</option>
                      {questionsData?.map((val, i) => (
                        <option value={val._id} key={i}>
                          {val.name}
                        </option>
                      ))}
                    </Form.Select>
                  </InputGroup>
                </Form.Group>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PlanEditForm;
