import React, { useEffect, useState } from "react";
import Table from "../../../Table/Table";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import { userManagement_column } from "../../../../utils/constant/column_header_data";
import { userManagement_data } from "../../../../utils/constant/column_row_data";
import { useDispatch, useSelector } from "react-redux";
import {
  deletedRowsC,
  getContributors,
  reset,
} from "../../../../features/contributers/contSlice";
import { toast } from "react-toastify";
import Modal from "react-bootstrap/Modal";

function Contributor() {
  const user = JSON.parse(localStorage.getItem("user"));
  const Contributor = user?.data?.user?.permissions[0]?.Contributors;
  const { contributors_List, isError, message, isSuccess, isList, isLoading } =
    useSelector((state) => state.contributors);

  const [selectedRows, setSelectedRows] = React.useState(false);
  const [CList, setCList] = React.useState([]);
  const [Search, setSearch] = useState("");

  const dispatch = useDispatch();
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => setShow(true);

  useEffect(() => {
    dispatch(getContributors());

    if (contributors_List) {
      const data = [...contributors_List].reverse();
      setCList(data);
    }
    if (isSuccess == true) {
      dispatch(reset());
    }
  }, [dispatch, isError, message, isSuccess, isList, selectedRows]);

  const deleteRow2 = () => {
    handleShow();
  };
  const deleteRow = () => {
    let yFilter = selectedRows.map((itemY) => {
      return itemY.id;
    });
    let filteredX = contributors_List.filter(
      (itemX) => !yFilter.includes(itemX._id)
    );
    dispatch(deletedRowsC(yFilter));
    dispatch(reset());
    setShow(false);
  };

  return (
    <div className="staffMainDiv">
      <div className="row mx-0 mb-4">
        <div className="d-flex justify-content-between">
          <div className="d-flex gap-3">
            {/* {
              (user?.data?.user?.role === 'admin' || ((user?.data?.user?.role === 'staff') && (Contributor !== false && Contributor?.some(val=> val === 'delete')) )) && (
                
                selectedRows.length > 0 ? (
                  <img src="/imgs/delete.svg" width={20} alt="" onClick={deleteRow2}/>
              ) : (
                  <img src="/imgs/delete.svg" width={20} alt=""/>
              )

              )
              
            } */}
            <img
              src="/imgs/revoke.svg"
              width={20}
              alt=""
              onClick={() => setSearch("")}
            />
            <Form.Group className="mb-0">
              <InputGroup className="mb-0">
                <InputGroup.Text id="basic-addon1">
                  <img src="/imgs/search.svg" alt="" />
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  placeholder="Search"
                  value={Search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </InputGroup>
            </Form.Group>
          </div>
          <Link to="/user-management/contributor/contributor-form">
            {(user?.data?.user?.role === "admin" ||
              (user?.data?.user?.role === "staff" &&
                Contributor !== false &&
                Contributor?.some((val) => val === "create"))) && (
              <Button className="loginBtn" type="submit">
                Add New
              </Button>
            )}
          </Link>
        </div>
      </div>
      <Table
        header={userManagement_column}
        data={CList}
        section="contributor"
        Search={Search}
        setSelectedRows={setSelectedRows}
        userPerm={Contributor}
      />

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Deleted</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this user?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              deleteRow();
            }}
          >
            Sure
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Contributor;
