import React, { useEffect, useLayoutEffect, useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";
import { Button as Button2, CardActionArea, CardActions } from "@mui/material";
import Modal from "react-bootstrap/Modal";
import { useLocation, useNavigate } from "react-router-dom";
import DOMPurify from "dompurify";
import { useRef } from "react";
import _ from "lodash";
import L from "leaflet";
import ReactMapGL, {
  GeolocateControl,
  Marker,
  NavigationControl,
} from "react-map-gl";
import { useValue } from "../../../../context/ContextProvider";
import { useDispatch, useSelector } from "react-redux";
import {
  clearList,
  getTour,
  reset,
} from "../../../../features/360Tours/tourSlice";

import Geocoder from "../../../Staff/Tours/Geocoder";
var position = [1.3521, 103.8198];

function VirtualMap({ GoToTour, handleAddressChange }) {
  const { state } = useLocation();
  const { tour_List, isError, isSuccess, message, isList, imageLocation } =
    useSelector((state) => state.tours);
  const [show, setShow] = useState(false);
  const [country, setCountry] = useState("");
  const [state2, setState] = useState("");
  const [city, setCity] = useState("");
  const [Address, setAddress] = useState("");
  const [tourData, setTourData] = useState([]);

  const {
    state: {
      location: { lng, lat },
    },
    mapdispatch,
  } = useValue();

  const mapContainer = useRef(null);
  const dispatch = useDispatch();
  const mapRef = useRef();
  const [mapObject, setMap] = useState();
  const navigate = useNavigate();
  const [zoom, setZoom] = useState(9);
  const sanitizer = DOMPurify.sanitize;
  const createMarkup = (encodedHtml) => ({
    __html: _.unescape(encodedHtml),
  });
  useLayoutEffect(() => {
    dispatch(getTour("All"));
  }, []);
  useEffect(() => {
    if (tour_List) {
      const newData = tour_List?.filter((val) => val?.status == "PUBLISHED");
      setTourData(newData);
    }
  }, [isError, isSuccess, message]);
  useEffect(() => {
    if (mapRef.current) {
      mapRef.current.flyTo({
        zoom: 15,
        center: [
          state &&
          state.Data &&
          state.Data.full_address?.coordinates?.length > 0
            ? Number(state.Data.full_address?.coordinates[0])
            : lng,
          state &&
          state.Data &&
          state.Data.full_address?.coordinates?.length > 0
            ? Number(state.Data.full_address?.coordinates[1])
            : lat,
        ],
      });
    }
  });
  useLayoutEffect(() => {
    if (state?.Data) {
      setShow(true);
    }
  }, [state?.Data]);
  const handleClose = () => setShow(false);

  var key = process.env.REACT_APP_LEAFLET_MAP;
  const handleMarkerClick = (data) => {
    GoToTour(data);
  };

  const handleGeolocate = async (event) => {
    const { longitude, latitude } = event.coords;
    console.log("lat", longitude, latitude);
    console.log(
      "process.env.REACT_APP_MAP_TOKEN",
      process.env.REACT_APP_MAP_TOKEN
    );
    const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${longitude},${latitude}.json?access_token=${process.env.REACT_APP_MAP_TOKEN}`;
    try {
      const response = await fetch(url);
      const data = await response.json();

      const result = data.features[0];
      const address = result.place_name;
      console.log("result:", result);
      const country = result.context.find((c) => c.id.includes("country")).text;
      const state = result.context.find((c) => c.id.includes("region")).text;
      const city = result.context.find((c) => c.id.includes("place")).text;
      mapdispatch({
        type: "UPDATE_LOCATION",
        payload: { lng: longitude, lat: latitude },
      });
      setAddress(address);
      setCountry(country);
      setState(state);
      setCity(city);
    } catch (error) {
      console.error("Error:", error);
    }
  };
  return (
    <div>
      <Box
        sx={{
          height: 500,
          position: "relative",
        }}
      >
        <ReactMapGL
          ref={mapRef}
          mapboxAccessToken="pk.eyJ1IjoiYmx1ZXNpbG9zZyIsImEiOiJjbGZyeDZ1MGswMG1mM2RxcmJyajRzbXRyIn0.SwkzsvT0dgMXSafnYQoZSQ"
          initialViewState={{
            longitude: `${
              state &&
              state.Data &&
              state.Data.full_address?.coordinates.length > 0
                ? Number(state.Data.full_address?.coordinates[0])
                : lng
            }`,
            latitude: `${
              state &&
              state.Data &&
              state.Data.full_address?.coordinates.length > 0
                ? Number(state.Data.full_address?.coordinates[1])
                : lat
            }`,
            zoom: 15,
          }}
          mapStyle="mapbox://styles/mapbox/streets-v11"
          width="100%"
          height="100%"
        >
          <NavigationControl position="bottom-right" />
          <GeolocateControl
            position="top-left"
            // trackUserLocation
            onGeolocate={handleGeolocate}
          />
          <Geocoder
            // setResult={setResult}
            setAddress={setAddress}
            setCountry={setCountry}
            setState={setState}
            setCity={setCity}
            // setPostal_Code={setPostal_Code}
          />
          {state?.tourData?.length > 0
            ? state?.tourData.map((data) => (
                <>
                  <Marker
                    key={data?._id}
                    offsetLeft={-20}
                    offsetTop={-10}
                    latitude={
                      data.full_address?.coordinates?.length > 0
                        ? data.full_address?.coordinates[1]
                        : lat
                    }
                    longitude={
                      data.full_address?.coordinates?.length > 0
                        ? data.full_address?.coordinates[0]
                        : lng
                    }
                    draggable
                    onDragEnd={(e) =>
                      mapdispatch({
                        type: "UPDATE_LOCATION",
                        payload: { lng: e.lngLat.lng, lat: e.lngLat.lat },
                      })
                    }
                  >
                    <div
                      className="pin_marker"
                      onClick={() => handleMarkerClick(data)}
                    >
                      <img
                        className="pin_back"
                        src="/imgs/pin_marker.png"
                        alt="pinmarker"
                      />{" "}
                      <img
                        className="pinInner"
                        src={
                          data
                            ? data?.cover_images[0]?.img
                            : "../../../../assets/map/placeholder.png"
                        }
                        alt="marker-image"
                      />
                    </div>
                  </Marker>
                </>
              ))
            : tourData.slice(0, 4).map((data) => (
                <>
                  <Marker
                    key={data?._id}
                    offsetLeft={-20}
                    offsetTop={-10}
                    latitude={
                      data.full_address?.coordinates?.length > 0
                        ? data.full_address?.coordinates[1]
                        : lat
                    }
                    longitude={
                      data.full_address?.coordinates?.length > 0
                        ? data.full_address?.coordinates[0]
                        : lng
                    }
                    draggable
                    onDragEnd={(e) =>
                      mapdispatch({
                        type: "UPDATE_LOCATION",
                        payload: { lng: e.lngLat.lng, lat: e.lngLat.lat },
                      })
                    }
                  >
                    <div
                      className="pin_marker"
                      onClick={() => handleMarkerClick(data)}
                    >
                      <img
                        className="pin_back"
                        src="/imgs/pin_marker.png"
                        alt="pinmarker"
                      />{" "}
                      <img
                        className="pinInner"
                        src={
                          data
                            ? data?.cover_images[0]?.img
                            : "../../../../assets/map/placeholder.png"
                        }
                        alt="marker-image"
                      />
                    </div>
                  </Marker>
                </>
              ))}
          <Modal className="tourDetailModal" show={show} onHide={handleClose}>
            <Card>
              <CardActionArea closeButton>
                <div className="tourMainCard">
                  {state?.Data?.cover_images?.map((val, i) => {
                    if (val.cover == true) {
                      return (
                        <CardMedia
                          component="img"
                          style={{ height: "250px" }}
                          image={val?.img}
                          alt={val.img}
                          key={i}
                        />
                      );
                    }
                  })}
                  <button className="loginBtn">
                    {state?.Data?.tags[0]?.text}
                  </button>
                </div>
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    {state?.Data?.Name}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    <div
                      className="dangerous"
                      dangerouslySetInnerHTML={createMarkup(
                        state?.Data?.description?.slice(0, 400) + "..."
                      )}
                    ></div>
                  </Typography>
                </CardContent>
              </CardActionArea>
              <CardActions>
                <button
                  className="loginBtn"
                  style={{ width: "auto" }}
                  onClick={() =>
                    navigate(`/property-viewing/${state?.Data?._id}`)
                  }
                >
                  Read More{" "}
                  <img src="/imgs/arrow-right.svg" className="arrow" alt="" />
                </button>
              </CardActions>
            </Card>
          </Modal>
          <NavigationControl position="bottom-right" />
        </ReactMapGL>
      </Box>
      {/* </MapContainer> */}
    </div>
  );
}

export default VirtualMap;
