import React, { useEffect, useState } from "react";
import { AppDrawer } from "@bsilobook/component";
import { Breadcrumb } from "react-bootstrap";
import {
  Link,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import paymentService from "../../../features/ProductPlans/productService";

import profileService from "../../../features/MyProfile/profileService";
import AppIcon from "./app.svg";

function Header({ breadData, setProfile, Profile }) {
  const user = JSON.parse(localStorage.getItem("user"));
  const path = useLocation();
  const navigate = useNavigate();
  const [apps, setApps] = useState([]);

  const id = path.pathname.split("/")[2];
  const token = path.pathname.split("/")[3];
  const mongoRegex = /^[0-9a-fA-F]{24}$/;
  // console.log("Id for breadcrumbs: ",id)
  var BData = breadData?.filter(Boolean);
  BData = BData?.filter((item) => !mongoRegex.test(item));

  useEffect(() => {
    profileService.getApps().then((res) => {
      if (res.data && res.data?.data) {
        setApps(
          res.data?.data?.availableModules?.map((x) => ({
            code: x.code,
            label: x.name,
            link: x.link,
            image: x.image ? x.image : AppIcon,
          }))
        );
      }
    });
  }, []);

  const [params] = useSearchParams();
  const session_id = params.get("session_id");
  const plan = params.get("plan");
  const quotationId = params.get("quotationId");
  const userId = params.get("userId");
  useEffect(() => {
    if (Profile == false) {
      navigate("/");
    }
  }, [Profile]);
  useEffect(() => {
    if (session_id) {
      payment();
    }
  }, [session_id]);

  const payment = async () => {
    const paymentData = await paymentService.paymentStatus({
      session_id,
      plan,
      quotationId,
      userId,
    });

    window.location.replace(paymentData.data);
  };

  const GoHome = () => {
    localStorage.setItem("profileData", false);
    setProfile(localStorage.getItem("profileData"));
    navigate("/");
  };

  const goToPage = (val, array) => {
    // setProfile(true);
    if (val === array[0]) {
      return;
    }
    // navigate(`/${val}`);
    navigate(-1);
  };

  return (
    <div className="headerMain">
      <div className="row mx-auto">
        <div className="col-12 mx-auto px-4">
          <div className="d-flex justify-content-between mt-25px px-4">
            <div className="breadCrumb">
              {/* <h3>Profile</h3> */}
              <Breadcrumb>
                {BData?.length > 0 ? (
                  BData?.map((val, i, array) =>
                    i === array.length - 1 ? (
                      <Breadcrumb.Item
                        key={i}
                        style={{
                          textDecoration: "none",
                          textTransform: "capitalize",
                        }}
                        href="#"
                        active
                      >
                        {token !== ""
                          ? val.split("-").join(" ").substring(0, 50)
                          : val.split("-").join(" ")}
                      </Breadcrumb.Item>
                    ) : (
                      <Breadcrumb.Item
                        key={i}
                        style={{
                          textDecoration: "none",
                          textTransform: "capitalize",
                        }}
                        // href="#"
                        // onClick={()=>goToPage(val, array)}
                      >
                        {val.split("-").join(" ")}
                      </Breadcrumb.Item>
                    )
                  )
                ) : (
                  <Breadcrumb.Item
                    style={{ textDecoration: "none" }}
                    href="#"
                    active
                  >
                    <h3>Profile</h3>
                  </Breadcrumb.Item>
                )}
              </Breadcrumb>
            </div>
            <div className="d-flex">
              {(user?.data?.user?.role === "admin" ||
                user?.data?.user?.role === "staff") && (
                <div style={{ marginRight: "10px", marginTop: "-7px" }}>
                  <AppDrawer
                    apps={apps}
                    token={user?.data?.token}
                    orgId={user?.data?.selectedOrg}
                    width="40px"
                    height="40px"
                    popupRight="20px"
                    popupTop="70px"
                    bgColor="#ddd"
                  />
                </div>
              )}
              <Link to="/notifications">
                <img src="/imgs/notificaiton.svg" alt="" />
              </Link>
              &nbsp; &nbsp;
              {user && (
                <span
                  className="signOut"
                  onClick={GoHome}
                  style={{ color: "#cc2939", cursor: "pointer" }}
                >
                  <HomeIcon />
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
