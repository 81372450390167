import customAxios from "../../utils/customAxios";

// Get list of Contributors user
const profileData = async (ID) => {
  refreshToken();
  const response = await customAxios.get(`profile/profile-data/${ID}`);
  if (response.data) {
      return response.data
  }
}

const updateLastLogin = async (ID) => {
  const response = await customAxios.put(`profile/update-login/${ID}`);
  if (response.data) {
      return response.data
  }
}

// Add Image Data
const saveImage = async (bodyFormData) => {
  const response = await customAxios.post('profile/uploads/s3', bodyFormData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    }
  });
  if (response.data) {
      return response.data
  }
}

const updateProfileData = async (userData) => {
  const response = await customAxios.put('profile/edit-profile', userData);
  if (response.data) {
      return response.data
  }
}

const changePassword = async (userData) => {
  const response = await customAxios.post('profile/change-password', userData);
  if (response.data) {
      return response.data
  }
}

const getApps = async () => {
  const response = await customAxios.get(`profile/apps`);
  return response;
}

const refreshToken = () => {
  const userStorage = localStorage.getItem("user");
  const userdata = userStorage ? JSON.parse(userStorage) : null;
    if (userdata) {
      customAxios.interceptors.request.use(
        request => {
          const userdata = userStorage ? JSON.parse(userStorage) : null;
          if (userdata) {
            request.headers.Authorization = userdata?.data?.token;
            request.headers.orgid = userdata?.data?.selectedOrg;
            request.headers.role = userdata?.data?.user?.role;
          }
          return request;
        },
        err => {
          return Promise.reject(err);
        },
      );
    }
}

// const deleteContributorsData = async (ID) => {
//   const response = await axios.put(API_URL + `delete-contributors/${ID}`);
//   if (response.data) {
//       return response.data
//   }
// }

// git checkout -b ali_dev

const profileService = {
    profileData,
    saveImage,
    updateProfileData,
    updateLastLogin,
    changePassword,
    getApps
    // deleteContributorsData,
  }
  
  export default profileService
