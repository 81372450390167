import React, { useState, useEffect, useLayoutEffect } from "react";
import { Button, Form, InputGroup, Modal } from "react-bootstrap";
import { WithContext as ReactTags } from "react-tag-input";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { v4 as uuid } from "uuid";
import {
  addArticle,
  ApproveArticles,
  clearList,
  editArticles,
  getArticle,
  setStatusAsDraft,
  getArticleApproved,
  getArticlePendingAll,
  getArticlePublished,
  RejectArticles,
  reset,
  getArticleDetail,
} from "../../../../features/Articles/articleSlice";
import {
  sendPrompt,
  reset as reset1,
} from "../../../../features/chatgpt/ChatgptSlice";
import Switch from "@mui/material/Switch";
import { Tooltip } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import { FormControlLabel } from "@mui/material";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import ReactQuill from "../../../Editor/Editor.jsx";
import { getQuotation } from "../../../../features/Quotations/quotationSlice";

const KeyCodes = {
  comma: 188,
  enter: 13,
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];

function AddArticle() {
  const API_URL = `${process.env.REACT_APP_BACKEND_ROUTE}/post-requirements/article`;

  const articleID = useParams();
  const { state } = useLocation();
  const [show, setShow] = useState(false);
  const [value, setValue] = useState("");

  const [Edit, setEdit] = useState(false);
  const [coverImages, setCoverImages] = useState([]);
  const [refImages, setRefImages] = useState([]);
  const [currentUserData, setCurrentUserData] = useState({});
  const [Featured, setFeatured] = useState(false);
  const [prompt, setPrompt] = useState("");
  const [validationRules, setValidationRules] = useState({});

  const user = JSON.parse(localStorage.getItem("user"));
  const figureTagsRegex = /<\/?figure>/g;

  const handleChange = (event) => {
    setFeatured(event.target.checked);
  };

  const [convertedContent, setConvertedContent] = useState(null);

  const ID = user?.data?.user?._id;
  const ROLE = user?.data?.user?.role;
  const {
    article_List,
    isError,
    isSuccess,
    message,
    isList,
    articleUpdated,
    articleAdded,
    imageLocation,
    article_Detail,
    credit,
  } = useSelector((state) => state.article);
  const {
    content,
    isError: isError1,
    isSuccess: isSuccess1,
    message: message1,
    isLoading,
  } = useSelector((state) => state.chatgpt);

  const [Reason, setReason] = useState("");
  const [addFormData, setAddFormData] = useState({
    title: "",
    description: "",
    tags: [],
    publish_type: "",
    date: "",
    time: "",
  });

  const [editFormData, setEditFormData] = useState({
    title: "",
    description: "",
    rags: [],
    publish_type: "",
    date: "",
    time: "",
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [tags, setTags] = React.useState([]);
  const handleDelete = (i) => {
    setTags(tags.filter((tag, index) => index !== i));
  };

  const handleAddition = (tag) => {
    setTags([...tags, tag]);
  };

  const handleDrag = (tag, currPos, newPos) => {
    const newTags = tags.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);
    setTags(newTags);
  };

  const handleTagClick = (index) => {
    console.log("The tag at index " + index + " was clicked");
  };

  useEffect(() => {
    if (
      (Object?.keys(content)?.length > 0 && content?.content !== "") ||
      content?.content !== undefined
    ) {
      if (window?.confirm("are you sure you want to replace text!")) {
        setValue(`<p>${content?.content}</p>`);
      } else {
        let description = editFormData?.description?.replace(
          figureTagsRegex,
          ""
        );
        setValue(
          `<p>${description ? description : ""} </br> ${content?.content}</p>`
        );

        dispatch(reset1());
      }
    }
  }, [content?.content]);

  useLayoutEffect(() => {
    dispatch(reset());
    dispatch(reset1());
    if (articleID.id && state?.type === "Draft" && state?.view == false) {
      dispatch(getArticle(ID));
      setEdit(false);
    }
    if (articleID.id && state?.type === "PendingStaff" && state?.view == true) {
      dispatch(getArticlePendingAll());
      setEdit(false);
    }
    if (articleID.id && state?.type === "Publish" && state?.view == true) {
      dispatch(getArticlePublished(ID));
      setEdit(false);
    }
    if (articleID.id && state?.type === "Approved" && state?.view == true) {
      dispatch(getArticleApproved(ID));
      setEdit(false);
    }
    if (state?.type == null || state?.view == null) {
      dispatch(getArticleDetail(articleID.id));
      setEdit(false);
    }
  }, [dispatch, state]);
  useEffect(() => {
    dispatch(reset1());
    axios
      .get(API_URL)
      .then((data) => {
        setValidationRules(data.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    state?.paidQuotationUser
      ? setCurrentUserData(state.paidQuotationUser)
      : setCurrentUserData({});
    if (isSuccess == true) {
      const newData = [...article_List];
      var data = newData?.filter((user) => user._id == articleID.id);
      setEditFormData(data[0]);
      setCoverImages(data[0]?.cover_images);
      setTags(data[0]?.tags);
      setFeatured(data[0]?.isFeatured);
      if (data[0]?.description != null) {
        let description = data[0]?.description.replace(figureTagsRegex, "");
        setValue(description);
      }
    }
    if (state?.type == null && state?.view == null) {
      if (article_Detail == {} && article_List == []) {
        toast.error("Article does not exists(possibly deleted)");
        navigate("/article/listing");
      }
      setEditFormData(article_Detail);
      setCoverImages(article_Detail?.cover_images);
      setTags(article_Detail?.tags);
      setFeatured(article_Detail?.isFeatured);
      if (article_Detail?.description != null) {
        let description = article_Detail?.description.replace(
          figureTagsRegex,
          ""
        );
        setValue(description);
      }
    }
  }, [isSuccess, articleID.id, state]);
  useEffect(() => {
    if (
      (articleAdded == true && message?.message != "") ||
      articleUpdated == true
    ) {
      toast.success(message.message);
      if (articleUpdated) {
        toast.success("Article Updated Successfully!");
      }
      dispatch(reset());
      setTimeout(() => {
        navigate(-1);
      }, 1500);
    }
    if (
      isError == true &&
      (articleAdded == false || articleUpdated == false) &&
      message !== ""
    ) {
      toast.error(message);
      dispatch(reset());
    }
  }, [credit, message, articleAdded, articleUpdated, isSuccess, isError]);

  const uploadFileHandler = async (e) => {
    const file = e.target.files[0];
    const bodyFormData = new FormData();
    bodyFormData.append("image", file);

    const ImgRes = await axios.put(
      `${process.env.REACT_APP_BACKEND_ROUTE}/article/uploads/s3`,
      bodyFormData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    const ID = uuid().slice(0, 8);

    const newData = {
      id: ID,
      img: ImgRes.data.data,
      cover: false,
    };
    const data1 = [...coverImages, newData];
    setCoverImages(data1);
  };

  const uploadFileHandlerRef = async (e) => {
    const file = e.target.files[0];
    const bodyFormData = new FormData();
    bodyFormData.append("image", file);

    const ImgRes = await axios.put(
      `${process.env.REACT_APP_BACKEND_ROUTE}/article/uploads/s3`,
      bodyFormData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    const ID = uuid().slice(0, 8);

    const newData = {
      id: ID,
      img: ImgRes.data.data,
      ref_reason: "",
    };
    const data = [...refImages, newData];
    setRefImages(data);
  };

  const handleAddFormChange = (event) => {
    event.preventDefault();
    const fieldName = event.target.getAttribute("name");
    const fieldValue = event.target.value;

    const newFormData = { ...addFormData };
    newFormData[fieldName] = fieldValue;

    setAddFormData(newFormData);
  };
  const validateForm = () => {
    let valid = true;
    const newErrors = {};

    Object.keys(validationRules).forEach((fieldName) => {
      const fieldRules = validationRules[fieldName];
      const fieldValue = getFieldFromState(fieldName);
      if (fieldRules.isRequired) {
        if (
          fieldName !== "tags" &&
          fieldName !== "coverImages" &&
          fieldValue.trim() === ""
        ) {
          newErrors[fieldName] = `${fieldName} is required.`;
          toast.warn(`${fieldName} is required.`);
          valid = false;
        }
        if (fieldName == "tags" && fieldValue?.length < 1) {
          newErrors[fieldName] = `${fieldName} is required.`;
          toast.warn(`${fieldName} is required.`);
          valid = false;
        }
        if (fieldName == "coverImages" && fieldValue?.length < 1) {
          newErrors[fieldName] = `${fieldName} is required.`;
          toast.warn(`${fieldName} is required.`);
          valid = false;
        }
      }
      if (
        fieldRules.isRequired &&
        fieldRules.minLength &&
        fieldRules.minLength !== undefined &&
        (fieldName == "title" || fieldName == "description")
      ) {
        if (fieldValue?.split(" ").length < fieldRules.minLength) {
          newErrors[
            fieldName
          ] = `${fieldName} must have a minimum of ${fieldRules.minLength} ${fieldName}.`;
          toast.warn(
            `${fieldName} must have a minimum of ${fieldRules.minLength} words.`
          );

          valid = false;
        }
      }
      if (
        fieldRules.isRequired &&
        fieldRules.minLength &&
        fieldRules.minLength !== undefined &&
        // fieldValue.length < fieldRules.minLength
        fieldName !== "title" &&
        fieldName !== "description" &&
        fieldValue.length < fieldRules.minLength
      ) {
        // if (fieldValue.split("").length < fieldRules.minLength) {
        newErrors[
          fieldName
        ] = `${fieldName} must have a minimum of ${fieldRules.minLength} ${fieldName}.`;
        toast.warn(
          `${fieldName} must have a minimum of ${fieldRules.minLength} ${fieldName}.`
        );

        valid = false;
        // }
      }
      if (
        fieldRules.isRequired &&
        fieldRules.maxLength &&
        (fieldName === "title" || fieldName === "description")
      ) {
        if (fieldValue.split(" ").length > fieldRules.maxLength) {
          newErrors[
            fieldName
          ] = `${fieldName} must have a maximum of ${fieldRules.maxLength} ${fieldName}.`;
          toast.warn(
            `${fieldName} must have a maximum of ${fieldRules.maxLength} words.`
          );
          valid = false;
        }
      }
      if (
        fieldRules.isRequired &&
        fieldRules?.maxLength &&
        fieldRules?.maxLength !== undefined &&
        // fieldValue.length < fieldRules.minLength
        fieldName !== "title" &&
        fieldName !== "description" &&
        fieldValue?.length > fieldRules?.maxLength
      ) {
        newErrors[
          fieldName
        ] = `${fieldName} must have a maximum of ${fieldRules.maxLength} ${fieldName}.`;
        toast.warn(
          `${fieldName} must have a maximum of ${fieldRules.maxLength} ${fieldName}.`
        );
        valid = false;
      }
    });
    return valid;
  };
  const getFieldFromState = (fieldName) => {
    switch (fieldName) {
      case "title":
        return (state.user === "EditStaff" && state.type === "EditArt") ||
          (state.user === "user" &&
            (state.type === "Approved" ||
              state.type === "Draft" ||
              state.type == "Publish"))
          ? editFormData.title
          : addFormData.title;
      case "description":
        return value;
      case "tags":
        return tags;
      case "coverImages":
        return coverImages;
      default:
        return "";
    }
  };

  const AddForm = (event) => {
    event.preventDefault();
    const datee = new Date();
    if (validateForm()) {
      const userData = {
        title: addFormData.title,
        description: value ? value : addFormData.description,
        tags: tags,
        cover_images: coverImages ? coverImages : [],
        publish_type:
          addFormData.publish_type === ""
            ? "Immediate"
            : addFormData.publish_type,
        date: addFormData.date,
        time: addFormData.time,
        owner: user?.data.user._id,
        isPending: ROLE === "staff" || ROLE === "admin" ? false : true,
        isPublish: ROLE === "staff" || ROLE === "admin" ? true : false,
        isApproved: ROLE === "staff" || ROLE === "admin" ? true : false,
        publishedAt: datee,
        approvedAt: datee,
        isFeatured: Featured,
        job: currentUserData ? currentUserData : "",
        jobModel: currentUserData ? "Article" : "",
      };
      dispatch(addArticle(userData));
    }
  };

  const handleEditFormChange = (event) => {
    event.preventDefault();
    const fieldName = event.target.getAttribute("name");
    const fieldValue = event.target.value;
    const newFormData = { ...editFormData };
    newFormData[fieldName] = fieldValue;
    setEditFormData(newFormData);
  };

  const UpdateForm = () => {
    if (state.user === "EditStaff" && state.type === "EditArt") {
      if (validateForm()) {
        const userData = {
          id: articleID.id,
          title: editFormData.title,
          description: value ? value : editFormData.description,
          tags: tags,
          cover_images: coverImages,
          owner: user?.data.user._id,
          staffEdit: true,
          isFeatured: Featured,
        };

        dispatch(editArticles(userData));
        navigate(-1);
      }
      // if (coverImages?.length > 0) {
      // } else {
      //   toast.error("Please, add atleat one image!");
      // }
    } else {
      // if (coverImages?.length > 0) {
      if (validateForm()) {
        const userData = {
          id: articleID.id,
          title: editFormData.title,
          description: value ? value : editFormData.description,
          tags: tags,
          cover_images: coverImages,
          publish_type: editFormData.publish_type,
          date: editFormData.date,
          time: editFormData.time,
          owner: user?.data.user._id,
          isPending: editFormData.publish_type == "Draft" ? false : true,
          isFeatured: Featured,
        };
        dispatch(editArticles(userData));

        // navigate(-1);
      }
    }
  };

  const deleteImg = (ID) => {
    const newData = [...coverImages];

    const data = newData.filter((s) => s.id !== ID);
    setCoverImages(data);
  };

  const handleCoverMark = (ID) => {
    const newData = [...coverImages];
    const Index = newData.findIndex((s) => s.id === ID);
    if (Index !== -1) {
      newData[Index] = { ...newData[Index], cover: !newData[Index]?.cover };
      setCoverImages(newData);
    }
  };

  const deleteImgRef = (ID) => {
    const newData = [...refImages];

    const data = newData.filter((s) => s.id !== ID);
    setRefImages(data);
  };

  const handleRefMark = (ID, val) => {
    const newData = [...refImages];
    const Index = newData.findIndex((s) => s.id === ID);

    newData[Index].ref_reason = val;

    setRefImages(newData);
  };

  const approveArticle = () => {
    const artData = {
      reason: Reason,
      id: articleID.id,
      isPending: false,
      isApproved: true,
    };
    dispatch(ApproveArticles(artData));
    setShow(false);
    navigate(-1);
  };
  const rejectArticle = () => {
    const artData = {
      reason: Reason,
      id: articleID.id,
      isPending: false,
      isApproved: false,
      isRejected: true,
      refImages: refImages,
    };
    dispatch(RejectArticles(artData));
    setShow(false);
    navigate(-1);
  };

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Featured Article
    </Tooltip>
  );

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "image", "video"],
      ["clean"],
      [{ align: [] }],
    ],
  };

  const handleImageUpload = async (file) => {
    const formData = new FormData();
    formData.append("image", file);

    try {
      const response = await axios.post("/upload-image", formData);
      const imageUrl = response.data.imageUrl;

      const quill = document.getElementsByClassName("ql-editor")[0];
      const range = quill.getSelection();
      const index = range ? range.index : 0;

      quill.insertEmbed(index, "image", imageUrl);
      quill.setSelection(index + 1);
    } catch (error) {
      console.error("Image upload failed:", error);
    }
  };
  const submitPrompt = () => {
    dispatch(sendPrompt(prompt));
  };
  return (
    <div>
      <ToastContainer />
      <button
        className="loginBtn"
        style={{ marginBottom: "10px" }}
        onClick={() => navigate(-1)}
      >
        Go back
      </button>
      <div className="d-flex justify-content-end gap-4 mb-3">
        <FormControlLabel
          control={
            <Switch
              checked={Featured}
              onChange={handleChange}
              inputProps={{ "aria-label": "controlled" }}
            />
          }
          label="Featured"
          labelPlacement="start"
        />

        {state?.user === "EditStaff" &&
          state?.type === "EditArt" &&
          state.type !== "PendingStaff" && (
            <Button
              className="loginBtn w-170"
              onClick={() => UpdateForm(convertedContent)}
            >
              Update
              <img src="/imgs/redirect.svg" className="ms-2" alt="" />
            </Button>
          )}
      </div>
      <div className="cardSection">
        <div className="d-flex justify-content-between">
          <h1>Article Form</h1>
        </div>
        <Form>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Title</Form.Label>
            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon1">
                {/* <img src="/imgs/name.svg" alt="" /> */}
              </InputGroup.Text>
              <Form.Control
                type="text"
                placeholder="Enter Title of Article"
                disabled={Edit}
                name="title"
                value={articleID.id ? editFormData?.title : addFormData?.title}
                onChange={
                  articleID.id ? handleEditFormChange : handleAddFormChange
                }
              />
            </InputGroup>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Enter prompt</Form.Label>
            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon1"></InputGroup.Text>
              <Form.Control
                type="text"
                name="link"
                placeholder="type prompt"
                disabled={Edit}
                value={prompt}
                onChange={(e) => setPrompt(e.target.value)}
              />
            </InputGroup>
            <Button className="loginBtn" onClick={submitPrompt}>
              prompt
            </Button>
          </Form.Group>
          {isLoading ? (
            <div className="loader-container">
              <div className="loader">
                <img src="/imgs/mouse-wala.gif" />
              </div>
            </div>
          ) : (
            <>
              <div></div>
              <Form.Group className="mb-3">
                <ReactQuill value={value} setValue={setValue} />
              </Form.Group>
            </>
          )}

          <Form.Group className="mb-3" style={{ marginTop: "10px" }}>
            <Form.Label>Tags</Form.Label>
            <ReactTags
              tags={tags}
              autofocus={false}
              delimiters={delimiters}
              handleDelete={handleDelete}
              handleAddition={handleAddition}
              handleDrag={handleDrag}
              handleTagClick={handleTagClick}
              inputFieldPosition="bottom"
              autocomplete
              placeholder="Enter tag here"
            />
          </Form.Group>
          <div className="coverImg mb-3 mt-4">
            <h5>Cover Image</h5>
            <div className="image-placeholder px-3 my-4">
              {coverImages !== [] &&
                coverImages?.map((val) => (
                  <>
                    {/* {val.img !== '' && ( */}
                    <div className="avatar-preview">
                      {/* {user?.data?.user?.role !== "staff" && ( */}
                      <img
                        src="/imgs/delImg.svg"
                        className="delImg"
                        alt=""
                        onClick={() => deleteImg(val.id)}
                      />
                      {/* )} */}

                      <div id="imagePreview">
                        <img id="saveImageFile" src={val.img} alt={val.img} />
                      </div>
                      <Form.Group
                        className="mb-3"
                        controlId="formBasicCheckbox"
                        value={val.cover}
                        onChange={() => handleCoverMark(val.id)}
                      >
                        <Form.Check
                          type="checkbox"
                          label="Make Cover"
                          checked={val.cover}
                        />
                      </Form.Group>
                    </div>
                    {/* )} */}
                  </>
                ))}
              {Edit == false && (
                <div className="avatar-edit">
                  <img src="/imgs/addImg.png" alt="" />
                  <input
                    type="file"
                    // onChange={fileHandler}
                    id="imageUpload"
                    onChange={uploadFileHandler}
                  />
                  <label htmlFor="imageUpload" name=""></label>
                </div>
              )}
            </div>
          </div>
        </Form>
      </div>
      <div className="cardSection mt-4 mb-2">
        <div className="d-flex justify-content-between">
          <h1>Publish Article</h1>
        </div>
        <div className="row mx-0">
          <div className="col-10 mx-auto">
            <Form className="mt-5">
              <div className="row mx-0 gx-5">
                <div className="col-md-12 px-0">
                  <Form.Group className="mb-3">
                    <Form.Label>Publish type</Form.Label>
                    <InputGroup className="mb-3">
                      <InputGroup.Text id="basic-addon1">
                        <img src="/imgs/email.svg" alt="" />
                      </InputGroup.Text>
                      <Form.Select
                        aria-label="Default select example"
                        disabled={Edit}
                        value={
                          articleID?.id
                            ? editFormData?.publish_type
                            : addFormData?.publish_type
                        }
                        onChange={
                          articleID?.id
                            ? handleEditFormChange
                            : handleAddFormChange
                        }
                        name="publish_type"
                      >
                        <option value="">Publish Type</option>
                        <option value="Immediate">Immediate</option>
                        {state?.user !== "Addstaff" && (
                          <option value="Draft">Draft</option>
                        )}
                        <option value="Schedule">Schedule</option>
                      </Form.Select>
                    </InputGroup>
                  </Form.Group>
                </div>

                {articleID.id
                  ? editFormData.publish_type === "Schedule" && (
                      <>
                        <div className="col-md-6 ps-md-0">
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>Date</Form.Label>
                            <InputGroup className="mb-3">
                              <InputGroup.Text id="basic-addon1">
                                <img src="/imgs/country.svg" alt="" />
                              </InputGroup.Text>
                              <Form.Control
                                type="date"
                                name="date"
                                disabled={Edit}
                                value={
                                  articleID.id
                                    ? editFormData?.date?.substring(0, 10)
                                    : addFormData?.date
                                }
                                onChange={
                                  articleID.id
                                    ? handleEditFormChange
                                    : handleAddFormChange
                                }
                              />
                            </InputGroup>
                          </Form.Group>
                        </div>
                        <div className="col-md-6 pe-md-0">
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>Time</Form.Label>
                            <InputGroup className="mb-3">
                              <InputGroup.Text id="basic-addon1">
                                <img src="/imgs/address.svg" alt="" />
                              </InputGroup.Text>
                              <Form.Control
                                type="time"
                                name="time"
                                disabled={Edit}
                                value={
                                  articleID.id
                                    ? editFormData?.time
                                    : addFormData?.time
                                }
                                onChange={
                                  articleID.id
                                    ? handleEditFormChange
                                    : handleAddFormChange
                                }
                              />
                            </InputGroup>
                          </Form.Group>
                        </div>
                      </>
                    )
                  : addFormData.publish_type === "Schedule" && (
                      <>
                        <div className="col-md-6 ps-md-0">
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>Date</Form.Label>
                            <InputGroup className="mb-3">
                              <InputGroup.Text id="basic-addon1">
                                <img src="/imgs/country.svg" alt="" />
                              </InputGroup.Text>
                              <Form.Control
                                type="date"
                                disabled={Edit}
                                name="date"
                                onChange={handleAddFormChange}
                              />
                            </InputGroup>
                          </Form.Group>
                        </div>
                        <div className="col-md-6 pe-md-0">
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>Time</Form.Label>
                            <InputGroup className="mb-3">
                              <InputGroup.Text id="basic-addon1">
                                <img src="/imgs/address.svg" alt="" />
                              </InputGroup.Text>
                              <Form.Control
                                type="time"
                                disabled={Edit}
                                name="time"
                                onChange={handleAddFormChange}
                              />
                            </InputGroup>
                          </Form.Group>
                        </div>
                      </>
                    )}
              </div>
            </Form>
          </div>
        </div>
      </div>
      {state?.view == false && (
        <div className="cardSection mt-4 mb-2">
          <div className="d-sm-flex justify-content-center text-center gap-4">
            <Button
              className="closeBtn w-170 me-sm-0 me-3 mb-sm-0 mb-2"
              onClick={() => navigate(-1)}
            >
              Close
            </Button>
            {user?.data?.user?.role === "staff" ||
              (articleID.id && (
                <Link to={`/article/article-detail/${articleID.id}`}>
                  <Button className="cancelBtn filled w-170 me-sm-0 me-3 mb-sm-0 mb-2">
                    View Details
                  </Button>
                </Link>
              ))}
            {(user?.data?.user?.role === "staff" ||
              user?.data?.user?.role === "admin") &&
            state.user !== "Addstaff" ? (
              <Button
                className="loginBtn w-170 me-sm-0 me-3"
                onClick={handleShow}
              >
                Approve/Reject
                <img src="/imgs/redirect.svg" className="ms-2" alt="" />
              </Button>
            ) : articleID.id ? (
              <Button
                className="loginBtn w-170 me-sm-0 me-3"
                onClick={() => UpdateForm(convertedContent)}
              >
                Save
                <img src="/imgs/redirect.svg" className="ms-2" alt="" />
              </Button>
            ) : (
              <Button className="loginBtn w-170 me-sm-0 me-3" onClick={AddForm}>
                Submit
                <img src="/imgs/redirect.svg" className="ms-2" alt="" />
              </Button>
            )}
          </div>
        </div>
      )}

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Body>
          <div className="d-flex justify-content-between mb-3">
            <h1 className="mb-0">Article Approval</h1>
            <img
              src="/imgs/revoke.svg"
              style={{ cursor: "pointer" }}
              onClick={handleClose}
              alt=""
            />
          </div>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Reason</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              value={Reason}
              onChange={(e) => setReason(e.target.value)}
            />
          </Form.Group>
          <h3 className="reference">Reference</h3>
          <div className="image-placeholder px-3 my-4">
            {refImages !== [] &&
              refImages?.map((val) => (
                <>
                  {val.img !== "" && (
                    <div className="avatar-preview">
                      <img
                        src="/imgs/delImg.svg"
                        className="delImg"
                        alt=""
                        onClick={() => deleteImgRef(val.id)}
                      />
                      <div id="imagePreview">
                        <img id="saveImageFile" src={val.img} alt={val.img} />
                      </div>
                      <Form.Group
                        className="mb-3"
                        controlId="formBasicCheckbox"
                      >
                        <Form.Control
                          type="text"
                          label="Add Ref"
                          value={val.ref_reason}
                          onChange={(e) =>
                            handleRefMark(val.id, e.target.value)
                          }
                          placeholder="Add reference (Optional)"
                        />
                      </Form.Group>
                    </div>
                  )}
                </>
              ))}

            <div className="avatar-edit">
              <img src="/imgs/addImg.png" alt="" />
              <input
                type="file"
                // onChange={fileHandler}
                id="imageUpload"
                onChange={uploadFileHandlerRef}
              />
              <label htmlFor="imageUpload" name=""></label>
            </div>
          </div>
          <div className="d-sm-flex justify-content-center text-center gap-2">
            <Button
              className="closeBtn w-170 me-sm-0 me-3 mb-sm-0 mb-2"
              disabled={Reason === "" ? true : false}
              onClick={rejectArticle}
            >
              Reject
            </Button>
            {/* <Button className="cancelBtn filled w-170 me-sm-0 me-3 mb-sm-0 mb-2">
              Save as Draft
            </Button> */}
            <Button
              className="loginBtn w-170 me-sm-0 me-3"
              disabled={Reason === "" ? true : false}
              onClick={approveArticle}
            >
              Approve
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default AddArticle;
