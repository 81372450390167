import React, { useLayoutEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { addAreaOIfInterest } from "../../../../features/auth/authSlice";
import ReactGA from "react-ga";

function TourList({ Tours }) {
  const user = JSON.parse(localStorage.getItem("user"));
  ReactGA.initialize("UA-277153930-1");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // User is currently on this page
  const [currentPage, setCurrentPage] = useState(1);
  // No of Records to be displayed on each page
  const [recordsPerPage] = useState(9);

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  // Records to be displayed on the current page
  const currentRecords = Tours?.slice(indexOfFirstRecord, indexOfLastRecord);
  const nPages = Math.ceil(Tours?.length / recordsPerPage);

  const pageNumbers = [...Array(nPages + 1).keys()].slice(1);

  const nextPage = () => {
    if (currentPage !== nPages) setCurrentPage(currentPage + 1);
  };
  const prevPage = () => {
    if (currentPage !== 1) setCurrentPage(currentPage - 1);
  };

  const GoToTour = (data) => {
    // ReactGA.event({
    //   category: "Engagement",
    //   action: "View",
    //   label: data?.Name,
    // });
    //  ReactGA.pageview(window.location.pathname + window.location.search);
    if (user) {
      const interest = {
        tourId: data._id,
        userId: user?.data?.user?._id,
      };
      console.log("interest", interest);
      dispatch(addAreaOIfInterest(interest));
    }
    navigate(`/property-viewing/${data?._id}`);
  };

  console.log(currentRecords);

  return (
    <>
      <div className="bannerSection featuredArticlesList p-0">
        <h1 className="allTours">All Tours</h1>
        {/* <div className="container-fluid px-50px"> */}
        <div className="row">
          <div className="col-12pt-3">
            <div className="row">
              {currentRecords?.map((data, index) => {
                return (
                  <div className="col-lg-4 col-md-6 col-sm-6 mb-4" key={index}>
                    <div className="blogCard">
                      <div className="position-relative">
                        {data?.cover_images?.map(
                          (val) =>
                            val?.cover == true && (
                              <img
                                style={{ height: "240px" }}
                                src={val?.img}
                                alt="Cover Images"
                              />
                            )
                        )}
                      </div>
                      <h2>{data?.Name}</h2>
                      <div className="d-flex justify-content-between pt-3">
                        <div className="d-flex gap-1 align-items-center">
                          <img
                            src="/imgs/calender.svg"
                            className="calender"
                            alt=""
                          />
                          <p>{data?.createdAt?.substring(0, 10)}</p>
                        </div>

                        <button onClick={() => GoToTour(data)}>
                          {/* {data.type === "blog" ? "Read More " : "Watch now "} */}
                          Tours &nbsp;
                          <img
                            src="/imgs/arrow-right.svg"
                            className="arrow"
                            alt=""
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="text-center mt-5">
            <div className="pagination">
              <a href="#" onClick={prevPage}>
                Previous
              </a>
              {pageNumbers?.map((pgNumber) => (
                <a
                  href="#"
                  className={`${pgNumber == currentPage && "active"}`}
                  onClick={() => setCurrentPage(pgNumber)}
                >
                  {pgNumber}
                </a>
              ))}
              <a href="#" onClick={nextPage}>
                Next
              </a>
            </div>
          </div>
        </div>
        {/* </div> */}
      </div>
    </>
  );
}

export default TourList;
