import axios from 'axios'

const API_URL = `${process.env.REACT_APP_BACKEND_ROUTE}/`;
const userStorage = localStorage.getItem("user");

const customAxios = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

customAxios.interceptors.request.use(
  request => {
    const userdata = userStorage ? JSON.parse(userStorage) : null;
    if (userdata) {
      request.headers.Authorization = userdata?.data?.token;
      request.headers.orgid = userdata?.data?.selectedOrg;
      request.headers.role = userdata?.data?.user?.role;
    }
    return request;
  },
  err => {
    return Promise.reject(err);
  },
);

export const authorization = async(url, data) => {
  const userStorage = localStorage.getItem("user");
  const userdata = userStorage ? JSON.parse(userStorage) : null;

  return await customAxios.get(`${url}`, data, {
    headers: {
      Authorization: userdata?.data?.token,
      orgid: userdata?.data?.selectedOrg,
      role: userdata?.data?.user?.role,
      'Content-Type': 'multipart/form-data',
    }
  })
}

export default customAxios;