import React, { useEffect, useLayoutEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getUpgrade } from "../../../features/UpgradePlan/upgradeSlice";
import DOMPurify from "dompurify";
import { Button, Modal, Row, Container, Col, Fade } from "react-bootstrap";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import axios from "axios";
import { logout } from "../../../features/auth/authSlice";

function UpgradeAccount() {
  const user = JSON.parse(localStorage.getItem("user"));

  const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        color: "#32325d",
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#aab7c4",
        },
        borderBottom: "1px solid red",
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
  };

  const stripe = useStripe();
  const elements = useElements();

  const [Data, setData] = useState([]);
  const [show, setShow] = useState(false);
  const [Spinner, setSpinner] = useState(false);
  const [Open, setOpen] = useState(false);
  const [Status, setStatus] = useState("");
  const [Plan, setPlan] = useState({});
  const [PayType, setPayType] = useState("");
  const [PayMessage, setPayMessage] = useState("");
  const [PayMessage2, setPayMessage2] = useState("");
  const [CardEmp, setCardEmp] = useState(false);

  const sanitizedData = (data) => ({
    __html: DOMPurify.sanitize(data),
  });

  const {
    upgrade_plan,
    isError,
    message,
    isSuccess,
    isLoading,
    isUpdated,
    isAdded,
  } = useSelector((state) => state.UpgradePlan);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useLayoutEffect(() => {
    dispatch(getUpgrade());
  }, []);

  useEffect(() => {
    if (upgrade_plan?.length > 0) {
      const newData = [...upgrade_plan];
      const sortedNumbers = newData
        ?.filter((val) => val.status === "Active")
        ?.sort((a, b) => b?.amount - a?.amount);
      setData(sortedNumbers);
    }
  }, [isError, message, isSuccess]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setShow(false);
    setSpinner(true);

    const card = elements.getElement(CardElement);
    setCardEmp(false);
    card.on("change", function (event) {
      if (event.complete) {
        // enable payment button
        setCardEmp(false);
      } else if (event.error) {
        // show validation to customer
        setCardEmp(event.empty);
        setPayMessage2("Please, enter card details first to proceed payment!");
        return;
      }
    });
    const { token } = await stripe.createToken(card);

    const sendData = {
      card_token: token?.id,
      payment_type: "card",
      userId: user?.data?.user?._id,
      plan: Plan,
    };

    if (token?.error || !token) {
      // Show error to your customer.
      setStatus("Fail");
      setPayMessage("Invalid Card!");
      setShow(false);
      setSpinner(false);
      setOpen(true);
    } else {
      const { data } = await axios.post(
        `${process.env.REACT_APP_BACKEND_ROUTE}/payment/create-charges-upgrade`,
        sendData
      );
      if (data?.status === "Success") {
        setStatus("Success");
        setPayMessage(data?.data);
        setShow(false);
        setSpinner(false);
        setOpen(true);
      }
    }
  };

  return (
    <div className="upgradeAccount">
      <h5>Be part of our community as a contributor</h5>
      <div className="upgrade-cards">
        <div className="row">
          {Data.slice(0, 3)?.map((val, i) => (
            <div className="col-md-4" key={i}>
              <div className="card">
                <div className="plan">
                  <div className="name-price">
                    <h4>{val?.name}</h4>
                    <h3>${val?.amount}</h3>
                  </div>
                </div>
                <div className="plan-detail">
                  <ul>
                    <li>
                      <div
                        dangerouslySetInnerHTML={sanitizedData(
                          val?.description
                        )}
                      />
                    </li>
                  </ul>
                </div>
                <div className="subscribe">
                  <button
                    className="btn"
                    onClick={() => {
                      setShow(true);
                      setPlan(val);
                    }}
                  >
                    Subscribe
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Modal show={show} onHide={() => setShow(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Online Payment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <CardElement options={CARD_ELEMENT_OPTIONS} />
            <div className="text-center">
              {Spinner == true ? (
                <div className="loader-container">
                  <div className="loader">
                    <img src="/imgs/mouse-wala.gif" />
                  </div>
                </div>
              ) : (
                <button
                  disabled={!stripe}
                  className="btn btn-success"
                  type="Submit"
                >
                  Pay ${Plan?.amount}
                </button>
              )}
            </div>
          </form>
        </Modal.Body>
      </Modal>

      <Modal show={Open} onHide={() => setOpen(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Payment Status</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Container>
              <Row>
                <div>
                  <div className="text-center">
                    {Status === "Fail" ? (
                      <>
                        <img
                          src={"/imgs/cross.png"}
                          style={{ width: "100px" }}
                          alt="Tick Image"
                        />
                        <h3
                          className="mt-5 text-center"
                          style={{ color: "red" }}
                        >
                          {CardEmp == true ? PayMessage2 : PayMessage}
                          {/* {CardEmp} */}
                        </h3>
                      </>
                    ) : (
                      <>
                        <img
                          src={"/imgs/tick.png"}
                          style={{ width: "100px" }}
                          alt="Tick Image"
                        />
                        <h3
                          className="mt-5 text-center"
                          style={{ color: "green" }}
                        >
                          {PayMessage}
                        </h3>
                      </>
                    )}
                  </div>

                  <div className="text-center">
                    <button
                      style={{ width: "5em" }}
                      className="btn btn-primary mt-3"
                      onClick={() => {
                        setOpen(false);
                        setPayMessage2("");
                        dispatch(logout());
                        navigate("/", { redirect: true });
                        localStorage.setItem("profileData", false);
                      }}
                    >
                      OK
                    </button>
                  </div>
                </div>
              </Row>
            </Container>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default UpgradeAccount;
