import React from "react";
import {
  FacebookShareButton,
  TwitterShareButton,
  FacebookIcon,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";

const ShareButtons = ({ url, title, trackShareEvent }) => {
  return (
    <div onClick={trackShareEvent}>
      <FacebookShareButton url={url} quote={title}>
        <FacebookIcon size={35} round={true} />
      </FacebookShareButton>
      <TwitterShareButton url={url} title={title}>
        <TwitterIcon size={35} round={true} />
      </TwitterShareButton>
      <WhatsappShareButton url={url} title={title}>
        <WhatsappIcon size={35} round={true} />
      </WhatsappShareButton>
    </div>
  );
};

export default ShareButtons;
