import React from "react";
import { useLayoutEffect } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getFrontend, reset } from "../../../features/Frontend/frontendSlice";
import { getProfile } from "../../../features/MyProfile/profileSlice";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import FeatureBlogs from "./_part/FeatureBlogs";
import PhotoGallery from "./_part/PhotoGallery";
import Testimonials from "./_part/Testimonials";
import Tours from "./_part/Tours";
import DOMPurify from "dompurify";
import {
  getTour,
  getTourByInterest,
} from "../../../features/360Tours/tourSlice";

function Home({ setProfile, Profile }) {
  const user = JSON.parse(localStorage.getItem("user"));
  const {
    profile_data,
    isLoading,
    isError: isErrorAuth,
    isSuccess: isSuccessAuth,
    message: messageAuth,
  } = useSelector((state) => state.profileData);

  const [nav, setNav] = useState([]);
  const [tourData, setTourData] = useState([]);
  const [about, setAbout] = useState();
  const [contact, setContact] = useState();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { frontData, isError, isSuccess, message } = useSelector(
    (state) => state.front_Data
  );
  const { tour_List, interestedTours } = useSelector((state) => state.tours);

  useLayoutEffect(() => {
    dispatch(reset());
    dispatch(getFrontend());
    dispatch(getTour("All"));
    if (user && user?.data?.user._id) {
      dispatch(getProfile(user?.data.user._id));
      let data = {
        id: user?.data.user._id,
        interest: user?.data.user.area_of_interest,
      };
      dispatch(getTourByInterest(data));
      // dispatch(updateLastLogin(ID));
    }
  }, []);

  useEffect(() => {
    if (user && user?.data?.user._id) {
      dispatch(getProfile(user?.data.user._id));
      // dispatch(updateLastLogin(ID));
    }
    if (frontData) {
      setNav(frontData?.navigationData);
      setAbout(frontData?.aboutsData);
      setAbout(frontData?.aboutsData);
    }
    let newData = [];
    if (user?.data?.token) {
      newData = interestedTours?.filter((val) => val?.status == "PUBLISHED");
    } else {
      newData = tour_List?.filter((val) => val?.status == "PUBLISHED");
    }
    setTourData(newData);
    // let filtered = tour_List?.filter((val) => val?.status == "PUBLISHED");
    // setTourData(filtered);
  }, [
    tour_List,
    isError,
    isSuccess,
    message,
    isErrorAuth,
    isSuccessAuth,
    messageAuth,
  ]);

  const sanitizedData = (data) => ({
    __html: DOMPurify.sanitize(data),
  });
  const GoToTour = (data) => {
    navigate("/virtual-tours", {
      state: { Data: data, tourData: tourData?.slice(0, 4) },
    });
  };

  return (
    <div>
      <Header
        user={user}
        userProfile={profile_data}
        setProfile={setProfile}
        Profile={Profile}
        nav={nav}
        contact={contact}
        about={about}
      />
      <div className="bannerSection">
        <div className="container-fluid px-50px">
          <div className="row">
            <div className="col-12 text-center">
              <h1> Explore The World With Virtual Tours</h1>
              <p>Captivate a global audience with virtual reality</p>
              <button>Discover Top Destinations</button>
            </div>
            <div className="col-12 mt-5 pt-5">
              <Tours
                GoToTour={GoToTour}
                tourData={tourData}
                // setTourData={setTourData}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="aboutSection py-5">
        <div className="container-fluid px-50px py-4">
          <div className="col-12">
            <div className="row flex-row-reverse gx-5">
              <div className="col-md-5">
                <img src="/imgs/aboutImg.png" className="w-100" alt="" />
              </div>
              <div className="col-md-1"></div>
              <div className="col-md-6">
                <h1>About Us</h1>
                <p>
                  <div dangerouslySetInnerHTML={sanitizedData(about)} />
                  {/* {about} */}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FeatureBlogs />
      <PhotoGallery page={false} />
      <Testimonials />
      <Footer />
    </div>
  );
}

export default Home;
