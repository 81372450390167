import React, {useEffect} from 'react'
import { NavLink, useNavigate } from 'react-router-dom'


function PageNotFound({Profile, setProfile}) {
    const navigate = useNavigate();
  const user2 = JSON.parse(localStorage.getItem("user"));
  var path = window.location.pathname;

    useEffect(()=>{
      if(!user2){
        navigate('/')
      }
      if(user2 && (path === '/login' || path === '/signup')){
        navigate('/')   
      }
      
      if(user2 && Profile === ('true' || true)){
        navigate('/profile')   
      }
      if(user2 && Profile === ('false' || false)){
        navigate('/')   
      }

    },[])

  return (
    <div className='App' style={{margin: '0', position:'absolute', top:'50%', left: '50%', transform: 'translate(-50%, -50%)'}}>
        <h4>Page Not Found! Please click on link to <span><NavLink to="#" onClick={()=>navigate(-1)}>Redirect.</NavLink></span></h4>
    </div>
  )
}

export default PageNotFound