import axios from "axios";

const API_URL = `${process.env.REACT_APP_BACKEND_ROUTE}/contrib/`;

// Get list of Contributors user
const contributorsList = async () => {
  const response = await axios.get(API_URL + "contributors-list");
  if (response.data) {
    return response.data;
  }
};

// Add Contributors user
const addContributorsData = async (userData) => {
  const response = await axios.post(API_URL + "add-contributors", userData);
  if (response.data) {
    return response.data;
  }
};

const editContributorsData = async (userData) => {
  const response = await axios.put(API_URL + "edit-contributors", userData);
  if (response.data) {
    return response.data;
  }
};

const deleteContributorsData = async (ID) => {
  const response = await axios.put(API_URL + `delete-contributors/${ID}`);
  if (response.data) {
    return response.data;
  }
};

// Add Image Data
const saveImageC = async (bodyFormData) => {
  const response = await axios.post(API_URL + 'add-uploads/s3', bodyFormData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    }
  });
  if (response.data) {
      return response.data
  }
}

const updatePassC = async (passData) => {
  const response = await axios.post(API_URL + 'change-password', passData);
    return response.data;
};

const deletedRowsC = async (passData) => {
  const response = await axios.put(API_URL + 'deleted-rows', passData);
    return response.data;
};


const contribService = {
  contributorsList,
  addContributorsData,
  editContributorsData,
  deleteContributorsData,
  saveImageC,
  updatePassC,
  deletedRowsC,
};

export default contribService;
