import React, { useState, useEffect, useLayoutEffect } from "react";
import { Modal, Form, InputGroup, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { quotation_column1 } from "../../../../utils/constant/column_header_data";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import {
  getQuotation,
  deleteQuotation,
  reset,
} from "../../../../features/Quotations/quotationSlice";
import { getSorting } from "../../../../features/sortingHIghlight/sortingSlice";
import { getQuestionsScore } from "../../../../features/Questions/questionSlice";
import { toast, ToastContainer } from "react-toastify";
import { getUsersByRole } from "../../../../features/users/userSlice";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import { formatDate } from "../../../../utils/DateHelper";
import moment from "moment";

function CommissionedJob() {
  const user = JSON.parse(localStorage.getItem("user"));
  const role = user?.data?.user?.role;
  const ID = user?.data?.user?._id;
  const {
    questions_List,
    isError: questionError,
    isSuccess: questionSuccess,
    message: questionMessage,
    isList: questionIsList,
  } = useSelector((state) => state.questions);
  const { quotation_List, isError, isSuccess, message, isList } = useSelector(
    (state) => state.quotations
  );
  const { sortingList } = useSelector((state) => state.sorting);
  const { frontData } = useSelector((state) => state.front_Data);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [Search, setSearch] = useState("");
  const [Sum, setSum] = useState(0);
  const [selectedRows, setSelectedRows] = useState(false);
  const [selectedDates, setSelectedDates] = useState({
    startDate: null,
    endDate: null,
  });

  const [CList, setCList] = useState([]);
  const [DelID, setDelID] = useState("");

  const [show, setShow] = useState(false);
  const [jobs, setJobs] = useState([]);
  const { allStaff } = useSelector((state) => state.users);

  const handleClose = () => {
    setShow(false);
    setDelID("");
  };

  useLayoutEffect(() => {
    dispatch(reset());
    dispatch(getUsersByRole("staff"));
    dispatch(getSorting());
  }, []);
  let getQuotatins = async () => {
    await dispatch(getQuotation());
    if (quotation_List?.length > 0) {
      const [questID] = quotation_List?.map((val) => val.plan[0].questionaire);
      dispatch(getQuestionsScore(questID));
      let data = quotation_List?.filter(
        (item) =>
          item?.Content_Generator === "Commissioned" &&
          item?.isPaid === true &&
          item?.status !== "Published" &&
          !item?.isDelete
      );
      console.log({ data });
      if (role === "user" || role === "contributor") {
        let filtered = data.filter((item) => item?.owner?._id === ID);
        let require = [];
        filtered
          .filter(
            (item) =>
              (item?.totalscore
                ? item.totalscore
                : Number(calculateScore(item?.plan[0].questionaire))) >
                sortingList.score &&
              item.dueDate &&
              caluculateDueDays(item?.dueDate?.substr(0, 10)) < sortingList.days
          )
          .sort((a, b) => {
            const scoreA = a?.totalscore
              ? a.totalscore
              : Number(calculateScore(a?.plan[0].questionaire));
            const scoreB = b?.totalscore
              ? b.totalscore
              : Number(calculateScore(b?.plan[0].questionaire));
            if (scoreA === scoreB) {
              const dueDaysA = caluculateDueDays(a?.dueDate?.substr(0, 10));
              const dueDaysB = caluculateDueDays(b?.dueDate?.substr(0, 10));
              return dueDaysA - dueDaysB;
            }

            return scoreB - scoreA;
          })
          .forEach((item) => require.push(item));
        filtered
          .filter(
            (item) =>
              (item?.totalscore
                ? item.totalscore
                : Number(calculateScore(item?.plan[0].questionaire))) >
              sortingList.score
          )
          .sort((a, b) => {
            const scoreA = a?.totalscore
              ? a.totalscore
              : Number(calculateScore(a?.plan[0].questionaire));
            const scoreB = b?.totalscore
              ? b.totalscore
              : Number(calculateScore(b?.plan[0].questionaire));

            if (scoreA === scoreB) {
              const dueDaysA = caluculateDueDays(a?.dueDate?.substr(0, 10));
              const dueDaysB = caluculateDueDays(b?.dueDate?.substr(0, 10));
              return dueDaysA - dueDaysB;
            }

            return scoreB - scoreA;
          })
          .forEach((item) => require.push(item));

        filtered
          .filter(
            (item) =>
              item.dueDate &&
              caluculateDueDays(item?.dueDate?.substr(0, 10)) < sortingList.days
          )
          .sort((a, b) => {
            const dueDaysA = caluculateDueDays(a?.dueDate?.substr(0, 10));
            const dueDaysB = caluculateDueDays(b?.dueDate?.substr(0, 10));

            if (dueDaysA === dueDaysB) {
              const scoreA = a?.totalscore
                ? a.totalscore
                : Number(calculateScore(a?.plan[0].questionaire));
              const scoreB = b?.totalscore
                ? b?.totalscore
                : Number(calculateScore(b?.plan[0].questionaire));
              return scoreB - scoreA;
            }
            return dueDaysA - dueDaysB;
          })
          .forEach((item) => require.push(item));
        let require1 = [];
        filtered.forEach((item) => {
          if (!require.includes(item)) {
            require1.push(item);
          }
        });
        require1 = require1?.sort((a, b) =>
          Number(
            calculateScore(b?.plan[0].questionaire) -
              Number(calculateScore(a?.plan[0].questionaire))
          )
        );
        require = [...require, ...require1];
        const uniqueArray = removeDuplicates(require, "_id");
        console.log("unique Array===>", uniqueArray.length);
        setCList(uniqueArray);
        setJobs(uniqueArray);
      }
      if (role === "staff") {
        let filtered = data.filter(
          (item) => item.assignTo == user?.data?.user?._id
        );
        let require = [];
        filtered
          .filter(
            (item) =>
              (item?.totalscore
                ? item.totalscore
                : Number(calculateScore(item?.plan[0].questionaire))) >
                sortingList.score &&
              item.dueDate &&
              caluculateDueDays(item?.dueDate?.substr(0, 10)) < sortingList.days
          )
          .sort((a, b) => {
            const scoreA = a?.totalscore
              ? a.totalscore
              : Number(calculateScore(a?.plan[0].questionaire));
            const scoreB = b?.totalscore
              ? b.totalscore
              : Number(calculateScore(b?.plan[0].questionaire));
            if (scoreA === scoreB) {
              const dueDaysA = caluculateDueDays(a?.dueDate?.substr(0, 10));
              const dueDaysB = caluculateDueDays(b?.dueDate?.substr(0, 10));
              return dueDaysA - dueDaysB;
            }

            return scoreB - scoreA;
          })
          .forEach((item) => require.push(item));
        filtered
          .filter(
            (item) =>
              (item?.totalscore
                ? item.totalscore
                : Number(calculateScore(item?.plan[0].questionaire))) >
              sortingList.score
          )
          .sort((a, b) => {
            const scoreA = a?.totalscore
              ? a.totalscore
              : Number(calculateScore(a?.plan[0].questionaire));
            const scoreB = b?.totalscore
              ? b.totalscore
              : Number(calculateScore(b?.plan[0].questionaire));

            if (scoreA === scoreB) {
              const dueDaysA = caluculateDueDays(a?.dueDate?.substr(0, 10));
              const dueDaysB = caluculateDueDays(b?.dueDate?.substr(0, 10));
              return dueDaysA - dueDaysB;
            }

            return scoreB - scoreA;
          })
          .forEach((item) => require.push(item));

        filtered
          .filter(
            (item) =>
              item.dueDate &&
              caluculateDueDays(item?.dueDate?.substr(0, 10)) < sortingList.days
          )
          .sort((a, b) => {
            const dueDaysA = caluculateDueDays(a?.dueDate?.substr(0, 10));
            const dueDaysB = caluculateDueDays(b?.dueDate?.substr(0, 10));

            if (dueDaysA === dueDaysB) {
              const scoreA = a?.totalscore
                ? a.totalscore
                : Number(calculateScore(a?.plan[0].questionaire));
              const scoreB = b?.totalscore
                ? b?.totalscore
                : Number(calculateScore(b?.plan[0].questionaire));
              return scoreB - scoreA;
            }
            return dueDaysA - dueDaysB;
          })
          .forEach((item) => require.push(item));
        let require1 = [];
        filtered.forEach((item) => {
          if (!require.includes(item)) {
            require1.push(item);
          }
        });
        require1 = require1?.sort((a, b) =>
          Number(
            calculateScore(b?.plan[0].questionaire) -
              Number(calculateScore(a?.plan[0].questionaire))
          )
        );
        require = [...require, ...require1];
        const uniqueArray = removeDuplicates(require, "_id");
        setCList(uniqueArray);
        setJobs(uniqueArray);
      }
      if (role === "admin") {
        let require = [];
        data
          .filter(
            (item) =>
              (item?.totalscore
                ? item.totalscore
                : Number(calculateScore(item?.plan[0].questionaire))) >
                sortingList.score &&
              item.dueDate &&
              caluculateDueDays(item?.dueDate?.substr(0, 10)) < sortingList.days
          )
          .sort((a, b) => {
            const scoreA = a?.totalscore
              ? a.totalscore
              : Number(calculateScore(a?.plan[0].questionaire));
            const scoreB = b?.totalscore
              ? b.totalscore
              : Number(calculateScore(b?.plan[0].questionaire));
            if (scoreA === scoreB) {
              const dueDaysA = caluculateDueDays(a?.dueDate?.substr(0, 10));
              const dueDaysB = caluculateDueDays(b?.dueDate?.substr(0, 10));
              return dueDaysA - dueDaysB;
            }

            return scoreB - scoreA;
          })
          .forEach((item) => {
            require.push(item);
          });
        data
          .filter(
            (item) =>
              (item?.totalscore
                ? item.totalscore
                : Number(calculateScore(item?.plan[0].questionaire))) >
              sortingList.score
          )
          .sort((a, b) => {
            const scoreA = a?.totalscore
              ? a.totalscore
              : Number(calculateScore(a?.plan[0].questionaire));
            const scoreB = b?.totalscore
              ? b.totalscore
              : Number(calculateScore(b?.plan[0].questionaire));

            if (scoreA === scoreB) {
              const dueDaysA = caluculateDueDays(a?.dueDate?.substr(0, 10));
              const dueDaysB = caluculateDueDays(b?.dueDate?.substr(0, 10));
              return dueDaysA - dueDaysB;
            }

            return scoreB - scoreA;
          })
          .forEach((item) => require.push(item));

        data
          .filter(
            (item) =>
              item.dueDate &&
              caluculateDueDays(item?.dueDate?.substr(0, 10)) < sortingList.days
          )
          .sort((a, b) => {
            const dueDaysA = caluculateDueDays(a?.dueDate?.substr(0, 10));
            const dueDaysB = caluculateDueDays(b?.dueDate?.substr(0, 10));

            if (dueDaysA === dueDaysB) {
              const scoreA = a?.totalscore
                ? a.totalscore
                : Number(calculateScore(a?.plan[0].questionaire));
              const scoreB = b?.totalscore
                ? b?.totalscore
                : Number(calculateScore(b?.plan[0].questionaire));
              return scoreB - scoreA;
            }
            return dueDaysA - dueDaysB;
          })
          .forEach((item) => require.push(item));
        let require1 = [];
        data.forEach((item) => {
          if (!require.includes(item)) {
            require1.push(item);
          }
        });
        require1 = require1?.sort((a, b) =>
          Number(
            calculateScore(b?.plan[0].questionaire) -
              Number(calculateScore(a?.plan[0].questionaire))
          )
        );
        require = [...require, ...require1];
        const uniqueArray = removeDuplicates(require, "_id");
        console.log("admin", uniqueArray);
        setCList(uniqueArray);
        setJobs(uniqueArray);
      }
      const [sumData] = questions_List?.map((val) => val.questions);
      const sum = sumData?.reduce((accumulator, object) => {
        return Number(accumulator) + Number(object.score);
      }, 0);
      setSum(sum);
    } else {
      setCList([]);
      setJobs([]);
    }
  };
  useEffect(() => {
    getQuotatins();
  }, [dispatch, navigate, isSuccess, isError, message, isList, ID]);

  function removeDuplicates(array, property) {
    const seen = new Set();
    return array.filter((item) => {
      const value = item[property];
      if (!seen.has(value)) {
        seen.add(value);
        return true;
      }
      return false;
    });
  }

  const deleteRow = () => {
    let yFilter = selectedRows.map((itemY) => {
      return itemY.id;
    });
    let filteredX = quotation_List.filter(
      (itemX) => !yFilter.includes(itemX._id)
    );
    const data = {
      Ids: yFilter,
    };
    // dispatch(deletedRowsArticles(data))
    // dispatch(reset())
    setDelID("");
    setSelectedRows([]);
    setShow(false);
  };

  const onView = (value) => {
    const answers = value?.plan[0]?.questionaire?.answers;
    const latestAnswers = answers?.slice(
      -value?.plan[0]?.questionaire?.questions?.length
    );
    var score;
    if (!value?.totalscore) {
      score = calculateScore(value?.plan[0].questionaire);
    }
    value = value?.totalscore
      ? {
          ...value,
          totalscore: value?.totalscore,
          plan: [
            {
              ...value?.plan[0],
              questionaire: {
                ...value?.plan[0]?.questionaire,
                answers: latestAnswers,
              },
            },
          ],
        }
      : {
          ...value,
          totalscore: score,
          plan: [
            {
              ...value?.plan[0],
              questionaire: {
                ...value?.plan[0]?.questionaire,
                answers: latestAnswers,
              },
            },
          ],
        };
    (user?.data?.user?.role === "staff" &&
      user?.data?.user?.permissions[0]?.ArticlesOrders?.some(
        (val) => val === "view"
      )) ||
    user?.data?.user?.role === "contributor" ||
    user?.data?.user?.role === "admin"
      ? navigate("/Commission-Job-List/Unpublished/Order-Detail", {
          state: { Details: value, edit: false, sum: Sum },
        })
      : toast.error("Permission denied!");
  };
  const onPin = (pinnedJob) => {
    const filteredRows = rows
      ? rows.filter(
          (item) =>
            item.id !== pinnedJob._id &&
            (item?.product_plan?.toLowerCase().includes(Search.toLowerCase()) ||
              item?.user?.toLowerCase().includes(Search.toLowerCase()))
        )
      : [];
    rows = pinnedJob ? [pinnedJob, ...filteredRows] : filteredRows;
  };
  const onEdit = (value) => {
    const answers = value?.plan[0]?.questionaire?.answers;
    const latestAnswers = answers?.slice(
      -value?.plan[0]?.questionaire?.questions?.length
    );
    var score;
    if (!value?.totalscore) {
      score = calculateScore(value?.plan[0].questionaire);
    }
    value = value?.totalscore
      ? {
          ...value,
          totalscore: value?.totalscore,
          plan: [
            {
              ...value?.plan[0],
              questionaire: {
                ...value?.plan[0]?.questionaire,
                answers: latestAnswers,
              },
            },
          ],
        }
      : {
          ...value,
          totalscore: score,
          plan: [
            {
              ...value?.plan[0],
              questionaire: {
                ...value?.plan[0]?.questionaire,
                answers: latestAnswers,
              },
            },
          ],
        };
    (user?.data?.user?.role === "staff" &&
      user?.data?.user?.permissions[0]?.ArticlesOrders?.some(
        (val) => val === "edit"
      )) ||
    user?.data?.user?.role === "contributor" ||
    user?.data?.user?.role === "admin"
      ? navigate("/Commission-Job-List/Unpublished/Order-Detail", {
          state: { Details: value, edit: true, sum: Sum },
        })
      : toast.error("Permission denied!");
  };
  const assignName = (id) => {
    const filter = allStaff?.filter((item) => item._id === id);
    if (filter.length > 0) {
      return filter[0].first_name;
    }
    return "";
  };

  var rows = [];
  const handleDateRangeChange = (event, picker) => {
    setSelectedDates((prev) => ({
      ...prev,
      startDate: moment(picker.startDate).format("l"),
      endDate: moment(picker.endDate).format("l"),
    }));

    // filterBy("date");
    filterBy(
      "date",
      moment(picker.startDate).format("l"),
      moment(picker.endDate).format("l")
    );
  };
  const calculateScore = (questionaire) => {
    const answers = questionaire?.answers;
    const latestAnswers = answers?.slice(-questionaire?.questions?.length);
    let score = 0;
    latestAnswers?.map((item) => {
      score += Number(item?.score || 0);
    });
    return score;
  };
  const caluculateDueDays = (date) => {
    const currentDate = moment();
    let daysDifference = currentDate.diff(date, "days");
    if (daysDifference < 0) {
      let remainingDays = Math.abs(daysDifference);
      remainingDays += 2;
      return remainingDays;
    } else if (daysDifference === 0) {
      daysDifference += 2;
      return daysDifference;
    } else {
      daysDifference += 1;
      const daysPassed = -daysDifference;
      return daysPassed;
    }
  };
  const onDelete = async (id) => {
    console.log(id);
    if (window.confirm("Are you sure, you want to delete")) {
      let wait = await dispatch(deleteQuotation(id));
      if (wait?.payload?.status === "Success") {
        toast.success(wait?.payload?.message);
        dispatch(reset());
      }
    }
  };
  if (jobs) {
    for (let [i, value] of jobs?.entries()) {
      rows[i] = {
        id: value?._id,
        score: value?.totalscore ? (
          <div
            className={`status ${
              value.totalscore > Number(sortingList.score) ? "score" : ""
            }`}
          >
            {value?.totalscore}
          </div>
        ) : value?.plan[0].questionaire &&
          value?.plan[0].questionaire?.questions?.length > 0 ? (
          <div
            className={`status ${
              Number(calculateScore(value?.plan[0].questionaire)) >
              Number(sortingList.score)
                ? "score"
                : ""
            }`}
          >
            {calculateScore(value?.plan[0].questionaire)}
          </div>
        ) : (
          0
        ),
        order_date: moment(value?.createdAt).format("MM-DD-YYYY"),
        user: value?.owner?.first_name + " " + value?.owner?.last_name,
        product_plan: value?.product_plan,
        assignTo: assignName(value?.assignTo),
        dueDate: formatDate(value?.dueDate),
        daystoDueDate: value?.dueDate && (
          <div
            className={`status ${
              caluculateDueDays(value?.dueDate?.substr(0, 10)) <
              sortingList.days
                ? "revoked"
                : ""
            }`}
          >
            {value?.dueDate?.substr(0, 10) &&
              caluculateDueDays(value?.dueDate?.substr(0, 10))}
          </div>
        ),
        status: (
          <div
            className={`status ${
              caluculateDueDays(value?.dueDate?.substr(0, 10)) >
                sortingList.days && value?.assignTo
                ? "draft1"
                : caluculateDueDays(value?.dueDate?.substr(0, 10)) <
                    sortingList.days && value?.dueDate
                ? "revoked"
                : "active1"
            }`}
          >
            {caluculateDueDays(value?.dueDate?.substr(0, 10)) >
              sortingList.days && value?.assignTo
              ? "In Progress"
              : caluculateDueDays(value?.dueDate?.substr(0, 10)) <
                  sortingList.days && value?.dueDate
              ? "On Due"
              : "Not Started"}
          </div>
        ),
        action: (
          <div>
            {(role === "admin" || role === "staff") && (
              <div className="d-flex gap-3">
                <img
                  src="/imgs/u_eye.svg"
                  width={20}
                  alt=""
                  onClick={() => onView(value)}
                />
                <img
                  src="/imgs/redirect_gray.svg"
                  width={20}
                  alt=""
                  onClick={() => onEdit(value)}
                />
                <img
                  src="/imgs/delete.svg"
                  width={20}
                  alt=""
                  onClick={() => onDelete(value._id)}
                />
              </div>
            )}
          </div>
        ),
      };
    }
  }

  const clearFilter = () => {
    setSelectedDates({
      startDate: null,
      endDate: null,
    });
  };
  const filterBy = (option, SD, ED) => {
    let filteredResult = CList;

    if (option === "user") {
      const userInput = prompt("Enter user:");
      if (userInput.length <= 0) {
        return (filteredResult = CList);
      }
      filteredResult = filteredResult.filter((item) => {
        if (
          item.owner?.first_name
            .toLocaleLowerCase()
            .includes(userInput.toLocaleLowerCase()) ||
          item.owner?.last_name
            .toLocaleLowerCase()
            .includes(userInput.toLocaleLowerCase())
        )
          return item;
      });
    } else if (option === "assignTo") {
      const assignToInput = prompt("Enter AssignTo:");
      if (assignToInput.length <= 0) {
        return (filteredResult = CList);
      }
      let filtername = allStaff.filter((item) => {
        console.log(8787, item);
        if (
          item.first_name
            .toLocaleLowerCase()
            .includes(assignToInput.toLocaleLowerCase())
        ) {
          return true;
        }
      });
      filteredResult = filteredResult.filter((item) => {
        console.log(item, 8787, 999);

        return item.assignTo === filtername[0]?._id;
      });
    } else if (option === "productPlan") {
      const productPlanInput = prompt("Enter Product Plan:");
      if (productPlanInput.length <= 0) {
        return (filteredResult = CList);
      }
      filteredResult = filteredResult.filter((item) => {
        if (item.product_plan === productPlanInput) return item;
      });
    } else if (option === "status") {
      const statusInput = prompt("Enter Status:");
      if (statusInput.length <= 0) {
        return (filteredResult = CList);
      }
      filteredResult = filteredResult.filter((item) => {
        if (
          caluculateDueDays(item?.dueDate?.substr(0, 10)) > sortingList.days &&
          item?.assignTo &&
          "In Progress"
            .toLocaleLowerCase()
            .includes(statusInput.toLocaleLowerCase())
        ) {
          return item;
        }
        if (
          caluculateDueDays(item?.dueDate?.substr(0, 10)) < sortingList.days &&
          item?.dueDate &&
          "On Due".toLocaleLowerCase().includes(statusInput.toLocaleLowerCase())
        ) {
          return item;
        } else if (
          !item.dueDate &&
          "Not Started"
            .toLocaleLowerCase()
            .includes(statusInput.toLocaleLowerCase())
        ) {
          return item;
        }

        if (item.status === statusInput) return item;
      });
    } else if (option === "date") {
      filteredResult = filteredResult?.filter((job) => {
        const createdAt = moment(job.createdAt).format("l");

        return (
          (!SD || moment(createdAt).isSameOrAfter(SD, "day")) &&
          (!ED || moment(createdAt).isSameOrBefore(ED, "day"))
        );
      });
    } else if (option === "reset") {
      setSelectedDates({ startDate: null, endDate: null });
      setJobs(CList);
    }
    setJobs(filteredResult);
  };
  console.log(jobs.length);
  return (
    <div className="staffMainDiv">
      <div className="row mx-0 mb-4">
        <ToastContainer />
        <div className="d-flex justify-content-between">
          <div className="d-flex gap-3">
            <img
              src="/imgs/revoke.svg"
              width={20}
              alt=""
              onClick={() => setSearch("")}
            />
            <Form.Group className="mb-0">
              <InputGroup className="mb-0">
                <InputGroup.Text id="basic-addon1">
                  <img src="/imgs/search.svg" alt="" />
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  placeholder="Search"
                  value={Search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </InputGroup>
            </Form.Group>
          </div>
          <div className="commission_rangePicker">
            <button className="filter-button" onClick={() => filterBy("user")}>
              Filter by User
            </button>
            <button
              className="filter-button"
              onClick={() => filterBy("assignTo")}
            >
              Filter by AssignTo
            </button>
            <button
              className="filter-button"
              onClick={() => filterBy("productPlan")}
            >
              Filter by Product Plan
            </button>
            <button
              className="filter-button"
              onClick={() => filterBy("status")}
            >
              Filter by Status
            </button>
            <button
              className="filter-button"
              style={{ marginTop: "5px" }}
              onClick={() => filterBy("reset")}
            >
              reset
            </button>

            <DateRangePicker onApply={handleDateRangeChange}>
              <input />
            </DateRangePicker>
            <img
              src="/imgs/revoke.svg"
              width={20}
              alt=""
              onClick={clearFilter}
            />
          </div>
        </div>
      </div>

      <DataTable
        columns={quotation_column1}
        data={
          rows
            ? rows.filter(
                (item) =>
                  item?.product_plan
                    ?.toLowerCase()
                    .includes(Search.toLowerCase()) ||
                  item?.user?.toLowerCase().includes(Search.toLowerCase())
              )
            : []
        }
        pagination
      />
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete Quotation?</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this quotation?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={deleteRow}>
            Sure
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default CommissionedJob;
