import axios from "axios";
const API_URL = `${process.env.REACT_APP_BACKEND_ROUTE}/tour/`;

// Get list of article user
const getTour = async (ID) => {
  const response = await axios.get(API_URL + `tour-list/${ID}`);
  if (response.data) {
    return response.data;
  }
};
const getTourByInterest = async (data) => {
  const response = await axios.post(
    API_URL + `tours/${data.id}`,
    data.interest
  );
  if (response.data) {
    return response.data;
  }
};

// Add Contributors user

const addTour = async (userData) => {
  const response = await axios.post(API_URL + "add-tour", userData);
  if (response.data) {
    return response.data;
  }
};

const editTourData = async (userData) => {
  const response = await axios.put(
    API_URL + `update-tour/${userData?.id}`,
    userData
  );
  if (response.data) {
    return response.data;
  }
};

const getDetails = async (ID) => {
  const response = await axios.put(API_URL + `details-tour/${ID}`);
  if (response.data) {
    return response.data;
  }
};

const createReview = async (Data) => {
  const response = await axios.post(API_URL + "add-reviews", Data);
  if (response.data) {
    return response.data;
  }
};
const replyReview = async (Data) => {
  const response = await axios.post(API_URL + "review-reply", Data);
  if (response.data) {
    return response.data;
  }
};

const deletedTour = async (delt) => {
  const response = await axios.put(API_URL + "delete-tour", delt);
  if (response.data) {
    return response.data;
  }
};

const deletedRowsTours = async (passData) => {
  const response = await axios.put(API_URL + "deleted-rows", passData);
  return response.data;
};

const getNearestTours = async (id) => {
  const response = await axios.post(API_URL + "nearest-tours", id);
  return response.data;
};
const deleteReview = async (id) => {
  const response = await axios.post(API_URL + "delete-review", id);
  return response.data;
};

const tourService = {
  getTour,
  addTour,
  editTourData,
  getDetails,
  createReview,
  deletedTour,
  deletedRowsTours,
  replyReview,
  getNearestTours,
  deleteReview,
  getTourByInterest,
};

export default tourService;
