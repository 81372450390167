import React from "react";
import { useLayoutEffect } from "react";
import { useEffect } from "react";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { addPayment, editPayment, paymentList, reset } from "../../../../features/ProductPlans/productSlice";

function AddPaymentGateway() {

  const [gateway, setGateway] = useState('CC_STRIPE');
  const [account, setAccount] = useState('');
  const [account_api, setAccountApi] = useState('');
  const [account_key, setAccountKey] = useState('');

  const {paymentData, isError, message, isSuccess, isLoading, isAdded,isUpdated} = useSelector((state) => state.productPlan);
  
  const user2 = JSON.parse(localStorage.getItem("user"));

  const dispatch = useDispatch();
  const navigate = useNavigate();
  useLayoutEffect(()=>{
    dispatch(reset());
    dispatch(paymentList());
  },[])
  useEffect(()=>{
    if(paymentData){
      setAccount(paymentData?.account)
      setAccountApi(paymentData?.account_api)
      setAccountKey(paymentData?.account_key)
    }
    if(isSuccess == true && isAdded == true && message?.message !== ''){
      toast.success(message?.message);
      dispatch(reset())
    }
    
    if(isSuccess == true && isUpdated == true && message?.message !== ''){
      toast.success(message?.message);
      dispatch(reset())
    }
    if(isError == true && message !== ''){
      toast.error(message)
      dispatch(reset())
    }
  },[isError, message, isSuccess, isAdded,isUpdated])
  
  const SaveGateway = () => {
    
    const data = {
      gateway,
      account,
      account_api,
      account_key,
      user: user2?.data?.user?._id,
    }
    dispatch(addPayment(data))
  }
  
  const UpdateGateway = () => {
    
    const data = {
      id: paymentData?._id,
      gateway,
      account,
      account_api,
      account_key,
    }
    dispatch(editPayment(data))
  }
  return (
    <div>
      <ToastContainer />
      <div className="cardSection">
        <div className="d-flex justify-content-between">
          <h1>General Information</h1>
          {/* <img src="/imgs/edit.svg" className="editIcon" alt="" /> */}
            <div className="d-flex justify-content-center gap-4 mb-3">
              <Button className="cancelBtn" type="button">
                Cancel
              </Button>
              <Button className="loginBtn" onClick={()=> Object.keys(paymentData).length > 0 ? UpdateGateway() : SaveGateway()}>
                {Object.keys(paymentData).length > 0 ? 'Update' : 'Save'}
              </Button>
            </div>
        </div>
      
        <div className="row mx-0 mt-4">
          <div className="col-10 mx-auto">
            <div className="row gx-5">
              <div className="col-md-6">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Gateway</Form.Label>
                  <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1">
                      <img src="/imgs/gateway.svg" alt="" />
                    </InputGroup.Text>
                    <Form.Control type="text" value={gateway} disabled={true} onChange={(e)=>setGateway(e.target.value)}/>
                  </InputGroup>
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Account Name</Form.Label>
                  <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1">
                      <img src="/imgs/u_user.svg" alt="" />
                    </InputGroup.Text>
                    <Form.Control type="text" value={account} onChange={(e)=>setAccount(e.target.value)} />
                  </InputGroup>
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Publish Key</Form.Label>
                  <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1">
                      <img src="/imgs/api.svg" alt="" />
                    </InputGroup.Text>
                    <Form.Control type="text" value={account_api} onChange={(e)=>setAccountApi(e.target.value)} />
                  </InputGroup>
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Secret Key</Form.Label>
                  <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1">
                      <img src="/imgs/key.svg" alt="" />
                    </InputGroup.Text>
                    <Form.Control type="text" value={account_key} onChange={(e)=>setAccountKey(e.target.value)}/>
                  </InputGroup>
                </Form.Group>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddPaymentGateway;
