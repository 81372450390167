import React, { useLayoutEffect, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Modal, Form, InputGroup, Button } from "react-bootstrap";
import {
  articleReview_column,
  tours_column,
} from "../../../utils/constant/column_header_data";
import { useDispatch, useSelector } from "react-redux";
import DataTable from "react-data-table-component";
import {
  clearList,
  deletedRowsTours,
  deletedTour,
  getTour,
  reset,
} from "../../../features/360Tours/tourSlice";
import { ToastContainer, toast } from "react-toastify";
import { getBadword } from "../../../features/badWord/BadWordSlice";

function Tours() {
  const user = JSON.parse(localStorage.getItem("user"));
  const ID = user?.data?.user?._id;
  const role = user?.data?.user?.role;

  const { tour_List, isError, isSuccess, message } = useSelector(
    (state) => state.tours
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [Search, setSearch] = useState("");
  const [selectedRows, setSelectedRows] = useState(false);
  const [CList, setCList] = useState([]);
  const [DelID, setDelID] = useState("");

  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [revoke, setRevoke] = useState(false);

  const handleClose = () => {
    setShow(false);
    setDelID("");
    setRevoke(false);
  };

  const handleClose2 = () => {
    setShow2(false);
    setDelID("");
  };

  useLayoutEffect(() => {
    dispatch(clearList());
    dispatch(reset());
    dispatch(getBadword());
    if (user?.data?.user?.role === "admin") {
      dispatch(getTour("All"));
    }
    if (user?.data?.user?.role === "staff") {
      dispatch(getTour("staff"));
    }
    if (user?.data?.user?.role === "contributor") {
      dispatch(getTour(ID));
    }
  }, []);
  useEffect(() => {
    if (user?.data?.user?.role === "admin" && message.length > 0 && isSuccess) {
      toast.success(message);
      dispatch(reset());
      setTimeout(() => {
        dispatch(getTour("All"));
      }, 1000);
    }
    if (user?.data?.user?.role === "staff" && message.length > 0 && isSuccess) {
      toast.success(message);
      dispatch(reset());
      setTimeout(() => {
        dispatch(getTour("staff"));
      }, 1000);
    }
    // if (user?.data?.user?.role === "staff") {
    //   dispatch(getTour("staff"));
    // }
    if (
      (user?.data?.user?.role === "contributor" ||
        user?.data?.user?.role === "user") &&
      message.length > 0 &&
      isSuccess
    ) {
      toast.success(message);
      setTimeout(() => {
        dispatch(reset());
        dispatch(getTour(ID));
      }, 1000);
    }
    if (tour_List) {
      const data = [...tour_List].reverse();
      setCList(data);
    }
  }, [isSuccess, message, isError]);

  const deleteRow = () => {
    let yFilter = selectedRows.map((itemY) => {
      return itemY.id;
    });
    let filteredX = tour_List.filter((itemX) => !yFilter.includes(itemX._id));
    const data = {
      Ids: yFilter,
    };
    dispatch(deletedRowsTours(data));
    dispatch(reset());
    setDelID("");
    setSelectedRows([]);
    setShow2(false);
  };

  const onView = (Id, value) => {
    navigate(`/360-tours/tour-list/${Id}`, {
      state: { view: true, Details: value },
    });
  };
  const onEdit = (Id, value) => {
    navigate(`/360-tours/tours-form/${Id}`, {
      state: { view: false, Details: value },
    });
  };

  const onDelete = (Id, isHide) => {
    setDelID(Id);
    if (isHide) {
      setShow(true);
    }
    setRevoke(true);
  };

  const DelFun = () => {
    const Dele = {
      id: DelID,
      // reason: DelReason,
    };

    dispatch(deletedTour(Dele));
    setDelID(deletedTour(Dele));
    setShow(false);
    setRevoke(false);
    if (user?.data?.user?.role === "admin") {
      setTimeout(() => dispatch(getTour("All")), 1000);
    }
    if (user?.data?.user?.role === "staff") {
      setTimeout(() => dispatch(getTour("staff")), 1000);
    }
  };

  var rows = [];
  if (CList) {
    for (let [i, value] of CList?.entries()) {
      rows[i] = {
        id: value?._id,
        name: value?.Name,
        status:
          value?.status === "DRAFT" ? (
            <p className="status draft">{value?.status}</p>
          ) : value?.status === "PUBLISHED" ? (
            <p className="status active">{value?.status}</p>
          ) : (
            <p className="status hide">{value?.status}</p>
          ),
        reviews: (
          <div className="d-flex gap-1 align-items-center">
            <img src="/imgs/star.svg" alt="" />
            <span>{value?.review ? value?.review : 0}</span>
          </div>
        ),
        visit_count: 0,
        // share_count: 0,
        action: (
          <div>
            {console.log(value._id,9999)}
            <div className="d-flex gap-3">
              {(role === "contributor" ||
                (role === "staff" &&
                  user?.data?.user?.permissions[0]?.VirtualReviews?.some(
                    (val) => val === "view"
                  )) ||
                role === "admin") && (
                <img
                  src="/imgs/u_eye.svg"
                  width={20}
                  alt=""
                  onClick={() => onView(value._id, value)}
                />
              )}

              {(role === "contributor" ||
                (role === "staff" &&
                  user?.data?.user?.permissions[0]?.VirtualReviews?.some(
                    (val) => val === "edit"
                  )) ||
                role === "admin") && (
                <img
                  src="/imgs/t_edit.svg"
                  width={20}
                  alt=""
                  onClick={() => onEdit(value._id, value)}
                />
              )}

              {(role === "contributor" ||
                (role === "staff" &&
                  user?.data?.user?.permissions[0]?.VirtualReviews?.some(
                    (val) => val === "delete"
                  )) ||
                role === "admin") &&
                (value.isHide ? (
                  <img
                    src="/imgs/delete.svg"
                    width={20}
                    alt=""
                    onClick={() => onDelete(value._id, value?.isHide)}
                  />
                ) : (
                  <img
                    src="/imgs/revoke.svg"
                    width={20}
                    alt=""
                    onClick={() => onDelete(value._id, value?.isHide)}
                  />
                ))}
            </div>
          </div>
        ),
      };
    }
  }

  return (
    <div className="staffMainDiv">
      <ToastContainer />
      <div className="row mx-0 mb-4">
        <div className="d-flex justify-content-between">
          <div className="d-flex gap-3">
            <img
              src="/imgs/revoke.svg"
              width={20}
              alt=""
              onClick={() => setSearch("")}
            />
            <Form.Group className="mb-0">
              <InputGroup className="mb-0">
                <InputGroup.Text id="basic-addon1">
                  <img src="/imgs/search.svg" alt="" />
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  placeholder="Search"
                  value={Search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </InputGroup>
            </Form.Group>
          </div>
          {((role === "contributor" && user?.data?.user?.isConfirmed == true) ||
            (role === "staff" &&
              user?.data?.user?.permissions[0]?.VirtualReviews?.some(
                (val) => val === "create"
              )) ||
            role === "admin") && (
            <Link to="/360-tours/tours-form">
              <Button className="loginBtn" type="submit">
                Add Tour
              </Button>
            </Link>
          )}
        </div>
      </div>
      <DataTable
        columns={tours_column}
        data={
          rows
            ? rows.filter((item) =>
                item.name.toLowerCase().includes(Search.toLowerCase())
              )
            : []
        }
        pagination
      />

      <Modal
        show={revoke}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Tour Revoke?</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to revoke this tour?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={DelFun}>
            Sure
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Tour Delete?</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this tour?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={DelFun}>
            Sure
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={show2}
        onHide={handleClose2}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Tour Deleted?</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this articles?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose2}>
            Close
          </Button>
          <Button variant="primary" onClick={deleteRow}>
            Sure
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Tours;
