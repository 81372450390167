import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  loginAdmin,
  register,
  reset,
} from "./../../../features/auth/authSlice";
import { ToastContainer, toast } from "react-toastify";
import { useLayoutEffect } from "react";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  reset as reset2,
  getProfile,
} from "../../../features/MyProfile/profileSlice";

function CustomerLogin({ setProfile }) {
  const [Email, setEmail] = useState("");
  const [Pass, setPass] = useState("");

  const [ShowPass, setShowPass] = useState("password");

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const Auth = useSelector((state) => state.auth);
  const user2 = JSON.parse(localStorage.getItem("user"));
  const { user, isLoading, isError, isSuccess, message } = Auth;

  useLayoutEffect(() => {
    dispatch(reset());
    dispatch(reset2());
  }, [dispatch]);

  useEffect(() => {
    dispatch(reset());
    // if (user?.data?.user?.role === "user") {
    //   localStorage.setItem('profileData', true)
    //   setProfile('true');
    //   navigate("/profile");
    // } else {
    //   navigate("/");
    // }
    // if (user?.data?.user?.role === "admin") {
    //   localStorage.setItem('profileData', false)
    //   setProfile('false');
    //   navigate("/");
    // }
    // if (user?.data?.user?.role === "contributor" || user?.data?.user?.role === "staff") {
    //   localStorage.setItem('profileData', false)
    //   setProfile('false');
    //   navigate("/profile");
    // } else {
    //   navigate("/");
    // }
    if (!user) {
      dispatch(reset());
      dispatch(reset2());
      navigate("/login");
    }
    if (isError == true && message !== "") {
      toast.error(message);
      dispatch(reset());
    }
    if (isSuccess == true && message !== "") {
      toast.success(message);
      dispatch(reset());
    }
  }, [user, isError, isSuccess, message, navigate, dispatch]);

  const Signin = async (e) => {
    e.preventDefault();

    const userData = {
      Email,
      Pass,
      role: "public",
    };
    let result = await dispatch(loginAdmin(userData));
    console.log({ result });
    let ID = user2.data.user._id;
  };

  const clearForm = () => {
    setEmail("");
    setPass("");
    navigate("/");
  };

  const PassShow = () => {
    if (ShowPass === "password") {
      setShowPass("text");
      return;
    }
    setShowPass("password");
  };

  return (
    <div className="signupMain">
      <div className="row mx-0 loginMain">
        <div className="col-12 my-auto">
          <div className="text-center py-5">
            <img
              src="/imgs/white-logo.svg"
              className="logo"
              width={270}
              alt=""
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/")}
            />
          </div>
          <ToastContainer />
          <div className="row mx-0">
            <div className="col-md-8 mx-auto">
              <div className="SignupBox mb-5">
                <h3>Login</h3>
                <p>Please Enter Your Details</p>
                <div className="row">
                  <div className="col-md-10 mx-auto">
                    <Form className="mt-5" onSubmit={Signin}>
                      <div className="row gx-5">
                        <div className="col-md-12">
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>Email</Form.Label>
                            <InputGroup className="mb-3">
                              <InputGroup.Text id="basic-addon1">
                                <img src="/imgs/email.svg" alt="" />
                              </InputGroup.Text>
                              <Form.Control
                                type="email"
                                placeholder="Enter Email..."
                                value={Email}
                                onChange={(e) => setEmail(e.target.value)}
                              />
                            </InputGroup>
                          </Form.Group>
                        </div>
                        <div className="col-md-12">
                          <Form.Group
                            className="mb-0"
                            controlId="formBasicPassword"
                          >
                            <Form.Label>Password</Form.Label>
                            <InputGroup className="mb-2">
                              <InputGroup.Text id="basic-addon1">
                                <img src="/imgs/lock_bg1.svg" alt="" />
                              </InputGroup.Text>
                              <Form.Control
                                type={ShowPass}
                                value={Pass}
                                onChange={(e) => setPass(e.target.value)}
                                placeholder="Enter Your Password"
                              />
                              {ShowPass === "text" ? (
                                <InputGroup.Text id="basic-addon1">
                                  <VisibilityOffIcon
                                    style={{
                                      cursor: "pointer",
                                      color: "lightgray",
                                    }}
                                    alt=""
                                    onClick={PassShow}
                                  />
                                </InputGroup.Text>
                              ) : (
                                <InputGroup.Text id="basic-addon1">
                                  <VisibilityIcon
                                    style={{
                                      cursor: "pointer",
                                      color: "lightgray",
                                    }}
                                    alt=""
                                    onClick={PassShow}
                                  />
                                </InputGroup.Text>
                              )}
                            </InputGroup>
                          </Form.Group>
                        </div>
                      </div>

                      <div className="d-flex justify-content-center gap-4 mt-5 mb-3">
                        <Button
                          className="cancelBtn"
                          type="button"
                          onClick={clearForm}
                        >
                          Cancel
                        </Button>

                        <Button className="loginBtn" type="submit">
                          Login
                        </Button>
                      </div>
                      <p className="forgotpassword">
                        Don’t have an account? <Link to="/signup">Sign up</Link>
                      </p>
                      <div className="text-center">
                        <Link to="/customer-forgetPassword">
                          <Button
                            className="loginBtn forgetPasswordBtn"
                            type="submit"
                          >
                            Forget password?
                          </Button>
                        </Link>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CustomerLogin;
