import React, { useLayoutEffect } from "react";
import { useState } from "react";
import { useEffect } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getQuestions,
  reset,
} from "../../../../features/Questions/questionSlice";

function PlanForm({ handleAddFormChange, AddForm, Edit, ShowPrice, QtyH }) {
  const { questions_List, isSuccess, isError, message, isLoading, isList } =
    useSelector((state) => state.questions);

  const user = JSON.parse(localStorage.getItem("user"));

  const [questionsData, setQuestionsData] = useState([]);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useLayoutEffect(() => {
    dispatch(reset());
    dispatch(getQuestions({ id: "all", type: "custom" }));
  }, []);

  useEffect(() => {
    if (isList == true) {
      const dataQ = questions_List.filter((val) => val.status !== "In-Active");
      setQuestionsData(dataQ);
    }
  }, [isSuccess, isError, message, isList]);

  return (
    <div>
      <div className="cardSection">
        <div className="d-flex justify-content-between">
          <h1>Product Plan Form</h1>
          {/* <img src="/imgs/edit.svg" className="editIcon" alt="" /> */}
          <div className="d-flex justify-content-center gap-4 mb-3">
            <Button
              className="cancelBtn"
              type="button"
              onClick={() => navigate(-1)}
            >
              Cancel
            </Button>
            {Edit == true ? (
              <Button className="loginBtn" onClick={AddForm}>
                Save
              </Button>
            ) : (
              <Button className="loginBtn" disabled={true}>
                Save
              </Button>
            )}
          </div>
        </div>
        <div className="row mx-0 mt-4">
          <div className="col-10 mx-auto">
            <div className="row gx-5">
              <div className="col-md-12">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Name</Form.Label>
                  <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1">
                      <img src="/imgs/name.svg" alt="" />
                    </InputGroup.Text>
                    <Form.Control
                      type="text"
                      name="name"
                      placeholder="Product Plan Name Here"
                      onChange={handleAddFormChange}
                    />
                  </InputGroup>
                </Form.Group>
              </div>
              <div className="col-md-12">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    name="description"
                    onChange={handleAddFormChange}
                  />
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Product Type</Form.Label>
                  <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1">
                      <img src="/imgs/product-type.svg" alt="" />
                    </InputGroup.Text>
                    <Form.Select
                      aria-label="Default select example"
                      name="product_type"
                      onChange={handleAddFormChange}
                    >
                      <option value="">Select Product Type</option>
                      <option value="Blog">Blog</option>
                      {/* <option value="Video">Video</option> */}
                      <option value="360 tour">360 tour</option>
                    </Form.Select>
                  </InputGroup>
                </Form.Group>
              </div>
              {QtyH == false && (
                <div className="col-md-6">
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Product Credit Qty</Form.Label>
                    <InputGroup className="mb-3">
                      <InputGroup.Text id="basic-addon1">
                        <img src="/imgs/pricing.svg" alt="" />
                      </InputGroup.Text>
                      <Form.Control
                        type="number"
                        min="0"
                        name="product_credit"
                        onChange={handleAddFormChange}
                      />
                    </InputGroup>
                  </Form.Group>
                </div>
              )}

              <div className="col-md-6">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Content Generator</Form.Label>
                  <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1">
                      <img src="/imgs/name.svg" alt="" />
                    </InputGroup.Text>
                    <Form.Select
                      aria-label="Default select example"
                      name="content_generator"
                      onChange={handleAddFormChange}
                    >
                      <option value="">Select Content Generated</option>
                      <option value="Self-Generated">Self-Generated</option>
                      <option value="Commissioned">Commissioned</option>
                    </Form.Select>
                  </InputGroup>
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Pricing Type</Form.Label>
                  <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1">
                      <img src="/imgs/credit.svg" alt="" />
                    </InputGroup.Text>
                    <Form.Select
                      aria-label="Default select example"
                      name="pricing_type"
                      onChange={handleAddFormChange}
                      defaultValue={""}
                    >
                      <option value="">Select Pricing Type</option>
                      <option value="Valued">Valued</option>
                      <option value="Quoted">Quoted</option>
                    </Form.Select>
                  </InputGroup>
                </Form.Group>
              </div>
              {ShowPrice == false && (
                <div className="col-md-6">
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Pricing Amount</Form.Label>
                    <InputGroup className="mb-3">
                      <InputGroup.Text id="basic-addon1">
                        <img src="/imgs/amount.svg" alt="" />
                      </InputGroup.Text>
                      <Form.Control
                        type="number"
                        min="0"
                        name="pricing_amount"
                        disabled={ShowPrice}
                        onChange={handleAddFormChange}
                      />
                    </InputGroup>
                  </Form.Group>
                </div>
              )}

              <div className="col-md-6">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Display</Form.Label>
                  <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1">
                      <img src="/imgs/password.svg" alt="" />
                    </InputGroup.Text>
                    <Form.Select
                      aria-label="Default select example"
                      name="display"
                      onChange={handleAddFormChange}
                    >
                      <option value="">Select Display</option>
                      <option value="Visible">Visible</option>
                      <option value="Hide">Hide</option>
                    </Form.Select>
                  </InputGroup>
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Status</Form.Label>
                  <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1">
                      <img src="/imgs/status-info.svg" alt="" />
                    </InputGroup.Text>
                    <Form.Select
                      aria-label="Default select example"
                      name="status"
                      onChange={handleAddFormChange}
                    >
                      <option value="">Select Status</option>
                      <option value="Active">Active</option>
                      <option value="Revoked">Revoked</option>
                    </Form.Select>
                  </InputGroup>
                </Form.Group>
              </div>
              {ShowPrice == false && (
                <div className="col-md-6">
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Questionaire</Form.Label>
                    <InputGroup className="mb-3">
                      <InputGroup.Text id="basic-addon1">
                        <img src="/imgs/status-info.svg" alt="" />
                      </InputGroup.Text>
                      <Form.Select
                        aria-label="Default select example"
                        name="questionaire"
                        onChange={handleAddFormChange}
                      >
                        <option value="">Select</option>
                        {questionsData?.map((val, i) => (
                          <option value={val._id} key={i}>
                            {val.name}
                          </option>
                        ))}
                      </Form.Select>
                    </InputGroup>
                  </Form.Group>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PlanForm;
