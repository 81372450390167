import { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import Button from "react-bootstrap/Button";
import { useDispatch, useSelector } from "react-redux";
import {
  addBadWordData,
  getBadword,
  updateBadWord,
  deleteBadWord,
  reset,
} from "../../../../features/badWord/BadWordSlice";
import Dragable from "../../../Dragable/Dragable";
import { get } from "jquery";

const BadWord = () => {
  const [value, setValue] = useState("");
  const dispatch = useDispatch();
  const { badWords, isSuccess, message, isLoading, isError } = useSelector(
    (state) => state.badword
  );
  const [data, setData] = useState(badWords);

  const handleAdd = async () => {
    let result = await dispatch(addBadWordData({ content: value }));
    if (result?.payload?.status === "Success") {
      toast.success(result?.payload?.message);
      setTimeout(() => {
        dispatch(getBadword());
      }, 1000);
    } else {
      toast.error(result?.payload);
      setTimeout(() => dispatch(getBadword()), 1000);
    }
    setValue("");
  };
  const DeleteNav = async (ID, isRevoke) => {
    if (window.confirm("are you sure, you want to delete?")) {
      let result = await dispatch(deleteBadWord(ID));
      if (result?.payload?.status === "Success") {
        toast.success(result.payload?.message);
        setTimeout(() => dispatch(getBadword()), 1000);
      }
    }
    dispatch(getBadword());
  };

  useEffect(() => {
    dispatch(getBadword());
    if (badWords.length > 0) {
      setData(badWords);
    }
  }, [isSuccess]);

  return (
    <div className="staffMainDiv">
      <ToastContainer />
      <div className="row mx-0 mb-4">
        <div className="d-flex justify-content-end">
          <input
            type="text"
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
          <Button className="loginBtn" onClick={handleAdd}>
            Add new
          </Button>
        </div>
      </div>

      <Dragable
        nav_List={badWords}
        // EditData={EditData}
        DeleteNav={DeleteNav}
        setData={setData}
        data={badWords}
      />
    </div>
  );
};

export default BadWord;
