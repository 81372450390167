import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import otherPagesService from './otherPagesService'

const initialState = {
  O_Pages: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  isAdded: false,
  isUpdated: false,
  message: '',
}


export const getOtherPages = createAsyncThunk(
  'other/getOtherPages',
  async (thunkAPI) => {
    try {
      return await otherPagesService.othersList()
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const addOtherPages = createAsyncThunk(
  'other/addOtherPages',
  async (userData,thunkAPI) => {
    try {
      return await otherPagesService.addOtherPages(userData)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)


export const editOtherPages = createAsyncThunk(
  'other/editOtherPages',
  async (userData,thunkAPI) => {
    try {
      return await otherPagesService.editOtherPages(userData)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const deleteOtherPages = createAsyncThunk(
  'other/deleteOtherPages',
  async (ID,thunkAPI) => {
    try {
      return await otherPagesService.deleteOtherPages(ID)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

// deleted rows
export const deletedRowsO = createAsyncThunk('other/deletedRowsO', async (passData, thunkAPI) => {
  try {
    return await otherPagesService.deletedRowsO(passData)
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

export const otherPagesSlice = createSlice({
  name: 'other',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false
      state.isSuccess = false
      state.isError = false
      state.isAdded = false
      state.isUpdated = false
      state.message = ''
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getOtherPages.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getOtherPages.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.message = action.payload.data.message
        state.O_Pages = action.payload.data
      })
      .addCase(getOtherPages.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
        state.O_Pages = null
      })

      .addCase(addOtherPages.pending, (state) => {
        state.isLoading = true
      })
      .addCase(addOtherPages.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.message = action.payload
        state.isAdded = true
        // state.O_Pages = [...state.O_Pages, action.payload.data] 
      })
      .addCase(addOtherPages.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
        state.O_Pages = null
      })

      .addCase(editOtherPages.pending, (state) => {
        state.isLoading = true
      })
      .addCase(editOtherPages.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.message = action.payload
        state.isUpdated = true
        // state.O_Pages = action.payload.data 
      })
      .addCase(editOtherPages.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
        state.O_Pages = null
      })

      .addCase(deleteOtherPages.pending, (state) => {
        state.isLoading = true
      })
      .addCase(deleteOtherPages.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.message = 'Deleted!'
        // state.O_Pages = action.payload.data 
      })
      .addCase(deleteOtherPages.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
        state.O_Pages = null
      })

      .addCase(deletedRowsO.pending, (state) => {
        state.isLoading = true
      })
      .addCase(deletedRowsO.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.isError = false
        // state.user = action.payload
        state.message = action.payload.data
      })
      .addCase(deletedRowsO.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.isSuccess = false
        state.message = action.payload
        // state.user = null
      })
  },
})

export const { reset } = otherPagesSlice.actions
export default otherPagesSlice.reducer