import { Input } from "@mui/material";
import React, { useLayoutEffect, useState } from "react";
import { useEffect } from "react";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Table from "react-bootstrap/Table";
import { useForm } from "react-hook-form";

// import { useForm } from "react-hook-form";

function AddPermission({
  AddForm,
  register,
  handleSubmit,
  Edit,
  New,
  // selectAll
}) {
  // const { register, handleSubmit, watch, formState: { errors } } = useForm();
  const [Customer, setCustomer] = React.useState(["view", "create"]);
  const [Contributor, setContributor] = React.useState(["view", "create"]);
  const [ArticlesReview, setArticlesReview] = React.useState([
    "view",
    "create",
  ]);
  const [VirtualReview, setVirtualReview] = React.useState(["view", "create"]);
  const [ArticlesOrder, setArticlesOrder] = React.useState(["view", "create"]);
  const [Billing, setBilling] = React.useState([]);

  const AllData = ["All", "view", "create", "edit", "delete"];

  const SelectAll = (val, name) => {
    if (name === "Customers") {
      if (val === "All") {
        if (Customer?.length == 0) {
          setCustomer(AllData);
        } else if (Customer?.length < 5) {
          setCustomer(AllData);
        } else {
          setCustomer([]);
        }
      } else {
        var data = [...Customer];
        if (data.some((val2) => val2 === val)) {
          const index = Customer.indexOf(val);
          if (index > -1) {
            data.splice(index, 1);
            data = data.filter((v) => v !== "All");
          }
          setCustomer(data);
        } else {
          if (Customer?.length == 3) {
            setCustomer(AllData);
          } else {
            setCustomer([...data, val]);
          }
        }
      }
    }

    if (name === "Contributors") {
      if (val === "All") {
        if (Contributor?.length == 0) {
          setContributor(AllData);
        } else if (Customer?.length < 5) {
          setContributor(AllData);
        } else {
          setContributor([]);
        }
      } else {
        var data = [...Contributor];
        if (data.some((val2) => val2 === val)) {
          const index = Contributor.indexOf(val);
          if (index > -1) {
            data.splice(index, 1);
            data = data.filter((v) => v !== "All");
          }
          setContributor(data);
        } else {
          if (Contributor?.length == 3) {
            setContributor(AllData);
          } else {
            setContributor([...data, val]);
          }
        }
      }
    }

    if (name === "ArticlesReviews") {
      if (val === "All") {
        if (ArticlesReview?.length == 0) {
          setArticlesReview(AllData);
        } else if (ArticlesReview?.length < 5) {
          setArticlesReview(AllData);
        } else {
          setArticlesReview([]);
        }
      } else {
        var data = [...ArticlesReview];
        if (data.some((val2) => val2 === val)) {
          const index = ArticlesReview.indexOf(val);
          if (index > -1) {
            data.splice(index, 1);
            data = data.filter((v) => v !== "All");
          }
          setArticlesReview(data);
        } else {
          if (ArticlesReview?.length == 3) {
            setArticlesReview(AllData);
          } else {
            setArticlesReview([...data, val]);
          }
        }
      }
    }

    if (name === "VirtualReviews") {
      if (val === "All") {
        if (VirtualReview?.length == 0) {
          setVirtualReview(AllData);
        } else if (VirtualReview?.length < 5) {
          setVirtualReview(AllData);
        } else {
          setVirtualReview([]);
        }
      } else {
        var data = [...VirtualReview];
        if (data.some((val2) => val2 === val)) {
          const index = VirtualReview.indexOf(val);
          if (index > -1) {
            data.splice(index, 1);
            data = data.filter((v) => v !== "All");
          }
          setVirtualReview(data);
        } else {
          if (VirtualReview?.length == 3) {
            setVirtualReview(AllData);
          } else {
            setVirtualReview([...data, val]);
          }
        }
      }
    }

    if (name === "ArticlesOrders") {
      if (val === "All") {
        if (ArticlesOrder?.length == 0) {
          setArticlesOrder(AllData);
        } else if (ArticlesOrder?.length < 5) {
          setArticlesOrder(AllData);
        } else {
          setArticlesOrder([]);
        }
      } else {
        var data = [...ArticlesOrder];
        if (data.some((val2) => val2 === val)) {
          const index = ArticlesOrder.indexOf(val);
          if (index > -1) {
            data.splice(index, 1);
            data = data.filter((v) => v !== "All");
          }
          setArticlesOrder(data);
        } else {
          if (ArticlesOrder?.length == 3) {
            setArticlesOrder(AllData);
          } else {
            setArticlesOrder([...data, val]);
          }
        }
      }
    }

    if (name === "Billing") {
      if (val === "All") {
        if (Billing?.length == 0) {
          setBilling(AllData);
        } else if (Billing?.length < 5) {
          setBilling(AllData);
        } else {
          setBilling([]);
        }
      } else {
        var data = [...Billing];
        if (data.some((val2) => val2 === val)) {
          const index = Billing.indexOf(val);
          if (index > -1) {
            data.splice(index, 1);
            data = data.filter((v) => v !== "All");
          }
          setBilling(data);
        } else {
          if (Billing?.length == 3) {
            setBilling(AllData);
          } else {
            setBilling([...data, val]);
          }
        }
      }
    }
  };

  const checkVAlues = (data) => {
    console.log("sdata: ", data);
  };

  return (
    <div className="row mx-0 mt-4">
      <form onSubmit={handleSubmit(AddForm)}>
        <div className="col-10 mx-auto">
          <Table responsive bordered>
            <thead>
              <tr>
                <th>
                  <div className="d-flex justify-content-between">
                    Permissions
                  </div>
                </th>
                <th>
                  <div className="d-flex justify-content-between">
                    View Data
                  </div>
                </th>
                <th>
                  <div className="d-flex justify-content-between">
                    Create Data
                  </div>
                </th>
                <th>
                  <div className="d-flex justify-content-between">
                    Edit Data
                  </div>
                </th>
                <th>
                  <div className="d-flex justify-content-between">
                    Delete Data
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div className="d-flex justify-content-between">
                    Users
                    <div className="tblCheckBox">
                      <Form.Group
                        controlId="formBasicCheckbox"
                        onClick={() => SelectAll("All", "Customers")}
                      >
                        <Form.Check
                          type="checkbox"
                          value={"All"}
                          checked={Customer?.some((val) => val === "All")}
                          {...register("Customers", {
                            required: {
                              value: false,
                            },
                          })}
                        />
                      </Form.Group>
                    </div>
                  </div>
                </td>
                <td className="tblCheckBox">
                  <Form.Group
                    controlId="formBasicCheckbox"
                    onClick={() => SelectAll("view", "Customers")}
                  >
                    <Form.Check
                      type="checkbox"
                      value="view"
                      checked={Customer?.some((val) => val === "view")}
                      {...register("Customers", {
                        required: {
                          value: false,
                        },
                      })}
                    />
                  </Form.Group>
                </td>
                <td className="tblCheckBox">
                  <Form.Group
                    controlId="formBasicCheckbox"
                    onClick={() => SelectAll("create", "Customers")}
                  >
                    <Form.Check
                      type="checkbox"
                      value="create"
                      checked={Customer?.some((val) => val === "create")}
                      {...register("Customers", {
                        required: {
                          value: false,
                        },
                      })}
                    />
                  </Form.Group>
                </td>
                <td className="tblCheckBox">
                  <Form.Group
                    controlId="formBasicCheckbox"
                    onClick={() => SelectAll("edit", "Customers")}
                  >
                    <Form.Check
                      type="checkbox"
                      value="edit"
                      checked={Customer?.some((val) => val === "edit")}
                      {...register("Customers", {
                        required: {
                          value: false,
                        },
                      })}
                    />
                  </Form.Group>
                </td>
                <td className="tblCheckBox">
                  <Form.Group
                    controlId="formBasicCheckbox"
                    onClick={() => SelectAll("delete", "Customers")}
                  >
                    <Form.Check
                      type="checkbox"
                      value="delete"
                      checked={Customer?.some((val) => val === "delete")}
                      {...register("Customers", {
                        required: {
                          value: false,
                        },
                      })}
                    />
                  </Form.Group>
                </td>
              </tr>
              {/* <tr>
                <td>
                  <div className="d-flex justify-content-between">
                    Contributors
                    <div className="tblCheckBox">
                      <Form.Group
                        controlId="formBasicCheckbox"
                        onClick={() => SelectAll("All", "Contributors")}
                      >
                        <Form.Check
                          type="checkbox"
                          value="All"
                          checked={Contributor?.some((val) => val === "All")}
                          {...register("Contributors", {
                            required: {
                              value: false,
                            },
                          })}
                        />
                      </Form.Group>
                    </div>
                  </div>
                </td>
                <td className="tblCheckBox">
                  <Form.Group
                    controlId="formBasicCheckbox"
                    onClick={() => SelectAll("view", "Contributors")}
                  >
                    <Form.Check
                      type="checkbox"
                      value="view"
                      checked={Contributor?.some((val) => val === "view")}
                      {...register("Contributors", {
                        required: {
                          value: false,
                        },
                      })}
                    />
                  </Form.Group>
                </td>
                <td className="tblCheckBox">
                  <Form.Group
                    controlId="formBasicCheckbox"
                    onClick={() => SelectAll("create", "Contributors")}
                  >
                    <Form.Check
                      type="checkbox"
                      value="create"
                      checked={Contributor?.some((val) => val === "create")}
                      {...register("Contributors", {
                        required: {
                          value: false,
                        },
                      })}
                    />
                  </Form.Group>
                </td>
                <td className="tblCheckBox">
                  <Form.Group
                    controlId="formBasicCheckbox"
                    onClick={() => SelectAll("edit", "Contributors")}
                  >
                    <Form.Check
                      type="checkbox"
                      value="edit"
                      checked={Contributor?.some((val) => val === "edit")}
                      {...register("Contributors", {
                        required: {
                          value: false,
                        },
                      })}
                    />
                  </Form.Group>
                </td>
                <td className="tblCheckBox">
                  <Form.Group
                    controlId="formBasicCheckbox"
                    onClick={() => SelectAll("delete", "Contributors")}
                  >
                    <Form.Check
                      type="checkbox"
                      value="delete"
                      checked={Contributor?.some((val) => val === "delete")}
                      {...register("Contributors", {
                        required: {
                          value: false,
                        },
                      })}
                    />
                  </Form.Group>
                </td>
              </tr> */}
              <tr>
                <td>
                  <div className="d-flex justify-content-between">
                    Articles & Reviews
                    <div className="tblCheckBox">
                      <Form.Group
                        controlId="formBasicCheckbox"
                        onClick={() => SelectAll("All", "ArticlesReviews")}
                      >
                        <Form.Check
                          type="checkbox"
                          value="All"
                          checked={ArticlesReview?.some((val) => val === "All")}
                          {...register("ArticlesReviews", {
                            required: {
                              value: false,
                            },
                          })}
                        />
                      </Form.Group>
                    </div>
                  </div>
                </td>
                <td className="tblCheckBox">
                  <Form.Group
                    controlId="formBasicCheckbox"
                    onClick={(e) =>
                      SelectAll(e.target.value, "ArticlesReviews")
                    }
                  >
                    <Form.Check
                      type="checkbox"
                      value="view"
                      checked={ArticlesReview?.some((val) => val === "view")}
                      {...register("ArticlesReviews", {
                        required: {
                          value: false,
                        },
                      })}
                    />
                  </Form.Group>
                </td>
                <td className="tblCheckBox">
                  <Form.Group
                    controlId="formBasicCheckbox"
                    onClick={(e) =>
                      SelectAll(e.target.value, "ArticlesReviews")
                    }
                  >
                    <Form.Check
                      type="checkbox"
                      value="create"
                      checked={ArticlesReview?.some((val) => val === "create")}
                      {...register("ArticlesReviews", {
                        required: {
                          value: false,
                        },
                      })}
                    />
                  </Form.Group>
                </td>
                <td className="tblCheckBox">
                  <Form.Group
                    controlId="formBasicCheckbox"
                    onClick={(e) =>
                      SelectAll(e.target.value, "ArticlesReviews")
                    }
                  >
                    <Form.Check
                      type="checkbox"
                      value="edit"
                      checked={ArticlesReview?.some((val) => val === "edit")}
                      {...register("ArticlesReviews", {
                        required: {
                          value: false,
                        },
                      })}
                    />
                  </Form.Group>
                </td>
                <td className="tblCheckBox">
                  <Form.Group
                    controlId="formBasicCheckbox"
                    onClick={(e) =>
                      SelectAll(e.target.value, "ArticlesReviews")
                    }
                  >
                    <Form.Check
                      type="checkbox"
                      value="delete"
                      checked={ArticlesReview?.some((val) => val === "delete")}
                      {...register("ArticlesReviews", {
                        required: {
                          value: false,
                        },
                      })}
                    />
                  </Form.Group>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="d-flex justify-content-between">
                    Virtual Maps & Reviews
                    <div className="tblCheckBox">
                      <Form.Group
                        controlId="formBasicCheckbox"
                        onClick={(e) =>
                          SelectAll(e.target.value, "VirtualReviews")
                        }
                      >
                        <Form.Check
                          type="checkbox"
                          value="All"
                          checked={VirtualReview?.some((val) => val === "All")}
                          {...register("VirtualReviews", {
                            required: {
                              value: false,
                            },
                          })}
                        />
                      </Form.Group>
                    </div>
                  </div>
                </td>
                <td className="tblCheckBox">
                  <Form.Group
                    controlId="formBasicCheckbox"
                    onClick={(e) => SelectAll(e.target.value, "VirtualReviews")}
                  >
                    <Form.Check
                      type="checkbox"
                      value="view"
                      checked={VirtualReview?.some((val) => val === "view")}
                      {...register("VirtualReviews", {
                        required: {
                          value: false,
                        },
                      })}
                    />
                  </Form.Group>
                </td>
                <td className="tblCheckBox">
                  <Form.Group
                    controlId="formBasicCheckbox"
                    onClick={(e) => SelectAll(e.target.value, "VirtualReviews")}
                  >
                    <Form.Check
                      type="checkbox"
                      value="create"
                      checked={VirtualReview?.some((val) => val === "create")}
                      {...register("VirtualReviews", {
                        required: {
                          value: false,
                        },
                      })}
                    />
                  </Form.Group>
                </td>
                <td className="tblCheckBox">
                  <Form.Group
                    controlId="formBasicCheckbox"
                    onClick={(e) => SelectAll(e.target.value, "VirtualReviews")}
                  >
                    <Form.Check
                      type="checkbox"
                      value="edit"
                      checked={VirtualReview?.some((val) => val === "edit")}
                      {...register("VirtualReviews", {
                        required: {
                          value: false,
                        },
                      })}
                    />
                  </Form.Group>
                </td>
                <td className="tblCheckBox">
                  <Form.Group
                    controlId="formBasicCheckbox"
                    onClick={(e) => SelectAll(e.target.value, "VirtualReviews")}
                  >
                    <Form.Check
                      type="checkbox"
                      value="delete"
                      checked={VirtualReview?.some((val) => val === "delete")}
                      {...register("VirtualReviews", {
                        required: {
                          value: false,
                        },
                      })}
                    />
                  </Form.Group>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="d-flex justify-content-between">
                    Articles Orders
                    <div className="tblCheckBox">
                      <Form.Group
                        controlId="formBasicCheckbox"
                        onClick={(e) =>
                          SelectAll(e.target.value, "ArticlesOrders")
                        }
                      >
                        <Form.Check
                          type="checkbox"
                          value="All"
                          checked={ArticlesOrder?.some((val) => val === "All")}
                          {...register("ArticlesOrders", {
                            required: {
                              value: false,
                            },
                          })}
                        />
                      </Form.Group>
                    </div>
                  </div>
                </td>
                <td className="tblCheckBox">
                  <Form.Group
                    controlId="formBasicCheckbox"
                    onClick={(e) => SelectAll(e.target.value, "ArticlesOrders")}
                  >
                    <Form.Check
                      type="checkbox"
                      value="view"
                      checked={ArticlesOrder?.some((val) => val === "view")}
                      {...register("ArticlesOrders", {
                        required: {
                          value: false,
                        },
                      })}
                    />
                  </Form.Group>
                </td>
                <td className="tblCheckBox">
                  <Form.Group
                    controlId="formBasicCheckbox"
                    onClick={(e) => SelectAll(e.target.value, "ArticlesOrders")}
                  >
                    <Form.Check
                      type="checkbox"
                      value="create"
                      checked={ArticlesOrder?.some((val) => val === "create")}
                      {...register("ArticlesOrders", {
                        required: {
                          value: false,
                        },
                      })}
                    />
                  </Form.Group>
                </td>
                <td className="tblCheckBox">
                  <Form.Group
                    controlId="formBasicCheckbox"
                    onClick={(e) => SelectAll(e.target.value, "ArticlesOrders")}
                  >
                    <Form.Check
                      type="checkbox"
                      value="edit"
                      checked={ArticlesOrder?.some((val) => val === "edit")}
                      {...register("ArticlesOrders", {
                        required: {
                          value: false,
                        },
                      })}
                    />
                  </Form.Group>
                </td>
                <td className="tblCheckBox">
                  <Form.Group
                    controlId="formBasicCheckbox"
                    onClick={(e) => SelectAll(e.target.value, "ArticlesOrders")}
                  >
                    <Form.Check
                      type="checkbox"
                      value="delete"
                      checked={ArticlesOrder?.some((val) => val === "delete")}
                      {...register("ArticlesOrders", {
                        required: {
                          value: false,
                        },
                      })}
                    />
                  </Form.Group>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="d-flex justify-content-between">
                    Billing
                    <div className="tblCheckBox">
                      <Form.Group
                        controlId="formBasicCheckbox"
                        onClick={(e) => SelectAll(e.target.value, "Billing")}
                      >
                        <Form.Check
                          type="checkbox"
                          value="All"
                          checked={Billing?.some((val) => val === "All")}
                          {...register("Billing", {
                            required: {
                              value: false,
                            },
                          })}
                        />
                      </Form.Group>
                    </div>
                  </div>
                </td>
                <td className="tblCheckBox">
                  <Form.Group
                    controlId="formBasicCheckbox"
                    onClick={(e) => SelectAll(e.target.value, "Billing")}
                  >
                    <Form.Check
                      type="checkbox"
                      value="view"
                      checked={Billing?.some((val) => val === "view")}
                      {...register("Billing", {
                        required: {
                          value: false,
                        },
                      })}
                    />
                  </Form.Group>
                </td>
                <td className="tblCheckBox">
                  <Form.Group
                    controlId="formBasicCheckbox"
                    onClick={(e) => SelectAll(e.target.value, "Billing")}
                  >
                    <Form.Check
                      type="checkbox"
                      value="create"
                      checked={Billing?.some((val) => val === "create")}
                      {...register("Billing", {
                        required: {
                          value: false,
                        },
                      })}
                    />
                  </Form.Group>
                </td>
                <td className="tblCheckBox">
                  <Form.Group
                    controlId="formBasicCheckbox"
                    onClick={(e) => SelectAll(e.target.value, "Billing")}
                  >
                    <Form.Check
                      type="checkbox"
                      value="edit"
                      checked={Billing?.some((val) => val === "edit")}
                      {...register("Billing", {
                        required: {
                          value: false,
                        },
                      })}
                    />
                  </Form.Group>
                </td>
                <td className="tblCheckBox">
                  <Form.Group
                    controlId="formBasicCheckbox"
                    onClick={(e) => SelectAll(e.target.value, "Billing")}
                  >
                    <Form.Check
                      type="checkbox"
                      value="delete"
                      checked={Billing?.some((val) => val === "delete")}
                      {...register("Billing", {
                        required: {
                          value: false,
                        },
                      })}
                    />
                  </Form.Group>
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      </form>
    </div>
  );
}

export default AddPermission;
