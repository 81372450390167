import React, { useEffect, useLayoutEffect, useState } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import { v4 as uuid } from "uuid";
import { useDispatch, useSelector } from "react-redux";
import {
  addQuestion,
  editQuestion,
  reset,
} from "../../../../features/Questions/questionSlice";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { getDetails } from "./../../../../features/Questions/questionSlice";
import QueueIcon from "@mui/icons-material/Queue";
import { toast, ToastContainer } from "react-toastify";

function QuestionareForm() {
  const user = JSON.parse(localStorage.getItem("user"));
  const userID = user?.data?.user?._id;

  const { state } = useLocation();
  const { id } = useParams();

  const {
    questions_List,
    isSuccess,
    isError,
    message,
    isLoading,
    isList,
    isAdded,
    isUpdated,
  } = useSelector((state) => state.questions);

  const [Name, setName] = useState("");
  const [Status, setStatus] = useState("");
  const [Questions, setQuestions] = useState([]);
  const [Quest, setQuest] = useState("");
  const [Score, setScore] = useState(0);
  const [answerType, setAnswerType] = useState("choice");
  const [DelID, setDelID] = useState("");
  const [Show, setShow] = useState(false);
  const [question1Answer, setQuestion1Answer] = useState("");
  const [question2Answer, setQuestion2Answer] = useState("yes");
  const [customScore, setCustomScore] = useState(0);
  const [options, setOptions] = useState([]);
  const [templateQuestions, setTemplateQuestion] = useState(0);

  const [addQuest, setAddQuest] = useState(false);
  const [editQuest, setEditQuest] = useState({ val: false, id: "" });
  const [type, setType] = useState("custom");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleClose = () => {
    setEditQuest({ val: false, id: "" });
    setQuest("");
    setScore(0);
    setDelID("");
    setOptions([]);
    setQuest("");
  };

  useLayoutEffect(() => {
    dispatch(reset());
    if (id) {
      dispatch(getDetails(id));
    }
  }, []);

  useEffect(() => {
    if (questions_List?.length > 0) {
      const data = [...questions_List]
        .reverse()
        .filter((item) => item.type === "template");
      setTemplateQuestion(data?.length);
    }
    if (questions_List && state) {
      const [name] = questions_List.map((val) => val.name);
      const [status] = questions_List.map((val) => val.status);
      const [questions] = questions_List.map((val) => val.questions);
      const [type] = questions_List.map((val) => val.type);
      console.log({ type });
      setType(type);
      setName(name);
      setStatus(status);
      setQuestions(questions);
    }
    if (isAdded == true && message?.message !== "") {
      toast.success(message.message);
      dispatch(reset());
      setTimeout(() => {
        navigate("/questionnaire");
      }, 1000);
    }

    if (isUpdated == true && message?.message !== "") {
      toast.success(message.message);
      dispatch(reset());
      setTimeout(() => {
        navigate("/questionnaire");
      }, 500);
    }
    if (isError == true && message !== "") {
      toast.error(message);
      dispatch(reset());
    }
  }, [dispatch, isSuccess, isError, message, isAdded, isUpdated]);

  const AddNewQuest = () => {
    //add new question row

    const Id = uuid().slice(0, 8);

    const newArray = {
      id: Id,
      question: Quest,
      answers: [],
      score:
        answerType === "choice"
          ? options
          : question2Answer === "yes" && customScore,
      answerType,
    };
    const Data = [...Questions, newArray];
    setQuestions(Data);
    setQuest("");
    setScore(0);
    setAddQuest(false);
    setAnswerType("choice");
    setCustomScore(0);
    setOptions([]);
  };
  console.log("template", templateQuestions);

  const ShowEditQuest = (val) => {
    setQuest(val?.question);
    setOptions(val?.score);
    setAnswerType(val?.answerType);
    if (
      val?.answerType === "yesNo" ||
      val?.answerType === "address" ||
      val?.answerType === "image"
    ) {
      setCustomScore(val?.score);
    } else {
      setScore(val?.score);
    }
    setEditQuest({ val: true, id: val?.id });
  };

  const addOption = (text, score) => {
    const newOption = { text, score };
    setOptions([...options, newOption]);
  };
  const removeOption = (index) => {
    const updatedOptions = options.filter((_, i) => i !== index);
    setOptions(updatedOptions);
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    const optionText = event.target.optionText.value;
    const optionScore = parseInt(event.target.optionScore.value);
    addOption(optionText, optionScore);
    event.target.reset();
  };
  const EditQuest = () => {
    const newData = [...Questions];
    const index = newData.findIndex((val) => val.id == editQuest.id);
    console.log(
      answerType === "yesNo"
        ? question2Answer == "no"
          ? false
          : customScore
        : options
    );
    const newArray = {
      id: editQuest.id,
      question: Quest,
      answers: [],
      score:
        answerType == "yesNo" ||
        answerType == "image" ||
        answerType == "address"
          ? question2Answer == "no"
            ? false
            : customScore
          : options,
      answerType,
    };
    newData[index] = newArray;
    setQuestions(newData);
    setEditQuest({ val: false, id: "" });
    setQuest("");
    setScore(0);
  };

  const DeleteQuestion = (ID) => {
    setDelID(ID);
    setShow(true);
  };
  const deleteQuest = () => {
    const data = [...Questions];
    const newData = data.filter((val) => val.id != DelID);
    setQuestions(newData);
    setDelID("");
    setShow(false);
  };
  const SubmitQuestion = () => {
    const questionSum = Questions.reduce(
      (sum, question) => sum + Number(question.score),
      0
    );

    const TARGET_SCORE = 100;

    if (questionSum < TARGET_SCORE) {
      toast.error(
        "Total Score of the questionnaire must be 100 to save the questionnaire."
      );
    } else {
      const newData = {
        name: Name,
        status: Status,
        type,
        questions: Questions,
        answers: [],
        owner: userID,
        answerType: answerType,
        score:
          answerType === "choice"
            ? options
            : question2Answer === "yes"
            ? customScore
            : "no",
      };

      dispatch(addQuestion(newData));
    }
  };

  const UpdatedQuestion = () => {
    const newData = {
      mainID: id,
      subID: state.subID,
      name: Name,
      status: Status,
      questions: Questions,
      answers: [],
    };

    dispatch(editQuestion(newData));
    // navigate(-1)
  };

  return (
    <div className="questionareDetailForm">
      <ToastContainer />
      <div className="d-flex justify-content-between">
        <h1 className="outTitle">Detail form</h1>
      </div>
      <div className="row mx-0 mt-3">
        <div className="col-sm-11 mx-auto">
          <div className="cardSection">
            <div className="row mx-0 mt-4">
              <div className="col-md-5">
                <Form.Group className="mb-3">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    value={Name}
                    disabled={
                      state?.view == true
                        ? true
                        : type === "template"
                        ? true
                        : false
                    }
                    onChange={(e) => setName(e.target.value)}
                  />
                </Form.Group>
              </div>
              <div className="col-md-2"></div>
              <div className="col-md-5">
                <Form.Group className="mb-3 ">
                  <Form.Label>Status</Form.Label>
                  <div
                    className="d-flex gap-3"
                    onChange={(e) => setStatus(e.target.value)}
                  >
                    <Form.Check
                      type="radio"
                      name="status"
                      id="active_radio"
                      label="Active"
                      value="Active"
                      disabled={state?.view == true ? true : false}
                      checked={Status === "Active"}
                    />
                    <Form.Check
                      type="radio"
                      name="status"
                      id="nonactive_radio"
                      label="Non Active"
                      value="In-Active"
                      disabled={state?.view == true ? true : false}
                      checked={Status === "In-Active"}
                    />
                  </div>
                </Form.Group>
                {templateQuestions?.length < 2 && (
                  <Form.Group className="mb-3 ">
                    <Form.Label>Type</Form.Label>
                    <div
                      className="d-flex gap-3"
                      onChange={(e) => setType(e.target.value)}
                    >
                      <Form.Check
                        type="radio"
                        label="Template"
                        name="custom"
                        id="templateType"
                        value="template"
                        checked={type === "template"}
                        onChange={(e) => setType(e.target.value)}
                        disabled={state?.view == true ? true : false}
                      />
                      <Form.Check
                        type="radio"
                        name="custom"
                        id="templateType"
                        label="Custom"
                        value="custom"
                        checked={type === "custom"}
                        onChange={(e) => setType(e.target.value)}
                        disabled={state?.view == true ? true : false}
                      />
                    </div>
                  </Form.Group>
                )}
              </div>

              <div className="col-md-12">
                <h3>Questionnaire</h3>
              </div>
              <div className="row">
                <hr />
              </div>
              <div className="col-md-12 questions py-3">
                {state?.edit == true ? (
                  <p
                    onClick={() => setAddQuest(!addQuest)}
                    style={{ cursor: "pointer" }}
                  >
                    Add Question{" "}
                    <QueueIcon
                      style={{ position: "relative", top: "-3px" }}
                      fontSize="small"
                      color="error"
                    />{" "}
                  </p>
                ) : (
                  state == null && (
                    <p
                      onClick={() => setAddQuest(!addQuest)}
                      style={{ cursor: "pointer" }}
                    >
                      Add Question{" "}
                      <QueueIcon
                        style={{ position: "relative", top: "-3px" }}
                        fontSize="small"
                        color="error"
                      />{" "}
                    </p>
                  )
                )}

                <div className="table-responsive my-4">
                  <table border="0" className="w-100 table border-none">
                    <thead>
                      <tr>
                        <th>
                          <Form.Label>Questions</Form.Label>
                        </th>
                        <th>
                          <Form.Label>Score</Form.Label>
                        </th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {Questions?.length > 0 &&
                        Questions?.map((val, index) => (
                          // <div className="row mb-3" key={index}>
                          <tr className="mt-3" key={index}>
                            <td>
                              {/* <div className="row w-100">
                                <div className="col-md-12">
                                  <div className="col-md-12 mb-4"> */}
                              <Form.Group className="w-170 mr-2 mb-2">
                                <Form.Control
                                  className="w-auto"
                                  type="text"
                                  value={val.question}
                                  disabled={state?.view == true ? true : false}
                                />
                              </Form.Group>
                              {/* </div>
                                </div>
                              </div> */}
                            </td>
                            <td>
                              {val?.answerType === "choice"
                                ? val?.score.map((option, index) => (
                                    // <div
                                    //   className="col-lg-6 align-items-center mb-3"
                                    //   key={index}
                                    // >
                                    <div className="d-flex mb-1" key={index}>
                                      <p className="me-2 mb-0">
                                        {option.text}:
                                      </p>
                                      <p className="mb-0">
                                        Score: {option.score}
                                      </p>
                                    </div>
                                    // </div>
                                  ))
                                : (val?.answerType === "yesNo" ||
                                    val?.answerType === "image" ||
                                    val?.answerType === "address") &&
                                  val?.score !== "" &&
                                  val?.score !== false && (
                                    <div className="d-flex">
                                      {val.score}
                                      {/* <Form.Group>
                                        <Form.Control
                                          type="text"
                                          value={val.score}
                                          disabled={
                                            state?.view == true ? true : false
                                          }
                                        />
                                      </Form.Group> */}
                                    </div>
                                  )}
                            </td>
                            <td>
                              {state?.edit == true ? (
                                <div className="col-md-4 text-center my-auto">
                                  <div className="d-flex gap-3 justify-content-center">
                                    <img
                                      src="/imgs/t_edit.svg"
                                      width={20}
                                      alt=""
                                      onClick={() => ShowEditQuest(val)}
                                    />
                                    <img
                                      src="/imgs/delete.svg"
                                      width={20}
                                      alt=""
                                      onClick={() => DeleteQuestion(val.id)}
                                    />
                                  </div>
                                </div>
                              ) : (
                                state == null && (
                                  <div className="col-lg-6 gap-3 mb-3 justify-content-end d-flex">
                                    <img
                                      src="/imgs/t_edit.svg"
                                      width={20}
                                      alt=""
                                      onClick={() => ShowEditQuest(val)}
                                    />
                                    <img
                                      src="/imgs/delete.svg"
                                      width={20}
                                      alt=""
                                      onClick={() => DeleteQuestion(val.id)}
                                    />
                                  </div>
                                )
                              )}
                            </td>
                          </tr>
                          // </div>
                        ))}
                      {/* <td className="w-25">question</td>
                      <td className="w-25">score </td>
                      <td className="w-50">edit </td> */}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className="d-flex justify-content-end">
              <div className="d-flex justify-content-center gap-4 mb-0">
                <Button
                  className="cancelBtn"
                  type="button"
                  onClick={() => navigate(-1)}
                >
                  Cancel
                </Button>
                {state?.edit == true ? (
                  <Button className="loginBtn" onClick={UpdatedQuestion}>
                    Update
                  </Button>
                ) : (
                  <Button
                    className="loginBtn"
                    disabled={state?.view == true ? true : false}
                    onClick={SubmitQuestion}
                  >
                    Save
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={addQuest == true ? addQuest : editQuest.val}
        onHide={() => (addQuest == true ? setAddQuest(false) : handleClose())}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          {addQuest == true ? (
            <Modal.Title>Add New Question</Modal.Title>
          ) : (
            <Modal.Title>Edit Question</Modal.Title>
          )}
        </Modal.Header>
        <Modal.Body>
          <div>
            <h5>Question</h5>

            <textarea
              className="form-control"
              type="textarea"
              rows={3}
              placeholder="Write Question"
              value={Quest}
              onChange={(e) => setQuest(e.target.value)}
            />
          </div>
          <div className="my-3 answerTypeFont">
            <h5>Question type</h5>
            <Form.Check
              inline
              label="Multiple Choices"
              name="group1"
              type="radio"
              id={`group1`}
              value={"choice"}
              checked={answerType === "choice"}
              onChange={(e) => setAnswerType(e.target.value)}
            />
            <Form.Check
              inline
              label="Simple Text"
              name="group1"
              type="radio"
              id={`group2`}
              value={"yesNo"}
              checked={answerType === "yesNo"}
              onChange={(e) => setAnswerType(e.target.value)}
            />
            <Form.Check
              inline
              label="Upload Image"
              name="group1"
              type="radio"
              id={`group3`}
              value={"image"}
              checked={answerType === "image"}
              onChange={(e) => setAnswerType(e.target.value)}
            />
            <Form.Check
              inline
              label="Location"
              name="group1"
              type="radio"
              id={`group4`}
              value={"address"}
              checked={answerType === "address"}
              onChange={(e) => setAnswerType(e.target.value)}
            />
          </div>
          {answerType === "choice" ? (
            <>
              {" "}
              <div>
                {options.map((option, index) => (
                  <div
                    className="d-flex align-items-center justify-content-between"
                    key={index}
                  >
                    <p>{option.text}</p>
                    <p>Score: {option.score}</p>
                    <p onClick={() => removeOption(index)}>
                      <img src="/imgs/revoke.svg" alt="revoke" />
                    </p>
                  </div>
                ))}

                <form onSubmit={handleFormSubmit}>
                  <input
                    className="form-control mb-3"
                    type="text"
                    name="optionText"
                    placeholder="Option text"
                  />
                  <input
                    className="form-control mb-3"
                    type="number"
                    name="optionScore"
                    placeholder="Option score"
                  />
                  <button className="btn btn-sm btn-primary" type="submit">
                    Add Option
                  </button>
                </form>
              </div>
            </>
          ) : (
            answerType === "yesNo" && (
              <>
                <h5>Would you like to set a custom score for this question?</h5>
                <label className="form-control border-0 p-0 mb-2">
                  <input
                    className="me-2"
                    type="radio"
                    name="question2"
                    value="yes"
                    checked={question2Answer === "yes"}
                    onChange={(e) => setQuestion2Answer(e.target.value)}
                  />
                  Yes
                </label>
                <label className="form-control border-0 p-0 mb-3">
                  <input
                    className="me-2"
                    type="radio"
                    name="question2"
                    value="no"
                    checked={question2Answer === "no"}
                    onChange={(e) => setQuestion2Answer(e.target.value)}
                  />
                  No
                </label>
              </>
            )
          )}
          <div>
            {((question2Answer === "yes" && answerType === "yesNo") ||
              answerType === "image" ||
              answerType === "address") && (
              <div>
                <h5>Enter custom score:</h5>
                <input
                  className="form-control"
                  type="number"
                  value={customScore}
                  onChange={(e) => setCustomScore(e.target.value)}
                />
              </div>
            )}
          </div>
          {/* <div>
            <h5>Score</h5>
            <Form.Group>
              <Form.Control
                type="number"
                min={0}
                placeholder="Add Score"
                value={Score}
                onChange={(e) => setScore(e.target.value)}
              />
            </Form.Group>
          </div> */}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() =>
              addQuest == true ? setAddQuest(false) : handleClose()
            }
          >
            Close
          </Button>
          {addQuest == true ? (
            <Button
              variant="btn btn-primary"
              onClick={AddNewQuest}
              // disabled={Quest === "" ? true : false}
            >
              Save
            </Button>
          ) : (
            <Button
              variant="primary"
              onClick={EditQuest}
              // disabled={(Quest && Score) === "" ? true : false}
            >
              Update
            </Button>
          )}
        </Modal.Footer>
      </Modal>

      <Modal
        show={Show}
        onHide={() => {
          setShow(false);
          setDelID("");
        }}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>Delete Question</Modal.Header>
        <Modal.Body>Are you sure you want to delete this question?</Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setShow(false);
              setDelID("");
            }}
          >
            Close
          </Button>

          <Button variant="secondary" onClick={deleteQuest}>
            Sure
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default QuestionareForm;
