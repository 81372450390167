import React, { useState, useEffect, useLayoutEffect } from "react";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import { Button, Form, InputGroup } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getProfile, reset } from "../../../features/MyProfile/profileSlice";
import { Fragment } from "react";
import ReactMapGL, { Marker, NavigationControl } from "react-map-gl";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import { contactUs } from "../../../features/ContactPage/contactSlice";
import { ToastContainer, toast } from "react-toastify";

const icon = L.icon({
  iconUrl: require("../../../assets/map/placeholder.png"),
  iconRetinaUrl: require("../../../assets/map/placeholder.png"),
  iconSize: [38, 38],
});

function ContactUs({ setProfile, Profile }) {
  var key = process.env.REACT_APP_LEAFLET_MAP;
  const user = JSON.parse(localStorage.getItem("user"));
  const { state } = useLocation();
  const {
    profile_data,
    isLoading,
    isError: isErrorAuth,
    isSuccess: isSuccessAuth,
    message: messageAuth,
  } = useSelector((state) => state.profileData);
  const {
    isLoading: contactLoading,
    message,
    isSuccess,
  } = useSelector((state) => state.contactData);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    company: "",
    message: "",
  });
  const dispatch = useDispatch();
  useEffect(() => {
    if (!contactLoading) {
      if (isSuccess && message) {
        toast.success(message);
        setFormData({
          firstName: "",
          lastName: "",
          email: "",
          phone: "",
          company: "",
          message: "",
        });
      } else {
        if (message) {
          toast.error(message);
        }
      }
    }
  }, [contactLoading, message, isSuccess]);

  useLayoutEffect(() => {
    dispatch(reset());
    if (user?.data.user._id) {
      dispatch(getProfile(user?.data.user._id));
      // dispatch(updateLastLogin(ID));
    }
  }, []);

  useEffect(() => {
    if (user?.data.user._id) {
      dispatch(getProfile(user?.data.user._id));
      // dispatch(updateLastLogin(ID));
    }
  }, [isErrorAuth, isSuccessAuth, messageAuth]);

  const handleSubmit = async () => {
    dispatch(contactUs(formData));
  };
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  return (
    <div className="contactUsMain">
      <ToastContainer />
      <Header
        user={user}
        userProfile={profile_data}
        setProfile={setProfile}
        Profile={Profile}
      />
      <div className="contactUs">
        <div className="container-fluid px-50px">
          <div className="row mx-0 ">
            <div className="col-md-9 mx-auto text-center my-auto">
              <h1 className="mt-5">Contact us</h1>
              <p>{state?.Contact?.PageContent[0]?.discription}</p>
              <div className="contactCard my-4">
                <div className="row mx-0 justify-content-between">
                  <div className="col-md-5 text-start">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Email Address</Form.Label>
                      <br />
                      {state?.Contact?.Email?.map(
                        (val, i) =>
                          val?.display === "Visible" && (
                            <>
                              <label>{val?.name}</label>
                              <InputGroup className="mb-3" key={i}>
                                <InputGroup.Text id="basic-addon1">
                                  <img src="/imgs/email.svg" alt="" />
                                </InputGroup.Text>
                                <Form.Control
                                  type="email"
                                  name="product_credit"
                                  value={val.email}
                                />
                              </InputGroup>
                            </>
                          )
                      )}
                    </Form.Group>
                    {/* <Form.Group className="mb-3" controlId="formBasicEmail">
                      <InputGroup className="mb-3">
                        <InputGroup.Text id="basic-addon1">
                          <img src="/imgs/email.svg" alt="" />
                        </InputGroup.Text>
                        <Form.Control type="email" name="product_credit" value={state?.Contact?.defaullt_email} />
                      </InputGroup>
                    </Form.Group> */}
                  </div>
                  <div className="col-md-5 text-start">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Phone</Form.Label>
                      <br />
                      {state?.Contact?.Contact?.map(
                        (val, i) =>
                          val?.display === "Visible" && (
                            <>
                              <label>{val?.name}</label>
                              <InputGroup className="mb-3" key={i}>
                                <InputGroup.Text id="basic-addon1">
                                  <img src="/imgs/phone.svg" alt="" />
                                </InputGroup.Text>
                                <Form.Control
                                  type="number"
                                  name="product_credit"
                                  value={val?.contact}
                                />
                              </InputGroup>
                            </>
                          )
                      )}
                    </Form.Group>
                  </div>
                </div>
              </div>
              <div className="contactCard mb-4">
                <div className="row mx-0 justify-content-between">
                  <div className="col-12 text-start">
                    <Form.Label>Address</Form.Label>
                  </div>
                  {state?.Contact?.Address?.map(
                    (val, i) =>
                      val.display == true && (
                        <Fragment key={i}>
                          <div className="col-md-5 text-start">
                            <Form.Group
                              className="mb-3"
                              controlId="formBasicEmail"
                            >
                              <InputGroup className="mb-3">
                                <InputGroup.Text id="basic-addon1">
                                  <img src="/imgs/address.svg" alt="" />
                                </InputGroup.Text>
                                <Form.Control
                                  as="textarea"
                                  rows={4}
                                  value={val?.address}
                                />
                              </InputGroup>
                            </Form.Group>
                          </div>
                          <div className="col-md-5 text-start">
                            {/* map section below */}
                            <ReactMapGL
                              // center={position}
                              mapboxAccessToken="pk.eyJ1IjoiYmx1ZXNpbG9zZyIsImEiOiJjbGZyeDZ1MGswMG1mM2RxcmJyajRzbXRyIn0.SwkzsvT0dgMXSafnYQoZSQ"
                              style={{ width: "100%", height: "130px" }}
                              initialViewState={{
                                longitude: `${val?.longi}`,
                                latitude: `${val?.lat}`,
                                zoom: 15,
                              }}
                              mapStyle="mapbox://styles/mapbox/streets-v11"
                            >
                              <Marker
                                latitude={val?.lat}
                                longitude={val?.longi}
                                draggable
                              />
                            </ReactMapGL>
                          </div>
                        </Fragment>
                      )
                  )}
                  <div className="col-12 ">
                    <div className="addressInfo">
                      <ul>
                        {state?.Contact?.Address?.map(
                          (val, i) =>
                            val?.display == true && (
                              <li key={i}>{val?.address}</li>
                            )
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="haveQuestion mb-5">
                <h1>Have A Question For Us ?</h1>
                <Form>
                  <div className="row gx-5">
                    <div className="col-md-6">
                      <Form.Group className="mb-4">
                        <Form.Control
                          type="text"
                          placeholder="First Name"
                          name="firstName"
                          value={formData.firstName}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </div>
                    <div className="col-md-6">
                      <Form.Group className="mb-4">
                        <Form.Control
                          type="text"
                          placeholder="Last Name"
                          name="lastName"
                          value={formData.lastName}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </div>
                    <div className="col-md-6">
                      <Form.Group className="mb-4">
                        <Form.Control
                          type="email"
                          placeholder="Enter Your Email Address"
                          name="email"
                          value={formData.email}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </div>
                    <div className="col-md-6">
                      <Form.Group className="mb-4">
                        <Form.Control
                          type="text"
                          placeholder="Phone"
                          name="phone"
                          value={formData.phone}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </div>
                    <div className="col-md-6">
                      <Form.Group className="mb-4">
                        <Form.Control
                          type="text"
                          placeholder="Company"
                          name="company"
                          value={formData.company}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </div>
                    <div className="col-md-12">
                      <Form.Group className="mb-3">
                        <Form.Control
                          as="textarea"
                          rows={8}
                          placeholder="Message"
                          name="message"
                          value={formData.message}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </div>
                    <div className="col-12 mt-3 mb-4">
                      <Button className="loginBtn w-230" onClick={handleSubmit}>
                        Submit
                      </Button>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default ContactUs;
