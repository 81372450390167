import React, { useEffect, useLayoutEffect, useState } from "react";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import { OpenStreetMapProvider } from "react-leaflet-geosearch";
import { useValue } from "../../../../context/ContextProvider";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import ReactMapGL, { Marker, NavigationControl } from "react-map-gl";
import { useRef } from "react";
const icon = L.icon({
  iconUrl: require("../../../../assets/map/placeholder.png"),
  iconRetinaUrl: require("../../../../assets/map/placeholder.png"),
  iconSize: [38, 38],
  shadowSize: [22, 36],
  iconAnchor: [22, 36], // point of the icon which will correspond to marker's location
  shadowAnchor: [4, 42],
  popupAnchor: [-3, -76],
});

function LocationAdddress({ Details }) {
  var key = process.env.REACT_APP_LEAFLET_MAP;
  const prov = OpenStreetMapProvider();
  const { state } = useLocation();
  const params = useParams();
  const mapRef = useRef();
  const {
    state: {
      location: { lng, lat },
    },
    mapdispatch,
  } = useValue();

  const [stateLng, setStateLng] = useState(null);
  const [stateLat, setStateLat] = useState(null);

  // useEffect(() => {
  //   const latitude = Details && Details.full_address && Details.full_address?.coordinates?.[1];
  //   const longitude = Details && Details.full_address && Details.full_address?.coordinates?.[0];
  //   const isValidLatitude = latitude >= -90 && latitude <= 90;
  //   const isValidLongitude = longitude >= -180 && longitude <= 180;
  //   if (mapRef.current && isValidLatitude && isValidLongitude) {
  //     mapRef.current.flyTo({
  //       zoom: 15,
  //       center: [Number(latitude), Number(longitude)],
  //     });
  //   }
  // }, [Details, lat, lng, mapRef]);

  useEffect(() => {
    if (Details && Details.full_address) {
      // if (params?.id === Details?._id) {
      setStateLng(Details.full_address?.coordinates?.[0]);
      setStateLat(Details.full_address?.coordinates?.[1]);
      // }
    }
  }, [mapRef.current, Details, params]);

  return (
    <div className="locationAdddress">
      <div className="container">
        <div className="row">
          <div className="col-12 mb-4">
            <h1>Location Address</h1>
          </div>
          <div className="col-lg-6 col-md-8 col-sm-10 mx-auto mb-3">
            <div className="d-flex justify-content-between mt-4">
              <div>
                <h6>Country</h6>
                <h6 className="mt-2">Postal code</h6>
              </div>
              <div className="spacer"></div>
              <div>
                <h6>{Details?.location?.country}</h6>
                <h6 className="mt-2">{Details?.location?.postal_code}</h6>
              </div>
            </div>
          </div>
          <div className="col-11 mx-auto mt-5">
            <Box
              sx={{
                height: 400,
                position: "relative",
              }}
            >
              {stateLng !== null && stateLat !== null && (
                <React.Fragment>
                  <ReactMapGL
                    ref={mapRef}
                    mapboxAccessToken="pk.eyJ1IjoiYmx1ZXNpbG9zZyIsImEiOiJjbGZyeDZ1MGswMG1mM2RxcmJyajRzbXRyIn0.SwkzsvT0dgMXSafnYQoZSQ"
                    initialViewState={{
                      zoom: 15,
                      longitude: stateLng,
                      latitude: stateLat,
                    }}
                    mapStyle="mapbox://styles/mapbox/streets-v11"
                  >
                    <Marker
                      latitude={stateLat}
                      longitude={stateLng}
                      draggable
                      onDragEnd={(e) =>
                        mapdispatch({
                          type: "UPDATE_LOCATION",
                          payload: { lng: e.lngLat.lng, lat: e.lngLat.lat },
                        })
                      }
                    />
                    <NavigationControl position="bottom-right" />
                  </ReactMapGL>
                </React.Fragment>
              )}
            </Box>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LocationAdddress;
