import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import {
  frogetPass,
  logout,
  reset as authReset,
} from "../../../features/auth/authSlice";
import { eprofileUpdatePass } from "../../../features/MyProfile/profileSlice";
import { useSnackbar } from "notistack";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";

function ChangePassword() {
  const { profile_data, message, isError, isSuccess, isLoading } = useSelector(
    (state) => state.profileData
  );
  const {
    user,
    message: authMessage,
    isLoading: authLoading,
    changeSuccess,
    changeError,
    sentLinkSuccess,
    sentLinkError,
  } = useSelector((state) => state.auth);

  const user2 = JSON.parse(localStorage.getItem("user"));

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [oldPass, setOldPass] = useState("");
  const [newPass, setNewPass] = useState("");
  const [CPass, setCPass] = useState("");

  const [showPass, setShowPass] = useState("password");
  const [showPass2, setShowPass2] = useState("password");
  const [showPass3, setShowPass3] = useState("password");

  const showPassword = () => {
    if (showPass === "password") {
      setShowPass("text");
    }
    if (showPass === "text") {
      setShowPass("password");
    }
  };
  const showPassword2 = () => {
    if (showPass2 === "password") {
      setShowPass2("text");
    }
    if (showPass2 === "text") {
      setShowPass2("password");
    }
  };
  const showPassword3 = () => {
    if (showPass3 === "password") {
      setShowPass3("text");
    }
    if (showPass3 === "text") {
      setShowPass3("password");
    }
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(authReset());

    if (changeSuccess == true) {
      toast.success(authMessage.data);
      dispatch(authReset());
    }
    if (changeError == true) {
      toast.error(authMessage);
      dispatch(authReset());
    }

    if (sentLinkSuccess == true) {
      toast.success(authMessage.data);
      dispatch(authReset());
    }

    if (sentLinkError == true) {
      toast.error(authMessage);
      dispatch(authReset());
    }
  }, [
    isSuccess,
    isError,
    changeError,
    changeSuccess,
    message,
    navigate,
    dispatch,
    sentLinkError,
    sentLinkSuccess,
    authMessage,
  ]);

  const ForgetPass = () => {
    if (profile_data) {
      dispatch(frogetPass({ email: profile_data?.email }));
    }
  };

  const SavePass = () => {
    const passData = {
      oldPass,
      newPass,
      CPass,
      id: profile_data?._id,
    };
    if (newPass === CPass) {
      eprofileUpdatePass(passData)
        .then((res) => {
          if (res.success === false) {
            toast.error("Old password did not match");
          } else {
            toast.success(res.data);
          }
        })
        .catch((err) => {
          toast.error("Error when updating password");
        });
      // dispatch(updatePass(passData));
      setShowPass("password");
      // toast.success("Password updated sucessfully");
    } else {
      toast.error("Password and confirm password did not match!");
    }
  };

  const ClearForm = () => {
    setOldPass("");
    setNewPass("");
    setCPass("");
  };

  return (
    <div>
      <ToastContainer />
      {user2?.data?.user?.emailToken != null &&
      user?.data?.user?.role === "user" ? (
        <>
          {/* error message */}
          <div
            className="card"
            style={{
              backgroundColor: "#F6CFD4",
              color: "#555555",
              padding: "10px",
              border: "none",
              borderRadius: "12px",
            }}
          >
            <h6>
              We've emailed you an cofirmation link. Once you confirm your email
              you can continue setting up your profile.
            </h6>
          </div>
        </>
      ) : (
        <div className="cardSection">
          <div className="d-flex justify-content-between">
            <h1>Change Password</h1>
            <div className="d-flex justify-content-center gap-4 mb-3">
              <Button className="cancelBtn" type="button" onClick={ClearForm}>
                Cancel
              </Button>
              {oldPass !== "" && newPass !== "" ? (
                <Button className="loginBtn" type="submit" onClick={SavePass}>
                  Save
                </Button>
              ) : (
                <Button className="loginBtn" type="submit" disabled={true}>
                  Save
                </Button>
              )}
            </div>
          </div>
          <div className="row mx-0 mt-4">
            <div className="col-6 mx-auto">
              <div className="row gx-5">
                <div className="col-md-12">
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Old Password</Form.Label>
                    <InputGroup className="mb-3">
                      <InputGroup.Text id="basic-addon1">
                        <img src="/imgs/blackLock.svg" alt="" />
                      </InputGroup.Text>
                      <Form.Control
                        type={showPass}
                        required={true}
                        value={oldPass}
                        onChange={(e) => setOldPass(e.target.value)}
                      />
                      {showPass === "text" ? (
                        <InputGroup.Text id="basic-addon1">
                          <VisibilityOffIcon
                            fontSize="small"
                            style={{ cursor: "pointer", color: "lightgray" }}
                            alt=""
                            onClick={showPassword}
                          />
                        </InputGroup.Text>
                      ) : (
                        <InputGroup.Text id="basic-addon1">
                          <VisibilityIcon
                            fontSize="small"
                            style={{ cursor: "pointer", color: "lightgray" }}
                            alt=""
                            onClick={showPassword}
                          />
                        </InputGroup.Text>
                      )}
                    </InputGroup>
                  </Form.Group>
                </div>
                <div className="col-md-12">
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>New Password</Form.Label>
                    <InputGroup className="mb-3">
                      <InputGroup.Text id="basic-addon1">
                        <img src="/imgs/blackLock.svg" alt="" />
                      </InputGroup.Text>
                      <Form.Control
                        type={showPass2}
                        required={true}
                        value={newPass}
                        onChange={(e) => setNewPass(e.target.value)}
                      />
                      {showPass2 === "text" ? (
                        <InputGroup.Text id="basic-addon1">
                          <VisibilityOffIcon
                            fontSize="small"
                            style={{ cursor: "pointer", color: "lightgray" }}
                            alt=""
                            onClick={showPassword2}
                          />
                        </InputGroup.Text>
                      ) : (
                        <InputGroup.Text id="basic-addon1">
                          <VisibilityIcon
                            fontSize="small"
                            style={{ cursor: "pointer", color: "lightgray" }}
                            alt=""
                            onClick={showPassword2}
                          />
                        </InputGroup.Text>
                      )}
                    </InputGroup>
                  </Form.Group>
                </div>
                <div className="col-md-12">
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Confirm Password</Form.Label>
                    <InputGroup className="mb-3">
                      <InputGroup.Text id="basic-addon1">
                        <img src="/imgs/blackLock.svg" alt="" />
                      </InputGroup.Text>
                      <Form.Control
                        type={showPass3}
                        required={true}
                        value={CPass}
                        onChange={(e) => setCPass(e.target.value)}
                      />
                      {showPass3 === "text" ? (
                        <InputGroup.Text id="basic-addon1">
                          <VisibilityOffIcon
                            fontSize="small"
                            style={{ cursor: "pointer", color: "lightgray" }}
                            alt=""
                            onClick={showPassword3}
                          />
                        </InputGroup.Text>
                      ) : (
                        <InputGroup.Text id="basic-addon1">
                          <VisibilityIcon
                            fontSize="small"
                            style={{ cursor: "pointer", color: "lightgray" }}
                            alt=""
                            onClick={showPassword3}
                          />
                        </InputGroup.Text>
                      )}
                    </InputGroup>
                  </Form.Group>
                  <p className="forgotPassword">
                    <Link to="#" onClick={ForgetPass}>
                      Forget Password?
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ChangePassword;
