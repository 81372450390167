import { Link } from "react-router-dom";
import Form from "react-bootstrap/Form";
export const userManagement_data = [
  {
    id: 1,
    name: "Muhammad Haseeb",
    u_name: "@Haseebztour",
    email: "haseebzemail@gmail.com",
    contact_no: "(480) 555-0103",
    n_id: "4279 691079",
    status: <p className="status active">Active</p>,
    l_login: "2020/02/14 19:03",
    action: (
      <div>
        <div className="d-flex gap-3">
          <img src="/imgs/u_eye.svg" width={20} alt="" />
          <img src="/imgs/t_edit.svg" width={20} alt="" />
          <img src="/imgs/revoke.svg" width={20} alt="" />
          <img src="/imgs/delete.svg" width={20} alt="" />
        </div>
      </div>
    ),
  },
  {
    id: 1,
    name: "Muhammad Haseeb",
    u_name: "@Haseebztour",
    email: "haseebzemail@gmail.com",
    contact_no: "(480) 555-0103",
    n_id: "4279 691079",
    status: <p className="status revoked">Revoked</p>,
    l_login: "2020/02/14 19:03",
    action: (
      <div>
        <div className="d-flex gap-3">
          <img src="/imgs/u_eye.svg" width={20} alt="" />
          <img src="/imgs/t_edit.svg" width={20} alt="" />
          <img src="/imgs/revoke.svg" width={20} alt="" />
          <img src="/imgs/delete.svg" width={20} alt="" />
        </div>
      </div>
    ),
  },
];
export const pendingApproval_data = [
  {
    title: "Ancaster – Meadowlands",
    author: "Emmanuel Sossou",
    submit: "9 May 2021 12:24 AM",
    type: "New articles",
    action: (
      <div>
        <div className="d-flex gap-3">
          <img src="/imgs/u_eye.svg" width={20} alt="" />
          <img src="/imgs/t_edit.svg" width={20} alt="" />
          <img src="/imgs/delete.svg" width={20} alt="" />
        </div>
      </div>
    ),
  },
  {
    title: "Ancaster – Meadowlands",
    author: "Emmanuel Sossou",
    submit: "9 May 2021 12:24 AM",
    type: "New articles",
    action: (
      <div>
        <div className="d-flex gap-3">
          <img src="/imgs/u_eye.svg" width={20} alt="" />
          <img src="/imgs/t_edit.svg" width={20} alt="" />
          <img src="/imgs/delete.svg" width={20} alt="" />
        </div>
      </div>
    ),
  },
];
export const pendingContApproval_data = [
  {
    title: "Ancaster – Meadowlands",
    created_at: "Emmanuel Sossou",
    submit_at: "9 May 2021 12:24 AM",
    publish_type: "New articles",
  },
];
export const draft_data = [
  {
    title: "Ancaster – Meadowlands",
    created_at: "Emmanuel Sossou",
    action: (
      <div>
        <div className="d-flex gap-3">
          <img src="/imgs/submit.svg" width={20} alt="" />
          <img src="/imgs/t_edit.svg" width={20} alt="" />
          <img src="/imgs/delete.svg" width={20} alt="" />
        </div>
      </div>
    ),
  },
];
export const approvedArticle_data = [
  {
    title: "Ancaster – Meadowlands",
    created_at: "Emmanuel Sossou",
    submit_at: "9 May 2021 12:24 AM",
    approved_at: "New articles",
    publish_type: "New articles",
    action: (
      <div>
        <div className="d-flex gap-3">
          <img src="/imgs/u_eye.svg" width={20} alt="" />
          <img src="/imgs/t_edit.svg" width={20} alt="" />
          <img src="/imgs/delete.svg" width={20} alt="" />
        </div>
      </div>
    ),
  },
];
export const listing_data = [
  {
    title: "Major Retail Co. - 270 Baychester Ave",
    author: "Elvis Bessan",
    submit: "10 Jun 2021 2:00 AM",
    publish: "10 Jun 2021 2:00 AM",
    publish_type: "Edit articles",
    views_count: 177,
    share_count: 492,
    reviews: (
      <div className="d-flex gap-1 align-items-center">
        <img src="/imgs/star.svg" alt="" />
        <span>4</span>
      </div>
    ),
    action: (
      <div>
        <div className="d-flex gap-3">
          <Link to="/article/article-detail">
            <img src="/imgs/u_eye.svg" width={20} alt="" />
          </Link>
          <Link to="/article/article-detail">
            <img src="/imgs/t_edit.svg" width={20} alt="" />
          </Link>
          <img src="/imgs/delete.svg" width={20} alt="" />
        </div>
      </div>
    ),
  },
  {
    title: "Major Retail Co. - 270 Baychester Ave",
    author: "Elvis Bessan",
    submit: "10 Jun 2021 2:00 AM",
    publish: "10 Jun 2021 2:00 AM",
    publish_type: "Edit articles",
    views_count: 177,
    share_count: 492,
    reviews: (
      <div className="d-flex gap-1 align-items-center">
        <img src="/imgs/star.svg" alt="" />
        <span>3.8</span>
      </div>
    ),
    action: (
      <div>
        <div className="d-flex gap-3">
          <Link to="/article/article-detail">
            <img src="/imgs/u_eye.svg" width={20} alt="" />
          </Link>
          <Link to="/article/article-detail">
            <img src="/imgs/t_edit.svg" width={20} alt="" />
          </Link>
          <img src="/imgs/delete.svg" width={20} alt="" />
        </div>
      </div>
    ),
  },
];
export const articleReview_data = [
  {
    title: "How a visual artist redefines success in graphic design",
    date: "9 May 2021 12:24 AM",
    reviews: (
      <div className="d-flex gap-1 align-items-center">
        <img src="/imgs/star.svg" alt="" />
        <span>4</span>
      </div>
    ),
    author: "Jacob Jones",
    replies_count: 23,
    action: (
      <div>
        <div className="d-flex gap-3">
          <img src="/imgs/u_eye.svg" width={20} alt="" />
        </div>
      </div>
    ),
  },
];
export const tours_data = [
  {
    name: "Boston, MA 02201",
    status: <p className="status active">Published</p>,
    reviews: (
      <div className="d-flex gap-1 align-items-center">
        <img src="/imgs/star.svg" alt="" />
        <span>4</span>
      </div>
    ),
    visit_count: 125975,
    share_count: 23,
    action: (
      <div>
        <div className="d-flex gap-3">
          <img src="/imgs/u_eye.svg" width={20} alt="" />
          <img src="/imgs/t_edit.svg" width={20} alt="" />
          <img src="/imgs/delete.svg" width={20} alt="" />
        </div>
      </div>
    ),
  },
];
export const productPlan_data = [
  {
    name: "Project Plan Name Here",
    pricing: "$350",
    status: <p className="status active">Active</p>,
    display: "visible",
    action: (
      <div>
        <div className="d-flex gap-3">
          <img src="/imgs/u_eye.svg" width={20} alt="" />
          <img src="/imgs/t_edit.svg" width={20} alt="" />
          <img src="/imgs/delete.svg" width={20} alt="" />
        </div>
      </div>
    ),
  },
  {
    name: "Project Plan Name Here",
    pricing: "$350",
    status: <p className="status revoked">In-Active</p>,
    display: "visible",
    action: (
      <div>
        <div className="d-flex gap-3">
          <img src="/imgs/u_eye.svg" width={20} alt="" />
          <img src="/imgs/t_edit.svg" width={20} alt="" />
          <img src="/imgs/delete.svg" width={20} alt="" />
        </div>
      </div>
    ),
  },
];
export const upgradePlan_data = [
  {
    name: "Project Plan Name Here",
    price: "$105.55",
    status: <p className="status active">Active</p>,
    action: (
      <div>
        <div className="d-flex gap-3">
          <img src="/imgs/u_eye.svg" width={20} alt="" />
          <img src="/imgs/t_edit.svg" width={20} alt="" />
          <img src="/imgs/delete.svg" width={20} alt="" />
        </div>
      </div>
    ),
  },
];
export const paymentGateway_data = [
  {
    name: "Project Plan Name Here",
    gateway: "Paypal",
    status: <p className="status active">Active</p>,
    action: (
      <div>
        <div className="d-flex gap-3">
          <img src="/imgs/u_eye.svg" width={20} alt="" />
          <img src="/imgs/t_edit.svg" width={20} alt="" />
          <img src="/imgs/delete.svg" width={20} alt="" />
        </div>
      </div>
    ),
  },
  {
    name: "Project Plan Name Here",
    gateway: "Paypal",
    status: <p className="status active">Active</p>,
    action: (
      <div>
        <div className="d-flex gap-3">
          <img src="/imgs/u_eye.svg" width={20} alt="" />
          <img src="/imgs/t_edit.svg" width={20} alt="" />
          <img src="/imgs/delete.svg" width={20} alt="" />
        </div>
      </div>
    ),
  },
];
export const otherPages_data = [
  {
    name: "Project Plan Name Here",
    url: "Https://www.virtualtour.com/blogs",
    status: <p className="status active">Published</p>,
    tags: "Lorem, Ipusm, loipusm ",
    action: (
      <div>
        <div className="d-flex gap-3">
          <img src="/imgs/u_eye.svg" width={20} alt="" />
          <img src="/imgs/t_edit.svg" width={20} alt="" />
          <img src="/imgs/delete.svg" width={20} alt="" />
        </div>
      </div>
    ),
  },
  {
    name: "Project Plan Name Here",
    url: "Https://www.virtualtour.com/blogs",
    status: <p className="status revoked">Hide</p>,
    tags: "Lorem, Ipusm, loipusm ",
    action: (
      <div>
        <div className="d-flex gap-3">
          <img src="/imgs/u_eye.svg" width={20} alt="" />
          <img src="/imgs/t_edit.svg" width={20} alt="" />
          <img src="/imgs/delete.svg" width={20} alt="" />
        </div>
      </div>
    ),
  },
  {
    name: "Project Plan Name Here",
    url: "Https://www.virtualtour.com/blogs",
    status: <p className="status draft">Draft</p>,
    tags: "Lorem, Ipusm, loipusm ",
    action: (
      <div>
        <div className="d-flex gap-3">
          <img src="/imgs/u_eye.svg" width={20} alt="" />
          <img src="/imgs/t_edit.svg" width={20} alt="" />
          <img src="/imgs/delete.svg" width={20} alt="" />
        </div>
      </div>
    ),
  },
];
export const quotation_data = [
  {
    score: 1,
    order_date: "9 May 2021 12:24 AM",
    user: (
      <div>
        <div className="d-flex gap-2 align-items-center">
          <img src="/imgs/order_dp.png" width={30} alt="" />
          <span>Ralph Edwards</span>
        </div>
      </div>
    ),
    product_plan: "Upgrade",
    status: <p className="status draft">Preparing</p>,
    action: (
      <div>
        <div className="d-flex gap-3">
          <img src="/imgs/u_eye.svg" width={20} alt="" />
          <img src="/imgs/redirect_gray.svg" width={20} alt="" />
        </div>
      </div>
    ),
  },
];
export const outstanding_data = [
  {
    user: (
      <div>
        <div className="d-flex gap-2 align-items-center">
          <img src="/imgs/order_dp.png" width={30} alt="" />
          <span>Ralph Edwards</span>
        </div>
      </div>
    ),
    amount: "$6899",
    order_date: "9 May 2021 12:24 AM",
    pricing_type: "Valued",
    product_plan: "Upgrade",
    billing_no: "38322",
    invoice_no: "38322",
    action: (
      <div>
        <div className="d-flex gap-3">
          <img src="/imgs/u_eye.svg" width={20} alt="" />
        </div>
      </div>
    ),
  },
];
export const paid_data = [
  {
    user: (
      <div>
        <div className="d-flex gap-2 align-items-center">
          <img src="/imgs/order_dp.png" width={30} alt="" />
          <span>Ralph Edwards</span>
        </div>
      </div>
    ),
    amount: "$6800",
    order_date: "9 May 2021 12:24 AM",
    pricing_type: "Valued",
    product_plan: "Upgrade",
    billing_no: "38322",
    invoice_no: "38322",
    paid_at: "Paid at",
    action: (
      <div>
        {/* <Link to="/orders/paid/order-detail"> */}
          <img src="/imgs/u_eye.svg" width={20} alt="" />
        {/* </Link> */}
      </div>
    ),
  },
];
export const billingPending_data = [
  {
    billing_date: "9 May 2021 12:24 AM",
    user: (
      <div>
        <div className="d-flex gap-2 align-items-center">
          <img src="/imgs/order_dp.png" width={30} alt="" />
          <span>Ralph Edwards</span>
        </div>
      </div>
    ),
    amount: "$6899",
    status: "Paid",
    billing_no: "38322",
    invoice_no: "38322",
    paid_at: "Paid at",
    action: (
      <div>
        <Link to="/billing/detail">
          <img src="/imgs/u_eye.svg" width={20} alt="" />
        </Link>
      </div>
    ),
  },
];
export const commonBillingPending_data = [
  {
    billing_date: "9 May 2021 12:24 AM",
    amount: "$6899",
    status: "Paid",
    billing_no: "38322",
    invoice_no: "38322",
    paid_at: "Paid at",
    via: "Paypal",
    action: (
      <div>
        {/* <Link to="/billing/billing-history/bill-form"> */}
          <img src="/imgs/amount.svg" width={20} alt="" />
        {/* </Link> */}
      </div>
    ),
  },
];
export const billingPaid_data = [
  {
    billing_date: "9 May 2021 12:24 AM",
    paid_at: "PayPal",
    user: (
      <div>
        <div className="d-flex gap-2 align-items-center">
          <img src="/imgs/order_dp.png" width={30} alt="" />
          <span>Ralph Edwards</span>
        </div>
      </div>
    ),
    amount: "$6899",
    status: "Paid",
    billing_no: "38322",
    invoice_no: "38322",
    action: (
      <div>
        <Link to="/billing/detail">
          <img src="/imgs/u_eye.svg" width={20} alt="" />
        </Link>
      </div>
    ),
  },
];
export const notification_data = [
  {
    notif_detail: (
      <div>
        <div className="d-flex gap-2 align-items-center">
          <img src="/imgs/order_dp.png" width={30} alt="" />
          <span>Get set on autoship</span>
        </div>
      </div>
    ),
    time: "13:11:35",
    date: "07/05/2016",
    action: (
      <div className="d-flex gap-3">
        <Link>
          <img src="/imgs/carbon_view.svg" width={20} alt="" />
        </Link>
        <img src="/imgs/mail.svg" width={20} alt="" />
        <img src="/imgs/del.svg" width={20} alt="" />
      </div>
    ),
  },
];
export const questionare_data = [
  {
    name: "What is Questionare",
    questions: 4,
    status: <p className="status active">Active</p>,
    action: (
      <div className="d-flex gap-3">
        <Link>
          <img src="/imgs/carbon_view.svg" width={20} alt="" />
        </Link>
        <img src="/imgs/mail.svg" width={20} alt="" />
        <img src="/imgs/del.svg" width={20} alt="" />
      </div>
    ),
  },
];
export const questionareDetails_data = [
  {
    name: "What is Questionare",
    status: (
      <div className="radioStatus">
        <div className="d-flex gap-4">
          <div className="radioBtns">
            <p className="status active">Active</p>
          </div>
          <Form.Check type="radio" name="status" checked />
        </div>
        <div className="d-flex gap-4 inActive">
          <div className="radioBtns">
            <p className="status revoked mt-0">In-Active</p>
          </div>

          <Form.Check type="radio" name="status" />
        </div>
      </div>
    ),
    action: (
      <div className="d-flex gap-3">
        <Link>
          <img src="/imgs/carbon_view.svg" width={20} alt="" />
        </Link>
        <img src="/imgs/t_edit.svg" width={20} alt="" />
        <img src="/imgs/del.svg" width={20} alt="" />
      </div>
    ),
  },
];
export const publishedContArticle_data = [
  {
    title: "Major Retail Co. - 270 Baychester Ave",
    created_at: "Elvis Bessan",
    submited_at: "10 Jun 2021 2:00 AM",
    publish_type: "10 Jun 2021 2:00 AM",
    publish_at: "Edit articles",
    views_count: 177,
    share_count: 492,
    reviews: (
      <div className="d-flex gap-1 align-items-center">
        <img src="/imgs/star.svg" alt="" />
        <span>4</span>
      </div>
    ),
    action: (
      <div>
        <div className="d-flex gap-3">
          <Link to="/article/article-detail">
            <img src="/imgs/u_eye.svg" width={20} alt="" />
          </Link>
          <Link to="/article/article-detail">
            <img src="/imgs/t_edit.svg" width={20} alt="" />
          </Link>
          <img src="/imgs/delete.svg" width={20} alt="" />
        </div>
      </div>
    ),
  },
];
