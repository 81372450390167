import axios from "axios";

const API_URL = `${process.env.REACT_APP_BACKEND_ROUTE}/quotation/`;

// Get list of getQuotation
const getQuotation = async () => {
  const response = await axios.get(API_URL + "quotation-list");
  if (response.data) {
    return response.data;
  }
};

// const getDetails = async (ID) => {
//   const response = await axios.get(API_URL + `question-details/${ID}`);
//   if (response.data) {
//       return response.data
//   }
// }

// Add Questions
const addQuotation = async (userData) => {
  const response = await axios.post(API_URL + "add-quotation", userData);
  if (response.data) {
    return response.data;
  }
};

const editQuotation = async (userData) => {
  const response = await axios.put(API_URL + "edit-quotation", userData);
  if (response.data) {
    return response.data;
  }
};

const deleteQuotation = async (ID) => {
  const response = await axios.put(API_URL + `delete-quotation/${ID}`);
  if (response.data) {
    return response.data;
  }
};



const getQuotationDetail = async (ID) => {
  const response = await axios.get(API_URL + `quotation-detail/${ID}`);
  if (response.data) {
    return response.data;
  }
};
// const deletedRowsQuest = async (passData) => {
//   const response = await axios.put(API_URL + 'deleted-rows', passData);
//     return response.data;
// };

const quotationService = {
  getQuotation,
  addQuotation,
  editQuotation,
  // getDetails,
  deleteQuotation,
  // deletedRowsQuest,
  getQuotationDetail
};

export default quotationService;
