import React, { useEffect } from "react";
import { useLayoutEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import {
  getFrontend,
  getOtherPagesFront,
} from "../../../../../features/Frontend/frontendSlice";
import {
  getProfile,
  reset,
} from "../../../../../features/MyProfile/profileSlice";
import Footer from "../../../../Customer/Footer/Footer";
import Header from "../../../../Customer/Header/Header";
import DOMPurify from "dompurify";

function OtherRoutes({ setProfile, Profile }) {
  const user = JSON.parse(localStorage.getItem("user"));
  const {
    profile_data,
    isLoading,
    isError: isErrorAuth,
    isSuccess: isSuccessAuth,
    message: messageAuth,
  } = useSelector((state) => state.profileData);

  const { othersFront, isError, isSuccess, message } = useSelector(
    (state) => state.front_Data
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();
  // var path = window.location.pathname;
  const path = useLocation();
  const path2 = path.pathname.split("/")[2];
  useLayoutEffect(() => {
    dispatch(reset());
    if (user?.data.user._id) {
      dispatch(getProfile(user?.data.user._id));
      // dispatch(updateLastLogin(ID));
    }
    dispatch(getOtherPagesFront(path));
  }, []);

  useEffect(() => {
    if (user?.data.user._id) {
      dispatch(getProfile(user?.data.user._id));
      // dispatch(updateLastLogin(ID));
    }
    dispatch(getOtherPagesFront("/" + path2));
  }, [
    path,
    isErrorAuth,
    isSuccessAuth,
    messageAuth,
    isError,
    isSuccess,
    message,
  ]);

  const sanitizedData = (data) => ({
    __html: DOMPurify.sanitize(data),
  });

  return (
    <div>
      <Header
        user={user}
        userProfile={profile_data}
        setProfile={setProfile}
        Profile={Profile}
      />
      {othersFront?.map((val) => (
        <>
          <div className="container-fluid px-50px py-5">
            <div className="row mx-0 h-500px my-5">
              <div className="col-md-7 mx-auto text-center my-5">
                <h3>{val?.name}</h3>
              </div>
            </div>
          </div>
          <div className="p-5">
            {/* <h4>Content: </h4> */}
            <div dangerouslySetInnerHTML={sanitizedData(val?.content)} />
            <br />
            {/* <h4>Description:</h4> */}
            <p>{val?.description}</p>
            <br />
            {/* <h4>Tags:</h4>
                    {val?.tags?.map(data=> (
                       <p key={data.id}>{data?.text}</p>
                    ))} */}
          </div>
        </>
      ))}

      <Footer />
    </div>
  );
}

export default OtherRoutes;
