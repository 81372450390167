import axios from 'axios'

const API_URL = `${process.env.REACT_APP_BACKEND_ROUTE}/nav/`

// Get list of nav user
const navList = async () => {
  const response = await axios.get(API_URL + 'nav-list');
  if (response.data) {
      return response.data
  }
}

// Add nav Data
const addNavData = async (navData) => {
  const response = await axios.post(API_URL + 'nav-add', navData);
  if (response.data) {
      return response.data
  }
}

const updateNavData = async (userData) => {
  const response = await axios.put(API_URL + 'edit-nav', userData);
  if (response.data) {
      return response.data
  }
}

const updateNavOrder = async (navData) => {
  const response = await axios.put(API_URL + 'nav-order', navData);
  if (response.data) {
      return response.data
  }
}

const deleteNavData = async (ID) => {
  const response = await axios.put(API_URL + `delete-nav/${ID}`);
  if (response.data) {
      return response.data
  }
}


const navService = {
    navList,
    addNavData,
    updateNavData,
    deleteNavData,
    updateNavOrder,
  }
  
  export default navService
