import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

function PhotoGallery({ page }) {
  const {
    frontData: { galleryImages },
  } = useSelector((state) => state.front_Data);
  const navigate = useNavigate();

  const overlay = (id) => {
    const cont = document.getElementById(`container0${id}`);
    if (cont) {
      cont.onmousemove = function (e) {
        const x = e.pageX - e.currentTarget.offsetLeft;
        const value = cont.offsetWidth - x;
        document.querySelector(
          `#container0${id} .overlay`
        ).style.width = `${value}px`;
      };
    }
  };

  return (
    <>
      <div className="bannerSection photGallery">
        <div className="container-fluid px-50px">
          <div className="row">
            <div className="col-12 text-center">
              <h1>Photo Gallery</h1>
              <p>Explore Photos, videos about the most beautiful places</p>
            </div>
            {page ? (
              <div className="col-12 mt-5 pt-3">
                <div className="row mx-5 g-3 ">
                  {galleryImages?.length > 0 &&
                    galleryImages?.map((ele, ind) => {
                      console.log(ele);
                      return (
                        <div className="col-md-4 mb-md-0 mb-3" key={ind}>
                          <div
                            className="position-relative imgContainer"
                            id={`container0${ind}`}
                          >
                            <div className="overlay" onMouseOver={overlay(ind)}>
                              <img src="/imgs/slider.svg" alt="" />
                            </div>
                            <img
                              style={{
                                height: "250px",
                              }}
                              src={ele.img}
                              className="w-100 max-h628px"
                              alt="img"
                            />
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            ) : (
              <>
                {galleryImages && galleryImages.length > 0 && (
                  <div className="col-12 mt-5 pt-3">
                    <div className="row">
                      <div className="col-md-4 mb-md-0 mb-3">
                        <div
                          className="position-relative imgContainer"
                          id="container1"
                        >
                          <div className="overlay">
                            <img src="/imgs/slider.svg" alt="" />
                          </div>
                          <img
                            src={galleryImages[0]?.img}
                            className="w-100 max-h628px"
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="col-md-8">
                        <div className="row">
                          <div className="col-sm-6 mb-sm-0 mb-3">
                            <div
                              className="position-relative imgContainer"
                              id="container2"
                            >
                              <div className="overlay">
                                <img src="/imgs/slider.svg" alt="" />
                              </div>
                              <img
                                src={galleryImages[1]?.img}
                                className="w-100 max-h628px"
                                alt=""
                              />
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div
                              className="position-relative imgContainer"
                              id="container3"
                            >
                              <div className="overlay">
                                <img src="/imgs/slider.svg" alt="" />
                              </div>
                              <img
                                src={galleryImages[2]?.img}
                                className="w-100 max-h628px"
                                alt=""
                              />
                            </div>
                          </div>
                        </div>
                        {galleryImages && galleryImages[3]?.img && (
                          <div className="row mt-3">
                            <div className="col-12">
                              <div
                                className="position-relative imgContainer"
                                id="container4"
                              >
                                <div className="overlay">
                                  <img src="/imgs/slider.svg" alt="" />
                                </div>
                                <img
                                  src={galleryImages[3]?.img}
                                  className="w-100 max-h628px"
                                  alt=""
                                />
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
                <div className="text-center mt-5">
                  <button
                    className="min-w300px"
                    onClick={() => navigate("/photos-gallery")}
                  >
                    View All Photos
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default PhotoGallery;
