import { useState, useEffect } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";

const Tour = () => {
  const API_URL = `${process.env.REACT_APP_BACKEND_ROUTE}/post-requirements/tour`;
  const navigate = useNavigate();
  const [titleValidation, setTitleValidation] = useState({});
  const [descriptionValidation, setDescriptionValidation] = useState({});
  const [tagsValidation, setTagsValidation] = useState({});
  const [coverImagesValidation, setCoverImagesValidation] = useState({});
  const [address, setAddrss] = useState({});

  const handleSave = async () => {
    let data = {
      title: titleValidation,
      description: descriptionValidation,
      tags: tagsValidation,
      coverImages: coverImagesValidation,
      address,
    };
    const result = await axios.put(API_URL, data);
    if (result.status === 200 || result.data.status === "Success") {
      toast.success("Post requirements updated successfully");
    }
    setTimeout(() => navigate(-1), 1000);
  };
  useEffect(() => {
    axios
      .get(API_URL)
      .then((data) => {
        setTitleValidation(data.data.data.title);
        setDescriptionValidation(data.data.data.description);
        setTagsValidation(data.data.data.tags);
        setCoverImagesValidation(data.data.data.coverImages);
        setAddrss(data.data.data.address);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <div className="container">
      <ToastContainer />
      <button
        className="loginBtn"
        style={{ marginBottom: "10px" }}
        onClick={() => navigate(-1)}
      >
        Go back
      </button>
      <div className="form-group">
        <h4>Title:</h4>
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            checked={titleValidation.isRequired}
            onChange={(e) =>
              setTitleValidation((prevValidation) => ({
                ...prevValidation,
                isRequired: e.target.checked,
              }))
            }
          />
          <label className="form-check-label">Is Required</label>
        </div>
        <div>
          <label>Minimum Length: (Words)</label>
          <input
            className="form-control"
            type="number"
            value={titleValidation.minLength}
            onChange={(e) =>
              setTitleValidation((prevValidation) => ({
                ...prevValidation,
                minLength: parseInt(e.target.value),
              }))
            }
          />
        </div>
        <div>
          <label>Maximum Length: (Words)</label>
          <input
            className="form-control"
            type="number"
            value={titleValidation.maxLength}
            onChange={(e) =>
              setTitleValidation((prevValidation) => ({
                ...prevValidation,
                maxLength: parseInt(e.target.value),
              }))
            }
          />
        </div>
      </div>

      <div className="form-group mt-3">
        <h4>Description:</h4>
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            checked={descriptionValidation.isRequired}
            onChange={(e) =>
              setDescriptionValidation((prevValidation) => ({
                ...prevValidation,
                isRequired: e.target.checked,
              }))
            }
          />
          <label className="form-check-label">Is Required</label>
        </div>
        <div>
          <label>Minimum Length: (Words)</label>
          <input
            className="form-control"
            type="number"
            value={descriptionValidation.minLength}
            onChange={(e) =>
              setDescriptionValidation((prevValidation) => ({
                ...prevValidation,
                minLength: parseInt(e.target.value),
              }))
            }
          />
        </div>
        <div>
          <label>Maximum Length: (Words)</label>
          <input
            className="form-control"
            type="number"
            value={descriptionValidation.maxLength}
            onChange={(e) =>
              setDescriptionValidation((prevValidation) => ({
                ...prevValidation,
                maxLength: parseInt(e.target.value),
              }))
            }
          />
        </div>
      </div>

      <div className="form-group mt-3">
        <h4>Tags:</h4>
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            checked={tagsValidation.isRequired}
            onChange={(e) =>
              setTagsValidation((prevValidation) => ({
                ...prevValidation,
                isRequired: e.target.checked,
              }))
            }
          />
          <label className="form-check-label">Is Required</label>
        </div>
        <div>
          <label>Maximum Tags:</label>
          <input
            className="form-control"
            type="number"
            value={tagsValidation.maxTags}
            onChange={(e) =>
              setTagsValidation((prevValidation) => ({
                ...prevValidation,
                maxLength: parseInt(e.target.value),
              }))
            }
          />
        </div>
      </div>

      <div className="form-group mt-3">
        <h4>Cover Images:</h4>
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            checked={coverImagesValidation.isRequired}
            onChange={(e) =>
              setCoverImagesValidation((prevValidation) => ({
                ...prevValidation,
                isRequired: e.target.checked,
              }))
            }
          />
          <label className="form-check-label">Is Required</label>
        </div>
        <div>
          <label>Maximum Images:</label>
          <input
            className="form-control"
            type="number"
            value={coverImagesValidation.maxImages}
            onChange={(e) =>
              setCoverImagesValidation((prevValidation) => ({
                ...prevValidation,
                maxLength: parseInt(e.target.value),
              }))
            }
          />
        </div>
      </div>
      <div className="form-group mt-3">
        <h4>Address:</h4>
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            checked={address?.isRequired}
            onChange={(e) =>
              setAddrss((prevValidation) => ({
                ...prevValidation,
                isRequired: e.target.checked,
              }))
            }
          />
          <label className="form-check-label">Is Required</label>
        </div>
      </div>

      <button className="loginBtn mt-3" onClick={handleSave}>
        Update
      </button>
    </div>
  );
};

export default Tour;
