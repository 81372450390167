import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { v4 as uuid } from "uuid";
import { ToastContainer, toast } from "react-toastify";

const PhotoGallery = () => {
  const API_URL = `${process.env.REACT_APP_BACKEND_ROUTE}/sorting/`;
  const [images, setImages] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetchImages();
  }, []);
  const fetchImages = () => {
    axios.get(API_URL).then(({ data }) => {
      setImages(data.data.galleryImage);
    });
  };
  const deleteImg = (ID) => {
    const newData = [...images];

    const data = newData.filter((s) => s.id !== ID);
    setImages(data);
  };
  const uploadFileHandler = async (e) => {
    const file = e.target.files[0];
    const bodyFormData = new FormData();
    bodyFormData.append("image", file);

    const ImgRes = await axios.put(
      `${process.env.REACT_APP_BACKEND_ROUTE}/sorting/uploads/s3`,
      bodyFormData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    const ID = uuid().slice(0, 8);
    const newData = {
      id: ID,
      img: ImgRes.data.data,
    };
    const data = [...images, newData];
    setImages(data);
  };

  const saveGallery = () => {
    const data = {
      images,
    };
    axios
      .put(API_URL, data)
      .then((res) => {
        console.log(res);
        //   navigate(-1);
        toast.success("Images saved successfully!");
        fetchImages();
      })
      .catch(() => {
        toast.error("Images not saved!");
      });
  };
  return (
    <div>
      <ToastContainer />
      <div className="d-flex justify-content-end gap-3">
        <button className="loginBtn" onClick={saveGallery}>
          Save
        </button>
        <button onClick={() => navigate(-1)} className="cancelBtn">
          Cancel
        </button>
      </div>
      <div>
        <div className="row g-3 my-3">
          {images !== [] &&
            images?.map((val) => (
              <>
                <div className="col-md-4">
                  <div className="position-relative w-100">
                    <img
                      src="/imgs/delImg.svg"
                      className="position-absolute top-0 start-100 translate-middle"
                      alt=""
                      onClick={() => deleteImg(val.id)}
                    />

                    <div id="imagePreview" className="w-100">
                      <img
                        id="saveImageFile"
                        src={val.img}
                        className="w-100"
                        alt={val.img}
                      />
                    </div>
                  </div>
                </div>
              </>
            ))}
        </div>
        <div className="row">
          <div className="col-md-12 d-flex justify-content-center align-items-center">
            <div
              className="avatar-edit my-3"
              style={{ width: "477px", height: "354px" }}
            >
              <img src="/imgs/addImg.png" alt="" />
              <input
                type="file"
                // onChange={fileHandler}
                id="imageUpload"
                onChange={uploadFileHandler}
              />
              <label htmlFor="imageUpload" name=""></label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PhotoGallery;
