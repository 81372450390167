import React from "react";
import PhotoGallery from "./PhotoGallery";
import Header from "../../Header/Header";
import Footer from "../../Footer/Footer";
import { useSelector } from "react-redux";

const PhotoGalleryPage = ({ setProfile, Profile }) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const {
    profile_data,
    isLoading,
    isError: isErrorAuth,
    isSuccess: isSuccessAuth,
    message: messageAuth,
  } = useSelector((state) => state.profileData);
  return (
    <>
      <Header
        user={user}
        userProfile={profile_data}
        setProfile={setProfile}
        Profile={Profile}
        // nav={nav}
        // contact={contact}
        // about={about}
      />
      <PhotoGallery page={true} />
      <Footer />
    </>
  );
};

export default PhotoGalleryPage;
