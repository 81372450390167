import React, { useEffect, useLayoutEffect, useState, useRef } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import { WithContext as ReactTags } from "react-tag-input";
import { v4 as uuid } from "uuid";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import axios from "axios";
import { useSelector } from "react-redux";
import {
  addTour,
  editTour,
  getTour,
  reset,
} from "../../../features/360Tours/tourSlice";
import {
  sendPrompt,
  reset as reset1,
} from "../../../features/chatgpt/ChatgptSlice";
import L from "leaflet";
import { OpenStreetMapProvider } from "react-leaflet-geosearch";
import "leaflet/dist/leaflet.css";
import { toast, ToastContainer } from "react-toastify";
import { Box } from "@mui/material";
import ReactMapGL, {
  GeolocateControl,
  Marker,
  NavigationControl,
} from "react-map-gl";
import Geocode from "react-geocode";
import { useValue } from "../../../context/ContextProvider";
import "mapbox-gl/dist/mapbox-gl.css";
import Geocoder from "./Geocoder";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Filter from "bad-words";
import ReactQuill from "../../Editor/Editor";
import { getQuotation } from "../../../features/Quotations/quotationSlice";

const KeyCodes = {
  comma: 188,
  enter: 13,
};

const icon = L.icon({
  iconUrl: require("../../../assets/map/placeholder.png"),
  iconRetinaUrl: require("../../../assets/map/placeholder.png"),
  iconSize: [38, 38],
});

const delimiters = [KeyCodes.comma, KeyCodes.enter];

function AddTour() {
  var key = "lq1rACh8fxmYQr1ZbHAB";
  const prov = OpenStreetMapProvider();
  const API_URL = `${process.env.REACT_APP_BACKEND_ROUTE}/post-requirements/tour`;
  let filter = new Filter();
  const { state } = useLocation();
  let ylati = Number(state?.Details?.full_address?.y);
  let xlongi = Number(state?.Details?.full_address?.x);
  const [maplat, setMapLat] = useState(ylati ? ylati : 1.3521);
  const [maplong, setMapLong] = useState(xlongi ? xlongi : 103.8198);
  const [Result, setResult] = useState();
  const [value, setValue] = useState("");

  const user = JSON.parse(localStorage.getItem("user"));
  const articleID = useParams();
  const [currentUserData, setCurrentUserData] = useState({});
  const [show, setShow] = useState(false);
  const [Edit, setEdit] = useState(false);
  const [coverImages, setCoverImages] = useState([]);
  const [country, setCountry] = useState("");
  const [state2, setState] = useState("");
  const [city, setCity] = useState("");
  const [Address, setAddress] = useState("");
  const [postal_code, setPostal_Code] = useState("");
  const [status, setStatus] = useState("PUBLISHED");
  const [prompt, setPrompt] = useState("");
  const [tags, setTags] = useState([]);
  const [latLng, setLatLng] = useState([]);
  const [convertedContent, setConvertedContent] = useState(null);
  const [validationRules, setValidationRules] = useState({});

  const {
    state: {
      location: { lng, lat },
    },
    mapdispatch,
  } = useValue();

  const mapRef = useRef();
  const figureTagsRegex = /<\/?figure>/g;

  const handleGeolocate = async (event) => {
    const { longitude, latitude } = event.coords;
    const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${longitude},${latitude}.json?access_token=${process.env.REACT_APP_MAP_TOKEN}`;
    try {
      const response = await fetch(url);
      const data = await response.json();

      const result = data.features[0];
      const address = result.place_name;
      console.log("result:", result);
      const country = result.context.find((c) => c.id.includes("country")).text;
      console.log("country", country);
      const state = result.context.find((c) => c.id.includes("region")).text;
      const city = result.context.find((c) => c.id.includes("place")).text;
      mapdispatch({
        type: "UPDATE_LOCATION",
        payload: { lng: longitude, lat: latitude },
      });
      setAddress(address);
      setCountry(country);
      setState(state);
      setCity(city);
    } catch (error) {
      console.error("Error:", error);
    }
  };
  useEffect(() => {
    if (mapRef.current) {
      mapRef.current.flyTo({
        zoom: 20,
        center: [
          state?.Details?.full_address?.y || lat,
          state?.Details?.full_address?.x || lng,
        ],
        // zoom: 0, // Example zoom level
      });
      setShow(true);
      setLatLng([
        state?.Details?.full_address?.y || lat,
        state?.Details?.full_address?.x || lng,
      ]);
    }
  }, [state]);
  useEffect(() => {
    state?.paidQuotationUser
      ? setCurrentUserData(state.paidQuotationUser)
      : setCurrentUserData({});
    Geocode.setApiKey(process.env.REACT_APP_MAP_TOKEN);
    if (!lng && !lat) {
      fetch("https://ipapi.co/json")
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          mapRef.current.flyTo({
            center: [data.longitude, data.latitude],
          });
          mapdispatch({
            type: "UPDATE_LOCATION",
            payload: { lng: data.longitude, lat: data.latitude },
          });
        });
    }
    if (state?.Details?.full_address?.coordinates.length > 0) {
      setLatLng(state.Details.full_address.coordinates);
    }
  }, []);

  const ID = user?.data?.user?._id;
  const {
    tour_List,
    isError,
    isSuccess,
    message,
    updated,
    isAdded,
    isList,
    credit,
  } = useSelector((state) => state.tours);
  const {
    content,
    isError: isError1,
    isSuccess: isSuccess1,
    message: message1,
    isLoading,
  } = useSelector((state) => state.chatgpt);

  const [addFormData, setAddFormData] = useState({
    Name: "",
    link: "",
    description: "",
    tags: [],
    coverImages: [],
    country: "",
    city: "",
    postal_code: "",
    address: "",
    owner: "",
  });
  const [editFormData, setEditFormData] = useState({
    Name: "",
    link: "",
    description: "",
    tags: [],
    coverImages: [],
    country: "",
    city: "",
    postal_code: "",
    address: "",
    owner: "",
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleDelete = (i) => {
    setTags(tags.filter((tag, index) => index !== i));
  };

  const handleAddition = (tag) => {
    setTags([...tags, tag]);
  };

  const handleDrag = (tag, currPos, newPos) => {
    const newTags = tags.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);
    setTags(newTags);
  };

  const handleTagClick = (index) => {
    console.log("The tag at index " + index + " was clicked");
  };

  useLayoutEffect(() => {
    dispatch(reset());
    dispatch(reset1());
    if (articleID.id && state.view == false) {
      // dispatch(getTour(ID))
      setEdit(false);
    }
  }, [state]);
  useEffect(() => {
    axios
      .get(API_URL)
      .then((data) => {
        setValidationRules(data.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (state?.Details) {
      setEditFormData(state?.Details);
      setCoverImages(state?.Details?.cover_images);
      setCountry(state?.Details?.location?.country);
      if (
        state?.Details?.full_address &&
        Object.keys(state?.Details?.full_address)?.length > 0
      ) {
        setMapLat(Number(state?.Details?.full_address?.y));
        setMapLong(Number(state?.Details?.full_address?.x));
      } else {
        setMapLat(1.3521);
        setMapLong(103.8198);
      }
      setAddress(state?.Details?.address);
      setState(state?.Details?.location?.state);
      setCity(state?.Details?.location?.city);
      setPostal_Code(state?.Details?.location?.postal_code);
      setResult({
        x: state?.Details?.full_address?.coordinates[0],
        y: state?.Details?.full_address?.coordinates[1],
      });
      setStatus(state?.Details?.status);
      setTags(state?.Details?.tags);
      if (state?.Details?.description != null) {
        let description = state?.Details?.description.replace(
          figureTagsRegex,
          ""
        );
        setValue(description);
      }
    }
  }, [articleID.id, state]);

  useEffect(() => {
    if (
      (Object.keys(content).length > 0 && content.content !== "") ||
      content.content !== undefined
    ) {
      if (window.confirm("are you sure you want to replace text!")) {
        console.log({ content: content.content });
        setValue(`<p>${content.content}</p>`);
      } else {
        let description = editFormData?.description.replace(
          figureTagsRegex,
          ""
        );
        setValue(
          `<p>${description ? description : ""} </br> ${content.content}</p>`
        );

        dispatch(reset1());
      }
    }
  }, [content?.content]);

  useEffect(() => {
    if (updated == true && isSuccess == true && message !== "") {
      toast.success(message);
      dispatch(reset());
      setTimeout(() => {
        navigate(-1);
      }, 1500);
    }

    if (isAdded == true && isSuccess == true && message !== "") {
      toast.success(message);
      dispatch(reset());
      setTimeout(() => {
        navigate(-1);
      }, 1500);
    }
    if (isError == true && message !== "") {
      toast.error(message);
      dispatch(reset());
    }
  }, [credit, message, isSuccess, isError, updated, isAdded]);

  const uploadFileHandler = async (e) => {
    const file = e.target.files[0];
    const bodyFormData = new FormData();
    bodyFormData.append("image", file);

    const ImgRes = await axios.put(
      `${process.env.REACT_APP_BACKEND_ROUTE}/tour/uploads/s3`,
      bodyFormData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    const ID = uuid().slice(0, 8);

    const newData = {
      id: ID,
      img: ImgRes.data.data,
      cover: false,
    };
    const data = [...coverImages, newData];
    setCoverImages(data);
  };

  const handleAddFormChange = (event) => {
    event.preventDefault();
    const fieldName = event.target.getAttribute("name");
    const fieldValue = event.target.value;

    const newFormData = { ...addFormData };
    newFormData[fieldName] = fieldValue;

    setAddFormData(newFormData);
  };
  const validateForm = () => {
    let valid = true;
    const newErrors = {};

    Object.keys(validationRules).forEach((fieldName) => {
      const fieldRules = validationRules[fieldName];
      const fieldValue = getFieldFromState(fieldName);

      if (fieldRules.isRequired) {
        if (
          fieldName !== "tags" &&
          fieldName !== "coverImages" &&
          fieldValue.trim() === ""
        ) {
          newErrors[fieldName] = `${fieldName} is required.`;
          toast.warn(`${fieldName} is required.`);
          valid = false;
        }
        if (fieldName == "tags" && fieldValue.length < 1) {
          newErrors[fieldName] = `${fieldName} is required.`;
          toast.warn(`${fieldName} is required.`);
          valid = false;
        }
        if (fieldName == "coverImages" && fieldValue.length < 1) {
          newErrors[fieldName] = `${fieldName} is required.`;
          toast.warn(`${fieldName} is required.`);
          valid = false;
        }
      }
      if (
        fieldRules.isRequired &&
        fieldRules.minLength &&
        fieldRules.minLength !== undefined &&
        // fieldValue.length < fieldRules.minLength
        (fieldName == "title" || fieldName == "description")
      ) {
        if (fieldValue?.split(" ").length < fieldRules.minLength) {
          newErrors[
            fieldName
          ] = `${fieldName} must have a minimum of ${fieldRules.minLength} ${fieldName}.`;
          toast.warn(
            `${fieldName} must have a minimum of ${fieldRules.minLength} words.`
          );

          valid = false;
        }
      }
      if (
        fieldRules.isRequired &&
        fieldRules.minLength &&
        fieldRules.minLength !== undefined &&
        // fieldValue.length < fieldRules.minLength
        fieldName !== "title" &&
        fieldName !== "description" &&
        fieldValue.length < fieldRules.minLength
      ) {
        // if (fieldValue.split("").length < fieldRules.minLength) {
        newErrors[
          fieldName
        ] = `${fieldName} must have a minimum of ${fieldRules.minLength} ${fieldName}.`;
        toast.warn(
          `${fieldName} must have a minimum of ${fieldRules.minLength} ${fieldName}.`
        );

        valid = false;
        // }
      }
      if (
        fieldRules.isRequired &&
        fieldRules.maxLength &&
        (fieldName === "title" || fieldName === "description")
      ) {
        if (fieldValue.split(" ").length > fieldRules.maxLength) {
          newErrors[
            fieldName
          ] = `${fieldName} must have a maximum of ${fieldRules.maxLength} ${fieldName}.`;
          toast.warn(
            `${fieldName} must have a maximum of ${fieldRules.maxLength} words.`
          );
          valid = false;
        }
      }
      if (
        fieldRules.isRequired &&
        fieldRules.maxLength &&
        fieldRules.maxLength !== undefined &&
        // fieldValue.length < fieldRules.minLength
        fieldName !== "title" &&
        fieldName !== "description" &&
        fieldValue.length > fieldRules.maxLength
      ) {
        newErrors[
          fieldName
        ] = `${fieldName} must have a maximum of ${fieldRules.maxLength} ${fieldName}.`;
        toast.warn(
          `${fieldName} must have a maximum of ${fieldRules.maxLength} ${fieldName}.`
        );
        valid = false;
      }
    });
    console.log(newErrors);
    return valid;
  };
  const getFieldFromState = (fieldName) => {
    switch (fieldName) {
      case "title":
        return articleID.id ? editFormData.Name : addFormData.Name;
      case "description":
        return value;
      case "tags":
        return tags;
      case "coverImages":
        return coverImages;
      case "address":
        return Address;
      case "link":
        return articleID.id ? editFormData.link : addFormData.link;
      default:
        return "";
    }
  };

  const AddForm = (event) => {
    event.preventDefault();
    if (validateForm()) {
      const userData = {
        Name: addFormData.Name,
        link: addFormData.link,
        description: value ? filter.clean(value) : addFormData.description,
        tags: tags,
        cover_images: coverImages ? coverImages : [],
        location: {
          country: country,
          state: state2,
          city: city,
          postal_code: postal_code,
        },
        address: Address,
        full_address: Result,
        status,
        // isFeatured: Featured,
        owner: user?.data.user._id,
        job: currentUserData ? currentUserData : {},
        jobModel: currentUserData ? "Tour" : "",
      };
      dispatch(addTour(userData));
      dispatch(getQuotation());
    }
  };

  const handleEditFormChange = (event) => {
    event.preventDefault();

    const fieldName = event.target.getAttribute("name");
    const fieldValue = event.target.value;
    const newFormData = { ...editFormData };
    newFormData[fieldName] = fieldValue;
    setEditFormData(newFormData);
  };

  const UpdateForm = (convertedContent) => {
    if (
      user?.data?.user?.role === "staff" ||
      user?.data?.user?.role === "admin"
    ) {
      if (validateForm()) {
        const userData = {
          id: articleID.id,
          name: editFormData.Name,
          link: editFormData.link,
          description: value ? filter.clean(value) : editFormData.description,
          tags: tags,
          coverImages: coverImages ? coverImages : [],
          location: {
            country: country,
            state: state2,
            city: city,
            postal_code: postal_code,
          },
          address: Address,
          full_address: Result,
          status: status,
          owner: editFormData?.owner?._id,
          // isFeatured: Featured,
          user: user?.data?.user.role,
        };
        dispatch(editTour(userData));
      }
    } else {
      if (validateForm()) {
        const userData = {
          id: articleID.id,
          name: editFormData.Name,
          link: editFormData.link,
          description: value ? value : editFormData.description,
          tags: tags,
          coverImages: coverImages ? coverImages : [],
          location: {
            country: country,
            state: state2,
            city: city,
            postal_code: postal_code,
          },
          address: Address,
          full_address: Result,
          // isFeatured: Featured,
          owner: user?.data.user._id,
        };
        dispatch(editTour(userData));
        navigate(-1);
      }
    }
  };

  const deleteImg = (ID) => {
    const newData = [...coverImages];

    const data = newData.filter((s) => s.id !== ID);
    setCoverImages(data);
  };

  const handleCoverMark = (ID) => {
    const newData = [...coverImages];
    const Index = newData.findIndex((s) => s.id === ID);
    if (newData[Index].cover == false) {
      newData[Index].cover = true;
    } else {
      newData[Index].cover = false;
    }

    setCoverImages(newData);
  };
  const submitPrompt = () => {
    dispatch(sendPrompt(prompt));
  };
  const handleMarkerChange = async (lat, lng) => {
    const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${lng},${lat}.json?access_token=${process.env.REACT_APP_MAP_TOKEN}`;
    try {
      const response = await fetch(url);
      const data = await response.json();
      console.log(data);
      const result = data.features[0];
      const address = result.place_name;
      const country = result.context.find((c) =>
        c.id.includes("country")
      )?.text;
      const state = result.context.find((c) => c.id.includes("region"))?.text;
      const city = result.context.find((c) => c.id.includes("place"))?.text;
      const postCode = data.features.find((c) =>
        c.id.includes("postcode")
      )?.text;
      mapdispatch({
        type: "UPDATE_LOCATION",
        payload: { lng, lat },
      });
      console.log(country);
      setAddress(address);
      setCountry(country);
      setState(state);
      setCity(city);
      setPostal_Code(postCode);
      setLatLng([lng, lat]);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div>
      <ToastContainer />
      <button
        className="loginBtn"
        style={{ marginBottom: "10px" }}
        onClick={() => navigate(-1)}
      >
        Go back
      </button>
      <div className="d-flex justify-content-end gap-4 mb-3">
        {articleID.id ? (
          <Button
            className="loginBtn"
            onClick={() => UpdateForm(convertedContent)}
          >
            Update
          </Button>
        ) : (
          <Button className="loginBtn" onClick={AddForm}>
            Save
          </Button>
        )}
      </div>
      <div className="cardSection">
        <div className="d-flex justify-content-between">
          <h1>General Information</h1>
        </div>
        <Form>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Name</Form.Label>
            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon1">
                {/* <img src="/imgs/name.svg" alt="" /> */}
              </InputGroup.Text>
              <Form.Control
                type="text"
                name="Name"
                placeholder="Enter Title of Tour"
                disabled={Edit}
                value={articleID.id ? editFormData?.Name : addFormData?.Name}
                onChange={
                  articleID.id ? handleEditFormChange : handleAddFormChange
                }
              />
            </InputGroup>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>360 Tour Link</Form.Label>
            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon1">
                <img src="/imgs/link.svg" alt="" />
              </InputGroup.Text>
              <Form.Control
                type="text"
                name="link"
                placeholder="Enter Title of Tour Link"
                disabled={Edit}
                value={articleID.id ? editFormData?.link : addFormData?.link}
                onChange={
                  articleID.id ? handleEditFormChange : handleAddFormChange
                }
              />
            </InputGroup>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Enter prompt</Form.Label>
            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon1"></InputGroup.Text>
              <Form.Control
                type="text"
                name="link"
                placeholder="type prompt"
                disabled={Edit}
                value={prompt}
                onChange={(e) => setPrompt(e.target.value)}
              />
            </InputGroup>
            <Button className="loginBtn" onClick={submitPrompt}>
              prompt
            </Button>
          </Form.Group>
          {isLoading ? (
            <div className="loader-container">
              <div className="loader">
                <img src="/imgs/mouse-wala.gif" />
              </div>
            </div>
          ) : (
            <Form.Group className="mb-3 pt-2">
              <ReactQuill value={value} setValue={setValue} />
            </Form.Group>
          )}
          <Form.Group className="mb-3">
            <Form.Label>Tags</Form.Label>
            <ReactTags
              tags={tags}
              autofocus={false}
              delimiters={delimiters}
              handleDelete={handleDelete}
              handleAddition={handleAddition}
              handleDrag={handleDrag}
              handleTagClick={handleTagClick}
              inputFieldPosition="bottom"
              autocomplete
              placeholder="Enter tag here"
            />
          </Form.Group>
          <div className="coverImg mb-3 mt-4">
            <h5>Cover Image</h5>
            <div className="image-placeholder px-3 my-4">
              {coverImages !== [] &&
                coverImages?.map((val) => (
                  <>
                    <div className="avatar-preview">
                      <img
                        src="/imgs/delImg.svg"
                        className="delImg"
                        alt=""
                        onClick={() => deleteImg(val.id)}
                      />

                      <div id="imagePreview">
                        <img id="saveImageFile" src={val.img} alt={val.img} />
                      </div>
                      <Form.Group
                        className="mb-3"
                        controlId="formBasicCheckbox"
                        value={val.cover}
                        onChange={() => handleCoverMark(val.id)}
                      >
                        <Form.Check
                          type="checkbox"
                          label="Make Cover"
                          checked={val.cover}
                        />
                      </Form.Group>
                    </div>
                  </>
                ))}
              {Edit == false && (
                <div className="avatar-edit">
                  <img src="/imgs/addImg.png" alt="" />
                  <input
                    type="file"
                    // onChange={fileHandler}
                    id="imageUpload"
                    onChange={uploadFileHandler}
                  />
                  <label htmlFor="imageUpload" name=""></label>
                </div>
              )}
            </div>
          </div>
        </Form>
      </div>
      <div className="cardSection mt-4 mb-2">
        <div className="d-flex justify-content-between">
          <h1>Contact & Address Information</h1>
        </div>
        <div className="row mx-0">
          <div className="col-10 mx-auto">
            <Form className="mt-5">
              <div className="row mx-0">
                <div className="col-md-5 px-0">
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Country/Island</Form.Label>
                    <InputGroup className="mb-3">
                      <InputGroup.Text id="basic-addon1">
                        <img src="/imgs/country.svg" alt="" />
                      </InputGroup.Text>
                      <Form.Control
                        type="text"
                        name="country"
                        value={country}
                        onChange={(e) => setCountry(e.target.value)}
                      />
                    </InputGroup>
                  </Form.Group>
                </div>
                <div className="col-md-2"></div>
                <div className="col-md-5 px-0">
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>State</Form.Label>
                    <InputGroup className="mb-3">
                      <InputGroup.Text id="basic-addon1">
                        <img src="/imgs/address.svg" alt="" />
                      </InputGroup.Text>
                      <Form.Control
                        type="text"
                        name="state"
                        value={state2}
                        onChange={(e) => setState(e.target.value)}
                      />
                    </InputGroup>
                  </Form.Group>
                </div>
                <div className="col-md-5 px-0">
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>City</Form.Label>
                    <InputGroup className="mb-3">
                      <InputGroup.Text id="basic-addon1">
                        <img src="/imgs/address.svg" alt="" />
                      </InputGroup.Text>
                      <Form.Control
                        type="text"
                        name="city"
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                      />
                    </InputGroup>
                  </Form.Group>
                </div>
                <div className="col-md-2"></div>
                <div className="col-md-5 px-0">
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Postal Code</Form.Label>
                    <InputGroup className="mb-3">
                      <InputGroup.Text id="basic-addon1">
                        <img src="/imgs/postal_code.svg" alt="" />
                      </InputGroup.Text>
                      <Form.Control
                        type="text"
                        name="postal_code"
                        value={postal_code}
                        onChange={(e) => setPostal_Code(e.target.value)}
                      />
                    </InputGroup>
                  </Form.Group>
                </div>
                <div className="col-md-12 px-0">
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Address</Form.Label>
                    <InputGroup className="mb-3">
                      <InputGroup.Text id="basic-addon1">
                        <img src="/imgs/address.svg" alt="" />
                      </InputGroup.Text>
                      <Form.Control
                        type="text"
                        name="address"
                        value={Address}
                        onChange={(e) => setAddress(e.target.value)}
                      />
                    </InputGroup>
                  </Form.Group>
                </div>
                <div className="col-md-12 px-0">
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Map Location</Form.Label>
                    <Box
                      sx={{
                        height: 400,
                        position: "relative",
                      }}
                    >
                      <ReactMapGL
                        ref={mapRef}
                        mapboxAccessToken="pk.eyJ1IjoiYmx1ZXNpbG9zZyIsImEiOiJjbGZyeDZ1MGswMG1mM2RxcmJyajRzbXRyIn0.SwkzsvT0dgMXSafnYQoZSQ"
                        initialViewState={{
                          zoom: 11,
                          longitude: `${
                            latLng[0]
                              ? latLng[0]
                              : state &&
                                state.Details &&
                                state?.Details?.full_address?.coordinates
                              ? state.Details.full_address.coordinates[0]
                              : state?.Details?.full_address?.y
                              ? state?.Details?.full_address?.x
                              : lng
                          }`,
                          latitude: `${
                            latLng[1]
                              ? latLng[1]
                              : state &&
                                state.Details &&
                                state?.Details?.full_address?.coordinates
                              ? state.Details.full_address.coordinates[1]
                              : lat
                          }`,
                        }}
                        mapStyle="mapbox://styles/mapbox/streets-v11"
                      >
                        <Marker
                          latitude={
                            latLng[1]
                              ? latLng[1]
                              : state &&
                                state.Details &&
                                state?.Details?.full_address?.coordinates
                              ? state.Details.full_address.coordinates[1]
                              : lat
                          }
                          longitude={
                            latLng[0]
                              ? latLng[0]
                              : state &&
                                state.Details &&
                                state?.Details?.full_address?.coordinates
                              ? state.Details.full_address.coordinates[0]
                              : state?.Details?.full_address?.y
                              ? state?.Details?.full_address?.x
                              : lng
                          }
                          draggable
                          onDragEnd={(e) => {
                            handleMarkerChange(e.lngLat.lat, e.lngLat.lng);
                            mapdispatch({
                              type: "UPDATE_LOCATION",
                              payload: { lng: e.lngLat.lng, lat: e.lngLat.lat },
                            });
                          }}
                        />
                        <NavigationControl position="bottom-right" />
                        <GeolocateControl
                          position="top-left"
                          // trackUserLocation
                          onGeolocate={handleGeolocate}
                        />
                        <Geocoder
                          setResult={setResult}
                          setAddress={setAddress}
                          setCountry={setCountry}
                          setState={setState}
                          setCity={setCity}
                          setPostal_Code={setPostal_Code}
                          setLatLng={setLatLng}
                        />
                      </ReactMapGL>
                    </Box>
                  </Form.Group>
                </div>
                <div className="col-md-6 px-0">
                  {user?.data?.user?.role === "contributor" ? (
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Owner</Form.Label>
                      <InputGroup className="mb-3">
                        <InputGroup.Text id="basic-addon1">
                          <img src="/imgs/u_user.svg" alt="" />
                        </InputGroup.Text>

                        <Form.Control
                          type="text"
                          value={user?.data?.user?.user_name}
                        />
                      </InputGroup>
                    </Form.Group>
                  ) : (
                    (user?.data?.user?.role === "staff" ||
                      user?.data?.user?.role === "admin") && (
                      <>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>Status</Form.Label>
                          <InputGroup className="mb-3">
                            <InputGroup.Text id="basic-addon1">
                              <img src="/imgs/u_user.svg" alt="" />
                            </InputGroup.Text>
                            <Form.Select
                              value={status}
                              onChange={(e) => setStatus(e.target.value)}
                            >
                              <option value={""}>Select Status</option>
                              <option value={"PUBLISHED"}>PUBLISHED</option>
                              <option value={"DRAFT"}>DRAFT</option>
                              <option value={"HIDE"}>HIDE</option>
                            </Form.Select>
                          </InputGroup>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>Owner</Form.Label>
                          <InputGroup className="mb-3">
                            <InputGroup.Text id="basic-addon1">
                              <img src="/imgs/u_user.svg" alt="" />
                            </InputGroup.Text>
                            <Form.Control
                              type="text"
                              value={
                                editFormData
                                  ? editFormData?.owner?.user_name
                                  : user?.data?.user?.user_name
                              }
                            />
                          </InputGroup>
                        </Form.Group>
                      </>
                    )
                  )}
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddTour;
