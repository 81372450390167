import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import contribService from './contServices'

// Get user from localStorage

// const contrib = JSON.parse(localStorage.getItem('contributors'))

const initialState = {
  contributors_List: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
  imageLocation: '',
  isList: false,
}

// get contributer list
export const getContributors = createAsyncThunk(
  'contrib/getContributors',
  async (thunkAPI) => {
    try {
      return await contribService.contributorsList()
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const addContributors = createAsyncThunk(
  'contrib/addContributors',
  async (userData,thunkAPI) => {
    try {
      return await contribService.addContributorsData(userData)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const editContributors = createAsyncThunk(
  'contrib/editContributors',
  async (userData,thunkAPI) => {
    try {
      return await contribService.editContributorsData(userData)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const deleteContributors = createAsyncThunk(
  'contrib/deleteContributors',
  async (ID,thunkAPI) => {
    try {
      return await contribService.deleteContributorsData(ID)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const saveImageC = createAsyncThunk(
  'contrib/saveImageC',
  async (bodyFormData,thunkAPI) => {
    try {
      return await contribService.saveImageC(bodyFormData)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

// update password post
export const updatePassC = createAsyncThunk('contrib/updatePass', async (passData, thunkAPI) => {
  try {
    return await contribService.updatePassC(passData)
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

// deleted rows
export const deletedRowsC = createAsyncThunk('contrib/deletedRowsC', async (passData, thunkAPI) => {
  try {
    return await contribService.deletedRowsC(passData)
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

export const contributorSlice = createSlice({
  name: 'contrib',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false
      state.isSuccess = false
      state.isError = false
      state.message = ''
      // state.imageLocation = ''
      state.isList = false
    },
    resetImage: (state) => {
      state.imageLocation = ''
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getContributors.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getContributors.fulfilled, (state, action) => {
        state.isLoading = false
        // state.isSuccess = true
        state.isList = true
        // state.message = action.payload.data.message
        state.contributors_List = action.payload.data 
      })
      .addCase(getContributors.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.isList = false
        state.message = action.payload
        state.contributors_List = null
      })

      .addCase(addContributors.pending, (state) => {
        state.isLoading = true
      })
      .addCase(addContributors.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.message = action.payload
        // state.contributors_List = [...state.contributors_List, action.payload.data] 
      })
      .addCase(addContributors.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
        state.contributors_List = null
      })

      .addCase(editContributors.pending, (state) => {
        state.isLoading = true
      })
      .addCase(editContributors.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
       state.message = action.payload
        // state.contributors_List = action.payload.data 
      })
      .addCase(editContributors.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
        // state.contributors_List = []
      })

      .addCase(deleteContributors.pending, (state) => {
        state.isLoading = true
      })
      .addCase(deleteContributors.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.message = action.payload
        // state.contributors_List = action.payload.data 
      })
      .addCase(deleteContributors.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
        state.contributors_List = null
      })

      .addCase(saveImageC.pending, (state) => {
        state.isLoading = true
      })
      .addCase(saveImageC.fulfilled, (state, action) => {
        state.isLoading = false
        // state.isSuccess = true
        // state.message = action.payload
        state.imageLocation = action.payload.data
        // state.profile_data = action.payload.data 
      })
      .addCase(saveImageC.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
        state.contributors_List = null
      })

      .addCase(updatePassC.pending, (state) => {
        state.isLoading = true
      })
      .addCase(updatePassC.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.isError = false
        // state.user = action.payload
        state.message = action.payload.data
      })
      .addCase(updatePassC.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.isSuccess = false
        state.message = action.payload
        // state.user = null
      })

      .addCase(deletedRowsC.pending, (state) => {
        state.isLoading = true
      })
      .addCase(deletedRowsC.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.isError = false
        // state.user = action.payload
        state.message = action.payload.data
      })
      .addCase(deletedRowsC.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.isSuccess = false
        state.message = action.payload
        // state.user = null
      })
  },
})

export const { reset, resetImage } = contributorSlice.actions
export default contributorSlice.reducer