import React from "react";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";

function AddBillingDetail() {
  return (
    <div>
      <div className="cardSection">
        <div className="d-flex justify-content-between">
          <h1>Add new Billing Detail</h1>
          <img src="/imgs/edit.svg" className="editIcon" alt="" />
          {/* <div className="d-flex justify-content-center gap-4 mb-3">
              <Button className="cancelBtn" type="button">
                Cancel
              </Button>
              <Button className="loginBtn" type="submit">
                login
              </Button>
            </div> */}
        </div>
        <div className="row mx-0 mt-4">
          <div className="col-10 mx-auto">
            <div className="row gx-5">
              <div className="col-md-6">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Billing Date</Form.Label>
                  <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1">
                      <img src="/imgs/dob.svg" alt="" />
                    </InputGroup.Text>
                    <Form.Control type="date" />
                  </InputGroup>
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Paid At</Form.Label>
                  <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1">
                      <img src="/imgs/dob.svg" alt="" />
                    </InputGroup.Text>
                    <Form.Control type="date" />
                  </InputGroup>
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Via</Form.Label>
                  <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1">
                      <img src="/imgs/gateway.svg" alt="" />
                    </InputGroup.Text>
                    <Form.Select aria-label="Default select example">
                      <option value="1">Payment Gateway</option>
                    </Form.Select>
                  </InputGroup>
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Billing No</Form.Label>
                  <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1">
                      <img src="/imgs/billing.svg" alt="" />
                    </InputGroup.Text>
                    <Form.Control type="text" />
                  </InputGroup>
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Invoice No</Form.Label>
                  <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1">
                      <img src="/imgs/pricing.svg" alt="" />
                    </InputGroup.Text>
                    <Form.Control type="text" />
                  </InputGroup>
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>User</Form.Label>
                  <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1">
                      <img src="/imgs/u_user.svg" alt="" />
                    </InputGroup.Text>
                    <Form.Select aria-label="Default select example">
                      <option value="1">user</option>
                    </Form.Select>
                  </InputGroup>
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Product Plan</Form.Label>
                  <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1">
                      <img src="/imgs/product-type.svg" alt="" />
                    </InputGroup.Text>
                    <Form.Select aria-label="Default select example">
                      <option value="1">Active</option>
                    </Form.Select>
                  </InputGroup>
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Pricing Type</Form.Label>
                  <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1">
                      <img src="/imgs/billing.svg" alt="" />
                    </InputGroup.Text>
                    <Form.Select aria-label="Default select example">
                      <option value="1">Product Plan</option>
                    </Form.Select>
                  </InputGroup>
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Amount</Form.Label>
                  <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1">
                      <img src="/imgs/amount.svg" alt="" />
                    </InputGroup.Text>
                    <Form.Control type="text" />
                  </InputGroup>
                </Form.Group>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddBillingDetail;
