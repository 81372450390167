import { useState, useEffect } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import { Button, Modal, Form, InputGroup } from "react-bootstrap";
import Switch from "@mui/material/Switch";
import { FormControlLabel } from "@mui/material";
import { useSelector } from "react-redux";
import { formatDate } from "../../../../utils/DateHelper";

const Testimonial = () => {
  const API_URL = `${process.env.REACT_APP_BACKEND_ROUTE}/testimonial`;
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [designation, setDesignation] = useState("");
  const [coverImage, setCoverImage] = useState("");
  const [isFeatured, setIsFeatured] = useState(false);
  const [testimonialList, setTestimonialList] = useState([]);
  const [Id, setId] = useState();
  const [Edit, setEdit] = useState(false);
  const [show, setShow] = useState(false);
  const [disable, setDisable] = useState(false);
  const { frontData } = useSelector((state) => state.front_Data);

  useEffect(() => {
    fetchTestimonialList();
  }, []);

  const fetchTestimonialList = () => {
    axios
      .get(API_URL)
      .then(({ data }) => {
        setTestimonialList(data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const EditData = (val) => {
    console.log(val);
    setEdit(true);
    setShow(true);
    setId(val._id);
    setName(val.name);
    setDescription(val.description);
    setDesignation(val.designation);
    setCoverImage(val.coverImage);
    setIsFeatured(val.isFeatured);
  };
  const handleShow = () => setShow(true);
  const handleChange = (event) => {
    setIsFeatured(event.target.checked);
  };
  const handleClose = () => {
    setName("");
    setDescription("");
    setDesignation("");
    setCoverImage("");
    setIsFeatured(false);
    setShow(false);
    setEdit(false);
    setDisable(false);
  };

  const updateData = async () => {
    const data = {
      Id,
      name,
      description,
      designation,
      coverImage,
      isFeatured,
    };
    const result = await axios.put(API_URL, data);
    setName("");
    if (!result.error) {
      fetchTestimonialList();
      toast.success("Testimonial updated Successfully!");
      setName("");
      setDescription("");
      setDesignation("");
      setCoverImage("");
      setIsFeatured(false);
    } else {
      toast.error("Unable to update");
    }
    setId("");
    setShow(false);
    setEdit(false);
  };
  const saveData = () => {
    const data = {
      name,
      description,
      designation,
      coverImage,
      isFeatured,
    };
    axios
      .post(API_URL, data)
      .then((res) => {
        console.log(res);
        toast.success("Testimonial added Successfully!");
        setShow(false);
        setEdit(false);
        setName("");
        setDescription("");
        setDesignation("");
        setCoverImage("");
        setIsFeatured(false);
        setTestimonialList([res.data.data, ...testimonialList]);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Unable to add");
      });
  };
  const onDelete = (id) => {
    if (window.confirm("Are you sure, you want to delete this?")) {
      axios
        .delete(`${API_URL}/${id}`)
        .then((res) => {
          console.log(res);
          toast.success("Testimonial deleted Successfully!");
          fetchTestimonialList();
        })
        .catch((err) => {
          console.log(err);
          toast.error("Unable to delete");
        });
    }
  };
  const onView = (data) => {
    setName(data.name);
    setDescription(data.description);
    setDesignation(data.designation);
    setCoverImage(data.coverImage);
    setIsFeatured(data.isFeatured);
    setShow(true);
    setDisable(true);
  };
  const uploadFileHandler = async (e) => {
    const file = e.target.files[0];
    const bodyFormData = new FormData();
    bodyFormData.append("image", file);

    const ImgRes = await axios.put(
      `${process.env.REACT_APP_BACKEND_ROUTE}/article/uploads/s3`,
      bodyFormData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    setCoverImage(ImgRes.data.data);
  };
  return (
    <div>
      <ToastContainer />
      <div className="d-flex justify-content-end">
        <button className="loginBtn" onClick={handleShow}>
          Add New
        </button>
      </div>

      <table className="table">
        <thead>
          <tr>
            <th scope="col">Name</th>
            <th scope="col">Created At</th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <tbody>
          {testimonialList.length > 0 &&
            testimonialList?.map((item, index) => (
              <tr key={item._id}>
                <td>{item.name}</td>
                <td
                  onClick={() => EditData(item)}
                  style={{ cursor: "pointer" }}
                >
                  {formatDate(item.createdAt, frontData.timezone)}
                </td>
                <td>
                  <div className="d-flex gap-2">
                    <img
                      src="/imgs/u_eye.svg"
                      width={20}
                      alt=""
                      //   onClick={() => ViewQuestions(value._id)}
                      onClick={() => onView(item)}
                    />

                    <img
                      src="/imgs/t_edit.svg"
                      width={20}
                      alt=""
                      onClick={() => EditData(item)}
                    />
                    <img
                      src="/imgs/delete.svg"
                      width={20}
                      alt=""
                      onClick={() => onDelete(item._id)}
                    />
                  </div>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Body>
          <div className="d-flex justify-content-between mb-3"></div>
          <FormControlLabel
            control={
              <Switch
                checked={isFeatured}
                onChange={handleChange}
                inputProps={{ "aria-label": "controlled" }}
                disabled={disable}
              />
            }
            label="Featured"
            labelPlacement="start"
          />
          <div className="row gx-5">
            <div className="col-md-6">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Name</Form.Label>
                <InputGroup className="mb-3">
                  <InputGroup.Text id="basic-addon1">
                    <img src="/imgs/u_user.svg" alt="" />
                  </InputGroup.Text>
                  <Form.Control
                    type="text"
                    value={name}
                    disabled={disable}
                    onChange={(e) => setName(e.target.value)}
                  />
                </InputGroup>
              </Form.Group>
            </div>
            <div className="col-md-6">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Designation</Form.Label>
                <InputGroup className="mb-3">
                  <InputGroup.Text id="basic-addon1">
                    <img src="/imgs/u_user.svg" alt="" />
                  </InputGroup.Text>
                  <Form.Control
                    type="text"
                    value={designation}
                    disabled={disable}
                    onChange={(e) => setDesignation(e.target.value)}
                  />
                </InputGroup>
              </Form.Group>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  value={description}
                  disabled={disable}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </Form.Group>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Image</Form.Label>
                {coverImage && (
                  <div className="avatar-preview">
                    {!disable && (
                      <img
                        src="/imgs/delImg.svg"
                        className="delImg"
                        alt=""
                        onClick={() => setCoverImage("")}
                      />
                    )}

                    <div id="imagePreview">
                      <img
                        id="saveImageFile"
                        src={coverImage}
                        alt={coverImage}
                      />
                    </div>
                  </div>
                )}
                {!coverImage && (
                  <div className="avatar-edit">
                    <img src="/imgs/addImg.png" alt="" />
                    <input
                      type="file"
                      // onChange={fileHandler}
                      id="imageUpload"
                      disabled={disable}
                      onChange={uploadFileHandler}
                    />
                    <label htmlFor="imageUpload" name=""></label>
                  </div>
                )}
                {/* <Form.Control
                  as="textarea"
                  rows={3}
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                /> */}
              </Form.Group>
            </div>
          </div>

          <div className="d-sm-flex justify-content-center text-center gap-4">
            {Edit == true ? (
              <>
                <Button
                  className="cancelBtn me-sm-0 me-3 mb-sm-0 mb-2"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
                <Button className="loginBtn me-sm-0 me-3" onClick={updateData}>
                  Update
                </Button>
              </>
            ) : (
              <>
                <Button
                  className="cancelBtn me-sm-0 me-3 mb-sm-0 mb-2"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
                {!disable && (
                  <Button
                    className="loginBtn me-sm-0 me-3"
                    //   disabled={(name && credits) === "" ? true : false}
                    onClick={saveData}
                  >
                    Save
                  </Button>
                )}
              </>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Testimonial;
