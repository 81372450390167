import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import notifyService from "./notifyService";

// Get user from localStorage
// const contrib = JSON.parse(localStorage.getItem('Users'))

const initialState = {
  notification: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

export const getNotify = createAsyncThunk(
  "notification/getNotify",
  async (id, thunkAPI) => {
    try {
      return await notifyService.getNotify(id);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updateNotify = createAsyncThunk(
  "notification/updateNotify",
  async (Id, thunkAPI) => {
    try {
      return await notifyService.updateNotify(Id);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const deleteNotify = createAsyncThunk(
  "notification/deleteNotify",
  async (ID, thunkAPI) => {
    try {
      return await notifyService.deleteNotify(ID);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// deleted rows
export const deletedRowsNotify = createAsyncThunk(
  "notification/deletedRowsNotify",
  async (passData, thunkAPI) => {
    try {
      return await notifyService.deletedRowsNotify(passData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const readRowsNotify = createAsyncThunk(
  "notification/readRowsNotify",
  async (passData, thunkAPI) => {
    try {
      return await notifyService.readRowsNotify(passData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const notifySlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getNotify.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getNotify.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        // state.message = action.payload.message
        state.notification = action.payload.data;
      })
      .addCase(getNotify.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload.data.message;
        state.notification = [];
      })

      .addCase(updateNotify.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateNotify.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = action.payload;
        state.notification = action.payload.data;
      })
      .addCase(updateNotify.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.notification = [];
      })

      .addCase(deleteNotify.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteNotify.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = action.payload;
        state.notification = action.payload.data;
      })
      .addCase(deleteNotify.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.notification = [];
      })

      .addCase(deletedRowsNotify.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deletedRowsNotify.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.message = action.payload;
        state.notification = action.payload.data;
      })
      .addCase(deletedRowsNotify.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
        state.notification = [];
      })

      .addCase(readRowsNotify.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(readRowsNotify.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.message = action.payload;
        state.notification = action.payload.data;
      })
      .addCase(readRowsNotify.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
        state.notification = [];
      });
  },
});

export const { reset } = notifySlice.actions;
export default notifySlice.reducer;
