import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import profileService from "./profileService";

// Get user from localStorage

// const contrib = JSON.parse(localStorage.getItem('contributors'))

const initialState = {
  profile_data: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
  imageLocation: "",
};

// get contributer list
export const getProfile = createAsyncThunk(
  "profile/getProfile",
  async (ID, thunkAPI) => {
    try {
      return await profileService.profileData(ID);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// get contributer list
export const updateLastLogin = createAsyncThunk(
  "profile/updateLastLogin",
  async (ID, thunkAPI) => {
    try {
      return await profileService.updateLastLogin(ID);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// export const addContributors = createAsyncThunk(
//   'contrib/addContributors',
//   async (userData,thunkAPI) => {
//     try {
//       return await contribService.addContributorsData(userData)
//     } catch (error) {
//       const message =
//         (error.response &&
//           error.response.data &&
//           error.response.data.message) ||
//         error.message ||
//         error.toString()
//       return thunkAPI.rejectWithValue(message)
//     }
//   }
// )

export const updateProfile = createAsyncThunk(
  "profile/updateProfile",
  async (userData, thunkAPI) => {
    try {
      return await profileService.updateProfileData(userData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const saveImage = createAsyncThunk(
  "profile/saveImage",
  async (bodyFormData, thunkAPI) => {
    try {
      return await profileService.saveImage(bodyFormData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updatePass = createAsyncThunk(
  "profile/change-password",
  async (passData, thunkAPI) => {
    try {
      return await profileService.changePassword(passData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const eprofileUpdatePass = async (passData) => {
  try {
    return await profileService.changePassword(passData);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();

    return {
      success: false,
      message,
    };
  }
};

// export const deleteContributors = createAsyncThunk(
//   'contrib/deleteContributors',
//   async (ID,thunkAPI) => {
//     try {
//       return await contribService.deleteContributorsData(ID)
//     } catch (error) {
//       const message =
//         (error.response &&
//           error.response.data &&
//           error.response.data.message) ||
//         error.message ||
//         error.toString()
//       return thunkAPI.rejectWithValue(message)
//     }
//   }
// )

export const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = "";
    },
    resetImage: (state) => {
      state.imageLocation = "";
    },
    logoutProfile: (state) => {
      state.profile_data = {};
    },
    setProfileData: (state, data) => {
      state.profile_data = data;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProfile.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getProfile.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.profile_data = action.payload.data;
        let profile = JSON.parse(localStorage.getItem("user"));
        profile.data.user = action.payload.data;
        localStorage.setItem("user", JSON.stringify(profile));
      })
      .addCase(getProfile.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.profile_data = null;
      })

      .addCase(updateLastLogin.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateLastLogin.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        // state.message = action.payload.data.message
        state.profile_data = action.payload.data;
      })
      .addCase(updateLastLogin.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.profile_data = null;
      })

      .addCase(updatePass.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updatePass.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        // state.user = action.payload
        state.message = action.payload.data;
      })
      .addCase(updatePass.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
        // state.user = null
      })

      .addCase(updateProfile.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateProfile.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = action.payload;
        state.profile_data = action.payload.data;
      })
      .addCase(updateProfile.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(saveImage.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(saveImage.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        // state.message = action.payload
        state.imageLocation = action.payload.data;
        // state.profile_data = action.payload.data
      })
      .addCase(saveImage.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        // state.message = action.payload
        state.profile_data = null;
      });
  },
});

export const { reset, resetImage, logoutProfile, setProfileData } =
  profileSlice.actions;
export default profileSlice.reducer;
