import axios from "axios";

const API_URL = `${process.env.REACT_APP_BACKEND_ROUTE}/notify/`;

const getNotify = async (id) => {
  const response = await axios.get(API_URL + "notifications-list/" + id);
  if (response.data) {
    return response.data;
  }
};

const updateNotify = async (Id) => {
  const response = await axios.put(API_URL + `notification-edit/${Id}`);
  if (response.data) {
    return response.data;
  }
};

const deleteNotify = async (ID) => {
  const response = await axios.put(API_URL + `delete-notification/${ID}`);
  if (response.data) {
    return response.data;
  }
};

const deletedRowsNotify = async (passData) => {
  const response = await axios.put(API_URL + "deleted-rows", passData);
  return response.data;
};

const readRowsNotify = async (passData) => {
  const response = await axios.put(API_URL + "read-rows", passData);
  return response.data;
};

const notifyService = {
  getNotify,
  updateNotify,
  deleteNotify,
  deletedRowsNotify,
  readRowsNotify,
};

export default notifyService;
