import React, { useState, useEffect, useLayoutEffect } from "react";
import { useSelector } from "react-redux";
import {
  BrowserRouter as Router,
  Navigate,
  redirect,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";

import Dashboard from "../../Admin/Dashboard/Dashboard";
import AdminLogin from "../../Admin/Login/Login";
import ChangePassword from "../../Admin/Profile/ChangePassword";
import ForgetPassword from "../../Admin/Profile/ForgetPassword";
import PageNotFound from "../../Admin/Profile/PageNotFound";
import Profile2 from "../../Admin/Profile/Profile";
import OtherRoutes from "../../Admin/Settings/PageManagement/OtherRoutes/OtherRoutes";
import AddBill from "../../Common/Billing/AddBill";
import BillHistory from "../../Common/Billing/BillingHistory";
import PendingBill from "../../Common/Billing/PendingBill";
import AboutUs from "../../Customer/AboutUs/AboutUs";
import Blog from "../../Customer/Blogs/Blog";
import ContactUs from "../../Customer/ContactUs/ContactUs";
import FeaturedTours from "../../Customer/FeaturedTours/FeaturedTours";
import CustomerForgetPassword from "../../Customer/ForgetPassword/ForgetPassword";
import Home from "../../Customer/Home/Home";
import Tours from "../../Customer/Home/_part/Tours";
import CustomerLogin from "../../Customer/Login/Login";
import PropertyViewing from "../../Customer/PropertyViewing/PropertyViewing";
import Signup from "../../Customer/Signup/Signup";
import UpgradeAccount from "../../Customer/UpgradeAccount/UpgradeAccount";
import VirtualTours from "../../Customer/VirtualTours/VirtualTours";
import Forget from "../../ForgetPass/Forget";
import ArticleReviews from "../../Staff/ArticleReviews/ArticleReviews";
import AddTour from "../../Staff/Tours/AddTour";
import TourList from "../../Staff/Tours/TourList";
import Header from "../Header/Header";
import Notifications from "../Notification/Notification";
import Page from "../Page/Page";
import VerifyEmail from "../Page/VerifyEmail";
import Sidebar from "../Sidebar/Sidebar";
import TourReviews from "../../Staff/TourReviews/TourReviews";
import JumpAuthorize from "../../JumpAuthorize";
import BlogDetails from "../../Customer/Blogs/_part/BlogDetails";
import PhotoGalleryPage from "../../Customer/Home/_part/PhotoGalleryPage";

function MainLayout() {
  const [sidebar, setSidebar] = useState(true);
  const [isUser, setIsUser] = useState();
  const [Profile, setProfile] = useState(
    localStorage.getItem("profileData") === null
      ? "false"
      : localStorage.getItem("profileData")
  );
  const [breadData, setBreadData] = useState();

  const user2 = JSON.parse(localStorage.getItem("user"));
  const { user, isLoading, isError, isSuccess, message, isAuthenticated } =
    useSelector((state) => state.auth);

  //choose the screen size
  const handleResize = () => {
    if (window.innerWidth < 992) {
      setSidebar(false);
    } else {
      setSidebar(true);
    }
  };
  useLayoutEffect(() => {
    if (window.innerWidth < 992) {
      setSidebar(false);
    } else {
      setSidebar(true);
    }
  }, []);

  var path = window.location.pathname;

  useEffect(() => {
    window.addEventListener("resize", handleResize);
  }, [path]);

  console.log(Profile);

  return (
    <Router>
      <div>
        {!user2 ? (
          <Routes>
            <Route path="/" exact element={<Home />} />
            <Route path="/middlepage" exact element={<JumpAuthorize />} />
            {/* <Route path="/auth/signin" exact element={<Login />} /> */}
            <Route
              path="/login"
              exact
              element={<CustomerLogin setProfile={setProfile} />}
            />
            {/* <Route path="/dashboard-login" exact element={<AdminLogin setProfile={setProfile}/>} /> */}
            <Route path="/virtual-tours" exact element={<VirtualTours />} />
            <Route path="/about-us" exact element={<AboutUs />} />
            <Route path="/contact-us" exact element={<ContactUs />} />
            <Route path="/blog" exact element={<Blog />} />
            <Route
              path="/photos-gallery"
              exact
              element={
                <PhotoGalleryPage setProfile={setProfile} Profile={Profile} />
              }
            />
            <Route
              path="/blog/:id"
              exact
              element={
                <BlogDetails setProfile={setProfile} Profile={Profile} />
              }
              // element={<h1>hello world here</h1>}
            />
            <Route path="/featured-tours" exact element={<FeaturedTours />} />
            <Route
              path="/property-viewing/:id"
              exact
              element={<PropertyViewing />}
            />
            <Route
              path="/signup"
              exact
              element={<Signup setProfile={setProfile} />}
            />
            <Route path="*" exact element={<PageNotFound />} />
            <Route path="/forget-password" exact element={<ForgetPassword />} />
            <Route
              path="/Other-Pages/*"
              exact
              element={
                <OtherRoutes setProfile={setProfile} Profile={Profile} />
              }
            />
            <Route path="/verifyEmail/:token" exact element={<VerifyEmail />} />
            <Route
              path="/reset-password/:id/:token"
              exact
              element={<Forget />}
            />
            <Route path="/customer-login" exact element={<CustomerLogin />} />
            <Route
              path="/customer-forgetPassword"
              exact
              element={<CustomerForgetPassword />}
            />
          </Routes>
        ) : user2?.data?.user?.role === "user" ? (
          Profile == "true" ? (
            <>
              <i
                className={`fa-solid fa-bars ${sidebar ? "openSidebar" : ""}`}
                onClick={() => setSidebar(!sidebar)}
              ></i>
              <Sidebar sidebar={sidebar} setProfile={setProfile} />
              <div
                className={`mainOverlay ${sidebar ? "openSidebar" : ""}`}
                onClick={() => setSidebar(false)}
              ></div>
              <div
                className={`sidePage ${
                  sidebar ? "openSidebar" : "closeSidebar"
                }`}
              >
                <Header
                  breadData={breadData}
                  setProfile={setProfile}
                  Profile={Profile}
                />
                <div className="px-sm-5 px-2 py-4">
                  <Routes>
                    <Route
                      path="/middlepage"
                      exact
                      element={<JumpAuthorize />}
                    />
                    <Route path="/dashboard" exact element={<Dashboard />} />
                    <Route
                      path="/notifications"
                      exact
                      element={<Notifications />}
                    />
                    <Route path="/profile" exact element={<Profile2 />} />
                    <Route
                      path="/profile/change-password"
                      exact
                      element={<ChangePassword />}
                    />
                    <Route path="*" exact element={<PageNotFound />} />
                    <Route
                      path="/article-reviews"
                      exact
                      element={<ArticleReviews />}
                    />
                    <Route
                      path="/tour-reviews"
                      exact
                      element={<TourReviews />}
                    />
                    {/* <Route path="/360-tours" exact element={<Tours />} />
          <Route path="/360-tours/tours-form" exact element={<AddTour />} />
          <Route path="/360-tours/tour-list" exact element={<TourList />} /> */}
                    <Route
                      path="/billing/pending-bill"
                      exact
                      element={<PendingBill />}
                    />
                    <Route
                      path="/billing/bill-history"
                      exact
                      element={<BillHistory />}
                    />
                    {/* <Route
                      path="/upgradeAccount"
                      exact
                      element={<UpgradeAccount />}
                    /> */}
                    <Route
                      path="/billing/billing-history/bill-form"
                      exact
                      element={<AddBill />}
                    />
                    <Route path="*" exact element={<PageNotFound />} />
                    <Route
                      path="/verifyEmail/:token"
                      exact
                      element={<VerifyEmail />}
                    />
                    <Route
                      path="/Other-Pages/*"
                      exact
                      element={<OtherRoutes />}
                    />
                  </Routes>
                </div>
              </div>
            </>
          ) : (
            <Routes>
              <Route path="/middlepage" exact element={<JumpAuthorize />} />
              <Route
                path="/"
                exact
                element={<Home setProfile={setProfile} Profile2={Profile} />}
              />
              <Route
                path="/virtual-tours"
                exact
                element={
                  <VirtualTours setProfile={setProfile} Profile={Profile} />
                }
              />
              <Route
                path="/about-us"
                exact
                element={<AboutUs setProfile={setProfile} Profile={Profile} />}
              />
              <Route
                path="/contact-us"
                exact
                element={
                  <ContactUs setProfile={setProfile} Profile={Profile} />
                }
              />
              <Route
                path="/blog"
                exact
                element={<Blog setProfile={setProfile} Profile={Profile} />}
              />
              <Route
                path="/property-viewing/:id"
                exact
                element={
                  <PropertyViewing setProfile={setProfile} Profile={Profile} />
                }
              />
              <Route
                path="/property-viewing"
                exact
                element={<PropertyViewing />}
              />
              <Route
                path="/photos-gallery"
                exact
                element={
                  <PhotoGalleryPage setProfile={setProfile} Profile={Profile} />
                }
              />
              <Route
                path="/Other-Pages/*"
                exact
                element={
                  <OtherRoutes setProfile={setProfile} Profile={Profile} />
                }
              />
              <Route
                path="/featured-tours"
                exact
                element={
                  <FeaturedTours setProfile={setProfile} Profile={Profile} />
                }
              />

              <Route
                path="*"
                exact
                element={
                  <PageNotFound Profile={Profile} setProfile={setProfile} />
                }
              />
              <Route
                path="/verifyEmail/:token"
                exact
                element={<VerifyEmail />}
              />
            </Routes>
          )
        ) : Profile == "true" ? (
          <Page
            sidebar={sidebar}
            setSidebar={setSidebar}
            setProfile={setProfile}
            Profile2={Profile}
          />
        ) : (
          <Routes>
            <Route path="/middlepage" exact element={<JumpAuthorize />} />
            <Route
              path="/"
              exact
              element={<Home setProfile={setProfile} Profile={Profile} />}
            />
            <Route
              path="/virtual-tours"
              exact
              element={
                <VirtualTours setProfile={setProfile} Profile={Profile} />
              }
            />
            <Route
              path="/about-us"
              exact
              element={<AboutUs setProfile={setProfile} Profile={Profile} />}
            />
            <Route
              path="/contact-us"
              exact
              element={<ContactUs setProfile={setProfile} Profile={Profile} />}
            />
            <Route
              path="/blog"
              exact
              element={<Blog setProfile={setProfile} Profile={Profile} />}
            />
            <Route
              path="/blog/:id"
              exact
              element={
                <BlogDetails setProfile={setProfile} Profile={Profile} />
              }
              // element={<h1>hello world here</h1>}
            />
            <Route
              path="/featured-tours"
              exact
              element={
                <FeaturedTours setProfile={setProfile} Profile={Profile} />
              }
            />
            <Route
              path="/property-viewing/:id"
              exact
              element={
                <PropertyViewing setProfile={setProfile} Profile={Profile} />
              }
            />
            <Route
              path="/property-viewing"
              exact
              element={
                <PropertyViewing setProfile={setProfile} Profile={Profile} />
              }
            />
            <Route
              path="/photos-gallery"
              exact
              element={
                <PhotoGalleryPage setProfile={setProfile} Profile={Profile} />
              }
            />
            <Route
              path="/Other-Pages/*"
              exact
              element={
                <OtherRoutes setProfile={setProfile} Profile={Profile} />
              }
            />
            <Route
              path="*"
              exact
              element={
                <PageNotFound Profile={Profile} setProfile={setProfile} />
              }
            />
            <Route path="/verifyEmail/:token" exact element={<VerifyEmail />} />
          </Routes>
        )}
      </div>
    </Router>
  );
}

export default MainLayout;
