import React, { useState } from "react";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import Carousel from "react-bootstrap/Carousel";
import Reviews from "./_part/Reviews";
import LocationAdddress from "./_part/LocationAdddress";
import { useLayoutEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  clearList,
  getDetails,
  reset,
  getNearestTours,
} from "../../../features/360Tours/tourSlice";
import { useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import { addAreaOIfInterest } from "../../../features/auth/authSlice";
import { getProfile } from "../../../features/MyProfile/profileSlice";
import DOMPurify from "dompurify";
import ShareButtons from "../../share";
import ReactGA from "react-ga";

function PropertyViewing({ setProfile, Profile }) {
  ReactGA.initialize("UA-277153930-1");
  const user = JSON.parse(localStorage.getItem("user"));
  const { id } = useParams();
  const [fetch, setFetch] = useState(false);

  const { tour_Detail, isError, isSuccess, message, nearestTours } =
    useSelector((state) => state.tours);
  const {
    profile_data,
    isLoading,
    isError: isErrorAuth,
    isSuccess: isSuccessAuth,
    message: messageAuth,
  } = useSelector((state) => state.profileData);

  const [Details, setDetails] = useState({});

  const ImgLength = Details?.cover_images?.length;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useLayoutEffect(() => {
    if (!id) {
      navigate("/");
    }
    if (id) {
      dispatch(clearList());
      dispatch(reset());
      dispatch(getDetails(id));
      dispatch(getNearestTours({ id }));
    }
  }, [fetch]);
  useEffect(() => {
    ReactGA.initialize("UA-277153930-1");
    ReactGA.pageview(window.location.pathname + window.location.search);
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 500);
  }, []);

  useEffect(() => {
    if (tour_Detail) {
      console.log("setting details");
      setDetails(...[tour_Detail]);
    }
    if (user?.data.user._id) {
      dispatch(getProfile(user?.data.user._id));
      // dispatch(updateLastLogin(ID));
    }
    if (isError == true && message) {
      toast.error(message);
    }
  }, [isError, isSuccess, message, fetch]);

  const [readmore, setReadmore] = useState(false);
  const [showtags, setShowTags] = useState(true);

  const sanitizedData = (data) => ({
    __html: data,
  });

  const GoToTour = async (data) => {
    ReactGA.event({
      category: "Engagement",
      action: "View",
      label: data?.Name,
    });
    if (user) {
      const interest = {
        tourId: data._id,
        userId: user?.data?.user?._id,
      };
      dispatch(addAreaOIfInterest(interest));
    }
    navigate(`/property-viewing/${data?._id}`);
    window.location.reload();
    // let result = await dispatch(getDetails(id));
    // console.log({ result });
    // setDetails(result?.payload?.data);
  };
  const trackShareEvent = () => {
    ReactGA.event({
      category: "Engagement",
      action: "Share",
      label: Details?.Name,
    });
    console.log({
      category: "Engagement",
      action: "Share",
      label: Details?.Name,
    });
  };

  const navigateLink = (link) => {
    window.open(link, "_blank");
  };
  const currentUrl = window.location.href;

  return (
    <div className="propertyViewing">
      <ToastContainer />
      <Header
        user={user}
        userProfile={profile_data}
        setProfile={setProfile}
        Profile={Profile}
      />
      <div className="sliderSection">
        <Carousel>
          {Details?.cover_images?.map((data, index) => {
            return (
              <Carousel.Item key={index}>
                <img
                  className="d-block w-100"
                  src={data.img}
                  alt="Cover Images"
                />
                <Carousel.Caption>
                  <p>{index + 1 + "/" + ImgLength + " Images"}</p>
                </Carousel.Caption>
              </Carousel.Item>
            );
          })}
        </Carousel>
      </div>
      <div className="virtualTours pb-4 pt-3">
        <div className="container-fluid px-50px">
          <h1>{Details?.Name}</h1>
          <button
            className="mb-4 mt-2"
            onClick={() => navigateLink(Details?.link)}
          >
            View 360 Tour
          </button>
          <ShareButtons
            url={currentUrl}
            title={currentUrl}
            trackShareEvent={trackShareEvent}
          />
          <div className={`textToShow ${readmore ? "opened" : ""}`}>
            <p>
              <div
                dangerouslySetInnerHTML={sanitizedData(Details?.description)}
              />
            </p>
          </div>
          <div className={`readMoreBtn ${readmore ? "opened" : ""}`}>
            <div className="blackDiv"></div>
            <button onClick={() => setReadmore(!readmore)}>
              {readmore ? "Read Less" : "Read More"}
            </button>
          </div>
        </div>
      </div>
      <div className="virtualTours py-4" style={{ backgroundColor: "black" }}>
        <h2 style={{ color: "white", textAlign: "center" }}>Nearest Tours</h2>
        <div className="container-fluid px-50px">
          <div className="row justify-content-center">
            {nearestTours.map((data, index) => (
              <div
                className="col-lg-3 col-md-6 mb-md-0 mb-3"
                key={index}
                onClick={() => GoToTour(data)}
              >
                <div className="tourMain">
                  {data?.cover_images?.map((val, i) => {
                    if (val.cover == true) {
                      return <img key={i} src={val.img} alt={val.img} />;
                    }
                  })}
                  <button>{data?.tags[0]?.text}</button>
                  <div className="bottomText">
                    <h3>{data.Name}</h3>
                    <h6>{data.location.country}</h6>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="propertyTags">
        <div className="container-fluid px-50px">
          <div className="tagBox">
            <div className="d-flex justify-content-between align-items-center">
              <h1>Tags</h1>
              <p onClick={() => setShowTags(!showtags)}>
                Show More <img src="/imgs/dropdown.svg" alt="" />
              </p>
            </div>
            {Details?.tags?.length > 0 ? (
              <div className="d-flex flex-wrap gap-2 mt-3">
                {Details?.tags.map((item, index) => {
                  return <span key={index}>{item.text}</span>;
                })}
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>

      <Reviews Details={Details} fetch={fetch} setFetch={setFetch} />
      <LocationAdddress Details={Details} />
      <Footer />
    </div>
  );
}

export default PropertyViewing;
