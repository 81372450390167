import React, { useEffect, useState, useRef } from "react";
import { Form, InputGroup } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { reset, submitAnswer } from "../../../features/Questions/questionSlice";
import Swal from "sweetalert2";
import axios from "axios";
import { v4 as uuid } from "uuid";
import { Box } from "@mui/material";
import Geocoder from "../../Contributor/Order/Geocoder";
import ReactMapGL, {
  GeolocateControl,
  Marker,
  NavigationControl,
} from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { useValue } from "../../../context/ContextProvider";

function QuestionareDetail({
  questions_List,
  CList,
  setShowQuest,
  isSelf,
  JustQuest,
  questId,
  SelfAndQuest,
  Details,
}) {
  console.log(questions_List);
  const user = JSON.parse(localStorage.getItem("user"));
  const userID = user?.data?.user?._id;
  const mapRef = useRef();
  const {
    state: {
      location: { lng, lat },
    },
    mapdispatch,
  } = useValue();
  const {
    // questions_List: Questions_List,
    ansAdded,
    isSuccess,
    isError,
    message,
    isLoading,
    isList,
  } = useSelector((state) => state.questions);

  const [totalScore, setTotalScore] = useState(0);
  const [Quest, setQuest] = useState([]);
  const [questArray, setquestArray] = useState([]);
  const [coverImages, setCoverImages] = useState([]);
  const [country, setCountry] = useState("");
  const [state2, setState] = useState("");
  const [city, setCity] = useState("");
  const [Address, setAddress] = useState("");
  const [result, setResult] = useState("");
  const [postal_code, setPostal_Code] = useState("");
  const [latLng, setLatLng] = useState([]);

  const [Answers, setAnswers] = useState();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    reset();
    let totalQuestions = CList?.questionaire?.questions;
    let totalScore = 0;
    CList?.questionaire?.questions?.map((val) => {
      const temp = parseInt(val.score);
      totalScore += temp;
    });
    setTotalScore(totalScore);
    setQuest(totalQuestions);
    let questionArray = [];
    const obj = {
      user: userID,
      answer: "",
    };
    for (let i = 0; i < totalQuestions?.length; i++) {
      questionArray = [...questionArray, obj];
    }
    setquestArray(questionArray);

    if (isSuccess == true && ansAdded && message.message !== "") {
      toast.success(message.message);
      setTimeout(() => {
        setShowQuest(false);
        reset();
        if (
          user?.data?.user?.role === "staff" ||
          user?.data?.user?.role === "admin"
        ) {
          setShowQuest(false);
        } else {
          navigate("/billing/pending-bill");
        }
      }, 1000);
    }
    if (isError == true && ansAdded && message !== "") {
      toast.error(message);
      reset();
    }
    const ans = questions_List[0]?.answers.map((ans) => {
      if (ans?.user === Details?.owner?._id) {
        return ans.answer;
      }
    });
    setAnswers(ans ? ans?.splice(0, 2) : []);
  }, [
    questions_List,
    totalScore,
    Quest,
    isSuccess,
    isError,
    message,
    ansAdded,
    Details,
  ]);

  const handleChange = (e, i, id, score) => {
    const { value } = e.target;
    console.log(value, i);
    setquestArray((answer) =>
      answer?.map((list, index) =>
        index === i ? { ...list, answer: value, score, questId: id } : list
      )
    );
  };
  console.log(questArray);

  const addAnswer = async (noQuest) => {
    let questdata = {
      questArray:
        questArray?.length > 0
          ? questArray?.slice(0, CList?.questionaire?.questions.length)
          : [],
      plan: isSelf == true ? CList : SelfAndQuest ? CList : CList.plan,
      user: user?.data?.user,
      isSelf: isSelf,
      justQuest: true,
      questId: CList?.questionaire?._id,
      noQuest: noQuest,
    };
    var totalScore = 0;
    questArray?.forEach((item) =>
      item.score ? (totalScore += Number(item?.score)) : (totalScore += 0)
    );
    let result = await dispatch(
      submitAnswer({ ...questdata, totalscore: totalScore })
    );
    if (result?.payload?.status === "Success") {
      Swal.fire(
        "Please check your order status in Orders menu (Your order will be rejected if not passed the minimum score)"
      );
    }
  };
  const deleteImg = (i, index) => {
    const newData = [...questArray[i].answer];
    console.log(newData);
    newData.splice(index, 1);
    setquestArray((answer) =>
      answer?.map((list, index) =>
        index === i ? { ...list, answer: newData } : list
      )
    );
  };
  const uploadFileHandler = async (e, id, i, score) => {
    const file = e.target.files[0];
    const bodyFormData = new FormData();
    bodyFormData.append("image", file);

    const ImgRes = await axios.put(
      `${process.env.REACT_APP_BACKEND_ROUTE}/plan/uploads/s3`,
      bodyFormData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    const ID = uuid().slice(0, 8);

    const newData = {
      id: ID,
      img: ImgRes.data.data,
      cover: false,
    };
    const data1 = [...questArray[i]?.answer, newData];
    let evt = {
      target: {
        value: data1,
      },
    };
    handleChange(evt, i, id, score);
  };

  const handleMarkerChange = async (lat, lng, i, id, score) => {
    const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${lng},${lat}.json?access_token=${process.env.REACT_APP_MAP_TOKEN}`;
    try {
      const response = await fetch(url);
      const data = await response.json();
      console.log(data);
      const result = data.features[0];
      const address = result.place_name;
      const country = result.context.find((c) =>
        c.id.includes("country")
      )?.text;
      const state = result.context.find((c) => c.id.includes("region"))?.text;
      const city = result.context.find((c) => c.id.includes("place"))?.text;
      const postCode = data.features.find((c) =>
        c.id.includes("postcode")
      )?.text;
      console.log(country);
      setAddress(address);
      setCountry(country);
      setState(state);
      setCity(city);
      setPostal_Code(postCode);
      setLatLng([lng, lat]);
      let event = {};
      console.log(lat, lng, 999, "ok");
      event.target = {
        value: {
          country,
          state,
          city,
          postCode,
          address,
          latitude: lat,
          longitude: lng,
        },
      };
      handleChange(event, i, id, score);
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const handleGeoLocate = (value1, index, id, score) => {
    let value = {
      ...value1,
      longitude: value1.latitude,
      latitude: value1.longitude,
    };
    let event = {};

    event.target = {
      value,
    };
    handleChange(event, index, id, score);
  };

  return (
    <div>
      <ToastContainer />
      <div className="cardSection mt-4">
        {Quest?.length > 0 ? (
          Quest?.map((val, i) => (
            <>
              <div className="questions" key={i}>
                <h1>{val?.question}</h1>
                <div className="answerBox">
                  {val?.answerType === "choice" ? (
                    val?.score?.map((item, index) => (
                      <div key={index}>
                        <label className="d-flex">
                          <input
                            type="radio"
                            name={`${val?.question}`}
                            value={item.text}
                            // checked={selectedOption === option.text}
                            // onChange={handleOptionChange}
                            onChange={(e) =>
                              handleChange(e, i, val.id, item.score)
                            }
                          />
                          <span className="mx-2">{item.text}</span>
                        </label>
                      </div>
                    ))
                  ) : val?.answerType === "yesNo" && val.score !== 0 ? (
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Control
                        as="textarea"
                        rows={5}
                        value={Answers[i]}
                        onChange={(e) => handleChange(e, i, val.id, val?.score)}
                        disabled={
                          user?.data?.user?.role === "staff" ||
                          user?.data?.user?.role === "admin"
                            ? true
                            : false
                        }
                      />
                    </Form.Group>
                  ) : val?.answerType === "image" ? (
                    <>
                      <div className="image-placeholder px-3 my-4 d-flex flex-wrap">
                        {questArray[i].answer?.length > 0 &&
                          questArray[i].answer?.map((val, index) => (
                            <>
                              <div className="avatar-preview">
                                <img
                                  src="/imgs/delImg.svg"
                                  className="delImg"
                                  alt=""
                                  onClick={() => deleteImg(i, index)}
                                />
                                <div id="imagePreview">
                                  <img
                                    id="saveImageFile"
                                    src={val.img}
                                    alt={val.img}
                                  />
                                </div>
                              </div>
                            </>
                          ))}
                        <div className="avatar-edit">
                          <img src="/imgs/addImg.png" alt="" />
                          <input
                            type="file"
                            // onChange={fileHandler}
                            id="imageUpload"
                            onChange={(e) =>
                              uploadFileHandler(e, val.id, i, val?.score)
                            }
                          />
                          <label htmlFor="imageUpload" name=""></label>
                        </div>
                      </div>
                    </>
                  ) : val?.answerType === "address" ? (
                    <div className="cardSection mt-4 mb-2">
                      <div className="row mx-0">
                        <div className="col-10 mx-auto">
                          <Form className="mt-5">
                            <div className="row mx-0">
                              <div className="col-md-5 px-0">
                                <Form.Group
                                  className="mb-3"
                                  controlId="formBasicEmail"
                                >
                                  <Form.Label>Country/Island</Form.Label>
                                  <InputGroup className="mb-3">
                                    <InputGroup.Text id="basic-addon1">
                                      <img src="/imgs/country.svg" alt="" />
                                    </InputGroup.Text>
                                    <Form.Control
                                      type="text"
                                      name="country"
                                      value={questArray[i]?.answer?.country}
                                      // onChange={(e) =>
                                      //   setCountry(e.target.value)
                                      // }
                                    />
                                  </InputGroup>
                                </Form.Group>
                              </div>
                              <div className="col-md-2"></div>
                              <div className="col-md-5 px-0">
                                <Form.Group
                                  className="mb-3"
                                  controlId="formBasicEmail"
                                >
                                  <Form.Label>State</Form.Label>
                                  <InputGroup className="mb-3">
                                    <InputGroup.Text id="basic-addon1">
                                      <img src="/imgs/address.svg" alt="" />
                                    </InputGroup.Text>
                                    <Form.Control
                                      type="text"
                                      name="state"
                                      value={questArray[i]?.answer?.state}
                                      // onChange={(e) => setState(e.target.value)}
                                    />
                                  </InputGroup>
                                </Form.Group>
                              </div>
                              <div className="col-md-5 px-0">
                                <Form.Group
                                  className="mb-3"
                                  controlId="formBasicEmail"
                                >
                                  <Form.Label>City</Form.Label>
                                  <InputGroup className="mb-3">
                                    <InputGroup.Text id="basic-addon1">
                                      <img src="/imgs/address.svg" alt="" />
                                    </InputGroup.Text>
                                    <Form.Control
                                      type="text"
                                      name="city"
                                      value={questArray[i]?.answer?.city}
                                      // onChange={(e) => setCity(e.target.value)}
                                    />
                                  </InputGroup>
                                </Form.Group>
                              </div>
                              <div className="col-md-2"></div>
                              <div className="col-md-5 px-0">
                                <Form.Group
                                  className="mb-3"
                                  controlId="formBasicEmail"
                                >
                                  <Form.Label>Postal Code</Form.Label>
                                  <InputGroup className="mb-3">
                                    <InputGroup.Text id="basic-addon1">
                                      <img src="/imgs/postal_code.svg" alt="" />
                                    </InputGroup.Text>
                                    <Form.Control
                                      type="text"
                                      name="postal_code"
                                      value={questArray[i]?.answer?.postal_code}
                                      // onChange={(e) =>
                                      //   setPostal_Code(e.target.value)
                                      // }
                                    />
                                  </InputGroup>
                                </Form.Group>
                              </div>
                              <div className="col-md-12 px-0">
                                <Form.Group
                                  className="mb-3"
                                  controlId="formBasicEmail"
                                >
                                  <Form.Label>Address</Form.Label>
                                  <InputGroup className="mb-3">
                                    <InputGroup.Text id="basic-addon1">
                                      <img src="/imgs/address.svg" alt="" />
                                    </InputGroup.Text>
                                    <Form.Control
                                      type="text"
                                      name="address"
                                      value={questArray[i]?.answer?.address}
                                      // onChange={(e) =>
                                      //   setAddress(e.target.value)
                                      // }
                                    />
                                  </InputGroup>
                                </Form.Group>
                              </div>
                              <div className="col-md-12 px-0">
                                <Form.Group
                                  className="mb-3"
                                  controlId="formBasicEmail"
                                >
                                  <Form.Label>Map Location</Form.Label>
                                  <Box
                                    sx={{
                                      height: 400,
                                      position: "relative",
                                    }}
                                  >
                                    <ReactMapGL
                                      ref={mapRef}
                                      mapboxAccessToken="pk.eyJ1IjoiYmx1ZXNpbG9zZyIsImEiOiJjbGZyeDZ1MGswMG1mM2RxcmJyajRzbXRyIn0.SwkzsvT0dgMXSafnYQoZSQ"
                                      initialViewState={{
                                        zoom: 11,
                                        longitude: `${
                                          questArray[i]?.answer?.longitude
                                            ? questArray[i]?.answer?.longitude
                                            : 103.8198
                                        }`,
                                        latitude: `${
                                          questArray[i]?.answer?.latitude
                                            ? questArray[i]?.answer?.latitude
                                            : 1.3521
                                        }`,
                                      }}
                                      mapStyle="mapbox://styles/mapbox/streets-v11"
                                    >
                                      <Marker
                                        latitude={
                                          questArray[i]?.answer?.latitude
                                            ? questArray[i]?.answer?.latitude
                                            : 1.3521
                                        }
                                        longitude={
                                          questArray[i]?.answer?.longitude
                                            ? questArray[i]?.answer?.longitude
                                            : 103.8198
                                        }
                                        draggable
                                        onDragEnd={(e) => {
                                          handleMarkerChange(
                                            e.lngLat.lat,
                                            e.lngLat.lng,
                                            i,
                                            val.id,
                                            val?.score
                                          );
                                        }}
                                      />
                                      <NavigationControl position="bottom-right" />
                                      <GeolocateControl
                                        position="top-left"
                                        // trackUserLocation
                                        onGeolocate={(e) =>
                                          handleGeoLocate(
                                            e,
                                            i,
                                            val.id,
                                            val?.score
                                          )
                                        }
                                      />
                                      <Geocoder
                                        setResult={setResult}
                                        setAddress={setAddress}
                                        setCountry={setCountry}
                                        setState={setState}
                                        setCity={setCity}
                                        setPostal_Code={setPostal_Code}
                                        setLatLng={setLatLng}
                                        index={i}
                                        id={val.id}
                                        score={val?.score}
                                        handleGeoLocate={handleGeoLocate}
                                      />
                                    </ReactMapGL>
                                  </Box>
                                </Form.Group>
                              </div>
                            </div>
                          </Form>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="my-5"></div>
              </div>
            </>
          ))
        ) : (
          <div style={{ textAlign: "center" }}>No Question</div>
        )}
      </div>
      {Quest?.length > 0 ? (
        user?.data?.user?.role === "staff" ||
        user?.data?.user?.role === "admin" ? (
          <button
            className="btn btn-outline-danger"
            style={{ float: "right", position: "relative", top: "20px" }}
            onClick={() =>
              user?.data?.user?.role === "staff" ||
              user?.data?.user?.role === "admin"
                ? setShowQuest(false)
                : addAnswer()
            }
          >
            Close
          </button>
        ) : (
          <button
            className="btn btn-outline-danger"
            style={{ float: "right", position: "relative", top: "20px" }}
            onClick={() =>
              user?.data?.user?.role === "staff" ||
              user?.data?.user?.role === "admin"
                ? setShowQuest(false)
                : addAnswer()
            }
          >
            Submit
          </button>
        )
      ) : (
        (user?.data?.user?.role === "contributor" ||
          user?.data?.user?.role === "user") && (
          <button
            className="btn btn-outline-success"
            style={{ float: "right", position: "relative", top: "20px" }}
            onClick={() =>
              user?.data?.user?.role === "staff" ||
              user?.data?.user?.role === "admin"
                ? setShowQuest(false)
                : addAnswer(true)
            }
          >
            Proceed to payment
          </button>
        )
      )}
    </div>
  );
}

export default QuestionareDetail;
