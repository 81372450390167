import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import allUsersService from './dashboardService'

const initialState = {
  all_users: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
}


export const getAllUsers = createAsyncThunk(
  'dashboard/getAllUsers',
  async (thunkAPI) => {
    try {
      return await allUsersService.allUsers()
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)


export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false
      state.isSuccess = false
      state.isError = false
      state.message = ''
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllUsers.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getAllUsers.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.message = action.payload
        state.all_users = action.payload.data 
      })
      .addCase(getAllUsers.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
        state.all_users = null
      })
  },
})

export const { reset } = dashboardSlice.actions
export default dashboardSlice.reducer