import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../features/auth/authSlice";
import contribReducers from "../features/contributers/contSlice";
import userReducers from "../features/users/userSlice";
import staffReducers from "../features/staffs/staffSlice";
import profileReducers from "../features/MyProfile/profileSlice";
import navReducers from "../features/navigations/navSlice";
import productReducers from "../features/ProductPlans/productSlice";
import aboutReducers from "../features/AboutUs/AboutusSlice";
import contactReducers from "../features/ContactPage/contactSlice";
import otherReducers from "../features/OtherPages/otherPagesSlice";
import dashboardReducers from "../features/Dashboard/dashboardSlice";
import upgradeReducers from "../features/UpgradePlan/upgradeSlice";
import articleReducers from "../features/Articles/articleSlice";
import questionReducers from "../features/Questions/questionSlice";
import quotationReducers from "../features/Quotations/quotationSlice";
import toursReducers from "../features/360Tours/tourSlice";
import frontReducers from "../features/Frontend/frontendSlice";
import notifyReducer from "../features/Notifications/notifySlice";
import chatgptReducer from "../features/chatgpt/ChatgptSlice";
import badwordReducer from "../features/badWord/BadWordSlice";
import postRequirementReducer from "../features/postRequirements/PostRequirementsSlice";
import creditSystemReducer from "../features/creditSystem/CreditSlice";
import sortingReducer from "../features/sortingHIghlight/sortingSlice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    contributors: contribReducers,
    users: userReducers,
    staff: staffReducers,
    profileData: profileReducers,
    navData: navReducers,
    productPlan: productReducers,
    about: aboutReducers,
    contactData: contactReducers,
    otherPages: otherReducers,
    Dashboard: dashboardReducers,
    UpgradePlan: upgradeReducers,
    article: articleReducers,
    questions: questionReducers,
    quotations: quotationReducers,
    tours: toursReducers,
    front_Data: frontReducers,
    notifyData: notifyReducer,
    chatgpt: chatgptReducer,
    badword: badwordReducer,
    postRequirements: postRequirementReducer,
    credits: creditSystemReducer,
    sorting: sortingReducer,
  },
});
