import React, { useEffect, useLayoutEffect, useState } from "react";
import { Form, InputGroup, Button, Modal } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { questionareDetails_column } from "../../../../utils/constant/column_header_data";
import { useDispatch, useSelector } from "react-redux";
import {
  getDetails,
  reset,
} from "../../../../features/Questions/questionSlice";
import DataTable from "react-data-table-component";

function QuestionareDetails() {
  const { id } = useParams();

  const { questions_List, isSuccess, isError, message, isLoading, isList } =
    useSelector((state) => state.questions);

  const [selectedRows, setSelectedRows] = useState("");
  const [Search, setSearch] = useState("");
  const [CList, setCList] = useState([]);

  const [delID, setDelID] = useState("");
  const [show2, setShow2] = useState(false);

  const handleClose2 = () => {
    setShow2(false);
    setDelID("");
  };

  const handleShow2 = () => setShow2(true);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useLayoutEffect(() => {
    dispatch(reset());
    dispatch(getDetails(id));
  }, []);

  useEffect(() => {
    if (isList == true) {
      setCList(questions_List);
    }
  }, [CList, isList, isSuccess, isError, CList?.questions]);

  const DetailsViewPage = (mainID, subID) => {
    navigate(`/add-questionnaire/${mainID}`, {
      state: { edit: false, view: true, subID: subID },
    });
  };

  const DetailsEditPage = (mainID, subID) => {
    navigate(`/add-questionnaire/${mainID}`, {
      state: { edit: true, view: false, subID: subID },
    });
  };

  var rows = [];
  if (CList) {
    for (let [i, value] of CList?.entries()) {
      for (let [j, val] of value?.questions.entries()) {
        rows[j] = {
          id: val.id,
          name: val.question,
          score: val?.answerType === "choice" ? "MCQS" : val.score,
          answerType: val?.answerType,
          action: (
            <div className="d-flex gap-3">
              <img
                src="/imgs/carbon_view.svg"
                width={20}
                alt=""
                onClick={() => DetailsViewPage(value._id, val.id)}
              />
              <img
                src="/imgs/t_edit.svg"
                width={20}
                alt=""
                onClick={() => DetailsEditPage(value._id, val.id)}
              />
              {/* <img src="/imgs/del.svg" width={20} alt="" /> */}
            </div>
          ),
        };
      }
    }
  }

  const deleteRow2 = () => {
    handleShow2();
  };

  const deleteRow = () => {
    let yFilter = selectedRows.map((itemY) => {
      return itemY.id;
    });
    const newData = [...CList];
    const data = newData.forEach(function (o) {
      o.questions = o.questions.filter((itemX) => !yFilter.includes(itemX.id));
    });

    // let [filteredX] = questions_List.map(val => val.questions = val.questions.filter(itemX=> !yFilter.includes(itemX.id)));
    setCList(data);
    // dispatch(deletedRowsSubQuest(yFilter))
    // dispatch(reset())
    setShow2(false);
  };

  const handleChange = ({ selectedRows }) => {
    setSelectedRows(selectedRows);
  };

  return (
    <div className="staffMainDiv">
      <button
        className="loginBtn"
        style={{ marginBottom: "10px" }}
        onClick={() => navigate(-1)}
      >
        Go back
      </button>
      <div className="row mx-0 mb-4">
        <div className="d-flex justify-content-between">
          <div className="d-flex gap-3">
            {/* {selectedRows.length > 0 ? (
              <img
                src="/imgs/delete.svg"
                width={20}
                alt=""
                onClick={deleteRow2}
              />
            ) : (
              <img src="/imgs/delete.svg" width={20} alt="" />
            )} */}
            <img
              src="/imgs/revoke.svg"
              width={20}
              alt=""
              onClick={() => setSearch("")}
            />
            <Form.Group className="mb-0">
              <InputGroup className="mb-0">
                <InputGroup.Text id="basic-addon1">
                  <img src="/imgs/search.svg" alt="" />
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  placeholder="Search"
                  value={Search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </InputGroup>
            </Form.Group>
          </div>
          {/* <Link to={`/add-questionare/${id}`}>
            <Button className="loginBtn">
              Update
            </Button>
          </Link> */}
        </div>
      </div>
      <DataTable
        columns={questionareDetails_column}
        data={
          rows
            ? rows.filter((item) =>
                item.name.toLowerCase().includes(Search.toLowerCase())
              )
            : []
        }
        pagination
      />

      <Modal
        show={show2}
        onHide={handleClose2}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Deleted</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose2}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              deleteRow();
            }}
          >
            Sure
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default QuestionareDetails;
