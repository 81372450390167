import React, { useEffect, useLayoutEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { useLocation, useNavigate } from "react-router-dom";
import PermissionTbl from "./_part/PermissionTbl";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import PhoneInput from "react-phone-input-2";
import moment from "moment";

function StaffEditForm({
  handleEditFormChange,
  UpdateForm,
  Details,
  setEditFormData,
  editFormData,
  handleSubmit,
  register,
  watch,
  uploadFileHandler,
  imageLocation,
  handleShow,
  showPassword,
  showPass,
  setValue,
}) {
  const navigate = useNavigate();
  const { state } = useLocation();

  const [Edit, setEdit] = useState(false);

  const [customerArray, setCustomerArray] = useState([]);
  const [contributorArray, setContributorArray] = useState([]);
  const [ArticlesReviews, setArticlesReviews] = useState([]);
  const [VirtualReviews, setVirtualReviews] = useState([]);
  const [ArticlesOrders, setArticlesOrders] = useState([]);
  const [Billing, setBilling] = useState([]);
  useEffect(() => {
    const formData = {
      first_name: Details.first_name,
      last_name: Details.last_name,
      user_name: Details.user_name,
      dob: Details.dob,
      national_id: Details.national_id,
      gender: Details.gender,
      profile: Details.profile,
      status: Details.status,
      password: Details.password,
      email: Details.email,
      contact_no: Details.contact_no,
      country: Details.country,
      state: Details.state,
      city: Details.city,
      postal_code: Details.postal_code,
      address: Details.address,
      role: "staff",
      permissions: Details.permissions,
    };
    setEditFormData(formData);

    if (Object.keys(Details).length !== 0) {
      setCustomerArray(
        ...[
          Details?.permissions[0]?.Customers?.length > 0
            ? Details?.permissions[0].Customers
            : [],
        ]
      );
      setContributorArray(
        ...[
          Details?.permissions[0]?.Contributors?.length > 0
            ? Details?.permissions[0]?.Contributors
            : [],
        ]
      );
      setArticlesReviews(
        ...[
          Details?.permissions[0]?.ArticlesReviews?.length > 0
            ? Details?.permissions[0]?.ArticlesReviews
            : [],
        ]
      );
      setVirtualReviews(
        ...[
          Details?.permissions[0]?.VirtualReviews?.length > 0
            ? Details?.permissions[0]?.VirtualReviews
            : [],
        ]
      );
      setArticlesOrders(
        ...[
          Details?.permissions[0]?.ArticlesOrders?.length > 0
            ? Details?.permissions[0]?.ArticlesOrders
            : [],
        ]
      );
      setBilling(
        ...[
          Details?.permissions[0]?.Billing?.length > 0
            ? Details?.permissions[0]?.Billing
            : [],
        ]
      );
    }

    if (state) {
      setEdit(true);
    }
  }, [Details, state, imageLocation]);

  const handleEditSate = () => {
    setEdit(false);
  };

  var dtToday = new Date();

  var month = dtToday.getMonth() + 1;
  var day = dtToday.getDate() - 1;
  var year = dtToday.getFullYear();

  if (month < 10) month = "0" + month.toString();
  if (day < 10) day = "0" + day.toString();

  var maxDate = year + "-" + month + "-" + day;

  return (
    <div>
      <div className="cardSection">
        <div className="d-flex justify-content-between">
          <h1>General Information</h1>
          {Edit && <img src="/imgs/edit.svg" alt="" onClick={handleEditSate} />}

          {Edit == false && (
            <div>
              <div className="d-flex justify-content-center gap-4 mb-3">
                <Button
                  className="cancelBtn"
                  type="button"
                  onClick={() => navigate(-1)}
                >
                  Cancel
                </Button>
                <Button className="loginBtn" onClick={handleSubmit(UpdateForm)}>
                  Save
                </Button>
              </div>
            </div>
          )}
        </div>
        <div className="text-center">
          <img
            src={imageLocation ? imageLocation : editFormData.profile}
            className="profileImg"
            alt=""
          />
          <br />
          {Edit == false && (
            <input type="file" onChange={uploadFileHandler}></input>
          )}
        </div>
        <div className="row mx-0">
          <div className="col-10 mx-auto">
            <Form className="mt-5">
              <div className="row mx-0">
                <div className="col-md-5 px-0">
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>First Name</Form.Label>
                    <InputGroup className="mb-3">
                      <InputGroup.Text id="basic-addon1">
                        <img src="/imgs/u_user.svg" alt="" />
                      </InputGroup.Text>
                      <Form.Control
                        type="text"
                        value={editFormData.first_name}
                        disabled={Edit}
                        name="first_name"
                        onChange={handleEditFormChange}
                      />
                    </InputGroup>
                  </Form.Group>
                </div>
                <div className="col-md-2"></div>
                <div className="col-md-5 px-0">
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Last Name</Form.Label>
                    <InputGroup className="mb-3">
                      <InputGroup.Text id="basic-addon1">
                        <img src="/imgs/u_user.svg" alt="" />
                      </InputGroup.Text>
                      <Form.Control
                        type="text"
                        value={editFormData.last_name}
                        disabled={Edit}
                        name="last_name"
                        onChange={handleEditFormChange}
                      />
                    </InputGroup>
                  </Form.Group>
                </div>
                <div className="col-md-5 px-0">
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>User Name</Form.Label>
                    <InputGroup className="mb-3">
                      <InputGroup.Text id="basic-addon1">
                        <img src="/imgs/u_user.svg" alt="" />
                      </InputGroup.Text>
                      <Form.Control
                        type="text"
                        value={editFormData.user_name}
                        disabled={Edit}
                        name="user_name"
                        onChange={handleEditFormChange}
                      />
                    </InputGroup>
                  </Form.Group>
                </div>
                <div className="col-md-2"></div>
                <div className="col-md-5 px-0">
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Date of Birth</Form.Label>
                    <InputGroup className="mb-3">
                      <InputGroup.Text id="basic-addon1">
                        <img src="/imgs/dob.svg" alt="" />
                      </InputGroup.Text>
                      <Form.Control
                        type="date"
                        value={moment(editFormData.dob).format("YYYY-MM-DD")}
                        disabled={Edit}
                        max={maxDate}
                        name="dob"
                        onChange={handleEditFormChange}
                      />
                    </InputGroup>
                  </Form.Group>
                </div>
                <div className="col-md-5 px-0">
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    {/* <Form.Label>National Id</Form.Label>
                    <InputGroup className="mb-3">
                      <InputGroup.Text id="basic-addon1">
                        <img src="/imgs/national.svg" alt="" />
                      </InputGroup.Text>
                      <Form.Control
                        type="text"
                        value={editFormData.national_id}
                        disabled={Edit}
                        name="national_id"
                        onChange={handleEditFormChange}
                      />
                    </InputGroup> */}
                    <Form.Label>Gender</Form.Label>
                    <div>
                      <Form.Check
                        inline
                        id="male"
                        type="radio"
                        label="Male"
                        name="gender"
                        value="man"
                        checked={editFormData.gender == "man"}
                        onChange={handleEditFormChange}
                      />
                      <Form.Check
                        inline
                        id="female"
                        type="radio"
                        label="Female"
                        name="gender"
                        value="woman"
                        checked={editFormData.gender == "woman"}
                        onChange={handleEditFormChange}
                      />
                    </div>
                  </Form.Group>
                </div>
                <div className="col-md-2"></div>
                <div className="col-md-5 px-0">
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Status</Form.Label>
                    <InputGroup className="mb-3">
                      <InputGroup.Text id="basic-addon1">
                        <img src="/imgs/u_user.svg" alt="" />
                      </InputGroup.Text>
                      <Form.Select
                        aria-label="Default select example"
                        disabled={Edit}
                        value={editFormData.status}
                        name="status"
                        onChange={handleEditFormChange}
                      >
                        <option value="">Select Status</option>
                        <option value="Active">Active</option>
                        <option value="Revoked">Revoked</option>
                      </Form.Select>
                    </InputGroup>
                  </Form.Group>
                </div>
                <div className="col-md-5 px-0">
                  <Form.Group className="mb-0" controlId="formBasicEmail">
                    <Form.Label>Password</Form.Label>
                    <InputGroup className="mb-2">
                      <InputGroup.Text id="basic-addon1">
                        <img src="/imgs/blackLock.svg" alt="" />
                      </InputGroup.Text>
                      <Form.Control
                        type={showPass}
                        name="password"
                        value="password"
                        disabled={true}
                        // value={editFormData.password}
                        onChange={handleEditFormChange}
                      />
                      {/* {showPass === "text" ? (
                        <InputGroup.Text id="basic-addon1">
                          <VisibilityOffIcon
                            fontSize="small"
                            style={{ cursor: "pointer", color: "lightgray" }}
                            alt=""
                            onClick={showPassword}
                          />
                        </InputGroup.Text>
                      ) : (
                        <InputGroup.Text id="basic-addon1">
                          <VisibilityIcon
                            fontSize="small"
                            style={{ cursor: "pointer", color: "lightgray" }}
                            alt=""
                            onClick={showPassword}
                          />
                        </InputGroup.Text>
                      )} */}
                    </InputGroup>
                  </Form.Group>
                  {Edit == false && (
                    <p className="forgotPassword" onClick={handleShow}>
                      Change Password
                    </p>
                  )}
                  {/* <p className="forgotPassword">Change Password</p> */}
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
      <div className="cardSection mt-4 mb-2">
        <div className="d-flex justify-content-between">
          <h1>Contact & Address Information</h1>
        </div>
        <div className="row mx-0">
          <div className="col-10 mx-auto">
            <Form className="mt-5">
              <div className="row mx-0">
                <div className="col-md-5 px-0">
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Email</Form.Label>
                    <InputGroup className="mb-3">
                      <InputGroup.Text id="basic-addon1">
                        <img src="/imgs/email.svg" alt="" />
                      </InputGroup.Text>
                      <Form.Control
                        type="email"
                        value={editFormData.email}
                        disabled={Edit}
                        name="email"
                        onChange={handleEditFormChange}
                      />
                    </InputGroup>
                  </Form.Group>
                </div>
                <div className="col-md-2"></div>
                <div className="col-md-5 px-0">
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Contact No</Form.Label>
                    <InputGroup className="mb-3">
                      <PhoneInput
                        specialLabel="Contact No"
                        country={"sg"}
                        name="contact_no"
                        disabled={Edit}
                        value={editFormData.contact_no}
                        onChange={handleEditFormChange}
                        containerStyle={{ width: "100%", height: "100%" }}
                      />
                      {/* <InputGroup.Text id="basic-addon1">
                        <img src="/imgs/phone.svg" alt="" />
                      </InputGroup.Text> */}
                      {/* <Form.Control
                        type="number"
                        min="0"
                        maxLength={6}
                        name="contact_no"
                        disabled={Edit}
                        value={editFormData.contact_no}
                        onChange={handleEditFormChange}
                      /> */}
                    </InputGroup>
                  </Form.Group>
                </div>
                <div className="col-12 px-0">
                  <h4 className="identifier py-3">Address</h4>
                </div>
                <div className="col-md-5 px-0">
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Country</Form.Label>
                    <InputGroup className="mb-3">
                      <InputGroup.Text id="basic-addon1">
                        <img src="/imgs/country.svg" alt="" />
                      </InputGroup.Text>
                      <Form.Control
                        type="text"
                        placeholder="e.g: Singapore"
                        style={{ fontStyle: "italic" }}
                        name="country"
                        disabled={Edit}
                        value={editFormData.country}
                        onChange={handleEditFormChange}
                      />
                    </InputGroup>
                  </Form.Group>
                </div>
                <div className="col-md-2"></div>
                <div className="col-md-5 px-0">
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>State</Form.Label>
                    <InputGroup className="mb-3">
                      <InputGroup.Text id="basic-addon1">
                        <img src="/imgs/address.svg" alt="" />
                      </InputGroup.Text>
                      <Form.Control
                        type="text"
                        placeholder="e.g: Singapore"
                        style={{ fontStyle: "italic" }}
                        name="state"
                        disabled={Edit}
                        value={editFormData.state}
                        onChange={handleEditFormChange}
                      />
                    </InputGroup>
                  </Form.Group>
                </div>
                <div className="col-md-5 px-0">
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>City</Form.Label>
                    <InputGroup className="mb-3">
                      <InputGroup.Text id="basic-addon1">
                        <img src="/imgs/address.svg" alt="" />
                      </InputGroup.Text>
                      <Form.Control
                        type="text"
                        placeholder="e.g: Singapore"
                        style={{ fontStyle: "italic" }}
                        name="city"
                        disabled={Edit}
                        value={editFormData.city}
                        onChange={handleEditFormChange}
                      />
                    </InputGroup>
                  </Form.Group>
                </div>
                <div className="col-md-2"></div>
                <div className="col-md-5 px-0">
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Postal Code</Form.Label>
                    <InputGroup className="mb-3">
                      <InputGroup.Text id="basic-addon1">
                        <img src="/imgs/postal_code.svg" alt="" />
                      </InputGroup.Text>
                      <Form.Control
                        type="text"
                        name="postal_code"
                        disabled={Edit}
                        value={editFormData.postal_code}
                        onChange={handleEditFormChange}
                      />
                    </InputGroup>
                  </Form.Group>
                </div>
                <div className="col-md-12 px-0">
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Address</Form.Label>
                    <InputGroup className="mb-3">
                      <InputGroup.Text id="basic-addon1">
                        <img src="/imgs/address.svg" alt="" />
                      </InputGroup.Text>
                      <Form.Control
                        type="text"
                        name="address"
                        placeholder="e.g: Singapore"
                        style={{ fontStyle: "italic" }}
                        disabled={Edit}
                        value={editFormData.address}
                        onChange={handleEditFormChange}
                      />
                    </InputGroup>
                  </Form.Group>
                  {/* <p className="forgotPassword">Add New Address</p> */}
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>

      <div className="cardSection mt-4 mb-2">
        <div className="d-flex justify-content-between">
          <h1>Permission Settings</h1>
        </div>
        <PermissionTbl
          UpdateForm={UpdateForm}
          register={register}
          handleSubmit={handleSubmit}
          watch={watch}
          editFormData={editFormData}
          Details={Details}
          Edit={Edit}
          setValue={setValue}
          setCustomerArray={setCustomerArray}
          setContributorArray={setContributorArray}
          setArticlesReviews={setArticlesReviews}
          setArticlesOrders={setArticlesOrders}
          setVirtualReviews={setVirtualReviews}
          setBilling={setBilling}
          customerArray={customerArray}
          // contributorArray={contributorArray}
          ArticlesReviews={ArticlesReviews}
          VirtualReviews={VirtualReviews}
          ArticlesOrders={ArticlesOrders}
          Billing={Billing}
          // formState = {formState}
        />
      </div>
    </div>
  );
}

export default StaffEditForm;
