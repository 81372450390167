import axios from "axios";

const API_URL = `${process.env.REACT_APP_BACKEND_ROUTE}/sorting/`;

const getData = async () => {
  const response = await axios.get(API_URL);
  if (response.data) {
    return response.data;
  }
};

// const addData = async (content) => {
//   console.log(content);
//   const response = await axios.post(API_URL, content);
//   if (response.data) {
//     return response.data;
//   }
// };

const updateData = async (content) => {
  const response = await axios.put(API_URL, content);
  if (response.data) {
    return response.data;
  }
};
const deleteData = async (id) => {
  const response = await axios.delete(`${API_URL}${id}`);
  if (response.data) {
    return response.data;
  }
};

const sortingService = {
  getData,
  //   addData,
  updateData,
  deleteData,
};

export default sortingService;
