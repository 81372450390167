import React, { useLayoutEffect, useState } from "react";
import Blogsdata from "../../../../utils/data/featuredArticles.json";
// import Rating from "./Rating";
import Rating from "@mui/material/Rating";
import Stack from "@mui/material/Stack";

function FeatureArticles({ Articles, handleShow }) {
  // User is currently on this page
  const [currentPage, setCurrentPage] = useState(1);
  // No of Records to be displayed on each page
  const [recordsPerPage] = useState(9);

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  // Records to be displayed on the current page
  const currentRecords = Articles?.slice(
    indexOfFirstRecord,
    indexOfLastRecord
  )?.sort((a, b) => {
    return new Date(b.publishedAt) - new Date(a.publishedAt);
  });
  const nPages = Math.ceil(Articles?.length / recordsPerPage);

  const pageNumbers = [...Array(nPages + 1).keys()].slice(1);

  const nextPage = () => {
    if (currentPage !== nPages) setCurrentPage(currentPage + 1);
  };
  const prevPage = () => {
    if (currentPage !== 1) setCurrentPage(currentPage - 1);
  };

  return (
    <>
      <div className="bannerSection featuredArticlesList">
        <div className="container-fluid px-50px">
          <div className="row">
            <div className="col-12pt-3">
              <div className="row">
                {currentRecords?.map((data, index) => {
                  return (
                    <div
                      className="col-lg-4 col-md-6 col-sm-6 mb-4"
                      key={index}
                    >
                      <div className="blogCard">
                        <div className="position-relative">
                          {data?.cover_images?.some((val) => val?.cover) ? (
                            data?.cover_images?.map(
                              (val) =>
                                val?.cover == true && (
                                  <img
                                    style={{ height: "240px" }}
                                    src={val?.img}
                                    alt="Cover Images"
                                  />
                                )
                            )
                          ) : (
                            <img
                              style={{ height: "240px" }}
                              src={data?.cover_images[0]?.img}
                              alt="Cover Images"
                            />
                          )}
                        </div>
                        <h2>{data?.title}</h2>
                        {/* <Rating value={data.rating} text = {`${data.review} reviews`}/> */}
                        <Stack spacing={1}>
                          <Rating
                            name="half-rating"
                            value={data.rating}
                            precision={0.5}
                            readOnly
                          />
                        </Stack>
                        <div className="d-flex justify-content-between pt-3">
                          <div className="d-flex gap-1 align-items-center">
                            <img
                              src="/imgs/calender.svg"
                              className="calender"
                              alt=""
                            />
                            <p>{data?.createdAt?.substring(0, 10)}</p>
                          </div>

                          <button onClick={() => handleShow(data)}>
                            {/* {data.type === "blog" ? "Read More " : "Watch now "} */}
                            Blog &nbsp;
                            <img
                              src="/imgs/arrow-right.svg"
                              className="arrow"
                              alt=""
                            />
                          </button>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="text-center mt-5">
              <div className="pagination">
                <a href="#" onClick={prevPage}>
                  Previous
                </a>
                {pageNumbers?.map((pgNumber) => (
                  <a
                    href="#"
                    className={`${pgNumber == currentPage && "active"}`}
                    onClick={() => setCurrentPage(pgNumber)}
                  >
                    {pgNumber}
                  </a>
                ))}
                <a href="#" onClick={nextPage}>
                  Next
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default FeatureArticles;
