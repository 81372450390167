import React, { useEffect, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import {
  addContributor,
  addContributors,
  editContributors,
  resetImage,
  saveImageC,
  updatePassC,
} from "../../../../features/contributers/contSlice";
import { getContributors } from "./../../../../features/contributers/contSlice";
import EditForm from "./EditForm";
import ContributorForm from "./ContributorForm";
import validator from "validator";
import { reset } from "../../../../features//contributers/contSlice";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { useSnackbar } from "notistack";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";

function AddContributor() {
  const user = JSON.parse(localStorage.getItem("user"));

  const userID = useParams();
  const [Details, setDetails] = useState({});
  const [oldPass, setOldPass] = useState("");
  const [newPass, setNewPass] = useState("");
  const [CPass, setCPass] = useState("");
  const [show, setShow] = useState(false);
  const [Edit, setEdit] = useState(false);
  const [showPass, setShowPass] = useState("password");
  const [showPass2, setShowPass2] = useState("password");
  const [showPass3, setShowPass3] = useState("password");
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const handleClose = () => {
    setShow(false);
    setOldPass("");
    setNewPass("");
    setCPass("");
    setShowPass("password");
  };
  const handleShow = () => setShow(true);

  const {
    contributors_List,
    isError,
    message,
    isSuccess,
    isLoading,
    imageLocation,
    isList,
  } = useSelector((state) => state.contributors);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [addFormData, setAddFormData] = useState({
    first_name: "",
    last_name: "",
    user_name: "",
    dob: "",
    national_id: "",
    profile: "",
    status: "",
    password: "",
    email: "",
    contact_no: "",
    country: "",
    state: "",
    city: "",
    postal_code: "",
    address: "",
  });

  const [editFormData, setEditFormData] = useState({
    first_name: "",
    last_name: "",
    user_name: "",
    dob: "",
    national_id: "",
    profile: "",
    status: "",
    // password: '',
    email: "",
    contact_no: "",
    country: "",
    state: "",
    city: "",
    postal_code: "",
    address: "",
  });

  useLayoutEffect(() => {
    dispatch(reset());
    dispatch(resetImage());
    if (userID.id) {
      dispatch(getContributors());
      if (isList == true) {
        const newData = [...contributors_List];
        var data = newData?.filter((user) => user._id == userID.id);
        setDetails(data[0]);
        //  dispatch(reset());
      }
    }
  }, [Details]);

  useEffect(() => {
    if (
      (addFormData.first_name &&
        addFormData.last_name &&
        addFormData.user_name &&
        addFormData.email) !== ""
    ) {
      setEdit(true);
    } else {
      setEdit(false);
    }

    if (isSuccess == true && message.message !== "") {
      toast.success(message.message);
      setTimeout(() => {
        navigate(-1);
        dispatch(reset());
        dispatch(resetImage());
      }, 1500);
    }
    if (isError == true) {
      toast.error(message);
      dispatch(reset());
    }
  }, [
    dispatch,
    userID,
    isError,
    message,
    isSuccess,
    isList,
    addFormData,
    Edit,
  ]);

  const SavePass = () => {
    const passData = {
      oldPass,
      newPass,
      CPass,
      id: userID.id,
    };
    if (newPass === CPass) {
      dispatch(updatePassC(passData));
      setShowPass("password");
    } else {
      toast.error("Password and confirm password did not match!");
      // toast.error("Password did not match!");
    }
  };

  const handleAddFormChange = (event) => {
    event.preventDefault();
    const fieldName = event.target.getAttribute("name");
    const fieldValue = event.target.value;

    const newFormData = { ...addFormData };
    newFormData[fieldName] = fieldValue;

    setAddFormData(newFormData);
  };

  const defaultImage =
    "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_640.png";

  const AddForm = (event) => {
    event.preventDefault();
    const userData = {
      first_name: addFormData.first_name,
      last_name: addFormData.last_name,
      user_name: addFormData.user_name,
      dob: addFormData.dob,
      national_id: addFormData.national_id,
      profile: imageLocation ? imageLocation : defaultImage,
      status: addFormData.status,
      password: addFormData.password,
      email: addFormData.email,
      contact_no: addFormData.contact_no,
      country: addFormData.country,
      state: addFormData.state,
      city: addFormData.city,
      postal_code: addFormData.postal_code,
      address: addFormData.address,
      role: "contributor",
    };
    dispatch(addContributors(userData));
    // navigate(-1)
  };

  const handleEditFormChange = (event) => {
    event.preventDefault();

    const fieldName = event.target.getAttribute("name");
    const fieldValue = event.target.value;

    const newFormData = { ...editFormData };
    newFormData[fieldName] = fieldValue;

    setEditFormData(newFormData);
  };

  const UpdateForm = (event) => {
    event.preventDefault();

    const userData = {
      id: userID.id,
      first_name: editFormData.first_name,
      last_name: editFormData.last_name,
      user_name: editFormData.user_name,
      dob: editFormData.dob,
      national_id: editFormData.national_id,
      profile: imageLocation ? imageLocation : editFormData.profile,
      status: editFormData.status,
      // password: editFormData.password,
      email: editFormData.email,
      contact_no: editFormData.contact_no,
      country: editFormData.country,
      state: editFormData.state,
      city: editFormData.city,
      postal_code: editFormData.postal_code,
      address: editFormData.address,
      role: "contributor",
    };

    dispatch(editContributors(userData));
    // localStorage.removeItem('profileImage')
    // navigate(-1);
  };

  const uploadFileHandler = (e) => {
    const file = e.target.files[0];
    const bodyFormData = new FormData();
    bodyFormData.append("image", file);

    dispatch(saveImageC(bodyFormData));
  };

  const showPassword = () => {
    if (showPass === "password") {
      setShowPass("text");
    }
    if (showPass === "text") {
      setShowPass("password");
    }
  };
  const showPassword2 = () => {
    if (showPass2 === "password") {
      setShowPass2("text");
    }
    if (showPass2 === "text") {
      setShowPass2("password");
    }
  };
  const showPassword3 = () => {
    if (showPass3 === "password") {
      setShowPass3("text");
    }
    if (showPass3 === "text") {
      setShowPass3("password");
    }
  };

  return (
    <div>
      <ToastContainer />
      {Object.keys(userID).length === 0 && userID.constructor === Object ? (
        <ContributorForm
          AddForm={AddForm}
          handleAddFormChange={handleAddFormChange}
          uploadFileHandler={uploadFileHandler}
          imageLocation={imageLocation}
          showPassword={showPassword}
          showPass={showPass}
          Edit={Edit}
        />
      ) : (
        <EditForm
          UpdateForm={UpdateForm}
          handleEditFormChange={handleEditFormChange}
          Details={Details}
          editFormData={editFormData}
          setEditFormData={setEditFormData}
          uploadFileHandler={uploadFileHandler}
          imageLocation={imageLocation}
          handleShow={handleShow}
          showPassword={showPassword}
          showPass={showPass}
        />
      )}

      <Modal show={show} onHide={handleClose} centered>
        <div className="d-flex justify-content-end pe-2 pt-2">
          <img
            src="/imgs/revoke.svg"
            className="closeIcon"
            onClick={handleClose}
            alt=""
          />
        </div>
        <Modal.Body>
          <div className="row">
            <div className="col-md-8 mx-auto">
              <div className="row gx-5">
                <div className="col-md-12">
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Old Password</Form.Label>
                    <InputGroup className="mb-3">
                      <InputGroup.Text id="basic-addon1">
                        <img src="/imgs/password.svg" alt="" />
                      </InputGroup.Text>
                      <Form.Control
                        type={showPass}
                        required={true}
                        value={oldPass}
                        onChange={(e) => setOldPass(e.target.value)}
                      />
                      {showPass === "text" ? (
                        <InputGroup.Text id="basic-addon1">
                          <VisibilityOffIcon
                            fontSize="small"
                            style={{ cursor: "pointer", color: "lightgray" }}
                            alt=""
                            onClick={showPassword}
                          />
                        </InputGroup.Text>
                      ) : (
                        <InputGroup.Text id="basic-addon1">
                          <VisibilityIcon
                            fontSize="small"
                            style={{ cursor: "pointer", color: "lightgray" }}
                            alt=""
                            onClick={showPassword}
                          />
                        </InputGroup.Text>
                      )}
                    </InputGroup>
                  </Form.Group>
                </div>
                <div className="col-md-12">
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>New Password</Form.Label>
                    <InputGroup className="mb-3">
                      <InputGroup.Text id="basic-addon1">
                        <img src="/imgs/password.svg" alt="" />
                      </InputGroup.Text>
                      <Form.Control
                        type={showPass2}
                        required={true}
                        value={newPass}
                        onChange={(e) => setNewPass(e.target.value)}
                      />
                      {showPass2 === "text" ? (
                        <InputGroup.Text id="basic-addon1">
                          <VisibilityOffIcon
                            fontSize="small"
                            style={{ cursor: "pointer", color: "lightgray" }}
                            alt=""
                            onClick={showPassword2}
                          />
                        </InputGroup.Text>
                      ) : (
                        <InputGroup.Text id="basic-addon1">
                          <VisibilityIcon
                            fontSize="small"
                            style={{ cursor: "pointer", color: "lightgray" }}
                            alt=""
                            onClick={showPassword2}
                          />
                        </InputGroup.Text>
                      )}
                    </InputGroup>
                  </Form.Group>
                </div>
                <div className="col-md-12">
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Confirm Password</Form.Label>
                    <InputGroup className="mb-3">
                      <InputGroup.Text id="basic-addon1">
                        <img src="/imgs/password.svg" alt="" />
                      </InputGroup.Text>
                      <Form.Control
                        type={showPass3}
                        required={true}
                        value={CPass}
                        onChange={(e) => setCPass(e.target.value)}
                      />

                      {showPass3 === "text" ? (
                        <InputGroup.Text id="basic-addon1">
                          <VisibilityOffIcon
                            fontSize="small"
                            style={{ cursor: "pointer", color: "lightgray" }}
                            alt=""
                            onClick={showPassword3}
                          />
                        </InputGroup.Text>
                      ) : (
                        <InputGroup.Text id="basic-addon1">
                          <VisibilityIcon
                            fontSize="small"
                            style={{ cursor: "pointer", color: "lightgray" }}
                            alt=""
                            onClick={showPassword3}
                          />
                        </InputGroup.Text>
                      )}
                    </InputGroup>
                  </Form.Group>
                </div>
              </div>
            </div>
          </div>
          <div className="d-sm-flex justify-content-center text-center gap-4">
            <Button
              className="loginBtn me-sm-0 me-3 mb-sm-0 mb-2 mt-4"
              onClick={SavePass}
              disabled={oldPass === "" || newPass === "" ? true : false}
            >
              Submit
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default AddContributor;
