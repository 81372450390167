import React, { useEffect, useState, useRef } from "react";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Modal, Button, Form, InputGroup } from "react-bootstrap";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { convertFromHTML, EditorState } from "draft-js";
import ContentState from "draft-js/lib/ContentState";
import { useDispatch } from "react-redux";
import {
  editQuotation,
  getQuotationDetail,
  reset,
} from "../../../../features/Quotations/quotationSlice";
import { useSelector } from "react-redux";
import QuestionareDetail from "../../../Staff/QuestionareDetail/QuestionareDetail";
import { toast, ToastContainer } from "react-toastify";
import { getUsersByRole } from "../../../../features/users/userSlice";
import ReactQuill from "../../../Editor/Editor.jsx";
import { useValue } from "../../../../context/ContextProvider";
import Geocoder from "../../Tours/Geocoder";
import ReactMapGL, {
  GeolocateControl,
  Marker,
  NavigationControl,
} from "react-map-gl";
import { Box } from "@mui/material";

function OrderCommisionDetail() {
  const {
    questions_List,
    isSuccess: questSuccess,
    isError: questError,
    message: questMessage,
    isLoading: questLoading,
    isList,
  } = useSelector((state) => state.questions);
  const { quotation_List, isError, isSuccess, message, quotationDetail } =
    useSelector((state) => state.quotations);
  const { allStaff } = useSelector((state) => state.users);
  const user = JSON.parse(localStorage.getItem("user"));
  const userID = user?.data?.user?._id;
  const mapRef = useRef();
  const { id } = useParams();
  const [status, setStatus] = useState("");
  const [Billing, setBilling] = useState("");
  const [Pricing_Amount, setPricing_Amount] = useState("");
  const [showQuest, setShowQuest] = useState(false);
  const [JustQuest, setJustQuest] = useState(false);
  const [dueDate, setDueDate] = useState("");
  const [staff, setStaff] = useState("");
  let { state } = useLocation();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const {
    state: {
      location: { lng, lat },
    },
    mapdispatch,
  } = useValue();

  const handleCloseQuest = () => {
    setShowQuest(false);
  };
  useEffect(() => {
    if (state?.Details?.Content != null) {
      setEditorState(
        EditorState.createWithContent(
          ContentState.createFromBlockArray(
            convertFromHTML(state?.Details?.Content)
          )
        )
      );
    }
    if (state?.Details != null) {
      setStaff(state?.Details?.assignTo);
      setDueDate(state?.Details?.dueDate?.substring(0, 10));
      setPricing_Amount(state?.Details?.plan[0]?.pricing_amount);
      setStatus(state?.Details?.status);
    }

    if (isSuccess == true && message !== "") {
      toast.success(message);
      setTimeout(() => {
        dispatch(reset());
        navigate(-1);
      }, 1500);
    }
    if (isError == true && message !== "") {
      toast.error(message);
      setTimeout(() => {
        dispatch(reset());
      }, 1500);
    }
  }, [isError, isSuccess, message]);
  useEffect(() => {
    if (id) {
      dispatch(getQuotationDetail(id));

      if (quotationDetail?.plan?.length > 0) {
        setValue(
          quotationDetail?.Content
            ? quotationDetail?.Content
            : quotationDetail?.plan[0]?.description
        );
      }
      if (quotationDetail?.plan?.length > 0) {
        setPricing_Amount(quotationDetail?.plan[0]?.pricing_amount);
        setStatus(quotationDetail?.status);
      }

      if (isSuccess == true && message !== "") {
        toast.success(message);
        setTimeout(() => {
          dispatch(reset());
          navigate(-1);
        }, 1500);
      }
      if (isError == true && message !== "") {
        toast.error(message);
        setTimeout(() => {
          dispatch(reset());
        }, 1500);
      }
    }
  }, []);
  useEffect(() => {
    dispatch(getUsersByRole("staff"));
  }, []);

  const SubmitQuote = () => {
    const data = { ...state?.Details?.plan[0] };
    data["pricing_amount"] = Pricing_Amount;

    const Data = {
      plan: data,
      owner: state?.Details?.owner,
      id: state?.Details?._id,
      status: status,
      Billing: Billing,
      dueDate,
      assignTo: staff,
      url: "/Commission-Job-List/Unpublished/Order-Detail/",
    };
    dispatch(editQuotation(Data));
  };

  const AddNewArticle = (userData) => {
    dispatch(reset());
    navigate("/article/article-form", {
      state: {
        type: "Draft",
        view: false,
        user: "Addstaff",
        paidQuotationUser: userData.Details,
      },
    });
  };
  const AddNewTour = (userData) => {
    dispatch(reset());
    navigate("/360-tours/tours-form", {
      state: {
        type: "Draft",
        view: false,
        user: "Addstaff",
        paidQuotationUser: userData.Details,
      },
    });
  };
  const [value, setValue] = useState();
  const numberOfQuestions =
    state?.Details?.plan[0]?.questionaire?.questions?.length;
  const latestAnswers = id
    ? quotationDetail?.questArray
    : state?.Details?.plan[0]?.questionaire?.answers
    ? state?.Details?.plan[0]?.questionaire?.answers.slice(-numberOfQuestions)
    : state?.Details?.questArray;

  return (
    <div>
      <ToastContainer />
      <button
        className="loginBtn"
        style={{ marginBottom: "10px" }}
        onClick={() => navigate(-1)}
      >
        Go back
      </button>
      <div className="cardSection">
        <div className="d-flex justify-content-between">
          <h1>Order Details</h1>
          <div className="d-flex justify-content-center gap-4 mb-3">
            {state?.edit == true && user?.data?.user?.role === "admin" && (
              <>
                <Button
                  className="cancelBtn"
                  type="button"
                  onClick={() => navigate(-1)}
                >
                  Cancel
                </Button>
                <Button
                  className="loginBtn"
                  disabled={status !== "" ? false : true}
                  onClick={SubmitQuote}
                >
                  Update
                </Button>
              </>
            )}
          </div>
        </div>
        <div className="row mx-0 mt-4">
          <div className="col-10 mx-auto">
            <div className="row gx-5">
              <div className="col-md-6">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Order Date</Form.Label>
                  <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1">
                      <img src="/imgs/dob.svg" alt="" />
                    </InputGroup.Text>
                    <Form.Control
                      type="date"
                      value={
                        quotationDetail?.createdAt?.substring(0, 10) ||
                        state?.Details?.createdAt?.substring(0, 10)
                      }
                    />
                  </InputGroup>
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>User</Form.Label>
                  <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1">
                      <img src="/imgs/u_user.svg" alt="" />
                    </InputGroup.Text>
                    <Form.Control
                      type="text"
                      value={
                        quotationDetail?.owner?.first_name ||
                        state?.Details?.owner?.first_name
                      }
                    />
                  </InputGroup>
                </Form.Group>
              </div>
              {(user && user?.data?.user?.role) == "admin" && (
                <>
                  <div
                    className="col-md-6"
                    style={{ backgroundColor: "#fff0f0b5" }}
                  >
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Due Date</Form.Label>
                      <InputGroup className="mb-3">
                        <InputGroup.Text id="basic-addon1">
                          <img src="/imgs/dob.svg" alt="" />
                        </InputGroup.Text>
                        <Form.Control
                          type="date"
                          value={
                            Object.keys(quotationDetail).length
                              ? quotationDetail?.dueDate?.substring(0, 10)
                              : dueDate
                          }
                          onChange={(e) => setDueDate(e.target.value)}
                        />
                      </InputGroup>
                    </Form.Group>
                  </div>
                  <div
                    className="col-md-6"
                    style={{ backgroundColor: "#fff0f0b5" }}
                  >
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Assign to</Form.Label>
                      <InputGroup className="mb-3">
                        <InputGroup.Text id="basic-addon1">
                          <img src="/imgs/u_user.svg" alt="" />
                        </InputGroup.Text>
                        <Form.Select
                          aria-label="Default select example"
                          value={
                            Object.keys(quotationDetail).length
                              ? quotationDetail?.assignTo
                              : staff
                          }
                          onChange={(e) => setStaff(e.target.value)}
                        >
                          <option value="">Select staff</option>
                          {allStaff.map((item) => (
                            <option value={item._id}>{item?.first_name}</option>
                          ))}
                        </Form.Select>
                      </InputGroup>
                    </Form.Group>
                  </div>
                </>
              )}
              {/* <div className="col-md-6">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Billing No</Form.Label>
                  <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1">
                      <img src="/imgs/billing.svg" alt="" />
                    </InputGroup.Text>
                    <Form.Control
                      type="text"
                      placeholder="N/A"
                      disabled={true}
                      value={Billing}
                      onChange={(e) => setBilling(e.target.value)}
                    />
                  </InputGroup>
                </Form.Group>
              </div> */}
              <div className="col-md-6">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Product Plan</Form.Label>
                  <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1">
                      <img src="/imgs/product-type.svg" alt="" />
                    </InputGroup.Text>
                    <Form.Control
                      type="text"
                      value={
                        quotationDetail?.plan?.[0]?.name ||
                        state?.Details?.product_plan
                      }
                    />
                  </InputGroup>
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Amount</Form.Label>
                  <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1">
                      <img src="/imgs/amount.svg" alt="" />
                    </InputGroup.Text>
                    {user?.data?.user?.role === "staff" ||
                    user?.data?.user?.role === "admin" ? (
                      <Form.Control
                        type="number"
                        min={0}
                        disabled={
                          state?.Details?.plan[0]?.pricing_type === "Quoted"
                            ? false
                            : true
                        }
                        value={Pricing_Amount}
                        onChange={(e) => setPricing_Amount(e.target.value)}
                      />
                    ) : (
                      <Form.Control
                        type="number"
                        min={0}
                        disabled={true}
                        value={Pricing_Amount}
                        onChange={(e) => setPricing_Amount(e.target.value)}
                      />
                    )}
                  </InputGroup>
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Pricing Type</Form.Label>
                  <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1">
                      <img src="/imgs/money_light.svg" alt="" />
                    </InputGroup.Text>
                    <Form.Control
                      aria-label="Default select example"
                      value={
                        quotationDetail?.plan?.[0]?.pricing_type ||
                        state?.Details?.plan[0]?.pricing_type
                      }
                    />
                  </InputGroup>
                </Form.Group>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="cardSection mt-4">
        <div className="row mx-0">
          <div className="col-sm-10 col-12 mx-auto">
            <div className="d-flex justify-content-between align-items-center">
              <span className="score">
                Score :{" "}
                {state?.Details?.totalscore
                  ? state?.Details?.totalscore
                  : quotationDetail?.totalscore}
              </span>
              {console.log(123, quotationDetail)}
              <Modal show={showQuest} onHide={handleCloseQuest}>
                <Modal.Header closeButton>
                  {/* <Modal.Title>Questionnaire</Modal.Title> */}
                  <Modal.Body>
                    <QuestionareDetail
                      questions_List={questions_List}
                      CList={[]}
                      Details={id ? quotationDetail : state?.Details}
                      questId={
                        id
                          ? {
                              answers:
                                quotationDetail?.templateQuestions?.[0]
                                  ?.answers,
                            }
                          : state?.Details?.plan[0]?.questionaire
                      }
                      setShowQuest={setShowQuest}
                      JustQuest={JustQuest}
                    />
                  </Modal.Body>
                </Modal.Header>
              </Modal>
            </div>
          </div>
        </div>
      </div>
      <div className="cardSection mt-4">
        <div className="d-flex justify-content-between">
          <h1>Quote request detail</h1>
        </div>
        <div className="row mx-0 mt-4">
          <div className="col-10 mx-auto">
            <div className="row gx-5">
              <div className="col-md-6">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Type of Post</Form.Label>
                  <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1">
                      <img src="/imgs/name.svg" alt="" />
                    </InputGroup.Text>
                    <Form.Select
                      aria-label="Default select example"
                      value={
                        quotationDetail?.plan?.[0]?.product_type ||
                        state?.Details?.plan[0]?.product_type
                      }
                    >
                      <option value="Blog">Blog</option>
                      {/* <option value="Video">Video</option> */}
                      <option value="360 tour">360 tour</option>
                    </Form.Select>
                  </InputGroup>
                </Form.Group>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="cardSection mt-4">
        <div className="d-flex justify-content-between">
          <h1>Blog / 360Tour</h1>
        </div>
        <div className="row mx-0 mt-4">
          <div className="col-10 mx-auto">
            <div className="row gx-5">
              <div className="col-md-6">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Content Generator</Form.Label>
                  <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1">
                      <img src="/imgs/name.svg" alt="" />
                    </InputGroup.Text>
                    <Form.Select
                      aria-label="Default select example"
                      value={
                        quotationDetail?.plan?.[0]?.content_generator ||
                        state?.Details?.Content_Generator
                      }
                    >
                      <option value="">Select</option>
                      <option value="Self-Generated">Self-Generated</option>
                      <option value="Commissioned">Commissioned</option>
                    </Form.Select>
                  </InputGroup>
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Self-Generated Post</Form.Label>
                  <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1">
                      <img src="/imgs/plus.svg" alt="" />
                    </InputGroup.Text>
                    <Form.Select
                      aria-label="Default select example"
                      value={
                        quotationDetail?.plan?.[0]?.product_type ||
                        state?.Details?.plan[0]?.product_type
                      }
                    >
                      <option value="">Select</option>
                      <option value="Blog">Blog</option>
                      {/* <option value="Video">Video</option> */}
                      <option value="360 tour">360 tours</option>
                    </Form.Select>
                  </InputGroup>
                </Form.Group>
              </div>
            </div>
          </div>
        </div>
      </div>
      {(state?.Details?.plan[0]?.product_type === "Blog" ||
        state?.Details?.product_plan === "Blog Commissioned Quoted") &&
      state?.Details?.plan[0]?.pricing_type === "Quoted" ? (
        <div className="cardSection mt-4">
          <div className="d-flex justify-content-between">
            <h1>Self-Generated Blog </h1>
          </div>
          <div className="row mx-0 mt-4">
            <div className="col-12 px-0">
              <div className="row gx-5">
                {state?.Details &&
                  state?.Details?.templateQuestions &&
                  state?.Details?.templateQuestions?.questions.length > 0 &&
                  state?.Details?.templateQuestions?.questions.map((val, i) => (
                    <div className="row mt-3">
                      {/* <div className="col-md-6"> */}
                      <h1>{val?.question}</h1>
                      <div className="answerBox">
                        {val?.answerType === "choice" ? (
                          val?.score?.map((item, index) => (
                            <div key={index}>
                              <label>
                                <input
                                  type="radio"
                                  name={`${val?.question}`}
                                  value={item.text}
                                  checked={
                                    item.text == latestAnswers[i]?.answer
                                  }
                                />
                                {item.text}
                              </label>
                            </div>
                          ))
                        ) : val?.answerType === "yesNo" && val?.score !== 0 ? (
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            <Form.Control
                              as="textarea"
                              rows={5}
                              value={latestAnswers[i]?.answer}
                              // onChange={(e) =>
                              //   handleChange(e, i, val.id, val?.score)
                              // }
                              disabled={
                                user?.data?.user?.role === "staff" ||
                                user?.data?.user?.role === "admin"
                                  ? true
                                  : false
                              }
                            />
                          </Form.Group>
                        ) : val?.answerType === "image" ? (
                          <>
                            <div className="image-placeholder px-3 my-4">
                              {latestAnswers[i]?.answer?.length > 0 &&
                                latestAnswers[i]?.answer?.map((val) => (
                                  <>
                                    <div className="avatar-preview">
                                      <div id="imagePreview">
                                        <img
                                          id="saveImageFile"
                                          src={val.img}
                                          alt={val.img}
                                        />
                                      </div>
                                    </div>
                                  </>
                                ))}
                            </div>
                          </>
                        ) : (
                          <div className="cardSection mt-4 mb-2">
                            <div className="row mx-0">
                              <div className="col-10 mx-auto">
                                <Form className="mt-5">
                                  <div className="row mx-0">
                                    <div className="col-md-5 px-0">
                                      <Form.Group
                                        className="mb-3"
                                        controlId="formBasicEmail"
                                      >
                                        <Form.Label>Country/Island</Form.Label>
                                        <InputGroup className="mb-3">
                                          <InputGroup.Text id="basic-addon1">
                                            <img
                                              src="/imgs/country.svg"
                                              alt=""
                                            />
                                          </InputGroup.Text>
                                          <Form.Control
                                            type="text"
                                            name="country"
                                            value={
                                              latestAnswers[i]?.answer?.country
                                            }
                                          />
                                        </InputGroup>
                                      </Form.Group>
                                    </div>
                                    <div className="col-md-2"></div>
                                    <div className="col-md-5 px-0">
                                      <Form.Group
                                        className="mb-3"
                                        controlId="formBasicEmail"
                                      >
                                        <Form.Label>State</Form.Label>
                                        <InputGroup className="mb-3">
                                          <InputGroup.Text id="basic-addon1">
                                            <img
                                              src="/imgs/address.svg"
                                              alt=""
                                            />
                                          </InputGroup.Text>
                                          <Form.Control
                                            type="text"
                                            name="state"
                                            value={
                                              latestAnswers[i]?.answer?.state
                                            }
                                          />
                                        </InputGroup>
                                      </Form.Group>
                                    </div>
                                    <div className="col-md-5 px-0">
                                      <Form.Group
                                        className="mb-3"
                                        controlId="formBasicEmail"
                                      >
                                        <Form.Label>City</Form.Label>
                                        <InputGroup className="mb-3">
                                          <InputGroup.Text id="basic-addon1">
                                            <img
                                              src="/imgs/address.svg"
                                              alt=""
                                            />
                                          </InputGroup.Text>
                                          <Form.Control
                                            type="text"
                                            name="city"
                                            value={
                                              latestAnswers[i]?.answer?.city
                                            }
                                          />
                                        </InputGroup>
                                      </Form.Group>
                                    </div>
                                    <div className="col-md-2"></div>
                                    <div className="col-md-5 px-0">
                                      <Form.Group
                                        className="mb-3"
                                        controlId="formBasicEmail"
                                      >
                                        <Form.Label>Postal Code</Form.Label>
                                        <InputGroup className="mb-3">
                                          <InputGroup.Text id="basic-addon1">
                                            <img
                                              src="/imgs/postal_code.svg"
                                              alt=""
                                            />
                                          </InputGroup.Text>
                                          <Form.Control
                                            type="text"
                                            name="postal_code"
                                            value={
                                              latestAnswers[i]?.answer?.postCode
                                                ? latestAnswers[i]?.answer
                                                    ?.postCode
                                                : latestAnswers[i]?.answer
                                                    ?.postalCode
                                            }
                                          />
                                        </InputGroup>
                                      </Form.Group>
                                    </div>
                                    <div className="col-md-12 px-0">
                                      <Form.Group
                                        className="mb-3"
                                        controlId="formBasicEmail"
                                      >
                                        <Form.Label>Address</Form.Label>
                                        <InputGroup className="mb-3">
                                          <InputGroup.Text id="basic-addon1">
                                            <img
                                              src="/imgs/address.svg"
                                              alt=""
                                            />
                                          </InputGroup.Text>
                                          <Form.Control
                                            type="text"
                                            name="address"
                                            value={
                                              state?.Details?.questArray[i]
                                                ?.answer?.address
                                            }
                                          />
                                        </InputGroup>
                                      </Form.Group>
                                    </div>
                                    <div className="col-md-12 px-0">
                                      <Form.Group
                                        className="mb-3"
                                        controlId="formBasicEmail"
                                      >
                                        <Form.Label>Map Location</Form.Label>
                                        <Box
                                          sx={{
                                            height: 400,
                                            position: "relative",
                                          }}
                                        >
                                          <ReactMapGL
                                            ref={mapRef}
                                            mapboxAccessToken="pk.eyJ1IjoiYmx1ZXNpbG9zZyIsImEiOiJjbGZyeDZ1MGswMG1mM2RxcmJyajRzbXRyIn0.SwkzsvT0dgMXSafnYQoZSQ"
                                            initialViewState={{
                                              zoom: 11,
                                              // latitude: 1.3521,
                                              // longitude: 103.8198,
                                              longitude: latestAnswers[i]
                                                ?.answer?.longitude
                                                ? Number(
                                                    latestAnswers[i]?.answer
                                                      ?.longitude
                                                  )
                                                : 103.8198,
                                              latitude: latestAnswers[i]?.answer
                                                ?.latitude
                                                ? Number(
                                                    latestAnswers[i]?.answer
                                                      ?.latitude
                                                  )
                                                : 1.3521,
                                            }}
                                            mapStyle="mapbox://styles/mapbox/streets-v11"
                                          >
                                            <Marker
                                              // longitude={103.8198}
                                              // latitude={1.3521}
                                              latitude={
                                                latestAnswers[i]?.answer
                                                  ?.latitude
                                                  ? Number(
                                                      latestAnswers[i]?.answer
                                                        ?.latitude
                                                    )
                                                  : 1.3521
                                              }
                                              longitude={
                                                latestAnswers[i]?.answer
                                                  ?.longitude
                                                  ? Number(
                                                      latestAnswers[i]?.answer
                                                        ?.longitude
                                                    )
                                                  : 103.8198
                                              }
                                            />
                                            <NavigationControl position="bottom-right" />
                                            <GeolocateControl
                                              position="top-left"
                                              // trackUserLocation
                                              // onGeolocate={handleGeolocate}
                                            />
                                            {/* <Geocoder
                                              setResult={setResult}
                                              setAddress={setAddress}
                                              setCountry={setCountry}
                                              setState={setState}
                                              setCity={setCity}
                                              setPostal_Code={setPostal_Code}
                                              setLatLng={setLatLng}
                                              /> */}
                                          </ReactMapGL>
                                        </Box>
                                      </Form.Group>
                                    </div>
                                  </div>
                                </Form>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      ) : state?.Details?.plan[0]?.product_type === "Video" ? (
        <div className="cardSection mt-4">
          <div className="d-flex justify-content-between">
            <h1>Self-Generated Video </h1>
          </div>
          <div className="row mx-0 mt-4">
            <div className="col-12 px-0">
              <div className="row gx-5">
                <div className="col-12">
                  <div className="w-100 mb-3 borderDashed border-0 text-center">
                    <video className="w-100" height="240" controls>
                      <source
                        src={state?.Details?.coverVideo}
                        type="video/mp4"
                      />
                    </video>
                    <div className="thumbnail">
                      <h1 className="mb-1 mt-3">Thumbnail</h1>

                      <div className="coverImg mb-3 mt-2">
                        <div className="image-placeholder my-4">
                          {state?.Details?.thumbnails !== [] &&
                            state?.Details?.thumbnails?.map((val) => (
                              <>
                                <div className="avatar-preview">
                                  <div id="imagePreview" key={val.id}>
                                    <img
                                      id="saveImageFile"
                                      src={val.img}
                                      alt="Thumbnail Image"
                                    />
                                  </div>
                                  <Form.Group
                                    className="mb-3"
                                    controlId="formBasicCheckbox"
                                    value={val.cover}
                                  >
                                    <Form.Check
                                      type="checkbox"
                                      label="Cover"
                                      checked={val.cover}
                                    />
                                  </Form.Group>
                                </div>
                              </>
                            ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Purpose</Form.Label>
                <InputGroup className="mb-3">
                  <InputGroup.Text id="basic-addon1">
                    <img src="/imgs/amount.svg" alt="" />
                  </InputGroup.Text>
                  <Form.Control
                    type="text"
                    value={
                      quotationDetail?.plan?.[0]?.pricing_amount ||
                      state?.Details?.Purpose
                    }
                    placeholder="Enter prupose of content"
                  />
                </InputGroup>
              </Form.Group>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Intended audience</Form.Label>
                <InputGroup className="mb-3">
                  <InputGroup.Text id="basic-addon1">
                    <img src="/imgs/amount.svg" alt="" />
                  </InputGroup.Text>
                  <Form.Control
                    type="text"
                    value={state?.Details?.Intended}
                    placeholder="Enter the intended of the content"
                  />
                </InputGroup>
              </Form.Group>
            </div>

            <div className="col-lg-6 col-md-6 col-sm-12">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Request</Form.Label>
                <InputGroup className="mb-3">
                  <InputGroup.Text id="basic-addon1">
                    <img src="/imgs/amount.svg" alt="" />
                  </InputGroup.Text>
                  <Form.Control
                    type="text"
                    value={state?.Details?.Request}
                    placeholder="Enter request"
                  />
                </InputGroup>
              </Form.Group>
            </div>
          </div>
        </div>
      ) : state?.Details?.plan[0]?.pricing_type === "Valued" ? (
        <>
          {/* {state?.Details?.plan[0]?.questionaire?.questions.map((item) => (
              <h1 key={item.id}>{item.question}</h1>
            ))} */}
          <div className="cardSection mt-4">
            {state?.Details?.plan[0]?.questionaire?.questions?.length > 0 ? (
              state?.Details?.plan[0]?.questionaire?.questions?.map(
                (val, i) => (
                  <>
                    <div className="questions" key={i}>
                      <h1>{val?.question}</h1>
                      <div className="answerBox">
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          {val?.answerType === "image" ? (
                            <>
                              <div className="image-placeholder px-3 my-4">
                                {latestAnswers[i]?.answer?.length > 0 &&
                                  latestAnswers[i]?.answer?.map((val) => (
                                    <>
                                      <div className="avatar-preview">
                                        <div id="imagePreview">
                                          <img
                                            id="saveImageFile"
                                            src={val.img}
                                            alt={val.img}
                                          />
                                        </div>
                                      </div>
                                    </>
                                  ))}
                              </div>
                            </>
                          ) : val?.answerType === "address" ? (
                            <>
                              {latestAnswers[i]?.answer !== [] &&
                                latestAnswers[i]?.answer && (
                                  <div className="cardSection mt-4 mb-2">
                                    <div className="row mx-0">
                                      <div className="col-10 mx-auto">
                                        <Form className="mt-5">
                                          <div className="row mx-0">
                                            <div className="col-md-5 px-0">
                                              <Form.Group
                                                className="mb-3"
                                                controlId="formBasicEmail"
                                              >
                                                <Form.Label>
                                                  Country/Island
                                                </Form.Label>
                                                <InputGroup className="mb-3">
                                                  <InputGroup.Text id="basic-addon1">
                                                    <img
                                                      src="/imgs/country.svg"
                                                      alt=""
                                                    />
                                                  </InputGroup.Text>
                                                  <Form.Control
                                                    type="text"
                                                    name="country"
                                                    value={
                                                      state?.Details?.plan[0]
                                                        ?.questionaire?.answers[
                                                        i
                                                      ]?.answer?.country
                                                    }
                                                  />
                                                </InputGroup>
                                              </Form.Group>
                                            </div>
                                            <div className="col-md-2"></div>
                                            <div className="col-md-5 px-0">
                                              <Form.Group
                                                className="mb-3"
                                                controlId="formBasicEmail"
                                              >
                                                <Form.Label>State</Form.Label>
                                                <InputGroup className="mb-3">
                                                  <InputGroup.Text id="basic-addon1">
                                                    <img
                                                      src="/imgs/address.svg"
                                                      alt=""
                                                    />
                                                  </InputGroup.Text>
                                                  <Form.Control
                                                    type="text"
                                                    name="state"
                                                    value={
                                                      state?.Details?.plan[0]
                                                        ?.questionaire?.answers[
                                                        i
                                                      ]?.answer?.state
                                                    }
                                                  />
                                                </InputGroup>
                                              </Form.Group>
                                            </div>
                                            <div className="col-md-5 px-0">
                                              <Form.Group
                                                className="mb-3"
                                                controlId="formBasicEmail"
                                              >
                                                <Form.Label>City</Form.Label>
                                                <InputGroup className="mb-3">
                                                  <InputGroup.Text id="basic-addon1">
                                                    <img
                                                      src="/imgs/address.svg"
                                                      alt=""
                                                    />
                                                  </InputGroup.Text>
                                                  <Form.Control
                                                    type="text"
                                                    name="city"
                                                    value={
                                                      state?.Details?.plan[0]
                                                        ?.questionaire?.answers[
                                                        i
                                                      ]?.answer?.city
                                                    }
                                                  />
                                                </InputGroup>
                                              </Form.Group>
                                            </div>
                                            <div className="col-md-2"></div>
                                            <div className="col-md-5 px-0">
                                              <Form.Group
                                                className="mb-3"
                                                controlId="formBasicEmail"
                                              >
                                                <Form.Label>
                                                  Postal Code
                                                </Form.Label>
                                                <InputGroup className="mb-3">
                                                  <InputGroup.Text id="basic-addon1">
                                                    <img
                                                      src="/imgs/postal_code.svg"
                                                      alt=""
                                                    />
                                                  </InputGroup.Text>
                                                  <Form.Control
                                                    type="text"
                                                    name="postal_code"
                                                    value={
                                                      state?.Details?.plan[0]
                                                        ?.questionaire?.answers[
                                                        i
                                                      ]?.answer?.postCode
                                                    }
                                                  />
                                                </InputGroup>
                                              </Form.Group>
                                            </div>
                                            <div className="col-md-12 px-0">
                                              <Form.Group
                                                className="mb-3"
                                                controlId="formBasicEmail"
                                              >
                                                <Form.Label>Address</Form.Label>
                                                <InputGroup className="mb-3">
                                                  <InputGroup.Text id="basic-addon1">
                                                    <img
                                                      src="/imgs/address.svg"
                                                      alt=""
                                                    />
                                                  </InputGroup.Text>
                                                  <Form.Control
                                                    type="text"
                                                    name="address"
                                                    value={
                                                      state?.Details?.plan[0]
                                                        ?.questionaire?.answers[
                                                        i
                                                      ]?.answer?.address
                                                    }
                                                  />
                                                </InputGroup>
                                              </Form.Group>
                                            </div>
                                            <div className="col-md-12 px-0">
                                              <Form.Group
                                                className="mb-3"
                                                controlId="formBasicEmail"
                                              >
                                                <Form.Label>
                                                  Map Location
                                                </Form.Label>
                                                <Box
                                                  sx={{
                                                    height: 400,
                                                    position: "relative",
                                                  }}
                                                >
                                                  <ReactMapGL
                                                    ref={mapRef}
                                                    mapboxAccessToken="pk.eyJ1IjoiYmx1ZXNpbG9zZyIsImEiOiJjbGZyeDZ1MGswMG1mM2RxcmJyajRzbXRyIn0.SwkzsvT0dgMXSafnYQoZSQ"
                                                    initialViewState={{
                                                      zoom: 11,
                                                      longitude: `${
                                                        state?.Details?.plan[0]
                                                          ?.questionaire
                                                          ?.answers[i]?.answer
                                                          ?.longitude
                                                          ? state?.Details
                                                              ?.plan[0]
                                                              ?.questionaire
                                                              ?.answers[i]
                                                              ?.answer
                                                              ?.longitude
                                                          : lng
                                                      }`,
                                                      latitude: `${
                                                        state?.Details?.plan[0]
                                                          ?.questionaire
                                                          ?.answers[i]?.answer
                                                          ?.latitude
                                                          ? state?.Details
                                                              ?.plan[0]
                                                              ?.questionaire
                                                              ?.answers[i]
                                                              ?.answer?.latitude
                                                          : lat
                                                      }`,
                                                    }}
                                                    mapStyle="mapbox://styles/mapbox/streets-v11"
                                                  >
                                                    <Marker
                                                      latitude={
                                                        state?.Details?.plan[0]
                                                          ?.questionaire
                                                          ?.answers[i]?.answer
                                                          ?.latitude
                                                          ? state?.Details
                                                              ?.plan[0]
                                                              ?.questionaire
                                                              ?.answers[i]
                                                              ?.answer?.latitude
                                                          : lat
                                                      }
                                                      longitude={
                                                        state?.Details?.plan[0]
                                                          ?.questionaire
                                                          ?.answers[i]?.answer
                                                          ?.longitude
                                                          ? state?.Details
                                                              ?.plan[0]
                                                              ?.questionaire
                                                              ?.answers[i]
                                                              ?.answer
                                                              ?.longitude
                                                          : lng
                                                      }
                                                      draggable
                                                      // onDragEnd={(e) => {
                                                      //   handleMarkerChange(
                                                      //     e.lngLat.lat,
                                                      //     e.lngLat.lng,
                                                      //     i,
                                                      //     val.id,
                                                      //     val?.score
                                                      //   );
                                                      // }}
                                                    />
                                                    <NavigationControl position="bottom-right" />
                                                    <GeolocateControl
                                                      position="top-left"
                                                      // trackUserLocation
                                                      // onGeolocate={handleGeolocate}
                                                    />
                                                    <Geocoder
                                                    // setResult={setResult}
                                                    // setAddress={setAddress}
                                                    // setCountry={setCountry}
                                                    // setState={setState}
                                                    // setCity={setCity}
                                                    // setPostal_Code={setPostal_Code}
                                                    // setLatLng={setLatLng}
                                                    />
                                                  </ReactMapGL>
                                                </Box>
                                              </Form.Group>
                                            </div>
                                          </div>
                                        </Form>
                                      </div>
                                    </div>
                                  </div>
                                )}
                            </>
                          ) : (
                            state?.Details?.plan[0]?.questionaire?.answers[i]
                              ?.answer !== "" && (
                              <Form.Control
                                as="textarea"
                                rows={5}
                                value={
                                  state?.Details?.plan[0]?.questionaire
                                    ?.answers[i]?.answer
                                }
                                disabled={
                                  user?.data?.user?.role === "staff" ||
                                  user?.data?.user?.role === "admin"
                                    ? true
                                    : false
                                }
                              />
                            )
                          )}
                        </Form.Group>
                      </div>
                      <div className="my-5"></div>
                    </div>
                  </>
                )
              )
            ) : (
              <div style={{ textAlign: "center" }}>No Question</div>
            )}
          </div>
        </>
      ) : (
        <div className="cardSection mt-4">
          <div className="d-flex justify-content-between">
            <h1>Pricing type is 360 Tour</h1>
          </div>
          <div className="row mx-0 mt-4">
            <div className="col-10 mx-auto">
              <div className="row gx-5">
                {id
                  ? quotationDetail &&
                    quotationDetail?.templateQuestions &&
                    quotationDetail?.templateQuestions?.questions.length > 0 &&
                    quotationDetail?.templateQuestions?.questions.map(
                      (val, i) => (
                        <div className="row mt-3">
                          {/* <div className="col-md-6"> */}
                          <h1>{val?.question}</h1>
                          <div className="answerBox">
                            {val?.answerType === "choice" ? (
                              val?.score?.map((item, index) => (
                                <div key={index}>
                                  <label>
                                    <input
                                      type="radio"
                                      name={`${val?.question}`}
                                      value={item.text}
                                      checked={
                                        item.text == latestAnswers?.[i]?.answer
                                      }
                                    />
                                    {item.text}
                                  </label>
                                </div>
                              ))
                            ) : val?.answerType === "yesNo" &&
                              val?.score !== 0 ? (
                              <Form.Group
                                className="mb-3"
                                controlId="formBasicEmail"
                              >
                                <Form.Control
                                  as="textarea"
                                  rows={5}
                                  value={latestAnswers?.[i]?.answer}
                                  // onChange={(e) =>
                                  //   handleChange(e, i, val.id, val?.score)
                                  // }
                                  disabled={
                                    user?.data?.user?.role === "staff" ||
                                    user?.data?.user?.role === "admin"
                                      ? true
                                      : false
                                  }
                                />
                              </Form.Group>
                            ) : val?.answerType === "image" ? (
                              <>
                                <div className="image-placeholder px-3 my-4">
                                  {latestAnswers?.[i]?.answer?.length > 0 &&
                                    latestAnswers?.[i]?.answer?.map((val) => (
                                      <>
                                        <div className="avatar-preview">
                                          <div id="imagePreview">
                                            <img
                                              id="saveImageFile"
                                              src={val.img}
                                              alt={val.img}
                                            />
                                          </div>
                                        </div>
                                      </>
                                    ))}
                                </div>
                              </>
                            ) : val?.answerType === "address" ? (
                              <>
                                {latestAnswers?.[i]?.answer !== [] &&
                                  latestAnswers?.[i]?.answer && (
                                    <div className="cardSection mt-4 mb-2">
                                      <div className="row mx-0">
                                        <div className="col-10 mx-auto">
                                          <Form className="mt-5">
                                            <div className="row mx-0">
                                              <div className="col-md-5 px-0">
                                                <Form.Group
                                                  className="mb-3"
                                                  controlId="formBasicEmail"
                                                >
                                                  <Form.Label>
                                                    Country/Island
                                                  </Form.Label>
                                                  <InputGroup className="mb-3">
                                                    <InputGroup.Text id="basic-addon1">
                                                      <img
                                                        src="/imgs/country.svg"
                                                        alt=""
                                                      />
                                                    </InputGroup.Text>
                                                    <Form.Control
                                                      type="text"
                                                      name="country"
                                                      value={
                                                        latestAnswers[i]?.answer
                                                          ?.country
                                                      }
                                                    />
                                                  </InputGroup>
                                                </Form.Group>
                                              </div>
                                              <div className="col-md-2"></div>
                                              <div className="col-md-5 px-0">
                                                <Form.Group
                                                  className="mb-3"
                                                  controlId="formBasicEmail"
                                                >
                                                  <Form.Label>State</Form.Label>
                                                  <InputGroup className="mb-3">
                                                    <InputGroup.Text id="basic-addon1">
                                                      <img
                                                        src="/imgs/address.svg"
                                                        alt=""
                                                      />
                                                    </InputGroup.Text>
                                                    <Form.Control
                                                      type="text"
                                                      name="state"
                                                      value={
                                                        latestAnswers[i]?.answer
                                                          ?.state
                                                      }
                                                    />
                                                  </InputGroup>
                                                </Form.Group>
                                              </div>
                                              <div className="col-md-5 px-0">
                                                <Form.Group
                                                  className="mb-3"
                                                  controlId="formBasicEmail"
                                                >
                                                  <Form.Label>City</Form.Label>
                                                  <InputGroup className="mb-3">
                                                    <InputGroup.Text id="basic-addon1">
                                                      <img
                                                        src="/imgs/address.svg"
                                                        alt=""
                                                      />
                                                    </InputGroup.Text>
                                                    <Form.Control
                                                      type="text"
                                                      name="city"
                                                      value={
                                                        latestAnswers[i]?.answer
                                                          ?.city
                                                      }
                                                    />
                                                  </InputGroup>
                                                </Form.Group>
                                              </div>
                                              <div className="col-md-2"></div>
                                              <div className="col-md-5 px-0">
                                                <Form.Group
                                                  className="mb-3"
                                                  controlId="formBasicEmail"
                                                >
                                                  <Form.Label>
                                                    Postal Code
                                                  </Form.Label>
                                                  <InputGroup className="mb-3">
                                                    <InputGroup.Text id="basic-addon1">
                                                      <img
                                                        src="/imgs/postal_code.svg"
                                                        alt=""
                                                      />
                                                    </InputGroup.Text>
                                                    <Form.Control
                                                      type="text"
                                                      name="postal_code"
                                                      value={
                                                        latestAnswers[i]?.answer
                                                          ?.postCode
                                                      }
                                                    />
                                                  </InputGroup>
                                                </Form.Group>
                                              </div>
                                              <div className="col-md-12 px-0">
                                                <Form.Group
                                                  className="mb-3"
                                                  controlId="formBasicEmail"
                                                >
                                                  <Form.Label>
                                                    Address
                                                  </Form.Label>
                                                  <InputGroup className="mb-3">
                                                    <InputGroup.Text id="basic-addon1">
                                                      <img
                                                        src="/imgs/address.svg"
                                                        alt=""
                                                      />
                                                    </InputGroup.Text>
                                                    <Form.Control
                                                      type="text"
                                                      name="address"
                                                      value={
                                                        latestAnswers[i]?.answer
                                                          ?.address
                                                      }
                                                    />
                                                  </InputGroup>
                                                </Form.Group>
                                              </div>
                                              <div className="col-md-12 px-0">
                                                <Form.Group
                                                  className="mb-3"
                                                  controlId="formBasicEmail"
                                                >
                                                  <Form.Label>
                                                    Map Location
                                                  </Form.Label>
                                                  <Box
                                                    sx={{
                                                      height: 400,
                                                      position: "relative",
                                                    }}
                                                  >
                                                    <ReactMapGL
                                                      ref={mapRef}
                                                      mapboxAccessToken="pk.eyJ1IjoiYmx1ZXNpbG9zZyIsImEiOiJjbGZyeDZ1MGswMG1mM2RxcmJyajRzbXRyIn0.SwkzsvT0dgMXSafnYQoZSQ"
                                                      initialViewState={{
                                                        zoom: 11,
                                                        longitude: `${
                                                          latestAnswers[i]
                                                            ?.answer?.longitude
                                                            ? latestAnswers[i]
                                                                ?.answer
                                                                ?.longitude
                                                            : lng
                                                        }`,
                                                        latitude: `${
                                                          latestAnswers[i]
                                                            ?.answer?.latitude
                                                            ? latestAnswers[i]
                                                                ?.answer
                                                                ?.latitude
                                                            : lat
                                                        }`,
                                                      }}
                                                      mapStyle="mapbox://styles/mapbox/streets-v11"
                                                    >
                                                      <Marker
                                                        latitude={
                                                          latestAnswers[i]
                                                            ?.answer?.latitude
                                                            ? latestAnswers[i]
                                                                ?.answer
                                                                ?.latitude
                                                            : lat
                                                        }
                                                        longitude={
                                                          latestAnswers[i]
                                                            ?.answer?.longitude
                                                            ? latestAnswers[i]
                                                                ?.answer
                                                                ?.longitude
                                                            : lng
                                                        }
                                                        draggable
                                                        // onDragEnd={(e) => {
                                                        //   handleMarkerChange(
                                                        //     e.lngLat.lat,
                                                        //     e.lngLat.lng,
                                                        //     i,
                                                        //     val.id,
                                                        //     val?.score
                                                        //   );
                                                        // }}
                                                      />
                                                      <NavigationControl position="bottom-right" />
                                                      <GeolocateControl
                                                        position="top-left"
                                                        // trackUserLocation
                                                        // onGeolocate={handleGeolocate}
                                                      />
                                                      {/* <Geocoder
                                                      setResult={setResult}
                                                      setAddress={setAddress}
                                                      setCountry={setCountry}
                                                      setState={setState}
                                                      setCity={setCity}
                                                      setPostal_Code={setPostal_Code}
                                                      setLatLng={setLatLng}
                                                      /> */}
                                                    </ReactMapGL>
                                                  </Box>
                                                </Form.Group>
                                              </div>
                                            </div>
                                          </Form>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                              </>
                            ) : (
                              ""
                            )}
                          </div>
                          {/* </div> */}
                        </div>
                      )
                    )
                  : state?.Details &&
                    state?.Details?.templateQuestions &&
                    state?.Details?.templateQuestions?.questions.length > 0 &&
                    state?.Details?.templateQuestions?.questions.map(
                      (val, i) => (
                        <div className="row mt-3">
                          {/* <div className="col-md-6"> */}
                          <h1>{val?.question}</h1>
                          <div className="answerBox">
                            {val?.answerType === "choice" ? (
                              val?.score?.map((item, index) => (
                                <div key={index}>
                                  <label>
                                    <input
                                      type="radio"
                                      name={`${val?.question}`}
                                      value={item.text}
                                      checked={
                                        item.text == latestAnswers[i]?.answer
                                      }
                                    />
                                    {item.text}
                                  </label>
                                </div>
                              ))
                            ) : val?.answerType === "yesNo" &&
                              val?.score !== 0 ? (
                              <Form.Group
                                className="mb-3"
                                controlId="formBasicEmail"
                              >
                                <Form.Control
                                  as="textarea"
                                  rows={5}
                                  value={latestAnswers[i]?.answer}
                                  // onChange={(e) =>
                                  //   handleChange(e, i, val.id, val?.score)
                                  // }
                                  disabled={
                                    user?.data?.user?.role === "staff" ||
                                    user?.data?.user?.role === "admin"
                                      ? true
                                      : false
                                  }
                                />
                              </Form.Group>
                            ) : val?.answerType === "image" ? (
                              <>
                                <div className="image-placeholder px-3 my-4">
                                  {latestAnswers[i]?.answer?.length > 0 &&
                                    latestAnswers[i]?.answer?.map((val) => (
                                      <>
                                        <div className="avatar-preview">
                                          <div id="imagePreview">
                                            <img
                                              id="saveImageFile"
                                              src={val.img}
                                              alt={val.img}
                                            />
                                          </div>
                                        </div>
                                      </>
                                    ))}
                                </div>
                              </>
                            ) : val?.answerType === "address" ? (
                              <>
                                {latestAnswers[i]?.answer !== [] &&
                                  latestAnswers[i]?.answer && (
                                    <div className="cardSection mt-4 mb-2">
                                      <div className="row mx-0">
                                        <div className="col-10 mx-auto">
                                          <Form className="mt-5">
                                            <div className="row mx-0">
                                              <div className="col-md-5 px-0">
                                                <Form.Group
                                                  className="mb-3"
                                                  controlId="formBasicEmail"
                                                >
                                                  <Form.Label>
                                                    Country/Island
                                                  </Form.Label>
                                                  <InputGroup className="mb-3">
                                                    <InputGroup.Text id="basic-addon1">
                                                      <img
                                                        src="/imgs/country.svg"
                                                        alt=""
                                                      />
                                                    </InputGroup.Text>
                                                    <Form.Control
                                                      type="text"
                                                      name="country"
                                                      value={
                                                        latestAnswers[i]?.answer
                                                          ?.country
                                                      }
                                                    />
                                                  </InputGroup>
                                                </Form.Group>
                                              </div>
                                              <div className="col-md-2"></div>
                                              <div className="col-md-5 px-0">
                                                <Form.Group
                                                  className="mb-3"
                                                  controlId="formBasicEmail"
                                                >
                                                  <Form.Label>State</Form.Label>
                                                  <InputGroup className="mb-3">
                                                    <InputGroup.Text id="basic-addon1">
                                                      <img
                                                        src="/imgs/address.svg"
                                                        alt=""
                                                      />
                                                    </InputGroup.Text>
                                                    <Form.Control
                                                      type="text"
                                                      name="state"
                                                      value={
                                                        latestAnswers[i]?.answer
                                                          ?.state
                                                      }
                                                    />
                                                  </InputGroup>
                                                </Form.Group>
                                              </div>
                                              <div className="col-md-5 px-0">
                                                <Form.Group
                                                  className="mb-3"
                                                  controlId="formBasicEmail"
                                                >
                                                  <Form.Label>City</Form.Label>
                                                  <InputGroup className="mb-3">
                                                    <InputGroup.Text id="basic-addon1">
                                                      <img
                                                        src="/imgs/address.svg"
                                                        alt=""
                                                      />
                                                    </InputGroup.Text>
                                                    <Form.Control
                                                      type="text"
                                                      name="city"
                                                      value={
                                                        latestAnswers[i]?.answer
                                                          ?.city
                                                      }
                                                    />
                                                  </InputGroup>
                                                </Form.Group>
                                              </div>
                                              <div className="col-md-2"></div>
                                              <div className="col-md-5 px-0">
                                                <Form.Group
                                                  className="mb-3"
                                                  controlId="formBasicEmail"
                                                >
                                                  <Form.Label>
                                                    Postal Code
                                                  </Form.Label>
                                                  <InputGroup className="mb-3">
                                                    <InputGroup.Text id="basic-addon1">
                                                      <img
                                                        src="/imgs/postal_code.svg"
                                                        alt=""
                                                      />
                                                    </InputGroup.Text>
                                                    <Form.Control
                                                      type="text"
                                                      name="postal_code"
                                                      value={
                                                        latestAnswers[i]?.answer
                                                          ?.postCode
                                                      }
                                                    />
                                                  </InputGroup>
                                                </Form.Group>
                                              </div>
                                              <div className="col-md-12 px-0">
                                                <Form.Group
                                                  className="mb-3"
                                                  controlId="formBasicEmail"
                                                >
                                                  <Form.Label>
                                                    Address
                                                  </Form.Label>
                                                  <InputGroup className="mb-3">
                                                    <InputGroup.Text id="basic-addon1">
                                                      <img
                                                        src="/imgs/address.svg"
                                                        alt=""
                                                      />
                                                    </InputGroup.Text>
                                                    <Form.Control
                                                      type="text"
                                                      name="address"
                                                      value={
                                                        latestAnswers[i]?.answer
                                                          ?.address
                                                      }
                                                    />
                                                  </InputGroup>
                                                </Form.Group>
                                              </div>
                                              <div className="col-md-12 px-0">
                                                <Form.Group
                                                  className="mb-3"
                                                  controlId="formBasicEmail"
                                                >
                                                  <Form.Label>
                                                    Map Location
                                                  </Form.Label>
                                                  <Box
                                                    sx={{
                                                      height: 400,
                                                      position: "relative",
                                                    }}
                                                  >
                                                    <ReactMapGL
                                                      ref={mapRef}
                                                      mapboxAccessToken="pk.eyJ1IjoiYmx1ZXNpbG9zZyIsImEiOiJjbGZyeDZ1MGswMG1mM2RxcmJyajRzbXRyIn0.SwkzsvT0dgMXSafnYQoZSQ"
                                                      initialViewState={{
                                                        zoom: 11,
                                                        longitude: `${
                                                          latestAnswers[i]
                                                            ?.answer?.longitude
                                                            ? latestAnswers[i]
                                                                ?.answer
                                                                ?.longitude
                                                            : lng
                                                        }`,
                                                        latitude: `${
                                                          latestAnswers[i]
                                                            ?.answer?.latitude
                                                            ? latestAnswers[i]
                                                                ?.answer
                                                                ?.latitude
                                                            : lat
                                                        }`,
                                                      }}
                                                      mapStyle="mapbox://styles/mapbox/streets-v11"
                                                    >
                                                      <Marker
                                                        latitude={
                                                          latestAnswers[i]
                                                            ?.answer?.latitude
                                                            ? latestAnswers[i]
                                                                ?.answer
                                                                ?.latitude
                                                            : lat
                                                        }
                                                        longitude={
                                                          latestAnswers[i]
                                                            ?.answer?.longitude
                                                            ? latestAnswers[i]
                                                                ?.answer
                                                                ?.longitude
                                                            : lng
                                                        }
                                                        draggable
                                                        // onDragEnd={(e) => {
                                                        //   handleMarkerChange(
                                                        //     e.lngLat.lat,
                                                        //     e.lngLat.lng,
                                                        //     i,
                                                        //     val.id,
                                                        //     val?.score
                                                        //   );
                                                        // }}
                                                      />
                                                      <NavigationControl position="bottom-right" />
                                                      <GeolocateControl
                                                        position="top-left"
                                                        // trackUserLocation
                                                        // onGeolocate={handleGeolocate}
                                                      />
                                                      {/* <Geocoder
                                                      setResult={setResult}
                                                      setAddress={setAddress}
                                                      setCountry={setCountry}
                                                      setState={setState}
                                                      setCity={setCity}
                                                      setPostal_Code={setPostal_Code}
                                                      setLatLng={setLatLng}
                                                      /> */}
                                                    </ReactMapGL>
                                                  </Box>
                                                </Form.Group>
                                              </div>
                                            </div>
                                          </Form>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                              </>
                            ) : (
                              ""
                            )}
                          </div>
                          {/* </div> */}
                        </div>
                      )
                    )}
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="text-end mt-3">
        <Button
          className="loginBtn"
          onClick={
            state?.Details?.plan[0]?.product_type === "360 tour"
              ? () => AddNewTour(state)
              : () => AddNewArticle(state)
          }
        >
          Create
        </Button>
      </div>
    </div>
  );
}

export default OrderCommisionDetail;
