import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateOrder } from "../../features/navigations/navSlice";

function Dragable({ EditData, DeleteNav, setData, data }) {
  const dispatch = useDispatch();

  const dragItem = useRef();
  const dragOverItem = useRef();

  const dragStart = (e, position) => {
    dragItem.current = position;
  };

  const dragEnter = (e, position) => {
    dragOverItem.current = position;
  };

  const drop = (e) => {
    const copyListItems = [...data];
    const dragItemContent = copyListItems[dragItem.current];
    copyListItems.splice(dragItem.current, 1);
    copyListItems.splice(dragOverItem.current, 0, dragItemContent);
    dragItem.current = null;
    dragOverItem.current = null;
    setData(copyListItems);
    dispatch(updateOrder({ navData: copyListItems }));
  };
  console.log(data);
  return (
    <>
      <ul>
        {data &&
          data?.map((item, index) => (
            <li
              key={index}
              // onDragStart={(e) => dragStart(e, index)}
              // onDragEnter={(e) => dragEnter(e, index)}
              // onDragEnd={drop}
              // draggable
            >
              <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex gap-4">
                  <img src="/imgs/dots.svg" alt="" />
                  {item.name ? item.name : item.word}
                </div>

                <div className="d-flex gap-3 me-3">
                  {item.name && (
                    <img
                      src="/imgs/t_edit.svg"
                      width={20}
                      alt=""
                      onClick={() => EditData(item)}
                    />
                  )}
                  {item.isRevoked ? (
                    <img
                      src="/imgs/delete.svg"
                      width={20}
                      alt=""
                      onClick={() =>
                        DeleteNav(item._id, item?.isRevoked, index)
                      }
                    />
                  ) : (
                    <img
                      src="/imgs/revoke.svg"
                      width={20}
                      alt=""
                      onClick={() => DeleteNav(item._id, item?.isRevoked)}
                    />
                  )}
                </div>
                {/* <img src="/imgs/upDown.svg" alt="" /> */}
              </div>
              <a href="#"></a>
            </li>
          ))}
      </ul>
    </>
  );
}
// }
export default Dragable;
