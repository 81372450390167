import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import productService from './productService'

// Get user from localStorage
// const contrib = JSON.parse(localStorage.getItem('Users'))

const initialState = {
  product_plans: [],
  paymentData: [],
  isAdded: false,
  isUpdated: false,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
}


export const getPlans = createAsyncThunk(
  'plan/getPlans',
  async (thunkAPI) => {
    try {
      return await productService.plansList()
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const paymentList = createAsyncThunk(
  'plan/paymentList',
  async (thunkAPI) => {
    try {
      return await productService.paymentList()
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const addPlans = createAsyncThunk(
  'plan/addPlans',
  async (userData,thunkAPI) => {
    try {
      return await productService.addPlansData(userData)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const addPayment = createAsyncThunk(
  'plan/addPayment',
  async (userData,thunkAPI) => {
    try {
      return await productService.addPayment(userData)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)


export const editPlans = createAsyncThunk(
  'plan/editPlans',
  async (userData,thunkAPI) => {
    try {
      return await productService.editPlansData(userData)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const editPayment = createAsyncThunk(
  'plan/editPayment',
  async (userData,thunkAPI) => {
    try {
      return await productService.editPayment(userData)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const deletePlans = createAsyncThunk(
  'plan/deletePlans',
  async (ID,thunkAPI) => {
    try {
      return await productService.deletePlansData(ID)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

// deleted rows
export const deletedRowsP = createAsyncThunk('plan/deletedRowsP', async (passData, thunkAPI) => {
  try {
    return await productService.deletedRowsP(passData)
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

export const productSlice = createSlice({
  name: 'plan',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false
      state.isSuccess = false
      state.isError = false
      state.isAdded = false
      state.isUpdated =  false
      state.message = ''
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPlans.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getPlans.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        // state.message = action.payload.data.message
        state.product_plans = action.payload.data 
      })
      .addCase(getPlans.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload.data.message
        state.product_plans = []
      })
      
      .addCase(paymentList.pending, (state) => {
        state.isLoading = true
      })
      .addCase(paymentList.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        // state.message = action.payload
        state.paymentData = {...action.payload.data[0]}
      })
      .addCase(paymentList.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
        state.paymentData = []
      })

      .addCase(addPlans.pending, (state) => {
        state.isLoading = true
      })
      .addCase(addPlans.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.message = action.payload.message
        state.isAdded = true
        // state.product_plans = [...state.product_plans, action.payload.data] 
      })
      .addCase(addPlans.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
        state.product_plans = null
      })
      
      .addCase(addPayment.pending, (state) => {
        state.isLoading = true
      })
      .addCase(addPayment.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.message = action.payload
        state.isAdded = true
        // state.product_plans = [...state.product_plans, action.payload.data] 
      })
      .addCase(addPayment.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
        state.paymentData = null
      })

      .addCase(editPlans.pending, (state) => {
        state.isLoading = true
      })
      .addCase(editPlans.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.message =  action.payload.message
        state.isUpdated = true
        state.product_plans = action.payload.data 
      })
      .addCase(editPlans.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
        state.isUpdated = false
        state.product_plans = []
      })
      
      .addCase(editPayment.pending, (state) => {
        state.isLoading = true
      })
      .addCase(editPayment.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.message =  action.payload
        state.isUpdated = true
        state.paymentData = {...action.payload.data[0]} 
      })
      .addCase(editPayment.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
        state.isUpdated = false
        state.paymentData = []
      })

      .addCase(deletePlans.pending, (state) => {
        state.isLoading = true
      })
      .addCase(deletePlans.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.message = action.payload
        state.product_plans = action.payload.data 
      })
      .addCase(deletePlans.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
        state.product_plans = null
      })

      .addCase(deletedRowsP.pending, (state) => {
        state.isLoading = true
      })
      .addCase(deletedRowsP.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.isError = false
        // state.user = action.payload
        state.message = action.payload.data
      })
      .addCase(deletedRowsP.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.isSuccess = false
        state.message = action.payload
        // state.user = null
      })
  },
})

export const { reset } = productSlice.actions
export default productSlice.reducer