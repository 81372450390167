import { Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";

const PostRequirements = () => {
  const navigate = useNavigate();
  // const handleShow = () => setShow(true);
  // const handleClose = () => {
  //   setTitle("");
  //   setBody("");
  //   setShow(false);
  //   setEdit(false);
  // };

  // const AddNav = async () => {
  //   const navData = {
  //     title,
  //     body,
  //   };
  //   const result = await dispatch(addPostRequirement(navData));
  //   setTitle("");
  //   setBody("");
  //   setShow(false);
  //   dispatch(getPostRequirements());
  //   if (!result.error) {
  //     toast.success("Post requirement Added Successfully!");
  //   } else {
  //     toast.error(result.payload);
  //   }
  // };

  // const UpdateNav = async () => {
  //   const navData = {
  //     Id,
  //     title,
  //     body,
  //   };
  //   const result = await dispatch(updatePostRequirement(navData));
  //   setTitle("");
  //   setBody("");
  //   if (!result.error) {
  //     dispatch(getPostRequirements());
  //     toast.success("Navigation Edited Successfully!");
  //   } else {
  //     dispatch(getPostRequirements());
  //     toast.error("Unable to update");
  //   }
  //   setShow(false);
  //   setEdit(false);
  // };
  // const EditData = (val) => {
  //   console.log(val);
  //   setEdit(true);
  //   setShow(true);
  //   setId(val._id);
  //   setTitle(val.title);
  //   setBody(val.body);
  // };
  // const deleteItem = (id) => {
  //   if (window.confirm("are you sure, you want to delete item?")) {
  //     dispatch(deletePostRequirement(id));
  //     setTimeout(() => dispatch(getPostRequirements()), 500);
  //   }
  //   return;
  // };
  // useEffect(() => {
  //   dispatch(getPostRequirements());
  // }, []);
  return (
    <div className="staffMainDiv">
      <ToastContainer />
      <button
        className="loginBtn"
        style={{ marginBottom: "10px" }}
        onClick={() => navigate(-1)}
      >
        Go back
      </button>
      <div className="pageManagement">
        <div className="row">
          <div className="col-md-4">
            <Link to="/settings/vetting/article">
              <div className="block">
                <h1>Article</h1>
              </div>
            </Link>
          </div>
          <div className="col-md-4">
            <Link to="/settings/vetting/tour">
              <div className="block">
                <h1>Tour</h1>
              </div>
            </Link>
          </div>
        </div>
      </div>

      {/* {postRequirements?.length > 0 &&
        postRequirements.map((item, index) => {
          return (
            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey={`${index}`}>
                <div className="button-wrapper">
                  <img
                    src="/imgs/t_edit.svg"
                    alt=""
                    onClick={() => EditData(item)}
                  />
                  <img
                    src="/imgs/delete.svg"
                    width={20}
                    alt=""
                    onClick={() => deleteItem(item._id)}
                  />
                </div>
                <Accordion.Header>{item.title}</Accordion.Header>
                <Accordion.Body>{item.body}</Accordion.Body>
              </Accordion.Item>
            </Accordion>
          );
        })}

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Body>
          <div className="d-flex justify-content-between mb-3"></div>
          <div className="row gx-5">
            <div className="col-md-12">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Title</Form.Label>
                <InputGroup className="mb-3">
                  <InputGroup.Text id="basic-addon1">
                    <img src="/imgs/u_user.svg" alt="" />
                  </InputGroup.Text>
                  <Form.Control
                    type="text"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </InputGroup>
              </Form.Group>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Error Message</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  value={body}
                  onChange={(e) => setBody(e.target.value)}
                />
              </Form.Group>
            </div>
          </div>

          <div className="d-sm-flex justify-content-center text-center gap-4">
            {Edit == true ? (
              <>
                <Button
                  className="cancelBtn me-sm-0 me-3 mb-sm-0 mb-2"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
                <Button className="loginBtn me-sm-0 me-3" onClick={UpdateNav}>
                  Update
                </Button>
              </>
            ) : (
              <>
                <Button
                  className="cancelBtn me-sm-0 me-3 mb-sm-0 mb-2"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
                <Button
                  className="loginBtn me-sm-0 me-3"
                  disabled={(title && body) === "" ? true : false}
                  onClick={AddNav}
                >
                  Save
                </Button>
              </>
            )}
          </div>
        </Modal.Body>
      </Modal> */}
    </div>
  );
};

export default PostRequirements;
