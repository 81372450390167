import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import quotationService from "./quotationService";

// Get user from localStorage
// const contrib = JSON.parse(localStorage.getItem('Users'))

const initialState = {
  quotation_List: [],
  quotationDetail: {},
  isError: false,
  isSuccess: false,
  isSuccess1: false,
  isLoading: false,
  message: "",
  isList: false,
};

// get question list
export const getQuotation = createAsyncThunk(
  "quotation/getQuotation",
  async (thunkAPI) => {
    try {
      return await quotationService.getQuotation();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const addQuotation = createAsyncThunk(
  "quotation/addQuotation",
  async (userData, thunkAPI) => {
    try {
      return await quotationService.addQuotation(userData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// export const getDetails = createAsyncThunk(
//   'question/getDetails',
//   async (ID,thunkAPI) => {
//     try {
//       return await questionService.getDetails(ID)
//     } catch (error) {
//       const message =
//         (error.response &&
//           error.response.data &&
//           error.response.data.message) ||
//         error.message ||
//         error.toString()
//       return thunkAPI.rejectWithValue(message)
//     }
//   }
// )

export const editQuotation = createAsyncThunk(
  "quotation/editQuotation",
  async (userData, thunkAPI) => {
    try {
      return await quotationService.editQuotation(userData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const deleteQuotation = createAsyncThunk(
  "question/deleteQuotation",
  async (ID, thunkAPI) => {
    try {
      return await quotationService.deleteQuotation(ID);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// // deleted rows
// export const deletedRowsQuest = createAsyncThunk('question/deletedRowsQuest', async (passData, thunkAPI) => {
//   try {
//     return await questionService.deletedRowsQuest(passData)
//   } catch (error) {
//     const message =
//       (error.response && error.response.data && error.response.data.message) ||
//       error.message ||
//       error.toString()
//     return thunkAPI.rejectWithValue(message)
//   }
// })

// get question detail
export const getQuotationDetail = createAsyncThunk(
  "quotation/getQuotationDetail",
  async (ID, thunkAPI) => {
    try {
      return await quotationService.getQuotationDetail(ID);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const quotationSlice = createSlice({
  name: "quotation",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isSuccess1 = false;

      state.isError = false;
      state.message = "";
      state.isList = false;
      state.quotation_List = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getQuotation.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getQuotation.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isList = true;
        // state.message = action.payload.data.message
        state.quotation_List = action.payload.data;
      })
      .addCase(getQuotation.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.quotation_List = [];
      })

      //   .addCase(getDetails.pending, (state) => {
      //     state.isLoading = true
      //   })
      //   .addCase(getDetails.fulfilled, (state, action) => {
      //     state.isLoading = false
      //     state.isSuccess = true
      //     state.isList = true
      //     // state.message = action.payload.data.message
      //     state.questions_List = action.payload.data
      //   })
      //   .addCase(getDetails.rejected, (state, action) => {
      //     state.isLoading = false
      //     state.isError = true
      //     state.message = action.payload
      //     state.questions_List = []
      //   })

      .addCase(addQuotation.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addQuotation.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = "Added!";
        // state.Staff_List = [...state.questions_List, action.payload.data]
      })
      .addCase(addQuotation.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.quotation_List = [];
      })

      .addCase(editQuotation.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(editQuotation.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isSuccess1 = true;
        state.message = "Updated!";
        // state.questions_List = action.payload.data
      })
      .addCase(editQuotation.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.quotation_List = [];
      })
      .addCase(deleteQuotation.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteQuotation.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = "Deleted!";
        // state.Staff_List = action.payload.data
      })
      .addCase(deleteQuotation.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.questions_List = [];
      })

      //   .addCase(deletedRowsQuest.pending, (state) => {
      //     state.isLoading = true
      //   })
      //   .addCase(deletedRowsQuest.fulfilled, (state, action) => {
      //     state.isLoading = false
      //     state.isSuccess = true
      //     state.isError = false
      //     // state.user = action.payload
      //     state.message = action.payload
      //   })
      //   .addCase(deletedRowsQuest.rejected, (state, action) => {
      //     state.isLoading = false
      //     state.isError = true
      //     state.isSuccess = false
      //     state.message = action.payload
      //     // state.user = null
      //   })
      .addCase(getQuotationDetail.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getQuotationDetail.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        // state.message = action.payload.data.message
        state.quotationDetail = action.payload.data;
      })
      .addCase(getQuotationDetail.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.quotationDetail = [];
      });
  },
});

export const { reset } = quotationSlice.actions;
export default quotationSlice.reducer;
