import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Accordion from "react-bootstrap/Accordion";
import Chart from "react-apexcharts";
import LineChart from "../../../Chart/LineChart";
import Data from "../../../../utils/data/reviewrsData.json";
import { useNavigate, useParams } from "react-router-dom";
import { useLayoutEffect } from "react";
import { useDispatch } from "react-redux";
import {
  createReview,
  getArticleDetail,
  reset,
} from "../../../../features/Articles/articleSlice";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { Box, Rating, Stack } from "@mui/material";
import { Modal } from "react-bootstrap";
import StarIcon from "@mui/icons-material/Star";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
import { toast, ToastContainer } from "react-toastify";
import { formatDate } from "../../../../utils/DateHelper";
import moment from "moment";
import tzOffset from "tz-offset";
import { getFrontend } from "../../../../features/Frontend/frontendSlice";

function ArticleDetail() {
  const { id } = useParams();

  const user = JSON.parse(localStorage.getItem("user"));
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    article_Detail,
    isError,
    isSuccess,
    message,
    isList,
    imageLocation,
    review,
  } = useSelector((state) => state.article);
  const { frontData } = useSelector((state) => state.front_Data);

  const [ViewAll, setViewAll] = useState(3);
  const [View, setView] = useState("View All");
  const [showModel, setShowModel] = useState(false);
  const [rating, setRating] = useState(0);
  const [name, setName] = useState("");
  const [comment, setComment] = useState("");
  const CloseModal = () => {
    setShowModel(false);
  };

  const reviewLength = article_Detail?.reviews?.length;
  const ViewAllData = () => {
    if (ViewAll === 3) {
      setView("View Less");
      setViewAll(reviewLength);
    } else {
      setView("View All");
      setViewAll(3);
    }
    // setViewAll('300px')
  };

  const submitHandler1 = () => {
    if (comment && rating) {
      dispatch(
        createReview({
          ID: id,
          rating,
          comment,
          user: user?.data?.user,
        })
      );

      setRating(0);
      setName("");
      setComment("");
      setShowModel(false);
    }
  };

  useLayoutEffect(() => {
    dispatch(reset());
    // dispatch(getArticleDetail(id));
    dispatch(getFrontend());
  }, []);

  useEffect(() => {
    dispatch(getArticleDetail(id));
    if (isSuccess == true && review == true && message !== "") {
      toast.success(message);
      setTimeout(() => {
        dispatch(reset());
      }, 5000);
    }
    if (isError == true && review == false && message !== "") {
      toast.error(message);
      setTimeout(() => {
        dispatch(reset());
      }, 5000);
    }
  }, [isError, isSuccess, message, id, review]);
  return (
    <div>
      <ToastContainer />
      <button
        className="loginBtn"
        style={{ marginBottom: "10px" }}
        onClick={() => navigate(-1)}
      >
        Go back
      </button>
      <div className="cardSection articleDetail py-5">
        <div className="row">
          <div className="col-md-6">
            <p>Article Title: {article_Detail?.title}</p>
          </div>
          <div className="col-md-6">
            <p>
              Author:{" "}
              {article_Detail?.owner
                ? article_Detail?.owner.first_name
                : "No Author"}
            </p>
          </div>
          <div className="col-md-6 mt-4">
            <p>Status: {article_Detail?.publish_type}</p>
          </div>
          <div className="col-md-6 mt-4">
            <p>
              Submit at:{" "}
              {article_Detail?.createdAt
                ? `${formatDate(
                    article_Detail.createdAt,
                    frontData.timezone
                  )} / ${frontData.timezone}`
                : ""}
            </p>
          </div>

          <div className="col-md-6 mt-4">
            <p>
              Approve at:{" "}
              {article_Detail?.approvedAt
                ? formatDate(article_Detail?.approvedAt.substring(0, 10)) +
                  " / " +
                  // article_Detail?.approvedAt.substring(11, 19)
                  moment(
                    tzOffset.timeAt(
                      new Date(article_Detail?.approvedAt),
                      "Singapore"
                    )
                  ).format("HH:mm:ss")
                : ""}
            </p>
          </div>
          <div className="col-md-6 mt-4">
            <p>Published Type: {article_Detail?.publish_type}</p>
          </div>
          <div className="col-md-6 mt-4">
            <p>
              Publish at:{" "}
              {article_Detail.publishedAt
                ? formatDate(article_Detail?.publishedAt.substring(0, 10)) +
                  " / " +
                  // article_Detail?.publishedAt.substring(11, 19)
                  moment(
                    tzOffset.timeAt(
                      new Date(article_Detail?.publishedAt),
                      "Singapore"
                    )
                  ).format("HH:mm:ss")
                : ""}
            </p>
          </div>
          <div className="col-md-12 mt-4">
            <p className="d-flex gap-3">
              Tags:{" "}
              <div className="d-flex flex-wrap">
                {article_Detail?.tags?.map((val, i) => (
                  <div className="tag" key={i} value={val.id}>
                    {val.text}
                  </div>
                ))}
              </div>
            </p>
          </div>
        </div>
      </div>
      <div className="cardSection articleDetail py-5 mt-4">
        <Accordion defaultActiveKey="0">
          <Accordion.Item eventKey="0">
            <Accordion.Header>Performance</Accordion.Header>
            <Accordion.Body>
              <div className="mixed-chart">
                <LineChart />
              </div>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>Reviews</Accordion.Header>
            <Accordion.Body>
              <div className="ratingReviews">
                <div className="d-flex justify-content-between align-items-start gap-4 p-3">
                  <div className="d-sm-flex gap-4">
                    <Form.Group className="" controlId="formBasicEmail">
                      <InputGroup
                        className=""
                        style={{ background: "#F1F1F1" }}
                      >
                        <InputGroup.Text id="basic-addon1">
                          <Stack spacing={1}>
                            {/* <img src="/imgs/star-gray.svg" alt="" /> */}
                            <Rating
                              name="half-rating"
                              value={article_Detail?.rating}
                              precision={0.5}
                              readOnly
                            />
                          </Stack>
                        </InputGroup.Text>
                        <Form.Control
                          aria-label="Default select example"
                          disabled={true}
                          value={
                            article_Detail?.rating
                              ? `${parseInt(article_Detail?.rating)} Stars`
                              : "0 Stars"
                          }
                        />
                      </InputGroup>
                    </Form.Group>
                    <p className="d-flex gap-2 mt-sm-0 mt-3">
                      <span>
                        {" "}
                        {article_Detail?.rating
                          ? Math.round(article_Detail?.rating * 10) / 10
                          : 0}
                      </span>
                      <div>
                        Total Reviews <br />{" "}
                        {article_Detail?.review ? article_Detail?.review : 0}
                      </div>
                    </p>
                  </div>
                  <div>
                    <Button
                      className="btn btn-secondary"
                      style={{ width: "auto" }}
                      onClick={() => setShowModel(true)}
                    >
                      Add Review
                    </Button>
                    &nbsp;
                    <Button
                      className="loginBtn"
                      style={{ width: "auto" }}
                      onClick={ViewAllData}
                    >
                      {View}
                    </Button>
                  </div>
                </div>
              </div>
              <Modal
                show={showModel}
                onHide={CloseModal}
                backdrop="static"
                keyboard={false}
              >
                <Modal.Header closeButton>
                  <Modal.Title>Rating & Reviews</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div>
                    <div>
                      <Box>
                        <Rating
                          name="simple-controlled"
                          value={rating}
                          size="large"
                          onChange={(event, newValue) => {
                            setRating(newValue);
                          }}
                          sx={{
                            "& .MuiRating-iconFilled": {
                              color: "orange",
                            },
                          }}
                          icon={
                            <StarIcon
                              style={{ width: "30px", height: "30px" }}
                            ></StarIcon>
                          }
                          emptyIcon={
                            <StarOutlineIcon
                              style={{ width: "30px", height: "30px" }}
                            ></StarOutlineIcon>
                          }
                        />
                      </Box>
                    </div>
                    <br />
                    <div>
                      <input
                        type={"text"}
                        className="form-control"
                        placeholder="Add Name"
                        value={user?.data?.user?.user_name}
                      />
                    </div>
                    <br />
                    <div>
                      <textarea
                        className="form-control"
                        rows="4"
                        value={comment}
                        placeholder="Write Comments here..."
                        onChange={(e) => setComment(e.target.value)}
                      ></textarea>
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={CloseModal}>
                    Close
                  </Button>
                  <Button
                    variant="primary"
                    onClick={submitHandler1}
                    disabled={(rating && comment) === "" ? true : false}
                  >
                    Submit
                  </Button>
                </Modal.Footer>
              </Modal>
              {article_Detail?.reviews?.slice(0, ViewAll).map((item, index) => {
                return (
                  <div
                    className="d-flex gap-3 reviewrsList align-items-center"
                    key={index}
                  >
                    <img
                      src={
                        "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_640.png"
                      }
                      alt=""
                    />
                    <div className="data">
                      <div className="d-flex gap-5">
                        <h3 className="name">{item.name}</h3>
                        <Stack spacing={1}>
                          <Rating
                            name="half-rating"
                            value={item?.rating}
                            precision={0.5}
                            readOnly
                          />
                        </Stack>
                      </div>
                      <p className="description">{item.comment}</p>
                    </div>
                  </div>
                );
              })}
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header>Share Information</Accordion.Header>
            <Accordion.Body>
              <div className="mixed-chart">
                <LineChart />
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
      <div className="cardSection approvalForm mt-4">
        <div className="d-flex justify-content-between">
          <h1>Approval Information</h1>
        </div>
        <div className="row mx-0 mt-4">
          <div className="col-10 mx-auto">
            <div className="row gx-5">
              <div className="col-md-6">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Approve at</Form.Label>
                  <Form.Control
                    type="text"
                    disabled={true}
                    value={
                      article_Detail?.approvedAt
                        ? article_Detail?.approvedAt.substring(0, 10) +
                          " / " +
                          article_Detail?.approvedAt.substring(11, 19)
                        : ""
                    }
                  />
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Type</Form.Label>
                  <Form.Control
                    type="text"
                    disabled={true}
                    value={article_Detail?.publish_type}
                  />
                </Form.Group>
              </div>
              <div className="col-md-12">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Reason</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={5}
                    disabled={true}
                    value={
                      article_Detail.reason
                        ? article_Detail?.reason
                        : "No Reason"
                    }
                  />
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Status</Form.Label>
                  <Form.Select aria-label="Default select example">
                    <option value="1">5 Stars</option>
                  </Form.Select>
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Button className="loginBtn w-100" type="submit">
                  Reason
                </Button>
              </div>
              <div className="col-12">
                <div className="subTitle my-3">Reference</div>
                {article_Detail?.refrences?.map((val, i) => (
                  <>
                    {val.img !== "" && (
                      <div className="avatar-preview">
                        {/* <img src="/imgs/delImg.svg" className="delImg" alt="" onClick={()=>deleteImgRef(val.id)} /> */}
                        <div id="imagePreview">
                          <img id="saveImageFile" src={val.img} alt={val.img} />
                        </div>
                        <Form.Group
                          className="mb-3"
                          controlId="formBasicCheckbox"
                        >
                          <Form.Control
                            type="text"
                            label="Add Ref"
                            value={val.ref_reason}
                            disabled={true}
                            style={{ width: "auto", marginTop: "10px" }}
                            placeholder="Add reference (Optional)"
                          />
                        </Form.Group>
                      </div>
                    )}
                  </>
                ))}
                {/* <div className="w-100 mb-3 borderDashed text-center">
                  <img src="/imgs/uploadBig.svg" alt="" />
                  <h1>Drag & Drop your Picture you want to upload</h1>
                  <h2>Drag & Drop your file you want to upload</h2>
                  <div className="uploadBtnDesign">
                    <Form.Group controlId="formFile" className="mb-3">
                      <Form.Control type="file" />
                    </Form.Group>
                    <Button className="loginBtn w-100" type="submit">
                      Browser
                    </Button>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {article_Detail?.isDelete == true && (
        <div className="cardSection approvalForm mt-4">
          <div className="d-flex justify-content-between">
            <h1>Deleted Information</h1>
          </div>
          <div className="row mx-0 mt-4">
            <div className="col-10 mx-auto">
              <div className="row gx-5">
                <div className="col-md-6">
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Deleted at</Form.Label>
                    <Form.Control
                      type="text"
                      disabled={true}
                      value={
                        article_Detail?.deletedAt
                          ? article_Detail?.deletedAt.substring(0, 10) +
                            " / " +
                            article_Detail?.deletedAt.substring(11, 19)
                          : ""
                      }
                    />
                  </Form.Group>
                </div>
                <div className="col-md-12">
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Reason</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={5}
                      disabled={true}
                      value={article_Detail?.deleteReason}
                    />
                  </Form.Group>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ArticleDetail;
