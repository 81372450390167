import axios from 'axios'

const API_URL = `${process.env.REACT_APP_BACKEND_ROUTE}/about/`


const AboutData = async () => {
  const response = await axios.get(API_URL + 'about-data');
  if (response.data) {
      return response.data
  }
}


const addAboutusData = async (convertedContent) => {
  const response = await axios.post(API_URL + 'add-about', convertedContent);
  if (response.data) {
      return response.data
  }
}

const updateAboutusData = async (convertedContent) => {
  const response = await axios.put(API_URL + 'update-about', convertedContent);
  if (response.data) {
      return response.data
  }
}

// const editPlansData = async (userData) => {
//   const response = await axios.put(API_URL + 'edit-plans', userData);
//   if (response.data) {
//       return response.data
//   }
// }

// const deletePlansData = async (ID) => {
//   const response = await axios.put(API_URL + `delete-plans/${ID}`);
//   if (response.data) {
//       return response.data
//   }
// }

const aboutService = {
    AboutData,
    addAboutusData,
    updateAboutusData,
    // editPlansData,
    // deletePlansData,
  }
  
  export default aboutService
