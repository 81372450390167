import React, { useEffect, useState, useRef, useLayoutEffect } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { getBadword } from "../../features/badWord/BadWordSlice";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { GrammarlyEditorPlugin } from "@grammarly/editor-sdk-react";

const modules = {
  toolbar: [
    //[{ 'font': [] }],
    [{ header: [1, 2, false] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link", "image", "video"],
    [{ align: [] }, { color: [] }, { background: [] }], // dropdown with defaults from theme
    ["clean"],
  ],
};

const formats = [
  //'font',
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "align",
  "color",
  "background",
  "video",
];

function Editor({ value, setValue }) {
  const [wordsList, setWordList] = useState([]);
  const [test, setTest] = useState("");
  const { badWords } = useSelector((state) => state.badword);
  const editorRef = useRef();
  const dispatch = useDispatch();

  const handleChange = (content, delta, source, editor) => {
    setValue(content);
    // highlightBadWords(content);
  };
  const highlightBadWords = (content) => {
    badWords.forEach((badWord) => {
      const regex = new RegExp(badWord.word.trim(), "gi");
      content = content?.replace(
        regex,
        `<span style="border-bottom: 3px solid yellow; font-weight: bold;">$&</span>`
      );
    });
    setTest(content);
    return content;
  };

  const getWords = async () => {
    highlightBadWords(value);
    const result = await dispatch(getBadword());
    if (result.payload.data.length > 0) {
      setWordList(result.payload.data);
    }
  };

  useEffect(() => {
    getWords();
  }, []);

  useEffect(() => {
    highlightBadWords(value);
  }, [value]);

  const renderHtmlContent = (value1) => {
    return { __html: value1 };
  };

  return (
    <>
      <div style={{ display: "flex" }} className="mb-2 mt-3">
        <div className="d-flex align-items-center me-4">
          <span
            style={{
              width: "10px",
              height: "10px",
              background: "red",
              borderRadius: "50%",
              marginRight: "7px",
            }}
          ></span>
          <p className="m-0">Spell Check</p>
        </div>
        <div className="d-flex align-items-center">
          <span
            style={{
              width: "10px",
              height: "10px",
              background: "yellow",
              borderRadius: "50%",
              marginRight: "7px",
            }}
          ></span>
          <p className="m-0">Bad Word</p>
        </div>

        <div></div>
      </div>
      <GrammarlyEditorPlugin
        clientId={process.env.REACT_APP_GRAMMARY}
        config={{ activation: "immediate" }}
      >
        <ReactQuill
          ref={editorRef}
          theme="snow"
          modules={modules}
          formats={formats}
          value={value}
          onChange={handleChange}
          // onFocus={handleSelectionChange}
          // onMouseUp={handleSelectionChange}
          // onTouchEnd={handleSelectionChange}
        />
      </GrammarlyEditorPlugin>
      <div>
        <h1 className="mt-3">Preview</h1>
        <div
          dangerouslySetInnerHTML={renderHtmlContent(test)}
          spellCheck="true"
        />
      </div>
    </>
  );
}

export default Editor;
