import React, { useEffect, useState, useLayoutEffect } from "react";
import {
  InputGroup,
  Button,
  Modal,
  Form,
  ToastContainer,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { draft_column } from "../../../utils/constant/column_header_data";
import { useSelector, useDispatch } from "react-redux";
import {
  deletedArticle,
  deletedRowsArticles,
  getArticle,
  publishArticle,
  reset,
} from "../../../features/Articles/articleSlice";
import DataTable from "react-data-table-component";
import { formatDate } from "../../../utils/DateHelper";
import { toast } from "react-toastify";

function Draft() {
  const user = JSON.parse(localStorage.getItem("user"));

  const ID = user?.data?.user?._id;
  const { article_List, isError, isSuccess, message, isList, imageLocation } =
    useSelector((state) => state.article);
  const { frontData } = useSelector((state) => state.front_Data);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [Search, setSearch] = useState("");
  const [selectedRows, setSelectedRows] = useState(false);
  const [CList, setCList] = useState([]);
  const [DelID, setDelID] = useState("");
  const [ApprovalID, setApprovalID] = useState("");
  const [DelReason, setDelReason] = useState("");

  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);

  const handleClose = () => {
    setShow(false);
    setDelID("");
  };

  const handleClose2 = () => {
    setShow2(false);
    setApprovalID("");
  };

  const handleClose3 = () => {
    setShow3(false);
    setDelID("");
    setDelReason("");
  };

  useLayoutEffect(() => {
    dispatch(reset());
    dispatch(getArticle(ID));
  }, []);

  useEffect(() => {
    dispatch(getArticle(ID));
    if (article_List) {
      const data = [...article_List].reverse();
      setCList(data);
    }
  }, [isSuccess, isError, message, isList, imageLocation, ID]);

  const deleteRow = () => {
    let yFilter = selectedRows.map((itemY) => {
      return itemY.id;
    });
    let filteredX = article_List.filter(
      (itemX) => !yFilter.includes(itemX._id)
    );

    const data = {
      Ids: yFilter,
      DelReason: DelReason,
    };
    dispatch(deletedRowsArticles(data));
    dispatch(reset());
    setDelID("");
    setSelectedRows([]);
    setShow3(false);
    setShow2(false);
    setShow(false);
    setDelReason("");
  };

  const onPublish = (ID) => {
    setApprovalID(ID);
    setShow2(true);
  };

  const onApproval = () => {
    dispatch(publishArticle(ApprovalID));
    setApprovalID("");
    setShow2(false);
  };

  const onEdit = (ID) => {
    navigate(`/article/article-form/${ID}`, {
      state: { type: "Draft", view: false, user: "user" },
    });
  };
  const onDelete = (Id) => {
    setDelID(Id);
    setShow(true);
  };

  var rows = [];
  if (CList) {
    for (let [i, value] of CList?.entries()) {
      rows[i] = {
        id: value._id,
        title: value.title,
        created_at: value?.createdAt
          ? formatDate(value.createdAt, frontData.timezone)
          : "",
        action: (
          <div>
            <div className="d-flex gap-3 justify-content-center align-items-center">
              {/* <img
                src="/imgs/submit.svg"
                width={20}
                alt=""
                onClick={() => onPublish(value._id)}
              /> */}
              <img
                src="/imgs/t_edit.svg"
                width={20}
                alt=""
                onClick={() => onEdit(value._id)}
              />
              <img
                src="/imgs/delete.svg"
                width={20}
                alt=""
                onClick={() => onDelete(value._id)}
              />
            </div>
          </div>
        ),
      };
    }
  }

  const DelFun = () => {
    const Dele = {
      id: DelID,
      reason: DelReason,
    };
    dispatch(deletedArticle(Dele));
    setDelID("");
    setDelReason("");
    setShow(false);
  };

  const AddNew = () => {
    dispatch(reset());
    navigate("/article/article-form", {
      state: { type: "Draft", view: false },
    });
  };

  return (
    <div className="staffMainDiv">
      <ToastContainer />

      <div className="row mx-0 mb-4">
        <div className="d-flex justify-content-between">
          <div className="d-flex gap-3">
            <img
              src="/imgs/revoke.svg"
              width={20}
              alt=""
              onClick={() => setSearch("")}
            />
            <Form.Group className="mb-0">
              <InputGroup className="mb-0">
                <InputGroup.Text id="basic-addon1">
                  <img src="/imgs/search.svg" alt="" />
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  placeholder="Search"
                  value={Search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </InputGroup>
            </Form.Group>
          </div>
          <Button className="loginBtn" type="submit" onClick={AddNew}>
            Add New
          </Button>
        </div>
      </div>
      <DataTable
        columns={draft_column}
        data={
          rows
            ? rows.filter((item) =>
                item.title.toLowerCase().includes(Search.toLowerCase())
              )
            : []
        }
        pagination
      />

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Are you sure you want to delete this article?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <textarea
            rows={4}
            className="form-control"
            placeholder="Add Reason for deleting..."
            value={DelReason}
            onChange={(e) => setDelReason(e.target.value)}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="primary"
            disabled={DelReason !== "" ? false : true}
            onClick={DelFun}
          >
            Sure
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={show2}
        onHide={handleClose2}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Submit Article</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to submit this article?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose2}>
            Close
          </Button>
          <Button variant="primary" onClick={onApproval}>
            Sure
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={show3}
        onHide={handleClose3}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Are you sure you want to delete this article?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <textarea
            rows={4}
            className="form-control"
            placeholder="Add Reason for deleting..."
            value={DelReason}
            onChange={(e) => setDelReason(e.target.value)}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose3}>
            Close
          </Button>
          <Button
            variant="primary"
            disabled={DelReason !== "" ? false : true}
            onClick={deleteRow}
          >
            Sure
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Draft;
