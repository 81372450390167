import axios from "axios";

const API_URL = `${process.env.REACT_APP_BACKEND_ROUTE}/plan/`;

const plansList = async () => {
  const response = await axios.get(API_URL + "plans-list");
  if (response.data) {
    return response.data;
  }
};

const paymentList = async () => {
  const response = await axios.get(API_URL + "payment-list");
  if (response.data) {
    return response.data;
  }
};

const addPlansData = async (userData) => {
  const response = await axios.post(API_URL + "add-plans", userData);
  if (response.data) {
    return response.data;
  }
};

const addPayment = async (userData) => {
  const response = await axios.post(API_URL + "add-payment", userData);
  if (response.data) {
    return response.data;
  }
};

const editPlansData = async (userData) => {
  const response = await axios.put(API_URL + "edit-plans", userData);
  if (response.data) {
    return response.data;
  }
};

const editPayment = async (userData) => {
  const response = await axios.put(API_URL + "edit-payment", userData);
  if (response.data) {
    return response.data;
  }
};

const deletePlansData = async (ID) => {
  console.log(ID);
  const response = await axios.put(API_URL + `delete-plans/${ID}`);
  if (response.data) {
    return response.data;
  }
};

const deletedRowsP = async (passData) => {
  const response = await axios.put(API_URL + "deleted-rows", passData);
  return response.data;
};

const paymentStatus = async (data) => {
  const response = await axios.get(
    `${process.env.REACT_APP_BACKEND_ROUTE}/payment/payment-status?session_id=${data.session_id}&userId=${data.userId}&quotationId=${data?.quotationId}&plan=${data.plan}`
  );
  return response.data;
};
const productService = {
  plansList,
  paymentList,
  addPlansData,
  addPayment,
  editPlansData,
  editPayment,
  deletePlansData,
  deletedRowsP,
  paymentStatus,
};

export default productService;
