import React, { useEffect, useLayoutEffect, useState } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { WithContext as ReactTags } from "react-tag-input";
import { useDispatch } from "react-redux";
import { reset } from "../../../../../features/OtherPages/otherPagesSlice";
import ReactQuill from "../../../../Editor/Editor";

function OtherForm({
  handleAddFormChange,
  AddForm,
  setAddFormData,
  tags,
  setTags,
  handleDelete,
  handleAddition,
  handleDrag,
  handleTagClick,
  delimiters,
  navData,
  URL_DATA,
  value,
  setValue,
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useLayoutEffect(() => {
    dispatch(reset());
  }, [dispatch]);
  return (
    <div>
      <div className="cardSection">
        <div className="d-flex justify-content-between">
          <h1>New Page</h1>
          {/* <img src="/imgs/edit.svg" className="editIcon" alt="" /> */}
          <div className="d-flex justify-content-center gap-4 mb-3">
            <Button
              className="cancelBtn"
              type="button"
              onClick={() => navigate(-1)}
            >
              Cancel
            </Button>
            <Button className="loginBtn" onClick={AddForm}>
              Save
            </Button>
          </div>
        </div>
        <div className="row mx-0 mt-4">
          <div className="col-10 mx-auto">
            <div className="row gx-5">
              <div className="col-md-6">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Name</Form.Label>
                  <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1">
                      <img src="/imgs/name.svg" alt="" />
                    </InputGroup.Text>
                    <Form.Control
                      type="text"
                      placeholder="Enter a name"
                      name="name"
                      onChange={handleAddFormChange}
                    />
                  </InputGroup>
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>URL</Form.Label>
                  <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1">
                      <img src="/imgs/api.svg" alt="" />
                    </InputGroup.Text>
                    <Form.Control
                      type="text"
                      placeholder="URL will be display here..."
                      name="url"
                      // value={URL_DATA}
                      onChange={handleAddFormChange}
                    />
                  </InputGroup>
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Status</Form.Label>
                  <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1">
                      <img src="/imgs/status-info.svg" alt="" />
                    </InputGroup.Text>
                    <Form.Select
                      aria-label="Default select example"
                      name="status"
                      onChange={handleAddFormChange}
                    >
                      <option value="">Select Status</option>
                      <option value="Published">Published</option>
                      <option value="Draft">Draft</option>
                      <option value="Hide">Hide</option>
                    </Form.Select>
                  </InputGroup>
                </Form.Group>
              </div>
              {/* <div className="col-md-6">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Navigation</Form.Label>
                  <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1">
                      <img src="/imgs/navigation.svg" alt="" />
                    </InputGroup.Text>
                    <Form.Select
                      aria-label="Default select example"
                      name="navigation"
                      onChange={handleAddFormChange}
                    >
                      <option value="">Select Navigation</option>
                      {navData?.map((val, i) => (
                        <option value={val.url} key={i}>
                          {val.name}
                        </option>
                      ))}
                    </Form.Select>
                  </InputGroup>
                </Form.Group>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <div className="cardSection mt-4">
        <div className="d-flex justify-content-between">
          <h1>Content</h1>
        </div>
        <div className="row mx-0 mt-4">
          <div className="my-3 ugradePlanEditor">
            <ReactQuill setValue={setValue} value={value} />
          </div>
        </div>
      </div>
      <div className="cardSection mt-4">
        <div className="d-flex justify-content-between"></div>
        <div className="row mx-0 mt-4">
          <div className="col-10 mx-auto">
            <div className="row gx-5">
              <div className="col-md-12">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Tags</Form.Label>
                  <ReactTags
                    tags={tags}
                    autofocus={false}
                    delimiters={delimiters}
                    handleDelete={handleDelete}
                    handleAddition={handleAddition}
                    handleDrag={handleDrag}
                    handleTagClick={handleTagClick}
                    inputFieldPosition="bottom"
                    autocomplete
                    name="tags"
                    placeholder="Enter tag here"
                  />
                </Form.Group>
              </div>
              <div className="col-md-12">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={5}
                    name="description"
                    placeholder="Enter a description"
                    onChange={handleAddFormChange}
                  />
                </Form.Group>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OtherForm;
