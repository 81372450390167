import axios from "axios";

const API_URL = `${process.env.REACT_APP_BACKEND_ROUTE}/auth/`;
// url = `http://3.108.146.184:8082/auth/signup`

// const setAuthToken = token => {
//   if (token) {
//     console.log("token get: ", token)
//       axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
//   }
//   else{
//       delete axios.defaults.headers.common["Authorization"];
//   }
// }

// Register user
const register = async (userData) => {
  const response = await axios.post(API_URL + "signup", userData);
  if (response.data.status === "Success") {
    localStorage.setItem("user", JSON.stringify(response.data));
    return response.data;
  }
};

// Login user
const login = async (userData) => {
  const response = await axios.post(API_URL + "login", userData);
  if (response.data.status === "Success") {
    localStorage.setItem("user", JSON.stringify(response.data));
    // setAuthToken(response?.data?.data?.token)
    return response.data;
  }
};

// Login admin ergi
const loginAdmin = async (userData) => {
  const response = await axios.post(API_URL + "logindashboard", userData);
  if (response.data.status === "Success") {
    localStorage.setItem("user", JSON.stringify(response.data));
    localStorage.setItem("profileData", false);
    localStorage.setItem("myProfile", false);
    // setAuthToken(response?.data?.data?.token)
    return response.data;
  }
};

const addAreaOIfInterest = async (interest) => {
  const response = await axios.put(
    `${process.env.REACT_APP_BACKEND_ROUTE}/user/add-interest`,
    interest
  );
  if (response.data) {
    return response.data;
  }
};

// Forget Password
const forgetpassword = async (email) => {
  const response = await axios.post(API_URL + "forget-password", email);
  if (response.data.status === "Success") {
    return response.data;
  }
};

const verifyEmail = async (token) => {
  const response = await axios.get(API_URL + `verify-email/${token}`);
  if (response.data.status === "Success") {
    return response.data;
  }
};

// reset Password
const getResetPass = async (ParamsData) => {
  const response = await axios.get(
    API_URL + `reset-password/${ParamsData.id}/${ParamsData.token}`
  );
  console.log("response.data.status: ", response.data.status);
  if (response.data.status === "Success") {
    return response.data;
  }
};

// Reset Password post
const postResetPass = async (ParamsData) => {
  const response = await axios.post(
    API_URL + `reset-password/${ParamsData.id}/${ParamsData.token}`,
    { password: ParamsData.password }
  );
  console.log("response.data.status: ", response.data.status);
  if (response.data.status === "Success") {
    return response.data;
  }
};

const updatePass = async (passData) => {
  const response = await axios.post(API_URL + "change-password", passData);
  console.log("response.data.status: ", response.data.message);
  return response.data;
};

const verifyToken = async (data) => {
  const response = await axios.post(API_URL + "verifyToken", data);
  if (response.data?.data?.isSuccess === true) {
    localStorage.setItem("user", JSON.stringify(response.data));
    localStorage.setItem("profileData", false);
    localStorage.setItem("myProfile", false);
    // setAuthToken(response?.data?.data?.token)
  }

  return response.data;
};

// Logout user
const Logout = () => {
  localStorage.setItem("profileData", false);
  localStorage.removeItem("user");
  localStorage.removeItem("reg_user");
  localStorage.setItem("myProfile", false);
};

const authService = {
  register,
  Logout,
  login,
  loginAdmin,
  forgetpassword,
  getResetPass,
  postResetPass,
  updatePass,
  verifyEmail,
  addAreaOIfInterest,
  verifyToken,
};

export default authService;
