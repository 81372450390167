import moment from "moment";

export const userManagement_column = [
  {
    name: "Name",
    selector: (row) => row.name,
    width: "150px",
  },
  {
    name: "User Name",
    selector: (row) => row?.user_name,
    width: "150px",
  },
  {
    name: "Email",
    selector: (row) => row.email,
    width: "150px",
  },
  {
    name: "Contact No",
    selector: (row) => row.contact_no,
    width: "150px",
  },
  {
    name: "Date Created",
    selector: (row) => row.createdAt,
    width: "150px",
  },
  {
    name: "Status",
    selector: (row) => row.status,
    width: "150px",
  },
  {
    name: "Last Login",
    selector: (row) => row.last_login,
    width: "150px",
  },
  {
    name: "Actions",
    selector: (row) => row.action,
    width: "200px",
  },
];
export const pendingApproval_column = [
  {
    name: "Title",
    selector: (row) => row.title,
    width: "150px",
  },
  {
    name: "Author",
    selector: (row) => row.author,
    width: "150px",
  },
  {
    name: "Submit At",
    selector: (row) => row.submitted_at,
    width: "150px",
  },
  {
    name: "Type",
    selector: (row) => row.publish_type,
    width: "150px",
  },
  {
    name: "Actions",
    selector: (row) => row.action,
    width: "200px",
  },
];
export const pendingContApproval_column = [
  {
    name: "Title",
    selector: (row) => row.title,
    width: "150px",
  },
  {
    name: "Created at",
    selector: (row) => row.created_at,
    width: "150px",
  },
  {
    name: "Submitted at",
    selector: (row) => row.submitted_at,
    width: "150px",
  },
  {
    name: "Publish Type",
    selector: (row) => row.publish_type,
    width: "150px",
  },
];
export const draft_column = [
  {
    name: "Title",
    selector: (row) => row.title,
    width: "150px",
  },
  {
    name: "Created at",
    selector: (row) => row.created_at,
    width: "150px",
  },
  {
    name: "Actions",
    selector: (row) => row.action,
  },
];
export const approvedArticle_column = [
  {
    name: "Title",
    selector: (row) => row.title,
    width: "150px",
  },
  {
    name: "Created at",
    selector: (row) => row.created_at,
    width: "150px",
  },
  {
    name: "Submitted at",
    selector: (row) => row.submit_at,
    width: "150px",
  },
  {
    name: "Approved at",
    selector: (row) => row.approved_at,
    width: "150px",
  },
  {
    name: "Publish type",
    selector: (row) => row.publish_type,
    width: "150px",
  },
  {
    name: "Actions",
    selector: (row) => row.action,
  },
];
export const listing_column = [
  {
    name: "Title",
    selector: (row) => row.title,
    width: "150px",
  },
  {
    name: "User Name",
    selector: (row) => row.author,
    width: "150px",
  },
  {
    name: "Email",
    selector: (row) => row.email,
    width: "150px",
  },
  {
    name: "Submit at",
    selector: (row) => row.submited_at,
    width: "150px",
  },
  {
    name: "Created at",
    selector: (row) => row.publish,
    width: "150px",
  },
  {
    name: "Publish type",
    selector: (row) => row.publish_type,
    width: "150px",
  },
  {
    name: "Views count",
    selector: (row) => row.views_count,
    width: "150px",
  },
  // {
  //   name: "Share count",
  //   selector: (row) => row.share_count,
  //   width: "150px",
  // },
  {
    name: "Reviews",
    selector: (row) => row.reviews,
    width: "180px",
  },
  {
    name: "Actions",
    selector: (row) => row.action,
    width: "200px",
  },
];

export const delete_article_column = [
  {
    name: "Title",
    selector: (row) => row.title,
    width: "150px",
  },
  {
    name: "User Name",
    selector: (row) => row.author,
    width: "150px",
  },
  {
    name: "Email",
    selector: (row) => row.email,
    width: "150px",
  },
  {
    name: "Deleted at",
    selector: (row) => row.publish,
    width: "150px",
  },
  {
    name: "Publish type",
    selector: (row) => row.publish_type,
    width: "150px",
  },
  {
    name: "Views count",
    selector: (row) => row.views_count,
    width: "150px",
  },
  // {
  //   name: "Share count",
  //   selector: (row) => row.share_count,
  //   width: "150px",
  // },
  {
    name: "Reviews",
    selector: (row) => row.reviews,
    width: "180px",
  },
  {
    name: "Actions",
    selector: (row) => row.action,
    width: "200px",
  },
];
export const articleReview_column = [
  {
    name: "Title",
    selector: (row) => row.title,
    width: "150px",
  },
  {
    name: "Date",
    selector: (row) => row.date,
    width: "150px",
  },
  {
    name: "Reviews",
    selector: (row) => row.reviews,
    width: "180px",
  },
  {
    name: "By",
    selector: (row) => row.author,
    width: "150px",
  },
  {
    name: "Replies Count",
    selector: (row) => row.replies_count,
    width: "150px",
  },
  {
    name: "Actions",
    selector: (row) => row.action,
  },
];
export const tourReview_column = [
  {
    name: "Name",
    selector: (row) => row.name,
    width: "150px",
  },
  {
    name: "Date",
    selector: (row) => row.date,
    width: "150px",
  },
  {
    name: "Reviews",
    selector: (row) => row.reviews,
    width: "180px",
  },
  {
    name: "By",
    selector: (row) => row.author,
    width: "150px",
  },
  {
    name: "Replies Count",
    selector: (row) => row.replies_count,
    width: "150px",
  },
  {
    name: "Actions",
    selector: (row) => row.action,
  },
];
export const tours_column = [
  {
    name: "Name",
    selector: (row) => row.name,
    width: "150px",
  },
  {
    name: "Status",
    selector: (row) => row.status,
    width: "150px",
  },
  {
    name: "Reviews",
    selector: (row) => row.reviews,
    width: "150px",
  },
  {
    name: "Visit Count",
    selector: (row) => row.visit_count,
    width: "150px",
  },
  // {
  //   name: "Share Count",
  //   selector: (row) => row.share_count,
  //   width: "150px",
  // },
  {
    name: "Actions",
    selector: (row) => row.action,
    width: "200px",
  },
];
export const productPlan_column = [
  {
    name: "Name",
    selector: (row) => row.name,
    width: "250px",
  },
  {
    name: "Pricing",
    selector: (row) => row.pricing_amount,
    width: "150px",
  },
  {
    name: "Status",
    selector: (row) => row.status,
    width: "150px",
  },
  {
    name: "Display",
    selector: (row) => row.display,
    width: "150px",
  },
  {
    name: "Actions",
    selector: (row) => row.action,
    width: "200px",
  },
];
export const paymentGateway_column = [
  {
    name: "Name",
    selector: (row) => row.name,
    width: "150px",
  },
  {
    name: "Gateway",
    selector: (row) => row.gateway,
    width: "150px",
  },
  {
    name: "Status",
    selector: (row) => row.status,
    width: "150px",
  },
  {
    name: "Actions",
    selector: (row) => row.action,
    width: "200px",
  },
];
export const upgradePlan_column = [
  {
    name: "Name",
    selector: (row) => row.name,
    width: "150px",
  },
  {
    name: "Price",
    selector: (row) => row.price,
    width: "150px",
  },
  {
    name: "Status",
    selector: (row) => row.status,
    width: "150px",
  },
  {
    name: "Actions",
    selector: (row) => row.action,
    width: "200px",
  },
];
export const otherPages_column = [
  {
    name: "Name",
    selector: (row) => row.name,
    width: "150px",
  },
  {
    name: "URL",
    selector: (row) => row.url,
    width: "150px",
  },
  {
    name: "Status",
    selector: (row) => row.status,
    width: "150px",
  },
  {
    name: "Tags",
    selector: (row) => row.tags,
    width: "250px",
  },
  {
    name: "Actions",
    selector: (row) => row.action,
    width: "200px",
  },
];

export const quotation_column = [
  {
    name: "Score",
    selector: (row) => row.score,
    width: "150px",
    sortable: true,
    sortFunction: (rowA, rowB) => {
      const a = rowA?.score?.props?.children;
      const b = rowB?.score?.props?.children;

      if (a > b) {
        return 1;
      }

      if (b > a) {
        return -1;
      }

      return 0;
    },
  },
  {
    name: "Order Date",
    selector: (row) => row.order_date,
    width: "150px",
    sortable: true,
    sortFunction: (rowA, rowB) => {
      const a = moment(rowA.order_date);
      const b = moment(rowB.order_date);

      if (a > b) {
        return 1;
      }

      if (b > a) {
        return -1;
      }

      return 0;
    },
  },
  // {
  //   name: "Due Date",
  //   selector: (row) => row.dueDate,
  //   width: "150px",
  //   sortable: true,
  // },
  {
    name: "User",
    selector: (row) => row.user,
    width: "150px",
    sortable: true,
    sortFunction: (rowA, rowB) => {
      const a = rowA.user.toLowerCase();
      const b = rowB.user.toLowerCase();

      if (a > b) {
        return 1;
      }

      if (b > a) {
        return -1;
      }

      return 0;
    },
  },
  {
    name: "Product Plan",
    selector: (row) => row.product_plan,
    width: "150px",
    sortable: true,
  },
  {
    name: "Status",
    selector: (row) => row.status,
    width: "150px",
    sortable: true,
  },
  // {
  //   name: "Assign to",
  //   selector: (row) => row.assignTo,
  //   width: "150px",
  //   sortable: true,
  // },
  {
    name: "Actions",
    selector: (row) => row.action,
    width: "200px",
  },
];
export const quotation_column1 = [
  {
    name: "Score",
    selector: (row) => row.score,
    width: "150px",
    sortable: true,
    sortFunction: (rowA, rowB) => {
      const a = rowA?.score?.props?.children;
      const b = rowB?.score?.props?.children;

      if (a > b) {
        return 1;
      }

      if (b > a) {
        return -1;
      }

      return 0;
    },
  },
  {
    name: "Order Date",
    selector: (row) => row.order_date,
    width: "150px",
    sortable: true,
    sortFunction: (rowA, rowB) => {
      const a = moment(rowA.order_date);
      const b = moment(rowB.order_date);

      if (a > b) {
        return 1;
      }

      if (b > a) {
        return -1;
      }

      return 0;
    },
  },
  {
    name: "Due Date",
    selector: (row) => row.dueDate,
    width: "150px",
    sortable: true,
  },
  {
    name: "Days to Due Date",
    selector: (row) => row.daystoDueDate,
    width: "150px",
    sortable: true,
  },
  {
    name: "User",
    selector: (row) => row.user,
    width: "150px",
    sortable: true,
    sortFunction: (rowA, rowB) => {
      const a = rowA.user.toLowerCase();
      const b = rowB.user.toLowerCase();

      if (a > b) {
        return 1;
      }

      if (b > a) {
        return -1;
      }

      return 0;
    },
  },
  {
    name: "Product Plan",
    selector: (row) => row.product_plan,
    width: "150px",
    sortable: true,
  },
  {
    name: "Status",
    selector: (row) => row.status,
    width: "150px",
    sortable: true,
  },
  {
    name: "Assign to",
    selector: (row) => row.assignTo,
    width: "150px",
    sortable: true,
  },
  {
    name: "Actions",
    selector: (row) => row.action,
    width: "200px",
  },
];
export const outstanding_column = [
  {
    name: "User",
    selector: (row) => row.user,
    width: "180px",
  },
  {
    name: "Amount",
    selector: (row) => row.amount,
    width: "120px",
  },
  {
    name: "Order Date",
    selector: (row) => row.order_date,
    width: "150px",
  },
  {
    name: "Product Plan",
    selector: (row) => row.product_plan,
    width: "150px",
  },
  {
    name: "Pricing Type",
    selector: (row) => row.pricing_type,
    width: "150px",
  },
  {
    name: "Actions",
    selector: (row) => row.action,
  },
];
export const paid_column = [
  {
    name: "User",
    selector: (row) => row.user,
    width: "150px",
  },
  {
    name: "Amount",
    selector: (row) => row.amount,
    width: "150px",
  },
  {
    name: "Order Date",
    selector: (row) => row.order_date,
    width: "150px",
  },
  {
    name: "Product Plan",
    selector: (row) => row.product_plan,
    width: "150px",
  },
  {
    name: "Pricing Type",
    selector: (row) => row.pricing_type,
    width: "150px",
  },
  {
    name: "Paid At",
    selector: (row) => row.paid_at,
    width: "150px",
  },
  {
    name: "Detail",
    selector: (row) => row.action,
    width: "150px",
  },
];
export const rejected_column = [
  {
    name: "User",
    selector: (row) => row.user,
    width: "150px",
  },
  {
    name: "Score",
    selector: (row) => row.score,
    width: "150px",
  },
  {
    name: "Created AT",
    selector: (row) => row.createdAt,
    width: "150px",
  },
  {
    name: "Product Plan",
    selector: (row) => row.product_plan,
    width: "150px",
  },
  {
    name: "Pricing Type",
    selector: (row) => row.pricing_type,
    width: "150px",
  },
  {
    name: "Detail",
    selector: (row) => row.action,
    width: "150px",
  },
];
export const commonBillingPending_column = [
  {
    name: "Billing Date",
    selector: (row) => row.billing_date,
    width: "150px",
  },
  {
    name: "Amount",
    selector: (row) => row.amount,
    width: "150px",
  },

  {
    name: "Status",
    selector: (row) => row.status,
    width: "150px",
  },
  // {
  //   name: "Billing No.",
  //   selector: (row) => row.billing_no,
  //   width: "150px",
  // },
  // {
  //   name: "Invoice No.",
  //   selector: (row) => row.invoice_no,
  //   width: "150px",
  // },
  {
    name: "Via",
    selector: (row) => row.via,
    width: "150px",
  },
  {
    name: "Pay",
    selector: (row) => row.action,
    width: "150px",
  },
];
export const billingPending_column = [
  {
    name: "Billing Date",
    selector: (row) => row.billing_date,
    width: "150px",
  },
  {
    name: "User",
    selector: (row) => row.user,
    width: "150px",
  },
  {
    name: "Amount",
    selector: (row) => row.amount,
    width: "150px",
  },

  {
    name: "Status",
    selector: (row) => row.status,
    width: "150px",
  },
  {
    name: "Detail",
    selector: (row) => row.action,
    width: "150px",
  },
];
export const billingPaid_column = [
  {
    name: "Billing Date",
    selector: (row) => row.billing_date,
    width: "150px",
  },
  {
    name: "Paid At",
    selector: (row) => row.paid_at,
    width: "150px",
  },
  {
    name: "Amount",
    selector: (row) => row.amount,
    width: "150px",
  },
  {
    name: "Status",
    selector: (row) => row.status,
    width: "150px",
  },
  {
    name: "Via",
    selector: (row) => row.via,
    width: "150px",
  },
  {
    name: "Actions",
    selector: (row) => row.action,
  },
];
export const notification_column = [
  {
    name: "Notification Details",
    selector: (row) => row.notif_detail,
    width: "300px",
  },
  {
    name: "Time",
    selector: (row) => row.time,
    width: "150px",
  },
  {
    name: "Date",
    selector: (row) => row.date,
    width: "150px",
  },
  {
    name: "Actions",
    selector: (row) => row.action,
  },
];
export const questionare_column = [
  {
    name: "Name",
    selector: (row) => row.name,
    width: "150px",
  },
  {
    name: "Question Count",
    selector: (row) => row.questions,
    width: "150px",
  },
  {
    name: "Status",
    selector: (row) => row.status,
    width: "150px",
  },

  {
    name: "Actions",
    selector: (row) => row.action,
    width: "200px",
  },
];
export const questionareDetails_column = [
  {
    name: "Name",
    selector: (row) => row.name,
    width: "150px",
  },
  {
    name: "Score",
    selector: (row) => row.score,
    width: "150px",
  },

  {
    name: "Actions",
    selector: (row) => row.action,
    width: "200px",
  },
];
export const publishedContArticle_column = [
  {
    name: "Title",
    selector: (row) => row.title,
    width: "150px",
  },
  {
    name: "Created at",
    selector: (row) => row.created_at,
    width: "150px",
  },
  {
    name: "Submitted at",
    selector: (row) => row.submited_at,
    width: "150px",
  },
  {
    name: "Publish type",
    selector: (row) => row.publish_type,
    width: "150px",
  },
  {
    name: "Publish at",
    selector: (row) => row.publish_at,
    width: "150px",
  },
  {
    name: "Views count",
    selector: (row) => row.views_count,
    width: "150px",
  },
  // {
  //   name: "Share count",
  //   selector: (row) => row.share_count,
  //   width: "150px",
  // },
  {
    name: "Reviews",
    selector: (row) => row.reviews,
    width: "150px",
  },
  {
    name: "Actions",
    selector: (row) => row.action,
    width: "200px",
  },
];
