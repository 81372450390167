import React, { useState, useEffect, useLayoutEffect } from "react";
import { Container, Modal, Row, Form, InputGroup } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import {
  getQuotation,
  reset,
} from "../../../features/Quotations/quotationSlice";
import { commonBillingPending_column } from "../../../utils/constant/column_header_data";
import { formatDate } from "../../../utils/DateHelper";

function PendingBill() {
  const location = useLocation();

  const user = JSON.parse(localStorage.getItem("user"));

  const ID = user?.data?.user?._id;

  var datee = new Date();
  const day = datee.getDay();
  const month = datee.getMonth() + 1;
  const year = datee.getFullYear();
  datee = month + "/" + day + "/" + year;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { quotation_List, isError, isSuccess, message, isList } = useSelector(
    (state) => state.quotations
  );
  const { frontData } = useSelector((state) => state.front_Data);

  const [Search, setSearch] = useState("");
  const [Open, setOpen] = useState(false);
  const [status, setStatus] = useState("");
  const [CList, setCList] = useState([]);
  const [DelID, setDelID] = useState("");
  const [PayMessage, setPayMessage] = useState("");

  useLayoutEffect(() => {
    dispatch(reset());
    dispatch(getQuotation());

    if (new URLSearchParams(location.search).get("status")) {
      setStatus("Paid");
      setOpen(true);
      setPayMessage("Payment Successful!");
    }
    if (new URLSearchParams(location.search).get("status") == false) {
      setStatus("Not Paid");
      setOpen(true);
      setPayMessage("Payment Failed!");
    }
  }, []);

  useEffect(() => {
    if (quotation_List) {
      const newData = [...quotation_List]
        ?.reverse()
        ?.filter(
          (val) =>
            val.isAccepted == true &&
            val?.owner?._id === ID &&
            val.isPaid == false
        );
      if (newData) {
        setCList(newData);
      }
    }
  }, [dispatch, navigate, isSuccess, isError, message, isList, ID]);

  const onView = (value) => {
    user?.data?.user?.role === "contributor" ||
    user?.data?.user?.role === "user" ||
    (user?.data?.user?.role === "staff" &&
      user?.data?.user?.permissions[0]?.ArticlesOrders?.some(
        (val) => val === "view"
      ))
      ? navigate("/billing/bill-form", {
          state: { Details: value, edit: false },
        })
      : toast.error("Permission denied!");
  };

  var rows = [];
  if (CList) {
    for (let [i, value] of CList?.entries()) {
      rows[i] = {
        id: value._id,
        billing_date: value?.billingDate
          ? formatDate(value?.billingDate, frontData.timezone)
          : datee,
        amount: value?.plan[0]?.pricing_amount,
        status: value?.status,
        billing_no: "N/A",
        invoice_no: "N/A",
        paid_at: "Paid at",
        via: "VISA / PAYNOW",
        action: (
          <div>
            <div className="d-flex gap-3">
              <img
                src="/imgs/amount.svg"
                width={20}
                alt=""
                onClick={() => onView(value)}
              />
            </div>
          </div>
        ),
      };
    }
  }

  return (
    <div className="staffMainDiv">
      <div className="row mx-0 mb-4">
        <ToastContainer />
        <div className="d-flex justify-content-between">
          <div className="d-flex gap-3">
            <img
              src="/imgs/revoke.svg"
              width={20}
              alt=""
              onClick={() => setSearch("")}
            />
            <Form.Group className="mb-0">
              <InputGroup className="mb-0">
                <InputGroup.Text id="basic-addon1">
                  <img src="/imgs/search.svg" alt="" />
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  placeholder="Search"
                  value={Search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </InputGroup>
            </Form.Group>
          </div>
          {/* <Link to="/billing/billing-history/bill-form">
            <Button className="loginBtn" type="submit">
              Add New
            </Button>
          </Link> */}
        </div>
      </div>
      <DataTable
        columns={commonBillingPending_column}
        data={
          rows
            ? rows.filter((item) =>
                item?.status?.toLowerCase()?.includes(Search?.toLowerCase())
              )
            : []
        }
        pagination
      />

      <Modal
        show={Open}
        onHide={() => {
          setOpen(false);
          setStatus("");
          setPayMessage("");
        }}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Payment Status</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Container>
              <Row>
                <div>
                  <div className="text-center">
                    {status === "Paid" ? (
                      <img
                        src={"/imgs/tick.png"}
                        style={{ width: "100px" }}
                        alt="Tick Image"
                      />
                    ) : (
                      status === "Not Paid" && (
                        <img
                          src={"/imgs/cross.png"}
                          style={{ width: "100px" }}
                          alt="Cross Image"
                        />
                      )
                    )}
                  </div>
                  <h3
                    className="mt-5 text-center"
                    style={{ color: status == "Paid" ? "green" : "red" }}
                  >
                    {PayMessage}
                  </h3>

                  <div className="text-center">
                    <button
                      style={{ width: "5em" }}
                      className="btn btn-primary mt-3"
                      onClick={() => {
                        setOpen(false);
                        setStatus("");
                        setPayMessage("");
                      }}
                    >
                      OK
                    </button>
                  </div>
                </div>
              </Row>
            </Container>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default PendingBill;
