import React from "react";
import Blogsdata from "../../../../utils/data/blogsData.json";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useSelector } from "react-redux";

function Testimonials() {
  const {
    frontData: { testimonials },
    isError,
    isSuccess,
    message,
  } = useSelector((state) => state.front_Data);
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  return (
    <>
      <div className="bannerSection testimonials">
        <div className="container-fluid px-50px">
          <div className="row">
            <div className="col-12 text-center">
              <h1>Testimonials</h1>
              <p className="subTitile">What People Say About Us</p>
            </div>
          </div>
          {testimonials?.length > 0 && (
            <Carousel
              className="mt-5"
              arrows={false}
              swipeable={true}
              showDots
              responsive={responsive}
              infinite={true}
            >
              {testimonials &&
                testimonials?.length > 0 &&
                testimonials?.map((item) => (
                  <div id={item._id}>
                    <div className="row gx-5 mx-0 py-5 px-sm-5  shadowBox">
                      <div className="col-md-5">
                        <img
                          src={item.coverImage}
                          className="w-100 testimonialImg"
                          alt={item.name}
                        />
                      </div>
                      <div className="col-md-7 mt-md-0 mt-4">
                        <div className="testimonialText">
                          <img src="/imgs/comma-left.png" alt="" />
                          <div className="position-relative">
                            <img src="/imgs/comma-left.svg" width={30} alt="" />
                            <p>{item.description}</p>
                            <div className="text-end">
                              <img
                                src="/imgs/comma-right.svg"
                                width={30}
                                alt=""
                              />
                            </div>
                          </div>
                          <p>
                            <h1>{item.name}</h1>
                          </p>
                          <p className="smallPera">{item.designation}</p>
                          <img src="/imgs/comma-right.png" alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                ))}

              {/* <div>
              <div className="row gx-5 mx-0 py-5 px-sm-5  shadowBox">
                <div className="col-md-5">
                  <img
                    src="/imgs/review1.jpg"
                    className="w-100 testimonialImg"
                    alt=""
                  />
                </div>
                <div className="col-md-7 mt-md-0 mt-4">
                  <div className="testimonialText">
                    <img src="/imgs/comma-left.png" alt="" />
                    <div className="position-relative">
                      <img src="/imgs/comma-left.svg" width={30} alt="" />
                      <p>
                        Willie has been entrusted with the management 
                        of my property for close to 7 years. His team 
                        at Little Big Red Dot created a 360 tour of my 
                        property which allows prospects to view the 
                        apartment without having to leave home. This has
                         allowed viewings to be extremely efficient during
                          the pandemic where visitation is limited
                      </p>
                      <div className="text-end">
                        <img src="/imgs/comma-right.svg" width={30} alt="" />
                      </div>
                    </div>
                    <p>
                      <h1>Mr wee</h1>
                    </p>
                    <p className="smallPera">
                      Altez Condo Owner
                    </p>
                    <img src="/imgs/comma-right.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="row gx-5 mx-0 py-5 px-sm-5  shadowBox">
                <div className="col-md-5">
                  <img
                    src="/imgs/review2.jpg"
                    className="w-100 testimonialImg"
                    alt=""
                  />
                </div>
                <div className="col-md-7 mt-md-0 mt-4">
                  <div className="testimonialText">
                    <img src="/imgs/comma-left.png" alt="" />
                    <div className="position-relative">
                      <img src="/imgs/comma-left.svg" width={30} alt="" />
                      <p>
                        The 360 tour brings about an immersive 
                        experience for customers who has not been  
                        to the restaurant. The 360tour.sg team is also 
                        responsive, and did a great job I'm designing 
                        the 360tour according to our requirements. 
                        Highly recommended.
                      </p>
                      <div className="text-end">
                        <img src="/imgs/comma-right.svg" width={30} alt="" />
                      </div>
                    </div>
                    <p>
                      <h1>Edmund Chan</h1>
                    </p>
                    <p className="smallPera">
                      Owner, Stirling Steaks
                    </p>
                    <img src="/imgs/comma-right.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="row gx-5 mx-0 py-5 px-sm-5  shadowBox">
                <div className="col-md-5">
                  <img
                    src="/imgs/review3.jpg"
                    className="w-100 testimonialImg"
                    alt=""
                  />
                </div>
                <div className="col-md-7 mt-md-0 mt-4">
                  <div className="testimonialText">
                    <img src="/imgs/comma-left.png" alt="" />
                    <div className="position-relative">
                      <img src="/imgs/comma-left.svg" width={30} alt="" />
                      <p>
                      像我一般海外投资者来说。360TOURS.SG 不单为我们创造了崭新又方便的观 請方式,也让我们对新加坡房地产有了更踏实的信心,
                      </p>
                      <div className="text-end">
                        <img src="/imgs/comma-right.svg" width={30} alt="" />
                      </div>
                    </div>
                    <p>
                      <h1>王先生</h1>
                    </p>
                    <p className="smallPera">
                      Foreign Investor
                    </p>
                    <img src="/imgs/comma-right.png" alt="" />
                  </div>
                </div>
              </div>
            </div> */}
            </Carousel>
          )}
        </div>
      </div>
    </>
  );
}

export default Testimonials;
