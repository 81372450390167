import React, { useState, useLayoutEffect, useEffect } from "react";
import { tourReview_column } from "../../../utils/constant/column_header_data";
import { Modal, Button, InputGroup, Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { Stack, Rating } from "@mui/material";
import { toast, ToastContainer } from "react-toastify";
import { useSelector } from "react-redux";
import {
  getArticlePublishedAll,
  reset,
} from "../../../features/Articles/articleSlice";
import {
  replyReview,
  reset as reset1,
  getTour,
  clearList,
} from "../../../features/360Tours/tourSlice";
import DataTable from "react-data-table-component";
import { deleteReview } from "../../../features/360Tours/tourSlice";
import { formatDate } from "../../../utils/DateHelper";

function TourReviews() {
  const user = JSON.parse(localStorage.getItem("user"));
  const ID = user?.data?.user?._id;

  const { tour_List, isError, isSuccess, message, isList, imageLocation } =
    useSelector((state) => state.tours);
  const { frontData } = useSelector((state) => state.front_Data);

  const dispatch = useDispatch();

  const [show, setShow] = useState(false);

  const [Search, setSearch] = useState("");
  const [selectedRows, setSelectedRows] = useState(false);
  const [CList, setCList] = useState([]);
  const [CList2, setCList2] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [reply, setReply] = useState([{}]);

  const [D_ID, setD_ID] = useState();
  const [show2, setShow2] = useState(false);

  const handleClose = () => setShow(false);
  const handleClose2 = () => {
    setShow2(false);
    setD_ID("");
  };
  const handleShow = (val) => {
    if (
      user?.data?.user?.role === "staff" &&
      user?.data?.user?.permissions[0]?.ArticlesReviews?.some(
        (val) => val === "view"
      )
    ) {
      let data = [...tour_List];
      data = data.filter((v) => v._id === val._id);
      setCList2(data);
      setShow(true);
    } else if (user?.data?.user?.role !== "staff") {
      let data = [...tour_List];
      data = data.filter((v) => v._id === val._id);
      setCList2(data);
      setShow(true);
    } else {
      toast.error("Permission denied!");
    }
  };
  const handleReplyChange = (e, index, id) => {
    const { value } = e.target;
    setReply((prevReply) => {
      const updatedReply = [...prevReply];
      updatedReply[index] = { id, value };
      return updatedReply;
    });
  };
  const addReply = async (tourId, reviewId) => {
    const data = {
      userId: user?.data?.user?._id,
      name: user?.data?.user?.role,
      comment: reply?.filter((item) => item.id == reviewId)[0]?.value,
      tourId,
      reviewId: reviewId,
    };
    dispatch(replyReview(data));
    dispatch(reset());
    setTimeout(() => dispatch(getTour("All")), 1000);
    // setRefresh(!refresh);
    handleClose();
    setReply("");
  };

  useLayoutEffect(() => {
    // dispatch(clearList())
    dispatch(reset());
    dispatch(getArticlePublishedAll());
  }, [dispatch]);

  useLayoutEffect(() => {
    dispatch(clearList());
    dispatch(reset1());
    if (user?.data?.user?.role === "admin") {
      dispatch(getTour("All"));
    }
    if (user?.data?.user?.role === "staff") {
      dispatch(getTour("staff"));
    }
    if (
      user?.data?.user?.role === "contributor" ||
      user?.data?.user?.role === "user"
    ) {
      dispatch(getTour(ID));
    }
  }, [refresh]);

  useEffect(() => {
    // dispatch(getArticlePublishedAll());
    if (tour_List) {
      if (user?.data?.user?.role === "admin") {
        let filtered = tour_List.filter((item) => item?.reviews.length > 0);
        return setCList(filtered);
      }
      let newData = tour_List
        ?.filter(
          (val) =>
            val?.owner?._id === user?.data?.user?._id && val?.reviews.length > 0
        )
        .sort((a, b) => {
          const aDate =
            a.reviews.length > 0
              ? new Date(
                  Math.max(...a.reviews.map((o) => new Date(o.createdAt)))
                )
              : new Date(-8640000000000000);
          const bDate =
            b.reviews.length > 0
              ? new Date(
                  Math.max(...b.reviews.map((o) => new Date(o.createdAt)))
                )
              : new Date(-8640000000000000);
          if (aDate < bDate) {
            return 1;
          }
          return -1;
        });
      setCList(newData);
    }
  }, [isSuccess, isError, message, isList, imageLocation]);

  const deleteRow = () => {
    let yFilter = selectedRows.map((itemY) => {
      return itemY.id;
    });
    let filteredX = tour_List
      ?.map((val) => val.reviews)
      .filter((itemX) => !yFilter.includes(itemX._id));
    const data = {
      Ids: yFilter,
    };
    // dispatch(deletedRowsArticles(data))
    dispatch(reset());
    setD_ID("");
    setSelectedRows([]);
    setShow2(false);
  };
  const handleDeleteReview = async (reviewId, val) => {
    if (window.confirm("are you sure, you want to delete?")) {
      let result = await dispatch(deleteReview({ reviewId, all: false }));
      if (result?.payload?.status === "Success") {
        toast.success(result?.payload?.message);
        handleClose();
        setRefresh(!refresh);
      }
    }
  };
  const handleDelete = async (val) => {
    if (window.confirm("are you sure, you want to delete?")) {
      let result = await dispatch(
        deleteReview({ _id: val?.reviews[0]?._id, all: true })
      );
      if (result?.payload?.status === "Success") {
        toast.success(result?.payload?.message);
        setRefresh(!refresh);
      }
    }
  };
  const handleDeleteReply = async (reviewId, replyId) => {
    if (window.confirm("are you sure, you want to delete?")) {
      let result = await dispatch(
        deleteReview({ reviewId, replyId, all: false })
      );
      if (result?.payload?.status === "Success") {
        handleClose();
        toast.success(result?.payload?.message);
        setRefresh(!refresh);
      }
    }
  };

  const calculateReplies = (replies) => {
    console.log(replies);
    let count = 0;
    replies?.map((item) => {
      count += item?.replies?.length;
    });
    return count;
  };

  var rows = [];
  if (CList) {
    for (let [i, value] of CList?.entries()) {
      rows[i] = {
        id: value._id,
        name: value?.Name,
        date: formatDate(value?.createdAt, frontData.timezone),
        reviews: (
          <div className="d-flex gap-1 align-items-center">
            {/* <img src="/imgs/star.svg" alt="" /> */}
            <Stack>
              <Rating value={value?.rating} precision={0.5} readOnly />
            </Stack>
            <span>{value?.review ? value.review : 0}</span>
          </div>
        ),
        author: value?.owner?.user_name,
        replies_count: calculateReplies(value?.reviews) || 0,
        action: (
          <>
            <div>
              <div className="d-flex gap-2">
                <img
                  src="/imgs/u_eye.svg"
                  width={20}
                  alt=""
                  onClick={() => handleShow(value)}
                />
                <img
                  src="/imgs/delete.svg"
                  width={20}
                  alt=""
                  onClick={() => handleDelete(value)}
                />
              </div>
            </div>
          </>
        ),
      };
    }
    rows = rows.sort((a, b) => {
      if (b.date > a.date) return 1;
      if (b.date < a.date) return -1;
    });
    // }
  }

  return (
    <div className="staffMainDiv">
      <ToastContainer />
      <div className="row mx-0 mb-4">
        <div className="d-flex justify-content-between">
          <div className="d-flex gap-3">
            <img
              src="/imgs/revoke.svg"
              onClick={() => setSearch("")}
              width={20}
              alt=""
            />
            <Form.Group className="mb-0">
              <InputGroup className="mb-0">
                <InputGroup.Text id="basic-addon1">
                  <img src="/imgs/search.svg" alt="" />
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  placeholder="Search"
                  value={Search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </InputGroup>
            </Form.Group>
          </div>
        </div>
      </div>

      <DataTable
        columns={tourReview_column}
        data={
          rows
            ? rows.filter(
                (item) =>
                  item?.name?.toLowerCase().includes(Search.toLowerCase()) ||
                  item?.author?.toLowerCase().includes(Search.toLowerCase())
              )
            : []
        }
        pagination
      />

      <Modal show={show} onHide={handleClose} centered>
        <div className="d-flex justify-content-end pe-2 pt-2">
          <img
            src="/imgs/revoke.svg"
            className="closeIcon"
            onClick={handleClose}
            alt=""
          />
        </div>
        <Modal.Body>
          <div className="row articleReply">
            <div className="col-md-11 mx-auto">
              <div className="row gx-5 review-comments">
                {CList2?.map((v) =>
                  v?.reviews?.length > 0 ? (
                    v?.reviews?.map((val, i) => (
                      <div className="col-md-12 mb-3" key={i}>
                        <div className="d-flex align-items-center gap-2 w-100">
                          <div>
                            <img src={val?.userId?.profile} alt="" />
                          </div>
                          <div>
                            <h5>
                              {val?.userId?.first_name} {val?.userId?.last_name}
                            </h5>
                            <div className="d-flex align-items-center gap-1">
                              <div className="rating">
                                <Stack>
                                  <Rating
                                    size="small"
                                    value={val?.rating}
                                    precision={0.5}
                                    icon={
                                      <img
                                        style={{
                                          width: "20px",
                                          height: "20px",
                                        }}
                                        src="/imgs/ratingStar.svg"
                                        alt=""
                                      />
                                    }
                                    readOnly
                                  />
                                </Stack>
                              </div>
                              <h6>{val?.createdAt?.substring(0, 10)}</h6>
                            </div>
                          </div>
                          <div className="align-self-end ms-auto">
                            <img
                              src="/imgs/delete.svg"
                              width={20}
                              style={{
                                height: "24px",
                              }}
                              alt=""
                              onClick={() => handleDeleteReview(val?._id)}
                            />
                          </div>
                        </div>
                        {val?.description && <p>{val?.description}</p>}
                        {val?.reviewImages?.map((item) => (
                          <img
                            src={item?.img}
                            alt="review"
                            style={{ width: "100px", height: "70px" }}
                          />
                        ))}
                        <div className="m-4 me-0">
                          {val?.replies.map((item, i) => (
                            <>
                              <div
                                className="d-flex align-items-center gap-2 w-100"
                                key={i}
                              >
                                <div>
                                  <img src={item?.userId?.profile} alt="" />{" "}
                                </div>
                                <div>
                                  <h5>
                                    {item?.userId?.first_name}{" "}
                                    {item?.userId?.last_name}
                                  </h5>
                                  <div className="d-flex align-items-center gap-1">
                                    <h6>{item?.createdAt?.substring(0, 10)}</h6>
                                  </div>
                                </div>
                                <div className="align-self-end ms-auto">
                                  <img
                                    src="/imgs/delete.svg"
                                    width={20}
                                    style={{
                                      height: "24px",
                                    }}
                                    alt=""
                                    onClick={() =>
                                      handleDeleteReply(val._id, item?._id)
                                    }
                                  />
                                </div>
                              </div>
                              <p>{item?.comment}</p>
                              {/* <p>reply by: {item?.name}</p> */}
                            </>
                          ))}
                        </div>
                        <div className="replyDiv">
                          <div className="d-flex justify-content-between align-items-center">
                            <div className="d-flex align-items-center w-100">
                              {/* <img src="/imgs/profile.png" alt="" /> */}
                              <Form.Control
                                className="border-0 w-100"
                                type="text"
                                name="first_name"
                                placeholder="Reply"
                                value={reply[i]?.value || ""}
                                onChange={(e) =>
                                  handleReplyChange(e, i, val._id)
                                }
                              />
                            </div>
                            <Button
                              className="loginBtn"
                              onClick={() => addReply(v?._id, val?._id)}
                            >
                              Reply
                            </Button>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="text-center">No Reviews</div>
                  )
                )}
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={show2}
        onHide={handleClose2}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete Reviews</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this articles?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose2}>
            Close
          </Button>
          <Button variant="primary" onClick={deleteRow}>
            Sure
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default TourReviews;
