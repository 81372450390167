import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import questionService from "./questionService";

// Get user from localStorage
// const contrib = JSON.parse(localStorage.getItem('Users'))

const initialState = {
  questions_List: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
  isList: false,
  ansAdded: false,
  isAdded: false,
  isUpdated: true,
};

// get question list
export const getQuestions = createAsyncThunk(
  "question/getQuestions",
  async (ID, thunkAPI) => {
    try {
      return await questionService.getQuestions(ID);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const addQuestion = createAsyncThunk(
  "question/addQuestion",
  async (userData, thunkAPI) => {
    try {
      return await questionService.addQuestion(userData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const submitAnswer = createAsyncThunk(
  "question/submitAnswer",
  async (userData, thunkAPI) => {
    try {
      return await questionService.submitAnswer(userData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getDetails = createAsyncThunk(
  "question/getDetails",
  async (ID, thunkAPI) => {
    try {
      return await questionService.getDetails(ID);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getQuestionsScore = createAsyncThunk(
  "question/getQuestionsScore",
  async (ID, thunkAPI) => {
    try {
      return await questionService.getQuestionsScore(ID);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const editQuestion = createAsyncThunk(
  "question/editQuestion",
  async (userData, thunkAPI) => {
    try {
      return await questionService.editQuestion(userData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const deleteQuestion = createAsyncThunk(
  "question/deleteQuestion",
  async (ID, thunkAPI) => {
    try {
      return await questionService.deleteQuestion(ID);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// deleted rows
export const deletedRowsQuest = createAsyncThunk(
  "question/deletedRowsQuest",
  async (passData, thunkAPI) => {
    try {
      return await questionService.deletedRowsQuest(passData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const questionSlice = createSlice({
  name: "question",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = "";
      state.isList = false;
      state.isAdded = false;
      state.ansAdded = false;
      state.isUpdated = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getQuestions.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getQuestions.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isList = true;
        // state.message = action.payload.data.message
        state.questions_List = action.payload.data;
      })
      .addCase(getQuestions.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.questions_List = [];
      })

      .addCase(getDetails.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getDetails.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isList = true;
        // state.message = action.payload.data.message
        state.questions_List = action.payload.data;
      })
      .addCase(getDetails.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.questions_List = [];
      })

      .addCase(getQuestionsScore.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getQuestionsScore.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isList = true;
        // state.message = action.payload.data.message
        state.questions_List = action.payload.data;
      })
      .addCase(getQuestionsScore.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.questions_List = [];
      })

      .addCase(addQuestion.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addQuestion.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = action.payload;
        state.isAdded = true;
        // state.questions_List = [...state.questions_List, action.payload.data]
      })
      .addCase(addQuestion.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isAdded = false;
        state.message = action.payload;
        state.questions_List = [];
      })

      .addCase(submitAnswer.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(submitAnswer.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = action.payload;
        state.isAdded = true;
        state.ansAdded = true;
        // state.questions_List = [...state.questions_List, action.payload.data]
      })
      .addCase(submitAnswer.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isAdded = false;
        state.message = action.payload;
        state.ansAdded = false;
        // state.questions_List = []
      })

      .addCase(editQuestion.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(editQuestion.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isUpdated = true;
        state.message = action.payload;
        // state.questions_List = action.payload.data
      })
      .addCase(editQuestion.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isUpdated = false;
        state.message = action.payload;
        state.questions_List = [];
      })

      .addCase(deleteQuestion.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteQuestion.fulfilled, (state, action) => {
        console.log(action?.payload?.message);
        state.isLoading = false;
        state.isSuccess = true;
        state.message = action?.payload?.message;
        // state.Staff_List = action.payload.data
      })
      .addCase(deleteQuestion.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.questions_List = [];
      })

      .addCase(deletedRowsQuest.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deletedRowsQuest.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        // state.user = action.payload
        state.message = action.payload;
      })
      .addCase(deletedRowsQuest.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
        // state.user = null
      });
  },
});

export const { reset } = questionSlice.actions;
export default questionSlice.reducer;
