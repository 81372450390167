import { useEffect, useLayoutEffect, useState } from "react";
import { Form, InputGroup } from "react-bootstrap";
import { billingPaid_column } from "../../../utils/constant/column_header_data";
import Table from "../../Table/Table";
import {
  getQuotation,
  reset,
} from "../../../features/Quotations/quotationSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { formatDate } from "../../../utils/DateHelper";

function BillHistory() {
  const [CList, setCList] = useState([]);

  const user = JSON.parse(localStorage.getItem("user"));

  const ID = user?.data?.user?._id;
  var datee = new Date();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { quotation_List, isError, isSuccess, message, isList } = useSelector(
    (state) => state.quotations
  );
  const { frontData } = useSelector((state) => state.front_Data);
  useLayoutEffect(() => {
    dispatch(reset());
    dispatch(getQuotation());
  }, []);

  useEffect(() => {
    if (quotation_List) {
      const newData = [...quotation_List]
        ?.reverse()
        ?.filter(
          (val) =>
            val.isAccepted == true &&
            val?.owner?._id === ID &&
            val.isPaid == true
        );
      if (newData) {
        setCList(newData);
      }
    }
  }, [dispatch, navigate, isSuccess, isError, message, isList, ID]);
  console.log(CList);

  const onView = (value) => {
    user?.data?.user?.role === "contributor" ||
    user?.data?.user?.role === "user" ||
    (user?.data?.user?.role === "staff" &&
      user?.data?.user?.permissions[0]?.ArticlesOrders?.some(
        (val) => val === "view"
      ))
      ? navigate("/billing/bill-form", {
          state: { Details: value, edit: false },
        })
      : toast.error("Permission denied!");
  };

  var rows = [];
  if (CList) {
    for (let [i, value] of CList?.entries()) {
      rows[i] = {
        id: value._id,
        billing_date: value?.billingDate
          ? formatDate(value?.billingDate, frontData.timezone)
          : datee,
        paidDate: value?.paidDate?.substring(0, 10),
        amount: value?.plan[0]?.pricing_amount,
        status: value?.status,
        billing_no: "N/A",
        invoice_no: "N/A",
        paid_at: formatDate(value.paidDate),
        via: value?.payment_gateway,
        action: (
          <div>
            <div className="d-flex gap-3">
              <img
                src="/imgs/u_eye.svg"
                width={20}
                alt=""
                onClick={() => onView(value)}
              />
            </div>
          </div>
        ),
      };
    }
  }
  return (
    <div className="staffMainDiv">
      <div className="row mx-0 mb-4">
        <ToastContainer />
        <div className="d-flex justify-content-between">
          <div className="d-flex gap-3">
            <img src="/imgs/revoke.svg" width={20} alt="" />
            <img src="/imgs/delete.svg" width={20} alt="" />
            <Form.Group className="mb-0">
              <InputGroup className="mb-0">
                <InputGroup.Text id="basic-addon1">
                  <img src="/imgs/search.svg" alt="" />
                </InputGroup.Text>
                <Form.Control type="text" placeholder="Search" />
              </InputGroup>
            </Form.Group>
          </div>
          {/* <Link to="/billing/billing-history/bill-form">
            <Button className="loginBtn" type="submit">
              Add New
            </Button>
          </Link> */}
        </div>
      </div>
      <Table header={billingPaid_column} data={rows} />
    </div>
  );
}

export default BillHistory;
