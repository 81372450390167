import React, { useEffect, useState } from "react";
import Header from "../Header/Header";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useNavigate,
  useLocation,
} from "react-router-dom";
import Login from "../../Admin/Login/Login";
import Profile from "../../Admin/Profile/Profile";
import Signup from "../../Customer/Signup/Signup";
import Staff from "../../Admin/UserManagement/Staff/Staff";
import AddStaff from "../../Admin/UserManagement/Staff/AddStaff";
import Contributor from "../../Admin/UserManagement/Contributor/Contributor";
import AddContributor from "../../Admin/UserManagement/Contributor/AddContributor";
import User from "../../Admin/UserManagement/User/User";
import AddUser from "../../Admin/UserManagement/User/AddUser";
import PendingApproval from "../../Staff/Article/PendingApproval/PendingApproval";
import Listing from "../../Staff/Article/Listing/Listing";
import Deleted from "../../Staff/Article/Deleted/Deleted";
import AddArticle from "../../Staff/Article/AddArticle/AddArticle";
import ProductPlan from "../../Admin/Settings/ProductPlan/ProductPlan";
import AddProductPlan from "../../Admin/Settings/ProductPlan/AddProductPlan";
import PaymentGateway from "../../Admin/Settings/PaymentGateway/PaymentGateway";
import AddPaymentGateway from "../../Admin/Settings/PaymentGateway/AddPaymentGateway";
import Navigation from "../../Admin/Settings/Navigations/Navigations";
import UpgradePlan from "../../Admin/Settings/UpgradePlan/UpgradePlan";
import AddUpgradePlan from "../../Admin/Settings/UpgradePlan/AddUpgradePlan";
import PageManagement from "../../Admin/Settings/PageManagement/PageManagement";
import AboutUs from "../../Admin/Settings/PageManagement/AboutUs";
import ContactUs from "../../Admin/Settings/PageManagement/ContactUs";
import OtherPages from "../../Admin/Settings/PageManagement/OtherPages/OtherPages";
import { useSelector } from "react-redux";
import PageNotFound from "../../Admin/Profile/PageNotFound";
import OtherPagesForm from "../../Admin/Settings/PageManagement/OtherPages/otherPagesForm";
import ArticleDetail from "../../Staff/Article/ArticleDetail/ArticleDetail";
import Quotation from "../../Staff/Order/Quotation/Quotation";
import CommissionedJob from "../../Staff/Order/Quotation/CommissionedJob";
import CommissionedJobPublished from "../../Staff/Order/Quotation/CommissionedJobPublished";

import Outstanding from "../../Staff/Order/Outstanding/Outstanding";
import Paid from "../../Staff/Order/Paid/Paid";
import Rejected from "../../Staff/Order/Rejected";
import OrderDetail from "../../Staff/Order/OrderDetail/OrderDetail";
import OrderCommisionDetail from "../../Staff/Order/OrderDetail/OrderCommisionDetail";
import QuestionareDetail from "../../Staff/QuestionareDetail/QuestionareDetail";
import Pending from "../../Staff/Billing/Pending";
import BillingPaid from "../../Staff/Billing/Paid";
import AddBillingDetail from "../../Staff/Billing/AddBillingDetail";
import ChangePassword from "../../Admin/Profile/ChangePassword";
import ForgetPassword from "../../Admin/Profile/ForgetPassword";
import ArticleReviews from "../../Staff/ArticleReviews/ArticleReviews";
import Tours from "../../Staff/Tours/Tours";
import AddTour from "../../Staff/Tours/AddTour";
import Forget from "../../ForgetPass/Forget";
import TourList from "../../Staff/Tours/TourList";
import PendingBill from "../../Common/Billing/PendingBill";
import BillHistory from "../../Common/Billing/BillingHistory";
import UpgradeAccount from "../../Customer/UpgradeAccount/UpgradeAccount";
import AddBill from "../../Common/Billing/AddBill";
import Notifications from "../Notification/Notification";
import Dashboard from "../../Admin/Dashboard/Dashboard";
import Questionare from "../../Admin/Questionare/Questionare/Questionare";
import QuestionareForm from "../../Admin/Questionare/QuestionareForm/Questionareform";
import QuestionareDetails from "../../Admin/Questionare/QuestionareDetail/QuestionareDetail";
import PublishedContributorArticle from "../../Contributor/Article/PublishedArticle";
import PendingContApproval from "../../Contributor/Article/PendingApproval";
import ApprovedArticle from "../../Contributor/Article/ApprovedArticle";
import Draft from "../../Contributor/Article/Draft";
import OrderProductPlan from "../../Contributor/Order/OrderProductPlan";
import RejectedArticle from "../../Contributor/Article/RejectedArticle";
import DeletedArticle from "../../Contributor/Article/DeletedArticle";
import Home from "../../Customer/Home/Home";
import Sidebar from "../Sidebar/Sidebar";
import VerifyEmail from "./VerifyEmail";
import OtherRoutes from "../../Admin/Settings/PageManagement/OtherRoutes/OtherRoutes";
import TourReviews from "../../Staff/TourReviews/TourReviews";
import Vetting from "../../Admin/Settings/vetting/index";
import PostRequirements from "../../Admin/Settings/vetting/PostRequirements";
import BadWords from "../../Admin/Settings/vetting/BadWord";
import CreditSystem from "../../Admin/Settings/CreditSystem";
import SortingHighlight from "../../Admin/Settings/sortingHighlight";
import Article from "../../Admin/Settings/vetting/postRequirements/Article";
import Tour from "../../Admin/Settings/vetting/postRequirements/Tour";
import Testimonial from "../../Admin/Settings/testimonial";
import PhotoGallery from "../../Staff/PhotoGallery";
import Footer from "../../Admin/Settings/Footer";
import OtherSettings from "../../Admin/Settings/otherSettings";
import PhotoGalleryPage from "../../Customer/Home/_part/PhotoGalleryPage";
import BlogDetails from "../../Customer/Blogs/_part/BlogDetails";
import BillingReminder from "../../Admin/Settings/billingReminder/BillingReminder";

function Page({ sidebar, setSidebar, setProfile, Profile2 }) {
  const user2 = JSON.parse(localStorage.getItem("user"));
  const { user, isLoading, isError, isSuccess, message } = useSelector(
    (state) => state.auth
  );
  const [breadData, setBreadData] = useState();
  const navigate = useNavigate();
  const path = useLocation();

  useEffect(() => {
    if (
      user?.data?.user?.role === "admin" &&
      path.pathname === "/dashboard-login"
    ) {
      navigate("/dashboard");
    }
    if (user?.data?.user?.role !== "admin" && path.pathname === "/login") {
      navigate("/profile");
    }
    if (user?.data?.user?.role === "admin" && path.pathname === "/") {
      navigate("/dashboard");
    }
    if (user?.data?.user?.role !== "admin" && path.pathname === "/") {
      navigate("/profile");
    }

    if (user?.data?.user?.role === "user" && path.pathname === "/dashboard") {
      navigate("/profile");
    }

    let Path = path.pathname.split("/");
    if (Path) {
      setBreadData(Path);
    }
  }, [path, isError, isSuccess, message, navigate]);

  return (
    <>
      <i
        className={`fa-solid fa-bars ${sidebar ? "openSidebar" : ""}`}
        onClick={() => setSidebar(!sidebar)}
      ></i>
      <Sidebar sidebar={sidebar} setProfile={setProfile} />
      <div
        className={`mainOverlay ${sidebar ? "openSidebar" : ""}`}
        onClick={() => setSidebar(false)}
      ></div>
      <div className={`sidePage ${sidebar ? "openSidebar" : "closeSidebar"}`}>
        <Header breadData={breadData} setProfile={setProfile} />
        <div className="px-sm-5 px-2 py-4">
          <Routes>
            <Route path="/profile" exact element={<Profile />} />
            <Route
              path="/blog/:id"
              exact
              element={
                <BlogDetails setProfile={setProfile} Profile={Profile} />
              }
            />
            <Route
              path="/photos-gallery"
              exact
              element={
                <PhotoGalleryPage setProfile={setProfile} Profile={Profile2} />
              }
            />
            <Route
              path="/Other-Pages/*"
              exact
              element={
                <OtherRoutes setProfile={setProfile} Profile={Profile2} />
              }
            />
            {/* <Route
              path="*"
              exact
              element={
                <PageNotFound setProfile={setProfile} Profile={Profile} />
              }
            /> */}
            <Route
              path="/reset-password/:id/:token"
              exact
              element={<Forget />}
            />
            <Route path="/user-management/staff" exact element={<Staff />} />
            <Route
              path="/user-management/contributor"
              exact
              element={<Contributor />}
            />
            <Route
              path="/user-management/staff/staff-form"
              exact
              element={<AddStaff />}
            />
            <Route
              path="/user-management/staff/staff-form/:id"
              exact
              element={<AddStaff />}
            />
            <Route
              path="/user-management/contributor/contributor-form"
              exact
              element={<AddContributor />}
            />
            <Route
              path="/user-management/contributor/contributor-form/:id"
              exact
              element={<AddContributor />}
            />
            <Route path="/user-management/users" exact element={<User />} />
            <Route
              path="/user-management/users/user-form"
              exact
              element={<AddUser />}
            />
            <Route
              path="/user-management/users/user-form/:id"
              exact
              element={<AddUser />}
            />
            <Route
              path="/article/pending-approval"
              exact
              element={<PendingApproval />}
            />
            <Route path="/article/listing" exact element={<Listing />} />
            <Route path="/article/deleted" exact element={<Deleted />} />

            <Route
              path="/article/article-form"
              exact
              element={<AddArticle />}
            />
            <Route
              path="/article/article-form/:id"
              exact
              element={<AddArticle />}
            />
            <Route path="/gallery" exact element={<PhotoGallery />} />
            <Route
              path="/settings/product-plan"
              exact
              element={<ProductPlan />}
            />
            <Route
              path="/settings/product-plan/form"
              exact
              element={<AddProductPlan />}
            />
            <Route
              path="/settings/product-plan/form/:id"
              exact
              element={<AddProductPlan />}
            />
            <Route
              path="/settings/payment-gateway"
              exact
              element={<PaymentGateway />}
            />
            <Route
              path="/settings/navigations"
              exact
              element={<Navigation />}
            />
            {/* <Route
              path="/settings/upgrade-plan"
              exact
              element={<UpgradePlan />}
            /> */}
            {/* <Route
              path="/settings/upgrade-plan/upgradePlan-form"
              exact
              element={<AddUpgradePlan />}
            /> */}
            {/* <Route
              path="/settings/upgrade-plan/upgradePlan-form/:id"
              exact
              element={<AddUpgradePlan />}
            /> */}
            <Route
              path="/settings/page-management"
              exact
              element={<PageManagement />}
            />
            <Route
              path="/settings/payment-gateway/paymentGateway-form"
              exact
              element={<AddPaymentGateway />}
            />
            <Route
              path="/settings/page-management/about-us"
              exact
              element={<AboutUs />}
            />
            <Route
              path="/settings/page-management/contact-us"
              exact
              element={<ContactUs />}
            />
            <Route
              path="/settings/page-management/other-pages"
              exact
              element={<OtherPages />}
            />

            <Route
              path="/settings/page-management/other-pages/otherPages-form"
              exact
              element={<OtherPagesForm />}
            />
            <Route
              path="/settings/page-management/other-pages/otherPages-form/:id"
              exact
              element={<OtherPagesForm />}
            />
            <Route path="/settings/vetting" exact element={<Vetting />} />
            <Route path="/settings/credits" exact element={<CreditSystem />} />
            <Route
              path="/settings/sorting"
              exact
              element={<SortingHighlight />}
            />
            <Route
              path="/settings/testimonial"
              exact
              element={<Testimonial />}
            />
            <Route path="/settings/footer" exact element={<Footer />} />
            <Route path="/settings/other" exact element={<OtherSettings />} />
            <Route
              path="/settings/billing-reminder"
              exact
              element={<BillingReminder />}
            />

            <Route
              path="/settings/vetting/bad-words"
              exact
              element={<BadWords />}
            />
            <Route
              path="/settings/vetting/post-requirements"
              exact
              element={<PostRequirements />}
            />
            <Route
              path="/settings/vetting/article"
              exact
              element={<Article />}
            />
            <Route path="/settings/vetting/tour" exact element={<Tour />} />

            <Route
              path="/article/article-detail/:id"
              exact
              element={<ArticleDetail />}
            />
            <Route path="/orders/quotation" exact element={<Quotation />} />
            <Route
              path="/Commission-Job-List/Unpublished"
              exact
              element={<CommissionedJob />}
            />
            <Route
              path="/Commission-Job-List/Published"
              exact
              element={<CommissionedJobPublished setProfile={setProfile} />}
            />
            <Route path="/orders/outstanding" exact element={<Outstanding />} />
            <Route path="/orders/paid" exact element={<Paid />} />
            <Route path="/orders/rejected" exact element={<Rejected />} />
            <Route
              path="/orders/quotation/order-detail/:id"
              exact
              element={<OrderDetail />}
            />
            <Route
              path="/Commission-Job-List/Unpublished/Order-Detail"
              exact
              element={<OrderCommisionDetail />}
            />
            <Route
              path="/Commission-Job-List/Unpublished/Order-Detail/:id"
              exact
              element={<OrderCommisionDetail />}
            />
            <Route
              path="/orders/outstanding/order-detail"
              exact
              element={<OrderDetail />}
            />
            <Route
              path="/orders/paid/order-detail"
              exact
              element={<OrderDetail />}
            />
            <Route
              path="/questionare-detail"
              exact
              element={<QuestionareDetail />}
            />
            <Route path="/billing/pending" exact element={<Pending />} />
            <Route path="/billing/paid" exact element={<BillingPaid />} />
            <Route
              path="/billing/detail"
              exact
              element={<AddBillingDetail />}
            />
            <Route
              path="/profile/change-password"
              exact
              element={<ChangePassword />}
            />
            <Route path="/article-reviews" exact element={<ArticleReviews />} />
            <Route path="/tour-reviews" exact element={<TourReviews />} />
            <Route path="/360-tours" exact element={<Tours />} />
            <Route path="/360-tours/tours-form" exact element={<AddTour />} />
            <Route
              path="/360-tours/tours-form/:id"
              exact
              element={<AddTour />}
            />
            {/* <Route path="/360-tours/tour-list" exact element={<TourList />} /> */}
            <Route
              path="/360-tours/tour-list/:id"
              exact
              element={<TourList />}
            />
            {/* <Route
              path="/billing/pending-bill"
              exact
              element={<PendingBill />}
            /> */}
            <Route
              path="/billing/pending-bill"
              exact
              element={<PendingBill />}
            />
            <Route
              path="/billing/bill-history"
              exact
              element={<BillHistory />}
            />
            <Route path="/upgradeAccount" exact element={<UpgradeAccount />} />
            <Route path="/billing/bill-form" exact element={<AddBill />} />
            <Route path="/billing/bill-form/:id" exact element={<AddBill />} />

            <Route path="/notifications" exact element={<Notifications />} />
            <Route path="/dashboard" exact element={<Dashboard />} />
            <Route path="/questionnaire" exact element={<Questionare />} />
            <Route
              path="/add-questionnaire"
              exact
              element={<QuestionareForm />}
            />
            <Route
              path="/add-questionnaire/:id"
              exact
              element={<QuestionareForm />}
            />
            <Route
              path="/questionnaire-details/:id"
              exact
              element={<QuestionareDetails />}
            />
            <Route
              path="/published"
              exact
              element={<PublishedContributorArticle />}
            />
            <Route
              path="/pending-approval"
              exact
              element={<PendingContApproval />}
            />
            <Route
              path="/article/approved"
              exact
              element={<ApprovedArticle />}
            />
            <Route
              path="/article/rejected"
              exact
              element={<RejectedArticle />}
            />
            <Route path="/article/delete" exact element={<DeletedArticle />} />
            <Route path="/article/draft" exact element={<Draft />} />
            <Route path="/order/details" exact element={<OrderProductPlan />} />
            <Route path="/verifyEmail/:token" exact element={<VerifyEmail />} />
          </Routes>
        </div>
      </div>
    </>
  );
}

export default Page;
