import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { getResetPass, postResetPass } from '../../features/auth/authSlice';
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";

const Forget = () => {

    const path = useLocation();
const id = path.pathname.split("/")[2];
const token = path.pathname.split("/")[3];

const navigate = useNavigate();

const {user, isSuccess, isError, isLoading,message} = useSelector(state => state.auth)

const [password, setPassword] = useState('')
const [password2, setConfirmPassword] = useState('')
const [email, setEmail] = useState('')
const [ShowPass, setShowPass] = useState('password')


const dispatch = useDispatch();

useEffect(() => {
  const link = async () =>{
    try {
        dispatch(getResetPass())
    } catch (error) {
      console.log(error)
    }  
   
  }    
  link()
  }, [path,isSuccess, isError, ShowPass]);

  const reset_Password = async (e) =>{
    e.preventDefault();
    if(password===password2){
    try {

        dispatch(postResetPass({id:id,token: token, password: password}))
        if(isSuccess || message){
            navigate("/dashboard");
        }
    // const reset = await axios.post(`/api/reset-password/${id}/${token}`,{password})
    //   window.alert("Your Password Reset Successfully!!!");
    //   props.history.push('/signin');
   
  } catch (error) {
      console.log("Error is: ",error)
    }
  }else{
    window.alert("Password Not match!!");
  }

} 

const showPassword = () => {
  if(ShowPass == 'password'){
    setShowPass('text')
  }
  if(ShowPass == 'text'){
    setShowPass('password')
  }
}

    return (
        <>
{/* <div className="container">
   
                <h1 className="App mt-5 mb-5">Reset Password</h1>
            <div className="row p-5">
            <div className = "card p-5 shadow-sm" style={{backgroundColor:"#f1f1f1"}}>    
                    <form className="contact-form" onSubmit={reset_Password}>
                    <div className="mb-3">
    <label for="exampleInputPassword1" className="form-label"><strong>Password</strong></label>
    <input type="password" className="form-control" id="exampleInputPassword1" required onChange= {(e)=>setPassword(e.target.value)} />
  </div>
  <div class="mb-3">
    <label for="exampleInputPassword1" className="form-label"><strong>Confirm Password</strong></label>
    <input type="password" className="form-control" id="exampleInputPassword1" required onChange= {(e)=>setConfirmPassword(e.target.value)} />
  </div>
      <br/>
      <button type="submit" className="btn btn-danger mb-2">Reset Password</button>
    </form>
                    </div>
                   

            </div>
          
            </div> */}
<div className="cardSection">
        <div className="d-flex justify-content-between">
          <h1>Reset Password</h1>
          <div className="d-flex justify-content-center gap-4 mb-3">
            <Button className="loginBtn" type="submit" onClick={reset_Password}>
              Submit
            </Button>
          </div>
        </div>
        <div className="row mx-0 mt-4">
          <div className="col-6 mx-auto">
            <div className="row gx-5">
              <div className="col-md-12">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>New Password</Form.Label>
                  <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1">
                      <img src="/imgs/password.svg" alt="" onClick={showPassword} />
                    </InputGroup.Text>
                    <Form.Control type={ShowPass} required onChange= {(e)=>setPassword(e.target.value)}  />
                  </InputGroup>
                </Form.Group>
              </div>
              <div className="col-md-12">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Confirm Password</Form.Label>
                  <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1">
                      <img src="/imgs/password.svg" alt="" onClick={showPassword}/>
                    </InputGroup.Text>
                    <Form.Control type={ShowPass} required onChange= {(e)=>setConfirmPassword(e.target.value)} />
                  </InputGroup>
                </Form.Group>
              </div>
            </div>
          </div>
        </div>
      </div>
        </>
    )
}

export default Forget