import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

function Footer() {
  const {
    frontData: { footer },
    isError,
    isSuccess,
    message,
  } = useSelector((state) => state.front_Data);
  return (
    <div className="footerMain">
      <img src="/imgs/footerTopImg.png" className="w-100" alt="" />
      <div className="footer">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <img src="/imgs/footerLogo.png" className="footerLogo" alt="" />
              <p>{footer?.description}</p>
            </div>
            <div className="col-md-1"></div>
            <div className="col-md-5">
              <div className="d-flex gap-5">
                <div>
                  <h4>Links</h4>
                  <ul>
                    {footer &&
                      footer?.links.length > 0 &&
                      footer?.links?.map((item) => (
                        <Link to={item?.url}>
                          <li>{item.name}</li>
                        </Link>
                      ))}
                  </ul>
                </div>
                <ul
                  className="ms-5"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  {footer &&
                    footer?.socialLinks.length > 0 &&
                    footer?.socialLinks?.map((item) => (
                      <a href={`${item.url}`} target="_blank">
                        <img
                          src={`/imgs/${item.name.toLowerCase()}.svg`}
                          width={22}
                          alt=""
                        />
                      </a>
                    ))}
                </ul>
              </div>
            </div>
          </div>
          <div className="row mt-4">
            <h6>{footer && footer?.copyright}</h6>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
