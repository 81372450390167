import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import aboutService from './AboutusService'

const initialState = {
  aboutus: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
}


export const getAboutus = createAsyncThunk(
  'about/getAboutus',
  async (thunkAPI) => {
    try {
      return await aboutService.AboutData()
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)


export const addAboutus = createAsyncThunk(
  'about/addAboutUs',
  async (convertedContent,thunkAPI) => {
    try {
      return await aboutService.addAboutusData(convertedContent)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const updateAboutus = createAsyncThunk(
  'about/updateAboutUs',
  async (convertedContent,thunkAPI) => {
    try {
      return await aboutService.updateAboutusData(convertedContent)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const aboutSlice = createSlice({
  name: 'about',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false
      state.isSuccess = false
      state.isError = false
      state.message = ''
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addAboutus.pending, (state) => {
        state.isLoading = true
      })
      .addCase(addAboutus.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.message = 'About Us Added!'
        // state.product_plans = [...state.product_plans, action.payload.data] 
      })
      .addCase(addAboutus.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
        state.aboutus = null
      })
      
      .addCase(updateAboutus.pending, (state) => {
        state.isLoading = true
      })
      .addCase(updateAboutus.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.message = 'About Us Updated!'
        // state.product_plans = [...state.product_plans, action.payload.data] 
      })
      .addCase(updateAboutus.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
        state.aboutus = null
      })

      .addCase(getAboutus.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getAboutus.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.message = 'About Us Added!'
        state.aboutus = action.payload.data
      })
      .addCase(getAboutus.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
        state.aboutus = null
      })

  },
})

export const { reset } = aboutSlice.actions
export default aboutSlice.reducer