import axios from "axios";

const API_URL = `${process.env.REACT_APP_BACKEND_ROUTE}/question/`;

// Get list of Questions
const getQuestions = async (data) => {
  const response = await axios.get(
    API_URL + `questions-list?id=${data.id}&type=${data.type}&name=${data.name}`
  );
  if (response.data) {
    return response.data;
  }
};

const getDetails = async (ID) => {
  const response = await axios.get(API_URL + `question-details/${ID}`);
  if (response.data) {
    return response.data;
  }
};

const getQuestionsScore = async (ID) => {
  const response = await axios.get(API_URL + `question-score/${ID}`);
  if (response.data) {
    return response.data;
  }
};

// Add Questions
const addQuestion = async (userData) => {
  const response = await axios.post(API_URL + "add-question", userData);
  if (response.data) {
    return response.data;
  }
};

// Add Answers
const submitAnswer = async (userData) => {
  const response = await axios.post(API_URL + "add-answers", userData);
  if (response.data) {
    return response.data;
  }
};

const editQuestion = async (userData) => {
  const response = await axios.put(API_URL + "edit-question", userData);
  if (response.data) {
    return response.data;
  }
};

const deleteQuestion = async (ID) => {
  const response = await axios.put(API_URL + `delete-question/${ID}`);
  if (response.data) {
    return response.data;
  }
};

const deletedRowsQuest = async (passData) => {
  const response = await axios.put(API_URL + "deleted-rows", passData);
  return response.data;
};

const questionService = {
  getQuestions,
  addQuestion,
  getDetails,
  editQuestion,
  deleteQuestion,
  deletedRowsQuest,
  getQuestionsScore,
  submitAnswer,
};

export default questionService;
