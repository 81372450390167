import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import navService from './navService'

// Get user from localStorage
// const contrib = JSON.parse(localStorage.getItem('Users'))

const initialState = {
  nav_List: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
}

// get navigations list
export const navigationList = createAsyncThunk(
  'nav/navigationList',
  async (thunkAPI) => {
    try {
      return await navService.navList()
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const addNav = createAsyncThunk(
  'nav/addNav',
  async (navData,thunkAPI) => {
    try {
      return await navService.addNavData(navData)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const updateNav = createAsyncThunk(
  'nav/updateNav',
  async (userData,thunkAPI) => {
    try {
      return await navService.updateNavData(userData)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const updateOrder = createAsyncThunk(
  'nav/updateOrder',
  async (navData,thunkAPI) => {
    try {
      return await navService.updateNavOrder(navData)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const deleteNav = createAsyncThunk(
  'nav/deleteNav',
  async (ID,thunkAPI) => {
    try {
      return await navService.deleteNavData(ID)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const navSlice = createSlice({
  name: 'nav',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false
      state.isSuccess = false
      state.isError = false
      state.message = ''
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(navigationList.pending, (state) => {
        state.isLoading = true
      })
      .addCase(navigationList.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.message = action.payload.data.message
        state.nav_List = action.payload.data 
      })
      .addCase(navigationList.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
        state.nav_List = null
      })

      .addCase(addNav.pending, (state) => {
        state.isLoading = true
      })
      .addCase(addNav.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.message = 'Added!'
        state.nav_List = [...state.nav_List, action.payload.data] 
      })
      .addCase(addNav.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
        state.nav_List = null
      })

      .addCase(updateNav.pending, (state) => {
        state.isLoading = true
      })
      .addCase(updateNav.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.message = 'Updated!'
        // state.Users_List = action.payload.data 
      })
      .addCase(updateNav.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
        state.nav_List = null
      })

      .addCase(deleteNav.pending, (state) => {
        state.isLoading = true
      })
      .addCase(deleteNav.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.message = 'Deleted!'
        // state.nav_List = action.payload.data 
      })
      .addCase(deleteNav.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
        state.nav_List = null
      })
      
      .addCase(updateOrder.pending, (state) => {
        state.isLoading = true
      })
      .addCase(updateOrder.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.message = 'Updated!'
        state.nav_List = action.payload.data 
      })
      .addCase(updateOrder.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
        // state.nav_List = null
      })
  },
})

export const { reset } = navSlice.actions
export default navSlice.reducer