import customAxios from "../../utils/customAxios";

// Get list of Contributors user
const staffList = async () => {
  const response = await customAxios.get('staff/staff-list');
  if (response.data) {
      return response.data
  }
}

// Add Contributors user
const addStaffData = async (userData) => {
  const response = await customAxios.post('staff/add-staff', userData);
  if (response.data) {
      return response.data
  }
}

const editStaffData = async (userData) => {
  const response = await customAxios.put('staff/edit-staff', userData);
  if (response.data) {
      return response.data
  }
}

const deleteStaffData = async (ID) => {
  const response = await customAxios.put(`staff/delete-staff/${ID}`);
  if (response.data) {
      return response.data
  }
}

// Add Image Data
const saveImageS = async (bodyFormData) => {
  const response = await customAxios.post('staff/add-uploads/s3', bodyFormData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    }
  });
  if (response.data) {
      return response.data
  }
}

const updatePassS = async (passData) => {
  const response = await customAxios.post('staff/change-password', passData);
    return response.data;
};

const deletedRowsS = async (passData) => {
  const response = await customAxios.put('staff/deleted-rows', passData);
    return response.data;
};

const staffService = {
    staffList,
    addStaffData,
    editStaffData,
    deleteStaffData,
    saveImageS,
    updatePassS,
    deletedRowsS,
  }
  
  export default staffService
