import React, { useEffect, useState, useRef } from "react";

function Dragable({ EditData, DeleteNav, setData, data }) {
  const dragItem = useRef();
  const dragOverItem = useRef();

  const dragStart = (e, position) => {
    dragItem.current = position;
  };

  const dragEnter = (e, position) => {
    dragOverItem.current = position;
  };

  return (
    <>
      <ul>
        {data &&
          data?.map((item, index) => (
            <li
              key={index}
              // onDragStart={(e) => dragStart(e, index)}
              // onDragEnter={(e) => dragEnter(e, index)}
              // onDragEnd={drop}
              // draggable
              style={{ listStyleType: "none" }}
            >
              <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex gap-4">
                  <img src="/imgs/dots.svg" alt="" />
                  {item.name ? item.name : item.word}
                </div>

                <div className="d-flex gap-3 me-3">
                  <img
                    src="/imgs/t_edit.svg"
                    width={20}
                    alt=""
                    onClick={() => EditData(item, index)}
                  />
                  <img
                    src="/imgs/delete.svg"
                    width={20}
                    alt=""
                    onClick={() => DeleteNav(index)}
                  />
                </div>
                {/* <img src="/imgs/upDown.svg" alt="" /> */}
              </div>
              <a href="#"></a>
            </li>
          ))}
      </ul>
    </>
  );
}
// }
export default Dragable;
