import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  addAboutus,
  getAboutus,
  updateAboutus,
  reset,
} from "../../../../features/AboutUs/AboutusSlice";
import { useSelector } from "react-redux";
import ContentState from "draft-js/lib/ContentState";
import { toast, ToastContainer } from "react-toastify";
import ReactQuill from "../../../Editor/Editor.jsx";

function AboutUs() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { aboutus, isSuccess, message, isLoading, isError } = useSelector(
    (state) => state.about
  );
  const [isData, setIsData] = useState(false);
  const [value, setValue] = useState("");

  const [ID, setID] = useState();

  useEffect(() => {
    dispatch(getAboutus());

    if (aboutus?.length > 0) {
      setIsData(true);
      setID(aboutus[0]?._id);
      if (aboutus[0]?.content != null) {
        setValue(aboutus[0]?.content);
      }
    }
  }, [dispatch, aboutus[0]?.content]);

  const saveData = () => {
    // convertedContent
    dispatch(addAboutus({ convertedContent: value }));
  };
  const updateData = () => {
    // convertedContent
    dispatch(updateAboutus({ convertedContent: value, id: ID }));
    if (isSuccess === true && message !== "") {
      setTimeout(() => {
        toast.success("About Us Added!");
        setTimeout(() => {
          navigate(-1);
        }, 1000); // Delay of 1 second before navigating to the previous page
      }, 1000);
    }

    if (isError == true) {
      toast(message);
    }
    dispatch(reset());
  };

  return (
    <div>
      <ToastContainer />
      <div className="d-flex justify-content-end">
        {isData == true ? (
          <Button className="loginBtn" onClick={updateData}>
            Update
          </Button>
        ) : (
          <Button className="loginBtn" onClick={saveData}>
            Save
          </Button>
        )}
      </div>
      <div className="my-3 ugradePlanEditor">
        <ReactQuill value={value} setValue={setValue} />
      </div>
    </div>
  );
}

export default AboutUs;
