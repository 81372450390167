import React, { useEffect, useState, useLayoutEffect } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { register, reset } from "./../../../features/auth/authSlice";
import { toast, ToastContainer } from "react-toastify";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import validator from "validator";
import PhoneInput from "react-phone-input-2";

function Signup({ setProfile }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [ShowPass, setShowPass] = useState("password");
  const [ShowPass2, setShowPass2] = useState("password");
  const [isChecked, setIsChecked] = useState(false);

  const user2 = JSON.parse(localStorage.getItem("user"));

  const { user, isLoading, isError, isSuccess, message } = useSelector(
    (state) => state.auth
  );

  const PassShow = () => {
    if (ShowPass === "password") {
      setShowPass("text");
      return;
    }
    setShowPass("password");
  };

  const PassShow2 = () => {
    if (ShowPass2 === "password") {
      setShowPass2("text");
      return;
    }
    setShowPass2("password");
  };

  useLayoutEffect(() => {
    dispatch(reset());
  }, []);

  useEffect(() => {
    if (user?.data?.user?.role === "user") {
      setProfile("false");
      navigate("/");
    }

    if (!user) {
      dispatch(reset());
      navigate("/signup");
    }

    if (isError == true && message !== "") {
      toast.error(message);
      dispatch(reset());
    }
    if (user && isSuccess == true && message.message !== "") {
      toast.success(message.message);
      dispatch(reset());
      setTimeout(() => {
        localStorage.setItem("profileData", false);
        setProfile("false");
        navigate("/");
      }, 2000);
    }
    dispatch(reset());
  }, [isError, isSuccess, message, dispatch]);

  const [addFormData, setAddFormData] = useState({
    first_name: "",
    last_name: "",
    user_name: "",
    email: "",
    contact_no: "",
    password: "",
  });

  const [pass, setPass] = useState("");
  const [Cpass, setCpass] = useState("");

  const handleAddFormChange = (event) => {
    event.preventDefault();
    const fieldName = event.target.getAttribute("name");
    const fieldValue = event.target.value;

    const newFormData = { ...addFormData };
    newFormData[fieldName] = fieldValue;

    setAddFormData(newFormData);
  };

  const handlePhoneNumberChange = (event) => {
    const newFormData = { ...addFormData };
    newFormData["contact_no"] = event;
    setAddFormData(newFormData);
  };

  const AddForm = (event) => {
    event.preventDefault();

    if (pass === Cpass) {
      const reg = /(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/;
      if (reg.test(pass)) {
        const userData = {
          first_name: addFormData.first_name,
          last_name: addFormData.last_name,
          user_name: addFormData.user_name,
          email: addFormData.email,
          contact_no: addFormData.contact_no,
          password: pass,
          isChecked: isChecked,
          role: "user",
        };
        dispatch(register(userData));
      } else {
        toast.error(
          "Password must have at least 1 capital letter, 1 number and be a minimum of 8 characters"
        );
      }
    } else {
      toast.error("Password and confirm password did not match!");
      // alert();
    }
  };

  const ResetForm = () => {
    setAddFormData({
      first_name: "",
      last_name: "",
      user_name: "",
      email: "",
      contact_no: "",
      password: "",
    });
    setPass("");
    setCpass("");
    navigate("/");
  };

  return (
    <div className="signupMain">
      <ToastContainer />
      <div className="row mx-0 loginMain">
        <div className="col-12 my-auto">
          <div className="text-center py-5">
            <img
              src="/imgs/logo.svg"
              className="logo"
              width={270}
              alt=""
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/")}
            />
          </div>
          <div className="row mx-0">
            <div className="col-md-11 mx-auto">
              <div className="SignupBox mb-5">
                <h3>Sign Up</h3>
                <p>Please Enter Your Details</p>
                <div className="row">
                  <div className="col-md-10 mx-auto">
                    <Form className="mt-5" onSubmit={AddForm}>
                      <div className="row gx-5">
                        <div className="col-md-6">
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>First Name</Form.Label>
                            <InputGroup className="mb-3">
                              <InputGroup.Text id="basic-addon1">
                                <img src="/imgs/u_user.svg" alt="" />
                              </InputGroup.Text>
                              <Form.Control
                                type="text"
                                value={addFormData.first_name}
                                name="first_name"
                                onChange={handleAddFormChange}
                                placeholder="Enter Your First Name"
                              />
                            </InputGroup>
                          </Form.Group>
                        </div>
                        <div className="col-md-6">
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>Last Name</Form.Label>
                            <InputGroup className="mb-3">
                              <InputGroup.Text id="basic-addon1">
                                <img src="/imgs/u_user.svg" alt="" />
                              </InputGroup.Text>
                              <Form.Control
                                type="text"
                                name="last_name"
                                value={addFormData.last_name}
                                onChange={handleAddFormChange}
                                placeholder="Enter Your Last Name"
                              />
                            </InputGroup>
                          </Form.Group>
                        </div>
                        <div className="col-md-6">
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>User Name</Form.Label>
                            <InputGroup className="mb-3">
                              <InputGroup.Text id="basic-addon1">
                                <img src="/imgs/u_user.svg" alt="" />
                              </InputGroup.Text>
                              <Form.Control
                                type="text"
                                value={addFormData.user_name}
                                name="user_name"
                                onChange={handleAddFormChange}
                                placeholder="Enter Your User Name"
                              />
                            </InputGroup>
                          </Form.Group>

                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>Email</Form.Label>
                            <InputGroup className="mb-3">
                              <InputGroup.Text id="basic-addon1">
                                <img src="/imgs/email.svg" alt="" />
                              </InputGroup.Text>
                              <Form.Control
                                type="email"
                                name="email"
                                value={addFormData.email}
                                onChange={handleAddFormChange}
                                placeholder="Enter Your Email"
                              />
                            </InputGroup>
                          </Form.Group>
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>Contact Number</Form.Label>
                            <InputGroup className="mb-3">
                              <PhoneInput
                                specialLabel="Contact No"
                                country={"sg"}
                                value={addFormData.contact_no}
                                onChange={handlePhoneNumberChange}
                                name="contact_no"
                                containerStyle={{
                                  width: "100%",
                                  height: "100%",
                                }}
                              />
                            </InputGroup>
                          </Form.Group>
                        </div>
                        <div className="col-md-6">
                          <Form.Group
                            className="mb-0"
                            controlId="formBasicPassword"
                          >
                            <Form.Label>Password</Form.Label>
                            <InputGroup className="mb-2">
                              <InputGroup.Text id="basic-addon1">
                                <img src="/imgs/lock_bg1.svg" alt="" />
                              </InputGroup.Text>
                              <Form.Control
                                type={ShowPass}
                                value={pass}
                                onChange={(e) => setPass(e.target.value)}
                                placeholder="Enter Your Password"
                              />
                              {ShowPass === "text" ? (
                                <InputGroup.Text id="basic-addon1">
                                  <VisibilityOffIcon
                                    style={{
                                      cursor: "pointer",
                                      color: "lightgray",
                                    }}
                                    alt=""
                                    onClick={PassShow}
                                  />
                                </InputGroup.Text>
                              ) : (
                                <InputGroup.Text id="basic-addon1">
                                  <VisibilityIcon
                                    style={{
                                      cursor: "pointer",
                                      color: "lightgray",
                                    }}
                                    alt=""
                                    onClick={PassShow}
                                  />
                                </InputGroup.Text>
                              )}
                            </InputGroup>
                          </Form.Group>
                          <Form.Group
                            className="mb-0"
                            controlId="formBasicPassword"
                          >
                            <Form.Label>Confirm Password</Form.Label>
                            <InputGroup className="mb-2">
                              <InputGroup.Text id="basic-addon1">
                                <img src="/imgs/lock_bg1.svg" alt="" />
                              </InputGroup.Text>

                              <Form.Control
                                type={ShowPass2}
                                value={Cpass}
                                onChange={(e) => setCpass(e.target.value)}
                                placeholder="Enter Your Password"
                              />
                              {ShowPass2 === "text" ? (
                                <InputGroup.Text id="basic-addon1">
                                  <VisibilityOffIcon
                                    style={{
                                      cursor: "pointer",
                                      color: "lightgray",
                                    }}
                                    alt=""
                                    onClick={PassShow2}
                                  />
                                </InputGroup.Text>
                              ) : (
                                <InputGroup.Text id="basic-addon1">
                                  <VisibilityIcon
                                    style={{
                                      cursor: "pointer",
                                      color: "lightgray",
                                    }}
                                    alt=""
                                    onClick={PassShow2}
                                  />
                                </InputGroup.Text>
                              )}
                            </InputGroup>
                          </Form.Group>
                          <Form.Group
                            className="mb-3 mt-4"
                            controlId="formBasicCheckbox"
                          >
                            <Form.Check
                              type="checkbox"
                              onChange={(e) => setIsChecked(e.target.checked)}
                              label="I agree to the terms and conditions"
                            />
                          </Form.Group>
                        </div>
                      </div>

                      <div className="d-flex justify-content-center gap-4 mt-5 mb-3">
                        <Button
                          className="loginBtn poppins"
                          type="button"
                          onClick={ResetForm}
                        >
                          Cancel
                        </Button>
                        <Button className="loginBtn poppins" type="submit">
                          Signup
                        </Button>
                      </div>
                      <p className="forgotpassword">
                        Already Have An Account? <Link to="/login">Log In</Link>
                      </p>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Signup;
