import React, { useEffect, useState, useLayoutEffect } from "react";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteContributors,
  reset as contReset,
} from "../../features/contributers/contSlice";
import { deleteStaff, reset } from "../../features/staffs/staffSlice";
import {
  deleteUsers,
  getUsers,
  reset as userReset,
} from "../../features/users/userSlice";
import { Modal, Button } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { formatDate } from "../../utils/DateHelper";

function Table({ header, data, section, Search, userPerm }) {
  const user = JSON.parse(localStorage.getItem("user"));
  // staff useSelector
  const {
    isError: staffError,
    message: staffMessage,
    isSuccess: staffSuccess,
    isList: staffList,
    isLoading: staffLoading,
  } = useSelector((state) => state.staff);
  const { frontData } = useSelector((state) => state.front_Data);

  // contributors useSelector

  const {
    isError: contError,
    message: contMessage,
    isSuccess: contSuccess,
    isList: contList,
    isLoading: contLoading,
  } = useSelector((state) => state.contributors);

  // user useSelector

  const {
    isError: userError,
    message: userMessage,
    isSuccess: userSuccess,
    isList: userList,
    isLoading: userLoading,
  } = useSelector((state) => state.users);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [show, setShow] = useState(false);
  const [revoke, setRevoke] = useState(false);

  const [delID, setDelID] = useState("");

  useLayoutEffect(() => {
    dispatch(userReset());
    dispatch(contReset());
    dispatch(reset());
  }, []);

  useEffect(() => {
    if (staffMessage?.status === "Success") {
      toast.success(staffMessage.message);
      dispatch(reset());
    }
    if (staffError == true) {
      toast.error(staffMessage);
      dispatch(reset());
    }
    if (contMessage?.status === "Success") {
      toast.success(contMessage.message);
      dispatch(contReset());
    }
    if (contError == true) {
      toast.error(contMessage);
      dispatch(contReset());
    }
    if (userMessage?.status === "Success") {
      toast.success(userMessage.message);
      dispatch(userReset());
    }
    if (userError == true) {
      toast.error(userMessage);
      dispatch(userReset());
    }
  }, [staffMessage, contMessage, userMessage]);
  useEffect(() => {
    setTimeout(() => dispatch(getUsers()), 1000);
  }, [delID]);

  const handleClose = () => {
    setDelID("");
    setShow(false);
    setRevoke(false);
  };

  const onView = (ID) => {
    if (section === "contributor") {
      user?.data?.user?.role === "admin" ||
      (user?.data?.user?.role === "staff" &&
        userPerm !== false &&
        userPerm?.some((val) => val === "view"))
        ? navigate(`/user-management/contributor/contributor-form/${ID}`, {
            state: true,
          })
        : toast.error("Permission denied!");
    }

    if (section === "user") {
      user?.data?.user?.role === "admin" ||
      (user?.data?.user?.role === "staff" &&
        userPerm !== false &&
        userPerm?.some((val) => val === "view"))
        ? navigate(`/user-management/users/user-form/${ID}`, { state: true })
        : toast.error("Permission denied!");
    }

    if (section === "staff") {
      navigate(`/user-management/staff/staff-form/${ID}`, { state: true });
    }
  };

  const onEdit = (ID) => {
    if (section === "contributor") {
      user?.data?.user?.role === "admin" ||
      (user?.data?.user?.role === "staff" &&
        userPerm !== false &&
        userPerm?.some((val) => val === "edit"))
        ? navigate(`/user-management/contributor/contributor-form/${ID}`)
        : toast.error("Permission denied!");
    }

    if (section === "user") {
      user?.data?.user?.role === "admin" ||
      (user?.data?.user?.role === "staff" &&
        userPerm !== false &&
        userPerm?.some((val) => val === "edit"))
        ? navigate(`/user-management/users/user-form/${ID}`, {
            state: { edit: true },
          })
        : toast.error("Permission denied!");
    }

    if (section === "staff") {
      navigate(`/user-management/staff/staff-form/${ID}`);
    }
  };

  const onDelete = (ID, isDelete) => {
    setDelID(ID);
    if (isDelete) {
      return setShow(true);
    }
    setRevoke(true);
  };

  const onDelete2 = () => {
    if (section === "contributor") {
      if (
        user?.data?.user?.role === "admin" ||
        (user?.data?.user?.role === "staff" &&
          userPerm !== false &&
          userPerm?.some((val) => val === "delete"))
      ) {
        dispatch(deleteContributors(delID));
        setDelID("");
        setShow(false);
      } else {
        toast.error("Permission denied!");
        setDelID("");
        setShow(false);
      }
    }

    if (section === "user") {
      if (
        user?.data?.user?.role === "admin" ||
        (user?.data?.user?.role === "staff" &&
          userPerm !== false &&
          userPerm?.some((val) => val === "delete"))
      ) {
        dispatch(deleteUsers(delID));
        setDelID("");
        setShow(false);
        setRevoke(false);
      } else {
        toast.error("Permission denied!");
        setDelID("");
        setShow(false);
        setRevoke(false);
      }
    }

    if (section === "staff") {
      dispatch(deleteStaff(delID));
      setDelID("");
      setShow(false);
      setRevoke(false);
    }
  };

  var rows = [];
  if (data) {
    for (let [i, value] of data?.entries()) {
      rows[i] = {
        id: value?._id,
        name: value?.first_name + " " + value.last_name,
        user_name: value?.user_name,
        email: value?.email,
        contact_no: value?.contact_no,
        national_id: value?.national_id,
        createdAt: formatDate(value?.created_at, frontData.timezone),
        status:
          value.status === "Active" ? (
            <p className="status active">{value.status}</p>
          ) : (
            <p className="status revoked">{value.status}</p>
          ),
        last_login: value.last_login
          ? formatDate(value.last_login, frontData.timezone)
          : null,
        action: (
          <div>
            <div className="d-flex gap-3">
              <img
                src="/imgs/u_eye.svg"
                width={20}
                alt=""
                onClick={() => onView(value._id)}
              />
              <img
                src="/imgs/t_edit.svg"
                width={20}
                alt=""
                onClick={() => onEdit(value._id)}
              />
              {value?.isDelete ? (
                <img
                  src="/imgs/delete.svg"
                  width={20}
                  alt=""
                  onClick={() => onDelete(value._id, value?.isDelete)}
                />
              ) : (
                <img
                  src="/imgs/revoke.svg"
                  width={20}
                  alt=""
                  onClick={() => onDelete(value._id, value?.isDelete)}
                />
              )}
            </div>
          </div>
        ),
      };
      if (section === "user") {
        rows = rows.sort((a, b) => {
          return new Date(b.createdAt) - new Date(a.createdAt);
        });

        rows = rows.sort((a, b) => {
          // if (b.createdAt > a.createdAt) return -1;
          if (a.last_login === null && b.last_login !== null) {
            return 1;
          } else if (a.last_login !== null && b.last_login === null) {
            return -1;
          } else {
            return 0;
          }
        });
      }
    }
  }
  if (section == "staff") {
    const staffNotNull = rows.filter((el) => el.last_login != null);
    const staffNull = rows.filter((el) => el.last_login == null);
    const staffNull1 = staffNull.reverse().sort((a, b) => {
      return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
    });
    const reverseArr = staffNotNull.reverse();
    const sortStaff = reverseArr.sort((a, b) => {
      return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
    });
    rows = [...sortStaff, ...staffNull1];
  }
  return (
    <>
      <ToastContainer />
      {section === "contributor" ? (
        <DataTable
          columns={header}
          data={
            rows
              ? rows.filter(
                  (item) =>
                    item.name.toLowerCase().includes(Search.toLowerCase()) ||
                    item?.user_name
                      ?.toLowerCase()
                      .includes(Search.toLowerCase()) ||
                    item?.contact_no
                      ?.toLowerCase()
                      .includes(Search.toLowerCase()) ||
                    item?.email?.toLowerCase().includes(Search.toLowerCase())
                )
              : []
          }
          pagination
        />
      ) : section === "user" ? (
        <DataTable
          columns={header}
          data={
            rows
              ? rows.filter(
                  (item) =>
                    item.name.toLowerCase().includes(Search.toLowerCase()) ||
                    item?.user_name
                      ?.toLowerCase()
                      .includes(Search.toLowerCase()) ||
                    item?.contact_no
                      ?.toLowerCase()
                      .includes(Search.toLowerCase()) ||
                    item?.email?.toLowerCase().includes(Search.toLowerCase())
                )
              : []
          }
          pagination
        />
      ) : section === "staff" ? (
        <DataTable
          columns={header}
          data={
            rows
              ? rows.filter(
                  (item) =>
                    item.name.toLowerCase().includes(Search.toLowerCase()) ||
                    item?.user_name
                      ?.toLowerCase()
                      .includes(Search.toLowerCase()) ||
                    item?.contact_no
                      ?.toLowerCase()
                      .includes(Search.toLowerCase()) ||
                    item?.email?.toLowerCase().includes(Search.toLowerCase())
                )
              : []
          }
          pagination
        />
      ) : (
        <DataTable columns={header} data={data} pagination />
      )}

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Deleted</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this user?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={onDelete2}>
            Sure
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={revoke}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Revoke</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to revoke this user?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={onDelete2}>
            Sure
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
export default Table;
