import React, { useEffect, useLayoutEffect, useState } from "react";
// import Button from "react-bootstrap/Button";
// import Form from "react-bootstrap/Form";
// import InputGroup from "react-bootstrap/InputGroup";
// import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import {
  addOtherPages,
  editOtherPages,
  getOtherPages,
  reset,
} from "../../../../../features/OtherPages/otherPagesSlice";
import { navigationList } from "../../../../../features/navigations/navSlice";

import OtherEditForm from "./OtherEditForm";
import OtherForm from "./OtherForm";
// import { convertFromHTML, EditorState } from "draft-js";
// import { convertToHTML } from "draft-convert";
// import DOMPurify from "dompurify";
// import ContentState from "draft-js/lib/ContentState";
// import validator from "validator";
// import { WithContext as ReactTags } from "react-tag-input";
// import axios from "axios";

const KeyCodes = {
  comma: 188,
  enter: 13,
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];

function OtherPagesForm() {
  const userID = useParams();
  const [Details, setDetails] = useState({});
  const [navData, setNavData] = useState([]);
  const [URl_DATA, setURL_DATA] = useState("");
  const [value, setValue] = useState("");

  const [tags, setTags] = React.useState([]);

  const handleDelete = (i) => {
    setTags(tags.filter((tag, index) => index !== i));
  };

  const handleAddition = (tag) => {
    setTags([...tags, tag]);
  };

  const handleDrag = (tag, currPos, newPos) => {
    const newTags = tags.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);

    // re-render
    setTags(newTags);
  };

  const handleTagClick = (index) => {
    console.log("The tag at index " + index + " was clicked");
  };

  const {
    O_Pages,
    isError,
    message,
    isSuccess,
    isLoading,
    isAdded,
    isUpdated,
  } = useSelector((state) => state.otherPages);
  const {
    nav_List,
    isError: navError,
    isSuccess: navSuccess,
    isLoading: navLoading,
    message: navMessage,
  } = useSelector((state) => state.navData);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useLayoutEffect(() => {
    dispatch(reset());
  }, [dispatch]);

  useEffect(() => {
    dispatch(navigationList());
    if (userID.id) {
      dispatch(getOtherPages());
      if (isSuccess == true) {
        const newData = [...O_Pages];
        var data = newData?.filter((user) => user._id == userID.id);
        setDetails(data[0]);
      }
    }
    if (nav_List.length > 0) {
      // const newData = nav_List.filter((val) => val.editAble == true);
      setNavData(nav_List);
    }
    if (isAdded == true && isSuccess == true && message?.message !== "") {
      toast.success(message?.message);
      dispatch(reset());
      setTimeout(() => {
        navigate(-1);
      }, 1000);
    }

    if (isUpdated == true && isSuccess == true && message?.message !== "") {
      toast.success(message?.message);
      dispatch(reset());
      setTimeout(() => {
        navigate(-1);
      }, 1000);
    }
    if (isError == true && message !== "") {
      toast.error(message);
      dispatch(reset());
    }
  }, [
    dispatch,
    userID,
    isError,
    message,
    isSuccess,
    navError,
    navSuccess,
    navMessage,
    URl_DATA,
    isAdded,
    isUpdated,
  ]);

  const [addFormData, setAddFormData] = useState({
    name: "",
    url: "",
    status: "",
    navigation: "",
    // content: "",
    tags: "",
    description: "",
  });

  const [editFormData, setEditFormData] = useState({
    name: "",
    url: "",
    status: "",
    navigation: "",
    // content: "",
    tags: "",
    description: "",
  });

  const handleAddFormChange = (event) => {
    event.preventDefault();
    const fieldName = event.target.getAttribute("name");
    const fieldValue = event.target.value;
    const newFormData = { ...addFormData };
    // if (fieldName === "navigation") {
    //   setURL_DATA(fieldValue);
    //   newFormData["url"] = fieldValue;
    //   newFormData[fieldName] = fieldValue;
    // } else {
    // const newFormData = { ...addFormData };
    newFormData[fieldName] = fieldValue;
    // }
    setAddFormData(newFormData);
  };

  const AddForm = (event) => {
    event.preventDefault();

    const userData = {
      name: addFormData.name,
      url: addFormData.url,
      status: addFormData.status,
      content: value,
      tags: tags,
      description: addFormData.description,
    };
    // console.log(userData);
    dispatch(addOtherPages(userData));
  };

  const handleEditFormChange = (event) => {
    event.preventDefault();
    const fieldName = event.target.getAttribute("name");
    const fieldValue = event.target.value;
    console.log(`Field Name:${fieldName} Field Value ${fieldValue}`);

    const newFormData = { ...editFormData };

    // if (fieldName === "navigation") {
    //   setURL_DATA(fieldValue);
    //   newFormData["url"] = fieldValue;
    //   newFormData[fieldName] = fieldValue;
    // } else {
    newFormData[fieldName] = fieldValue;
    // }
    console.log(newFormData);

    setEditFormData(newFormData);
  };

  const UpdateForm = (value) => {
    const userData = {
      id: userID.id,
      name: editFormData.name,
      url: editFormData.url,
      status: editFormData.status,
      navigation: editFormData.navigation,
      content: value ? value : "",
      tags: tags,
      description: editFormData.description,
    };
    console.log(userData);
    dispatch(editOtherPages(userData));
  };

  return (
    <div>
      <ToastContainer />
      {Object.keys(userID).length === 0 && userID.constructor === Object ? (
        <OtherForm
          AddForm={AddForm}
          handleAddFormChange={handleAddFormChange}
          setAddFormData={setAddFormData}
          tags={tags}
          setTags={setTags}
          handleDelete={handleDelete}
          handleAddition={handleAddition}
          handleDrag={handleDrag}
          handleTagClick={handleTagClick}
          delimiters={delimiters}
          navData={navData ? navData : []}
          URL_DATA={URl_DATA}
          value={value}
          setValue={setValue}
        />
      ) : (
        <OtherEditForm
          tags={tags}
          setTags={setTags}
          handleDelete={handleDelete}
          handleAddition={handleAddition}
          handleDrag={handleDrag}
          handleTagClick={handleTagClick}
          delimiters={delimiters}
          UpdateForm={UpdateForm}
          handleEditFormChange={handleEditFormChange}
          Details={Details}
          editFormData={editFormData}
          setEditFormData={setEditFormData}
          navData={navData ? navData : []}
          URL_DATA={URl_DATA}
          value={value}
          setValue={setValue}
        />
      )}
    </div>
  );
}

export default OtherPagesForm;
