import axios from "axios";
const API_URL = `${process.env.REACT_APP_BACKEND_ROUTE}/chat`;

const sendPrompt = async (prompt) => {
  const response = await axios.post(API_URL + "/", { prompt });
  if (response.data) {
    console.log(response.data);
    return response.data;
  }
};

const chatgptService = {
  sendPrompt,
};

export default chatgptService;
