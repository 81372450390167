import React, { useState, useEffect, useLayoutEffect } from "react";
import { Form, InputGroup } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  getQuotation,
  reset,
} from "../../../features/Quotations/quotationSlice";
import { billingPending_column } from "../../../utils/constant/column_header_data";

function Pending() {
  const user = JSON.parse(localStorage.getItem("user"));

  const ID = user?.data?.user?._id;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { quotation_List, isError, isSuccess, message, isList } = useSelector(
    (state) => state.quotations
  );
  const { frontData } = useSelector((state) => state.front_Data);

  const [Search, setSearch] = useState("");
  const [CList, setCList] = useState([]);
  const [DelID, setDelID] = useState("");
  const [PayMessage, setPayMessage] = useState("");

  const [show, setShow] = useState(false);

  useLayoutEffect(() => {
    dispatch(reset());
    dispatch(getQuotation());
  }, []);

  useEffect(() => {
    if (quotation_List) {
      const newData = quotation_List?.filter(
        (val) => val.isAccepted == true && val.isPaid == false
      );
      if (newData) {
        const data = [...newData].reverse();
        setCList(data);
      }
    }
  }, [dispatch, navigate, isSuccess, isError, message, isList, ID, PayMessage]);

  const onView = (value) => {
    user?.data?.user?.role === "contributor" ||
    (user?.data?.user?.role === "staff" &&
      user?.data?.user?.permissions[0]?.ArticlesOrders?.some(
        (val) => val === "view"
      )) ||
    user?.data?.user?.role === "admin"
      ? navigate(`/billing/bill-form/${value._id}`, {
          state: { Details: value, edit: false },
        })
      : toast.error("Permission denied!");
  };

  const datee = new Date();
  const day = datee.getDay();
  const year = datee.getFullYear();
  const month = datee.getMonth() + 1;
  const changeFormatDate = (date) => {
    const parts = date.split("-");
    const formattedDate = `${parts[2]}-${parts[1]}-${parts[0]}`;
    return formattedDate;
  };

  var rows = [];
  if (CList) {
    for (let [i, value] of CList?.entries()) {
      rows[i] = {
        id: value._id,
        billing_date: value?.billingDate?.substring(0, 10),
        user: (
          <div>
            <div className="d-flex gap-2 align-items-center">
              <img src={value?.owner?.profile} width={30} alt="Profile image" />
              <span>{value?.owner?.user_name}</span>
            </div>
          </div>
        ),
        amount:
          value?.plan[0]?.pricing_amount > 0
            ? "$" + value?.plan[0]?.pricing_amount
            : 0,
        status: value?.status,
        action: (
          <div>
            <div className="d-flex gap-3">
              <img
                src="/imgs/u_eye.svg"
                width={20}
                alt=""
                onClick={() => onView(value)}
              />
            </div>
          </div>
        ),
      };
    }
  }

  return (
    <div className="staffMainDiv">
      <div className="row mx-0 mb-4">
        <div className="d-flex justify-content-between">
          <div className="d-flex gap-3">
            <img
              src="/imgs/revoke.svg"
              width={20}
              alt=""
              onClick={() => setSearch("")}
            />
            <Form.Group className="mb-0">
              <InputGroup className="mb-0">
                <InputGroup.Text id="basic-addon1">
                  <img src="/imgs/search.svg" alt="" />
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  placeholder="Search"
                  value={Search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </InputGroup>
            </Form.Group>
          </div>
        </div>
      </div>
      <DataTable
        columns={billingPending_column}
        data={
          rows
            ? rows.filter((item) =>
                item?.user?.props?.children?.props?.children[1]?.props?.children
                  ?.toLowerCase()
                  ?.includes(Search?.toLowerCase())
              )
            : []
        }
        pagination
      />
    </div>
  );
}

export default Pending;
