import axios from "axios";

const API_URL = `${process.env.REACT_APP_BACKEND_ROUTE}/upgrade/`;

const upgradeList = async () => {
  const response = await axios.get(API_URL + "upgrade-list");
  if (response.data) {
    return response.data;
  }
};

const addUpgrade = async (userData) => {
  const response = await axios.post(API_URL + "add-upgrade", userData);
  if (response.data) {
    return response.data;
  }
};

const editUpgrade = async (userData) => {
  const response = await axios.put(API_URL + "edit-upgrade", userData);
  if (response.data) {
    return response.data;
  }
};

const deleteUpgrade = async (ID) => {
  const response = await axios.put(API_URL + `delete-upgrade/${ID}`);
  if (response.data) {
    return response.data;
  }
};

const deletedRowsUp = async (passData) => {
  const response = await axios.put(API_URL + "deleted-rows", passData);
  return response.data;
};

const upgradeService = {
  upgradeList,
  addUpgrade,
  editUpgrade,
  deleteUpgrade,
  deletedRowsUp,
};

export default upgradeService;
