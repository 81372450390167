import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import staffService from './staffService'

// Get user from localStorage
// const contrib = JSON.parse(localStorage.getItem('Users'))

const initialState = {
  Staff_List: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
  imageLocation: '',
  isList: false,
}

// get contributer list
export const getStaff = createAsyncThunk(
  'staff/getStaff',
  async (thunkAPI) => {
    try {
      return await staffService.staffList()
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const addStaff = createAsyncThunk(
  'staff/addStaff',
  async (userData,thunkAPI) => {
    try {
      return await staffService.addStaffData(userData)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const editStaff = createAsyncThunk(
  'staff/editStaff',
  async (userData,thunkAPI) => {
    try {
      return await staffService.editStaffData(userData)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const deleteStaff = createAsyncThunk(
  'staff/deleteStaff',
  async (ID,thunkAPI) => {
    try {
      return await staffService.deleteStaffData(ID)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const saveImageS = createAsyncThunk(
  'staff/saveImageS',
  async (bodyFormData,thunkAPI) => {
    try {
      return await staffService.saveImageS(bodyFormData)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

// update password post
export const updatePassS = createAsyncThunk('staff/updatePass', async (passData, thunkAPI) => {
  try {
    return await staffService.updatePassS(passData)
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

// deleted rows
export const deletedRowsS = createAsyncThunk('staff/deletedRowsS', async (passData, thunkAPI) => {
  try {
    return await staffService.deletedRowsS(passData)
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

export const staffSlice = createSlice({
  name: 'staff',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false
      state.isSuccess = false
      state.isError = false
      state.message = ''
      state.isList = false
    },
    resetImage: (state) => {
      state.imageLocation = ''
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getStaff.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getStaff.fulfilled, (state, action) => {
        state.isLoading = false
        // state.isSuccess = true
        state.isList = true
        // state.message = action.payload.data.message
        state.Staff_List = action.payload.data 
      })
      .addCase(getStaff.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
        state.Staff_List = null
      })

      .addCase(addStaff.pending, (state) => {
        state.isLoading = true
      })
      .addCase(addStaff.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.message = action.payload
        // state.Staff_List = [...state.Staff_List, action.payload.data] 
      })
      .addCase(addStaff.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
        state.Staff_List = null
      })

      .addCase(editStaff.pending, (state) => {
        state.isLoading = true
      })
      .addCase(editStaff.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.message = action.payload
        // state.Staff_List = action.payload.data 
      })
      .addCase(editStaff.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
        // state.Staff_List = []
      })

      .addCase(deleteStaff.pending, (state) => {
        state.isLoading = true
      })
      .addCase(deleteStaff.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.message = action.payload
        // state.Staff_List = action.payload.data 
      })
      .addCase(deleteStaff.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
        state.Staff_List = null
      })

      .addCase(saveImageS.pending, (state) => {
        state.isLoading = true
      })
      .addCase(saveImageS.fulfilled, (state, action) => {
        state.isLoading = false
        // state.isSuccess = true
        // state.message = action.payload
        state.imageLocation = action.payload.data
        // state.profile_data = action.payload.data 
      })
      .addCase(saveImageS.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
        state.Staff_List = null
      })

      .addCase(updatePassS.pending, (state) => {
        state.isLoading = true
      })
      .addCase(updatePassS.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.isError = false
        // state.user = action.payload
        state.message = action.payload.data
      })
      .addCase(updatePassS.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.isSuccess = false
        state.message = action.payload
        // state.user = null
      })

      .addCase(deletedRowsS.pending, (state) => {
        state.isLoading = true
      })
      .addCase(deletedRowsS.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.isError = false
        // state.user = action.payload
        state.message = action.payload.data
      })
      .addCase(deletedRowsS.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.isSuccess = false
        state.message = action.payload
        // state.user = null
      })

  },
})

export const { reset, resetImage } = staffSlice.actions
export default staffSlice.reducer