import axios from 'axios'

const API_URL = `${process.env.REACT_APP_BACKEND_ROUTE}/other/`


const othersList = async () => {
  const response = await axios.get(API_URL + 'others-list');
  if (response.data) {
      return response.data
  }
}


const addOtherPages = async (userData) => {
  const response = await axios.post(API_URL + 'add-others', userData);
  if (response.data) {
      return response.data
  }
}

const editOtherPages = async (userData) => {
  const response = await axios.put(API_URL + 'edit-others', userData);
  if (response.data) {
      return response.data
  }
}

const deleteOtherPages = async (ID) => {
  const response = await axios.put(API_URL + `delete-others/${ID}`);
  if (response.data) {
      return response.data
  }
}

const deletedRowsO = async (passData) => {
  const response = await axios.put(API_URL + 'deleted-rows', passData);
    return response.data;
};

const otherPagesService = {
    othersList,
    addOtherPages,
    editOtherPages,
    deleteOtherPages,
    deletedRowsO
  }
  
  export default otherPagesService
