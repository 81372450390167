import React from "react";
import { Link } from "react-router-dom";

function PageManagement() {
  return (
    <div className="pageManagement">
      <div className="row">
        <div className="col-md-4">
          <Link to="/settings/page-management/about-us">
            <div className="block">
              <h1>About Us</h1>
            </div>
          </Link>
        </div>
        <div className="col-md-4">
          <Link to="/settings/page-management/contact-us">
            <div className="block">
              <h1>Contact Us</h1>
            </div>
          </Link>
        </div>
        <div className="col-md-4">
          <Link to="/settings/page-management/other-pages">
            <div className="block">
              <h1>Other Pages</h1>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default PageManagement;
