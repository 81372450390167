import React from "react";
import Table from "../../../Table/Table";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import { paymentGateway_data } from "../../../../utils/constant/column_row_data";
import { paymentGateway_column } from "../../../../utils/constant/column_header_data";

function PaymentGateway() {
  return (
    <div className="staffMainDiv">
      <div className="row mx-0 mb-4">
        <div className="d-flex justify-content-between">
          <div className="d-flex gap-3">
            <img src="/imgs/revoke.svg" width={20} alt="" />
            <img src="/imgs/delete.svg" width={20} alt="" />
            <Form.Group className="mb-0">
              <InputGroup className="mb-0">
                <InputGroup.Text id="basic-addon1">
                  <img src="/imgs/search.svg" alt="" />
                </InputGroup.Text>
                <Form.Control type="text" placeholder="Search" />
              </InputGroup>
            </Form.Group>
          </div>
          <Link to="/settings/payment-gateway/paymentGateway-form">
            <Button className="loginBtn" type="submit">
              Add New
            </Button>
          </Link>
        </div>
      </div>
      <Table header={paymentGateway_column} data={paymentGateway_data} />
    </div>
  );
}

export default PaymentGateway;
