import axios from "axios";

const API_URL = `${process.env.REACT_APP_BACKEND_ROUTE}/dashboard/`;

const allUsers = async () => {
  const response = await axios.get(API_URL + "all-users");
  if (response.data) {
    return response.data;
  }
};

const allUsersService = {
  allUsers,
};

export default allUsersService;
