import React, { useRef } from "react";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import FeatureArticles from "./_part/FeaturedArticles";
import BlogDetails from "./_part/BlogDetails";
import { useState } from "react";
import { useLayoutEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getArticlePublishedAll,
  reset,
  updateCount,
} from "../../../features/Articles/articleSlice";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { toast, ToastContainer } from "react-toastify";
import { getProfile } from "../../../features/MyProfile/profileSlice";

const blackTags = [
  "Singapore",
  "Waterfall",
  "World Tour",
  "Best Resturants in Signapore",
];

function Blog({ setProfile, Profile }) {
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("user"));
  const location = useLocation();
  const { article_List, isError, isSuccess, message, isList, imageLocation } =
    useSelector((state) => state.article);
  const {
    profile_data,
    isLoading,
    isError: isErrorAuth,
    isSuccess: isSuccessAuth,
    message: messageAuth,
  } = useSelector((state) => state.profileData);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const ref = useRef(null);

  const [Details, setDetails] = useState([]);
  const [Articles, setArticles] = useState([]);
  const [showData, setShowData] = useState(false);
  const [Search, setSearch] = useState("");
  const [fetch, setFetch] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (location?.state) {
      setDetails(location?.state);
      setShowData(true);
      navigate({
        ...location,
        state: null,
      });
    }
  }, [location?.state]);

  useLayoutEffect(() => {
    dispatch(reset());
    dispatch(getArticlePublishedAll());
  }, [fetch]);

  useEffect(() => {
    if (article_List) {
      const newData = [...article_List];
      let data = newData
        .filter((val) => val.isPublish == true && val?.isDelete == false)
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      setArticles(data);
    }
  }, [isSuccess, isError, message, fetch]);

  const handleShow = (val) => {
    // const countData = {
    //   userID: user?.data?.user?._id,
    //   articleID: val._id,
    // }
    // dispatch(updateCount(countData))
    navigate(`/blog/${val?._id}`);
    // setDetails(val);
    // setShowData(true);
    // ref.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleClose = () => {
    setDetails([]);
    setShowData(false);
  };

  const SearchFun = () => {
    const newData = [...article_List];
    if (Search?.length <= 0) {
      return setArticles(newData);
    }
    let searchTerm = Search.toLowerCase();
    let filtered = newData?.filter((obj) =>
      obj?.title?.toLowerCase().includes(searchTerm)
    );
    setArticles(filtered);
  };

  return (
    <div className="blogMain">
      <ToastContainer />
      <Header
        user={user}
        userProfile={profile_data}
        setProfile={setProfile}
        Profile={Profile}
      />
      <div className="blogBanner">
        <img src="/imgs/footerLogo.png" className="logo" alt="" />
        <div className="container-fluid px-50px">
          <div className="row mx-0">
            <div className="col-md-7 veticallyCentered mx-auto text-center">
              <h1>Blogs</h1>
              <Form.Group className="mb-3 mt-5" controlId="formBasicEmail">
                <InputGroup className="mb-3">
                  <Form.Control
                    type="text"
                    placeholder="Type To Search"
                    value={Search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                  <InputGroup.Text id="basic-addon1">
                    <Button className="loginBtn" onClick={SearchFun}>
                      Search
                    </Button>
                  </InputGroup.Text>
                </InputGroup>
              </Form.Group>
              {/* <div className="blackTags pt-3">
                {blackTags.map((data, index) => {
                  return <p key={index}>{data}</p>;
                })}
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <div className="featuredArticles">
        <div className="container-fluid px-50px" ref={ref}>
          <h1>Latest Articles</h1>
          <p className="pera">Read Our Latest Virtual Tour Articles</p>

          {/* {showData == true && (
            <BlogDetails
              Details={Details}
              setDetails={setDetails}
              handleClose={handleClose}
              user={user}
              setFetch={setFetch}
              fetch={fetch}
            />
          )} */}

          <FeatureArticles Articles={Articles} handleShow={handleShow} />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Blog;
