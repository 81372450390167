import React from "react";
import { useEffect } from "react";
import { useLayoutEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import {
  getArticlePublishedAll,
  reset,
} from "../../../../features/Articles/articleSlice";

function FeatureBlogs() {
  const user = JSON.parse(localStorage.getItem("user"));
  const { article_List, isError, isSuccess, message, isList, imageLocation } =
    useSelector((state) => state.article);

  const [Articles, setArticles] = useState([]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useLayoutEffect(() => {
    dispatch(reset());
    dispatch(getArticlePublishedAll());
  }, []);
  useEffect(() => {
    if (article_List) {
      let newData = article_List?.filter(
        (val) => val.isPublish == true && val.isFeatured == true
      );
      newData = newData.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );
      setArticles(newData);
    }
    // if(isError == true && message){
    //   toast.error(message);
    //   // dispatch(reset())
    // }
  }, [isSuccess, isError, message]);

  return (
    <>
      <ToastContainer />
      <div className="bannerSection featuredBlogs">
        <div className="container-fluid px-50px">
          <div className="row">
            <div className="col-12 text-center">
              <h1> Featured Blogs</h1>
              <p>Read Our Most Popular Virtual Tour Articles</p>
            </div>
            <div className="col-12 mt-5 pt-3">
              <div className="row">
                {Articles?.slice(0, 3)?.map((data, index) => {
                  return (
                    <div className="col-lg-4 col-md-6 mb-lg-0 mb-3">
                      <div className="blogCard">
                        <img
                          style={{ height: "240px" }}
                          src={data?.cover_images.map((val) => {
                            if (val.cover == true) {
                              return val.img;
                            }
                          })}
                          alt=""
                        />
                        <h2>{data?.title}</h2>
                        <div className="d-flex justify-content-between pt-3">
                          <div className="d-flex gap-1 align-items-center">
                            <img
                              src="/imgs/calender.svg"
                              className="calender"
                              alt=""
                            />
                            <p>{data?.createdAt.substring(0, 10)}</p>
                          </div>
                          <button
                            onClick={() => navigate(`/blog/${data?._id}`)}
                          >
                            Read More{" "}
                            <img
                              src="/imgs/arrow-right.svg"
                              className="arrow"
                              alt=""
                            />
                          </button>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="text-center mt-5">
              <button className="viewAllBlog" onClick={() => navigate("/blog")}>
                View All Blogs
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default FeatureBlogs;
