import React, { useEffect, useLayoutEffect, useState, useRef } from "react";
import { Modal, InputGroup, Button, Form } from "react-bootstrap";
import { getPlans, reset } from "../../../features/ProductPlans/productSlice";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { v4 as uuid } from "uuid";
import axios from "axios";
import { addQuotation } from "../../../features/Quotations/quotationSlice";
import QuestionareDetail from "../../Staff/QuestionareDetail/QuestionareDetail";
import {
  getQuestions,
  reset as resetQuest,
  getDetails,
} from "../../../features/Questions/questionSlice";
import { useValue } from "../../../context/ContextProvider";
import ReactMapGL, {
  GeolocateControl,
  Marker,
  NavigationControl,
} from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import Geocoder from "./Geocoder";
import { Box } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import Swal from "sweetalert2";
function OrderProductPlan() {
  const { product_plans, isError, message, isSuccess, isLoading } = useSelector(
    (state) => state.productPlan
  );
  const {
    state: {
      location: { lng, lat },
    },
    mapdispatch,
  } = useValue();
  const mapRef = useRef();

  const {
    questions_List,
    isSuccess: questSuccess,
    isError: questError,
    message: questMessage,
    isLoading: questLoading,
    isList,
  } = useSelector((state) => state.questions);
  const user = JSON.parse(localStorage.getItem("user"));
  const userID = user?.data?.user?._id;

  const [CList, setCList] = useState([]);
  const [CList2, setCList2] = useState([]);
  // const [value, setValue] = useState("");

  const [Content_Generator, setContent_Generator] = useState("");

  const [Edit, setEdit] = useState(false);
  const [coverImages, setCoverImages] = useState([]);
  const [show, setShow] = useState(false);
  const [showQuest, setShowQuest] = useState(false);
  const [Bool, setBool] = useState(false);
  const [SelfAndQuest, setSelfAndQuest] = useState(false);
  const [country, setCountry] = useState("");
  const [state2, setState] = useState("");
  const [city, setCity] = useState("");
  const [Address, setAddress] = useState("");
  const [postal_code, setPostal_Code] = useState("");
  const [result, setResult] = useState("");
  const [templateQuestions, setTemplateQuestions] = useState({});
  const [questArray, setquestArray] = useState([]);
  const [Answers, setAnswers] = useState([]);
  const [latLng, setLatLng] = useState([]);

  useLayoutEffect(() => {
    dispatch(reset());
    dispatch(getPlans());
  }, []);

  useEffect(() => {
    if (product_plans) {
      const data = product_plans.filter(
        (s) => s.display !== "Hide" && !s.isRevoked
      );
      setContent_Generator(CList2?.content_generator);
      setCList(data);
    }
  }, [
    isSuccess,
    message,
    isError,
    CList2,
    coverImages,
    questSuccess,
    questError,
    questMessage,
  ]);

  const handleClose = () => {
    setContent_Generator("");
    // setPurpose("");
    // setIntended("");
    // setRequest("");
    // setCoverVideo("");
    setCoverImages([]);
    // setThumbnails([]);
    // setConvertedContent(null);
    // setValue("");
    setShow(false);
  };

  const handleCloseQuest = () => {
    setShowQuest(false);
  };

  const handleShow = async (val) => {
    console.log(val, 888);
    let result = await dispatch(
      getQuestions({ id: "all", type: "template", name: val.product_type })
    );
    setTemplateQuestions(result?.payload?.data);
    let questionArray = [];
    const obj = {
      user: userID,
      answer: "",
    };
    for (let i = 0; i < result?.payload?.data?.questions?.length; i++) {
      questionArray = [...questionArray, obj];
    }
    setquestArray(questionArray);
    setCList2(val);
    setShow(true);
  };
  const handleShowQuest = (val, bool) => {
    setCList2(val);
    dispatch(resetQuest());
    if (bool) {
      dispatch(getDetails(val?.plan?.questionaire));
      setBool(bool);
    } else {
      dispatch(getDetails(val?.questionaire));
      setSelfAndQuest(true);
    }
    setShowQuest(true);
  };

  const dispatch = useDispatch();

  const uploadFileHandler = async (e, id, i, score) => {
    const file = e.target.files[0];
    const bodyFormData = new FormData();
    bodyFormData.append("image", file);

    const ImgRes = await axios.put(
      `${process.env.REACT_APP_BACKEND_ROUTE}/plan/uploads/s3`,
      bodyFormData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    const ID = uuid().slice(0, 8);

    const newData = {
      id: ID,
      img: ImgRes.data.data,
      cover: false,
    };
    const data1 = [...questArray[i]?.answer, newData];
    let evt = {
      target: {
        value: data1,
      },
    };
    handleChange(evt, i, id, score);
    // setCoverImages(data1);
  };

  const deleteImg = (i, index) => {
    const newData = [...questArray[i].answer];
    console.log(newData);
    newData.splice(index, 1);
    setquestArray((answer) =>
      answer?.map((list, index) =>
        index === i ? { ...list, answer: newData } : list
      )
    );

    //   const data = newData.filter((s) => s.id !== ID);
    //   setCoverImages(data);
  };

  // const handleCoverMark = (ID) => {
  //   const newData = [...coverImages];
  //   const Index = newData.findIndex((s) => s.id === ID);
  //   if (newData[Index].cover == false) {
  //     newData[Index].cover = true;
  //   } else {
  //     newData[Index].cover = false;
  //   }

  //   setCoverImages(newData);
  // };

  const AddQuoted = async (val) => {
    const newQuestArray = questArray.map((el) => {
      if (el?.answer?.longitude) {
        return {
          ...el,
          answer: {
            ...el.answer,
            longitude: el.answer.latitude,
            latitude: el.answer.longitude,
          },
        };
      } else {
        return el;
      }
    });
    if (val?.product_type === "360 tour") {
      if (val.content_generator === "Self-Generated") {
        let quotation = {
          plan: CList2,
          user: user?.data?.user,
          Content_Generator,
          templateQuestions,
          questArray:
            newQuestArray?.length > 0
              ? newQuestArray?.slice(0, CList?.questionaire?.questions.length)
              : [],
        };
        var totalScore = 0;
        newQuestArray?.forEach((item) =>
          item.score ? (totalScore += Number(item?.score)) : (totalScore += 0)
        );
        quotation = { ...quotation, totalscore: totalScore };
        let result = await dispatch(addQuotation(quotation));
        if (result?.payload?.status === "Success") {
          Swal.fire(
            "Please check your order status in Orders menu (Your order will be rejected if not passed the minimum score)"
          );
        }
        // handleShowQuest(quotation);
        setShow(false);
      } else {
        let quotation = {
          plan: CList2,
          user: user?.data?.user,
          Content_Generator,
          templateQuestions,
          questArray:
            newQuestArray?.length > 0
              ? newQuestArray?.slice(0, CList?.questionaire?.questions.length)
              : [],
        };
        if (val?.content_generator === "Commissioned") {
          if (val?.pricing_type === "Quoted") {
            var totalScore = 0;
            newQuestArray?.forEach((item) =>
              item.score
                ? (totalScore += Number(item?.score))
                : (totalScore += 0)
            );
            quotation = { ...quotation, totalscore: totalScore };
            let result = await dispatch(addQuotation(quotation));
            if (result?.payload?.status === "Success") {
              Swal.fire(
                "Please check your order status in Orders menu (Your order will be rejected if not passed the minimum score)"
              );
            }
            setContent_Generator("");
            setCoverImages([]);
            setShow(false);
          }
        } else {
          var totalScore = 0;
          newQuestArray?.forEach((item) =>
            item.score ? (totalScore += Number(item?.score)) : (totalScore += 0)
          );
          quotation = { ...quotation, totalscore: totalScore };
          let result = await dispatch(addQuotation(quotation));
          if (result?.payload?.status === "Success") {
            Swal.fire(
              "Please check your order status in Orders menu (Your order will be rejected if not passed the minimum score)"
            );
          }
          setContent_Generator("");
          setCoverImages([]);
          setShow(false);
        }
      }
    } else {
      if (val.content_generator === "Self-Generated") {
        const quotation = {
          plan: CList2,
          user: user?.data?.user,
          Content_Generator,
          coverImages,
        };
        let result = await dispatch(addQuotation(quotation));
        if (result?.payload?.status === "Success") {
          Swal.fire(
            "Please check your order status in Orders menu (Your order will be rejected if not passed the minimum score)"
          );
        }
        // handleShowQuest(quotation, true);
      } else {
        let quotation = {
          plan: CList2,
          user: user?.data?.user,
          Content_Generator,
          coverImages,
          questArray: newQuestArray,
          templateQuestions,
        };

        if (
          val?.content_generator === "Commissioned" &&
          val?.product_type === "Blog"
        ) {
          if (val?.pricing_type === "Quoted") {
            var totalScore = 0;
            newQuestArray?.forEach((item) =>
              item.score
                ? (totalScore += Number(item?.score))
                : (totalScore += 0)
            );
            quotation = { ...quotation, totalscore: totalScore };
            let result = await dispatch(addQuotation(quotation));
            if (result?.payload?.status === "Success") {
              Swal.fire(
                "Please check your order status in Orders menu (Your order will be rejected if not passed the minimum score)"
              );
            }
            setContent_Generator("");
            setCoverImages([]);
            setShow(false);
          }
        } else {
          var totalScore = 0;
          newQuestArray?.forEach((item) =>
            item.score ? (totalScore += Number(item?.score)) : (totalScore += 0)
          );
          quotation = { ...quotation, totalscore: totalScore };
          let result = await dispatch(addQuotation(quotation));
          if (result?.payload?.status === "Success") {
            Swal.fire(
              "Please check your order status in Orders menu (Your order will be rejected if not passed the minimum score)"
            );
          }
          setContent_Generator("");
          setCoverImages([]);
          setShow(false);
        }
      }
    }
  };
  const handleGeolocate = async (event, i, id, score) => {
    const { longitude, latitude } = event.coords;
    const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${longitude},${latitude}.json?access_token=${process.env.REACT_APP_MAP_TOKEN}`;
    try {
      const response = await fetch(url);
      const data = await response.json();

      const result = data.features[0];
      const address = result.place_name;
      const country = result.context.find((c) => c.id.includes("country")).text;
      const state = result.context.find((c) => c.id.includes("region")).text;
      const city = result.context.find((c) => c.id.includes("place")).text;
      const postCode = data.features.find((c) =>
        c.id.includes("postcode")
      )?.text;
      setLatLng([longitude, latitude]);
      let event = {};
      event.target = {
        value: {
          country,
          state,
          city,
          postalCode: postCode,
          address,
          longitude,
          latitude,
        },
      };
      handleChange(event, i, id, score);

      mapdispatch({
        type: "UPDATE_LOCATION",
        payload: { lng: longitude, lat: latitude },
      });
      setAddress(address);
      setCountry(country);
      setState(state);
      setCity(city);
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const handleChange = (e, i, id, score) => {
    const { value } = e.target;
    setquestArray((answer) =>
      answer?.map((list, index) =>
        index === i ? { ...list, answer: value, score, questId: id } : list
      )
    );
  };
  const handleMarkerChange = async (lat, lng, i, id, score) => {
    const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${lng},${lat}.json?access_token=${process.env.REACT_APP_MAP_TOKEN}`;
    try {
      const response = await fetch(url);
      const data = await response.json();
      console.log(data);
      const result = data.features[0];
      const address = result.place_name;
      const country = result.context.find((c) =>
        c.id.includes("country")
      )?.text;
      const state = result.context.find((c) => c.id.includes("region"))?.text;
      const city = result.context.find((c) => c.id.includes("place"))?.text;
      const postCode = data.features.find((c) =>
        c.id.includes("postcode")
      )?.text;
      mapdispatch({
        type: "UPDATE_LOCATION",
        payload: { lng, lat },
      });
      console.log(country);
      setAddress(address);
      setCountry(country);
      setState(state);
      setCity(city);
      setPostal_Code(postCode);
      setLatLng([lng, lat]);
      let event = {};
      event.target = {
        value: {
          country,
          state,
          city,
          postalCode: postCode,
          address,
          latitude: lng,
          longitude: lat,
        },
      };
      handleChange(event, i, id, score);
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const handleGeoLocate = (value, index, id, score) => {
    console.log(value);
    let event = {};
    event.target = {
      value,
    };
    handleChange(event, index, id, score);
  };

  console.log(questArray);

  return (
    <div className="orderProductPlan">
      <ToastContainer />
      {CList?.map((val, i) => (
        <React.Fragment key={i}>
          <div
            className={`cardSection mb-3 ${
              val.pricing_type === "Quoted" && "getQuoted"
            }`}
            key={i}
          >
            <div className="d-flex justify-content-between">
              <h1>{val.name}</h1>
            </div>
            <div className="row dividerRow">
              <hr />
            </div>
            <h3>description</h3>
            <p className="mb-4">{val.description}</p>
            {val.pricing_type === "Valued" && (
              <span className="credits">credits: {val.product_credit}</span>
            )}
            <div className="row dividerRow">
              <hr />
            </div>
            <div className="priceMain d-flex justify-content-between align-items-center">
              {Number(val.pricing_amount) != 0 ? (
                <p>
                  <strong>${val.pricing_amount}</strong>
                </p>
              ) : (
                <p></p>
              )}
              {val.pricing_type === "Valued" ? (
                val.questionaire !== "" ? (
                  <button onClick={() => handleShowQuest(val)}>Order</button>
                ) : (
                  <button onClick={() => handleShow(val)}>Order</button>
                )
              ) : (
                <button onClick={() => handleShow(val)}>Get Quoted</button>
              )}
            </div>
          </div>
        </React.Fragment>
      ))}
      <Modal show={showQuest} onHide={handleCloseQuest}>
        <Modal.Header closeButton>
          <Modal.Body>
            <QuestionareDetail
              questions_List={questions_List}
              CList={CList2}
              setShowQuest={setShowQuest}
              isSelf={Bool}
              SelfAndQuest={SelfAndQuest}
              handleGeoLocate={handleGeoLocate}
            />
          </Modal.Body>
        </Modal.Header>
      </Modal>
      {/* This modal is for content generator page */}
      <Modal
        className="orderProductPlanModal"
        show={show}
        onHide={handleClose}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{CList2?.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Button className="loginBtn type" style={{ width: "auto" }}>
            {CList2?.product_type}
          </Button>
          <div className="row mt-3">
            <div className="col-md-6">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Content Generator</Form.Label>
                <InputGroup className="mb-3">
                  <InputGroup.Text id="basic-addon1">
                    <img src="/imgs/name.svg" alt="" />
                  </InputGroup.Text>
                  <Form.Select
                    aria-label="Default select example"
                    disabled
                    value={Content_Generator}
                    onChange={(e) => setContent_Generator(e.target.value)}
                  >
                    <option value="">Select Content Generator</option>
                    <option value="Self-Generated">Self Generated</option>
                    <option value="Commissioned">Commissioned</option>
                  </Form.Select>
                </InputGroup>
              </Form.Group>
            </div>
          </div>

          {CList2?.product_type === "Blog" ? (
            <>
              {Object.entries(templateQuestions).length > 0 &&
                templateQuestions?.questions?.map((val, i) => (
                  <div className="row mt-3">
                    <h1>{val?.question}</h1>
                    <div className="answerBox">
                      {val?.answerType === "choice" ? (
                        val?.score?.map((item, index) => (
                          <div key={index}>
                            <label className="d-flex">
                              <input
                                type="radio"
                                name={`${val?.question}`}
                                value={item.text}
                                // checked={selectedOption === option.text}
                                // onChange={handleOptionChange}
                                onChange={(e) =>
                                  handleChange(e, i, val.id, item.score)
                                }
                              />{" "}
                              <span className="mx-2">{item.text}</span>
                            </label>
                          </div>
                        ))
                      ) : val?.answerType === "yesNo" && val?.score !== 0 ? (
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Control
                            as="textarea"
                            rows={5}
                            value={Answers[i]}
                            onChange={(e) =>
                              handleChange(e, i, val.id, val?.score)
                            }
                            disabled={
                              user?.data?.user?.role === "staff" ||
                              user?.data?.user?.role === "admin"
                                ? true
                                : false
                            }
                          />
                        </Form.Group>
                      ) : val?.answerType === "image" ? (
                        <>
                          <div className="image-placeholder px-3 my-4 d-flex flex-wrap">
                            {questArray[i].answer?.length > 0 &&
                              questArray[i].answer?.map((val, index) => (
                                <>
                                  <div className="avatar-preview">
                                    <img
                                      src="/imgs/delImg.svg"
                                      className="delImg"
                                      alt=""
                                      onClick={() => deleteImg(i, index)}
                                    />
                                    <div id="imagePreview">
                                      <img
                                        id="saveImageFile"
                                        src={val.img}
                                        alt={val.img}
                                      />
                                    </div>
                                  </div>
                                </>
                              ))}
                            {Edit == false && (
                              <div className="avatar-edit">
                                <img src="/imgs/addImg.png" alt="" />
                                <input
                                  type="file"
                                  // onChange={fileHandler}
                                  id="imageUpload"
                                  onChange={(e) =>
                                    uploadFileHandler(e, val.id, i, val?.score)
                                  }
                                />
                                <label htmlFor="imageUpload" name=""></label>
                              </div>
                            )}
                          </div>
                        </>
                      ) : val?.answerType === "address" ? (
                        <div className="cardSection mt-4 mb-2">
                          <div className="row mx-0">
                            <div className="col-10 mx-auto">
                              <Form className="mt-5">
                                <div className="row mx-0">
                                  <div className="col-md-5 px-0">
                                    <Form.Group
                                      className="mb-3"
                                      controlId="formBasicEmail"
                                    >
                                      <Form.Label>Country/Island</Form.Label>
                                      <InputGroup className="mb-3">
                                        <InputGroup.Text id="basic-addon1">
                                          <img src="/imgs/country.svg" alt="" />
                                        </InputGroup.Text>
                                        <Form.Control
                                          type="text"
                                          name="country"
                                          value={questArray[i]?.answer?.country}
                                          onChange={(e) =>
                                            setCountry(e.target.value)
                                          }
                                        />
                                      </InputGroup>
                                    </Form.Group>
                                  </div>
                                  <div className="col-md-2"></div>
                                  <div className="col-md-5 px-0">
                                    <Form.Group
                                      className="mb-3"
                                      controlId="formBasicEmail"
                                    >
                                      <Form.Label>State</Form.Label>
                                      <InputGroup className="mb-3">
                                        <InputGroup.Text id="basic-addon1">
                                          <img src="/imgs/address.svg" alt="" />
                                        </InputGroup.Text>
                                        <Form.Control
                                          type="text"
                                          name="state"
                                          value={questArray[i]?.answer?.state}
                                          onChange={(e) =>
                                            setState(e.target.value)
                                          }
                                        />
                                      </InputGroup>
                                    </Form.Group>
                                  </div>
                                  <div className="col-md-5 px-0">
                                    <Form.Group
                                      className="mb-3"
                                      controlId="formBasicEmail"
                                    >
                                      <Form.Label>City</Form.Label>
                                      <InputGroup className="mb-3">
                                        <InputGroup.Text id="basic-addon1">
                                          <img src="/imgs/address.svg" alt="" />
                                        </InputGroup.Text>
                                        <Form.Control
                                          type="text"
                                          name="city"
                                          value={questArray[i]?.answer?.city}
                                          // onChange={(e) =>
                                          //   setCity(e.target.value)
                                          // }
                                        />
                                      </InputGroup>
                                    </Form.Group>
                                  </div>
                                  <div className="col-md-2"></div>
                                  <div className="col-md-5 px-0">
                                    <Form.Group
                                      className="mb-3"
                                      controlId="formBasicEmail"
                                    >
                                      <Form.Label>Postal Code</Form.Label>
                                      <InputGroup className="mb-3">
                                        <InputGroup.Text id="basic-addon1">
                                          <img
                                            src="/imgs/postal_code.svg"
                                            alt=""
                                          />
                                        </InputGroup.Text>
                                        <Form.Control
                                          type="text"
                                          name="postal_code"
                                          value={
                                            questArray[i]?.answer?.postal_code
                                          }
                                          // onChange={(e) =>
                                          //   setPostal_Code(e.target.value)
                                          // }
                                        />
                                      </InputGroup>
                                    </Form.Group>
                                  </div>
                                  <div className="col-md-12 px-0">
                                    <Form.Group
                                      className="mb-3"
                                      controlId="formBasicEmail"
                                    >
                                      <Form.Label>Address</Form.Label>
                                      <InputGroup className="mb-3">
                                        <InputGroup.Text id="basic-addon1">
                                          <img src="/imgs/address.svg" alt="" />
                                        </InputGroup.Text>
                                        <Form.Control
                                          type="text"
                                          name="address"
                                          value={questArray[i]?.answer?.address}
                                          // onChange={(e) =>
                                          //   setAddress(e.target.value)
                                          // }
                                        />
                                      </InputGroup>
                                    </Form.Group>
                                  </div>
                                  <div className="col-md-12 px-0">
                                    <Form.Group
                                      className="mb-3"
                                      controlId="formBasicEmail"
                                    >
                                      <Form.Label>Map Location</Form.Label>
                                      <Box
                                        sx={{
                                          height: 400,
                                          position: "relative",
                                        }}
                                      >
                                        <ReactMapGL
                                          ref={mapRef}
                                          mapboxAccessToken="pk.eyJ1IjoiYmx1ZXNpbG9zZyIsImEiOiJjbGZyeDZ1MGswMG1mM2RxcmJyajRzbXRyIn0.SwkzsvT0dgMXSafnYQoZSQ"
                                          initialViewState={{
                                            zoom: 11,
                                            longitude: `${
                                              questArray[i]?.answer?.latitude
                                                ? questArray[i]?.answer
                                                    ?.latitude
                                                : 103.8198
                                            }`,
                                            latitude: `${
                                              questArray[i]?.answer?.longitude
                                                ? questArray[i]?.answer
                                                    ?.longitude
                                                : 1.3521
                                            }`,
                                          }}
                                          mapStyle="mapbox://styles/mapbox/streets-v11"
                                        >
                                          <Marker
                                            latitude={
                                              questArray[i]?.answer?.longitude
                                                ? questArray[i]?.answer
                                                    ?.longitude
                                                : 1.3521
                                            }
                                            longitude={
                                              questArray[i]?.answer?.latitude
                                                ? questArray[i]?.answer
                                                    ?.latitude
                                                : 103.8198
                                            }
                                            draggable
                                            onDragEnd={(e) => {
                                              handleMarkerChange(
                                                e.lngLat.lat,
                                                e.lngLat.lng,
                                                i,
                                                val.id,
                                                val?.score
                                              );
                                              mapdispatch({
                                                type: "UPDATE_LOCATION",
                                                payload: {
                                                  lng: e.lngLat.lng,
                                                  lat: e.lngLat.lat,
                                                },
                                              });
                                            }}
                                          />
                                          <NavigationControl position="bottom-right" />
                                          <GeolocateControl
                                            position="top-left"
                                            // trackUserLocation
                                            onGeolocate={(e) =>
                                              handleGeolocate(
                                                e,
                                                i,
                                                val.id,
                                                val?.score
                                              )
                                            }
                                          />
                                          <Geocoder
                                            setResult={setResult}
                                            setAddress={setAddress}
                                            setCountry={setCountry}
                                            setState={setState}
                                            setCity={setCity}
                                            setPostal_Code={setPostal_Code}
                                            setLatLng={setLatLng}
                                            index={i}
                                            id={val.id}
                                            score={val?.score}
                                            handleGeoLocate={handleGeoLocate}
                                          />
                                        </ReactMapGL>
                                      </Box>
                                    </Form.Group>
                                  </div>
                                </div>
                              </Form>
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                ))}
            </>
          ) : (
            CList2?.product_type === "360 tour" && (
              <>
                {Object.entries(templateQuestions).length > 0 &&
                  templateQuestions?.questions?.map((val, i) => (
                    <div className="row mt-3">
                      {/* <div className="col-md-6"> */}
                      <h1>{val?.question}</h1>
                      <div className="answerBox">
                        {val?.answerType === "choice" ? (
                          val?.score?.map((item, index) => (
                            <div key={index}>
                              <label className="d-flex">
                                <input
                                  type="radio"
                                  name={`${val?.question}`}
                                  value={item.text}
                                  // checked={selectedOption === option.text}
                                  // onChange={handleOptionChange}
                                  onChange={(e) =>
                                    handleChange(e, i, val.id, item.score)
                                  }
                                />
                                <span className="mx-2">{item.text}</span>
                              </label>
                            </div>
                          ))
                        ) : val?.answerType === "yesNo" && val?.score !== 0 ? (
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            <Form.Control
                              as="textarea"
                              rows={5}
                              value={Answers[i]}
                              onChange={(e) =>
                                handleChange(e, i, val.id, val?.score)
                              }
                              disabled={
                                user?.data?.user?.role === "staff" ||
                                user?.data?.user?.role === "admin"
                                  ? true
                                  : false
                              }
                            />
                          </Form.Group>
                        ) : val?.answerType === "image" ? (
                          <>
                            <div className="image-placeholder d-flex flex-wrap px-3 my-4">
                              {questArray[i].answer?.length > 0 &&
                                questArray[i].answer?.map((val, index) => (
                                  <>
                                    <div className="avatar-preview">
                                      <img
                                        src="/imgs/delImg.svg"
                                        className="delImg"
                                        alt=""
                                        onClick={() => deleteImg(i, index)}
                                      />
                                      <div id="imagePreview">
                                        <img
                                          id="saveImageFile"
                                          src={val.img}
                                          alt={val.img}
                                        />
                                      </div>
                                    </div>
                                  </>
                                ))}
                              {Edit == false && (
                                <div className="avatar-edit">
                                  <img src="/imgs/addImg.png" alt="" />
                                  <input
                                    type="file"
                                    // onChange={fileHandler}
                                    id="imageUpload"
                                    onChange={(e) =>
                                      uploadFileHandler(
                                        e,
                                        val.id,
                                        i,
                                        val?.score
                                      )
                                    }
                                  />
                                  <label htmlFor="imageUpload" name=""></label>
                                </div>
                              )}
                            </div>
                            {/* <div className="avatar-edit">
                                <img src="/imgs/addImg.png" alt="" />
                                <input
                                  type="file"
                                  // onChange={fileHandler}
                                  id="imageUpload"
                                  onChange={uploadFileHandler}
                                />
                                <label htmlFor="imageUpload" name=""></label>
                              </div> */}
                          </>
                        ) : val?.answerType === "address" ? (
                          <div className="cardSection mt-4 mb-2">
                            <div className="row mx-0">
                              <div className="col-10 mx-auto">
                                <Form className="mt-5">
                                  <div className="row mx-0">
                                    <div className="col-md-5 px-0">
                                      <Form.Group
                                        className="mb-3"
                                        controlId="formBasicEmail"
                                      >
                                        <Form.Label>Country/Island</Form.Label>
                                        <InputGroup className="mb-3">
                                          <InputGroup.Text id="basic-addon1">
                                            <img
                                              src="/imgs/country.svg"
                                              alt=""
                                            />
                                          </InputGroup.Text>
                                          <Form.Control
                                            type="text"
                                            name="country"
                                            value={
                                              questArray[i]?.answer?.country
                                            }
                                            // onChange={(e) =>
                                            //   setCountry(e.target.value)
                                            // }
                                          />
                                        </InputGroup>
                                      </Form.Group>
                                    </div>
                                    <div className="col-md-2"></div>
                                    <div className="col-md-5 px-0">
                                      <Form.Group
                                        className="mb-3"
                                        controlId="formBasicEmail"
                                      >
                                        <Form.Label>State</Form.Label>
                                        <InputGroup className="mb-3">
                                          <InputGroup.Text id="basic-addon1">
                                            <img
                                              src="/imgs/address.svg"
                                              alt=""
                                            />
                                          </InputGroup.Text>
                                          <Form.Control
                                            type="text"
                                            name="state"
                                            value={questArray[i]?.answer?.state}
                                            // onChange={(e) =>
                                            //   setState(e.target.value)
                                            // }
                                          />
                                        </InputGroup>
                                      </Form.Group>
                                    </div>
                                    <div className="col-md-5 px-0">
                                      <Form.Group
                                        className="mb-3"
                                        controlId="formBasicEmail"
                                      >
                                        <Form.Label>City</Form.Label>
                                        <InputGroup className="mb-3">
                                          <InputGroup.Text id="basic-addon1">
                                            <img
                                              src="/imgs/address.svg"
                                              alt=""
                                            />
                                          </InputGroup.Text>
                                          <Form.Control
                                            type="text"
                                            name="city"
                                            value={questArray[i]?.answer?.city}
                                            // onChange={(e) =>
                                            //   setCity(e.target.value)
                                            // }
                                          />
                                        </InputGroup>
                                      </Form.Group>
                                    </div>
                                    <div className="col-md-2"></div>
                                    <div className="col-md-5 px-0">
                                      <Form.Group
                                        className="mb-3"
                                        controlId="formBasicEmail"
                                      >
                                        <Form.Label>Postal Code</Form.Label>
                                        <InputGroup className="mb-3">
                                          <InputGroup.Text id="basic-addon1">
                                            <img
                                              src="/imgs/postal_code.svg"
                                              alt=""
                                            />
                                          </InputGroup.Text>
                                          <Form.Control
                                            type="text"
                                            name="postal_code"
                                            value={
                                              questArray[i]?.answer?.postal_code
                                            }
                                            // onChange={(e) =>
                                            //   setPostal_Code(e.target.value)
                                            // }
                                          />
                                        </InputGroup>
                                      </Form.Group>
                                    </div>
                                    <div className="col-md-12 px-0">
                                      <Form.Group
                                        className="mb-3"
                                        controlId="formBasicEmail"
                                      >
                                        <Form.Label>Address</Form.Label>
                                        <InputGroup className="mb-3">
                                          <InputGroup.Text id="basic-addon1">
                                            <img
                                              src="/imgs/address.svg"
                                              alt=""
                                            />
                                          </InputGroup.Text>
                                          <Form.Control
                                            type="text"
                                            name="address"
                                            value={
                                              questArray[i]?.answer?.address
                                            }
                                            onChange={(e) =>
                                              setAddress(e.target.value)
                                            }
                                          />
                                        </InputGroup>
                                      </Form.Group>
                                    </div>
                                    <div className="col-md-12 px-0">
                                      <Form.Group
                                        className="mb-3"
                                        controlId="formBasicEmail"
                                      >
                                        <Form.Label>Map Location</Form.Label>
                                        <Box
                                          sx={{
                                            height: 400,
                                            position: "relative",
                                          }}
                                        >
                                          <ReactMapGL
                                            ref={mapRef}
                                            mapboxAccessToken="pk.eyJ1IjoiYmx1ZXNpbG9zZyIsImEiOiJjbGZyeDZ1MGswMG1mM2RxcmJyajRzbXRyIn0.SwkzsvT0dgMXSafnYQoZSQ"
                                            initialViewState={{
                                              zoom: 11,
                                              longitude: `${
                                                questArray[i]?.answer?.latitude
                                                  ? questArray[i]?.answer
                                                      ?.latitude
                                                  : 103.8198
                                              }`,
                                              latitude: `${
                                                questArray[i]?.answer?.longitude
                                                  ? questArray[i]?.answer
                                                      ?.longitude
                                                  : 1.3521
                                              }`,
                                              // longitude: `${103.8198}`,
                                              // latitude: `${1.3521}`,
                                            }}
                                            mapStyle="mapbox://styles/mapbox/streets-v11"
                                          >
                                            <Marker
                                              // latitude={1.3521}
                                              // longitude={103.8198}
                                              latitude={
                                                questArray[i]?.answer?.longitude
                                                  ? questArray[i]?.answer
                                                      ?.longitude
                                                  : 1.3521
                                              }
                                              longitude={
                                                questArray[i]?.answer?.latitude
                                                  ? questArray[i]?.answer
                                                      ?.latitude
                                                  : 103.8198
                                              }
                                              draggable
                                              onDragEnd={(e) => {
                                                handleMarkerChange(
                                                  e.lngLat.lat,
                                                  e.lngLat.lng,
                                                  i,
                                                  val.id,
                                                  val?.score
                                                );
                                                mapdispatch({
                                                  type: "UPDATE_LOCATION",
                                                  payload: {
                                                    lng: e.lngLat.lng,
                                                    lat: e.lngLat.lat,
                                                  },
                                                });
                                              }}
                                            />
                                            <NavigationControl position="bottom-right" />
                                            <GeolocateControl
                                              position="top-left"
                                              // trackUserLocation
                                              onGeolocate={(e) =>
                                                handleGeolocate(
                                                  e,
                                                  i,
                                                  val.id,
                                                  val?.score
                                                )
                                              }
                                            />
                                            <Geocoder
                                              setResult={setResult}
                                              setAddress={setAddress}
                                              setCountry={setCountry}
                                              setState={setState}
                                              setCity={setCity}
                                              setPostal_Code={setPostal_Code}
                                              setLatLng={setLatLng}
                                              index={i}
                                              id={val.id}
                                              score={val?.score}
                                              handleGeoLocate={handleGeoLocate}
                                            />
                                          </ReactMapGL>
                                        </Box>
                                      </Form.Group>
                                    </div>
                                  </div>
                                </Form>
                              </div>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      {/* </div> */}
                    </div>
                  ))}
              </>
            )
          )}
          <div className="d-sm-flex justify-content-center text-center gap-2 py-3">
            <Button
              className="loginBtn w-50 me-sm-0 me-3"
              onClick={() => AddQuoted(CList2)}
            >
              Get Quoted
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default OrderProductPlan;
