import React, { useEffect, useState, useLayoutEffect } from "react";
import { Form, InputGroup } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  getQuotation,
  reset,
} from "../../../features/Quotations/quotationSlice";
import { billingPaid_column } from "../../../utils/constant/column_header_data";
import { formatDate } from "../../../utils/DateHelper";

function BillingPaid() {
  const user = JSON.parse(localStorage.getItem("user"));

  const ID = user?.data?.user?._id;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { quotation_List, isError, isSuccess, message, isList } = useSelector(
    (state) => state.quotations
  );
  const { frontData } = useSelector((state) => state.front_Data);

  const [Search, setSearch] = useState("");

  const [CList, setCList] = useState([]);

  useLayoutEffect(() => {
    dispatch(reset());
    dispatch(getQuotation());
  }, []);

  useEffect(() => {
    if (quotation_List) {
      const newData = quotation_List?.filter(
        (val) => val.isAccepted == true && val.isPaid == true
      );
      if (newData) {
        const data = [...newData].reverse();
        setCList(data);
      }
    }
  }, [dispatch, navigate, isSuccess, isError, message, isList, ID]);

  const onView = (value) => {
    (user?.data?.user?.role === "staff" &&
      user?.data?.user?.permissions[0]?.ArticlesOrders?.some(
        (val) => val === "view"
      )) ||
    user?.data?.user?.role === "admin"
      ? navigate("/billing/bill-form", {
          state: { Details: value, edit: false },
        })
      : toast.error("Permission denied!");
  };
  console.log(CList);
  var rows = [];
  if (CList) {
    for (let [i, value] of CList?.entries()) {
      rows[i] = {
        id: value._id,
        billing_date: formatDate(value?.billingDate),
        paid_at: formatDate(value?.paidDate),
        via: value?.payment_gateway,
        user: value?.owner?.user_name,
        amount: value?.plan[0].pricing_amount,
        status: value?.isPaid == true ? "Paid" : "Pending",
        action: (
          <div>
            <div className="d-flex gap-3">
              <img
                src="/imgs/u_eye.svg"
                width={20}
                alt=""
                onClick={() => onView(value)}
              />
            </div>
          </div>
        ),
      };
    }
  }

  return (
    <div className="staffMainDiv">
      <div className="row mx-0 mb-4">
        <div className="d-flex justify-content-between">
          <div className="d-flex gap-3">
            <img
              src="/imgs/revoke.svg"
              width={20}
              alt=""
              onClick={() => setSearch("")}
            />
            <Form.Group className="mb-0">
              <InputGroup className="mb-0">
                <InputGroup.Text id="basic-addon1">
                  <img src="/imgs/search.svg" alt="" />
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  placeholder="Search"
                  value={Search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </InputGroup>
            </Form.Group>
          </div>
        </div>
      </div>
      <DataTable
        columns={billingPaid_column}
        data={
          rows
            ? rows.filter(
                (item) =>
                  item?.status?.toLowerCase().includes(Search.toLowerCase()) ||
                  item?.user?.toLowerCase().includes(Search.toLowerCase())
              )
            : []
        }
        pagination
      />
    </div>
  );
}

export default BillingPaid;
