import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getCredits,
  updateCredits,
  reset,
} from "../../../../features/creditSystem/CreditSlice";
import { Button, Modal, Form, InputGroup } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";

const Credits = () => {
  const dispatch = useDispatch();
  const [state, setState] = useState(false);
  const [credits, setCredits] = useState([]);
  const [Edit, setEdit] = useState(false);
  const [show, setShow] = useState(false);
  const [Id, setId] = useState();
  const [name, setName] = useState("");
  const [credit, setCredit] = useState("");
  let { creditList, isSuccess, message, isLoading, isError } = useSelector(
    (state) => state.credits
  );
  useEffect(() => {
    fetchCredits();
  }, []);
  const EditData = (val) => {
    setEdit(true);
    setShow(true);
    setId(val._id);
    setName(val.name);
    setCredit(val.credits);
  };
  const handleClose = () => {
    setName("");
    setCredit("");
    setShow(false);
    setEdit(false);
  };

  const UpdateNav = async () => {
    const navData = {
      Id,
      name,
      credit,
    };
    const result = await dispatch(updateCredits(navData));
    setName("");
    setCredit("");
    if (!result.error) {
      fetchCredits();
      toast.success("Credit updated Successfully!");
    } else {
      dispatch(getCredits());
      toast.error("Unable to update");
    }
    setShow(false);
    setEdit(false);
  };
  const fetchCredits = async () => {
    let result = await dispatch(getCredits());
    result = result?.payload?.data;
    setCredits(result);
  };

  return (
    <div>
      <ToastContainer />
      <div className="d-flex flex-column gap-3">
        {credits?.map((item, index) => (
          <>
            <div
              className="d-flex flex-column gap-2 w-50 me-auto"
              key={item._id}
            >
              <label>
                {item.name === "blog"
                  ? "Credit Required For Content Creating (Article/Blog)"
                  : item.name === "360tour"
                  ? "Credit Required For Content Creating (360 Tour)"
                  : item.name === "free360Tour"
                  ? `Free "360 Tour" Credit For New Registered User                  `
                  : item.name === "freeBlog"
                  ? `Free "Article/Blog" Credit For New Registered User`
                  : ""}
              </label>
              <span
                className="border border-dark w-100 fs-5 py-2 px-3 rounded"
                onClick={() => EditData(item)}
                style={{ cursor: "pointer" }}
              >
                {item.credits}
              </span>
            </div>
          </>
        ))}
      </div>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Body>
          <div className="d-flex justify-content-between mb-3"></div>
          <div className="row gx-5">
            <div className="col-md-12">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Name</Form.Label>
                <InputGroup className="mb-3">
                  <InputGroup.Text id="basic-addon1">
                    <img src="/imgs/u_user.svg" alt="" />
                  </InputGroup.Text>
                  <Form.Control
                    type="text"
                    value={name}
                    disabled
                    onChange={(e) => setName(e.target.value)}
                  />
                </InputGroup>
              </Form.Group>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Credits</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  value={credit}
                  onChange={(e) => setCredit(e.target.value)}
                />
              </Form.Group>
            </div>
          </div>

          <div className="d-sm-flex justify-content-center text-center gap-4">
            {Edit == true ? (
              <>
                <Button
                  className="cancelBtn me-sm-0 me-3 mb-sm-0 mb-2"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
                <Button className="loginBtn me-sm-0 me-3" onClick={UpdateNav}>
                  Update
                </Button>
              </>
            ) : (
              <>
                <Button
                  className="cancelBtn me-sm-0 me-3 mb-sm-0 mb-2"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
                <Button
                  className="loginBtn me-sm-0 me-3"
                  disabled={(name && credits) === "" ? true : false}
                >
                  Save
                </Button>
              </>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Credits;
