import React, { useEffect, useMemo } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";

import { verifyToken } from "../../features/auth/authSlice";

const JumpAuthorize = () => {
  const { search } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);

  const searchParams = useMemo(
    () => new URLSearchParams(search),
    [search]
  );

  const splitOrg = search?.toString().split("organizationId=");
  const organizationId = splitOrg.length > 0 ? splitOrg[splitOrg.length - 1] : "";

  const data = {
    bearerToken: searchParams.get("token"),
    organizationId: organizationId
  }

  useEffect(() => {
    dispatch(verifyToken(data));
  }, []);

  useEffect(() => {
    if (user?.status === "Success") {
      navigate("/");
    }
  }, [user])

  return (
    <div></div>
  );
};

export default JumpAuthorize;
