import React, { useEffect, useState, useRef, useLayoutEffect } from "react";
import { Button, Form, InputGroup, Modal } from "react-bootstrap";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  editQuotation,
  reset,
  getQuotationDetail,
} from "../../../../features/Quotations/quotationSlice";
import {
  getQuestions,
  reset as resetQuest,
  getDetails,
} from "../../../../features/Questions/questionSlice";
import { useSelector } from "react-redux";
import QuestionareDetail from "../../../Staff/QuestionareDetail/QuestionareDetail";
import { toast, ToastContainer } from "react-toastify";
// import ReactQuill from "../../../Editor/Editor";
import { useValue } from "../../../../context/ContextProvider";
// import Geocoder from "../../Tours/Geocoder";
// import Geocode from "react-geocode";
import ReactMapGL, {
  GeolocateControl,
  Marker,
  NavigationControl,
} from "react-map-gl";
import { Box } from "@mui/material";

function OrderDetail() {
  const [isCall, setIsCall] = useState(false);
  const {
    questions_List,
    // isSuccess: questSuccess,
    isError: questError,
    message: questMessage,
    isLoading: questLoading,
    isList,
  } = useSelector((state) => state.questions);
  const {
    quotation_List,
    quotationDetail,
    isError,
    isSuccess,
    isSuccess1,
    message,
  } = useSelector((state) => state.quotations);

  const {
    state: {
      location: { lng, lat },
    },
    mapdispatch,
  } = useValue();
  const mapRef = useRef();
  const { id } = useParams();

  const user = JSON.parse(localStorage.getItem("user"));
  const userID = user?.data?.user?._id;

  const [show, setShow] = useState(false);
  const [status, setStatus] = useState("");
  const [Billing, setBilling] = useState("");
  const [value, setValue] = useState("");

  const [Pricing_Amount, setPricing_Amount] = useState("");
  const [showQuest, setShowQuest] = useState(false);
  const [JustQuest, setJustQuest] = useState(false);
  const { state } = useLocation();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleShowQuest = () => {
    dispatch(resetQuest());
    dispatch(getDetails(state?.Details?.plan[0]?.questionaire));
    setShowQuest(true);
    setJustQuest(true);
  };

  const handleCloseQuest = () => {
    setShowQuest(false);
  };

  const length = quotationDetail?.plan?.length;
  let count = 0;
  useEffect(() => {
    if (quotationDetail?.plan?.length > 0) {
      setValue(
        quotationDetail?.Content
          ? quotationDetail?.Content
          : quotationDetail?.plan[0]?.description
      );
    }
    if (quotationDetail?.plan?.length > 0) {
      setPricing_Amount(quotationDetail?.plan[0]?.pricing_amount);
      setStatus(quotationDetail?.status);
    }

    if (isSuccess1 == true && message !== "") {
      toast.success(message);
      setTimeout(() => {
        dispatch(reset());
        if (count <= 2) {
          navigate(-1);
          count = count + 1;
        }
        console.log(123, 333, "dsafsd", count, isSuccess);
      }, 1500);
    }
    if (isError == true && message !== "") {
      toast.error(message);
      setTimeout(() => {
        dispatch(reset());
      }, 1500);
    }
    if (isCall == false) {
      dispatch(getQuotationDetail(id));
    }
  }, [
    isError,
    isSuccess,
    message,
    length,
    quotationDetail?.status,
    quotationDetail?.plan?.[0]?.pricing_amount,
  ]);

  const SubmitQuote = () => {
    setIsCall(true);
    const data = { ...quotationDetail?.plan[0] };
    data["pricing_amount"] = Pricing_Amount;

    const Data = {
      plan: data,
      owner: quotationDetail?.owner,
      id: quotationDetail?._id,
      status: status,
      Billing: Billing,
      url: "/orders/quotation/order-detail/",
    };
    dispatch(editQuotation(Data));
  };

  const numberOfQuestions =
    length > 0 && quotationDetail?.plan[0]?.questionaire?.questions.length;

  // Get the latest number of records from the answers array based on the number of questions
  const latestAnswers =
    length > 0 && quotationDetail?.plan[0]?.questionaire?.answers
      ? quotationDetail?.plan[0]?.questionaire?.answers.slice(
          -numberOfQuestions
        )
      : quotationDetail?.questArray;

  return (
    <>
      {quotationDetail?.plan?.length > 0 && (
        <div>
          <ToastContainer />
          {console.log(123, quotationDetail)}
          <button
            className="loginBtn"
            style={{ marginBottom: "10px" }}
            onClick={() => navigate(-1)}
          >
            Go back
          </button>
          <div className="cardSection">
            <div className="d-flex justify-content-between">
              <h1>Order Details</h1>
              <div className="d-flex justify-content-center gap-4 mb-3">
                {state?.edit == true && state?.edit != null && (
                  <>
                    <Button
                      className="cancelBtn"
                      type="button"
                      onClick={() => navigate(-1)}
                    >
                      Cancel
                    </Button>
                    <Button
                      className="loginBtn"
                      disabled={status !== "" ? false : true}
                      onClick={SubmitQuote}
                    >
                      Update
                    </Button>
                  </>
                )}
              </div>
            </div>
            <div className="row mx-0 mt-4">
              <div className="col-10 mx-auto">
                <div className="row gx-5">
                  <div className="col-md-6">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Order Date</Form.Label>
                      <InputGroup className="mb-3">
                        <InputGroup.Text id="basic-addon1">
                          <img src="/imgs/dob.svg" alt="" />
                        </InputGroup.Text>
                        <Form.Control
                          type="date"
                          value={quotationDetail?.createdAt?.substring(0, 10)}
                        />
                      </InputGroup>
                    </Form.Group>
                  </div>
                  <div className="col-md-6">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>User</Form.Label>
                      <InputGroup className="mb-3">
                        <InputGroup.Text id="basic-addon1">
                          <img src="/imgs/u_user.svg" alt="" />
                        </InputGroup.Text>
                        <Form.Control
                          type="text"
                          value={quotationDetail?.owner?.first_name}
                        />
                      </InputGroup>
                    </Form.Group>
                  </div>
                  {/* <div className="col-md-6">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Billing No</Form.Label>
                  <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1">
                      <img src="/imgs/billing.svg" alt="" />
                    </InputGroup.Text>
                    <Form.Control
                      type="text"
                      placeholder="N/A"
                      disabled={true}
                      value={Billing}
                      onChange={(e) => setBilling(e.target.value)}
                    />
                  </InputGroup>
                </Form.Group>
              </div> */}
                  {state?.edit && (
                    <div
                      className="col-md-6"
                      style={{
                        backgroundColor:
                          Pricing_Amount == "0" ||
                          (Pricing_Amount == "" &&
                            user?.data?.user?.role !== "user")
                            ? "#EAEAEA"
                            : "#fff0f0b5",
                      }}
                    >
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label
                          style={{
                            color:
                              user?.data?.user?.role == "user" ? "red" : "",
                          }}
                        >
                          Status
                        </Form.Label>
                        <InputGroup className="mb-3">
                          <InputGroup.Text id="basic-addon1">
                            <img src="/imgs/u_user.svg" alt="" />
                          </InputGroup.Text>
                          <Form.Select
                            aria-label="Default select example"
                            value={status}
                            onChange={(e) => setStatus(e.target.value)}
                            // disabled={Pricing_Amount == "0" || Pricing_Amount == ""}
                          >
                            <option value="">Select status</option>
                            {user?.data?.user?.role === "staff" ||
                            user?.data?.user?.role === "admin" ? (
                              <>
                                <option value="Pending">Pending</option>
                                <option value="Sent">Sent</option>
                                <option value="Reject">Reject</option>
                              </>
                            ) : (
                              <>
                                <option value="Accept">Accept</option>
                                <option value="Declined">Declined</option>
                              </>
                            )}
                          </Form.Select>
                        </InputGroup>
                      </Form.Group>
                    </div>
                  )}
                  <div className="col-md-6">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Product Plan</Form.Label>
                      <InputGroup className="mb-3">
                        <InputGroup.Text id="basic-addon1">
                          <img src="/imgs/product-type.svg" alt="" />
                        </InputGroup.Text>
                        <Form.Control
                          type="text"
                          value={quotationDetail?.product_plan}
                        />
                      </InputGroup>
                    </Form.Group>
                  </div>
                  <div
                    className="col-md-6"
                    style={{ backgroundColor: "#fff0f0b5" }}
                  >
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label style={{ color: "red" }}>Amount</Form.Label>
                      <InputGroup className="mb-3">
                        <InputGroup.Text id="basic-addon1">
                          <img src="/imgs/amount.svg" alt="" />
                        </InputGroup.Text>
                        {user?.data?.user?.role === "staff" ||
                        user?.data?.user?.role === "admin" ? (
                          <Form.Control
                            type="number"
                            min={0}
                            disabled={
                              quotationDetail?.plan[0]?.pricing_type ===
                              "Quoted"
                                ? false
                                : true
                            }
                            value={Pricing_Amount}
                            onChange={(e) => setPricing_Amount(e.target.value)}
                          />
                        ) : (
                          <Form.Control
                            type="number"
                            min={0}
                            disabled={true}
                            value={Pricing_Amount}
                            onChange={(e) => setPricing_Amount(e.target.value)}
                          />
                        )}
                      </InputGroup>
                    </Form.Group>
                  </div>
                  <div className="col-md-6">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Pricing Type</Form.Label>
                      <InputGroup className="mb-3">
                        <InputGroup.Text id="basic-addon1">
                          <img src="/imgs/money_light.svg" alt="" />
                        </InputGroup.Text>
                        <Form.Control
                          aria-label="Default select example"
                          value={quotationDetail?.plan[0]?.pricing_type}
                        />
                      </InputGroup>
                    </Form.Group>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="cardSection mt-4">
            <div className="row mx-0">
              <div className="col-sm-10 col-12 mx-auto">
                <div className="d-flex justify-content-between align-items-center">
                  <span className="score">
                    Score :{" "}
                    {quotationDetail?.totalscore
                      ? quotationDetail?.totalscore
                      : 0}
                  </span>
                  <Modal show={showQuest} onHide={handleCloseQuest}>
                    <Modal.Header closeButton>
                      {/* <Modal.Title>Questionnaire</Modal.Title> */}
                      <Modal.Body>
                        <QuestionareDetail
                          questions_List={questions_List}
                          CList={[]}
                          Details={quotationDetail}
                          questId={quotationDetail?.plan[0]?.questionaire}
                          setShowQuest={setShowQuest}
                          JustQuest={JustQuest}
                        />
                      </Modal.Body>
                    </Modal.Header>
                  </Modal>
                </div>
              </div>
            </div>
          </div>
          <div className="cardSection mt-4">
            <div className="d-flex justify-content-between">
              <h1>Quote request detail</h1>
            </div>
            <div className="row mx-0 mt-4">
              <div className="col-10 mx-auto">
                <div className="row gx-5">
                  <div className="col-md-6">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Type of Post</Form.Label>
                      <InputGroup className="mb-3">
                        <InputGroup.Text id="basic-addon1">
                          <img src="/imgs/name.svg" alt="" />
                        </InputGroup.Text>
                        <Form.Select
                          aria-label="Default select example"
                          value={quotationDetail?.plan[0]?.product_type}
                        >
                          <option value="Blog">Blog</option>
                          {/* <option value="Video">Video</option> */}
                          <option value="360 tour">360 tour</option>
                        </Form.Select>
                      </InputGroup>
                    </Form.Group>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="cardSection mt-4">
            <div className="d-flex justify-content-between">
              <h1>Blog / 360Tour</h1>
            </div>
            <div className="row mx-0 mt-4">
              <div className="col-10 mx-auto">
                <div className="row gx-5">
                  <div className="col-md-6">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Content Generator</Form.Label>
                      <InputGroup className="mb-3">
                        <InputGroup.Text id="basic-addon1">
                          <img src="/imgs/name.svg" alt="" />
                        </InputGroup.Text>
                        <Form.Select
                          aria-label="Default select example"
                          value={quotationDetail?.Content_Generator}
                        >
                          <option value="">Select</option>
                          <option value="Self-Generated">Self-Generated</option>
                          <option value="Commissioned">Commissioned</option>
                        </Form.Select>
                      </InputGroup>
                    </Form.Group>
                  </div>
                  <div className="col-md-6">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Self-Generated Post</Form.Label>
                      <InputGroup className="mb-3">
                        <InputGroup.Text id="basic-addon1">
                          <img src="/imgs/plus.svg" alt="" />
                        </InputGroup.Text>
                        <Form.Select
                          aria-label="Default select example"
                          value={quotationDetail?.plan[0]?.product_type}
                        >
                          <option value="">Select</option>
                          <option value="Blog">Blog</option>
                          {/* <option value="Video">Video</option> */}
                          <option value="360 tour">360 tours</option>
                        </Form.Select>
                      </InputGroup>
                    </Form.Group>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {quotationDetail?.plan[0]?.product_type === "Blog" ? (
            <div className="cardSection mt-4">
              <div className="d-flex justify-content-between">
                <h1>Self-Generated Blog </h1>
              </div>
              {quotationDetail?.plan[0]?.pricing_type == "Quoted" ? (
                <div className="row mx-0 mt-4">
                  <div className="col-10 mx-auto">
                    <div className="row gx-5">
                      {quotationDetail &&
                        quotationDetail?.templateQuestions &&
                        quotationDetail?.templateQuestions?.questions.length >
                          0 &&
                        quotationDetail?.templateQuestions?.questions.map(
                          (val, i) => (
                            <div className="row mt-3">
                              {/* <div className="col-md-6"> */}
                              <h1>{val?.question}</h1>
                              <div className="answerBox">
                                {val?.answerType === "choice" ? (
                                  val?.score?.map((item, index) => (
                                    <div key={index}>
                                      <label>
                                        <input
                                          type="radio"
                                          name={`${val?.question}`}
                                          value={item.text}
                                          checked={
                                            item.text ==
                                            latestAnswers[i]?.answer
                                          }
                                        />
                                        {item.text}
                                      </label>
                                    </div>
                                  ))
                                ) : val?.answerType === "yesNo" &&
                                  val?.score !== 0 ? (
                                  <Form.Group
                                    className="mb-3"
                                    controlId="formBasicEmail"
                                  >
                                    <Form.Control
                                      as="textarea"
                                      rows={5}
                                      value={latestAnswers[i]?.answer}
                                      // onChange={(e) =>
                                      //   handleChange(e, i, val.id, val?.score)
                                      // }
                                      disabled={
                                        user?.data?.user?.role === "staff" ||
                                        user?.data?.user?.role === "admin"
                                          ? true
                                          : false
                                      }
                                    />
                                  </Form.Group>
                                ) : val?.answerType === "image" ? (
                                  <>
                                    <div className="image-placeholder px-3 my-4">
                                      {latestAnswers[i]?.answer?.length > 0 &&
                                        latestAnswers[i]?.answer?.map((val) => (
                                          <>
                                            <div className="avatar-preview">
                                              <div id="imagePreview">
                                                <img
                                                  id="saveImageFile"
                                                  src={val.img}
                                                  alt={val.img}
                                                />
                                              </div>
                                            </div>
                                          </>
                                        ))}
                                    </div>
                                  </>
                                ) : (
                                  <div className="cardSection mt-4 mb-2">
                                    <div className="row mx-0">
                                      <div className="col-10 mx-auto">
                                        <Form className="mt-5">
                                          <div className="row mx-0">
                                            <div className="col-md-5 px-0">
                                              <Form.Group
                                                className="mb-3"
                                                controlId="formBasicEmail"
                                              >
                                                <Form.Label>
                                                  Country/Island
                                                </Form.Label>
                                                <InputGroup className="mb-3">
                                                  <InputGroup.Text id="basic-addon1">
                                                    <img
                                                      src="/imgs/country.svg"
                                                      alt=""
                                                    />
                                                  </InputGroup.Text>
                                                  <Form.Control
                                                    type="text"
                                                    name="country"
                                                    value={
                                                      latestAnswers[i]?.answer
                                                        ?.country
                                                    }
                                                  />
                                                </InputGroup>
                                              </Form.Group>
                                            </div>
                                            <div className="col-md-2"></div>
                                            <div className="col-md-5 px-0">
                                              <Form.Group
                                                className="mb-3"
                                                controlId="formBasicEmail"
                                              >
                                                <Form.Label>State</Form.Label>
                                                <InputGroup className="mb-3">
                                                  <InputGroup.Text id="basic-addon1">
                                                    <img
                                                      src="/imgs/address.svg"
                                                      alt=""
                                                    />
                                                  </InputGroup.Text>
                                                  <Form.Control
                                                    type="text"
                                                    name="state"
                                                    value={
                                                      latestAnswers[i]?.answer
                                                        ?.state
                                                    }
                                                  />
                                                </InputGroup>
                                              </Form.Group>
                                            </div>
                                            <div className="col-md-5 px-0">
                                              <Form.Group
                                                className="mb-3"
                                                controlId="formBasicEmail"
                                              >
                                                <Form.Label>City</Form.Label>
                                                <InputGroup className="mb-3">
                                                  <InputGroup.Text id="basic-addon1">
                                                    <img
                                                      src="/imgs/address.svg"
                                                      alt=""
                                                    />
                                                  </InputGroup.Text>
                                                  <Form.Control
                                                    type="text"
                                                    name="city"
                                                    value={
                                                      latestAnswers[i]?.answer
                                                        ?.city
                                                    }
                                                  />
                                                </InputGroup>
                                              </Form.Group>
                                            </div>
                                            <div className="col-md-2"></div>
                                            <div className="col-md-5 px-0">
                                              <Form.Group
                                                className="mb-3"
                                                controlId="formBasicEmail"
                                              >
                                                <Form.Label>
                                                  Postal Code
                                                </Form.Label>
                                                <InputGroup className="mb-3">
                                                  <InputGroup.Text id="basic-addon1">
                                                    <img
                                                      src="/imgs/postal_code.svg"
                                                      alt=""
                                                    />
                                                  </InputGroup.Text>
                                                  <Form.Control
                                                    type="text"
                                                    name="postal_code"
                                                    value={
                                                      latestAnswers[i]?.answer
                                                        ?.postCode
                                                        ? latestAnswers[i]
                                                            ?.answer?.postCode
                                                        : latestAnswers[i]
                                                            ?.answer?.postalCode
                                                    }
                                                  />
                                                </InputGroup>
                                              </Form.Group>
                                            </div>
                                            <div className="col-md-12 px-0">
                                              <Form.Group
                                                className="mb-3"
                                                controlId="formBasicEmail"
                                              >
                                                <Form.Label>Address</Form.Label>
                                                <InputGroup className="mb-3">
                                                  <InputGroup.Text id="basic-addon1">
                                                    <img
                                                      src="/imgs/address.svg"
                                                      alt=""
                                                    />
                                                  </InputGroup.Text>
                                                  <Form.Control
                                                    type="text"
                                                    name="address"
                                                    value={
                                                      latestAnswers[i]?.answer
                                                        ?.address
                                                    }
                                                  />
                                                </InputGroup>
                                              </Form.Group>
                                            </div>
                                            <div className="col-md-12 px-0">
                                              <Form.Group
                                                className="mb-3"
                                                controlId="formBasicEmail"
                                              >
                                                <Form.Label>
                                                  Map Location
                                                </Form.Label>
                                                <Box
                                                  sx={{
                                                    height: 400,
                                                    position: "relative",
                                                  }}
                                                >
                                                  <ReactMapGL
                                                    ref={mapRef}
                                                    mapboxAccessToken="pk.eyJ1IjoiYmx1ZXNpbG9zZyIsImEiOiJjbGZyeDZ1MGswMG1mM2RxcmJyajRzbXRyIn0.SwkzsvT0dgMXSafnYQoZSQ"
                                                    initialViewState={{
                                                      zoom: 11,
                                                      // latitude: 1.3521,
                                                      // longitude: 103.8198,
                                                      longitude: latestAnswers[
                                                        i
                                                      ]?.answer?.longitude
                                                        ? Number(
                                                            latestAnswers[i]
                                                              ?.answer
                                                              ?.longitude
                                                          )
                                                        : 103.8198,
                                                      latitude: latestAnswers[i]
                                                        ?.answer?.latitude
                                                        ? Number(
                                                            latestAnswers[i]
                                                              ?.answer?.latitude
                                                          )
                                                        : 1.3521,
                                                    }}
                                                    mapStyle="mapbox://styles/mapbox/streets-v11"
                                                  >
                                                    <Marker
                                                      // longitude={103.8198}
                                                      // latitude={1.3521}
                                                      latitude={
                                                        latestAnswers[i]?.answer
                                                          ?.latitude
                                                          ? Number(
                                                              latestAnswers[i]
                                                                ?.answer
                                                                ?.latitude
                                                            )
                                                          : 1.3521
                                                      }
                                                      longitude={
                                                        latestAnswers[i]?.answer
                                                          ?.longitude
                                                          ? Number(
                                                              latestAnswers[i]
                                                                ?.answer
                                                                ?.longitude
                                                            )
                                                          : 103.8198
                                                      }
                                                    />
                                                    <NavigationControl position="bottom-right" />
                                                    <GeolocateControl
                                                      position="top-left"
                                                      // trackUserLocation
                                                      // onGeolocate={handleGeolocate}
                                                    />
                                                    {/* <Geocoder
                                              setResult={setResult}
                                              setAddress={setAddress}
                                              setCountry={setCountry}
                                              setState={setState}
                                              setCity={setCity}
                                              setPostal_Code={setPostal_Code}
                                              setLatLng={setLatLng}
                                              /> */}
                                                  </ReactMapGL>
                                                </Box>
                                              </Form.Group>
                                            </div>
                                          </div>
                                        </Form>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          )
                        )}
                    </div>
                  </div>
                </div>
              ) : (
                <div className="row mx-0 mt-4">
                  <div className="col-10 mx-auto">
                    <div className="row gx-5">
                      {quotationDetail &&
                        quotationDetail?.plan[0] &&
                        quotationDetail?.plan[0]?.questionaire?.questions
                          ?.length > 0 &&
                        quotationDetail?.plan[0]?.questionaire?.questions.map(
                          (val, i) => (
                            <div className="row mt-3">
                              <h1>{val?.question}</h1>
                              <div className="answerBox">
                                {val?.answerType === "choice" ? (
                                  val?.score?.map((item, index) => (
                                    <div key={index}>
                                      <label>
                                        <input
                                          type="radio"
                                          name={`${val?.question}`}
                                          value={latestAnswers[i]?.answer}
                                          checked={
                                            item.text ===
                                            latestAnswers[i]?.answer
                                          }
                                        />
                                        {item.text}
                                      </label>
                                    </div>
                                  ))
                                ) : val?.answerType === "yesNo" &&
                                  val?.score !== 0 ? (
                                  <Form.Group
                                    className="mb-3"
                                    controlId="formBasicEmail"
                                  >
                                    <Form.Control
                                      as="textarea"
                                      rows={5}
                                      value={latestAnswers[i]?.answer}
                                      disabled={
                                        user?.data?.user?.role === "staff" ||
                                        user?.data?.user?.role === "admin"
                                          ? true
                                          : false
                                      }
                                    />
                                  </Form.Group>
                                ) : val?.answerType === "image" ? (
                                  <>
                                    <div className="image-placeholder px-3 my-4">
                                      {latestAnswers[i]?.answer?.length > 0 &&
                                        latestAnswers[i]?.answer?.map((val) => (
                                          <>
                                            <div className="avatar-preview">
                                              <div id="imagePreview">
                                                <img
                                                  id="saveImageFile"
                                                  src={val.img}
                                                  alt={val.img}
                                                />
                                              </div>
                                            </div>
                                          </>
                                        ))}
                                    </div>
                                  </>
                                ) : (
                                  <div className="cardSection mt-4 mb-2">
                                    <div className="row mx-0">
                                      <div className="col-10 mx-auto">
                                        <Form className="mt-5">
                                          <div className="row mx-0">
                                            <div className="col-md-5 px-0">
                                              <Form.Group
                                                className="mb-3"
                                                controlId="formBasicEmail"
                                              >
                                                <Form.Label>
                                                  Country/Island
                                                </Form.Label>
                                                <InputGroup className="mb-3">
                                                  <InputGroup.Text id="basic-addon1">
                                                    <img
                                                      src="/imgs/country.svg"
                                                      alt=""
                                                    />
                                                  </InputGroup.Text>
                                                  <Form.Control
                                                    type="text"
                                                    name="country"
                                                    value={
                                                      latestAnswers[i]?.answer
                                                        ?.country
                                                    }
                                                  />
                                                </InputGroup>
                                              </Form.Group>
                                            </div>
                                            <div className="col-md-2"></div>
                                            <div className="col-md-5 px-0">
                                              <Form.Group
                                                className="mb-3"
                                                controlId="formBasicEmail"
                                              >
                                                <Form.Label>State</Form.Label>
                                                <InputGroup className="mb-3">
                                                  <InputGroup.Text id="basic-addon1">
                                                    <img
                                                      src="/imgs/address.svg"
                                                      alt=""
                                                    />
                                                  </InputGroup.Text>
                                                  <Form.Control
                                                    type="text"
                                                    name="state"
                                                    value={
                                                      latestAnswers[i]?.answer
                                                        ?.state
                                                    }
                                                  />
                                                </InputGroup>
                                              </Form.Group>
                                            </div>
                                            <div className="col-md-5 px-0">
                                              <Form.Group
                                                className="mb-3"
                                                controlId="formBasicEmail"
                                              >
                                                <Form.Label>City</Form.Label>
                                                <InputGroup className="mb-3">
                                                  <InputGroup.Text id="basic-addon1">
                                                    <img
                                                      src="/imgs/address.svg"
                                                      alt=""
                                                    />
                                                  </InputGroup.Text>
                                                  <Form.Control
                                                    type="text"
                                                    name="city"
                                                    value={
                                                      latestAnswers[i]?.answer
                                                        ?.city
                                                    }
                                                  />
                                                </InputGroup>
                                              </Form.Group>
                                            </div>
                                            <div className="col-md-2"></div>
                                            <div className="col-md-5 px-0">
                                              <Form.Group
                                                className="mb-3"
                                                controlId="formBasicEmail"
                                              >
                                                <Form.Label>
                                                  Postal Code
                                                </Form.Label>
                                                <InputGroup className="mb-3">
                                                  <InputGroup.Text id="basic-addon1">
                                                    <img
                                                      src="/imgs/postal_code.svg"
                                                      alt=""
                                                    />
                                                  </InputGroup.Text>
                                                  <Form.Control
                                                    type="text"
                                                    name="postal_code"
                                                    value={
                                                      latestAnswers[i]?.answer
                                                        ?.postCode
                                                        ? latestAnswers[i]
                                                            ?.answer?.postCode
                                                        : latestAnswers[i]
                                                            ?.answer?.postalCode
                                                    }
                                                  />
                                                </InputGroup>
                                              </Form.Group>
                                            </div>
                                            <div className="col-md-12 px-0">
                                              <Form.Group
                                                className="mb-3"
                                                controlId="formBasicEmail"
                                              >
                                                <Form.Label>Address</Form.Label>
                                                <InputGroup className="mb-3">
                                                  <InputGroup.Text id="basic-addon1">
                                                    <img
                                                      src="/imgs/address.svg"
                                                      alt=""
                                                    />
                                                  </InputGroup.Text>
                                                  <Form.Control
                                                    type="text"
                                                    name="address"
                                                    value={
                                                      latestAnswers[i]?.answer
                                                        ?.address
                                                    }
                                                  />
                                                </InputGroup>
                                              </Form.Group>
                                            </div>
                                            <div className="col-md-12 px-0">
                                              <Form.Group
                                                className="mb-3"
                                                controlId="formBasicEmail"
                                              >
                                                <Form.Label>
                                                  Map Location
                                                </Form.Label>
                                                <Box
                                                  sx={{
                                                    height: 400,
                                                    position: "relative",
                                                  }}
                                                >
                                                  <ReactMapGL
                                                    ref={mapRef}
                                                    mapboxAccessToken="pk.eyJ1IjoiYmx1ZXNpbG9zZyIsImEiOiJjbGZyeDZ1MGswMG1mM2RxcmJyajRzbXRyIn0.SwkzsvT0dgMXSafnYQoZSQ"
                                                    initialViewState={{
                                                      zoom: 11,
                                                      // latitude: 1.3521,
                                                      // longitude: 103.8198,
                                                      longitude: latestAnswers[
                                                        i
                                                      ]?.answer?.longitude
                                                        ? Number(
                                                            latestAnswers[i]
                                                              ?.answer
                                                              ?.longitude
                                                          )
                                                        : 103.8198,
                                                      latitude: latestAnswers[i]
                                                        ?.answer?.latitude
                                                        ? Number(
                                                            latestAnswers[i]
                                                              ?.answer?.answer
                                                              ?.latitude
                                                          )
                                                        : 1.3521,
                                                    }}
                                                    mapStyle="mapbox://styles/mapbox/streets-v11"
                                                  >
                                                    <Marker
                                                      // longitude={103.8198}
                                                      // latitude={1.3521}
                                                      latitude={
                                                        latestAnswers[i]?.answer
                                                          ?.latitude
                                                          ? Number(
                                                              latestAnswers[i]
                                                                ?.answer
                                                                ?.latitude
                                                            )
                                                          : 1.3521
                                                      }
                                                      longitude={
                                                        latestAnswers[i]?.answer
                                                          ?.longitude
                                                          ? Number(
                                                              latestAnswers[i]
                                                                ?.answer
                                                                ?.longitude
                                                            )
                                                          : 103.8198
                                                      }
                                                      // draggable
                                                      // onDragEnd={(e) => {
                                                      //   handleMarkerChange(
                                                      //     e.lngLat.lat,
                                                      //     e.lngLat.lng,
                                                      //     i,
                                                      //     val.id,
                                                      //     val?.score
                                                      //   );
                                                      // }}
                                                    />
                                                    <NavigationControl position="bottom-right" />
                                                    <GeolocateControl
                                                      position="top-left"
                                                      // trackUserLocation
                                                      // onGeolocate={handleGeolocate}
                                                    />
                                                    {/* <Geocoder
                                                setResult={setResult}
                                                setAddress={setAddress}
                                                setCountry={setCountry}
                                                setState={setState}
                                                setCity={setCity}
                                                setPostal_Code={setPostal_Code}
                                                setLatLng={setLatLng}
                                                /> */}
                                                  </ReactMapGL>
                                                </Box>
                                              </Form.Group>
                                            </div>
                                          </div>
                                        </Form>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          )
                        )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          ) : quotationDetail?.plan[0]?.product_type === "Video" ? (
            <div className="cardSection mt-4">
              <div className="d-flex justify-content-between">
                <h1>Self-Generated Video </h1>
              </div>
              <div className="row mx-0 mt-4">
                <div className="col-12 px-0">
                  <div className="row gx-5">
                    <div className="col-12">
                      <div className="w-100 mb-3 borderDashed border-0 text-center">
                        {/* <img src="/imgs/uploadBig.svg" alt="" />
                  <h1>Drag & Drop your Picture you want to upload</h1>
                  <h2>Drag & Drop your file you want to upload</h2>
                  <div className="uploadBtnDesign">
                    <Button
                      className="loginBtn w-100 mt-3"
                      onClick={handleShow}
                    >
                      Browser
                    </Button>
                  </div> */}

                        <video className="w-100" height="240" controls>
                          <source
                            src={quotationDetail?.coverVideo}
                            type="video/mp4"
                          />
                        </video>
                        <div className="thumbnail">
                          <h1 className="mb-1 mt-3">Thumbnail</h1>

                          <div className="coverImg mb-3 mt-2">
                            <div className="image-placeholder my-4">
                              {quotationDetail?.thumbnails !== [] &&
                                quotationDetail?.thumbnails?.map((val) => (
                                  <>
                                    <div className="avatar-preview">
                                      <div id="imagePreview" key={val.id}>
                                        <img
                                          id="saveImageFile"
                                          src={val.img}
                                          alt="Thumbnail Image"
                                        />
                                      </div>
                                      <Form.Group
                                        className="mb-3"
                                        controlId="formBasicCheckbox"
                                        value={val.cover}
                                      >
                                        <Form.Check
                                          type="checkbox"
                                          label="Cover"
                                          checked={val.cover}
                                        />
                                      </Form.Group>
                                    </div>
                                  </>
                                ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Purpose</Form.Label>
                    <InputGroup className="mb-3">
                      <InputGroup.Text id="basic-addon1">
                        <img src="/imgs/amount.svg" alt="" />
                      </InputGroup.Text>
                      <Form.Control
                        type="text"
                        value={quotationDetail?.Purpose}
                        placeholder="Enter prupose of content"
                      />
                    </InputGroup>
                  </Form.Group>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Intended audience</Form.Label>
                    <InputGroup className="mb-3">
                      <InputGroup.Text id="basic-addon1">
                        <img src="/imgs/amount.svg" alt="" />
                      </InputGroup.Text>
                      <Form.Control
                        type="text"
                        value={quotationDetail?.Intended}
                        placeholder="Enter the intended of the content"
                      />
                    </InputGroup>
                  </Form.Group>
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Request</Form.Label>
                    <InputGroup className="mb-3">
                      <InputGroup.Text id="basic-addon1">
                        <img src="/imgs/amount.svg" alt="" />
                      </InputGroup.Text>
                      <Form.Control
                        type="text"
                        value={quotationDetail?.Request}
                        placeholder="Enter request"
                      />
                    </InputGroup>
                  </Form.Group>
                </div>
              </div>
            </div>
          ) : quotationDetail?.plan[0]?.product_type === "360 tour" ? (
            <div className="cardSection mt-4">
              <div className="d-flex justify-content-between">
                <h1>Pricing type is 360 Tour</h1>
              </div>
              {quotationDetail?.plan[0]?.pricing_type == "Quoted" ? (
                <div className="row mx-0 mt-4">
                  <div className="col-10 mx-auto">
                    <div className="row gx-5">
                      {quotationDetail &&
                        quotationDetail?.templateQuestions &&
                        quotationDetail?.templateQuestions?.questions.length >
                          0 &&
                        quotationDetail?.templateQuestions?.questions.map(
                          (val, i) => (
                            <div className="row mt-3">
                              {/* <div className="col-md-6"> */}
                              <h1>{val?.question}</h1>
                              <div className="answerBox">
                                {val?.answerType === "choice" ? (
                                  val?.score?.map((item, index) => (
                                    <div key={index}>
                                      <label>
                                        <input
                                          type="radio"
                                          name={`${val?.question}`}
                                          value={item.text}
                                          checked={
                                            item.text ==
                                            latestAnswers[i]?.answer
                                          }
                                        />
                                        {item.text}
                                      </label>
                                    </div>
                                  ))
                                ) : val?.answerType === "yesNo" &&
                                  val?.score !== 0 ? (
                                  <Form.Group
                                    className="mb-3"
                                    controlId="formBasicEmail"
                                  >
                                    <Form.Control
                                      as="textarea"
                                      rows={5}
                                      value={latestAnswers[i]?.answer}
                                      // onChange={(e) =>
                                      //   handleChange(e, i, val.id, val?.score)
                                      // }
                                      disabled={
                                        user?.data?.user?.role === "staff" ||
                                        user?.data?.user?.role === "admin"
                                          ? true
                                          : false
                                      }
                                    />
                                  </Form.Group>
                                ) : val?.answerType === "image" ? (
                                  <>
                                    <div className="image-placeholder px-3 my-4">
                                      {latestAnswers[i]?.answer?.length > 0 &&
                                        latestAnswers[i]?.answer?.map?.(
                                          (val) => (
                                            <>
                                              <div className="avatar-preview">
                                                <div id="imagePreview">
                                                  <img
                                                    id="saveImageFile"
                                                    src={val.img}
                                                    alt={val.img}
                                                  />
                                                </div>
                                              </div>
                                            </>
                                          )
                                        )}
                                    </div>
                                  </>
                                ) : val?.answerType === "address" ? (
                                  <>
                                    {latestAnswers[i]?.answer !== [] &&
                                      latestAnswers[i]?.answer && (
                                        <>
                                          <div className="cardSection mt-4 mb-2">
                                            <div className="row mx-0">
                                              <div className="col-10 mx-auto">
                                                <Form className="mt-5">
                                                  <div className="row mx-0">
                                                    <div className="col-md-5 px-0">
                                                      <Form.Group
                                                        className="mb-3"
                                                        controlId="formBasicEmail"
                                                      >
                                                        <Form.Label>
                                                          Country/Island
                                                        </Form.Label>
                                                        <InputGroup className="mb-3">
                                                          <InputGroup.Text id="basic-addon1">
                                                            <img
                                                              src="/imgs/country.svg"
                                                              alt=""
                                                            />
                                                          </InputGroup.Text>
                                                          <Form.Control
                                                            type="text"
                                                            name="country"
                                                            value={
                                                              latestAnswers[i]
                                                                ?.answer.country
                                                                ? latestAnswers[
                                                                    i
                                                                  ]?.answer
                                                                    .country
                                                                : ""
                                                            }
                                                            // onChange={(e) =>
                                                            //   setCountry(
                                                            //     e.target.value
                                                            //   )
                                                            // }
                                                          />
                                                        </InputGroup>
                                                      </Form.Group>
                                                    </div>
                                                    <div className="col-md-2"></div>
                                                    <div className="col-md-5 px-0">
                                                      <Form.Group
                                                        className="mb-3"
                                                        controlId="formBasicEmail"
                                                      >
                                                        <Form.Label>
                                                          State
                                                        </Form.Label>
                                                        <InputGroup className="mb-3">
                                                          <InputGroup.Text id="basic-addon1">
                                                            <img
                                                              src="/imgs/address.svg"
                                                              alt=""
                                                            />
                                                          </InputGroup.Text>
                                                          <Form.Control
                                                            type="text"
                                                            name="state"
                                                            value={
                                                              latestAnswers[i]
                                                                ?.answer.state
                                                                ? latestAnswers[
                                                                    i
                                                                  ]?.answer
                                                                    .state
                                                                : ""
                                                            }
                                                            // onChange={(e) =>
                                                            //   setState(
                                                            //     e.target.value
                                                            //   )
                                                            // }
                                                          />
                                                        </InputGroup>
                                                      </Form.Group>
                                                    </div>
                                                    <div className="col-md-5 px-0">
                                                      <Form.Group
                                                        className="mb-3"
                                                        controlId="formBasicEmail"
                                                      >
                                                        <Form.Label>
                                                          City
                                                        </Form.Label>
                                                        <InputGroup className="mb-3">
                                                          <InputGroup.Text id="basic-addon1">
                                                            <img
                                                              src="/imgs/address.svg"
                                                              alt=""
                                                            />
                                                          </InputGroup.Text>
                                                          <Form.Control
                                                            type="text"
                                                            name="city"
                                                            value={
                                                              latestAnswers[i]
                                                                ?.answer.city
                                                                ? latestAnswers[
                                                                    i
                                                                  ]?.answer.city
                                                                : ""
                                                            }
                                                            // onChange={(e) =>
                                                            //   setCity(
                                                            //     e.target.value
                                                            //   )
                                                            // }
                                                          />
                                                        </InputGroup>
                                                      </Form.Group>
                                                    </div>
                                                    <div className="col-md-2"></div>
                                                    <div className="col-md-5 px-0">
                                                      <Form.Group
                                                        className="mb-3"
                                                        controlId="formBasicEmail"
                                                      >
                                                        <Form.Label>
                                                          Postal Code
                                                        </Form.Label>
                                                        <InputGroup className="mb-3">
                                                          <InputGroup.Text id="basic-addon1">
                                                            <img
                                                              src="/imgs/postal_code.svg"
                                                              alt=""
                                                            />
                                                          </InputGroup.Text>
                                                          <Form.Control
                                                            type="text"
                                                            name="postal_code"
                                                            value={
                                                              latestAnswers[i]
                                                                ?.answer
                                                                ?.postCode
                                                                ? latestAnswers[
                                                                    i
                                                                  ]?.answer
                                                                    ?.postCode
                                                                : latestAnswers[
                                                                    i
                                                                  ]?.answer
                                                                    ?.postalCode
                                                            }
                                                            // onChange={(e) =>
                                                            //   setPostal_Code(
                                                            //     e.target.value
                                                            //   )
                                                            // }
                                                          />
                                                        </InputGroup>
                                                      </Form.Group>
                                                    </div>
                                                    <div className="col-md-12 px-0">
                                                      <Form.Group
                                                        className="mb-3"
                                                        controlId="formBasicEmail"
                                                      >
                                                        <Form.Label>
                                                          Address
                                                        </Form.Label>
                                                        <InputGroup className="mb-3">
                                                          <InputGroup.Text id="basic-addon1">
                                                            <img
                                                              src="/imgs/address.svg"
                                                              alt=""
                                                            />
                                                          </InputGroup.Text>
                                                          <Form.Control
                                                            type="text"
                                                            name="address"
                                                            value={
                                                              latestAnswers[i]
                                                                ?.answer.address
                                                                ? latestAnswers[
                                                                    i
                                                                  ]?.answer
                                                                    .address
                                                                : ""
                                                            }
                                                            // onChange={(e) =>
                                                            //   setAddress(
                                                            //     e.target.value
                                                            //   )
                                                            // }
                                                          />
                                                        </InputGroup>
                                                      </Form.Group>
                                                    </div>
                                                    <div className="col-md-12 px-0">
                                                      <Form.Group
                                                        className="mb-3"
                                                        controlId="formBasicEmail"
                                                      >
                                                        <Form.Label>
                                                          Map Location
                                                        </Form.Label>
                                                        <Box
                                                          sx={{
                                                            height: 400,
                                                            position:
                                                              "relative",
                                                          }}
                                                        >
                                                          <ReactMapGL
                                                            ref={mapRef}
                                                            mapboxAccessToken="pk.eyJ1IjoiYmx1ZXNpbG9zZyIsImEiOiJjbGZyeDZ1MGswMG1mM2RxcmJyajRzbXRyIn0.SwkzsvT0dgMXSafnYQoZSQ"
                                                            initialViewState={{
                                                              zoom: 11,
                                                              longitude:
                                                                latestAnswers[i]
                                                                  ?.answer
                                                                  .longitude
                                                                  ? Number(
                                                                      latestAnswers[
                                                                        i
                                                                      ]?.answer
                                                                        .longitude
                                                                    )
                                                                  : 103.8198,
                                                              latitude:
                                                                latestAnswers[i]
                                                                  ?.answer
                                                                  .latitude
                                                                  ? Number(
                                                                      latestAnswers[
                                                                        i
                                                                      ]?.answer
                                                                        .latitude
                                                                    )
                                                                  : 1.3521,
                                                              // longitude: `${103.8198}`,
                                                              // latitude: `${1.3521}`,
                                                              // latitude: 1.3521,
                                                              // longitude: 103.8198,
                                                            }}
                                                            mapStyle="mapbox://styles/mapbox/streets-v11"
                                                          >
                                                            <Marker
                                                              // longitude={103.8198}
                                                              // latitude={1.3521}
                                                              latitude={
                                                                latestAnswers[i]
                                                                  ?.answer
                                                                  .latitude
                                                                  ? Number(
                                                                      latestAnswers[
                                                                        i
                                                                      ]?.answer
                                                                        .latitude
                                                                    )
                                                                  : 1.3521
                                                              }
                                                              longitude={
                                                                latestAnswers[i]
                                                                  ?.answer
                                                                  .longitude
                                                                  ? Number(
                                                                      latestAnswers[
                                                                        i
                                                                      ]?.answer
                                                                        .longitude
                                                                    )
                                                                  : 103.8198
                                                              }
                                                              // draggable
                                                              // onDragEnd={(e) => {
                                                              //   mapdispatch({
                                                              //     type: "UPDATE_LOCATION",
                                                              //     payload: {
                                                              //       lng: e.lngLat
                                                              //         .lng,
                                                              //       lat: e.lngLat
                                                              //         .lat,
                                                              //     },
                                                              //   });
                                                              // }}
                                                            />
                                                            <NavigationControl position="bottom-right" />
                                                            <GeolocateControl
                                                              position="top-left"
                                                              // trackUserLocation
                                                              // onGeolocate={handleGeolocate}
                                                            />
                                                            {/* <Geocoder
                                              setResult={setResult}
                                              setAddress={setAddress}
                                              setCountry={setCountry}
                                              setState={setState}
                                              setCity={setCity}
                                              setPostal_Code={setPostal_Code}
                                              setLatLng={setLatLng}
                                            /> */}
                                                          </ReactMapGL>
                                                        </Box>
                                                      </Form.Group>
                                                    </div>
                                                  </div>
                                                </Form>
                                              </div>
                                            </div>
                                          </div>
                                        </>
                                      )}
                                  </>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          )
                        )}
                    </div>
                  </div>
                </div>
              ) : (
                <div className="row mx-0 mt-4">
                  <div className="col-10 mx-auto">
                    <div className="row gx-5">
                      {quotationDetail &&
                        quotationDetail?.plan[0] &&
                        quotationDetail?.plan[0]?.questionaire?.questions
                          ?.length > 0 &&
                        quotationDetail?.plan[0]?.questionaire?.questions.map(
                          (val, i) => (
                            <div className="row mt-3">
                              <h1>{val?.question}</h1>
                              <div className="answerBox">
                                {val?.answerType === "choice" ? (
                                  val?.score?.map((item, index) => (
                                    <div key={index}>
                                      <label>
                                        <input
                                          type="radio"
                                          name={`${val?.question}`}
                                          value={latestAnswers[i]?.answer}
                                          checked={
                                            item.text ===
                                            latestAnswers[i]?.answer
                                          }
                                        />
                                        {item.text}
                                      </label>
                                    </div>
                                  ))
                                ) : val?.answerType === "yesNo" &&
                                  val?.score !== 0 ? (
                                  <Form.Group
                                    className="mb-3"
                                    controlId="formBasicEmail"
                                  >
                                    <Form.Control
                                      as="textarea"
                                      rows={5}
                                      value={latestAnswers[i]?.answer}
                                      disabled={
                                        user?.data?.user?.role === "staff" ||
                                        user?.data?.user?.role === "admin"
                                          ? true
                                          : false
                                      }
                                    />
                                  </Form.Group>
                                ) : val?.answerType === "image" ? (
                                  <>
                                    <div className="image-placeholder px-3 my-4">
                                      {latestAnswers[i]?.answer?.length > 0 &&
                                        latestAnswers[i]?.answer?.map((val) => (
                                          <>
                                            <div className="avatar-preview">
                                              <div id="imagePreview">
                                                <img
                                                  id="saveImageFile"
                                                  src={val.img}
                                                  alt={val.img}
                                                />
                                              </div>
                                            </div>
                                          </>
                                        ))}
                                    </div>
                                  </>
                                ) : val?.answerType === "address" ? (
                                  <>
                                    {latestAnswers !== [] && latestAnswers && (
                                      <>
                                        <div className="cardSection mt-4 mb-2">
                                          <div className="row mx-0">
                                            <div className="col-10 mx-auto">
                                              <Form className="mt-5">
                                                <div className="row mx-0">
                                                  <div className="col-md-5 px-0">
                                                    <Form.Group
                                                      className="mb-3"
                                                      controlId="formBasicEmail"
                                                    >
                                                      <Form.Label>
                                                        Country/Island
                                                      </Form.Label>
                                                      <InputGroup className="mb-3">
                                                        <InputGroup.Text id="basic-addon1">
                                                          <img
                                                            src="/imgs/country.svg"
                                                            alt=""
                                                          />
                                                        </InputGroup.Text>
                                                        <Form.Control
                                                          type="text"
                                                          name="country"
                                                          value={
                                                            latestAnswers[i]
                                                              ?.answer.country
                                                              ? latestAnswers[i]
                                                                  ?.answer
                                                                  .country
                                                              : ""
                                                          }
                                                          // onChange={(e) =>
                                                          //   setCountry(
                                                          //     e.target.value
                                                          //   )
                                                          // }
                                                        />
                                                      </InputGroup>
                                                    </Form.Group>
                                                  </div>
                                                  <div className="col-md-2"></div>
                                                  <div className="col-md-5 px-0">
                                                    <Form.Group
                                                      className="mb-3"
                                                      controlId="formBasicEmail"
                                                    >
                                                      <Form.Label>
                                                        State
                                                      </Form.Label>
                                                      <InputGroup className="mb-3">
                                                        <InputGroup.Text id="basic-addon1">
                                                          <img
                                                            src="/imgs/address.svg"
                                                            alt=""
                                                          />
                                                        </InputGroup.Text>
                                                        <Form.Control
                                                          type="text"
                                                          name="state"
                                                          value={
                                                            latestAnswers[i]
                                                              ?.answer.state
                                                              ? latestAnswers[i]
                                                                  ?.answer.state
                                                              : ""
                                                          }
                                                          // onChange={(e) =>
                                                          //   setState(
                                                          //     e.target.value
                                                          //   )
                                                          // }
                                                        />
                                                      </InputGroup>
                                                    </Form.Group>
                                                  </div>
                                                  <div className="col-md-5 px-0">
                                                    <Form.Group
                                                      className="mb-3"
                                                      controlId="formBasicEmail"
                                                    >
                                                      <Form.Label>
                                                        City
                                                      </Form.Label>
                                                      <InputGroup className="mb-3">
                                                        <InputGroup.Text id="basic-addon1">
                                                          <img
                                                            src="/imgs/address.svg"
                                                            alt=""
                                                          />
                                                        </InputGroup.Text>
                                                        <Form.Control
                                                          type="text"
                                                          name="city"
                                                          value={
                                                            latestAnswers[i]
                                                              ?.answer?.city
                                                              ? latestAnswers[i]
                                                                  ?.answer.city
                                                              : ""
                                                          }
                                                          // onChange={(e) =>
                                                          //   setCity(
                                                          //     e.target.value
                                                          //   )
                                                          // }
                                                        />
                                                      </InputGroup>
                                                    </Form.Group>
                                                  </div>
                                                  <div className="col-md-2"></div>
                                                  <div className="col-md-5 px-0">
                                                    <Form.Group
                                                      className="mb-3"
                                                      controlId="formBasicEmail"
                                                    >
                                                      <Form.Label>
                                                        Postal Code
                                                      </Form.Label>
                                                      <InputGroup className="mb-3">
                                                        <InputGroup.Text id="basic-addon1">
                                                          <img
                                                            src="/imgs/postal_code.svg"
                                                            alt=""
                                                          />
                                                        </InputGroup.Text>
                                                        <Form.Control
                                                          type="text"
                                                          name="postal_code"
                                                          value={
                                                            latestAnswers[i]
                                                              ?.answer?.postCode
                                                              ? latestAnswers[i]
                                                                  ?.answer
                                                                  ?.postCode
                                                              : latestAnswers[i]
                                                                  ?.answer
                                                                  ?.postalCode
                                                          }
                                                          // onChange={(e) =>
                                                          //   setPostal_Code(
                                                          //     e.target.value
                                                          //   )
                                                          // }
                                                        />
                                                      </InputGroup>
                                                    </Form.Group>
                                                  </div>
                                                  <div className="col-md-12 px-0">
                                                    <Form.Group
                                                      className="mb-3"
                                                      controlId="formBasicEmail"
                                                    >
                                                      <Form.Label>
                                                        Address
                                                      </Form.Label>
                                                      <InputGroup className="mb-3">
                                                        <InputGroup.Text id="basic-addon1">
                                                          <img
                                                            src="/imgs/address.svg"
                                                            alt=""
                                                          />
                                                        </InputGroup.Text>
                                                        <Form.Control
                                                          type="text"
                                                          name="address"
                                                          value={
                                                            latestAnswers[i]
                                                              ?.answer.address
                                                              ? latestAnswers[i]
                                                                  ?.answer
                                                                  .address
                                                              : ""
                                                          }
                                                          // onChange={(e) =>
                                                          //   setAddress(
                                                          //     e.target.value
                                                          //   )
                                                          // }
                                                        />
                                                      </InputGroup>
                                                    </Form.Group>
                                                  </div>
                                                  <div className="col-md-12 px-0">
                                                    <Form.Group
                                                      className="mb-3"
                                                      controlId="formBasicEmail"
                                                    >
                                                      <Form.Label>
                                                        Map Location
                                                      </Form.Label>
                                                      <Box
                                                        sx={{
                                                          height: 400,
                                                          position: "relative",
                                                        }}
                                                      >
                                                        <ReactMapGL
                                                          ref={mapRef}
                                                          mapboxAccessToken="pk.eyJ1IjoiYmx1ZXNpbG9zZyIsImEiOiJjbGZyeDZ1MGswMG1mM2RxcmJyajRzbXRyIn0.SwkzsvT0dgMXSafnYQoZSQ"
                                                          initialViewState={{
                                                            zoom: 11,
                                                            longitude:
                                                              latestAnswers[i]
                                                                ?.answer
                                                                .longitude
                                                                ? Number(
                                                                    latestAnswers[
                                                                      i
                                                                    ]?.answer
                                                                      .longitude
                                                                  )
                                                                : 103.8198,
                                                            latitude:
                                                              latestAnswers[i]
                                                                ?.answer
                                                                .latitude
                                                                ? Number(
                                                                    latestAnswers[
                                                                      i
                                                                    ]?.answer
                                                                      .latitude
                                                                  )
                                                                : 1.3521,
                                                            // longitude: `${103.8198}`,
                                                            // latitude: `${1.3521}`,
                                                            // latitude: 1.3521,
                                                            // longitude: 103.8198,
                                                          }}
                                                          mapStyle="mapbox://styles/mapbox/streets-v11"
                                                        >
                                                          <Marker
                                                            // longitude={103.8198}
                                                            // latitude={1.3521}
                                                            latitude={
                                                              latestAnswers[i]
                                                                ?.answer
                                                                .latitude
                                                                ? Number(
                                                                    latestAnswers[
                                                                      i
                                                                    ]?.answer
                                                                      .latitude
                                                                  )
                                                                : 1.3521
                                                            }
                                                            longitude={
                                                              latestAnswers[i]
                                                                ?.answer
                                                                .longitude
                                                                ? Number(
                                                                    latestAnswers[
                                                                      i
                                                                    ]?.answer
                                                                      .longitude
                                                                  )
                                                                : 103.8198
                                                            }
                                                          />
                                                          <NavigationControl position="bottom-right" />
                                                          <GeolocateControl
                                                            position="top-left"
                                                            // trackUserLocation
                                                            // onGeolocate={handleGeolocate}
                                                          />
                                                        </ReactMapGL>
                                                      </Box>
                                                    </Form.Group>
                                                  </div>
                                                </div>
                                              </Form>
                                            </div>
                                          </div>
                                        </div>
                                      </>
                                    )}
                                  </>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          )
                        )}
                    </div>
                  </div>
                </div>
              )}
              {/* <div className="row mx-0 mt-4">
            <div className="col-10 mx-auto">
              <div className="row gx-5">
                {state?.Details &&
                  state?.Details?.templateQuestions &&
                  state?.Details?.templateQuestions?.questions.length > 0 &&
                  state?.Details?.templateQuestions?.questions.map((val, i) => (
                    <div className="row mt-3">
                      <h1>{val?.question}</h1>
                      <div className="answerBox">
                        {val?.answerType === "choice" ? (
                          val?.score?.map((item, index) => (
                            <div key={index}>
                              <label>
                                <input
                                  type="radio"
                                  name={`${val?.question}`}
                                  value={item.text}
                                />
                                {item.text}
                              </label>
                            </div>
                          ))
                        ) : val?.answerType === "yesNo" && val?.score !== 0 ? (
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            <Form.Control
                              as="textarea"
                              rows={5}
                              value={state?.Details?.questArray[i]?.answer}
                              disabled={
                                user?.data?.user?.role === "staff" ||
                                user?.data?.user?.role === "admin"
                                  ? true
                                  : false
                              }
                            />
                          </Form.Group>
                        ) : val?.answerType === "image" && val.score !== 0 ? (
                          <>
                            <div className="image-placeholder px-3 my-4">
                              {state?.Details?.questArray[i]?.answer !== [] &&
                                state?.Details?.questArray[i]?.answer?.map(
                                  (val) => (
                                    <>
                                      <div className="avatar-preview">
                                        <div id="imagePreview">
                                          <img
                                            id="saveImageFile"
                                            src={val.img}
                                            alt={val.img}
                                          />
                                        </div>
                                      </div>
                                    </>
                                  )
                                )}
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div> */}
            </div>
          ) : (
            ""
          )}
        </div>
      )}
    </>
  );
}

export default OrderDetail;
