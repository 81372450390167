import React from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { useNavigate } from "react-router-dom";
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';

function ContributorForm({ handleAddFormChange, AddForm, uploadFileHandler, imageLocation, showPassword, showPass, Edit}) {
  const navigate = useNavigate();

  var dtToday = new Date();

  var month = dtToday.getMonth() + 1;
  var day = dtToday.getDate() - 1;
  var year = dtToday.getFullYear();

  if (month < 10) month = "0" + month.toString();
  if (day < 10) day = "0" + day.toString();

  var maxDate = year + "-" + month + "-" + day;

  const defaultImage = 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_640.png';

  return (
    <div>
      <div className="cardSection">
        <div className="d-flex justify-content-between">
          <h1>General Information</h1>
          <div>
            <div className="d-flex justify-content-center gap-4 mb-3">
              <Button
                className="cancelBtn"
                type="button"
                onClick={() => navigate(-1)}
              >
                Cancel
              </Button>
              {
                Edit == true ? (
                  <Button className="loginBtn" disabled={false} onClick={AddForm}>
                    Save
                  </Button>
                ) : (
                  <Button className="loginBtn" disabled={true}>
                    Save
                  </Button>
                )
              }
              
            </div>
          </div>
        </div>
        <div className="text-center">
          <img
            src={imageLocation ? imageLocation : defaultImage}
            className="profileImg"
            alt=""
          />
          <br />
          <input type="file" onChange={uploadFileHandler}></input>
        </div>
        <div className="row mx-0">
          <div className="col-10 mx-auto">
            <Form className="mt-5">
              <div className="row mx-0">
                <div className="col-md-5 px-0">
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>First Name</Form.Label>
                    <InputGroup className="mb-3">
                      <InputGroup.Text id="basic-addon1">
                        <img src="/imgs/u_user.svg" alt="" />
                      </InputGroup.Text>
                      <Form.Control
                        type="text"
                        name="first_name"
                        onChange={handleAddFormChange}
                      />
                    </InputGroup>
                  </Form.Group>
                </div>
                <div className="col-md-2"></div>
                <div className="col-md-5 px-0">
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Last Name</Form.Label>
                    <InputGroup className="mb-3">
                      <InputGroup.Text id="basic-addon1">
                        <img src="/imgs/u_user.svg" alt="" />
                      </InputGroup.Text>
                      <Form.Control
                        type="text"
                        name="last_name"
                        onChange={handleAddFormChange}
                      />
                    </InputGroup>
                  </Form.Group>
                </div>
                <div className="col-md-5 px-0">
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>User Name</Form.Label>
                    <InputGroup className="mb-3">
                      <InputGroup.Text id="basic-addon1">
                        <img src="/imgs/u_user.svg" alt="" />
                      </InputGroup.Text>
                      <Form.Control
                        type="text"
                        name="user_name"
                        onChange={handleAddFormChange}
                      />
                    </InputGroup>
                  </Form.Group>
                </div>
                <div className="col-md-2"></div>
                <div className="col-md-5 px-0">
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Date of Birth</Form.Label>
                    <InputGroup className="mb-3">
                      <InputGroup.Text id="basic-addon1">
                        <img src="/imgs/dob.svg" alt="" />
                      </InputGroup.Text>
                      <Form.Control
                        type="date"
                        max={maxDate}
                        name="dob"
                        onChange={handleAddFormChange}
                      />
                    </InputGroup>
                  </Form.Group>
                </div>
                <div className="col-md-5 px-0">
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>National Id</Form.Label>
                    <InputGroup className="mb-3">
                      <InputGroup.Text id="basic-addon1">
                        <img src="/imgs/national.svg" alt="" />
                      </InputGroup.Text>
                      <Form.Control
                        type="text"
                        name="national_id"
                        onChange={handleAddFormChange}
                      />
                    </InputGroup>
                  </Form.Group>
                </div>
                <div className="col-md-2"></div>
                <div className="col-md-5 px-0">
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Status</Form.Label>
                    <InputGroup className="mb-3">
                      <InputGroup.Text id="basic-addon1">
                        <img src="/imgs/u_user.svg" alt="" />
                      </InputGroup.Text>
                      <Form.Select
                        aria-label="Default select example"
                        name="status"
                        onChange={handleAddFormChange}
                      >
                        <option value="">Select Status</option>
                        <option value="Active">Active</option>
                        <option value="Revoked">Revoked</option>
                      </Form.Select>
                    </InputGroup>
                  </Form.Group>
                </div>
                <div className="col-md-5 px-0">
                  <Form.Group className="mb-0" controlId="formBasicEmail">
                    <Form.Label>Password</Form.Label>
                    <InputGroup className="mb-2">
                      <InputGroup.Text id="basic-addon1">
                        <img src="/imgs/password.svg" alt="" />
                      </InputGroup.Text>
                      <Form.Control
                        type={showPass}
                        name="password"
                        onChange={handleAddFormChange}
                      />

{
                      showPass === 'text' ? (
                        <InputGroup.Text id="basic-addon1">
                            <VisibilityOffIcon fontSize='small' style={{ cursor: "pointer", color:'lightgray' }}
                            alt=""
                            onClick={showPassword} />
                        </InputGroup.Text>
                      ) : (
                        <InputGroup.Text id="basic-addon1">
                            <VisibilityIcon fontSize='small' style={{ cursor: "pointer", color:'lightgray' }}
                            alt=""
                            onClick={showPassword} />
                        </InputGroup.Text>
                      )
                    }

                    </InputGroup>
                  </Form.Group>
                  {/* <p className="forgotPassword">Change Password</p> */}
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
      <div className="cardSection mt-4 mb-2">
        <div className="d-flex justify-content-between">
          <h1>Contact & Address Information</h1>
        </div>
        <div className="row mx-0">
          <div className="col-10 mx-auto">
            <Form className="mt-5">
              <div className="row mx-0">
                <div className="col-md-5 px-0">
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Email</Form.Label>
                    <InputGroup className="mb-3">
                      <InputGroup.Text id="basic-addon1">
                        <img src="/imgs/email.svg" alt="" />
                      </InputGroup.Text>
                      <Form.Control
                        type="email"
                        name="email"
                        onChange={handleAddFormChange}
                      />
                    </InputGroup>
                  </Form.Group>
                </div>
                <div className="col-md-2"></div>
                <div className="col-md-5 px-0">
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Contact No</Form.Label>
                    <InputGroup className="mb-3">
                      <InputGroup.Text id="basic-addon1">
                        <img src="/imgs/phone.svg" alt="" />
                      </InputGroup.Text>
                      <Form.Control
                        type="number"
                        min='0'
                        name="contact_no"
                        onChange={handleAddFormChange}
                      />
                    </InputGroup>
                  </Form.Group>
                </div>
                <div className="col-12 px-0">
                  <h4 className="identifier py-3">Address</h4>
                </div>
                <div className="col-md-5 px-0">
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Country</Form.Label>
                    <InputGroup className="mb-3">
                      <InputGroup.Text id="basic-addon1">
                        <img src="/imgs/country.svg" alt="" />
                      </InputGroup.Text>
                      <Form.Control
                        type="text"
                        name="country"
                        onChange={handleAddFormChange}
                      />
                    </InputGroup>
                  </Form.Group>
                </div>
                <div className="col-md-2"></div>
                <div className="col-md-5 px-0">
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>State</Form.Label>
                    <InputGroup className="mb-3">
                      <InputGroup.Text id="basic-addon1">
                        <img src="/imgs/address.svg" alt="" />
                      </InputGroup.Text>
                      <Form.Control
                        type="text"
                        name="state"
                        onChange={handleAddFormChange}
                      />
                    </InputGroup>
                  </Form.Group>
                </div>
                <div className="col-md-5 px-0">
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>City</Form.Label>
                    <InputGroup className="mb-3">
                      <InputGroup.Text id="basic-addon1">
                        <img src="/imgs/address.svg" alt="" />
                      </InputGroup.Text>
                      <Form.Control
                        type="text"
                        name="city"
                        onChange={handleAddFormChange}
                      />
                    </InputGroup>
                  </Form.Group>
                </div>
                <div className="col-md-2"></div>
                <div className="col-md-5 px-0">
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Postal Code</Form.Label>
                    <InputGroup className="mb-3">
                      <InputGroup.Text id="basic-addon1">
                        <img src="/imgs/postal_code.svg" alt="" />
                      </InputGroup.Text>
                      <Form.Control
                        type="text"
                        name="postal_code"
                        onChange={handleAddFormChange}
                      />
                    </InputGroup>
                  </Form.Group>
                </div>
                <div className="col-md-12 px-0">
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Address</Form.Label>
                    <InputGroup className="mb-3">
                      <InputGroup.Text id="basic-addon1">
                        <img src="/imgs/address.svg" alt="" />
                      </InputGroup.Text>
                      <Form.Control
                        type="text"
                        name="address"
                        onChange={handleAddFormChange}
                      />
                    </InputGroup>
                  </Form.Group>
                  {/* <p className="forgotPassword">Add New Address</p> */}
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContributorForm;
