import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { frogetPass } from "../../../features/auth/authSlice";
import "react-toastify/dist/ReactToastify.css";
import { useSnackbar } from "notistack";
import validator from "validator";

function ForgetPassword() {
  const [email, setEmail] = useState("");
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const { user, isLoading, isSuccess, isError, message } = useSelector(
    (state) => state.auth
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (isSuccess || message) {
      enqueueSnackbar(message.data, { variant: "success" });
    }
    if (isError) {
      enqueueSnackbar(message.data, { variant: "error" });
    }
  }, [dispatch, isSuccess, isError, message]);

  const SubmitHandler = (event) => {
    event.preventDefault();
    if (validator.isEmail(email)) {
      dispatch(frogetPass({ email: email }));
      if (isSuccess || message) {
        toast(message.data);
      }
      if (isError) {
        toast(message.data);
      }
    } else {
      enqueueSnackbar("Please add valid email.", { variant: "error" });
    }
  };

  return (
    <div className="loginMain">
      <div className="row mx-0 loginMain">
        <div className="col-12 my-auto">
          <div className="text-center py-5">
            <img src="/imgs/logo.png" alt="" onClick={() => navigate("/")} />
          </div>
          <div className="row mx-0">
            <div className="col-md-6 mx-auto">
              <div className="loginBox">
                <h3>Forget Password</h3>
                <div className="row">
                  <div className="col-md-8 mx-auto">
                    <Form className="mt-5" onSubmit={SubmitHandler}>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Email</Form.Label>
                        <InputGroup className="mb-3">
                          <InputGroup.Text id="basic-addon1">
                            <img src="/imgs/email.svg" alt="" />
                          </InputGroup.Text>
                          <Form.Control
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </InputGroup>
                      </Form.Group>
                      <div className="d-flex justify-content-center gap-4 mt-5 mb-3">
                        <Button
                          className="loginBtn"
                          type="submit"
                          disabled={email === "" ? true : false}
                        >
                          Submit
                        </Button>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForgetPassword;
