import MapBoxGeocoder from "@mapbox/mapbox-gl-geocoder";
import { useControl } from "react-map-gl";
import { useValue } from "../../../context/ContextProvider";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";

const Geocoder = ({
  setResult,
  setAddress,
  setCountry,
  setState,
  setCity,
  setPostal_Code,
  setLatLng,
  index,
  score,
  id,
  handleGeoLocate,
}) => {
  const { mapdispatch } = useValue();
  const ctrl = new MapBoxGeocoder({
    accessToken: process.env.REACT_APP_MAP_TOKEN,
    marker: false,
    collapsed: true,
  });
  useControl(() => ctrl);
  ctrl.on("result", (e) => {
    const coords = e.result.geometry.coordinates;
    let value = {};
    value["longitude"] = coords[1];
    value["latitude"] = coords[0];
    setLatLng([coords[0], coords[1]]);
    setAddress(e?.result?.place_name);
    value["address"] = e?.result?.place_name;
    let a = 0,
      b = 0,
      c = 0,
      d = 0,
      country = "";
    for (let i = 0; i < e.result.context.length; i++) {
      const str = e.result.context[i].id;
      const beforeDot = str.substring(0, str.indexOf("."));
      if (beforeDot === "region") {
        a++;
        value["state"] = e?.result?.context[i]?.text;
        setState(e?.result?.context[i]?.text);
      } else if (beforeDot === "place" || beforeDot === "district") {
        b++;
        value["city"] = e?.result?.context[i]?.text;
        country = e?.result?.context[i]?.text;
        setCity(e?.result?.context[i]?.text);
      } else if (beforeDot === "country") {
        c++;
        value["country"] = e?.result?.context[i]?.text;
        setCountry(e?.result?.context[i]?.text);
      } else if (beforeDot === "postcode") {
        d++;
        value["postalCode"] = e?.result?.context[i]?.text;
        setPostal_Code(e?.result?.context[i]?.text);
      } else {
      }
    }
    if (c === 0 && b >= 1) setCountry(country);

    if (d === 0) {
      setPostal_Code("");
    }

    setResult({
      x: e?.result.geometry.coordinates[0],
      y: e?.result.geometry.coordinates[1],
    });
    handleGeoLocate(value, index, id, score);
    mapdispatch({
      type: "UPDATE_LOCATION",
      payload: { lng: coords[0], lat: coords[1] },
    });
  });
  return null;
};

export default Geocoder;
