import { useState, useEffect } from "react";
import axios from "axios";
import { Button, Form, InputGroup } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import Dragable from "./Draggable";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const API_URL = `${process.env.REACT_APP_BACKEND_ROUTE}/footer/`;
  const [description, setDescription] = useState("");
  const [links, setLinks] = useState([]);
  const [socialLinks, setSocialLinks] = useState([]);
  const [copyright, setCopyRight] = useState("");
  const [Id, setId] = useState(null);
  const [Id1, setId1] = useState(null);
  const [Edit, setEdit] = useState(false);
  const [name, setName] = useState("");
  const [url, setURL] = useState("");
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    fetchFooter();
  }, []);
  const handleShow = () => setShow(true);
  const handleShow1 = () => setShow1(true);

  const fetchFooter = () => {
    axios
      .get(API_URL)
      .then(({ data }) => {
        console.log(data);
        setLinks(data?.data?.links);
        setSocialLinks(data?.data?.socialLinks);
        setDescription(data?.data?.description);
        setCopyRight(data?.data?.copyright);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const AddNav = async () => {
    if (validateURL(url)) {
      const navData = {
        name,
        url,
      };
      setLinks([...links, navData]);
      setShow(!show);
      setName("");
      setURL("");
    }
  };
  const AddNav1 = async () => {
    if (validateURL(url)) {
      const navData = {
        name,
        url,
      };
      setSocialLinks([...socialLinks, navData]);
      setShow1(!show1);
      setName("");
      setURL("");
    }
  };
  const handleClose = () => {
    setName("");
    setURL("");
    setShow(false);
    setEdit(false);
  };
  const handleClose1 = () => {
    setName("");
    setURL("");
    setShow1(false);
    setEdit(false);
  };
  const EditData = (val, index) => {
    setEdit(true);
    setShow(true);
    setId(index);
    setName(val.name);
    setURL(val.url);
  };
  const EditData1 = (val, index) => {
    setEdit(true);
    setShow1(true);
    setId1(index);
    setName(val.name);
    setURL(val.url);
  };
  function validateURL(textval) {
    var urlregex = new RegExp("/(.*)");
    return urlregex.test(textval);
  }
  const DeleteNav = (index) => {
    let filter = links.filter((item, i) => i !== index);
    setLinks(filter);
  };
  const DeleteNav1 = (index) => {
    let filter = socialLinks.filter((item, i) => i !== index);
    setSocialLinks(filter);
  };
  const UpdateNav = () => {
    links[Id].name = name;
    links[Id].url = url;

    setLinks(links);
    setShow(!show);
    setName("");
    setURL("");
  };
  const UpdateNav1 = () => {
    console.log("clicking");
    socialLinks[Id1].name = name;
    socialLinks[Id1].url = url;

    setSocialLinks(socialLinks);
    setShow1(!show1);
    setName("");
    setURL("");
  };
  const saveFooter = () => {
    const data = {
      description,
      links,
      socialLinks,
      copyright,
    };
    axios
      .put(API_URL, data)
      .then(({ data }) => {
        console.log(data);
        toast.success("Footer updated successfully");
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <div>
      <ToastContainer />
      <div className="d-flex justify-content-end gap-4 mb-3">
        <Button
          className="cancelBtn"
          type="button"
          onClick={() => navigate(-1)}
        >
          Cancel
        </Button>
        <Button className="loginBtn" onClick={saveFooter}>
          Save
        </Button>
      </div>
      <div>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Description</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            value={description}
            name="description"
            onChange={(e) => setDescription(e.target.value)}
          />
        </Form.Group>
      </div>
      <div>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Copyright Section</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            value={copyright}
            name="copyright"
            onChange={(e) => setCopyRight(e.target.value)}
          />
        </Form.Group>
      </div>
      <div className="row mx-0 mb-4">
        <div>
          <h5 className="mb-3">Page Links</h5>
        </div>
        <div className="d-flex justify-content-end">
          <Button className="loginBtn" onClick={handleShow}>
            Add new
          </Button>
        </div>
      </div>

      <Dragable
        EditData={EditData}
        DeleteNav={DeleteNav}
        setData={setLinks}
        data={links}
      />
      <div className="row mx-0 mb-4">
        <div>
          <h5 className="mb-3">Social Links</h5>
        </div>
        <div className="d-flex justify-content-end">
          <Button className="loginBtn" onClick={handleShow1}>
            Add new
          </Button>
        </div>
      </div>

      <Dragable
        EditData={EditData1}
        DeleteNav={DeleteNav1}
        setData={setSocialLinks}
        data={socialLinks}
      />
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Body>
          <div className="d-flex justify-content-between mb-3"></div>
          <div className="row gx-5">
            <div className="col-md-6">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Name</Form.Label>
                <InputGroup className="mb-3">
                  <InputGroup.Text id="basic-addon1">
                    <img src="/imgs/u_user.svg" alt="" />
                  </InputGroup.Text>
                  <Form.Control
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </InputGroup>
              </Form.Group>
            </div>
            <div className="col-md-6">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Server Link</Form.Label>
                <InputGroup className="mb-3">
                  <InputGroup.Text id="basic-addon1">
                    <img src="/imgs/server.svg" alt="" />
                  </InputGroup.Text>
                  <Form.Control
                    type="text"
                    value={url}
                    onChange={(e) => setURL(e.target.value)}
                  />
                </InputGroup>
              </Form.Group>
            </div>
          </div>

          <div className="d-sm-flex justify-content-center text-center gap-4">
            {Edit == true ? (
              <>
                <Button
                  className="cancelBtn me-sm-0 me-3 mb-sm-0 mb-2"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
                <Button className="loginBtn me-sm-0 me-3" onClick={UpdateNav}>
                  Update
                </Button>
              </>
            ) : (
              <>
                <Button
                  className="cancelBtn me-sm-0 me-3 mb-sm-0 mb-2"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
                <Button className="loginBtn me-sm-0 me-3" onClick={AddNav}>
                  Save
                </Button>
              </>
            )}
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={show1} onHide={handleClose} centered>
        <Modal.Body>
          <div className="d-flex justify-content-between mb-3"></div>
          <div className="row gx-5">
            <div className="col-md-6">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Name</Form.Label>
                <InputGroup className="mb-3">
                  <InputGroup.Text id="basic-addon1">
                    <img src="/imgs/u_user.svg" alt="" />
                  </InputGroup.Text>
                  <Form.Select
                    aria-label="Default select example"
                    value={name}
                    name="status"
                    onChange={(e) => setName(e.target.value)}
                  >
                    <option value="">Select</option>
                    <option value="facebook">Facebook</option>
                    <option value="twitter">Twitter</option>
                    <option value="linkedin">Linkedin</option>
                    <option value="instagram">Instagram</option>
                    <option value="youtube">Youtube</option>
                  </Form.Select>
                </InputGroup>
              </Form.Group>
            </div>
            <div className="col-md-6">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Server Link</Form.Label>
                <InputGroup className="mb-3">
                  <InputGroup.Text id="basic-addon1">
                    <img src="/imgs/server.svg" alt="" />
                  </InputGroup.Text>
                  <Form.Control
                    type="text"
                    value={url}
                    onChange={(e) => setURL(e.target.value)}
                  />
                </InputGroup>
              </Form.Group>
            </div>
          </div>

          <div className="d-sm-flex justify-content-center text-center gap-4">
            {Edit == true ? (
              <>
                <Button
                  className="cancelBtn me-sm-0 me-3 mb-sm-0 mb-2"
                  onClick={handleClose1}
                >
                  Cancel
                </Button>
                <Button className="loginBtn me-sm-0 me-3" onClick={UpdateNav1}>
                  Update
                </Button>
              </>
            ) : (
              <>
                <Button
                  className="cancelBtn me-sm-0 me-3 mb-sm-0 mb-2"
                  onClick={handleClose1}
                >
                  Cancel
                </Button>
                <Button className="loginBtn me-sm-0 me-3" onClick={AddNav1}>
                  Save
                </Button>
              </>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Footer;
