import React, { useEffect, useState } from "react";
import Table from "../../../Table/Table";
import { InputGroup, Button, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { userManagement_column } from "../../../../utils/constant/column_header_data";
import { useDispatch, useSelector } from "react-redux";
import {
  deletedRowsS,
  getStaff,
  reset,
} from "../../../../features/staffs/staffSlice";
import Modal from "react-bootstrap/Modal";

function Staff() {
  const { Staff_List, isError, message, isSuccess, isList, isLoading } =
    useSelector((state) => state.staff);
  const [Search, setSearch] = useState("");

  const [selectedRows, setSelectedRows] = useState(false);
  const [CList, setCList] = useState([]);

  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);

  const dispatch = useDispatch();
  const handleClose = () => {
    setShow(false);
  };

  useEffect(() => {
    dispatch(getStaff());

    if (Staff_List) {
      const data = [...Staff_List].reverse();
      setCList(data);
    }
    if (isSuccess == true) {
      dispatch(reset());
    }
  }, [dispatch, isError, message, isList, isSuccess]);

  const deleteRow = () => {
    let yFilter = selectedRows.map((itemY) => {
      return itemY.id;
    });
    let filteredX = Staff_List.filter((itemX) => !yFilter.includes(itemX._id));
    dispatch(deletedRowsS(yFilter));
    dispatch(reset());
    setShow(false);
  };

  return (
    <div className="staffMainDiv">
      <div className="row mx-0 mb-4">
        <div className="d-flex justify-content-between">
          <div className="d-flex gap-3">
            <img
              src="/imgs/revoke.svg"
              width={20}
              alt=""
              onClick={() => setSearch("")}
            />
            <Form.Group className="mb-0">
              <InputGroup className="mb-0">
                <InputGroup.Text id="basic-addon1">
                  <img src="/imgs/search.svg" alt="" />
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  placeholder="Search"
                  value={Search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </InputGroup>
            </Form.Group>
          </div>
          <Link to="/user-management/staff/staff-form">
            <Button className="loginBtn" type="submit">
              Add New
            </Button>
          </Link>
        </div>
      </div>
      <Table
        header={userManagement_column}
        data={CList}
        section="staff"
        Search={Search}
      />

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Deleted</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this user?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              deleteRow();
            }}
          >
            Sure
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Staff;
