import React, { useEffect, useState } from "react";
import Table from "../../../Table/Table";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import { Link, Navigate, useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Dragable from "../../../Dragable/Dragable";
import { useDispatch, useSelector } from "react-redux";
import {
  addNav,
  deleteNav,
  navigationList,
  updateNav,
} from "../../../../features/navigations/navSlice";
import { toast, ToastContainer } from "react-toastify";
import { useSnackbar } from "notistack";

function Navigation() {
  const { nav_List, isError, isSuccess, isLoading, message } = useSelector(
    (state) => state.navData
  );

  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [revoke, setRevoke] = useState(false);
  const [delID, setDelID] = useState("");

  const [data, setData] = useState();
  const [Id, setId] = useState();
  const [Edit, setEdit] = useState(false);
  const [name, setName] = useState("");
  const [url, setURL] = useState("");
  const [showScreen, setShowScreen] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const handleClose2 = () => {
    setDelID("");
    setShow2(false);
    setRevoke(false);
  };
  const handleShow2 = () => setShow2(true);

  const handleClose = () => {
    setName("");
    setURL("");
    setShow(false);
    setEdit(false);
  };
  const handleShow = () => setShow(true);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(navigationList());
    if (isSuccess) {
      setData(nav_List);
    }
  }, [dispatch, isError, isSuccess, message]);

  function validateURL(textval) {
    // var urlregex = new RegExp("^(http|https|ftp)\://[a-zA-Z0-9\-\.]+\.[a-zA-Z]{2,3}(:[a-zA-Z0-9]*)?/?([a-zA-Z0-9\-\._\?\,\'/\\\+&amp;%\$#\=~])*$");
    var urlregex = new RegExp("/(.*)");
    return urlregex.test(textval);
  }

  const AddNav = async () => {
    if (validateURL(url)) {
      const navData = {
        name,
        url,
        editAble: false,
        showScreen,
      };
      const result = await dispatch(addNav(navData));
      setName("");
      setURL("");
      setShow(false);
      if (!result.error) {
        toast.success("Navgation Added Successfully!");
      } else {
        toast.error(result.payload);
      }
    } else {
      toast.error("URL is Incorrect!");
    }
  };

  const UpdateNav = async () => {
    if (validateURL(url)) {
      const navData = {
        Id,
        name,
        url,
        showScreen,
      };
      const result = await dispatch(updateNav(navData));
      setName("");
      setURL("");
      if (!result.error) {
        toast.success("Navigation Edited Successfully!");
      } else {
        toast.error("Unable to update");
      }
      setShow(false);
      setEdit(false);
    } else {
      toast.error("URL is Incorrect!");
    }
  };

  const EditData = (val) => {
    setEdit(true);
    setShow(true);
    setId(val._id);
    setName(val.name);
    setURL(val.url);
    setShowScreen(val.showScreen);
  };

  const DeleteNav = (ID, isRevoke) => {
    setDelID(ID);
    if (isRevoke) {
      return setShow2(true);
    }
    setRevoke(true);
  };

  const onDelete2 = () => {
    dispatch(deleteNav(delID));
    setDelID("");
    if (isSuccess) {
      toast.success("Navigation Deleted Successfully!");
    } else if (isError) {
      toast.error("Unable to delete!");
    }
    setShow2(false);
    setRevoke(false);
  };

  return (
    <div className="staffMainDiv">
      <ToastContainer />
      <div className="row mx-0 mb-4">
        <div className="d-flex justify-content-end">
          <Button className="loginBtn" onClick={handleShow}>
            Add new
          </Button>
        </div>
      </div>

      <Dragable
        nav_List={nav_List}
        EditData={EditData}
        DeleteNav={DeleteNav}
        setData={setData}
        data={data}
      />

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Body>
          <div className="d-flex justify-content-between mb-3"></div>
          <div className="row gx-5">
            <div className="col-md-6">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Name</Form.Label>
                <InputGroup className="mb-3">
                  <InputGroup.Text id="basic-addon1">
                    <img src="/imgs/u_user.svg" alt="" />
                  </InputGroup.Text>
                  <Form.Control
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </InputGroup>
              </Form.Group>
            </div>
            <div className="col-md-6">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Server Link</Form.Label>
                <InputGroup className="mb-3">
                  <InputGroup.Text id="basic-addon1">
                    <img src="/imgs/server.svg" alt="" />
                  </InputGroup.Text>
                  <Form.Control
                    type="text"
                    value={url}
                    onChange={(e) => setURL(e.target.value)}
                  />
                </InputGroup>
              </Form.Group>
            </div>
          </div>
          <div className="row gx-5">
            <div className="col-md-6">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                {/* <Form.Label>Show</Form.Label> */}
                <Form.Check
                  type="checkbox"
                  label="show on landing page"
                  checked={showScreen}
                  onChange={(e) => setShowScreen(e.target.checked)}
                />
                {/* <InputGroup className="mb-3"></InputGroup> */}
              </Form.Group>
            </div>
          </div>

          <div className="d-sm-flex justify-content-center text-center gap-4">
            {Edit == true ? (
              <>
                <Button
                  className="cancelBtn me-sm-0 me-3 mb-sm-0 mb-2"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
                <Button className="loginBtn me-sm-0 me-3" onClick={UpdateNav}>
                  Update
                </Button>
              </>
            ) : (
              <>
                <Button
                  className="cancelBtn me-sm-0 me-3 mb-sm-0 mb-2"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
                <Button
                  className="loginBtn me-sm-0 me-3"
                  disabled={(name && url) === "" ? true : false}
                  onClick={AddNav}
                >
                  Save
                </Button>
              </>
            )}
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={revoke}
        onHide={handleClose2}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Revoke</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to revoke this Navigation?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose2}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              onDelete2();
            }}
          >
            Sure
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={show2}
        onHide={handleClose2}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Deleted</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this Navigation?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose2}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              onDelete2();
            }}
          >
            Sure
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Navigation;
