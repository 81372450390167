import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import tourService from "./tourService";
import axios from "axios";

const API_URL = `${process.env.REACT_APP_BACKEND_ROUTE}/quotation/`;
// Get user from localStorage

const initialState = {
  tour_List: [],
  interestedTours: [],
  tour_Detail: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  credit: false,
  isAdded: false,
  message: "",
  updated: false,
  imageLocation: "",
  isList: false,
  nearestTours: [],
};

// get articles list
export const getTour = createAsyncThunk(
  "tours/getTour",
  async (ID, thunkAPI) => {
    try {
      return await tourService.getTour(ID);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getTourByInterest = createAsyncThunk(
  "tours/getTourByInterest",
  async (data, thunkAPI) => {
    try {
      return await tourService.getTourByInterest(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const setStatusAsDraft = async (currentUserData) => {
  const data = currentUserData;
  data.status = "Draft";
  const response = await axios.put(API_URL + "edit-quotation", data);
  if (response.data) {
    return response.data;
  }
};

export const addTour = createAsyncThunk(
  "tours/addTour",
  async (userData, thunkAPI) => {
    try {
      const result = await tourService.addTour(userData);
      return result;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const editTour = createAsyncThunk(
  "tours/editTour",
  async (userData, thunkAPI) => {
    try {
      return await tourService.editTourData(userData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getDetails = createAsyncThunk(
  "tours/getDetails",
  async (ID, thunkAPI) => {
    try {
      return await tourService.getDetails(ID);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const createReview = createAsyncThunk(
  "tours/createReview",
  async (Data, thunkAPI) => {
    try {
      return await tourService.createReview(Data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const replyReview = createAsyncThunk(
  "tours/replyReview",
  async (Data, thunkAPI) => {
    try {
      return await tourService.replyReview(Data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const deletedTour = createAsyncThunk(
  "tours/deletedTour",
  async (delt, thunkAPI) => {
    try {
      return await tourService.deletedTour(delt);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const deletedRowsTours = createAsyncThunk(
  "tours/deletedRowsTours",
  async (passData, thunkAPI) => {
    try {
      return await tourService.deletedRowsTours(passData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getNearestTours = createAsyncThunk(
  "tours/nearestTours",
  async (passData, thunkAPI) => {
    try {
      return await tourService.getNearestTours(passData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const deleteReview = createAsyncThunk(
  "tours/deletereviews",
  async (passData, thunkAPI) => {
    try {
      return await tourService.deleteReview(passData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const tourSlice = createSlice({
  name: "tours",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.isAdded = false;
      state.updated = false;
      state.message = "";
      state.imageLocation = "";
      state.isList = false;
    },
    clearList: (state) => {
      state.tour_List = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTour.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getTour.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isList = true;
        state.credit = false;
        // state.message = action.payload.data.message
        state.tour_List = action.payload.data;
      })
      .addCase(getTour.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isList = false;
        state.credit = false;
        // state.message = action.payload
        state.tour_List = [];
      })

      .addCase(addTour.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addTour.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = "Added!";
        state.isAdded = true;
        state.credit = true;
        state.tour_List = [...state.tour_List, action.payload.data];
      })
      .addCase(addTour.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.credit = false;
        state.message = action.payload;
        state.tour_List = [];
      })

      .addCase(editTour.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(editTour.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = "Updated!";
        state.credit = false;
        state.updated = true;
        // state.article_List = action.payload.data
      })
      .addCase(editTour.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.credit = false;
        state.updated = false;
        state.tour_List = [];
      })

      .addCase(getDetails.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getDetails.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = action.payload;
        state.credit = false;
        state.tour_Detail = action.payload.data;
      })
      .addCase(getDetails.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.credit = false;
        state.tour_Detail = [];
        state.tour_List = [];
      })

      .addCase(createReview.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createReview.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = action.payload;
        state.credit = false;
        const newData = [...state.tour_List];
        const Index = newData.findIndex(
          (val) => val._id === action.payload.data._id
        );
        newData[Index] = action.payload.data;
        state.tour_List = newData;
        state.tour_Detail = action.payload.data;
      })
      .addCase(createReview.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.credit = false;
        state.message = action.payload;
        state.tour_List = [...state.tour_List];
      })
      .addCase(replyReview.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(replyReview.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = action.payload;
        state.credit = false;
        const newData = [...state.tour_List];
        const Index = newData.findIndex(
          (val) => val._id === action.payload.data._id
        );
        newData[Index] = action.payload.data;
        state.tour_List = newData;
        state.tour_Detail = action.payload.data;
      })
      .addCase(replyReview.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.credit = false;
        state.message = action.payload;
        state.tour_List = [...state.tour_List];
      })

      .addCase(deletedTour.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deletedTour.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = action.payload?.message;
        state.credit = false;
        // state.article_List = [...state.article_List]
      })
      .addCase(deletedTour.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.credit = false;
        state.article_List = [];
      })

      .addCase(deletedRowsTours.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deletedRowsTours.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.credit = false;
        // state.user = action.payload
        state.message = action.payload.data;
      })
      .addCase(deletedRowsTours.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.credit = false;
        state.message = action.payload;
        // state.user = null
      })

      .addCase(deleteReview.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteReview.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.credit = false;
        state.message = action.payload.message;
        // state.nearestTours = action.payload.data;
      })
      .addCase(deleteReview.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.credit = false;
        state.message = action.payload.message;
        // state.user = null
      })
      .addCase(getTourByInterest.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getTourByInterest.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.credit = false;
        state.message = action.payload.message;
        state.interestedTours = action.payload.data;
        // state.nearestTours = action.payload.data;
      })
      .addCase(getTourByInterest.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.credit = false;
        state.message = action.payload.message;
        // state.user = null
      })
      .addCase(getNearestTours.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getNearestTours.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.credit = false;
        state.message = action.payload.message;
        state.nearestTours = action.payload.data;
        // state.nearestTours = action.payload.data;
      })
      .addCase(getNearestTours.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.credit = false;
        state.message = action.payload.message;
        // state.user = null
      });
  },
});

export const { reset, clearList } = tourSlice.actions;
export default tourSlice.reducer;
