import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import contactService from "./contactService";

const initialState = {
  contact: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

export const getContactData = createAsyncThunk(
  "contact/getContactData",
  async (thunkAPI) => {
    try {
      return await contactService.getContactData();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const addContactContent = createAsyncThunk(
  "contact/addContactContent",
  async (userData, thunkAPI) => {
    try {
      return await contactService.addContactContent(userData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const contactUs = createAsyncThunk(
  "contact/contactUs",
  async (data, thunkAPI) => {
    try {
      return await contactService.contactUs(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const contactSlice = createSlice({
  name: "contact",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getContactData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getContactData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = action.payload.data.message;
        state.contact = action.payload.data;
      })
      .addCase(getContactData.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.contact = null;
      })

      .addCase(addContactContent.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(contactUs.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(contactUs.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = `Message Sent`;
      })
      .addCase(contactUs.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.message = `Unable To Send Message`;
      })
      .addCase(addContactContent.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = action.payload;
        state.contact = action.payload.data;
      })
      .addCase(addContactContent.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.contact = null;
      });
  },
});

export const { reset } = contactSlice.actions;
export default contactSlice.reducer;
