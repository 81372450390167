import React, { useEffect, useLayoutEffect, useState } from "react";
import { Button, InputGroup, Form, Modal } from "react-bootstrap";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Accordion from "react-bootstrap/Accordion";
import Data from "../../../utils/data/reviewrsData.json";
import Carousel from "react-bootstrap/Carousel";
import {
  clearList,
  getDetails,
  reset,
} from "../../../features/360Tours/tourSlice";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Stack, Rating, Box } from "@mui/material";
import { toast, ToastContainer } from "react-toastify";
// import { MapContainer, Marker, Popup, TileLayer, useMap } from "react-leaflet";
import ReactMapGL, { Marker, NavigationControl } from "react-map-gl";
import { useRef } from "react";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import SearchControl from "../../SharedSearch/SearchControl";
import { OpenStreetMapProvider } from "react-leaflet-geosearch";
import { useValue } from "../../../../src/context/ContextProvider";

function TourList() {
  var key = "lq1rACh8fxmYQr1ZbHAB";

  const prov = OpenStreetMapProvider();

  const [latLng, setLatLng] = useState();

  const { tour_Detail, isError, message, isSuccess, isLoading } = useSelector(
    (state) => state.tours
  );

  const {
    state: {
      location: { lng, lat },
    },
    mapdispatch,
  } = useValue();
  const user = JSON.parse(localStorage.getItem("user"));
  const role = user?.data?.user?.role;

  const { id: tourID } = useParams();
  const { state, pathname } = useLocation();
  const parts = pathname.split("/");
  const id = parts[parts.length - 1];
  const mapRef = useRef();

  const [Details, setDetails] = useState({});

  const [ViewAll, setViewAll] = useState(3);
  const [View, setView] = useState("View All");
  const reviewLength = Details?.reviews?.length;
  const ViewAllData = () => {
    if (ViewAll === 3) {
      setView("View Less");
      setViewAll(reviewLength);
    } else {
      setView("View All");
      setViewAll(3);
    }
    // setViewAll('300px')
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    if (mapRef.current) {
      // mapRef.current.flyTo({
      //   zoom: 8,
      //   center: [
      //     state && state?.Details && state?.Details?.full_address
      //       ? state?.Details?.full_address?.y
      //       : lng,
      //     state && state?.Details && state?.Details?.full_address
      //       ? state?.Details?.full_address?.x
      //       : lat,
      //   ],
      // });
    }
  }, [mapRef]);

  useEffect(() => {
    // getTour();
    dispatch(getDetails(tourID));


    

  }, []);

  

  // const getTour = () => {
  //   dispatch(getTour(tourID));
  // };

  const GoToEdit = () => {
    if (tourID) {
      navigate(`/360-tours/tours-form/${tourID}`, {
        state: { view: false, Details: state?.Details },
      });
    } else {
      toast.error("Invalid tour id!");
    }
  };

  return (
    <div>
      <ToastContainer />
      <button
        className="loginBtn"
        style={{ marginBottom: "10px" }}
        onClick={() => navigate(-1)}
      >
        Go back
      </button>
      {(role === "contributor" ||
        (role === "staff" &&
          user?.data?.user?.permissions[0]?.VirtualReviews?.some(
            (val) => val === "edit"
          ))) && (
        <div className="d-flex justify-content-end gap-4 mb-3">
          <Button className="loginBtn" onClick={GoToEdit}>
            Edit
          </Button>
        </div>
      )}

      <div className="cardSection slider pb-5">
        <div className="d-flex justify-content-between align-items-start">
          <div>
            <h1>{tour_Detail?.Name}</h1>
            <p>
              Registerd at{" "}
              <span>{tour_Detail?.createdAt?.substring(0, 10)}</span>
            </p>
          </div>
          {tour_Detail.status === "DRAFT" ? (
            <p className="status draft">{Details?.status}</p>
          ) : tour_Detail.status === "PUBLISHED" ? (
            <p className="status active">{tour_Detail?.status}</p>
          ) : (
            <p className="status hide">{tour_Detail?.status}</p>
          )}
        </div>
        <Carousel>
          {tour_Detail?.cover_images?.map((item, index) => (
            <Carousel.Item key={index}>
              <img
                className="d-block w-100"
                src={item?.img}
                alt="Cover Image"
                style={{ height: "400px" }}
              />
            </Carousel.Item>
          ))}
        </Carousel>
      </div>
      <div className="articleDetail  mt-4 mb-2">
        <Accordion defaultActiveKey="0">
          <Accordion.Item eventKey="0">
            <Accordion.Header>Reviews</Accordion.Header>
            <Accordion.Body>
              <div className="ratingReviews">
                <div className="d-flex justify-content-between align-items-start gap-4 p-3">
                  <div className="d-sm-flex gap-4 w-50">
                    <Form.Group className="w-100" controlId="formBasicEmail">
                      <InputGroup
                        className=""
                        style={{ background: "#F1F1F1" }}
                      >
                        <InputGroup.Text id="basic-addon1">
                          <Stack spacing={1}>
                            <Rating
                              name="half-rating"
                              value={tour_Detail?.rating}
                              precision={0.5}
                              readOnly
                            />
                          </Stack>
                        </InputGroup.Text>
                        <Form.Control
                          aria-label="Default select example"
                          disabled={true}
                          value={`${parseInt(
                            tour_Detail?.rating ? tour_Detail?.rating : 0
                          )} Stars`}
                        />
                      </InputGroup>
                    </Form.Group>
                  </div>
                  <Button
                    className="loginBtn"
                    style={{ width: "auto" }}
                    onClick={ViewAllData}
                  >
                    {View}
                  </Button>
                </div>
              </div>
              {tour_Detail?.reviews?.slice(0, 3).map((item, index) => {
                return (
                  <div
                    className="d-flex gap-3 reviewrsList align-items-center"
                    key={index}
                  >
                    <img
                      src={
                        item?.profile
                          ? item?.profile
                          : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_640.png"
                      }
                      alt=""
                    />
                    <div className="data">
                      <div className="d-flex gap-5">
                        <h3 className="name">{item?.name}</h3>
                        <Stack spacing={1}>
                          <Rating
                            name="half-rating"
                            value={item?.rating}
                            precision={0.5}
                            readOnly
                          />
                        </Stack>
                      </div>
                      <p className="description">{item?.description}</p>
                    </div>
                  </div>
                );
              })}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>

      <div className="cardSection mt-4 mb-2">
        <div className="d-flex justify-content-between">
          <h1>Contact & Address Information</h1>
        </div>
        <div className="row mx-0">
          <div className="col-10 mx-auto">
            <Form className="mt-5">
              <div className="row mx-0">
                <div className="col-md-5 px-0">
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Country</Form.Label>
                    <InputGroup className="mb-3">
                      <InputGroup.Text id="basic-addon1">
                        <img src="/imgs/country.svg" alt="" />
                      </InputGroup.Text>
                      <Form.Control
                        type="text"
                        name="country"
                        disabled={true}
                        value={tour_Detail?.location?.country}
                      />
                    </InputGroup>
                  </Form.Group>
                </div>
                <div className="col-md-2"></div>
                <div className="col-md-5 px-0">
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>State</Form.Label>
                    <InputGroup className="mb-3">
                      <InputGroup.Text id="basic-addon1">
                        <img src="/imgs/address.svg" alt="" />
                      </InputGroup.Text>
                      <Form.Control
                        type="text"
                        name="State"
                        disabled={true}
                        value={tour_Detail?.location?.state}
                      />
                    </InputGroup>
                  </Form.Group>
                </div>
                <div className="col-md-5 px-0">
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>City</Form.Label>
                    <InputGroup className="mb-3">
                      <InputGroup.Text id="basic-addon1">
                        <img src="/imgs/address.svg" alt="" />
                      </InputGroup.Text>
                      <Form.Control
                        type="text"
                        name="city"
                        disabled={true}
                        value={tour_Detail?.location?.city}
                      />
                    </InputGroup>
                  </Form.Group>
                </div>
                <div className="col-md-2"></div>
                <div className="col-md-5 px-0">
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Postal Code</Form.Label>
                    <InputGroup className="mb-3">
                      <InputGroup.Text id="basic-addon1">
                        <img src="/imgs/postal_code.svg" alt="" />
                      </InputGroup.Text>
                      <Form.Control
                        type="text"
                        name="postal_code"
                        disabled={true}
                        value={tour_Detail?.location?.postal_code}
                      />
                    </InputGroup>
                  </Form.Group>
                </div>
                <div className="col-md-12 px-0">
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Address</Form.Label>
                    <InputGroup className="mb-3">
                      <InputGroup.Text id="basic-addon1">
                        <img src="/imgs/address.svg" alt="" />
                      </InputGroup.Text>
                      <Form.Control
                        type="text"
                        name="address"
                        disabled={true}
                        value={tour_Detail?.address}
                      />
                    </InputGroup>
                  </Form.Group>
                </div>
                <div className="col-md-12 px-0">
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Map Location</Form.Label>
                    <div>
                      <Box
                        sx={{
                          height: 500,
                          position: "relative",
                        }}
                      >
                        <ReactMapGL
                          ref={mapRef}
                          mapboxAccessToken="pk.eyJ1IjoiYmx1ZXNpbG9zZyIsImEiOiJjbGZyeDZ1MGswMG1mM2RxcmJyajRzbXRyIn0.SwkzsvT0dgMXSafnYQoZSQ"
                          initialViewState={{
                            longitude: `${
                              // state &&
                              // state.Details &&
                              tour_Detail?.full_address?.coordinates
                                ? tour_Detail.full_address.coordinates[0]
                                : tour_Detail?.full_address?.y
                                ? tour_Detail?.full_address?.x
                                : lng
                            }`,
                            latitude: `${
                              // state &&
                              // state.Details &&
                              tour_Detail?.full_address?.coordinates
                                ? tour_Detail.full_address.coordinates[1]
                                : lat
                            }`,
                            zoom: 15,
                          }}
                          mapStyle="mapbox://styles/mapbox/streets-v11"
                        >
                          <Marker
                            latitude={
                              // state &&
                              // state.Details &&
                             tour_Detail?.full_address?.coordinates
                                ? tour_Detail.full_address.coordinates[1]
                                : lat
                            }
                            longitude={
                              // state &&
                              // state.Details &&
                              tour_Detail?.full_address?.coordinates
                                ? tour_Detail.full_address.coordinates[0]
                                : tour_Detail?.full_address?.y
                                ? tour_Detail?.full_address?.x
                                : lng
                            }
                            draggable
                            onDragEnd={(e) =>
                              mapdispatch({
                                type: "UPDATE_LOCATION",
                                payload: {
                                  lng: e.lngLat.lng,
                                  lat: e.lngLat.lat,
                                },
                              })
                            }
                          />

                          <NavigationControl position="bottom-right" />
                        </ReactMapGL>
                      </Box>
                      {/* </MapContainer> */}
                    </div>
                  </Form.Group>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TourList;
