import axios from "axios";
import { toast } from "react-toastify";

const API_URL = `${process.env.REACT_APP_BACKEND_ROUTE}/user/`;

// Get list of Contributors user
const usersList = async () => {
  const response = await axios.get(`${API_URL}${"users-list"}`);
  if (response.data) {
    return response.data;
  }
};

// Add Contributors user
const addUsersData = async (userData) => {
  const response = await axios.post(API_URL + "add-users", userData);
  if (response.data) {
    return response.data;
  }
};

const editUsersData = async (userData) => {
  const response = await axios.put(API_URL + "edit-users", userData);
  if (response.data) {
    return response.data;
  }
};

const deleteUsersData = async (ID) => {
  const response = await axios.put(API_URL + `delete-users/${ID}`);
  if (response.data) {
    return response.data;
  }
};

// Add Image Data
const saveImageU = async (bodyFormData) => {
  const response = await axios.post(API_URL + "add-uploads/s3", bodyFormData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  if (response.data) {
    return response.data;
  }
};

const updatePassU = async (passData) => {
  const response = await axios.post(API_URL + "change-password", passData);
  toast.success("Password has been changed successfully!");
  return response.data;
};

const deletedRowsU = async (passData) => {
  const response = await axios.put(API_URL + "deleted-rows", passData);
  return response.data;
};

const roleBaseUser = async (role) => {
  const response = await axios.get(API_URL + `userrole?role=${role}`);
  if (response.data) {
    return response.data;
  }
};

const userService = {
  usersList,
  addUsersData,
  editUsersData,
  deleteUsersData,
  saveImageU,
  updatePassU,
  deletedRowsU,
  roleBaseUser,
};

export default userService;
