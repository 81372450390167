import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import badWordService from "./BadWordService";

const initialState = {
  badWords: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

export const getBadword = createAsyncThunk(
  "badword/getBadWords",
  async (thunkAPI) => {
    try {
      return await badWordService.getData();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const addBadWordData = createAsyncThunk(
  "badword/addBadWords",
  async (content, thunkAPI) => {
    try {
      return await badWordService.addData(content);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updateBadWord = createAsyncThunk(
  "badword/updateBadWords",
  async (content, thunkAPI) => {
    try {
      return await badWordService.updateData(content);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const deleteBadWord = createAsyncThunk(
  "badword/deleteBadWords",
  async (id, thunkAPI) => {
    try {
      return await badWordService.deleteData(id);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const badWordSlice = createSlice({
  name: "badword",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = "";
      state.badWords = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addBadWordData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addBadWordData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = "Bad word Added!";
        // state.product_plans = [...state.product_plans, action.payload.data]
      })
      .addCase(addBadWordData.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.badWords = null;
      })

      .addCase(updateBadWord.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateBadWord.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = "About Us Updated!";
        // state.product_plans = [...state.product_plans, action.payload.data]
      })
      .addCase(updateBadWord.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.aboutus = null;
      })

      .addCase(getBadword.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getBadword.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = action.payload?.message;
        state.badWords = action.payload.data;
      })
      .addCase(getBadword.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.aboutus = null;
      })
      .addCase(deleteBadWord.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteBadWord.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = action.payload?.message;
      })
      .addCase(deleteBadWord.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.aboutus = null;
      });
  },
});

export const { reset } = badWordSlice.actions;
export default badWordSlice.reducer;
